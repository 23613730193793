import { styled, Skeleton, Typography } from "@mui/material";
import { RowLabel } from "./RowLabel";
import { PowerScore } from "../powerScore";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";

const SkeletonRow = styled(Skeleton)(({ theme: { themeColors } }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  backgroundColor: themeColors.borderLowContrast,
  border: `1px solid ${themeColors.buttonBorderColor}`,
  marginTop: 8,
  height: 64,
  transform: "scale(1)",
}));

const StyledRow = styled("div")(({ theme: { themeColors } }) => ({
  display: "flex",
  flexDirection: "row",
  width: "auto",
  minWidth: "fit-content",
  backgroundColor: "none",
  color: themeColors.buttonContentColor,
  "&:not(:last-child)": {
    borderBottom: `1px solid ${themeColors.buttonBorderColor}`,
    borderRadius: 0,
  },
  minHeight: "82px",
  gap: "32px",
  cursor: "pointer",
  "&:visited": {
    backgroundColor: themeColors.contentCardBackgroundColor,
  },
  "&:hover": {
    backgroundColor: themeColors.buttonHoverBackgroundColor,
    color: themeColors.buttonHoverContentColor,
  },
  "&:hover > div": {
    backgroundColor: themeColors.buttonHoverBackgroundColor,
    color: themeColors.buttonHoverContentColor,
  },
}));

const LabelElements = styled("div")(({ theme: { themeColors } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  zIndex: 1,
  height: "100%",
  left: 0,
  paddingLeft: 16,
  position: "sticky",
  gap: 32,
  backgroundColor: themeColors.cardBackgroundColor,
  "&:hover": {
    backgroundColor: themeColors.buttonHoverBackgroundColor,
    color: themeColors.buttonHoverContentColor,
  },
}));

const ScoreContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

const RankContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "60px",
  gap: "10px",
}));

const StyledAutoAwesomeOutlinedIcon = styled(AutoAwesomeOutlinedIcon)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.anchorIconsGeneralSecondary,
  })
);

const StyledRankValue = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tableCellDefaultContent,
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "120%",
  marginLeft: "4px",
}));

export const Row = ({
  row,
  isShimmer,
  onRowClick = () => {},
  children: rowElements,
  showPowerscore,
  rowTitle,
  rowSubtitle = "",
  showRankCol,
  rank,
  showRankValue,
  productLineId,
  profile,
  showCustomListIcon = false,
}) => {
  if (isShimmer) return <SkeletonRow />;
  return (
    <StyledRow onClick={(e) => onRowClick(e)}>
      <LabelElements>
        {showRankCol && (
          <RankContainer>
            {showRankValue && <StyledRankValue>{rank}</StyledRankValue>}
            {!!rank && <StyledAutoAwesomeOutlinedIcon />}
          </RankContainer>
        )}
        {showPowerscore && (row.score || row.score === 0) && (
          <ScoreContainer>
            <PowerScore score={Math.round(row.score)} />
          </ScoreContainer>
        )}
        <RowLabel
          entityId={row.id}
          title={rowTitle}
          subTitle={rowSubtitle}
          productLineId={productLineId}
          showCustomListIcon={showCustomListIcon}
          profile={profile}
        />
      </LabelElements>
      {rowElements}
    </StyledRow>
  );
};
