import { useState, useContext } from 'react';
import { styled, Button, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import {
  ModalDisplay,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '../../../../../../components/generic/modalDisplay';
import { ConfirmationModal } from './confirmationModal';
import { StatusModal } from './statusModal';
import { rollbackCuration } from '../../../../../../request/projectRequests';
import { AppContext } from '../../../../appContext';

const StyledTypography = styled(Typography)(({ theme: { themeColors } }) => ({
  padding: '8px 16px',
  color: themeColors.primaryTextColor,
  textAlign: 'left',
  fontFamily: 'Roboto',
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '135%',
}));

const StyledButton = styled(Button)(({ theme: { themeColors } }) => ({
  color: themeColors.maptualListFilteringBadgeColor,
  height: 32,
  borderRadius: 4,
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
}));

export const CurationRollbackModal = ({ isOpen, handleClose, projectId }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const { projectList } = useContext(AppContext);

  const projectFromParams = projectList.find(
    (item) => item && item.projectId && item.projectId === projectId
  );
  const productLineId = projectFromParams?.productLineId;
  const marketId = projectFromParams?.marketId;

  const {
    mutate: startCurationRollback,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(() =>
    rollbackCuration({
      marketId,
      productLineId,
    })
  );

  const handleConfirmation = () => {
    setShowConfirmationModal(false);
    startCurationRollback();
    setShowStatusModal(true);
  };

  const handleStatusModalClose = () => {
    setShowStatusModal(false);
  };

  const handleConfirmationClose = () => {
    setShowConfirmationModal(false);
  };

  const handleUpperClose = () => {
    handleClose();
  };

  return (
    <>
      <ModalDisplay onClose={() => handleClose()} isOpen={isOpen}>
        <ModalHeader shouldShowXIcon={false}>Rollback Curation?</ModalHeader>
        <ModalBody>
          <StyledTypography>
            Clicking "Rollback Curation" will permanately delete the live
            curated list and re-publish the previous list', please be cognizant
            before proceeding
          </StyledTypography>
        </ModalBody>
        <ModalFooter style={{ padding: 16 }}>
          <StyledButton
            style={{ border: '1px solid #313B49', backgroundColor: '#1F232C' }}
            onClick={() => handleClose()}
          >
            Cancel
          </StyledButton>
          <StyledButton
            style={{ backgroundColor: '#0D47A0' }}
            onClick={() => {
              setShowConfirmationModal(true);
              handleClose();
            }}
          >
            Rollback Curation
          </StyledButton>
        </ModalFooter>
      </ModalDisplay>
      <ConfirmationModal
        isModalOpen={showConfirmationModal}
        onClose={handleConfirmationClose}
        onConfirm={() => handleConfirmation()}
        handleUpperClose={handleUpperClose}
      />
      <StatusModal
        isModalOpen={showStatusModal}
        loading={isLoading}
        success={isSuccess}
        error={isError}
        onClose={handleStatusModalClose}
      />
    </>
  );
};

export default CurationRollbackModal;
