import moment from 'moment';

export const filterEntitiesByLastContactedDate = (
  entities,
  notContactedFilter
) =>
  entities.filter((entity) =>
    checkLastContactedDate(entity, notContactedFilter)
  );

export const checkLastContactedDate = (entity, notContactedFilter) => {
  if (!entity.secondaryText) {
    return true;
  }

  const contactedDaysAgo = moment().diff(
    convertSecondaryTextToDate(entity.secondaryText),
    'days'
  );
  return contactedDaysAgo > notContactedFilter;
};

export const convertSecondaryTextToDate = (text) => {
  try {
    const splitText = text.split(',').map((item) => item.trim());
    const date = splitText[1];
    const year = splitText[2].split(' ')[0];

    return moment(date.concat(', ', year)).format('YYYY-MM-DD');
  } catch (error) {
    return moment().format('YYYY-MM-DD');
  }
};
