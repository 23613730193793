import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import { CardHeader } from '@mui/material';
import ProjectCardContent from './projectTiles/projectCardContent';
import { trackObjectiveSelection } from '../../trackers/appEventTracker';

const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    maxWidth: '20rem',
    width: '20rem',
    marginRight: '1.5vw',
    marginBottom: '1.5vw',
    backgroundColor: themeColors.mainSurfaceBackground,
  },
  cardHeaderTypographyStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    textAlign: 'left',
  },
  cardHeaderSubheaderStyle: {
    fontWeight: 400,
    fontSize: 14,
    color: themeColors.adminCardSubheader,
  },
  cardActionAreaStyle: {
    flexGrow: 1,
  },
  cardActionsStyle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 16,
    borderTop: `1px solid ${themeColors.borderLowContrast}`,
  },
  noSelectClickable: {
    webkitUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    cursor: 'pointer',
  },
}));

export default function HeadOfficeProjectTile({ project, match }) {
  const { projectName, projectId, market, projectStatus, isBusy } = project;

  const classes = useStyles();
  const routeHistory = useHistory();
  const newItemPath = `${match.path}/${projectId}`;

  const openProject = () => {
    routeHistory.push(newItemPath);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        title={project.projectName}
        titleTypographyProps={{
          className: `${classes.cardHeaderTypographyStyle} ${classes.noSelectClickable}`,
          onClick: openProject,
        }}
        subheader={project.market}
        subheaderTypographyProps={{
          className: `${classes.cardHeaderTypographyStyle} ${classes.cardHeaderSubheaderStyle} ${classes.noSelectClickable}`,
          onClick: openProject,
        }}
      />
      <CardActionArea
        disabled={isBusy}
        onClick={() => {
          routeHistory.push(newItemPath);
          trackObjectiveSelection(projectName);
        }}
      >
        <ProjectCardContent
          isBusy={isBusy}
          projectStatus={projectStatus}
          market={market}
          projectName={projectName}
        />
      </CardActionArea>
    </Card>
  );
}

HeadOfficeProjectTile.propTypes = {
  projectName: PropTypes.string,
  projectId: PropTypes.string,
  market: PropTypes.string,
  projectStatus: PropTypes.string,
  isBusy: PropTypes.bool,
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};
