import { Drawer } from '@mui/material';
import { useRouteMatch } from 'react-router-dom';
import HcpInfoContainer from '../../../../hcpinfoview/hcpInfoContainer';

export const CallPlanDrawer = ({
  open,
  setOpen,
  entityId,
  snowflakeMaptualListId,
}) => {
  const match = useRouteMatch();
  const updatedMatch = {
    params: {
      ...match.params,
      snowflakeEntityId: entityId,
      snowflakeMaptualListId,
    },
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { width: '800px' },
      }}
    >
      <HcpInfoContainer match={updatedMatch} />
    </Drawer>
  );
};
