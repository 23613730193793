import { configProxyApiInstance } from '../config';

const rootPath = `module-configurations`;

export type SchemaSummary = {
  name: string;
  title: string;
  description: string;
};
export const getSchemas = () =>
  configProxyApiInstance.get<SchemaSummary[]>(`/${rootPath}/schemas`);

export const getSchema = (schemaName: string) =>
  configProxyApiInstance.get(`/${rootPath}/schemas/${schemaName}`);

export interface GetSchemaValuesArgs {
  userGroup?: string;
  marketId?: string;
  schemaName: string;
  useDefaults?: boolean;
}

export interface GetInstancesArgs {
  userGroup?: string;
  marketId?: string;
}

export const getInstancesBySchema = (args: GetSchemaValuesArgs) =>
  configProxyApiInstance.get(`/${rootPath}/schemas/${args.schemaName}/values`, {
    params: {
      market_id: args.marketId,
      config_user_group: args.userGroup,
      use_defaults: args.useDefaults,
    },
  });

export const getInstances = (args: GetInstancesArgs) =>
  configProxyApiInstance.get<string[]>(`/${rootPath}/values`, {
    params: { market_id: args.marketId, config_user_group: args.userGroup },
  });

type UpdateModuleConfigValuesParams = {
  requestBody: any;
  schemaName: string;
  configUserGroup?: string | null;
  marketId?: string | null;
};
export const updateModuleConfigValues = ({
  requestBody,
  schemaName,
  configUserGroup,
  marketId,
}: UpdateModuleConfigValuesParams) =>
  configProxyApiInstance.put(`/${rootPath}/values`, requestBody, {
    params: {
      config_name: schemaName,
      config_user_group: configUserGroup,
      market_id: marketId,
    },
  });
export const useCreateModuleConfigSchema = (requestBody) =>
  configProxyApiInstance.put(`/${rootPath}/schemas`, requestBody);

export const updateModuleConfigSchemaWithMigration = ({ requestBody }) =>
  configProxyApiInstance.put(
    `/${rootPath}/update-schema-with-instance-migration`,
    requestBody
  );

type DeleteModuleConfigValuesParams = {
  schema_name: string;
  config_user_group: string;
  market_id?: string | null;
};
export const deleteModuleConfigValue = (
  params: DeleteModuleConfigValuesParams
) => configProxyApiInstance.delete(`/${rootPath}/values`, { params });

export const deleteModuleConfigSchema = (schemaName: string) =>
  configProxyApiInstance.delete(`/${rootPath}/schemas/${schemaName}`);
