import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  styled,
} from '@mui/material';

import { toCapitalize } from '../../../utils/toCapitalize';
import { Wrapper } from './generic';

const stringFormat = (str, delimiter = '_') => {
  const arr = str.split(delimiter);

  return [...arr.map((a) => toCapitalize(a))].join(' ');
};

const Stat = ({ title, value }) => (
  <Box>
    <b>{title}: </b>
    {value}
  </Box>
);

const Subtitle = styled(Box)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryMaptualListFilteringColor,
  fontSize: 14,
}));
const BasketList = styled('ul')(() => ({
  margin: '5px 5px 0 10px',
  paddingLeft: '10px',
}));

const BasketStat = ({ title, value }) => (
  <Box>
    <i>{title}: </i>
    {value}
  </Box>
);

const BasketWrapper = styled(Box)(() => ({
  marginTop: 2,
  marginBottom: 2,
}));
const Basket = ({ basket }) => {
  const {
    metricsScoringWeight,
    paramText,
    productFriendlyName,
    basketScoringWeight,
  } = basket;
  return (
    <BasketWrapper>
      <Box>
        <Subtitle>{paramText}</Subtitle>
        <Typography variant="h4">{productFriendlyName}</Typography>
      </Box>
      <Box>
        <BasketStat title="Basket Scoring Weight" value={basketScoringWeight} />
        {metricsScoringWeight ? (
          <Box>
            <BasketStat title="Scoring Weights" />
            <BasketList>
              {Object.entries(metricsScoringWeight).map(([key, val], i) => (
                <li key={key + i}>
                  <b>{stringFormat(key)}: </b>
                  {val}
                </li>
              ))}
            </BasketList>
          </Box>
        ) : null}
      </Box>
    </BasketWrapper>
  );
};

const ObjectiveCard = styled(Card)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.contentCardBackgroundColor,
  borderColor: themeColors.contentCardBorderColor,
  padding: 10,
  flexGrow: 1,
}));

const ObjectiveCardHeader = styled(CardHeader)(
  ({ theme: { themeColors } }) => ({
    '.MuiCardHeader-subheader': {
      color: themeColors.primaryMaptualListFilteringColor,
    },
    textAlign: 'left',
  })
);

const ObjectiveCardContent = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gridGap: '10px',
}));

const Objective = ({ objective, name }) => (
  <ObjectiveCard variant="outlined">
    <ObjectiveCardHeader
      title={`${objective.fullDisplayName}: ${name}`}
      subheader={objective.objectiveId}
    />
    <ObjectiveCardContent>
      {objective.params.products.map((p, i) => (
        <Basket basket={p} key={`${i}basket`} />
      ))}
    </ObjectiveCardContent>
  </ObjectiveCard>
);

const Metadata = ({ metadata }) => {
  if (Object.keys(metadata || {}).length === 0) {
    return null;
  }
  return (
    <Box sx={{ marginTop: 2, marginBottom: 2 }}>
      <Stat
        title="Created"
        value={new Date(metadata.creationDate * 1000).toDateString()}
      />
      <Stat
        title="Last Updated"
        value={new Date(metadata.lastUpdated * 1000).toDateString()}
      />
      <Stat
        title="Project Import ID"
        value={
          metadata.projectImportId.length > 0
            ? metadata.projectImportId
            : 'None'
        }
      />
      <Stat
        title="Product Line"
        value={`${metadata.productLine} (${metadata.productLineId})`}
      />
      <Stat title="Market" value={metadata.market} />
    </Box>
  );
};

const ObjectiveCardWrapper = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridGap: 20,
  flexWrap: 'wrap',
}));

export const ProjectSummary = ({ project, objectiveNames }) => (
  <Wrapper>
    <Typography variant="h2">Metadata</Typography>
    <Metadata metadata={project.metadata} />
    <Typography variant="h2">Objectives</Typography>
    <ObjectiveCardWrapper>
      {project.objectives.map((obj, i) => (
        <Objective
          objective={obj}
          key={`obj-${i}`}
          name={objectiveNames[obj.objectiveId]}
        />
      ))}
    </ObjectiveCardWrapper>
  </Wrapper>
);
