import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { FeatureToggleView } from './featureToggleView';
import { ModalStates } from './modalStates';
import {
  createNewToggle,
  deleteFeatureToggle,
  disableForAllGroups,
  enableForAllGroups,
  getFeatureToggleData,
  toggleMultiple,
} from '../../request/featureToggleRequests';
import ToggleChangeModal from './toggleChangeModal';
import { ToggleDeleteModal } from './deleteToggleModal';

const fetchFeatureToggleData = async () => {
  try {
    const response = await getFeatureToggleData();
    return JSON.parse(response.data);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('[ERROR] fetchFeatureToggleData');
  }
  return {};
};

export const FeatureToggleViewProvider = () => {
  const { isLoading, data: sampleData } = useQuery(
    'feature-toggles-v2',
    fetchFeatureToggleData
  );

  const [toggles, setToggles] = useState();
  const [modal, setModalState] = useState(ModalStates.CLOSED);
  const [modalConfirmCallback, setModalConfirmCallback] = useState(() => {});
  const [clientGroups, setClientGroups] = useState();

  useEffect(() => {
    if (!isLoading && sampleData) {
      setToggles(sampleData.toggles);
      setClientGroups(sampleData.clientGroups);
    }
  }, [isLoading, sampleData]);

  const postNewToggle = async (newToggle) => {
    const toggle = newToggle;
    toggle.enabledFor = toggle.isEnabled ? sampleData.clientGroups : [];
    setToggles((prevToggles) => [toggle, ...prevToggles]);
    await createNewToggle({
      name: toggle.name,
      description: toggle.description,
      initialState: toggle.isEnabled,
      type: toggle.type,
    });
  };

  const onToggleChange = (changedToggles) => {
    setModalState(ModalStates.TOGGLE_CHANGE);
    setModalConfirmCallback(() => async () => {
      const newToggles = toggles.map((toggle) => {
        const changedToggle = changedToggles.find(
          (t) => t.name === toggle.name
        );
        if (changedToggle) {
          toggleMultiple({
            names: [changedToggle.name],
            clientGroup: changedToggle.clientGroup,
          });
          const newEnabledFor = changedToggle.isEnabled
            ? [...toggle.enabledFor, changedToggle.clientGroup]
            : toggle.enabledFor.filter(
                (clientGroup) => clientGroup !== changedToggle.clientGroup
              );
          return { ...toggle, enabledFor: newEnabledFor };
        }
        return toggle;
      });
      setToggles(newToggles);
    });
  };

  const deleteToggle = async (toggleName) => {
    setModalState(ModalStates.DELETE);
    setModalConfirmCallback(() => async () => {
      setToggles((prevToggles) => {
        const newToggles = prevToggles.filter(
          (item) => item.name !== toggleName
        );
        return newToggles;
      });
      await deleteFeatureToggle(toggleName);
    });
  };

  const onEnableToggleForAllClientGroups = (toggleName) => {
    setModalState(ModalStates.ENABLE_ALL);
    setModalConfirmCallback(() => async () => {
      const newToggles = toggles.map((toggle) => {
        if (toggle.name === toggleName) {
          return { ...toggle, enabledFor: sampleData.clientGroups };
        }
        return toggle;
      });
      await enableForAllGroups(toggleName);
      setToggles(newToggles);
    });
  };

  const onDisableToggleForAllClientGroups = async (toggleName) => {
    setModalState(ModalStates.DISABLE_ALL);
    setModalConfirmCallback(() => async () => {
      const newToggles = toggles.map((toggle) => {
        if (toggle.name === toggleName) {
          return { ...toggle, enabledFor: [] };
        }
        return toggle;
      });
      await disableForAllGroups(toggleName);
      setToggles(newToggles);
    });
  };

  const handleCloseModal = () => {
    setModalState(ModalStates.CLOSED);
  };

  if (isLoading || !toggles || !clientGroups) return null;
  return (
    <>
      <ToggleChangeModal
        isModalOpen={modal === ModalStates.TOGGLE_CHANGE}
        onClose={handleCloseModal}
        onConfirm={modalConfirmCallback}
        title="Change Feature Toggle State?"
      />
      <ToggleChangeModal
        isModalOpen={modal === ModalStates.ENABLE_ALL}
        onClose={handleCloseModal}
        onConfirm={modalConfirmCallback}
        title="Enable Feature Toggle For All Usergroups?"
      />
      <ToggleChangeModal
        isModalOpen={modal === ModalStates.DISABLE_ALL}
        onClose={handleCloseModal}
        onConfirm={modalConfirmCallback}
        title="Disable Feature Toggle For All Usergroups?"
      />
      <ToggleDeleteModal
        isModalOpen={modal === ModalStates.DELETE}
        onClose={handleCloseModal}
        onConfirm={modalConfirmCallback}
      />
      <FeatureToggleView
        clientGroups={clientGroups}
        toggles={toggles}
        onToggleChange={onToggleChange}
        onSave={postNewToggle}
        onToggleDelete={deleteToggle}
        onEnableToggleForAllClientGroups={onEnableToggleForAllClientGroups}
        onDisableToggleForAllClientGroups={onDisableToggleForAllClientGroups}
      />
    </>
  );
};
