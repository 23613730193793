import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';

import {
  ModalDisplay,
  ModalFooter,
  ModalHeader,
} from '../../generic/modalDisplay';
import { ModalBody, StyledButton } from '../styledComponents';
import { StyledFormTextField } from '../../configview/components/form/FormTextField';
import { ROUTES } from '../../../constants';

export const SchemaCreationModal = ({ isOpen, onClose = () => {} }) => {
  const { formState, control, handleSubmit } = useForm();
  const history = useHistory();
  const onCreate = handleSubmit((formData) => {
    onClose();
    history.push(
      `${ROUTES.ADMIN}${ROUTES.APP_CONFIG}${ROUTES.CREATE_SCHEMA}`,
      formData
    );
  });
  const noSpaces = (value) =>
    !/\s/.test(value) || 'String must not contain spaces';

  return (
    <ModalDisplay isOpen={isOpen}>
      <ModalHeader onClose={onClose}>New Config</ModalHeader>
      <ModalBody>
        <StyledFormTextField
          name="name"
          InputLabelProps={{ shrink: true }}
          label="Config Name"
          control={control}
          rules={{ required: true, validate: noSpaces }}
          placeholder="Add Config Name"
          notched
          fullWidth
          required
          focused
          helperText="Must be camel case; no spaces"
        />
        <StyledFormTextField
          name="description"
          InputLabelProps={{ shrink: true }}
          label="Config Description"
          control={control}
          rules={{ required: true }}
          placeholder="Add Description"
          notched
          fullWidth
          focused
          required
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
        <StyledButton
          onClick={onCreate}
          variant="contained"
          disabled={!formState.isValid}
        >
          Create Config
        </StyledButton>
      </ModalFooter>
    </ModalDisplay>
  );
};
