import { useEffect, useState, useContext, useMemo } from 'react';
import { styled } from '@mui/material';
import { NewProjectContext } from '../newProjectContext';
import { StyledTextField } from '../styles/styledComponents';

const validateBasketScoringWeightRange = (scoringWeight) =>
  scoringWeight >= 0 && scoringWeight <= 100;

const StyledScoringWeightField = styled(StyledTextField)(() => ({
  flex: 1,
  minWidth: '35%',
}));

export const BasketScoringWeightField = ({
  basketConfig,
  handleOnChange,
  setBasketScoringWeightError = () => {},
  basketScoringWeightFieldError,
  errorText = '',
  setIsError,
}) => {
  const { objectiveBeingEdited, baskets } = useContext(NewProjectContext);
  const hasConflictingBasketWeights = useMemo(
    () =>
      baskets.some(
        (basket) =>
          basket.basketScoringWeight === basketConfig.basketScoringWeight &&
          basket.uuid !== basketConfig.uuid
      ),
    [baskets, basketConfig]
  );
  const [scoringWeight, setScoringWeight] = useState(
    (basketConfig.basketScoringWeight || 0) * 10
  );

  useEffect(() => {
    if (objectiveBeingEdited) {
      setScoringWeight(basketConfig.basketScoringWeight * 10);
    }
  }, [basketConfig]);

  const handleScoringWeightChange = (newValue) => {
    const isError = handleError(newValue);
    setIsError(isError);
    setScoringWeight(newValue);
    const newValueFloat = parseFloat(newValue / 10);
    handleOnChange({
      ...basketConfig,
      basketScoringWeight: !Number.isNaN(newValueFloat) ? newValueFloat : 0,
    });
  };

  const handleError = (basketScoringWeight) => {
    const isError =
      !validateBasketScoringWeightRange(basketScoringWeight) ||
      hasConflictingBasketWeights;
    setBasketScoringWeightError(isError);
    return isError;
  };
  return (
    <StyledScoringWeightField
      focused
      label="Basket Scoring Weight (0-100)"
      value={scoringWeight}
      isError={handleError(scoringWeight)}
      error={handleError(scoringWeight)}
      onChange={(e) => {
        handleScoringWeightChange(e.target.value);
      }}
      helperText={
        (!hasConflictingBasketWeights &&
          basketScoringWeightFieldError &&
          errorText) ||
        (hasConflictingBasketWeights && 'Must be unique for each basket')
      }
      InputProps={{
        endAdornment: <span>%</span>,
      }}
    />
  );
};
