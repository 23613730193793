import { styled, Typography, Box } from '@mui/material';
import Sell from '@mui/icons-material/Sell';
import { InsightsCard } from './insightsCard/insightsCard';

const TagIconView = styled((props) => <Sell {...props} />)`
  height: 16px;
  width: 16px;
  margin-top: 2px;
`;

const FlexWrapper = styled(Box)(() => ({
  display: 'flex',
}));
const Topic = styled(FlexWrapper)(({ theme: { themeColors } }) => ({
  gridGap: 10,
  alignItems: 'center',
  svg: {
    height: 16,
    width: 16,
    color: themeColors.textButtonColor,
  },
}));
const ErrorTopic = styled(Topic)(({ theme: { themeColors } }) => ({
  p: {
    color: themeColors.primaryMaptualListFilteringColor,
  },
}));
const TopicRow = styled(FlexWrapper)(() => ({
  gridGap: 16,
  flexWrap: 'wrap',
}));

export const InsightsTopics = ({ maptualTopTopics, isLoading }) => (
  <InsightsCard title="Top Influential Topics" minimal isLoading={isLoading}>
    <TopicRow>
      {maptualTopTopics &&
        maptualTopTopics.map((topic) => (
          <Topic key={topic.messageName}>
            <TagIconView />
            <Typography variant="body2">{topic.messageName}</Typography>
          </Topic>
        ))}
      {!maptualTopTopics ||
        (maptualTopTopics.length === 0 && (
          <ErrorTopic>
            <Typography variant="body2">
              Not enough data available to generate topics for this HCP.
            </Typography>
          </ErrorTopic>
        ))}
    </TopicRow>
  </InsightsCard>
);
