import {
  MaterialDesignContent,
  SnackbarProvider as NotiStackSnackbarProvider,
  useSnackbar,
} from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Fade, styled } from '@mui/material';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    '&.notistack-MuiContent': {
      flexWrap: 'nowrap',
      textAlign: 'left',
    },
    '.MuiIconButton-root': {
      color: `${theme.themeColors.alertPrimaryInfoIconColor}`,
    },
    '&.notistack-MuiContent-info': {
      backgroundColor: theme.themeColors.alertPrimaryInfoColor,
    },
    '&.notistack-MuiContent-success': {
      backgroundColor: theme.themeColors.alertPrimarySuccessFillColor,
      '.MuiIconButton-root': {
        color: `${theme.themeColors.alertPrimarySuccessContentColor}`,
      },
      color: `${theme.themeColors.alertPrimarySuccessContentColor}`,
    },
  })
);

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <CloseIcon />
    </IconButton>
  );
}

const closeAction = (snackbarKey) => (
  <SnackbarCloseButton snackbarKey={snackbarKey} />
);

export const SnackbarProvider = ({ children }) => (
  <NotiStackSnackbarProvider
    maxSnack={1}
    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    action={closeAction}
    TransitionComponent={Fade}
    Components={{
      info: StyledMaterialDesignContent,
      success: StyledMaterialDesignContent,
      error: StyledMaterialDesignContent,
    }}
  >
    {children}
  </NotiStackSnackbarProvider>
);
