import { useContext } from 'react';
import { styled, Button } from '@mui/material';
import { Box } from '@mui/system';

import { EntityOverviewContext } from '../data/entityOverviewContext';
import { CadenceSelectorTabs } from './tableControls/CadenceSelectorTabs';
import { EntitySubtypeSelectorTabs } from './tableControls/EntitySubtypeSelectorTabs';
import { ObjectiveSelector } from './tableControls/ObjectiveSelector';
import { FilterSelector } from './tableControls/FilterSelector';
import { FilterChips } from './tableControls/FilterChips';
import { Search } from './tableControls/Search';
import { CSVExport } from './tableControls/CSVExport';
import { TimeFrameSelector } from './tableControls/TimeFrameSelector';

const HeaderContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
});

const ControlsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  textAlign: 'left',
});

const StyledControlsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  alignItems: 'center',
  flexWrap: 'wrap',
});

const StyledControls = styled(Box)({
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'row',
});

const FilterWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  height: 40,
});

const StyledFilterControls = styled(Box)({
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const ClearFiltersButton = styled(Button)({
  whiteSpace: 'nowrap',
});

export const EntityControls = () => {
  const { metadata, filters, setFilters } = useContext(EntityOverviewContext);

  const handleClearFilters = () => {
    setFilters({});
  };

  return (
    <HeaderContainer>
      <ControlsContainer>
        <StyledControls>
          <StyledControlsBox>
            <ObjectiveSelector />
            <CadenceSelectorTabs />
            <TimeFrameSelector />
            {metadata?.entitySubtypes?.length > 0 && (
              <EntitySubtypeSelectorTabs />
            )}
          </StyledControlsBox>

          <FilterWrapper>
            <Search />
            <FilterSelector />
            <CSVExport />
          </FilterWrapper>
        </StyledControls>
      </ControlsContainer>
      {Object.keys(filters).length > 0 && (
        <StyledFilterControls>
          <FilterChips />
          <ClearFiltersButton variant="utility" onClick={handleClearFilters}>
            Clear Filters
          </ClearFiltersButton>
        </StyledFilterControls>
      )}
    </HeaderContainer>
  );
};
