import { styled } from '@mui/material';
import { useCallPlanContext } from '@odaia/shared-components/src/callPlan';
import { CADENCE } from '@odaia/domain/src';
import { CallPlanRowProvider } from './callPlanRowProvider';
import { InfiniteEntitiesTable } from '../../../../shared/entitiesTable/InfiniteEntitiesTable';

const TableWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
}));

const stickyColumns = [
  {
    id: 'rank',
    label: 'Rank',
    message: 'HCPs are ranked by importance to call.',
    visible: true,
    width: 40,
  },
  {
    id: 'name',
    label: 'Name',
    visible: true,
    width: 150,
  },
];

const nonStickyColumns = [
  {
    id: 'address',
    subLabel: 'Address',
    visible: true,
    width: 175,
  },
  {
    id: 'productScore',
    subLabel: 'Curated Product',
    message: 'The product we recommend focusing on with the HCP.',
    visible: true,
    width: 150,
  },
];

const activityColumns = [
  {
    id: 'curatedHcpsCalled',
    subLabel: '% Called When Curated',
    visible: true,
    width: 140,
  },
  {
    id: 'totalCalls',
    subLabel: 'Total Calls',
    visible: true,
    width: 70,
  },
  {
    id: 'segments',
    subLabel: 'Segments',
    visible: true,
    width: 150,
  },
  {
    id: 'segmentsCustomer',
    subLabel: 'Customer Segments',
    visible: true,
    width: 150,
  },
];

export const CallPlanTableProvider = ({
  openEntityDrawer,
}: {
  openEntityDrawer: (entityId: string) => void;
}) => {
  const {
    latestCurationTimestamp,
    productLineId,
    projectTargetProducts,
    projectId,
    isTimestampLoading,
    isProductsLoading,
    sfRegionId,
  } = useCallPlanContext();

  if (isTimestampLoading || isProductsLoading) {
    return null;
  }

  return (
    <TableWrapper>
      <InfiniteEntitiesTable
        regionId={sfRegionId}
        objectiveId=""
        timestamp={latestCurationTimestamp}
        curatedType={['curated']}
        cadence={CADENCE.WEEK}
        hcpView
        pageSize={10}
        RowContentComponent={CallPlanRowProvider}
        stickyColumns={stickyColumns}
        nonStickyColumns={nonStickyColumns}
        metricColumns={[]}
        activityColumns={activityColumns}
        activitySubtitle="Quarter to Date"
        activityCadence={CADENCE.DAY}
        productLineId={productLineId}
        projectTargetProducts={projectTargetProducts}
        projectId={projectId}
        showProduct
        fullHeight
        onRowClick={openEntityDrawer}
      />
    </TableWrapper>
  );
};
