import Typography from '@mui/material/Typography';

import { styled } from '@mui/material';
import Switch from '@mui/material/Switch';
import { useTheme } from '@mui/styles';

const SwitchContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const FilterSwitchSelection = ({
  selectedFilters,
  setSelectedFilters,
  switchDescription,
  switchId,
  switchLabel,
}) => {
  const { themeColors } = useTheme();
  return (
    <SwitchContainer>
      <Typography
        noWrap
        variant="body1"
        color={themeColors.segmentSelectionCaptionColor}
      >
        {switchDescription}
      </Typography>
      <Switch
        checked={selectedFilters[switchId]}
        data-testid="test-filter-switch"
        onChange={(_, val) => {
          if (val) {
            setSelectedFilters({
              ...selectedFilters,
              [switchId]: { id: val, label: switchLabel },
            });
          } else {
            const newFilter = { ...selectedFilters };
            delete newFilter[switchId];
            setSelectedFilters(newFilter);
          }
        }}
      />
    </SwitchContainer>
  );
};

export default FilterSwitchSelection;
