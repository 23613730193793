import { useCallback, useState } from 'react';
import { styled, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ConfigTabs, DEFAULT_TABS } from './configTabs';
import { SegmentsViewProvider } from './segmentsViewProvider';
import { MarketsViewProvider } from './marketsViewProvider';
import { MetricsViewProvider } from './metricsViewProvider';
import { ObjectiveTypesProvider } from './objectiveTypesProvider';
import { ConfigAlert } from './configAlert';
import { ProductTreePage } from './productTree/productTreePage';
import { FeedbackButton } from './feedbackButton';

const ConfigMainContainer = styled('div')(({ theme: { themeColors } }) => ({
  padding: '30px 70px 30px 40px',
  height: 'calc(95vh - 55px)',
  color: themeColors.offWhiteFontColor,
}));

export const ConfigViewTitle = styled('div')(() => ({
  textAlign: 'left',
  fontSize: 20.25,
  fontWeight: 600,
  paddingBottom: 24,
}));

export const ConfigViewContainer = styled('div')(
  ({ theme: { themeColors } }) => ({
    width: '100%',
    height: '100%',
    background: themeColors.contentCardAltBackgroundColor,
    border: `1px solid ${themeColors.borderLowContrast}`,
    borderRadius: '10px',
    display: 'flex',
    position: 'relative',
  })
);

const ConfigSectionsContainer = styled('div')(({ theme: { themeColors } }) => ({
  width: '208px',
  borderRight: `1px solid ${themeColors.borderLowContrast}`,
}));

const ConfigSectionViewContainer = styled('div')(() => ({
  flex: 1,
}));

const ConfigUserGroupInfo = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 4,
  alignItems: 'start',
  padding: 24,
});
export const ConfigView = ({ profile }) => {
  const [currentTabTitle, setCurrentTabTitle] = useState('');
  const initializeTabSelection = useCallback((tab) => {
    setCurrentTabTitle(tab);
  });
  return (
    <ConfigMainContainer>
      <FeedbackButton />
      <ConfigViewTitle> User Group Configurations</ConfigViewTitle>
      <ConfigViewContainer>
        <ConfigSectionsContainer>
          <ConfigUserGroupInfo>
            <Typography variant="h4">{profile.userGroup}</Typography>
            <Typography variant="h6">Placeholder</Typography>
          </ConfigUserGroupInfo>
          <ConfigTabs
            initializeTabSelection={initializeTabSelection}
            handleTabChange={(tabText) => {
              setCurrentTabTitle(tabText);
            }}
          />
        </ConfigSectionsContainer>
        <ConfigSectionViewContainer>
          {currentTabTitle === DEFAULT_TABS.SEGMENTS && (
            <SegmentsViewProvider />
          )}
          {currentTabTitle === DEFAULT_TABS.MARKETS && <MarketsViewProvider />}
          {currentTabTitle === DEFAULT_TABS.METRICS && <MetricsViewProvider />}
          {currentTabTitle === DEFAULT_TABS.TYPES && <ObjectiveTypesProvider />}
          {currentTabTitle === DEFAULT_TABS.PRODUCT_TREE && <ProductTreePage />}
        </ConfigSectionViewContainer>
        <ConfigAlert />
      </ConfigViewContainer>
    </ConfigMainContainer>
  );
};
