import { useQueryClient, useQuery } from 'react-query';
import { styled, Box, FormControl, InputLabel, Select } from '@mui/material';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { getRecentProjects } from '../../../../request/projectRequests';
import { addCsvDeliveryProject } from '../../../../request/csvDeliveryDashboard';

const FlexColumnBase = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const SelectorContainer = styled(FlexColumnBase)({
  gap: 16,
});

const ProjectDetails = styled(FlexColumnBase)({
  gap: 8,
  textAlign: 'left',
  justifyContent: 'center',
});

const ProjectName = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
});

const UserGroup = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
}));

const StyledSelect = styled(Select)({
  width: 350,
});

export const CsvDeliveryProjectSelector = ({ userGroup, businessUnit }) => {
  const queryClient = useQueryClient();
  const { data: recentProjects = [] } = useQuery(
    `projects-${userGroup}-${businessUnit}`,
    async () => {
      const response = await getRecentProjects(userGroup, businessUnit);
      return response.data.recentProjects;
    }
  );

  const handleChange = async (event) => {
    const selectedProjectId = event.target.value;
    const selectedProject = recentProjects.find(
      (project) => project.projectId === selectedProjectId
    );
    await addCsvDeliveryProject(
      userGroup,
      businessUnit,
      selectedProjectId,
      selectedProject.projectName
    );
    await queryClient.refetchQueries('deliveryStatuses');
  };

  return (
    <SelectorContainer>
      <ProjectDetails>
        <ProjectName variant="h5">{businessUnit}</ProjectName>
        <UserGroup variant="body1">{userGroup}</UserGroup>
      </ProjectDetails>
      <FormControl>
        <InputLabel id="select-project-label">
          Select Project for {businessUnit}
        </InputLabel>
        <StyledSelect
          labelId="select-project-label"
          label={`Select Project for ${businessUnit}`}
          onChange={handleChange}
        >
          {recentProjects.map((project) => (
            <MenuItem
              key={project.projectId}
              value={project.projectId}
              projectName={project.projectName}
            >
              {project.projectName}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </SelectorContainer>
  );
};
