import { useEffect, useState } from 'react';
import prettier from 'prettier/standalone';
import parserBabel from 'prettier/parser-babel';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { StyledHelperText } from '../appConfig/styledComponents';

export const formatJsonString = (jsonString) => {
  try {
    return prettier.format(jsonString, {
      parser: 'json',
      plugins: [parserBabel],
    });
  } catch (e) {
    return jsonString;
  }
};

const StyledTextArea = styled('textarea')(
  ({ error, theme: { themeColors }, styleOverrides }) => ({
    width: '100%',
    maxWidth: '100%',
    minHeight: '200px',
    height: '100%',
    verticalAlign: 'top',
    backgroundColor: `${themeColors.modalBackgroundColor}`,
    color: `${themeColors.jsonInputTextColor}`,
    border: error
      ? `1px solid ${themeColors.danger60}`
      : `1px solid ${themeColors.borderLowContrast}`,
    borderRadius: '4px',
    paddingLeft: '16px',
    resize: 'none',
    ...styleOverrides,
  })
);

const StyledErrorText = styled('div')(({ theme: { themeColors } }) => ({
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  color: `${themeColors.danger60}`,
  marginLeft: 8,
}));

const StyledHeader = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
  marginBottom: 4,
  lineHeight: '135%',
});

const StyledSubheader = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: 16,
  color: themeColors.neutral60,
  marginBottom: 24,
}));

const JsonInputWrapper = styled('div')({
  flex: 1,
});

export const JsonInputSection = ({
  value,
  header,
  subheader,
  onChange,
  onBlur,
  error,
  helperText,
  useHeader = true,
  footerText,
  ...textFieldProps
}) => {
  const [jsonInput, setJsonInput] = useState(value);

  useEffect(() => {
    setJsonInput(value);
  }, [value]);

  const handleBlur = (e) => {
    setJsonInput(jsonInput);
    onBlur(e);
  };

  const handleChange = (event) => {
    setJsonInput(event.target.value);
    onChange(event);
  };

  return (
    <JsonInputWrapper>
      {useHeader && (
        <div>
          <StyledHeader>{header}</StyledHeader>
          <StyledSubheader>{subheader}</StyledSubheader>{' '}
        </div>
      )}

      <StyledTextArea
        {...textFieldProps}
        value={jsonInput}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="Enter JSON here"
        error={error}
      />
      {error && (
        <StyledErrorText>{`Invalid JSON: ${helperText}`}</StyledErrorText>
      )}
      {!error && footerText && (
        <StyledHelperText>{footerText}</StyledHelperText>
      )}
    </JsonInputWrapper>
  );
};
