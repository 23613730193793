import TuneIcon from '@mui/icons-material/Tune';
import { useState, useContext, useEffect, JSX } from 'react';
import { styled, Badge, Button, Box, FormGroup, Chip } from '@mui/material';
import Typography from '@mui/material/Typography';

import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Check,
  ErrorOutlineOutlined,
  History,
  Timeline,
} from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import { AutocompleteCombobox } from '@odaia/ui/src/components/autocompleteCombobox';
import { EntityOverviewContext } from '../../data/entityOverviewContext';
import {
  ModalDisplay,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '../../../../components/generic/modalDisplay';
import { formatEntityName } from '../../../../utils/formatEntityName';
import {
  countMultiselectFilters,
  createMultiselectFilters,
} from '../../../../components/fieldView/multiSelectFiltersObject';
import { trackEntityOverviewFilterSelection } from '../../../../trackers/mixpanel';
import { formatMixpanelFilters } from '../tableHelpers';

const OpenModalButton = styled(Button)(({ theme: { themeColors } }) => ({
  display: 'flex',
  minWidth: 'fit-content',
  padding: '8px 12px',
  '.MuiButton-startIcon': {
    margin: 0,
  },
  '& .MuiSvgIcon-root': {
    color: themeColors.buttonContentColor,
    width: 24,
    height: 24,
  },
}));

const StyledBadge = styled(Badge)(({ theme: { themeColors } }) => ({
  marginRight: 0,
  height: '100%',
  '& .MuiBadge-badge': {
    backgroundColor: themeColors.notificationBadgeSurface,
    color: themeColors.notificationBadgeContent,
  },
}));

const StyledModalBody = styled(ModalBody)(() => ({
  padding: 24,
}));

const SectionTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
  textTransform: 'capitalize',
}));

const FilterSection = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
}));

const FilterDescription = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tertiaryColor,
  marginBottom: 28,
}));

const CheckboxContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridGap: 9,
  alignItems: 'flex-start',

  '& .MuiFormControlLabel-label': {
    paddingTop: 1.5,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme: { themeColors } }) => ({
    marginLeft: 0,
    color: themeColors.primaryTextColor,
    '.MuiCheckbox-root': {
      padding: 0,
      marginRight: 9,
      alignSelf: 'start',
    },
    paddingTop: 0,
  })
);

const StyledCheckbox = styled(Checkbox)(({ theme: { themeColors } }) => ({
  '&.Mui-checked': {
    color: themeColors.checkboxSelected,
  },
}));

const Divider = styled(Box)(({ theme: { themeColors } }) => ({
  margin: 8,
  borderBottom: `1px solid ${themeColors.dividerPrimaryColor}`,
}));

const TERRITORY_CUTOFF = 12;

const IconFormControlLabel = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  svg: {
    color: themeColors.listIconColor,
    fontSize: 20,
  },
}));

const ChipArray = styled(FormGroup)(() => ({
  display: 'flex',
  gridGap: 10,
  flexDirection: 'row',
  marginBottom: 12,
}));

const StyledPrimaryChip = styled(Chip)(
  ({ theme: { themeColors }, selected }) => ({
    '&.MuiChip-colorPrimary': {
      backgroundColor: themeColors.chipChoiceActiveSurface,
    },
    backgroundColor: selected
      ? themeColors.chipChoiceActiveSurface
      : themeColors.chipChoiceDefaultSurface,
    color: selected
      ? themeColors.chipChoiceActiveContent
      : themeColors.chipChoiceDefaultContent,
    borderColor: selected
      ? themeColors.chipChoiceActiveBorder
      : themeColors.chipChoiceDefaultBorder,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '22px',
    '&:hover': {
      opacity: 1,
      backgroundColor: themeColors.chipChoiceHoverSurface,
      color: themeColors.chipChoiceHoverContent,
      borderColor: themeColors.chipChoiceHoverBorder,
    },
  })
);

const LastContactedNote = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  gap: 4,
  alignItems: 'center',
  color: themeColors.primaryMaptualListFilteringColor,
  '.MuiSvgIcon-root': {
    color: themeColors.tertiaryColor,
    width: 20,
    height: 20,
  },
}));

const createFormLabel = (
  label: string,
  filterId: string
): JSX.Element | string => {
  switch (filterId) {
    case 'behaviouralSegmentTypes':
      if (label === 'Recent') {
        return (
          <IconFormControlLabel>
            <History />
            {label}
          </IconFormControlLabel>
        );
      }
      if (label === 'Predicted') {
        return (
          <IconFormControlLabel>
            <Timeline />
            {label}
          </IconFormControlLabel>
        );
      }
      return label;
    default:
      return label;
  }
};

interface BehaviouralSegmentDescriptionType {
  template: string;
  params: Record<string, string>;
}

export const formatBehaviouralSegmentDescription = (
  description: BehaviouralSegmentDescriptionType
) => {
  const { template, params } = description;
  if (!params || !template) return template ?? null;

  let str = template;
  (template.match(/{(.+?)}/g) || []).forEach((key: string) => {
    const searchValue = new RegExp(key, 'g');

    if (key === '{metric}') {
      str = str.replace(
        searchValue,
        `<b>${params[key.replace('{', '').replace('}', '')]}</b>`
      );
    } else {
      str = str.replace(
        searchValue,
        params[key.replace('{', '').replace('}', '')]
      );
    }
  });
  return str;
};

const BehaviouralSegmentDescription = ({
  segmentDescriptions,
  objectiveId,
}: {
  segmentDescriptions: Record<string, BehaviouralSegmentDescriptionType>;
  objectiveId: string;
}) => (
  <FilterDescription
    variant="body2"
    ml="32px"
    dangerouslySetInnerHTML={{
      __html: formatBehaviouralSegmentDescription(
        segmentDescriptions[objectiveId]
      ),
    }}
  />
);

export const FilterSelector = () => {
  const { entityType, metadata, filters, setFilters, objectiveId } = useContext(
    EntityOverviewContext
  );

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(filters);

  useEffect(() => {
    if (filters) {
      setSelectedFilters(filters);
    }
  }, [openFilterModal, filters]);

  const handleClickOpenFilterModal = () => {
    setOpenFilterModal(true);
  };
  const handleCloseFilterModal = () => {
    setOpenFilterModal(false);
  };

  const handleApplyFilters = () => {
    trackEntityOverviewFilterSelection(
      formatMixpanelFilters(selectedFilters),
      entityType
    );

    setFilters(selectedFilters);
    handleCloseFilterModal();
  };

  const lastContactedChipIsSelected = (option) => {
    const isOffSelected = !selectedFilters?.lastContacted?.id && !option.id;
    return selectedFilters?.lastContacted?.id === option.id || isOffSelected;
  };

  return (
    <>
      <StyledBadge badgeContent={countMultiselectFilters(filters)}>
        <OpenModalButton
          data-testid="test-open-filter-button"
          aria-label="openFilterButton"
          variant="outlined"
          onClick={handleClickOpenFilterModal}
          startIcon={<TuneIcon />}
        />
      </StyledBadge>

      <ModalDisplay onClose={handleCloseFilterModal} isOpen={openFilterModal}>
        <ModalHeader onClose={handleCloseFilterModal}>
          Filter Accounts
        </ModalHeader>

        <StyledModalBody>
          {metadata?.activityFilters?.length > 0 && (
            <>
              <FilterSection>
                <SectionTitle variant="h5">Not contacted</SectionTitle>
                <FilterDescription variant="body2">
                  Accounts you have not seen in the last...
                </FilterDescription>
                <ChipArray>
                  {metadata.activityFilters.map((option) => (
                    <StyledPrimaryChip
                      data-testid="test-filter-last-n-days"
                      key={`last-contacted-${option.label}`}
                      label={option.label}
                      variant={
                        lastContactedChipIsSelected(option)
                          ? 'primary'
                          : 'outlined'
                      }
                      color={
                        lastContactedChipIsSelected(option)
                          ? 'primary'
                          : 'default'
                      }
                      icon={
                        lastContactedChipIsSelected(option) ? <Check /> : null
                      }
                      onClick={() => {
                        if (option.id) {
                          setSelectedFilters({
                            ...selectedFilters,
                            lastContacted: option,
                          });
                        } else {
                          const newFilter = { ...selectedFilters };
                          delete newFilter.lastContacted;
                          setSelectedFilters(newFilter);
                        }
                      }}
                    />
                  ))}
                </ChipArray>
                <LastContactedNote>
                  <ErrorOutlineOutlined />
                  <Typography variant="body2">
                    Last contacted dates can lag because you don't have
                    real-time data enabled
                  </Typography>
                </LastContactedNote>
              </FilterSection>
              <Divider />
            </>
          )}

          {metadata?.behaviouralSegmentFilters?.length > 0 && (
            <>
              {metadata?.behaviouralSegmentFilters?.map((filter) => (
                <FilterSection key={filter.name}>
                  <SectionTitle variant="h5">{filter.title}</SectionTitle>
                  <FilterDescription variant="body2">
                    {filter.description}
                  </FilterDescription>
                  <CheckboxContainer sx={{ gridTemplateColumns: '1fr 1fr' }}>
                    {filter.options?.map((option) => (
                      <Box key={option.id}>
                        <StyledFormControlLabel
                          control={
                            <StyledCheckbox
                              name={option.id}
                              onChange={() =>
                                setSelectedFilters(
                                  createMultiselectFilters(
                                    option.id,
                                    formatEntityName(option.label),
                                    filter.name,
                                    selectedFilters
                                  )
                                )
                              }
                              data-testid={`test-${filter.name}-${option.id}-checkbox`}
                              checked={Object.keys(
                                selectedFilters?.[filter.name] || []
                              ).includes(option.id)}
                            />
                          }
                          label={createFormLabel(option.label, filter.name)}
                        />
                        {option.description && (
                          <BehaviouralSegmentDescription
                            segmentDescriptions={option.description}
                            objectiveId={objectiveId}
                          />
                        )}
                      </Box>
                    ))}
                  </CheckboxContainer>
                </FilterSection>
              ))}
              <Divider />
            </>
          )}
          {metadata?.parentEntityType && metadata?.parentFilterRegions && (
            <FilterSection>
              <SectionTitle variant="h5">
                {metadata?.parentEntityType}
              </SectionTitle>
              <FilterDescription variant="body1">
                Filter territory by {metadata?.parentEntityType}
              </FilterDescription>
              {metadata?.parentFilterRegions?.length > TERRITORY_CUTOFF && (
                <AutocompleteCombobox
                  options={metadata?.parentFilterRegions}
                  selection={selectedFilters?.regions || {}}
                  onOptionChange={(terr) => {
                    setSelectedFilters(
                      createMultiselectFilters(
                        terr.id,
                        formatEntityName(terr.label),
                        'regions',
                        selectedFilters
                      )
                    );
                  }}
                  type={metadata?.parentEntityType}
                  formatLabel={formatEntityName}
                />
              )}
              {metadata?.parentFilterRegions?.length <= TERRITORY_CUTOFF && (
                <CheckboxContainer>
                  {metadata?.parentFilterRegions?.map((region) => (
                    <StyledFormControlLabel
                      key={region.id}
                      control={
                        <StyledCheckbox
                          name={region.id}
                          onChange={() =>
                            setSelectedFilters(
                              createMultiselectFilters(
                                region.id,
                                formatEntityName(region.label),
                                'regions',
                                selectedFilters
                              )
                            )
                          }
                          checked={Object.keys(
                            selectedFilters?.regions || []
                          ).includes(region.id)}
                        />
                      }
                      label={formatEntityName(region.label)}
                    />
                  ))}
                </CheckboxContainer>
              )}
            </FilterSection>
          )}
        </StyledModalBody>

        <ModalFooter>
          <Button variant="utility" onClick={handleCloseFilterModal}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleApplyFilters}
            data-testid="apply-filters-button"
          >
            Apply Filters
          </Button>
        </ModalFooter>
      </ModalDisplay>
    </>
  );
};
