import { Box, Typography, useTheme, styled } from '@mui/material';
import isValidProp from '@emotion/is-prop-valid';
import { ActivityDescriptionPopover } from '../../components/ActivityDescriptionPopover';

const ActivityHeaderBox = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 3,
  color: themeColors.tableCellHeaderDefaultContent,
}));

const HeaderBox = styled(Box, {
  shouldForwardProp: isValidProp,
})(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: themeColors.tableCellHeaderDefaultContent,
}));

const HeaderText = styled(Typography)(() => ({
  whiteSpace: 'normal',
  textAlign: 'left',
}));

export const DemandTableHeader = ({ label, subLabel }) => {
  const { themeColors } = useTheme();
  return (
    <Box>
      {label === 'Activity' ? (
        <ActivityHeaderBox>
          <HeaderText variant="caption">Activity</HeaderText>
          <ActivityDescriptionPopover
            iconColor={themeColors.tableCellHeaderDefaultContent}
          />
        </ActivityHeaderBox>
      ) : (
        <HeaderBox headerType={label}>
          <HeaderText variant="caption">{label}</HeaderText>
          <HeaderText variant="caption">{subLabel}</HeaderText>
        </HeaderBox>
      )}
    </Box>
  );
};
