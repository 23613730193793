import { useQuery } from 'react-query';
import { fieldApi } from '../../request/maptualApiClient';

const getEntityJourneyFromApi = ({ entityId, maptualListId, projectId }) => {
  if (!entityId || !maptualListId || !projectId) {
    return Promise.reject();
  }

  return fieldApi.getEntityJourney({
    projectId,
    regionId: maptualListId,
    entityId,
  });
};

export const useEntityJourney = ({ entityId, maptualListId, projectId }) => {
  const {
    data: entityJourney,
    isLoading: isEntityJourneyBusy,
    isError: isEntityJourneyError,
    refetch: refetchEntityJourney,
  } = useQuery(
    [projectId, maptualListId, 'entity', entityId, 'entityJourney'],
    async () => {
      try {
        const response = await getEntityJourneyFromApi({
          entityId,
          maptualListId,
          projectId,
        });
        return response.data;
      } catch {
        return null;
      }
    }
  );

  return {
    entityJourney,
    isEntityJourneyBusy,
    isEntityJourneyError,
    refetchEntityJourney,
  };
};
