import { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { STATIC_METRIC_TYPES } from '../../../../../shared/constants';

const StyledMetricTypeSelector = styled('div')(() => ({}));

const StyledToggleButton = styled(ToggleButton)(() => ({
  textTransform: 'capitalize',
}));

export const MetricTypeSelector = ({ metricTypes, updateFilterMetricType }) => {
  const [selectedMetricType, setSelectedMetricType] = useState([]);

  const handleMetricTypeChange = (e, newMetricType) => {
    if (newMetricType.length > 0) {
      setSelectedMetricType(newMetricType);
      updateFilterMetricType(newMetricType);
    }
  };

  useEffect(() => {
    if (metricTypes && metricTypes.length > 0) {
      setSelectedMetricType([metricTypes[0]]);
      updateFilterMetricType([metricTypes[0]]);
    }
  }, [metricTypes]);

  return (
    <StyledMetricTypeSelector>
      {metricTypes && metricTypes.length > 0 && (
        <ToggleButtonGroup
          value={selectedMetricType}
          onChange={handleMetricTypeChange}
          aria-label="Metric Type"
        >
          {STATIC_METRIC_TYPES.map((metricType) => (
            <StyledToggleButton
              key={metricType}
              value={metricType}
              variant="contained"
              disabled={!metricTypes.includes(metricType)}
            >
              {metricType}
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    </StyledMetricTypeSelector>
  );
};
