import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Box, styled } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useState } from 'react';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.themeColors.tooltipBackgroundColor,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 16px 70px 0px #00000080', // TODO: add to theme
    padding: '8px',
    borderRadius: '3px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.themeColors.buttonBorderColor,
  },
}));

const StyledTitle = styled(Box)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '21.6px',
}));

const StyledMessage = styled(Box)(({ theme: { themeColors } }) => ({
  color: themeColors.tertiaryColor,
  fontSize: '16px',
  fontWeight: 400,
  marginTop: '8px',
  lineHeight: '19.2px',
  whiteSpace: 'pre-line',
}));

export const CustomTooltip = ({
  children,
  title,
  message,
  placement = 'bottom',
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <StyledTooltip
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -8],
                  },
                },
              ],
            },
          }}
          title={
            <>
              <StyledTitle>{title}</StyledTitle>
              <StyledMessage>{message}</StyledMessage>
            </>
          }
          disableHoverListener
          disableFocusListener
          disableTouchListener
          onClose={handleTooltipClose}
          open={open}
          placement={placement}
        >
          <span onClick={handleTooltipOpen}>{children}</span>
        </StyledTooltip>
      </div>
    </ClickAwayListener>
  );
};
