import { useContext } from 'react';
import { useQuery } from 'react-query';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import { styled, Box, Typography, Skeleton } from '@mui/material';
import { AppContext } from '../../../containers/application/appContext';
import { getEntityMessages } from '../../../request/projectRequests';

const MessagesContainer = styled(Box)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.cardBackgroundColor,
  border: '1px solid',
  borderColor: themeColors.borderPrimaryColor,
  borderRadius: '7px',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '20px',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '16px',
  width: '100%',
  alignItems: 'center',
}));

const MessageText = styled(Typography)(({ theme: { themeColors } }) => ({
  textAlign: 'left',
  color: themeColors.secondaryTextColor,
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 400,
  marginLeft: '8px',
}));

const MessageIconWrapper = styled(Box)(() => ({
  position: 'relative',
}));

const MessageIconBackground = styled(Box)(({ theme: { themeColors } }) => ({
  width: '24px',
  height: '24px',
  background: themeColors.dataGeneralColor,
  opacity: 0.2,
  borderRadius: '20px',
}));

const MessageIcon = styled(EmojiObjectsOutlinedIcon)(
  ({ theme: { themeColors } }) => ({
    width: '16px',
    height: '16px',
    color: themeColors.dataGeneralColor,
    position: 'absolute',
    top: '4px',
    left: '4px',
  })
);

const SkeletonLoadingBar = styled(Skeleton)(
  ({ theme: { themeColors, spacing } }) => ({
    backgroundColor: themeColors.surfaceEmpty,
    transform: 'scale(1)',
    marginBottom: spacing(2),
    height: 80,
    width: '100%',
  })
);

const fetchEntityMessages = async (params) => {
  try {
    const response = await getEntityMessages(params);
    return response.data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('[ERROR] fetchEntityMessages');
  }
  return {};
};
export const EntityMessages = ({ selectedObjective, entityId, projectId }) => {
  const { projectList } = useContext(AppContext);

  const productLineId = projectList.find(
    (proj) => proj.projectId === projectId
  )?.productLineId;
  const { objectiveId } = selectedObjective;

  const params = {
    entityId,
    productLineId,
    objectiveId,
  };

  const { data, isLoading, isError } = useQuery(
    ['entity-messages', entityId, productLineId, objectiveId],
    async () => fetchEntityMessages(params),
    {
      enabled: !!(entityId && productLineId && objectiveId),
    }
  );

  if (isError) {
    return null;
  }

  if (isLoading || !data || !data.messages) {
    return <SkeletonLoadingBar />;
  }

  return data.messages.map((message) => (
    <MessagesContainer key={message}>
      <MessageIconWrapper>
        <MessageIconBackground />
        <MessageIcon />
      </MessageIconWrapper>

      <MessageText>{message}</MessageText>
    </MessagesContainer>
  ));
};
