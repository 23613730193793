import { useContext } from 'react';
import { styled } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import Fade from '@mui/material/Fade';
import { ConfigViewContext } from './configViewContext';

const METRICS_CADENCE_MAPPING = {
  wk: 'weekly',
  mth: 'monthly',
  qtr: 'quarterly',
  yr: 'yearly',
};

const TableViewContentContainer = styled('div')(() => ({
  height: '100%',
}));

const StyledDataGrid = styled(DataGridPremium)({
  border: 'none',
  '.first-column-cell, .first-column-header': {
    paddingLeft: 25,
  },
  '.predict-metric': {
    textTransform: 'capitalize',
  },
});

const columns = [
  {
    field: 'metricFriendlyName',
    headerName: 'Metric Friendly Name',
    minWidth: 290,
    flex: 1,
    headerClassName: 'first-column-header',
    cellClassName: 'first-column-cell',
  },
  {
    field: 'metricId',
    headerName: 'Metric ID',
    width: 150,
  },
  {
    field: 'metricRxType',
    headerName: 'Metric Type',
    minWidth: 140,
    flex: 1,
  },
  {
    field: 'metricInternalName',
    headerName: 'Internal Name',
    minWidth: 250,
    flex: 1,
  },
  {
    field: 'metricEntityType',
    headerName: 'Entity Type',
    minWidth: 110,
    flex: 1,
  },
  {
    field: 'metricActivityLabel',
    headerName: 'Activity Label',
    minWidth: 210,
    flex: 1,
  },
  {
    field: 'metricRxCadence',
    headerName: 'Cadence',
    minWidth: 80,
    flex: 1,
  },
  {
    field: 'metricRxSource',
    headerName: 'Source',
    minWidth: 60,
    flex: 1,
  },
  {
    field: 'rxSourceType',
    headerName: 'Source Type',
    minWidth: 130,
    flex: 1,
  },
  {
    field: 'lastTimestamp',
    headerName: 'Timestamp',
    minWidth: 100,
    flex: 1,
  },
  {
    field: 'predictMetric',
    headerName: 'Predict Metric',
    minWidth: 120,
    flex: 1,
    cellClassName: 'predict-metric',
  },
];

const formatToMuiSchema = (metrics) =>
  Object.values(metrics).map((metric) => ({
    ...metric,
    id: metric.metricId,
    metricRxCadence: METRICS_CADENCE_MAPPING[metric.metricRxCadence.value],
  }));

export const MetricsTableView = ({ data, isLoading }) => {
  const {
    metricsTableSorting: columnSorting,
    setMetricsTableSorting: setColumnSorting,
    metricsTableColumnVisibility: columnVisibility,
    setMetricsTableColumnVisibility: setColumnVisibility,
  } = useContext(ConfigViewContext);

  return (
    <Fade in timeout={500}>
      <TableViewContentContainer>
        <StyledDataGrid
          rows={formatToMuiSchema(data)}
          columns={columns}
          hideFooterSelectedRowCount
          loading={isLoading}
          sortModel={columnSorting}
          onSortModelChange={setColumnSorting}
          columnVisibilityModel={columnVisibility}
          onColumnVisibilityModelChange={setColumnVisibility}
        />
      </TableViewContentContainer>
    </Fade>
  );
};
