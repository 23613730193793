import { createContext, useContext } from "react";
import { EnqueueSnackbar } from "@odaia/ui/src/components/snackbar";
import {
  MaptualList,
  MaptualListObjective,
  EntityPrecallInsight,
  EntityRuleInsight,
  EntityCoordinate,
  UserCreatedLists,
} from "@odaia/domain/src/types";
import { SetState } from "@odaia/domain/src/utilityTypes";
import { MapViewCoreContext } from "./mapViewCoreContext";

export type MapViewContextType = {
  projectId: string | undefined;
  productLineId: string | undefined;
  maptualListMetadata:
    | {
        listName: string;
        maptualListId: string;
        locality: string;
      }
    | undefined;
  maptualList: MaptualList;
  isMaptualListLoading: boolean;
  isMaptualListsLoading: boolean;
  maptualListObjectives: MaptualListObjective[];
  entityCoordinatesData: EntityCoordinate[] | undefined;
  isLoadingEntityCoords: boolean;
  isErrorEntityCoords: boolean;
  objective: MaptualListObjective | null;
  setObjective: SetState<MaptualListObjective | null>;
  objectiveIndex: number | null;
  setObjectiveIndex: SetState<number | null>;
  entityPrecallInsights: EntityPrecallInsight[] | undefined;
  isEntityPrecallInsightsLoading: boolean;
  entityRuleInsights: Record<string, EntityRuleInsight> | undefined;
  isEntityRuleInsightsLoading: boolean;
  enqueueSnackbar: EnqueueSnackbar;
  canUserFilterMapView: boolean;
  relevantUserSpecialties: string[] | undefined;
  productLineUserCreatedLists: UserCreatedLists | undefined;
  entityCuration: { [key: string]: number } | undefined;
  isEntityCurationLoading: boolean;
  voiceEnabled?: boolean;
  setMapObjective?: (
    objective: MaptualListObjective | null,
    objectiveIndex: number
  ) => void;
  anonymize?: boolean;
  filterMetadata?: any;
  filterMetadataLoading?: boolean;
};

export const MapViewContext = createContext<MapViewContextType | undefined>(
  undefined
);

export const useMapViewContext = () => {
  const context = useContext(MapViewContext);
  const coreContext = useContext(MapViewCoreContext);
  if (context === undefined && coreContext === undefined) {
    throw new Error(
      "useMapViewContext must be used within a MapViewContextProvider"
    );
  }
  return coreContext ? coreContext : context;
};
