import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import Fade from '@mui/material/Fade';

import SkeletonChart from '../../generic/SkeletonChart';
import { RawDataDisplay } from './RawDataDisplay';

const LOADING_STATE = 'loading';

const useStyles = makeStyles(({ insightContentCardStyle, palette }) => ({
  root: {
    ...insightContentCardStyle(`${palette.mode}Theme`),
    height: '670px',
    width: '100%',
  },
  dashBoardChart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const EmptyViewContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px;
  width: 100%;
`;

const FailedToLoadTitle = styled(Typography)`
  font-size: 32px;
  font-weight: 600;
  color: ${grey[600]};
  margin: 1rem;
`;

const getFailedToLoadTitle = (url) => {
  switch (url) {
    case '/attributionAssembler':
      return 'Omnichannel Attribution Data Unavailable, Please Try Again Later';
    default:
      return 'Unable to load content';
  }
};

const SubCharts = ({
  getDashboardSubUrl,
  subUrl,
  projectId,
  segmentId,
  maptualListId,
  primaryText,
  tileGraphHeight = 220,
  componentType,
}) => {
  const [chartData, setChartData] = useState(null);
  const [isFailed, setIsFailed] = useState(false);

  const classes = useStyles();
  const dashboardTileStyle = {
    margin: 0,
    marginRight: 30,
    marginBottom: 30,
    width: 800,
    customHeight: tileGraphHeight,
  };

  const fetchChartData = async () => {
    setChartData(LOADING_STATE);
    const data = await getDashboardSubUrl({
      subUrl,
      projectId,
      segmentId,
      maptualListId,
    });

    if (data.value?.isRejected) {
      setIsFailed(true);
    }
    setChartData(data.action.payload);
  };

  useEffect(() => {
    fetchChartData();
  }, [segmentId, maptualListId]);

  if (chartData === LOADING_STATE) {
    return <SkeletonChart title={primaryText} style={dashboardTileStyle} />;
  }

  if (isFailed) {
    return (
      <Fade in timeout={600} key={`chart-loading-${segmentId}-failed`}>
        <div className={classes.dashBoardChart} style={dashboardTileStyle}>
          <div className={classes.root}>
            <EmptyViewContainer>
              <FailedToLoadTitle>
                {getFailedToLoadTitle(subUrl)}
              </FailedToLoadTitle>
            </EmptyViewContainer>
          </div>
        </div>
      </Fade>
    );
  }
  return (
    chartData && (
      <RawDataDisplay
        dataKey={componentType}
        data={chartData[Object.keys(chartData)?.[0]]}
        style={dashboardTileStyle}
      />
    )
  );
};

export default SubCharts;
