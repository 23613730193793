import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { styled } from '@mui/material';
import { userManagementColors } from '../styles/styledComponents';

const accountTypes = [
  {
    id: 'userAccount',
    value: 'N/A',
    label: 'User Account',
    description: 'Account will be assigned to a customer user',
  },
  {
    id: 'internalAccount',
    value: 'TEST',
    label: 'Internal Account',
    description:
      'Account will be assigned to an internal user; not tracked by Mixpanel',
  },
  {
    id: 'demoAccount',
    value: 'DEMO',
    label: 'Demo Account',
    description:
      'Account will be assigned for demo purposes; not tracked by Mixpanel',
  },
];

const SubtitleText = styled(Typography)(() => ({
  marginTop: 5,
  color: userManagementColors.primaryColor,
}));

const StyledRadioGroup = styled(RadioGroup)(() => ({
  color: userManagementColors.primaryColor,
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  marginTop: 16,
}));

const StyledRadio = styled(Radio)(() => ({
  marginLeft: 10,
  width: 20,
  height: 20,
  color: userManagementColors.primaryColor,
}));

const RadioLabel = styled(Typography)(() => ({
  marginLeft: 8,
  color: userManagementColors.tertiaryColor,
}));

const RadioDescription = styled(Typography)(() => ({
  marginLeft: 28,
  marginTop: 4,
  color: userManagementColors.primaryColor,
}));

const AccountTypeSelectionInput = ({ id, onChange, value }) => {
  const handleChange = (e) => {
    const specialType = e.target.value;
    onChange(id, specialType);
  };
  return (
    <div>
      <Typography variant="h5"> Account Type</Typography>
      <SubtitleText variant="body1">
        Set account type based on the account's purpose
      </SubtitleText>

      <StyledRadioGroup value={value} onChange={(e) => handleChange(e)}>
        {accountTypes.map((accountType) => (
          <>
            <StyledFormControlLabel
              key={accountType.id}
              control={<StyledRadio size="small" />}
              label={
                <RadioLabel variant="title3">{accountType.label}</RadioLabel>
              }
              value={accountType.value}
            />
            <RadioDescription variant="body2">
              {accountType.description}
            </RadioDescription>
          </>
        ))}
      </StyledRadioGroup>
    </div>
  );
};

export default AccountTypeSelectionInput;
