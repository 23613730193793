/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import EmptyView from '../emptyview/emptyView';
import ProjectFeatureView from './projectFeatureView';
import DownloadButton from '../input/downloadButton';
import { trackProjectSelection } from '../../trackers/appEventTracker';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    height: '100%',
  },
  iconButtonStyle: {
    size: 'medium',
    color: 'inherit',
    backgroundColor: theme.palette.background.default,
  },
  iconStyle: {
    height: '2rem',
    width: '2rem',
    margin: 0,
  },
  iconLinkContainer: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  linkTextStyle: {
    fontWeight: 500,
    fontSize: 24,
  },
  linkStyle: {
    padding: 0,
    margin: 0,
    backgroundColor: '#00000000',
    color: 'textSecondary',
  },
}));

type ProjectItemProps = {
  match: {
    params: {
      projectId: string;
      externalAnalysisId: string;
    };
  };
  location: {
    search: PropTypes.string;
  };
  returnMatch: {
    path: string;
  };
  getProject: PropTypes.func;
  getAnalysis: PropTypes.func;
  setProjectItem: PropTypes.func;
  setInjectableComponents: (...args: unknown[]) => void;
  setCustomCrumb: (...args: unknown[]) => void;
  viewer: {
    isBusy: boolean;
    projectItem: {
      projectId: string;
      metadata: {
        projectName: string;
        snapshotUrl: string;
      };
    };
    isAnalysisExpanded: boolean;
  };
};

export default function ProjectItem({
  match,
  returnMatch,
  getProject,
  getAnalysis,
  setProjectItem,
  setInjectableComponents,
  setCustomCrumb,
  viewer,
  location: { search },
}: ProjectItemProps) {
  const classes = useStyles();
  const routeHistory = useHistory();
  const { isBusy, projectItem } = viewer;

  const { params } = match;

  useEffect(() => {
    if (
      params?.projectId &&
      (!projectItem || projectItem?.projectId !== params.projectId)
    ) {
      trackProjectSelection(params.projectId);
      setProjectItem(null);
      getProject(params.projectId);
    }
    if (params.externalAnalysisId) {
      const searchParams = new URLSearchParams(search);
      getAnalysis(params.externalAnalysisId, searchParams.get('userId'));
    }
  }, []);

  useEffect(() => {
    const closeControl = (
      <IconButton
        onClick={() => {
          routeHistory.push(returnMatch.path);
        }}
        className={classes.iconButtonStyle}
        style={{ marginLeft: '2rem' }}
        size="large"
      >
        <CloseIcon className={classes.iconStyle} />
      </IconButton>
    );
    setCustomCrumb({
      component: (
        <Link
          color="textSecondary"
          className={classes.linkStyle}
          component="button"
          style={{ marginRight: '0.8rem' }}
          disabled
          underline="hover"
        >
          <div className={classes.iconLinkContainer}>
            <Typography className={classes.linkTextStyle}>
              {isBusy
                ? 'Loading Project ...'
                : projectItem
                ? projectItem.metadata && projectItem.metadata.projectName
                  ? projectItem.metadata.projectName
                  : 'Unnamed Project'
                : 'Project Not Found'}
            </Typography>
          </div>
        </Link>
      ),
      action: null,
    });

    if (!isBusy && projectItem) {
      setInjectableComponents([
        <DownloadButton
          key="download"
          linkUrl={projectItem.metadata?.snapshotUrl}
        />,
        closeControl,
      ]);
    } else {
      setInjectableComponents([closeControl]);
    }
  }, [isBusy]);

  return (
    <div className={classes.root}>
      {!isBusy && projectItem ? (
        <ProjectFeatureView
          getAnalysis={getAnalysis}
          match={match}
          viewer={viewer}
        />
      ) : (
        <div style={{ width: '100%', height: '100%' }}>
          <EmptyView
            isLoading={isBusy}
            refreshAction={() => {
              getProject(params.projectId);
              if (params.externalAnalysisId) {
                const searchParams = new URLSearchParams(search);
                getAnalysis(
                  params.externalAnalysisId,
                  searchParams.get('userId')
                );
              }
            }}
          />
        </div>
      )}
    </div>
  );
}
