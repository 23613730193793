import { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { styled, Box, Skeleton, Typography } from '@mui/material';
import Scrollbar from 'react-scrollbars-custom';
import ProfilePicNLinks from './entityProfile/profilePicNLinks';
import ProfileJourneyView from './entityProfile/profileJourneyView';
import { useEntityJourney } from '../../containers/fieldview/useEntityJourney';
import { ErrorContainer } from '../generic/errorContainer';
import ProfileDetails from './entityProfile/profileDetails';

const useStyles = makeStyles(({ themeColors }) => ({
  scrollView: {
    flexGrow: 2,
    width: '100%',
    height: '100%',
  },
  stepperStyle: {
    margin: 0,
    borderRadius: 10,
    padding: 0,
    marginTop: 10,
    backgroundColor: themeColors.mainBackground,
  },
  dot: {
    backgroundColor: themeColors.buttonBackgroundColor,
  },
  dotActive: {
    backgroundColor: themeColors.buttonContentColor,
  },
  stepperButton: {
    color: themeColors.buttonContentColor,
    '&:hover': {
      backgroundColor: themeColors.buttonBackgroundColor,
    },
  },
}));

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 16,
}));

const DrawerWrapper = styled(Box)(({ theme: { themeColors } }) => ({
  width: 435,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: themeColors.journeyDrawerBackgroundColor,
}));

const SkeletonLoadingBar = styled(Skeleton, {
  shouldForwardProp: (prop) => prop !== 'height',
})(({ theme: { themeColors }, height }) => ({
  backgroundColor: themeColors.surfaceEmpty,
  transform: 'scale(1)',
  height,
  width: '100%',
}));

const TimelineWrapper = styled(Box)(({ theme: { spacing, themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: themeColors.modalBackgroundColor,
  width: '100%',
  height: '100%',
  gap: spacing(1.5),
  padding: spacing(3),
}));

const LoadingTimeline = () => (
  <TimelineWrapper>
    <SkeletonLoadingBar height="64px" />
    <SkeletonLoadingBar height="36px" />
    <SkeletonLoadingBar height="105px" />
    <SkeletonLoadingBar height="105px" />
    <SkeletonLoadingBar height="105px" />
  </TimelineWrapper>
);

const ErrorOnFetching = ({ handleRetry }) => (
  <TimelineWrapper>
    <ErrorContainer handleRetry={handleRetry} />
  </TimelineWrapper>
);

export function EntityJourney({
  entityId,
  maptualListId,
  projectId,
  entityUData,
}) {
  const {
    entityJourney,
    isEntityJourneyBusy,
    isEntityJourneyError,
    refetchEntityJourney,
  } = useEntityJourney({
    entityId,
    maptualListId,
    projectId,
  });

  const classes = useStyles();

  const [isEntityJourneyLoading, setIsEntityJourneyLoading] = useState(true);

  useEffect(() => {
    if (!isEntityJourneyBusy) {
      setIsEntityJourneyLoading(false);
    }
  }, [isEntityJourneyBusy]);

  const retryEntityJourney = () => {
    setIsEntityJourneyLoading(true);
    refetchEntityJourney();
  };

  if (isEntityJourneyError) {
    return <ErrorOnFetching handleRetry={retryEntityJourney} />;
  }

  return (
    <DrawerWrapper>
      {isEntityJourneyLoading ? (
        <LoadingTimeline />
      ) : (
        <Scrollbar
          disableTracksWidthCompensation
          noScrollX
          className={`${classes.scrollView} intercom-field-entity-journey`}
        >
          <Box padding="30px">
            <ProfilePicNLinks entityUData={entityUData} />
            <StyledBox>
              <ProfileDetails entityUData={entityUData} />
              <Typography variant="h4">Timeline</Typography>
              <ProfileJourneyView entityJourney={entityJourney} />
            </StyledBox>
          </Box>
        </Scrollbar>
      )}
    </DrawerWrapper>
  );
}
