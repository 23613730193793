import { styled, Skeleton, Box } from '@mui/material';

const SkeletonWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
}));

const SkeletonHeader = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  height: 64,
  backgroundColor: themeColors.surfaceEmpty,
}));

const SkeletonBody = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  height: 160,
  backgroundColor: themeColors.surfaceEmpty,
}));

export const TableSkeleton = () => (
  <SkeletonWrapper>
    <SkeletonHeader animation="wave" />
    <SkeletonBody animation="wave" />
  </SkeletonWrapper>
);
