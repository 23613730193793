import { styled, Typography } from '@mui/material';
import { TreeItem, TreeView } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import { cloneDeep } from 'lodash';

import { v4 as uuidv4 } from 'uuid';

import { DETAIL_DISPLAY } from '../detailDisplay';
import { useIsAuthorized } from '../../../hooks/useIsAuthorized';
import { RBAC_PERMISSIONS } from '../../../constants';

const StyledTreeView = styled(TreeView)(
  ({ theme: { themeColors }, disabled }) => ({
    overflowY: 'scroll',
    borderRight: `1px solid ${themeColors.borderLowContrast}`,
    opacity: disabled ? '0.5' : '1',
    pointerEvents: disabled ? 'none' : 'auto',
    '& .top-level-tree-item': {
      borderBottom: `1px solid ${themeColors.borderLowContrast}`,
    },
  })
);

const StyledTreeItem = styled(TreeItem)(({ theme: { themeColors } }) => ({
  '& .MuiTreeItem-group': {
    marginLeft: 16,
  },
  '& .MuiTreeItem-content': {
    textAlign: 'left',
    padding: '16px',
  },
  '& .MuiTreeItem-content:hover, & .MuiTreeItem-content.Mui-focused:hover': {
    backgroundColor: themeColors.tableHoverColor,
  },
  '& .MuiTreeItem-content.Mui-selected, & .MuiTreeItem-content.Mui-selected:hover, & .MuiTreeItem-content.Mui-selected.Mui-focused':
    {
      backgroundColor: themeColors.entitySelectionHoverBackgroundColor,
    },
  '& .MuiTreeItem-content.Mui-focused': {
    backgroundColor: 'transparent',
  },
  '& .MuiTreeItem-content .MuiTreeItem-iconContainer': {
    width: '20px',
    marginRight: 0,
  },
  '& .MuiTreeItem-content .MuiTreeItem-iconContainer svg': {
    fontSize: 20,
  },
  '& .MuiTreeItem-content .MuiTreeItem-label': {
    fontSize: 15,
    paddingLeft: 0,
    wordBreak: 'break-word',
    marginLeft: 8,
    color: themeColors.primaryTextColor,
  },
}));

const LabelWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.themeColors.tooltipBackgroundColor,
    color: theme.themeColors.primaryTextColor,
    maxWidth: 250,
  },
}))(Tooltip);

const SecondaryText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.adminCardSubheader,
  fontSize: 16,
}));

export function findProductById(tree, targetProductId) {
  if (tree.productId === targetProductId) {
    return tree;
  }
  let result = null;
  tree.subCategories.forEach((subCategory) => {
    const subResult = findProductById(subCategory, targetProductId);
    if (subResult) {
      result = subResult;
    }
  });

  return result;
}

export function ProductTreeHierarchy({
  productTree,
  setProductTreeInState,
  selectedTreeNode,
  setSelectedTreeNode,
  editedNode,
  setEditedNode,
  setDisplayTreeDetails,
}) {
  useEffect(() => {}, [selectedTreeNode]);
  const [expanded, setExpanded] = useState([]);

  const hasConfigAdminPermissions = useIsAuthorized([
    RBAC_PERMISSIONS.USER_GROUP_CONFIG_ADMIN,
  ]);

  const handleSelect = (event, nodeId, override = false) => {
    if (!event.target.closest('.MuiTreeItem-iconContainer') || override) {
      const product = findProductById(productTree.productTree, nodeId);
      setSelectedTreeNode(product);
    }
  };

  const handleToggle = (event, nodeId, override = false) => {
    if (event.target.closest('.MuiTreeItem-iconContainer') || override) {
      setExpanded(nodeId);
    }
  };

  const createNewChildNode = (productId, newProductId) => {
    const newProduct = {
      productId: newProductId,
      productName: 'New Node',
      isNewNode: true,
      nodeParentId: productId,
      subCategories: [],
    };
    const newProductTree = cloneDeep(productTree);
    findProductById(newProductTree.productTree, productId).subCategories.push(
      newProduct
    );
    setProductTreeInState(newProductTree);
    setSelectedTreeNode(newProduct);
  };

  const handleAddNodeTo = (event, productId) => {
    event.stopPropagation();
    const newProductId = uuidv4();
    setEditedNode(newProductId);
    setDisplayTreeDetails(DETAIL_DISPLAY.DISABLE);
    createNewChildNode(productId, newProductId);
    handleToggle(event, [productId, ...expanded], true);
  };
  const renderTree = (node, isFirst = false) => (
    <StyledTreeItem
      key={node.productId}
      nodeId={node.productId}
      label={
        <LabelWrapper>
          <Typography variant="h6">{node.productName}</Typography>
          {hasConfigAdminPermissions && (
            <CustomTooltip
              title={
                <SecondaryText variant="body2">Add a child node</SecondaryText>
              }
            >
              <AddIcon
                onClick={(event) => handleAddNodeTo(event, node.productId)}
              />
            </CustomTooltip>
          )}
        </LabelWrapper>
      }
      className={isFirst && 'top-level-tree-item'}
      disabled={node.isPlaceholder}
    >
      {Array.isArray(node.subCategories)
        ? node.subCategories.map((subCategory) => renderTree(subCategory))
        : null}
    </StyledTreeItem>
  );
  return (
    <StyledTreeView
      disabled={!!editedNode}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeSelect={handleSelect}
      selected={selectedTreeNode.productId}
      onNodeToggle={handleToggle}
      expanded={expanded}
    >
      {renderTree(productTree.productTree, true)}
    </StyledTreeView>
  );
}
