import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getTerritoryOverviewData } from '../../request/territoryOverviewRequests';
import { disclaimerOptions } from './constants';
import { fieldApi } from '../../request/maptualApiClient';
import useFeatureToggles from '../../hooks/useFeatureToggles';
import { AppContext } from '../application/appContext';
import { FieldContext } from '../application/appViews/field/fieldContext';

const getRoutingHeaders = () => [
  {
    label: '',
    subLabel: '',
    type: 'externalLink',
  },
];

const getRoutingItems = (featureToggles, userGroup, entityViews) => {
  const hasFeatureEnabled = userGroup
    ? featureToggles(userGroup, 'showAccountView')
    : false;

  const hasEntityViews = entityViews?.length > 0;

  return {
    data: {
      hcpRouting: true,
      entityOverviewRouting: hasFeatureEnabled && hasEntityViews,
    },
  };
};

const buildTableItems = (serverItems, specializedItem) => {
  if (!serverItems) {
    return [];
  }

  if (!specializedItem && serverItems.length > 0) {
    return serverItems;
  }

  return serverItems?.map((item) => [...item, specializedItem]);
};

export const useTerritoryOverviewData = ({
  distributionType,
  setDistributionType,
  projectId,
  maptualListId,
  sfMaptualListId,
  selectedMetric,
  selectedCategory,
  selectedTimeframeID,
  comparisonTimeFrameId,
}) => {
  const featureToggles = useFeatureToggles();
  const { profile } = useContext(AppContext);
  const { entityViews } = useContext(FieldContext);
  const specializedHeaders = getRoutingHeaders();
  const specializedItem = getRoutingItems(
    featureToggles,
    profile?.userGroup,
    entityViews
  );

  const {
    isFetching: isMetadataLoading,
    data: metadataResponse,
    refetch: refetchMetadata,
  } = useQuery({
    queryKey: ['territory-overview-metadata', projectId, sfMaptualListId],
    queryFn: async () => {
      try {
        const response = await fieldApi.getTerritoryOverviewMetadata({
          projectId,
          regionId: sfMaptualListId,
        });

        if (response?.status === 204) {
          return {
            data: disclaimerOptions.INVALID_REGION,
            status: response.status,
          };
        }

        const { data } = response;
        const formattedMetadata = {
          ...data,
          objectives: formatCategory(data.objectives),
          products: formatCategory(data.products),
        };
        return { ...response, data: formattedMetadata };
      } catch (err) {
        return { status: 500, ...err.response };
      }
    },
    cacheTime: 0,
    enabled: !!projectId && !!sfMaptualListId,
  });

  const {
    isFetching: isDataLoading,
    isError: isDataError,
    isIdle: isDataRequestIdle,
    data: dataResponse,
  } = useQuery({
    queryKey: [
      'territory-overview',
      projectId,
      sfMaptualListId,
      distributionType,
      selectedMetric,
      selectedCategory,
      selectedTimeframeID,
      comparisonTimeFrameId,
    ],
    queryFn: async () => {
      const params = {
        projectId,
        regionId: sfMaptualListId,
        metric: selectedMetric,
        distribution: distributionType,
        categoryId: selectedCategory,
        cadence: selectedTimeframeID,
        comparisonTimeFrameId,
      };

      const response = await getTerritoryOverviewData(params);

      if (
        response?.status === 204 ||
        Object.keys(response?.data ?? {}).length === 0
      ) {
        return {
          data: {},
          status: 204,
        };
      }
      const { headers: serverHeaders, items: serverItems } = response.data;
      const tableItems = buildTableItems(serverItems, specializedItem);

      const territoryOverviewSpecializedData = {
        ...response.data,
        headers: [...serverHeaders, ...specializedHeaders],
        items: tableItems,
      };

      return { ...response, data: territoryOverviewSpecializedData };
    },
    enabled:
      !!projectId &&
      !!sfMaptualListId &&
      !!selectedMetric &&
      !!selectedCategory &&
      !!distributionType &&
      !!selectedTimeframeID &&
      !!comparisonTimeFrameId,
  });

  useEffect(() => {
    if (
      metadataResponse?.status === 200 &&
      metadataResponse?.data &&
      metadataResponse.data !== disclaimerOptions.INVALID_REGION
    ) {
      const metadata = metadataResponse.data;
      const defaultDistribution = metadata.availableDistributions[0];

      setDistributionType(defaultDistribution);
    }
  }, [metadataResponse?.data, metadataResponse?.status]);

  const [previousMaptualListId, setPreviousMaptualListId] =
    useState(maptualListId);

  useEffect(() => {
    if (!previousMaptualListId) {
      setPreviousMaptualListId(maptualListId);
    } else if (maptualListId !== previousMaptualListId) {
      refetchMetadata();
      setPreviousMaptualListId(maptualListId);
    }
  }, [maptualListId, previousMaptualListId]);

  return {
    dataResponse: dataResponse?.data ?? {},
    status: dataResponse?.status,
    metadataResponse,
    metadataStatus: metadataResponse?.status,
    isDataLoading,
    isDataError,
    isDataRequestIdle,
    isMetadataLoading,
  };
};

const formatCategory = (categories) =>
  categories.reduce(
    (result, category) => ({
      ...result,
      [category.id]: {
        ...category,
        metrics: category.metrics.reduce(
          (result, metric) => ({
            ...result,
            [metric.rxType]: { ...metric, id: metric.rxType },
          }),
          {}
        ),
      },
    }),
    {}
  );
