import { useEffect, useMemo, useState } from "react";
import { useMap } from "@vis.gl/react-google-maps";
import { styled, Box, Typography } from "@mui/material";
import { PowerScore } from "../powerScore";

const MarkerBackground = styled(Box, {
  shouldForwardProp: (prop) =>
    !["isSelected", "isRouted", "doesSpiderExist"].includes(prop),
})(({ isSelected, isRouted, doesSpiderExist, theme: { themeColors } }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "48px",
  width: "48px",
  backgroundColor: isSelected
    ? themeColors.inputBackgroundHover
    : themeColors.secondaryColor,
  borderRadius: "50%",
  border:
    isSelected || isRouted
      ? `2px ${isSelected ? "solid" : "dashed"} ${themeColors.borderFocus}`
      : `1px solid ${themeColors.buttonBorderColor}`,
  boxShadow: "0px 3px 15px 0px rgba(0, 0, 0, 0.25)",
  opacity: doesSpiderExist ? 0.5 : 1,
}));

const MarkerBackgroundNew = styled(Box, {
  shouldForwardProp: (prop) =>
    !["isSelected", "isRouted", "doesSpiderExist"].includes(prop),
})(({ isSelected, isRouted, doesSpiderExist, theme: { themeColors } }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "48px",
  backgroundColor: themeColors.secondaryColor,
  borderRadius: "50px",
  border:
    isSelected || isRouted
      ? `2px ${isSelected ? "solid" : "dashed"} ${themeColors.borderFocus}`
      : `1px solid ${themeColors.buttonBorderColor}`,
  boxShadow: "0px 3px 15px 0px rgba(0, 0, 0, 0.25)",
  opacity: doesSpiderExist ? 0.5 : 1,
}));

const MultipleMarkerContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  padding: 8,
}));

const MultipleMarkerExtraText = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.secondaryTextColor,
    fontSize: 16,
    alignSelf: "center",
    marginLeft: 12,
  })
);

const PowerScorePositioner = styled(Box)(() => ({
  marginRight: "-8px",
}));

export const EntityMarker = ({
  entity,
  marker,
  oms,
  markers,
  points,
  onPointClick,
  onSpiderClick,
  selectedObjectiveIndex,
  selectedEntity = null,
  spiderfiedEntityId = "",
  setSpiderfiedEntityId = null,
  setParentSpiderState = null,
  parentSpiderState = "",
  routeEntities,
}) => {
  const [spiderListening, setSpiderListening] = useState(false);
  const [prevSpiderState, setPrevSpiderState] = useState("UNSPIDERFIABLE");
  const [spiderState, setSpiderState] = useState("UNSPIDERFIABLE");
  const [nearbyMarkers, setNearbyMarkers] = useState([]);
  const [
    nearbyMarkerSpiderStateChangeDepth,
    setNearbyMarkerSpiderStateChangeDepth,
  ] = useState(0);
  const [nearbyMarkerSpiderStateListener, setNearbyMarkerSpiderStateListener] =
    useState(null);
  const map = useMap();
  if (marker && !spiderListening && oms) {
    window.google.maps.event.addListener(marker, "spider_format", (status) => {
      setSpiderState(status);
    });

    window.google.maps.event.addListener(marker, "spider_click", () => {
      onPointClick(entity, false);
    });
    oms.addMarker(marker);
    setSpiderListening(true);
  }
  useEffect(() => {
    if (marker && map) {
      if (nearbyMarkerSpiderStateListener) {
        window.google.maps.event.removeListener(
          nearbyMarkerSpiderStateListener
        );
      }
      const nearbyListener = window.google.maps.event.addListener(
        marker,
        "spider_format_nearby",
        (newNearbyDepth) => {
          setNearbyMarkerSpiderStateChangeDepth(newNearbyDepth);
        }
      );
      setNearbyMarkerSpiderStateListener(nearbyListener);
    }
  }, [marker]);

  useEffect(() => {
    setNearbyMarkerSpiderStateChangeDepth(0);
    if (spiderState !== prevSpiderState) {
      // reformat all markers in group when one group marker format changes
      nearbyMarkers.forEach((point) => {
        if (point.snowflakeEntityId !== entity.snowflakeEntityId) {
          window.google.maps.event.trigger(
            markers[parseInt(point.snowflakeEntityId)],
            "spider_format_nearby",
            nearbyMarkerSpiderStateChangeDepth + 1
          );
        }
      });

      setPrevSpiderState(spiderState);
    }

    if (spiderState === "UNSPIDERFIABLE") {
      setNearbyMarkers([]);
    }
    if (spiderState === "SPIDERFIABLE") {
      if (spiderfiedEntityId === entity.snowflakeEntityId) {
        setSpiderfiedEntityId("");
      }
      if (parentSpiderState !== "SPIDERFIED" && marker) {
        const markersToAgg = oms.markersNearMarker(marker, false);
        if (markersToAgg.length === nearbyMarkers.length - 1) return;
        const entityIdsToAgg = markersToAgg.map(
          (m) =>
            Object.entries(markers).find(
              ([_entityId, mapMarker]) => mapMarker === m
            )[0]
        );
        entityIdsToAgg.push(entity.snowflakeEntityId);
        const pointsToAgg = points.filter(
          (point) => entityIdsToAgg.indexOf(point.snowflakeEntityId) !== -1
        );
        pointsToAgg.sort(
          (a, b) =>
            b.objectiveScores[selectedObjectiveIndex] -
            a.objectiveScores[selectedObjectiveIndex]
        );
        if (nearbyMarkers.length !== pointsToAgg.length) {
          pointsToAgg.forEach((point) => {
            if (point.snowflakeEntityId !== entity.snowflakeEntityId) {
              window.google.maps.event.trigger(
                markers[parseInt(point.snowflakeEntityId)],
                "spider_format_nearby",
                nearbyMarkerSpiderStateChangeDepth + 1
              );
            }
          });
        }

        setNearbyMarkers(pointsToAgg);
      }
    }
    setParentSpiderState(spiderState);
    if (spiderState === "SPIDERFIED") {
      onSpiderClick(nearbyMarkers);
      if (spiderfiedEntityId.length === 0) {
        setSpiderfiedEntityId(entity.snowflakeEntityId);
      }
    }
  }, [spiderState, nearbyMarkerSpiderStateChangeDepth, points]);

  let topMarkerInGroupId = "";

  if (nearbyMarkers && nearbyMarkers.length > 0) {
    for (let i = 0; i < nearbyMarkers.length; i++) {
      const m = nearbyMarkers[i];

      if (
        selectedEntity &&
        selectedEntity.snowflakeEntityId === m.snowflakeEntityId
      ) {
        topMarkerInGroupId = m.snowflakeEntityId;
        break;
      }

      if (routeEntities && routeEntities.length > 0) {
        const nearbyRouteEntity = routeEntities.find(
          (e) => e.snowflakeEntityId === m.snowflakeEntityId
        );
        if (nearbyRouteEntity) {
          topMarkerInGroupId = nearbyRouteEntity.snowflakeEntityId;
        }
      }
    }

    if (!topMarkerInGroupId) {
      topMarkerInGroupId = nearbyMarkers[0].snowflakeEntityId;
    }
  }

  const isEntitySelected =
    selectedEntity?.snowflakeEntityId === entity.snowflakeEntityId;

  const isEntityRouted =
    routeEntities &&
    routeEntities.find((e) => e.snowflakeEntityId === entity.snowflakeEntityId);

  const topMarkerInGroup = topMarkerInGroupId === entity.snowflakeEntityId;

  const bounds = map.getBounds();
  const inBounds =
    bounds &&
    marker &&
    marker.position &&
    map.getBounds().contains(marker.position);

  const spiderfiedEntityIdAndInBounds = useMemo(() => {
    if (inBounds) {
      return spiderfiedEntityId;
    }
    return "";
  }, [inBounds, spiderfiedEntityId]);

  return useMemo(
    () => (
      <>
        {spiderState !== "SPIDERFIABLE" && (
          <MarkerBackground
            isSelected={isEntitySelected}
            isRouted={isEntityRouted}
            doesSpiderExist={
              spiderfiedEntityIdAndInBounds.length > 0 &&
              spiderState !== "SPIDERFIED"
            }
          >
            <PowerScore
              score={entity.objectiveScores[selectedObjectiveIndex]}
              scoreFont="small"
            />
          </MarkerBackground>
        )}
        {spiderState === "SPIDERFIABLE" && topMarkerInGroup && (
          <MarkerBackgroundNew
            isSelected={isEntitySelected}
            isRouted={isEntityRouted}
            doesSpiderExist={spiderfiedEntityIdAndInBounds.length > 0}
          >
            <MultipleMarkerContainer>
              {nearbyMarkers.slice(0, 3).map((point, index) => (
                <PowerScorePositioner
                  key={point.snowflakeEntityId}
                  sx={{ zIndex: nearbyMarkers.length - index }}
                >
                  <PowerScore
                    score={point.objectiveScores[selectedObjectiveIndex]}
                    scoreFont={"small"}
                  />
                </PowerScorePositioner>
              ))}
              <MultipleMarkerExtraText>
                {nearbyMarkers.length > 3 ? `+${nearbyMarkers.length - 3}` : ""}
              </MultipleMarkerExtraText>
            </MultipleMarkerContainer>
          </MarkerBackgroundNew>
        )}
      </>
    ),
    [
      entity,
      spiderState,
      nearbyMarkers,
      points,
      selectedObjectiveIndex,
      isEntitySelected,
      topMarkerInGroup,
      spiderfiedEntityIdAndInBounds,
      routeEntities,
    ]
  );
};
