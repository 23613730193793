import { useContext } from 'react';
import Scrollbar from 'react-scrollbars-custom';

import { Typography, Box, Switch, Button, styled } from '@mui/material';
import { AppContext } from '../../containers/application/appContext';
import { EventLoggerList, PlaceholderBox } from './eventLoggerList';

const EventContainer = styled(Box)(({ theme: { themeColors } }) => ({
  width: 500,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: themeColors.contentCardAltBackgroundColor,
}));

const EventScrollbar = styled(Scrollbar)(() => ({
  flexGrow: 2,
  width: '100%',
}));

const EventHeader = styled(Box)(({ theme: { themeColors } }) => ({
  padding: '15px 30px',
  paddingTop: 30,
  display: 'flex',
  flexDirection: 'row',
  borderBottom: `1px solid ${themeColors.contentCardBorderColor}`,
}));

const ClearButton = styled(Box)(({ theme: { themeColors } }) => ({
  button: {
    width: '100%',
  },
  display: 'flex',
  padding: '10px 20px',
  borderTop: `1px solid ${themeColors.contentCardBorderColor}`,
}));

const EventHeaderWrapper = styled(Box)(() => ({
  flexGrow: 1,
}));

const EventTitle = styled(Typography)(() => ({
  marginBottom: 4,
}));
export const EventLoggerControls = () => {
  const { settings, setSettings } = useContext(AppContext);
  return (
    <EventHeader>
      <EventHeaderWrapper>
        <EventTitle variant="h3">Events</EventTitle>
        <Typography variant="subtitle1" color="textSecondary">
          The last 20 events, starting with the latest.
        </Typography>
      </EventHeaderWrapper>
      <Switch
        checked={settings.enableEventLogger}
        inputProps={{ 'data-testid': 'test-filter-switch' }}
        onClick={() => {
          setSettings({
            ...settings,
            enableEventLogger: !settings.enableEventLogger,
          });
        }}
      />
    </EventHeader>
  );
};

export const EventLogger = () => {
  const { settings, setEvents } = useContext(AppContext);

  if (!settings.enableEventLogger) {
    return (
      <EventContainer data-testid="events-disabled">
        <EventLoggerControls />
        <PlaceholderBox>
          <Typography>Event logging has been disabled.</Typography>
        </PlaceholderBox>
      </EventContainer>
    );
  }

  return (
    <EventContainer data-testid="events-enabled">
      <EventLoggerControls />
      <EventScrollbar disableTracksWidthCompensation noScrollX>
        <EventLoggerList />
      </EventScrollbar>
      <ClearButton>
        <Button
          onClick={() => setEvents([])}
          data-testid="clear-events"
          variant="text"
        >
          Clear Events
        </Button>
      </ClearButton>
    </EventContainer>
  );
};
