import { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { RegionSelectionModal } from '../../../../shared/RegionSelectionModal';
import { ROUTES } from '../../../../../../../constants';

export const RegionModalProvider = ({
  allRegions,
  userRegions,
  isAllRegionsLoading,
  isUserRegionsLoading,
  isOpen,
  handleClose,
}) => {
  const { projectId, regionId: regionIdFromParams } = useParams();
  const history = useHistory();
  const location = useLocation();

  const regionFromParams = !isAllRegionsLoading
    ? allRegions?.find(
        (item) =>
          item &&
          item.entity_id &&
          item.entity_id === Number(regionIdFromParams)
      )
    : undefined;

  useEffect(() => {
    if (
      regionIdFromParams &&
      !isUserRegionsLoading &&
      !regionFromParams &&
      userRegions
    ) {
      const view = location.pathname.split('/').slice(-1).join();
      const newRegion = userRegions[0];
      if (newRegion) {
        const route = `${ROUTES.PULSE}${ROUTES.PROJECTS}/${projectId}${ROUTES.REGIONS}/${newRegion.entity_id}/${view}`;
        history.push(route);
      }
    }
  }, [userRegions]);

  const handleRegionSelection = (region) => {
    if (regionIdFromParams !== region.id && region.id) {
      const newRegion = userRegions.find(
        (item) => item && item.id && item.id === region.id
      );

      if (newRegion) {
        const view = location.pathname.split('/').slice(-1).join();
        const route = `${ROUTES.PULSE}${ROUTES.PROJECTS}/${projectId}${ROUTES.REGIONS}/${newRegion.entity_id}/${view}`;
        history.push(route);
      }
    }
    handleClose();
  };
  return (
    <RegionSelectionModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleRegionSelection={handleRegionSelection}
      regionsForSearch={allRegions}
      regionList={userRegions}
    />
  );
};
