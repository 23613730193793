import { ReactNode, useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ShowDialog, DialogContext } from '../contexts';

type DialogState = {
  isOpen: boolean;
  title: string;
  content: string;
  isLoading: boolean;
  onConfirm?: () => Promise<void>;
  onCancel?: () => void;
  confirmText: ReactNode;
  loadingText?: string;
  cancelText: string;
};
const initialDialogState = {
  isOpen: false,
  title: '',
  content: '',
  isLoading: false,
  loadingText: '',
  confirmText: 'Confirm',
  cancelText: 'Cancel',
};
export const DialogProvider = ({ children }) => {
  const [dialogState, setDialogState] =
    useState<DialogState>(initialDialogState);

  const showDialog: ShowDialog = useCallback(
    ({ title, content, onConfirm, onCancel, confirmText, cancelText }) => {
      setDialogState({
        ...initialDialogState,
        isOpen: true,
        title,
        content,
        onConfirm,
        onCancel,
        confirmText: confirmText ?? initialDialogState.confirmText,
        cancelText: cancelText ?? initialDialogState.cancelText,
      });
    },
    []
  );

  const onClose = () => {
    dialogState.onCancel?.();
    setDialogState((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const onConfirm = async () => {
    setDialogState((prev) => ({ ...prev, isLoading: true }));
    await dialogState.onConfirm();
    setDialogState((prev) => ({
      ...prev,
      isLoading: false,
      isOpen: false,
    }));
  };

  return (
    <DialogContext.Provider value={showDialog}>
      {children}
      <Dialog
        open={dialogState.isOpen}
        onClose={onClose}
        sx={{
          '.MuiDialog-paper': {
            background: (theme) => theme.themeColors.modalBackgroundColor,
            border: (theme) =>
              `1px solid ${theme.themeColors.borderPrimaryColor}`,
            maxWidth: '400px',
          },
        }}
      >
        <DialogTitle
          sx={{
            borderBottom: (theme) =>
              `1px solid ${theme.themeColors.borderPrimaryColor}`,
          }}
        >
          {dialogState.title}
        </DialogTitle>
        <DialogContent
          sx={{
            padding: (theme) => `${theme.spacing(3)} !important`,
            borderBottom: (theme) =>
              `1px solid ${theme.themeColors.borderPrimaryColor}`,
            color: (theme) => theme.themeColors.secondaryTextColor,
          }}
        >
          {dialogState.content}
        </DialogContent>
        <DialogActions sx={{ alignItems: 'unset', padding: 2 }}>
          <Button variant="outlined" onClick={onClose}>
            {dialogState.cancelText}
          </Button>
          <Button
            variant="contained"
            onClick={onConfirm}
            endIcon={
              dialogState.isLoading && (
                <CircularProgress size={16} color="inherit" />
              )
            }
          >
            {dialogState.isLoading && dialogState.loadingText
              ? dialogState.loadingText
              : dialogState.confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </DialogContext.Provider>
  );
};
