import React, { useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';
import { CategoricalDataCard } from './categoricalDataCard';
import {
  DataItem,
  useCategoricalData,
} from '../../../hooks/useCategoricalData';

const Wrapper = styled(Box)(({ theme: { themeColors } }) => ({
  marginBottom: 16,
  color: themeColors.primaryMaptualListFilteringColor,
}));

const HorizontalWrapper = styled(Wrapper)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gridGap: 15,
}));

const VerticalWrapper = styled(Wrapper)(() => ({
  display: 'grid',
  gridGap: 15,
  gridTemplateColumns: '1fr 1fr',
}));

interface CategoricalDataWrapperProps {
  entityId: string;
  projectId: string;
  objectiveId: string;
}

export const CategoricalDataWrapper: React.FC<CategoricalDataWrapperProps> = ({
  entityId,
  projectId,
  objectiveId,
}) => {
  const { categoricalData } = useCategoricalData(entityId, projectId);

  const [data, setData] = useState<DataItem[] | []>([]);

  useEffect(() => {
    if (categoricalData && objectiveId) {
      const objectiveCategoricalData = categoricalData[objectiveId];

      setData(objectiveCategoricalData);
    }

    if (categoricalData && !objectiveId) {
      const data = Object.values(categoricalData)[0];
      setData(data);
    }

    return () => {
      setData([]);
    };
  }, [objectiveId, categoricalData]);

  if (!Array.isArray(data) || !data.length) {
    return null;
  }

  if (data.length > 1) {
    return (
      <VerticalWrapper data-testid="vertical-wrapper">
        {data.map((card) => (
          <CategoricalDataCard
            data={card}
            key={card.title}
            orientation="vertical"
          />
        ))}
      </VerticalWrapper>
    );
  }

  return (
    <HorizontalWrapper data-testid="horizontal-wrapper">
      {data.map((card) => (
        <CategoricalDataCard data={card} key={card.title} />
      ))}
    </HorizontalWrapper>
  );
};
