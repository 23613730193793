import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  getMarkets,
  getObjectiveTypes,
} from '../../request/newProjectRequests';
import NewProjectModal from './newProjectModal';
import { NewProjectContext } from './newProjectContext';
import NewObjectiveModal from './newObjectiveModal';
import NewProjectConfirmationModal from './newProjectConfirmationModal';
import NewProjectSuccessModal from './newProjectSuccessModal';
import NewProjectSummaryModal from './newProjectSummaryModal';
import NewProjectCloseModal from './NewProjectCloseModal';
import { useDeployDialogContext } from '../projectviewer/deployCsvDialog/deployDialogContext';
import { transformExistingToNew } from './newProjectTransformation';
import { PROJECT_CREATION_STEPS } from './common/constants';
import { ProjectModalMode } from './projectModalMode';
import { getProjectName } from './common/utils';
import { AssetMigrationModal } from './assetMigrationModal';
import NewProjectErrorModal from './newProjectErrorModal';

export function NewProjectProvider({ isOpen }) {
  const {
    projects,
    setActiveProject,
    activeProject: selectedProjectForViewing,
    projectModalMode,
    isTemplate,
    setProjectModalMode,
    setShowProjectDialog,
  } = useDeployDialogContext();

  const [projectCreationStep, setProjectCreationStep] = useState(
    PROJECT_CREATION_STEPS.PROJECT
  );

  const initialProjectConfigState = useMemo(
    () => ({
      projectName: getProjectName(isTemplate),
      productLineName: '',
      productLineId: '',
      marketId: '',
      changeLog: '',
      dueDate: new Date(),
    }),
    [isTemplate]
  );

  const [projectConfig, setProjectConfig] = useState(initialProjectConfigState);
  const [objectives, setObjectives] = useState([]);
  const [objectiveType, setObjectiveType] = useState({});
  const [baskets, setBaskets] = useState([]);
  const [objectiveBeingEdited, setObjectiveBeingEdited] = useState('');
  const [shareMetric, setShareMetric] = useState(null);
  const [nonScoringObjective, setNonScoringObjective] = useState(false);
  const existingProjectSelected = Object.entries(
    selectedProjectForViewing
  ).length;

  useEffect(() => {
    if (existingProjectSelected) {
      const {
        projectConfig: existingProjectConfig,
        objectives: existingObjectives,
      } = transformExistingToNew(selectedProjectForViewing, false);

      switch (projectModalMode) {
        case ProjectModalMode.viewProject:
          setProjectCreationStep(PROJECT_CREATION_STEPS.SUMMARY);
          setProjectConfig(existingProjectConfig);
          break;
        case ProjectModalMode.templateCreate:
          setProjectConfig((prev) => ({
            ...prev,
            projectName: getProjectName(isTemplate),
          }));
          break;
        case ProjectModalMode.templateEdit:
          setProjectConfig((prev) => ({
            ...prev,
            projectName: selectedProjectForViewing.projectName,
            productLineId: selectedProjectForViewing?.productLineId ?? '',
            marketId: selectedProjectForViewing?.marketId,
            productLineName: selectedProjectForViewing?.productLineName,
          }));
          break;
        case ProjectModalMode.templateClone:
          setProjectConfig((prev) => ({
            ...prev,
            projectName: `Clone of ${selectedProjectForViewing.projectName}`,
            productLineId: selectedProjectForViewing?.productLineId ?? '',
            marketId: selectedProjectForViewing?.marketId,
            productLineName: selectedProjectForViewing?.productLineName,
          }));
          break;
        case ProjectModalMode.runProjectFromTemplate:
          setProjectConfig((prev) => ({
            ...prev,
            projectName: getProjectName(isTemplate),
            productLineId: selectedProjectForViewing?.productLineId ?? '',
            marketId: selectedProjectForViewing?.marketId,
            productLineName: selectedProjectForViewing?.productLineName,
          }));
          setProjectCreationStep(PROJECT_CREATION_STEPS.ASSET_MIGRATION);
          break;
        default:
          break;
      }
      setObjectives(existingObjectives);
    }
  }, [projectModalMode, selectedProjectForViewing]);

  const resetProjectModal = () => {
    setProjectCreationStep(PROJECT_CREATION_STEPS.PROJECT);

    setProjectConfig(initialProjectConfigState);
    setObjectives([]);
    setObjectiveType({});

    setActiveProject({});
    setProjectModalMode('');
  };

  const closeForm = () => {
    resetProjectModal();
    setShowProjectDialog(false);
  };

  const markets = useQuery('productLine', async () => {
    const response = await getMarkets();
    return JSON.parse(response.data);
  }).data;

  const typesQuery = useQuery('objectiveTypes', async () => {
    const response = await getObjectiveTypes();
    return JSON.parse(response.data);
  }).data;

  const productLineRoots = useMemo(
    () =>
      Array.isArray(markets)
        ? markets.map((market) => ({
            ...market.productLines[0],
            productLine: market.market,
            marketId: market.marketId,
          }))
        : [],
    [markets]
  );

  const objectiveTypes = Array.isArray(typesQuery) ? typesQuery : [];

  const market =
    projectConfig.productLineId !== ''
      ? markets?.find(
          (x) => x.productLines[0].productLineId === projectConfig.productLineId
        )
      : [];

  const {
    basketsScoringWeight: marketBasketsScoringWeight,
    metricsScoringWeight,
    entityMetricsScoringWeight,
    indications,
  } = market;
  const specialties = market.specialty;

  const therapeuticAreas =
    market.length > 0 ? market.productLines[0]?.therapeuticAreas : [];
  const contextValues = useMemo(
    () => ({
      projectCreationStep,
      setProjectCreationStep,
      projectConfig,
      setProjectConfig,
      objectives,
      setObjectives,
      objectiveType,
      setObjectiveType,
      objectiveTypes,
      indications,
      specialties,
      therapeuticAreas,
      marketBasketsScoringWeight,
      metricsScoringWeight,
      entityMetricsScoringWeight,
      resetProjectModal,
      objectiveBeingEdited,
      setObjectiveBeingEdited,
      baskets,
      setBaskets,
      shareMetric,
      setShareMetric,
      nonScoringObjective,
      setNonScoringObjective,
    }),
    [
      projectCreationStep,
      setProjectCreationStep,
      projectConfig,
      setProjectConfig,
      objectives,
      setObjectives,
      objectiveType,
      setObjectiveType,
      objectiveTypes,
      indications,
      specialties,
      therapeuticAreas,
      marketBasketsScoringWeight,
      metricsScoringWeight,
      entityMetricsScoringWeight,
      resetProjectModal,
      objectiveBeingEdited,
      setObjectiveBeingEdited,
      baskets,
      setBaskets,
      shareMetric,
      setShareMetric,
      nonScoringObjective,
      setNonScoringObjective,
    ]
  );

  return (
    <NewProjectContext.Provider value={contextValues}>
      <NewProjectModal
        isOpen={
          isOpen && projectCreationStep === PROJECT_CREATION_STEPS.PROJECT
        }
        productLines={productLineRoots}
        closeForm={closeForm}
      />
      <AssetMigrationModal
        isOpen={
          isOpen &&
          projectCreationStep === PROJECT_CREATION_STEPS.ASSET_MIGRATION
        }
        projects={projects}
        onClose={closeForm}
      />
      <NewObjectiveModal
        isOpen={
          isOpen && projectCreationStep === PROJECT_CREATION_STEPS.OBJECTIVE
        }
        productLines={productLineRoots}
      />
      <NewProjectSummaryModal
        isOpen={
          isOpen && projectCreationStep === PROJECT_CREATION_STEPS.SUMMARY
        }
        closeForm={closeForm}
      />
      <NewProjectConfirmationModal
        isOpen={
          isOpen && projectCreationStep === PROJECT_CREATION_STEPS.CONFIRMATION
        }
        onClose={() => setProjectCreationStep(PROJECT_CREATION_STEPS.SUMMARY)}
      />
      <NewProjectSuccessModal
        isOpen={
          isOpen && projectCreationStep === PROJECT_CREATION_STEPS.SUCCESS
        }
        onClose={closeForm}
      />
      <NewProjectErrorModal
        isOpen={isOpen && projectCreationStep === PROJECT_CREATION_STEPS.ERROR}
        onClose={closeForm}
      />
      <NewProjectCloseModal
        isOpen={
          isOpen && projectCreationStep === PROJECT_CREATION_STEPS.CLOSE_PROJECT
        }
        onClose={() => setProjectCreationStep(PROJECT_CREATION_STEPS.PROJECT)}
        handleDiscard={closeForm}
      />
    </NewProjectContext.Provider>
  );
}
