import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { styled, Box } from '@mui/material';
import { AccountCard } from '../../../../../components/accountCard/accountCard';
import { NavigationButton } from '../../shared/navigationButton';
import { ViewsList } from './viewList/ViewsList';
import { NavigationContainer } from '../../shared/NavigationContainer';
import { ROUTES } from '../../../../../constants';
import { AppContext } from '../../../appContext';
import { PulseContext } from '../context/PulseContext';
import ProjectSelector from '../../shared/projectSelector';

const Root = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  padding: '32px 16px 0px 16px',
}));

const StyledButtonContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  marginLeft: '16px',
  marginTop: '26px',
}));

export const Navigation = () => {
  const { isProjectListLoading, projectList } = useContext(AppContext);
  const { navigationOpen, setNavigationOpen } = useContext(PulseContext);
  const { projectId: projectIdFromParams } = useParams();
  const history = useHistory();

  const projectFromParams = projectList.find(
    (item) => item && item.projectId && item.projectId === projectIdFromParams
  );

  const handleProjectChange = (event) => {
    if (
      projectFromParams.projectId !== event.target.value &&
      event.target.value
    ) {
      const newProj = projectList.find(
        (item) =>
          item && item.projectId && item.projectId === event.target.value
      );

      if (newProj) {
        const route = `${ROUTES.PULSE}${ROUTES.PROJECTS}/${newProj.projectId}`;
        history.push(route);
      }
    }
  };

  const handleNavigationClick = () => {
    setNavigationOpen(!navigationOpen);
  };

  return (
    <NavigationContainer navigationOpen={navigationOpen}>
      <Root>
        <StyledButtonContainer>
          <NavigationButton
            navigationOpen={navigationOpen}
            handleClick={handleNavigationClick}
          />
        </StyledButtonContainer>
        <ProjectSelector
          isProjectListLoading={isProjectListLoading}
          projectList={projectList}
          project={projectFromParams}
          handleProjectChange={handleProjectChange}
        />
        <ViewsList />
      </Root>
      <AccountCard />
    </NavigationContainer>
  );
};
