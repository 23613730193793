import '@fontsource/roboto';
import 'typeface-roboto';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { GoogleMapsProvider } from '@odaia/shared-components/src/providers/googleMapsApiProvider';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

import { SnackbarProvider } from '@odaia/ui/src/components/snackbar';
import Application from './application';
import { AppContext } from './appContext';
import { ThemeContext } from './themeContext';
import { useApplicationContext } from './useApplicationContext';
import { useThemeContext } from './useThemeContext';
import { AxiosInterceptor } from './axiosInterceptor';
import { DialogProvider } from '../../providers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

export const ApplicationWrapper = () => {
  const groupedContext = useApplicationContext();
  const themeContext = useThemeContext(groupedContext);
  return (
    <StyledEngineProvider injectFirst>
      <GoogleMapsProvider
        apiKey={import.meta.env.VITE_MAPS_KEY}
        libraries={['core', 'maps', 'marker']}
      >
        <ThemeContext.Provider value={themeContext}>
          <ThemeProvider theme={themeContext.activeTheme}>
            <AppContext.Provider value={groupedContext}>
              <AxiosInterceptor>
                <DialogProvider>
                  <SnackbarProvider>
                    <Application />
                  </SnackbarProvider>
                </DialogProvider>
              </AxiosInterceptor>
            </AppContext.Provider>
          </ThemeProvider>
        </ThemeContext.Provider>
      </GoogleMapsProvider>
    </StyledEngineProvider>
  );
};
