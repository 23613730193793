import { v4 as uuidv4 } from 'uuid';
import { ParamType } from '@odaia/clients/maptualAPI';
import { formatDateToString } from './common/formatters';

const projectConfigBase = {
  projectName: '',
  productLineName: '',
  productLineId: '',
};

const objectiveBase = {
  objectiveType: {
    features: [],
    typeDisplay: '',
    icon: null,
    typeDescription: '',
    metrics: [],
    params: {
      products: [],
    },
    type: '',
  },
  baskets: [],
  uuid: '',
};

const basketSortKey = {
  TO_PRODUCT: 1,
  PRODUCT_FROM: 2,
  ANALOUGE_PRODUCT: 3,
  PRECURSOR_PRODUCT: 4,
};

export const IMPORT_TYPES = {
  CLONE_WITH_SCORES: 'cloneWithScores',
  NONE: '',
};

export const transformExistingToNew = (
  existingProject,
  isCloneWithoutScoreMigration = false
) => {
  const projectConfig = {
    ...projectConfigBase,
    projectName: existingProject.projectName,
    projectImportId: existingProject.projectId,
    productLineId: existingProject.productLineId,
    productLineName: existingProject.productLine,
    dueDate: existingProject.dueDate,
    changeLog: existingProject.changeLog,
    importType: isCloneWithoutScoreMigration
      ? IMPORT_TYPES.NONE
      : IMPORT_TYPES.CLONE_WITH_SCORES,
  };
  const objectives = existingProject.objectives.map((objective) => ({
    ...objectiveBase,
    ...(objective?.shareMetric?.uuid && objective?.shareMetric?.rxType
      ? { shareMetric: objective.shareMetric }
      : {}),
    nonScoringObjective: !!objective.nonScoringObjective,
    objectiveType: {
      typeDisplay: objective.typeDisplay,
      params: {
        products: objective.params.products.map((param) => ({
          paramType: param.paramType,
          productLine: null,
          paramText: param.paramText,
          productFriendlyName: param.productFriendlyName,
        })),
      },
      type: objective.type,
    },
    baskets: objective.params.products
      .slice()
      .sort((a, b) => basketSortKey[a.paramType] - basketSortKey[b.paramType])
      .map((param, index) => ({
        basketName: param.productFriendlyName,
        basketText: param.paramText,
        basketType: param.paramType,
        basketKey: index,
        uuid: uuidv4(),
        basketScoringWeight: param.basketScoringWeight,
        therapeuticAreas: param.therapeuticAreas || [],
        products: param.productLines,
        indications: param.indications || [],
        specialties: param.specialties || [],
        metrics: setMetricStructure(param),
      })),
    uuid: uuidv4(),
  }));
  return {
    projectConfig,
    objectives,
  };
};

const convertBasketMetricToFormMetric = ({
  rxType,
  rxSourceType,
  scoringWeight,
  displayPriority,
}) => ({
  metricRxType: rxType,
  rxSourceType,
  scoringWeight,
  visualize: !!displayPriority,
});

const setMetricStructure = (basketConfig) => {
  if (basketConfig.basketMetrics) {
    return basketConfig.basketMetrics.reduce(
      (acc, metric) => ({
        ...acc,
        [metric.entity]: [
          ...(acc[metric.entity] || []),
          convertBasketMetricToFormMetric(metric),
        ],
      }),
      {}
    );
  }
  if (!basketConfig.entityMetricsScoringWeight) {
    return {
      hcp: basketConfig.metricsScoringWeight
        ? Object.entries(basketConfig.metricsScoringWeight).map(
            ([rxType, scoringWeight]) => ({
              metricRxType: rxType,
              scoringWeight,
              visualize: basketConfig.displayableMetrics
                ? rxType in basketConfig.displayableMetrics
                : true,
            })
          )
        : [],
    };
  }
  const res = {};
  Object.entries(basketConfig.entityMetricsScoringWeight).forEach(
    ([entityName, entityScoringWeights]) => {
      res[entityName] = Object.entries(entityScoringWeights).map(
        ([rxType, scoringWeight]) => ({
          metricRxType: rxType,
          scoringWeight,
          visualize:
            basketConfig?.entityDisplayableMetrics?.[entityName] &&
            rxType in basketConfig.entityDisplayableMetrics[entityName],
        })
      );
    }
  );
  return res;
};

const basketFormDataToPayload = (basket, objectiveShareMetric) => ({
  basketScoringWeight: basket.basketScoringWeight,
  metricsScoringWeight: basket.metrics?.hcp
    ? newMetricStructure(basket.metrics.hcp)
    : {},
  entityMetricsScoringWeight: formatEntityMetricsScoringWeight(basket.metrics),
  basketMetrics: handleBasketMetrics(basket, objectiveShareMetric),
  displayableMetrics: displayableMetricsStructure(
    basket.metrics?.hcp ? basket.metrics.hcp : []
  ),
  entityDisplayableMetrics: entityDisplayableMetricsStructure(basket.metrics),
  specialties: basket.specialties,
  indications: basket.indications,
  therapeuticAreas: basket.therapeuticAreas,
  paramType: basket.basketType,
  paramText: basket.basketText,
  productFriendlyName: basket.basketName,
  productLines: basket.products,
});

const handleBasketMetrics = (basket, objectiveShareMetric) => {
  let basketMetrics = [];
  if (
    basket.basketType === ParamType.ToProduct ||
    basket.basketType === ParamType.ProductFrom
  ) {
    basketMetrics = generateTotalBasketMetrics(
      transformBasketMetrics(basket.metrics),
      objectiveShareMetric
    );
  } else {
    basketMetrics = transformBasketMetrics(basket.metrics);
  }
  return basketMetrics;
};

export const transformNewToExisting = ({
  projectConfig,
  objectives,
  templateId,
}) => ({
  projectName: projectConfig.projectName,
  projectImportId: projectConfig.projectImportId || undefined,
  templateId,
  productLineId: projectConfig.productLineId,
  dueDate: formatDateToString(projectConfig.dueDate),
  changeLog: projectConfig.changeLog,
  importType: projectConfig.importType || IMPORT_TYPES.NONE,
  objectives: objectives.map((objective) => ({
    type: objective.objectiveType.type,
    typeDisplay: objective.objectiveType.typeDisplay,
    icon: objective.objectiveType.icon,
    ...(objective?.shareMetric?.uuid && objective?.shareMetric?.rxType
      ? { shareMetric: objective.shareMetric }
      : {}),
    nonScoringObjective: objective.nonScoringObjective,
    params: {
      products: objective.baskets.map((basket) =>
        basketFormDataToPayload(basket, objective?.shareMetric)
      ),
    },
  })),
});

const transformShareMetricToBasketMetricEntity = (shareMetric) => {
  const { rxType, rxSourceType } = shareMetric;
  return {
    rxType,
    entity: 'hcp',
    rxSourceType,
    scoringWeight: 0,
    displayPriority: null,
  };
};

const generateTotalBasketMetrics = (scriptBasketMetrics, shareMetric) => {
  if (shareMetric === undefined || shareMetric === null) {
    return scriptBasketMetrics;
  }

  const shareMetricEntity =
    transformShareMetricToBasketMetricEntity(shareMetric);

  const shareMetricExists = scriptBasketMetrics.find(
    (metric) =>
      metric.metricRxType === shareMetricEntity.metricRxType &&
      metric.rxType === shareMetricEntity.rxType &&
      metric.entity === 'hcp'
  );

  if (!shareMetricExists) {
    scriptBasketMetrics.push(shareMetricEntity);
  }
  return scriptBasketMetrics;
};
export const transformToTemplatePayload = ({ projectConfig, objectives }) => ({
  templateName: projectConfig.projectName,
  productLineId: projectConfig.productLineId,
  marketId: projectConfig.marketId,
  marketName: projectConfig.productLineName,
  objectives: objectives.map((objective) => ({
    type: objective.objectiveType.type,
    typeDisplay: objective.objectiveType.typeDisplay,
    nonScoringObjective: objective.nonScoringObjective,
    baskets: objective.baskets.map((basket) =>
      basketFormDataToPayload(basket, objective?.shareMetric)
    ),
    indications: objective.indications ?? [],
    ...(objective?.shareMetric?.uuid && objective?.shareMetric?.rxType
      ? { shareMetric: objective.shareMetric }
      : {}),
    therapeuticAreas: objective.therapeuticAreas ?? [],
  })),
});

/*
  Useful for performing a diff between the existing template
  and the edited version to see if something changed.
 */
export const transformTemplateEntityToPayload = (template) => {
  const templateCopy = { ...template };
  delete templateCopy.id;
  delete templateCopy.createdAt;
  delete templateCopy.updatedAt;
  templateCopy.objectives = templateCopy.objectives.map(
    ({ shareMetric, ...rest }) => ({
      ...rest,
      ...(shareMetric ? { shareMetric } : {}),
    })
  );
  return templateCopy;
};

const formatEntityMetricsScoringWeight = (entityMetricsScoringWeight) => {
  const newEntityMetricsScoringWeight = {};
  Object.entries(entityMetricsScoringWeight).forEach(([key, value]) => {
    newEntityMetricsScoringWeight[key] = newMetricStructure(value);
  });
  return newEntityMetricsScoringWeight;
};

const newMetricStructure = (metrics) =>
  metrics.reduce((acc, metric) => {
    const { metricRxType, scoringWeight } = metric;
    return {
      ...acc,
      [metricRxType]: scoringWeight,
    };
  }, {});

// Order descending by scoring weight, and alphabetically by metricRxType if a tie
const metricSortFunction = (a, b) =>
  b.scoringWeight - a.scoringWeight ||
  a.metricRxType.localeCompare(b.metricRxType);

const displayableMetricsStructure = (metrics) => {
  const metricsToVisualize = metrics.filter((metric) => metric.visualize);

  const orderedMetrics = metricsToVisualize.sort(metricSortFunction);

  return Object.fromEntries(
    orderedMetrics.map((metric, index) => [metric.metricRxType, index + 1])
  );
};

const getTransformedMetric = (metric, entity, displayPriority = null) => {
  const { metricRxType, rxSourceType, scoringWeight } = metric;
  return {
    entity,
    rxType: metricRxType,
    rxSourceType,
    scoringWeight,
    displayPriority,
  };
};

const transformBasketMetricsForEntity = (metrics, entity) => {
  const sortedMetrics = metrics.sort(metricSortFunction);

  const transformedMetrics = [];
  let displayPriority = 1;
  for (let i = 0; i < sortedMetrics.length; i++) {
    const metric = sortedMetrics[i];
    if (metric.visualize) {
      transformedMetrics.push(
        getTransformedMetric(metric, entity, displayPriority)
      );
      displayPriority++;
    } else {
      transformedMetrics.push(getTransformedMetric(metric, entity));
    }
  }
  return transformedMetrics.filter((metric) =>
    metricIsScoredOrVisualized(metric)
  );
};

const metricIsScoredOrVisualized = (metric) =>
  metric.scoringWeight || metric.displayPriority;

const entityDisplayableMetricsStructure = (metrics) =>
  Object.entries(metrics).reduce((acc, [metricEntityType, metricList]) => {
    acc[metricEntityType] = displayableMetricsStructure(metricList);
    return acc;
  }, {});

const transformBasketMetrics = (metrics) =>
  Object.entries(metrics).reduce(
    (acc, [metricEntityType, metricList]) => [
      ...acc,
      ...transformBasketMetricsForEntity(metricList, metricEntityType),
    ],
    []
  );
export const templateToProject = (template) => ({
  ...template,
  projectName: template.templateName,
  templateName: template.templateName,
  productLineName: template.marketName,
  objectives: template.objectives.map((objective) => ({
    ...objective,
    params: {
      products: objective.baskets,
    },
  })),
});
