import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import '@fontsource/roboto';
import { Box } from '@mui/system';
import { themeColors as adminColors } from '../../styles/adminStyles';
import { ROUTES } from '../../constants';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 'auto',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
    marginTop: '1.8vw',
    paddingLeft: '3vw',
    paddingRight: '3vw',
  },
  breadCrumbs: {},
  linkTextStyle: {
    fontWeight: 600,
    fontSize: 20.25,
    color: adminColors.darkTheme.maptualPageTitle,
  },
  linkStyle: {
    padding: 0,
    margin: 0,
    backgroundColor: '#00000000',
    color: 'textSecondary',
  },
  iconLinkContainer: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function ProjectControl({
  customCrumb = null,
  injectableControls = [],
}) {
  const classes = useStyles();
  const routeHistory = useHistory();
  const { themeColors } = useTheme();
  const isAdminProjectsPage = useRouteMatch(
    ROUTES.ADMIN + ROUTES.PROJECTS
  )?.isExact;

  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumbs}>
        <Fade in timeout={800}>
          <Link
            color="textSecondary"
            className={classes.linkStyle}
            component="button"
            style={{ marginRight: '0.8rem' }}
            onClick={() => {
              routeHistory.push(ROUTES.ROOT);
            }}
            underline="hover"
          >
            <div className={classes.iconLinkContainer}>
              <Typography className={classes.linkTextStyle}>
                Projects
              </Typography>
            </div>
          </Link>
        </Fade>

        {customCrumb ? (
          <Grow in style={{ transformOrigin: '0 0 0' }} timeout={500}>
            <Link
              color="textSecondary"
              className={classes.linkStyle}
              style={{
                marginLeft: '0.8rem',
                color: themeColors.secondaryTextColorHighContrast,
              }}
              aria-current="page"
              onClick={customCrumb.action ? customCrumb.action : null}
              component="button"
              underline="hover"
            >
              {customCrumb.component}
            </Link>
          </Grow>
        ) : null}
      </Breadcrumbs>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexGrow: 1,
          alignItems: 'center',
          '&>div': {
            flexGrow: isAdminProjectsPage ? 1 : 0,
          },
        }}
      >
        {Array.isArray(injectableControls)
          ? injectableControls.map((item, i) => (
              <Fade
                in
                timeout={800 + i * 200}
                key={i}
                data-testid="test-top-bar"
              >
                <div>{item}</div>
              </Fade>
            ))
          : null}
      </Box>
    </div>
  );
}

ProjectControl.propTypes = {
  customCrumb: PropTypes.shape({
    component: PropTypes.element,
    action: PropTypes.func,
  }),
  injectableControls: PropTypes.arrayOf(PropTypes.element),
};
