import { StyledTextField } from '../styles/styledComponents';

const TextInput = ({
  label,
  id,
  onChange,
  error,
  inputProps = null,
  type = 'text',
  errorMessage = 'Required',
  value = '',
}) => {
  const inputPropsWithDefault = inputProps || {
    onBlur: (ev) => (!ev.target.value ? onChange(id, '') : null),
  };
  return (
    <StyledTextField
      id="outlined-basic"
      label={`${label}*`}
      value={value}
      error={error?.[id]}
      helperText={error?.[id] ? errorMessage : ''}
      variant="outlined"
      fullWidth
      onChange={(ev) => {
        onChange(id, ev.target.value);
      }}
      inputProps={inputPropsWithDefault}
      type={type}
      name={id}
    />
  );
};

export default TextInput;
