import {
  UserManagementApi,
  CoreApi,
  FieldApi,
  ProjectsApi,
} from '@odaia/clients/maptualAPI';
import { baseFastInstance, baseFastUrl } from './config';
import { apiInstanceCreator } from './utils';

const createApiInstance = apiInstanceCreator(baseFastUrl, baseFastInstance);
export const userManagementApi = createApiInstance(UserManagementApi);

export const coreApi = createApiInstance(CoreApi);

export const fieldApi = createApiInstance(FieldApi);
export const projectsApi = createApiInstance(ProjectsApi);
