import { styled, Button } from '@mui/material';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { ROUTES } from '../../../../../../constants';

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const StyledViewListButton = styled(Button, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
})(({ theme: { themeColors }, isActive }) => ({
  width: '100%',
  padding: '12px',
  minHeight: '24px',
  justifyContent: 'flex-start',
  color: isActive
    ? themeColors.buttonActiveContentColor
    : themeColors.buttonContentColor,
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '24px',
  backgroundColor: isActive
    ? themeColors.buttonSecondaryBackgroundColour
    : 'none',
  '&:hover': {
    backgroundColor: isActive
      ? themeColors.buttonSecondaryBackgroundColour
      : themeColors.buttonHoverBackgroundColor,
    color: themeColors.buttonHoverContentColor,
  },
  '& .MuiSvgIcon-root': {
    color: themeColors.listIconColor,
  },
}));

export const ViewsList = () => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const routeContains = (route) => location.pathname.includes(route);
  const handleViewListButtonClick = (view) => {
    if (routeContains(view)) {
      return;
    }
    const route = `${ROUTES.PULSE}${ROUTES.PROJECTS}/${params?.projectId}`;
    history.push(route);
  };

  return (
    <Container>
      <StyledViewListButton
        isActive={routeContains(ROUTES.SALES_REPS)}
        startIcon={<ConnectWithoutContactIcon />}
        onClick={() => handleViewListButtonClick(ROUTES.SALES_REPS)}
      >
        Sales Reps
      </StyledViewListButton>
    </Container>
  );
};
