import makeStyles from '@mui/styles/makeStyles';
import { Grow, LinearProgress, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    'align-items': 'center',
    flex: 1,
    'flex-direction': 'column',
    'justify-content': 'center',
    overflowX: 'auto',
  },
  labelGroup: {
    'justify-content': 'flex-end',
    'flex-direction': 'column',
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    'text-align': 'left',
    padding: theme.spacing(1),
    maxWidth: '600px',
    fontSize: 16,
  },
  subtitle2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    'text-align': 'left',
    padding: theme.spacing(1),
    maxWidth: '600px',
    fontSize: 24,
    color: theme.palette.secondary.light,
  },
  iconStyle: {
    width: '150px',
    height: '150px',
  },
}));

export default function IngestionConfirmation({
  ingest,
  updateDataUploadProgress,
  confirmDataUpload,
  onComplete,
}) {
  const classes = useStyles();
  // eslint-disable-next-line no-param-reassign
  onComplete.action = () => {
    confirmDataUpload(
      ingest.dataset,
      ingest.dataFile,
      ingest.isNewDataset,
      updateDataUploadProgress
    );
  };

  return (
    <div className={classes.container}>
      <Grow in>
        <div className={classes.labelGroup}>
          {ingest.isBusy || ingest.dataUploadPercentage === 100.0 ? (
            <Typography className={classes.title} variant="h1" component="h3">
              {`${ingest.dataUploadPercentage}%`}
            </Typography>
          ) : (
            <InfoIcon className={classes.iconStyle} />
          )}
          <Typography className={classes.title} variant="h2" component="h3">
            {ingest.isBusy ? 'Processing ...' : 'Confirmation'}
          </Typography>
          {ingest.isBusy ? (
            <>
              <Typography
                className={classes.subtitle2}
                variant="h5"
                color="textSecondary"
              >
                DO NOT leave this page while data is being processed!
              </Typography>
              <br />
              <LinearProgress />
            </>
          ) : (
            <Typography className={classes.subtitle} variant="h5">
              Your dataset is about to be processed now. Please be patient and
              make sure that you keep this page open and uninterrupted after
              clicking 'Finish'
            </Typography>
          )}
        </div>
      </Grow>
    </div>
  );
}
