import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material';
import { colorToneMapping } from '@odaia/ui/src/colorConfig';
import { BodyTypography } from '../projectviewer/deployCsvDialog/sharedComponents';

const CustomTableBody = styled(TableBody)(({ theme: { themeColors } }) => ({
  background: themeColors.mainSurfaceBackground,
  border: '1px solid #313B49',
}));

const CustomTableContainer = styled(TableContainer)(() => ({
  border: '1px solid #313B49',
}));

const StackTraceStyle = styled(BodyTypography)(() => ({
  fontWeight: 400,
  fontSize: 14,
  paddingTop: 5,
}));

export default function TestDetailTableView({ passed, failed }) {
  return (
    <CustomTableContainer component={Paper}>
      <Table>
        <CustomTableBody>
          {failed.map((row) => (
            <TableRow key={row.name}>
              <TableCell>
                <>
                  <Typography variant="body2">{row.name}</Typography>
                  <StackTraceStyle variant="body2">
                    {row.stackTrace}
                  </StackTraceStyle>
                </>
              </TableCell>
              <TableCell>
                <ErrorIcon style={{ color: colorToneMapping.NEGATIVE }} />
              </TableCell>
            </TableRow>
          ))}
          {passed.map((row) => (
            <TableRow key={row}>
              <TableCell>{row}</TableCell>
              <TableCell>
                <CheckCircleIcon style={{ color: colorToneMapping.POSITIVE }} />
              </TableCell>
            </TableRow>
          ))}
        </CustomTableBody>
      </Table>
    </CustomTableContainer>
  );
}
