import makeStyles from '@mui/styles/makeStyles';
import { Grow, Typography } from '@mui/material';
import ChunkDropZone from '../../controls/DropZone';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    'align-items': 'center',
    flex: 1,
    'flex-direction': 'column',
    'justify-content': 'center',
  },
  labelGroup: {
    'justify-content': 'flex-end',
    'flex-direction': 'column',
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    'text-align': 'left',
    padding: theme.spacing(1),
  },
  paddingBox: {},
  datadrop: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '70%',
    height: 'auto',
  },
}));

export default function IngestionSelectData({
  ingestNav,
  setDataFile,
  ingest,
  sampleDataFile,
  validateDataFile,
  clearErrorMsg,
  myIndex,
  onComplete,
}) {
  const classes = useStyles();
  if (ingest.isNewDataset) {
    // eslint-disable-next-line no-param-reassign
    onComplete.action = () => {
      sampleDataFile(ingest.dataFile);
    };
  } else {
    const { dataColumns } = ingest.customerDatasets[ingest.dataset.datasetName];
    // eslint-disable-next-line no-param-reassign
    onComplete.action = () => {
      validateDataFile(ingest.dataFile, dataColumns);
    };
  }

  return (
    <div className={classes.container}>
      <Grow in timeout={200}>
        <div className={classes.labelGroup}>
          <div className={classes.paddingBox} />
          <Typography className={classes.title} variant="h2" component="h3">
            Select a Data File
          </Typography>
          <Typography className={classes.subtitle} variant="h5">
            Accepted file format: .csv
          </Typography>
        </div>
      </Grow>
      <Grow in timeout={1000}>
        <div className={classes.datadrop}>
          <ChunkDropZone
            height="300px"
            setDataFile={setDataFile}
            fileName={ingest.dataFileName}
            clearErrorMsg={clearErrorMsg}
            disabled={ingestNav.completedSteps[myIndex] || ingest.isBusy}
          />
        </div>
      </Grow>
      {ingest.errorMessage ? (
        <Typography className={classes.subtitle} variant="body1" color="error">
          ERROR: {ingest.errorMessage}
        </Typography>
      ) : null}
    </div>
  );
}
