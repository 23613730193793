/* eslint-disable import/no-cycle */

import { useEffect, useState, useContext } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import makeStyles from '@mui/styles/makeStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { darkThemeColors } from '@odaia/ui/src/colorConfig';
import { trackSecondaryTabNavigation } from '../../trackers/appEventTracker';

import * as requests from '../../request/projectRequests';
import EmptyView from '../emptyview/emptyView';
import AnalysisItem from './analysisItem';

import { ProjectContext } from '../../contexts/projectContexts';
import ExpandButton from '../input/expandButton';
import { Content } from './content';
import SectionLabel from '../labels/sectionLabel';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    marginBottom: '1.5vh',
    marginTop: '1vh',
  },
  horContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    paddingBottom: 10,
    borderBottomStyle: 'solid',
    borderWidth: 1,
    borderColor: darkThemeColors.tertiaryColor,
  },
  verContainer: {
    width: '20rem',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  scrollAreaStyle: {
    width: '100%',
    height: '100%',
  },
  tabContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

export default function TabSection({
  injectableControl,
  primaryText = null,
  secondaryText = null,
  tertiaryText = null,
  match,
  tabContent,
  tabs,
  isMultiListItem,
}) {
  const classes = useStyles();

  const [analysisId, setAnalysisId] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [analysisItem, setAnalysisItem] = useState(null);
  const [analysisLinkMap, setAnalysisLinkMap] = useState({});
  const [analysisItemMap, setAnalysisItemMap] = useState({});

  const handleChange = (
    event,
    newAnalysisId,
    overridingItem = null,
    overridingLink = null
  ) => {
    setAnalysisId(newAnalysisId);

    if (
      analysisLinkMap[newAnalysisId] === 'EXTERNAL' ||
      overridingLink === 'EXTERNAL'
    ) {
      setIsBusy(true);

      requests
        .getAnalysis(newAnalysisId)
        .then((response) => {
          setIsBusy(false);
          let responseJson = {};
          try {
            responseJson = JSON.parse(response.data);
            setAnalysisItem(responseJson);
          } catch (e) {
            setAnalysisItem(null);
          }
        })
        .catch((error) => {
          setIsBusy(false);
          setAnalysisItem(null);
          return error;
        });
    } else {
      setIsBusy(false);
      if (overridingItem) setAnalysisItem(overridingItem);
      else setAnalysisItem(analysisItemMap[newAnalysisId]);
    }
  };
  const customStyle = {
    margin: 0,
    lineHeight: 1.1,
  };

  useEffect(() => {
    const tempLinkMap = {};
    const tempItemMap = {};
    if (Array.isArray(tabs)) {
      tabs.forEach((item, i) => {
        const tempId =
          item.linkLocation === 'EXTERNAL'
            ? item.analysisId
            : item.analysisItem.metadata.analysisId;
        tempLinkMap[tempId] = item.linkLocation;

        if (item.linkLocation === 'INTERNAL') {
          tempItemMap[tempId] = item.analysisItem;
        }

        if (i === 0) {
          setAnalysisId(tempId);
          handleChange(null, tempId, item.analysisItem, item.linkLocation);
        }
      });
    }
    setAnalysisLinkMap({ ...tempLinkMap });
    setAnalysisItemMap({ ...tempItemMap });
  }, [tabs]);

  const { isAnalysisExpanded, setAnalysisExpanded } =
    useContext(ProjectContext);

  const isListSingleItem = (list) =>
    list && Array.isArray(list) && list.length === 1;
  const isSingleXGridTable = (gridAnalysisItem) =>
    gridAnalysisItem &&
    isListSingleItem(gridAnalysisItem.sections) &&
    gridAnalysisItem.sections[0].content.xGridTables;

  return (
    <div className={classes.root}>
      <div className={classes.horContainer}>
        {injectableControl || null}
        <div className={classes.verContainer}>
          {primaryText ? (
            <SectionLabel
              text={primaryText}
              type="primaryText"
              match={match}
              customStyle={customStyle}
            />
          ) : null}
          {secondaryText ? (
            <SectionLabel
              text={secondaryText}
              type="secondaryText"
              match={match}
              customStyle={customStyle}
            />
          ) : null}
          {tertiaryText ? (
            <SectionLabel
              text={tertiaryText}
              type="tertiaryText"
              match={match}
              customStyle={customStyle}
            />
          ) : null}
        </div>
        <div className={classes.tabContainer}>
          {analysisId ? (
            <Tabs
              value={analysisId}
              onChange={handleChange}
              aria-label="tabSection"
              indicatorColor="primary"
              variant="scrollable"
            >
              {Array.isArray(tabs)
                ? tabs.map((item, i) => {
                    trackSecondaryTabNavigation(item.primaryText);
                    const tempId =
                      item.linkLocation === 'EXTERNAL'
                        ? item.analysisId
                        : item.analysisItem.metadata.analysisId;
                    return item ? (
                      <Tab label={item.primaryText} value={tempId} key={i} />
                    ) : null;
                  })
                : null}
            </Tabs>
          ) : null}
        </div>

        {tabContent ? (
          <Content
            content={tabContent}
            isColumn={false}
            match={match}
            containerStyle={{ justifyContent: 'flex-start' }}
          />
        ) : null}

        {isAnalysisExpanded && setAnalysisExpanded && (
          <ExpandButton
            isExpanded={isAnalysisExpanded}
            handleExpandClick={setAnalysisExpanded}
          />
        )}
      </div>

      {isBusy && (
        <EmptyView
          isLoading={isBusy}
          refreshAction={() => {
            handleChange(null, analysisId);
          }}
        />
      )}

      {!isBusy && (isMultiListItem || isSingleXGridTable(analysisItem)) ? (
        <div className={classes.scrollAreaStyle}>
          <AnalysisItem
            analysisItem={{ ...analysisItem }}
            match={match}
            customStyle={{ marginTop: '2vh' }}
          />
        </div>
      ) : (
        <Scrollbar
          disableTracksWidthCompensation
          noScrollX
          className={classes.scrollAreaStyle}
        >
          <AnalysisItem
            analysisItem={{ ...analysisItem }}
            match={match}
            customStyle={{ marginTop: '2vh' }}
          />
        </Scrollbar>
      )}
    </div>
  );
}
