import {
  Box,
  Typography,
  CircularProgress,
  styled,
} from '@mui/material';

const StyledFetchContainer = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '16px 0px',
  color: themeColors.buttonContentColor,
}));

const MessageTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.emptyText,
  fontSize: 14,
  fontWeight: 500,
  marginBottom: 8,
}));

const StyledProgress = styled(CircularProgress)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.buttonContentColor,
  })
);

export const FetchContainer = () => (
  <StyledFetchContainer>
    <MessageTitle>Retrieving data...</MessageTitle>
    <StyledProgress />
  </StyledFetchContainer>
);