import { useContext, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { styled, Box, Tab, Tabs, Typography } from '@mui/material';
import isValidProp from '@emotion/is-prop-valid';
import { EntityDrawerDataTab } from './EntityDrawerDataTab';
import { EntityDrawerProfileTab } from './EntityDrawerProfileTab';
import { addSpacesBetweenSeparators } from '../../../../utils/formatEntityName';
import { trackEntityOverviewDrilldownTabSelection } from '../../../../trackers/mixpanel';
import { EntityDrawerContext } from './data/entityDrawerContext';
import { EntityDrawerTimelineTab } from './EntityDrawerTimelineTab';

const DrawerHeader = styled(Box)(({ theme: { themeColors } }) => ({
  width: '100%',
  position: 'sticky',
  top: 0,
  zIndex: 2,
  background: themeColors.modalBackgroundColor,
  paddingTop: 16,
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme: { themeColors } }) => ({
  width: 16,
  height: 16,
  cursor: 'pointer',
  color: themeColors.utilityContentColor,
}));

const StyledAddress = styled(Typography, {
  shouldForwardProp: isValidProp,
})(({ theme: { themeColors } }) => ({
  color: themeColors.utilityContentColor,
}));

const TabsContainer = styled(Box)(() => ({
  width: '100%',
}));

const CustomTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme: { themeColors } }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 3.5,
    '&:disabled': {
      display: 'none',
      backgroundColor: themeColors.mainSurfaceBackground,
    },
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 24,
    width: 24,
    backgroundColor: themeColors.userConfigEditHighlight,
  },
  borderBottom: '1px solid',
  borderColor: themeColors.dividerPrimaryColor,
  minHeight: 43.5,
  height: 43.5,
})) as typeof Tabs;

const CustomTab = styled(Tab)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
  textTransform: 'none',
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%',

  '&.Mui-selected': {
    color: themeColors.primaryTextColor,
  },

  '&:disabled': {
    color: themeColors.secondaryTextColor,
    opacity: 0.3,
  },
}));

export const EntityDrawerContent = ({
  tabs,
  name,
  address,
  setDrawerVisibility,
}) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { entityType } = useContext(EntityDrawerContext);
  const onTabChange = (event) => {
    trackEntityOverviewDrilldownTabSelection(
      event.target.textContent,
      entityType
    );
    setSelectedTab(event.target.textContent);
  };

  return (
    <>
      <DrawerHeader>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          pr={2}
          ml={2}
        >
          <Box>
            <Typography variant="h4">
              {addSpacesBetweenSeparators(name.toUpperCase())}
            </Typography>
            <StyledAddress variant="body2">{address}</StyledAddress>
          </Box>

          <Box padding={1.5} onClick={() => setDrawerVisibility(false)}>
            <StyledCloseIcon />
          </Box>
        </Box>

        {tabs && (
          <TabsContainer>
            <CustomTabs value={selectedTab} onChange={onTabChange}>
              {tabs.map((tab) => (
                <CustomTab key={`${tab}-key`} value={tab} label={tab} />
              ))}
            </CustomTabs>
          </TabsContainer>
        )}
      </DrawerHeader>

      <Box width="100%">
        {selectedTab === 'Data' && <EntityDrawerDataTab entityName={name} />}
        {selectedTab === 'Profile' && <EntityDrawerProfileTab />}
        {selectedTab === 'Timeline' && <EntityDrawerTimelineTab />}
      </Box>
    </>
  );
};
