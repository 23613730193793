import { useSelector } from 'react-redux';

const getUserManagementState = () => {
  const userManagement = useSelector((state) => ({
    ...state.userManagement,
  }));
  return userManagement;
};

export default getUserManagementState;
