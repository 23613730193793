export default function humanizeDuration(durationInSeconds: number): string {
  if (durationInSeconds < 0) {
    return 'N/A';
  }

  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);

  let result = '';

  if (hours >= 1) result += `${hours}h `;
  if (minutes >= 1) result += `${minutes}m `;
  if (result === '') result = 'less than 1m';

  return result;
}
