import { styled } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import Check from '@mui/icons-material/Check';
import FormHelperText from '@mui/material/FormHelperText';
import { StyledPrimaryChip, Title } from '../styles/styledComponents';

const ChipArray = styled(FormGroup)(() => ({
  marginTop: 8,
  display: 'flex',
  gridGap: '10px',
  flexDirection: 'row',
}));

const ChipSelectionInput = ({
  options,
  id,
  onChange,
  error,
  value = [],
  errorMessage,
  title = '',
  singleSelect = false,
}) => {
  const handleClick = (newValue) => {
    if (singleSelect) {
      onChange(newValue);
      return;
    }
    const checked = !value.includes(newValue);
    const newSelection = checked
      ? [...value, newValue]
      : value.filter((x) => x !== newValue);

    onChange(id, newSelection);
  };

  return (
    <div>
      <Title variant="h5">{title}</Title>
      <ChipArray>
        {options.map((option) => (
          <StyledPrimaryChip
            key={option.id}
            label={option.label}
            selected={value.includes(option.id)}
            icon={value.includes(option.id) ? <Check /> : null}
            onClick={() => handleClick(option.id)}
          />
        ))}
      </ChipArray>
      {error?.[id] ? (
        <FormHelperText error={error?.[id]}>{errorMessage}</FormHelperText>
      ) : null}
    </div>
  );
};

export default ChipSelectionInput;
