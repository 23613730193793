import { useState, useContext } from 'react';
import { useTheme } from '@mui/styles';
import { Box, styled } from '@mui/material';
import isValidProp from '@emotion/is-prop-valid';

import {
  formatCellValue,
  formatModalValue,
} from '../../../shared/graphs/utils';
import { SparklineBarGraph } from '../../../shared/graphs/barGraph/barGraph';
import { SparklineLineGraph } from '../../../shared/graphs/lineGraph/lineGraph';
import { GraphModal } from '../../../shared/graphs/graphModal';
import { TrendValue } from '../../../shared/trend/TrendValue';
import { CADENCES_ENUM, TABLE_HEADER_TYPES_ENUM } from '../../constants';
import { CHART_TYPES_ENUM } from '../../../shared/graphs/constants';
import { EntityOverviewContext } from '../../data/entityOverviewContext';

export const getEntityCellValue = (rowData, type) => {
  if (!rowData || rowData.length === 0) return '-';

  const value = Number(rowData[rowData.length - 1]);
  const valueType = type === TABLE_HEADER_TYPES_ENUM.RATE ? 'percent' : type;
  return formatCellValue(value, valueType);
};

const BarGraphBox = styled(Box, {
  shouldForwardProp: isValidProp,
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  width: 32,
  height: '100%',
  marginRight: theme.spacing(1),
  paddingTop: theme.spacing(1),
}));

const LineGraphBox = styled(Box, {
  shouldForwardProp: isValidProp,
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  width: 32,
  height: '100%',
  marginRight: theme.spacing(1),
}));

const ValueTrendBox = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  fontSize: 16,
  fontWeight: 500,
  cursor: 'default',
  color: themeColors.tableCellDefaultContent,
  minWidth: 50,
}));

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: 35,
  cursor: 'pointer',
}));

export const EntityTableTableDefaultCell = ({
  graph,
  data,
  chartLabels,
  trend,
  type,
  label,
  chartTitlePrefix,
}) => {
  const { themeColors } = useTheme();
  const { cadence } = useContext(EntityOverviewContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalType = type === TABLE_HEADER_TYPES_ENUM.RATE ? 'percent' : type;
  const modalData = {
    data: data?.map((value) => formatModalValue(value, modalType)),
    trend,
  };

  const onContainerClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (modalData?.data?.length > 0 && graph?.length > 0) {
      setIsModalOpen(true);
    }
  };

  const graphTitle = chartTitlePrefix
    ? `${chartTitlePrefix} ${label}, ${CADENCES_ENUM[cadence]}`
    : `${label}, ${CADENCES_ENUM[cadence]}`;

  return (
    <>
      <Container cellType={type} onClick={onContainerClick}>
        {graph === CHART_TYPES_ENUM.BAR && (
          <BarGraphBox data-testid={`entityTableBarGraph-${data.join('-')}`}>
            <SparklineBarGraph data={data} labels={chartLabels} />
          </BarGraphBox>
        )}

        {graph === CHART_TYPES_ENUM.LINE && (
          <LineGraphBox data-testid={`entityTableLineGraph-${data.join('-')}`}>
            <SparklineLineGraph
              data={data}
              labels={chartLabels}
              color={themeColors.dataGeneralColor}
            />
          </LineGraphBox>
        )}

        <ValueTrendBox data-testid={`value-trend-box-${data.join('-')}`}>
          {getEntityCellValue(data, type)}
          <Box mt={0.5}>
            <TrendValue
              type={trend?.type}
              status={trend?.status}
              value={trend?.value}
            />
          </Box>
        </ValueTrendBox>
      </Container>

      <GraphModal
        isOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
        }}
        graphType={graph}
        data={modalData}
        chartLabels={chartLabels}
        graphTitle={graphTitle}
        yTitle={label}
        graphColor={themeColors.dataGeneralColor}
        valueType={type}
      />
    </>
  );
};
