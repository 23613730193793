import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  styled,
  FormGroup,
  Typography,
  FormControlLabel,
} from '@mui/material';
import { omit, sortBy } from 'lodash';
import { HelpOutline, History, Timeline, Check } from '@mui/icons-material';
import {
  availabilityOptions,
  userCreatedListOptions,
} from '@odaia/shared-components/src/userCreatedListsConfig';
import { AutocompleteCombobox } from '@odaia/ui/src/components/autocompleteCombobox';
import { StyledPrimaryChip } from '../usermanagement/styles/styledComponents';
import { lastContactedIds, segmentTypes } from './constants';
import FilterSwitchSelection from './filterSwitchSelection';
import { trackSegmentFilterSelection } from '../../trackers/mixpanel';
import {
  ModalDisplay,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '../generic/modalDisplay';
import { useRelevantUserSpecialties } from '../../hooks/useRelevantUserSpecialties';
import { toCapitalize } from '../../utils/toCapitalize';
import { formatEntityName } from '../../utils/formatEntityName';
import { FieldContext } from '../../containers/application/appViews/field/fieldContext';
import { createMultiselectFilters } from './multiSelectFiltersObject';

const FilterSection = styled('div')(({ theme: { themeColors } }) => ({
  paddingBottom: 32,
  borderBottom: '1px solid',
  borderColor: themeColors.borderLowContrast,
  '&.LastSection': {
    borderBottom: 0,
    paddingBottom: 0,
  },
}));

const SectionTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryMaptualListFilteringColor,
}));

const SectionBody = styled('div')(() => ({
  marginTop: '32px',
}));

const SegmentFilterDescription = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.primaryMaptualListFilteringColor,
    marginBottom: '32px',
    marginTop: '4px',
  })
);

const SubsegmentDescription = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.primaryMaptualListFilteringColor,
    gridColumnStart: 2,
  })
);

const SegmentColorTypography = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.segmentSelectionCaptionColor,
  })
);

const SegmentLabelContainer = styled('div')(() => ({
  alignSelf: 'center',
  display: 'flex',
}));

const ChipArray = styled(FormGroup)(() => ({
  display: 'flex',
  gridGap: '10px',
  flexDirection: 'row',
  marginBottom: 32,
}));

const LastContactedNote = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  gap: '7px',
  alignItems: 'flex-start',
  color: themeColors.primaryMaptualListFilteringColor,
  '.MuiSvgIcon-root': {
    color: themeColors.textButtonColor,
    width: '13.33px',
    height: '13.33px',
  },
}));

const AvailabilitySwitches = styled(SectionBody)(() => ({
  display: 'flex',
  gap: '16px',
  flexDirection: 'column',
}));

const lastContactedFilterOptions = [
  { label: 'Off', id: lastContactedIds.OFF },
  { label: '30 days', id: lastContactedIds.THIRTY_DAYS },
  { label: '60 days', id: lastContactedIds.SIXTY_DAYS },
  { label: '90 days', id: lastContactedIds.NINETY_DAYS },
];

const SubsegmentFilters = styled(SectionBody)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: 10,
  '.MuiFormControlLabel-root': {
    alignItems: 'start',
  },
}));

const SubsegmentLabel = styled('div')(({ theme: { themeColors } }) => ({
  display: 'grid',
  gridTemplateColumns: '24px 1fr',
  gridTemplateRows: '24px 1fr',
  gridGap: '6px',
  height: '100%',
  alignSelf: 'start',
  '.MuiSvgIcon-root': {
    color: themeColors.primaryMaptualListFilteringColor,
    width: 18,
    height: 18,
    alignSelf: 'center',
  },
}));

const CheckboxContainer = styled(SectionBody)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridGap: 9,
  alignItems: 'flex-start',

  '& .MuiFormControlLabel-label': {
    paddingTop: 1.5,
  },
}));

const StyledCheckbox = styled(Checkbox)(({ theme: { themeColors } }) => ({
  '&.Mui-checked': {
    color: themeColors.checkboxSelected,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme: { themeColors } }) => ({
    marginLeft: 0,
    color: themeColors.segmentSelectionCaptionColor,
    '.MuiCheckbox-root': {
      padding: 0,
      marginRight: 9,
      alignSelf: 'start',
    },
  })
);

const COMBOBOX_CUTOFF: number = 12;

const FilterSelection = ({
  maptualListFilters,
  setMaptualListFilters,
  formatMixpanelFilters,
  openDialog,
  closeDialog,
  segment,
  selectedUserCreatedList,
  territories,
}) => {
  const { maptualList, segmentList } = useContext(FieldContext);

  const maptualListName = maptualList?.metadata?.listName;

  const specialties = useRelevantUserSpecialties();
  const currentSegment = segmentList?.find(
    (seg) => seg.name.toLowerCase() === segment.toLowerCase()
  );
  const subsegments = currentSegment ? currentSegment.subSegments : [];

  const showSubsegmentFilters = subsegments.length === 2;

  const [selectedFilters, setSelectedFilters] = useState(maptualListFilters);

  useEffect(() => {
    if (maptualListFilters) {
      setSelectedFilters(maptualListFilters);
    }
  }, [openDialog, maptualListFilters]);

  useEffect(() => {
    if (maptualListFilters) {
      setMaptualListFilters(omit(maptualListFilters, 'territories'));
    }
  }, [maptualListName]);

  const handleApplyFilters = () => {
    trackSegmentFilterSelection(
      maptualListName,
      selectedUserCreatedList || segment,
      'Apply Filters',
      formatMixpanelFilters(selectedFilters)
    );
    setMaptualListFilters({ ...selectedFilters });
    closeDialog();
  };

  const handleClearFilters = () => {
    trackSegmentFilterSelection(
      maptualListName,
      selectedUserCreatedList || segment,
      'Clear Filters',
      formatMixpanelFilters(maptualListFilters)
    );
    setSelectedFilters({});
    setMaptualListFilters({});
    closeDialog();
  };

  const lastContactedChipIsSelected = (option) =>
    selectedFilters?.lastContacted?.id === option.id ||
    (!selectedFilters?.lastContacted?.id && !option.id);
  const sortedSpecialties = specialties
    .sort()
    .map((s) => ({ id: s, label: s.toUpperCase() }));
  const sortedTerritories = sortBy(territories, ['label']);

  const availabilitySwitches = (
    <AvailabilitySwitches>
      {availabilityOptions.map((option) => (
        <FilterSwitchSelection
          key={option.id}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          switchDescription={option.switchDescription}
          switchId={option.id}
          switchLabel={option.label}
        />
      ))}
    </AvailabilitySwitches>
  );

  return (
    <ModalDisplay onClose={closeDialog} isOpen={openDialog}>
      <ModalHeader onClose={closeDialog}>Filters</ModalHeader>

      <ModalBody>
        {showSubsegmentFilters && (
          <FilterSection>
            <SectionTitle variant="h4">{segment} Segment</SectionTitle>
            <SegmentFilterDescription variant="body2">
              Types of {segment.toLowerCase()}s you wish to see
            </SegmentFilterDescription>
            <SubsegmentFilters>
              {subsegments.map((subsegment) => (
                <StyledFormControlLabel
                  key={subsegment.type}
                  control={
                    <StyledCheckbox
                      name={subsegment.type}
                      onChange={() =>
                        setSelectedFilters(
                          createMultiselectFilters(
                            subsegment.type,
                            toCapitalize(subsegment.type),
                            'segment',
                            selectedFilters
                          )
                        )
                      }
                      checked={Object.keys(
                        selectedFilters?.segment || []
                      ).includes(subsegment.type)}
                    />
                  }
                  label={
                    <SubsegmentLabel>
                      <SegmentLabelContainer>
                        {subsegment.type === segmentTypes.RECENT ? (
                          <History />
                        ) : (
                          <Timeline />
                        )}
                      </SegmentLabelContainer>
                      <SegmentLabelContainer>
                        <SegmentColorTypography variant="title3">
                          {toCapitalize(subsegment.type)}
                        </SegmentColorTypography>
                      </SegmentLabelContainer>
                      <SubsegmentDescription variant="body2">
                        {subsegment.description}
                      </SubsegmentDescription>
                    </SubsegmentLabel>
                  }
                  value={subsegment.type}
                />
              ))}
            </SubsegmentFilters>
          </FilterSection>
        )}

        {sortedSpecialties.length > 0 && (
          <FilterSection>
            <SectionTitle variant="h4">Specialty</SectionTitle>
            <SegmentFilterDescription variant="body2">
              Filter HCPs by specialty.
            </SegmentFilterDescription>
            {sortedSpecialties?.length > COMBOBOX_CUTOFF && (
              <AutocompleteCombobox
                options={sortedSpecialties}
                selection={selectedFilters?.specialties || {}}
                onOptionChange={(spec) => {
                  setSelectedFilters(
                    createMultiselectFilters(
                      spec.id,
                      spec.label,
                      'specialties',
                      selectedFilters
                    )
                  );
                }}
                type="specialties"
              />
            )}
            {sortedSpecialties?.length <= COMBOBOX_CUTOFF && (
              <CheckboxContainer>
                {sortedSpecialties.map((specialty) => (
                  <StyledFormControlLabel
                    key={specialty}
                    control={
                      <StyledCheckbox
                        name={specialty.id}
                        onChange={() =>
                          setSelectedFilters(
                            createMultiselectFilters(
                              specialty.id,
                              specialty.label,
                              'specialties',
                              selectedFilters
                            )
                          )
                        }
                        checked={Object.keys(
                          selectedFilters?.specialties || []
                        ).includes(specialty.id)}
                      />
                    }
                    label={specialty.label}
                  />
                ))}
              </CheckboxContainer>
            )}
          </FilterSection>
        )}

        <FilterSection>
          <SectionTitle variant="h4">Not Contacted</SectionTitle>
          <SegmentFilterDescription variant="body2">
            HCPs you have not contacted in the last...
          </SegmentFilterDescription>
          <ChipArray>
            {lastContactedFilterOptions.map((option) => (
              <StyledPrimaryChip
                data-testid="test-filter-last-n-days"
                key={option.id}
                label={option.label}
                variant={
                  lastContactedChipIsSelected(option) ? 'primary' : 'outlined'
                }
                color={
                  lastContactedChipIsSelected(option) ? 'primary' : 'default'
                }
                icon={lastContactedChipIsSelected(option) ? <Check /> : null}
                onClick={() => {
                  if (option.id) {
                    setSelectedFilters({
                      ...selectedFilters,
                      lastContacted: { id: option.id, label: option.label },
                    });
                  } else {
                    const newFilter = { ...selectedFilters };
                    delete newFilter.lastContacted;
                    setSelectedFilters(newFilter);
                  }
                }}
              />
            ))}
          </ChipArray>
          <LastContactedNote>
            <HelpOutline />
            <Typography variant="body2">
              Last contacted dates can lag because you don't have real-time data
              enabled
            </Typography>
          </LastContactedNote>
        </FilterSection>

        {sortedTerritories?.length > 0 && (
          <FilterSection>
            <SectionTitle variant="h4">Geography</SectionTitle>
            <SegmentFilterDescription>
              Filter HCPs by geography
            </SegmentFilterDescription>
            {sortedTerritories?.length > COMBOBOX_CUTOFF && (
              <AutocompleteCombobox
                options={sortedTerritories}
                selection={selectedFilters?.territories || {}}
                onOptionChange={(terr) => {
                  setSelectedFilters(
                    createMultiselectFilters(
                      terr.id,
                      formatEntityName(terr.label),
                      'territories',
                      selectedFilters
                    )
                  );
                }}
                type="geographies"
                formatLabel={formatEntityName}
              />
            )}
            {sortedTerritories?.length <= COMBOBOX_CUTOFF && (
              <CheckboxContainer>
                {sortedTerritories.map((territory) => (
                  <StyledFormControlLabel
                    key={territory.id}
                    control={
                      <StyledCheckbox
                        name={territory.id}
                        onChange={() =>
                          setSelectedFilters(
                            createMultiselectFilters(
                              territory.id,
                              formatEntityName(territory.label),
                              'territories',
                              selectedFilters
                            )
                          )
                        }
                        checked={Object.keys(
                          selectedFilters?.territories || []
                        ).includes(territory.id)}
                      />
                    }
                    label={formatEntityName(territory.label)}
                  />
                ))}
              </CheckboxContainer>
            )}
          </FilterSection>
        )}

        {selectedUserCreatedList !== userCreatedListOptions.starred.id && (
          <FilterSection
            className={!selectedUserCreatedList ? '' : 'LastSection'}
          >
            <SectionTitle variant="h4">
              {userCreatedListOptions.starred.label}
            </SectionTitle>
            <SectionBody>
              <FilterSwitchSelection
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                switchDescription="Only show HCPs that I starred"
                switchId={userCreatedListOptions.starred.id}
                switchLabel={userCreatedListOptions.starred.label}
              />
            </SectionBody>
          </FilterSection>
        )}

        {(!selectedUserCreatedList ||
          selectedUserCreatedList === userCreatedListOptions.starred.id) && (
          <FilterSection className="LastSection">
            <SectionTitle variant="h4">Availability Options</SectionTitle>
            {availabilitySwitches}
          </FilterSection>
        )}
      </ModalBody>

      <ModalFooter>
        <Button variant="text" onClick={() => handleClearFilters()}>
          Clear Filters
        </Button>
        <Button variant="contained" onClick={() => handleApplyFilters()}>
          Apply Filters
        </Button>
      </ModalFooter>
    </ModalDisplay>
  );
};

export default FilterSelection;
