import Typography from '@mui/material/Typography';

import { ProjectRunStatusResponse } from '../hooks/useProjectStatus';
import TaskIconWrapper from '../../common/taskIconWrapper';
import CircularProgressWithLabel from '../../common/circularProgressWithLabel';
import humanizeDuration from '../../common/humanizeDuration';

export default function RunningProjectStatus({
  status,
}: {
  status: ProjectRunStatusResponse;
}) {
  return (
    <TaskIconWrapper color="primary">
      <CircularProgressWithLabel
        size={20}
        value={status.estimatedPercentageComplete * 100}
      />
      <Typography variant="body2">
        In Progress, {humanizeDuration(status.estimatedRemainingTime)} remaining
      </Typography>
    </TaskIconWrapper>
  );
}
