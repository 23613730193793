import {
  ModalDisplay,
  ModalHeader,
  ModalBody,
} from '../../../../components/generic/modalDisplay';
import { RegionSelectionList } from './RegionSelectionList';
import { AutocompleteDisplay } from './AutocompleteDisplay';

export const RegionSelectionModal = ({
  isOpen,
  handleClose,
  handleRegionSelection,
  regionsForSearch,
  regionList,
}) => (
  <ModalDisplay
    onClose={() => handleClose()}
    isOpen={isOpen}
    style={{ width: '35%', maxHeight: '95%' }}
    positionTop
  >
    <ModalHeader onClose={() => handleClose()} largeCloseIcon={false}>
      Change Region
    </ModalHeader>
    <ModalBody notScrollable>
      <AutocompleteDisplay
        itemKey="maptualListId"
        itemsForSearch={regionsForSearch}
        handleSelection={handleRegionSelection}
        optionLabel="listName"
        noOptionsText="No regions found that match your search."
        textFieldLabel="Find other regions"
      />
    </ModalBody>
    <ModalBody>
      <RegionSelectionList
        regionList={regionList}
        handleRegionSelection={handleRegionSelection}
      />
    </ModalBody>
  </ModalDisplay>
);
