import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { FieldContext } from '../fieldContext';
import { NavigationButton } from './navigationButton/NavigationButton';
import { ROUTES } from '../../../../../constants';
import { AppContext } from '../../../appContext';
import { ViewsList } from './viewsList/ViewsList';
import { AccountCard } from '../../../../../components/accountCard/accountCard';
import { NavigationContainer } from '../../shared/NavigationContainer';
import ProjectSelector from '../../shared/projectSelector';
import { useLocalStorageValue } from '../../../../../hooks/useLocalStorageValue';
import { selectEntity } from '../../../../../actions/projectViewActions';

const ContentWrapper = styled('div')(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  padding: '32px 16px 0px 16px',
}));

const StyledButtonContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  marginLeft: '16px',
  marginTop: '24px',
}));

export const Navigation = () => {
  const { navigationOpen, project } = useContext(FieldContext);
  const { isProjectListLoading, projectList } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [, , removeMaptualListMetadata] = useLocalStorageValue(
    'maptualListMetadata'
  );

  const setProjectItemFull = (proj) => {
    if (location.pathname.includes(`${ROUTES.FIELD}${ROUTES.PROJECTS}`)) {
      const route = `${ROUTES.FIELD}${ROUTES.PROJECTS}/${proj.metadata.projectId}`;
      history.push(route);
    }
  };

  const handleProjectChange = (event) => {
    if (project !== undefined && project !== null) {
      const selectedProjectId = project.metadata.projectId;
      if (
        selectedProjectId !== event.target.value &&
        event.target.value &&
        Array.isArray(projectList)
      ) {
        const newProj = projectList.find(
          (item) =>
            item && item.projectId && item.projectId === event.target.value
        );

        if (newProj) {
          setProjectItemFull({ metadata: { ...newProj } });
          removeMaptualListMetadata();
          dispatch(selectEntity(null));
        }
      }
    }
  };

  return (
    <NavigationContainer navigationOpen={navigationOpen}>
      <ContentWrapper>
        <StyledButtonContainer>
          <NavigationButton />
        </StyledButtonContainer>
        <ProjectSelector
          isProjectListLoading={isProjectListLoading}
          projectList={projectList}
          project={project?.metadata}
          handleProjectChange={handleProjectChange}
        />
        <ViewsList project={project} />
      </ContentWrapper>
      <AccountCard />
    </NavigationContainer>
  );
};
