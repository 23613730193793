import { useQuery } from 'react-query';
import { fieldApi } from '../../request/maptualApiClient';

export const useEntityTopAttribution = ({ projectId, entityId }) => {
  const { data: topAttributionData, isLoading: isTopAttributionLoading } =
    useQuery({
      queryKey: ['entity-top-attribution', projectId, entityId],
      queryFn: async () => {
        const response = await fieldApi.getEntityTopAttributionData({
          entityId,
          projectId,
        });
        return response.data;
      },
      enabled: !!(projectId && entityId),
    });

  return {
    topAttributionData,
    isTopAttributionLoading,
  };
};
