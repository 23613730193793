import { useContext } from 'react';
import { Skeleton, styled, Typography, useTheme } from '@mui/material';
import { OpportunityMetadataContext } from './data/opportunityMetadataContext';
import { OpportunityChartContext } from './data/opportunityChartContext';
import { ErrorContainer } from '../../../generic/errorContainer';
import { LineChart } from '../shared/LineChart';

const SkeletonBar = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  backgroundColor: themeColors.surfaceEmpty,
}));

export const OpportunityLineChart = () => {
  const theme = useTheme();
  const { themeColors } = theme;

  const {
    isMetadataLoading,
    isMetadataError,
    metadata,
    selectedMetric,
    selectedCadence,
    selectedUnit,
  } = useContext(OpportunityMetadataContext);

  const { isDataLoading, isDataError, isDataIdle, refetchData, title, data } =
    useContext(OpportunityChartContext);

  if (isMetadataError || (metadata && !metadata.metrics?.length)) {
    return null;
  }

  if (isMetadataLoading || isDataIdle || isDataLoading) {
    return <SkeletonBar animation="wave" height={225} />;
  }

  if (isDataError) {
    return <ErrorContainer handleRetry={refetchData} />;
  }

  if (!data?.length) {
    return (
      <ErrorContainer
        title="Graph not available"
        description="We do not have enough data to generate a graph for these products."
      />
    );
  }

  const metricName = metadata?.metrics?.find(
    (metric) => metric.rxType === selectedMetric
  )?.displayName;

  return (
    <>
      <Typography
        variant="h6"
        textAlign="left"
        color={themeColors.primaryTextColor}
      >
        {title}, {selectedCadence.label}
      </Typography>

      <LineChart
        selectedCadence={selectedCadence}
        unit={selectedUnit}
        metricName={metricName}
        data={data}
        legendPlugin={{ display: false }}
        showLineValueLabel={false}
        dataTestId="opportunity-line-chart"
      />
    </>
  );
};
