import { useQuery } from 'react-query';
import { fieldApi } from '../request/maptualApiClient';

interface CategoricalDataItem {
  title: string;
  subTitle: string;
  numberOfCategories: number;
  selectedCategoryIndex: number;
}

export interface DataItem {
  title: string;
  subTitle: string;
  tooltip: number;
  categoricalData: CategoricalDataItem[];
}

export interface CategoricalData {
  [key: string]: DataItem[];
}

interface LookupTable {
  [key: string]: boolean;
}

export const removeDuplicateCategories = (
  categoricalData: CategoricalDataItem[]
) => {
  const lookupTable: LookupTable = {};

  const uniqueCategoricalData = categoricalData.reduce(
    // @ts-ignore
    (newCategoricalData, category) => {
      const lookupKey = category.title + category.selectedCategoryIndex;

      if (!lookupTable[lookupKey]) {
        lookupTable[lookupKey] = true;
        return [...newCategoricalData, category];
      }

      return newCategoricalData;
    },
    []
  ) as unknown as CategoricalDataItem[];

  return uniqueCategoricalData;
};

export const filterCategoricalData = (data: CategoricalData): CategoricalData =>
  Object.entries(data).reduce((result, [objectiveId, dataPerObjective]) => {
    if (!dataPerObjective?.length) {
      return result;
    }

    // @ts-ignore
    const filteredCards = dataPerObjective.reduce((nonEmptyCards, card) => {
      const uniqueCategoricalData = removeDuplicateCategories(
        card.categoricalData
      );

      return [
        ...nonEmptyCards,
        { ...card, categoricalData: uniqueCategoricalData },
      ];
    }, []);

    return { ...result, [objectiveId]: filteredCards };
  }, {});

interface ResponseData {
  isLoading: boolean;
  isError: boolean;
  categoricalData?: CategoricalData;
}

export const useCategoricalData = (
  entityId: string,
  projectId: string
): ResponseData => {
  const { data, isLoading, isError } = useQuery(
    `categoricalData-${entityId}${projectId}`,
    async () => {
      const response = await fieldApi.getCategoricalData({
        projectId,
        entityId,
      });
      return filterCategoricalData(response.data ?? {});
    },
    {
      enabled: !!projectId && !!entityId,
    }
  );
  return { isLoading, categoricalData: data, isError };
};
