import makeStyles from '@mui/styles/makeStyles';
import Chart from 'react-apexcharts';
import { darkThemeColors } from '@odaia/ui/src/colorConfig';
import GraphConfig from './graphConfig';

const maxStatsWidth = '15rem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '700',
    height: 'auto',
    paddingTop: '1rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    paddingBottom: '1rem',
    marginBottom: '1vh',
    marginRight: '3rem',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 3,
  },
  horiContainer: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  lengendContainer: {
    flexGrow: 1,
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  graphTitleContainer: {
    width: '100%',
    height: 'auto',
    margin: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  primaryText: {
    fontSize: 24,
    fontWeight: 900,
    color: theme.palette.text.primary,
    lineHeight: 1.1,
    maxWidth: maxStatsWidth,
    marginBottom: '0.25rem',
  },
  secondaryText: {
    fontSize: 16,
    fontWeight: 500,
    maxWidth: maxStatsWidth,
    lineHeight: 1.1,
    marginBottom: '0.05rem',
    color: theme.palette.text.secondary,
  },
  tertiaryText: {
    fontSize: 12,
    fontWeight: 400,
    maxWidth: maxStatsWidth,
    lineHeight: 1.1,
    color: darkThemeColors.tertiaryColor,
  },
  legendBar: {
    height: '100%',
    width: '0.3rem',
    borderRadius: '0.15rem',
    marginRight: 15,
  },
  statsContainer: {
    display: 'flex',
    margin: '0.5rem',
    height: '3.8rem',
  },
}));

export default function RadarChart({ graphName, graphContent }) {
  const classes = useStyles();

  const labels = Object.keys(graphContent);
  const series = [
    {
      name: graphName,
      data: Object.values(graphContent),
    },
  ];

  const options = {
    dataLabels: {
      enabled: true,
      background: {
        enabled: true,
        borderRadius: 2,
      },
    },
    labels,
    colors: GraphConfig.graphColors,
    fill: {
      opacity: 0.8,
      colors: GraphConfig.graphColors,
    },
    theme: {
      mode: 'dark',
    },
    yaxis: {
      show: false,
    },
    xaxis: {
      categories: labels,
      labels: {
        show: true,
        trim: true,
        maxWidth: 15,
        style: {
          colors: labels.map(() => GraphConfig.graphColors[0]),
          fontSize: '12px',
          maxWidth: 15,
          'word-wrap': 'normal',
        },
      },
    },
    markers: {
      size: 5,
      hover: {
        size: 10,
      },
    },
    chart: {
      background: '#00000000', // grey[800],
    },
  };

  return (
    <div className={classes.root}>
      <div className={classes.horiContainer}>
        <Chart
          type="radar"
          options={options}
          series={series}
          height={365}
          width={365}
        />
      </div>
    </div>
  );
}
