import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import { TypographyDefinitions } from '@odaia/ui/src/typography';
import { TrendIcon } from '../../containers/application/appViews/shared/TrendIcon';

export const TREND_TYPES = Object.freeze({
  VALUE: 'VALUE',
  TREND: 'TREND',
  TREND_ONLY: 'TREND_ONLY',
  TREND_CHANGE: 'TREND_CHANGE',
  DIRECTION_ONLY: 'DIRECTION_ONLY',
});

export const COLOR_STATES = Object.freeze({
  NEGATIVE: 'NEGATIVE',
  POSITIVE: 'POSITIVE',
  NEUTRAL: 'NEUTRAL',
  UNKNOWN: undefined,
});

const Root = styled('div')(
  ({ noMargin, filled, fillColor, theme: { themeColors } }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    margin: noMargin ? 0 : '3px 0px 0px 3px',
    color: themeColors.primaryTextColor,
    background: filled ? fillColor : 'inherit',
    padding: filled ? '4px' : 0,
    borderRadius: filled ? '4px' : 0,
  })
);

const TrendValueStyle = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: TypographyDefinitions.fontWeight.medium,
}));

export const roundPercentage = (number) => {
  if (Math.abs(number) > 0 && Math.abs(number) < 1) {
    return '< 1';
  }
  return Math.round(number);
};

export const PREDICTION_PRECISION = Object.freeze({
  INCREASING_WITHIN_RANGE: 2,
  NO_CHANGE: 1,
  DECREASING_WITHIN_RANGE: 0,
  CANNOT_PREDICT: -1,
  INCREASING_OUTSIDE_RANGE: -2,
  DECREASING_OUTSIDE_RANGE: -3,
});

export const getColors = ({ type, status, value }) => {
  const { themeColors } = useTheme();
  const primaryColorMap = {
    [COLOR_STATES.NEUTRAL]: themeColors.primaryTextColor,
    [COLOR_STATES.POSITIVE]: themeColors.increaseColorMainDefaultContent,
    [COLOR_STATES.NEGATIVE]: themeColors.decreaseColorMainDefaultContent,
  };
  const secondaryColorMap = {
    [COLOR_STATES.NEUTRAL]: 'inherit',
    [COLOR_STATES.POSITIVE]: themeColors.increaseColorMainDefault,
    [COLOR_STATES.NEGATIVE]: themeColors.decreaseColorMainDefault,
  };

  const colorState = getColorState({ type, status, value });

  if (!colorState) {
    return { primary: colorState, secondary: colorState };
  }
  return {
    primary: primaryColorMap[colorState],
    secondary: secondaryColorMap[colorState],
  };
};

export const getColorState = ({ type, status, value }) => {
  if (type === TREND_TYPES.VALUE) return COLOR_STATES.NEUTRAL;
  // if no value, but instead bad prediction status, use that instead
  if (value === null) {
    switch (status) {
      case PREDICTION_PRECISION.CANNOT_PREDICT:
        return COLOR_STATES.NEUTRAL;
      case PREDICTION_PRECISION.INCREASING_WITHIN_RANGE:
      case PREDICTION_PRECISION.INCREASING_OUTSIDE_RANGE:
        return COLOR_STATES.POSITIVE;
      case PREDICTION_PRECISION.DECREASING_WITHIN_RANGE:
      case PREDICTION_PRECISION.DECREASING_OUTSIDE_RANGE:
        return COLOR_STATES.NEGATIVE;
      default:
        return COLOR_STATES.UNKNOWN;
    }
  }

  if (value === 0) return 'NEUTRAL';
  if (value > 0) return 'POSITIVE';
  if (value < 0) return 'NEGATIVE';
  return undefined;
};

const getIncrDecrText = ({ value, status }) => {
  // if no value, but instead bad prediction status, use that instead
  if (value === null) {
    switch (status) {
      case PREDICTION_PRECISION.INCREASING_OUTSIDE_RANGE:
        return 'INCR';
      case PREDICTION_PRECISION.DECREASING_OUTSIDE_RANGE:
        return 'DECR';
      default:
        return '';
    }
  }
  if (value > 0) return 'INCR';
  if (value < 0) return 'DECR';
  return '';
};

const Arrow = ({ value, status, collapsed }) => {
  const upArrow = (
    <TrendIcon collapsed={collapsed} testId="trend-up-arrow" direction="up" />
  );

  const downArrow = (
    <TrendIcon
      collapsed={collapsed}
      testId="trend-down-arrow"
      direction="down"
    />
  );

  if (value === null) {
    switch (status) {
      case PREDICTION_PRECISION.INCREASING_WITHIN_RANGE:
      case PREDICTION_PRECISION.INCREASING_OUTSIDE_RANGE:
        return upArrow;
      case PREDICTION_PRECISION.DECREASING_WITHIN_RANGE:
      case PREDICTION_PRECISION.DECREASING_OUTSIDE_RANGE:
        return downArrow;
      default:
        return null;
    }
  }

  if (value > 0) return upArrow;
  if (value < 0) return downArrow;
  return null;
};

export const transformValue = ({ type, status, useZero, value }) => {
  if (
    !useZero &&
    value === 0 &&
    (status === PREDICTION_PRECISION.CANNOT_PREDICT ||
      status === PREDICTION_PRECISION.NO_CHANGE)
  ) {
    return null;
  }

  switch (type) {
    case TREND_TYPES.TREND:
    case TREND_TYPES.TREND_CHANGE:
      return value === null
        ? null
        : `${roundPercentage(Math.abs(value) * 100)}%`;
    case TREND_TYPES.TREND_ONLY:
    case TREND_TYPES.DIRECTION_ONLY:
      return getIncrDecrText({ value, status });
    default:
      return value;
  }
};

export const getIcon = ({
  type,
  status,
  value,
  useZero,
  collapsed,
  checkNull,
}) => {
  if (
    ((!useZero && value === 0) || (checkNull && value === null)) &&
    (status === PREDICTION_PRECISION.CANNOT_PREDICT ||
      status === PREDICTION_PRECISION.NO_CHANGE)
  ) {
    return '—';
  }

  switch (type) {
    case TREND_TYPES.TREND:
    case TREND_TYPES.TREND_ONLY:
    case TREND_TYPES.TREND_CHANGE:
    case TREND_TYPES.DIRECTION_ONLY:
      return Arrow({ value, status, collapsed });
    default:
      return null;
  }
};

export default function TrendValue({
  type,
  status,
  value,
  useZero = false,
  collapsed = false,
  noMargin = false,
  filled = false,
}) {
  return (value !== undefined || status) && type ? (
    <Root
      data-testid="trend-value-container"
      noMargin={noMargin}
      filled={filled}
      fillColor={getColors({ type, status, value }).secondary}
    >
      {getIcon({ type, status, value, useZero, collapsed })}
      {collapsed ? null : (
        <TrendValueStyle
          noWrap
          align="left"
          style={{ color: getColors({ type, status, value }).primary }}
        >
          {transformValue({ type, status, useZero, value })}
        </TrendValueStyle>
      )}
    </Root>
  ) : null;
}
