import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { getInternalAccountStatus } from '../request/config';
import { AppContext } from '../containers/application/appContext';

export const useRelevantUserSpecialties = () => {
  const { projectId } = useParams();
  const { projectList, profile } = useContext(AppContext);

  const profileSpecialties = profile?.role?.specialty;
  const project = projectList?.find((proj) => proj.projectId === projectId);
  const projectSpecialties = project ? project.specialties : [];

  const isInternalAccount = getInternalAccountStatus();
  if (isInternalAccount) {
    return projectSpecialties;
  }
  const res = profileSpecialties?.filter((s) =>
    projectSpecialties?.includes(s)
  );

  return res || [];
};

export default useRelevantUserSpecialties;
