import { useQuery } from 'react-query';
import {
  EntityType,
  OpportunityChartResponse,
} from '@odaia/clients/maptualAPI';
import { SHORT_FORM_CADENCE } from '../../constants';
import { fieldApi } from '../../../../../request/maptualApiClient';

interface OpportunityDataParams {
  entityId: string;
  projectId: string;
  regionId: string;
  objectiveId: string;
  cadence: SHORT_FORM_CADENCE;
  metric: string;
  entityType: EntityType;
}

export const useOpportunityData = ({
  projectId,
  regionId,
  objectiveId,
  entityId,
  entityType,
  metric,
  cadence,
}: OpportunityDataParams): {
  data?: OpportunityChartResponse;
  isLoading: boolean;
  refetch: () => {};
  isError: boolean;
  isIdle: boolean;
} => {
  const { data, isLoading, refetch, isError, isIdle } = useQuery({
    queryKey: [
      'opportunity-data',
      projectId,
      regionId,
      objectiveId,
      entityId,
      entityType,
      metric,
      cadence,
    ],
    queryFn: async () => {
      const response = await fieldApi.getOpportunityChart({
        projectId,
        regionId,
        objectiveId,
        entityId,
        metric,
        cadence,
        entityType,
      });

      return response.data;
    },
    enabled: !!(
      projectId &&
      regionId &&
      objectiveId &&
      entityId &&
      metric &&
      cadence
    ),
  });

  return {
    data,
    isLoading,
    refetch,
    isError,
    isIdle,
  };
};
