import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import PositiveIcon from '@mui/icons-material/ArrowDropUp';
import NegativeIcon from '@mui/icons-material/ArrowDropDown';
import { darkThemeColors } from '@odaia/ui/src/colorConfig';

const cardSize = '15rem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: cardSize,
    height: cardSize,
    display: 'flex',
    padding: '1.5rem',
    margin: '0.5rem',
    backgroundColor: grey[900],
    borderRadius: 4,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  primaryText: {
    fontSize: 18,
    fontWeight: 800,
    color: theme.palette.text.primary,
    margin: 0,
    padding: 0,
    lineHeight: 1.15,
  },
  secondaryText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.15,
    color: theme.palette.text.secondary,
    margin: 0,
  },
  tertiaryText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.1,
    color: darkThemeColors.tertiaryColor,
    margin: 0,
  },
  heroContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
  },
  percentageText: {
    fontSize: 52,
    fontWeight: 800,
    padding: 0,
    margin: 0,
  },
  positiveToneIcon: {
    width: '2rem',
    height: '2rem',
    padding: 0,
    margin: 0,
  },
  scorePrimaryText: {
    fontSize: 72,
    fontWeight: 800,
    padding: 0,
    margin: 0,
  },
  scoreSecondaryText: {
    fontSize: 14,
    fontWeight: 500,
    padding: 0,
    margin: 0,
  },
  labelText: {
    fontSize: 24,
    fontWeight: 700,
    padding: 0,
    margin: 0,
    color: 'Black',
  },
}));

const mapToneColor = (tone) => {
  switch (tone) {
    case 'POSITIVE':
      return '#1ECB44';
    case 'NEGATIVE':
      return '#b03228';
    default:
      return '';
  }
};

export default function DashboardCard({
  heroSection = {},
  primaryText,
  secondaryText,
  tertiaryText,
}) {
  const classes = useStyles();

  // eslint-disable-next-line no-shadow
  const getHeroComponent = (heroSection) => {
    switch (heroSection.valueType) {
      case 'PERCENTAGE':
        return (
          <div className={classes.heroContainer}>
            {heroSection.colorTone === 'POSITIVE' ? (
              <PositiveIcon
                className={classes.positiveToneIcon}
                style={{ color: mapToneColor(heroSection.colorTone) }}
              />
            ) : null}
            {heroSection.colorTone === 'NEGATIVE' ? (
              <NegativeIcon
                className={classes.positiveToneIcon}
                style={{ color: mapToneColor(heroSection.colorTone) }}
              />
            ) : null}

            <Typography
              className={classes.percentageText}
              color="textPrimary"
              style={{ color: mapToneColor(heroSection.colorTone) }}
            >
              {`${heroSection.value}%`}
            </Typography>
          </div>
        );
      case 'SCORE':
        return (
          <div
            className={classes.heroContainer}
            style={{ alignItems: 'baseline' }}
          >
            <Typography
              className={classes.scorePrimaryText}
              color="textPrimary"
            >
              {heroSection.value}
            </Typography>
            <Typography
              className={classes.scoreSecondaryText}
              color="textSecondary"
            >
              /10
            </Typography>
          </div>
        );
      case 'LABEL':
      default:
        return (
          <div
            className={classes.heroContainer}
            style={{
              backgroundColor: grey[100],
              paddingTop: 10,
              paddingBottom: 10,
              marginBottom: 15,
            }}
          >
            <Typography
              className={classes.labelText}
              color="textPrimary"
              noWrap
            >
              {heroSection.value}
            </Typography>
          </div>
        );
    }
  };

  return (
    <div className={classes.root}>
      {heroSection ? getHeroComponent(heroSection) : null}
      {primaryText ? (
        <Typography className={classes.primaryText}>{primaryText}</Typography>
      ) : null}
      {secondaryText ? (
        <Typography className={classes.secondaryText}>
          {secondaryText}
        </Typography>
      ) : null}
      {tertiaryText ? (
        <Typography className={classes.tertiaryText}>{tertiaryText}</Typography>
      ) : null}
    </div>
  );
}
