import makeStyles from '@mui/styles/makeStyles';
import { Grow, Skeleton, Typography } from '@mui/material';

const useStyles = makeStyles(
  ({ insightContentCardStyle, themeColors, palette, spacing }) => ({
    root: {
      ...insightContentCardStyle(`${palette.mode}Theme`),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      height: '724px',
      width: '100%',
      margin: '0',
      backgroundColor: themeColors.cardBackgroundColor,
      borderColor: themeColors.borderPrimaryColor,
    },
    error: {
      ...insightContentCardStyle(`${palette.mode}Theme`),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      height: '300px',
      width: '100%',
      margin: '0',
      backgroundColor: themeColors.cardBackgroundColor,
    },
    errorContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
      width: '100%',
      margin: '20px',
      gap: spacing(0.5),
    },
    errorText: {
      color: themeColors.emptyText,
    },
    dashBoardChart: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 16,
    },
    title: {
      height: '25px',
      margin: '0',
      marginBottom: '6px',
      fontSize: 18,
      fontWeight: 500,
      color: themeColors.neutral60,
      whiteSpace: 'normal',
    },
    mediumRowSkeleton: {
      transform: 'scale(1)',
      margin: '0',
      marginTop: '15px',
      height: '9%',
      width: '100%',
      backgroundColor: themeColors.surfaceEmpty,
    },
    smallRowSkeleton: {
      transform: 'scale(1)',
      margin: '0',
      marginTop: '19px',
      height: '6%',
      width: '29%',
      backgroundColor: themeColors.surfaceEmpty,
    },
    largeRowSkeleton: {
      transform: 'scale(1)',
      margin: '0',
      marginTop: '19px',
      height: '25%',
      width: '100%',
      backgroundColor: themeColors.surfaceEmpty,
    },
  })
);

const SkeletonChart = ({ style = {}, title = '', error = '' }) => {
  const classes = useStyles();
  if (error?.length)
    return (
      <div className={classes.dashBoardChart} style={style}>
        <Grow
          in
          timeout={800}
          key="skeleton"
          style={{ margin: 0, padding: 30 }}
        >
          <div className={classes.error}>
            <Typography className={classes.title} align="left">
              {title}
            </Typography>
            <div className={classes.errorContainer}>
              <Typography className={classes.errorText} variant="title3">
                Graph and table not available
              </Typography>
              <Typography className={classes.errorText} variant="subtitle1">
                We do not have enough data to generate a graph and table for
                this HCP.
              </Typography>
            </div>
          </div>
        </Grow>
      </div>
    );
  return (
    <div className={classes.dashBoardChart} style={style}>
      <Grow in timeout={800} key="skeleton" style={{ margin: 0, padding: 30 }}>
        <div className={classes.root}>
          <Typography className={classes.title} align="left">
            {title}
          </Typography>
          <Skeleton
            classes={{ root: classes.mediumRowSkeleton }}
            animation="wave"
            style={{ height: style?.heightPref }}
          />
          <Skeleton
            classes={{ root: classes.mediumRowSkeleton }}
            animation="wave"
            style={{ height: style?.heightPref }}
          />
          <Skeleton
            classes={{ root: classes.smallRowSkeleton }}
            animation="wave"
            style={{ height: style?.heightPref }}
          />
          <Skeleton
            classes={{ root: classes.largeRowSkeleton }}
            animation="wave"
            style={{ height: style?.heightPref }}
          />
          <Skeleton
            classes={{ root: classes.largeRowSkeleton }}
            animation="wave"
            style={{ height: style?.heightPref }}
          />
        </div>
      </Grow>
    </div>
  );
};

export default SkeletonChart;
