import { EntityType } from '@odaia/clients/maptualAPI';
import { METRIC_DATA_TYPE, SHORT_FORM_CADENCE } from '../../constants';
import { ChartDataset } from '../../types';
import { useMarketPerformanceData } from './useMarketPerformanceData';

interface MarketPerformanceChartParams {
  entityId: string;
  projectId: string;
  regionId: string;
  objectiveId: string;
  cadence: SHORT_FORM_CADENCE;
  metric: string;
  metricDataType: METRIC_DATA_TYPE;
  entityType: EntityType;
}

export interface ChartData {
  data: ChartDataset[];
  title: string;
}

export const useMarketPerformanceChart = ({
  projectId,
  regionId,
  objectiveId,
  entityId,
  metricDataType,
  metric,
  cadence,
  entityType,
}: MarketPerformanceChartParams): {
  data: ChartData | undefined;
  isLoading: boolean;
  refetch: () => {};
  isError: boolean;
  isIdle: boolean;
} => {
  const { isLoading, data, isError, isIdle, refetch } =
    useMarketPerformanceData({
      projectId,
      regionId,
      objectiveId,
      entityId,
      metricDataType,
      metric,
      cadence,
      entityType,
    });

  return {
    data,
    isLoading,
    refetch,
    isError,
    isIdle,
  };
};
