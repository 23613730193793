import { Typography, Skeleton, styled } from "@mui/material";

const Container = styled("div", {
  shouldForwardProp: (prop) => prop !== "big",
})(({ big }) => ({
  display: "flex",
  width: big ? 40 : 110,
  minWidth: big ? 40 : 110,
  justifyContent: "flex-start",
  alignItems: "center",
}));

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "big",
})(({ big }) => ({
  fontSize: big ? 18 : 16,
}));

const StyledSkeleton = styled(Skeleton)(({ theme: { themeColors } }) => ({
  color: themeColors.buttonContentColor,
  transform: "none",
}));

interface NumberContainerProps {
  value: number;
  isLoading?: boolean;
  big?: boolean;
}

export const NumberContainer = ({
  value,
  isLoading = false,
  big = false,
}: NumberContainerProps) => (
  <Container big={big}>
    {isLoading && <StyledSkeleton height={35} width={70} />}
    {!isLoading && (
      <StyledTypography big={big}>
        {value === undefined || value === null ? 0 : value}
      </StyledTypography>
    )}
  </Container>
);
