import makeStyles from '@mui/styles/makeStyles';
import { Skeleton, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { styled } from '@mui/system';

const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '420px',
    width: '100%',
    margin: '0',
    border: 'none',
  },
  error: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '300px',
    width: '100%',
    margin: '0',
    border: 'none',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    width: '100%',
    margin: '20px',
  },
  errorMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '250px',
  },
  errorMessageRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  errorTitle: {
    margin: '0',
    padding: '0',
    marginLeft: '12px',
    fontSize: 16,
    fontWeight: 500,
    color: themeColors.emptyText,
  },
  errorMessage: {
    margin: '0',
    padding: '0 16px',
    marginTop: '16px',
    fontSize: 12,
    fontWeight: 400,
    color: themeColors.emptyText,
  },
  errorIconStyle: {
    width: '20px',
    height: '20px',
    fill: themeColors.emptyText,
  },
  dashBoardChart: {
    display: 'flex',
    flexDirection: 'column',
  },
  largeRowSkeleton: {
    transform: 'scale(1)',
    margin: '0',
    marginTop: '19px',
    height: '50%',
    width: '100%',
    backgroundColor: themeColors.surfaceEmpty,
  },
}));

export const SkeletonBars = ({ style = {}, error = '' }) => {
  const classes = useStyles();
  if (error?.length)
    return (
      <div className={classes.dashBoardChart} style={style}>
        <div className={classes.error}>
          <div className={classes.errorContainer}>
            <div className={classes.errorMessageContainer}>
              <div className={classes.errorMessageRow}>
                <ErrorOutlineIcon
                  className={classes.errorIconStyle}
                  data-test-state="check-chart-appears"
                />
                <Typography className={classes.errorTitle} align="left">
                  Graph and table not available
                </Typography>
              </div>
              <div className={classes.errorMessageRow}>
                <Typography className={classes.errorMessage} align="left">
                  {error}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className={classes.dashBoardChart} style={style}>
      <div className={classes.root}>
        <Skeleton
          classes={{ root: classes.largeRowSkeleton }}
          sx={{ bgcolor: '#111215' }}
          animation="wave"
          style={{ height: style?.heightPref }}
        />
        <Skeleton
          classes={{ root: classes.largeRowSkeleton }}
          sx={{ bgcolor: '#111215' }}
          animation="wave"
          style={{ height: style?.heightPref }}
        />
      </div>
    </div>
  );
};

export const SkeletonBar = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  backgroundColor: themeColors.surfaceEmpty,
}));
