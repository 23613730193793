import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Typography from '@mui/material/Typography';

import TaskIconWrapper from '../../common/taskIconWrapper';

export default function UnknownProjectStatus() {
  return (
    <TaskIconWrapper color="primary">
      <ErrorOutlineOutlinedIcon fontSize="small" color="primary" />
      <Typography variant="body2">
        Unable to determine current project status
      </Typography>
    </TaskIconWrapper>
  );
}
