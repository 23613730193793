import { useContext } from 'react';
import {
  Badge,
  styled,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { Search } from '@mui/icons-material';
import { ObjectiveSelector } from '@odaia/shared-components/src/objectiveSelector';
import { FieldContext } from './fieldContext';
import { RegionSelection } from './navigation/regionSelection/RegionSelection';
import { SuggestedEntitiesButton } from './SuggestedEntitiesButton';
import { MapToggle } from './map/mapToggle';

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '16px 16px 0px 16px',
  gap: '16px',
  marginTop: 48,
}));

const StyledBadge = styled(Badge)(({ theme: { themeColors } }) => ({
  display: 'block',
  height: '100%',
  width: '56px',
  '& .MuiBadge-badge': {
    backgroundColor: themeColors.notificationBadgeSurface,
    color: themeColors.notificationBadgeContent,
  },
}));

const StyledIconButton = styled(IconButton)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.buttonBackgroundColor,
  color: themeColors.buttonContentColor,
  border: `1px solid ${themeColors.buttonBorderColor}`,
  borderRadius: '4px',
  boxShadow: themeColors.boxShadowLevelOne,
  '&:hover': {
    backgroundColor: themeColors.inputBackgroundHover,
    borderColor: themeColors.buttonBorderColor,
  },
  padding: '8px 12px',
}));

const ControlsEnd = styled('div')(() => ({
  marginLeft: 'auto',
  height: 40,
  marginTop: '-42px',
  gap: 8,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
}));

export const ControlBar = ({
  mergedMaptualList,
  showCurationData,
  setSearch,
  activeFilterCount,
  setIsFilterDrawerOpen,
  isSuggestedEntities,
  setIsSuggestedEntities,
}) => {
  const {
    maptualListMetadata,
    objective,
    setObjective,
    maptualListObjectives,
  } = useContext(FieldContext);

  const anonymize = false;
  return (
    <Container>
      {mergedMaptualList?.list?.items.length > 0 && (
        <FormControl>
          <OutlinedInput
            sx={{ height: '40px', width: '350px' }}
            onChange={(e) => setSearch(e.target.value)}
            id="search"
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
          />
        </FormControl>
      )}
      {mergedMaptualList?.list?.items.length > 0 && (
        <StyledBadge badgeContent={activeFilterCount}>
          <StyledIconButton
            data-testid="filter-button"
            onClick={() => setIsFilterDrawerOpen()}
          >
            <TuneIcon />
          </StyledIconButton>
        </StyledBadge>
      )}
      {showCurationData && (
        <SuggestedEntitiesButton
          isActive={isSuggestedEntities}
          handleClick={() => setIsSuggestedEntities()}
        />
      )}
      {maptualListObjectives && objective?.id && (
        <ObjectiveSelector
          updateFilterObjective={(objectiveId) =>
            setObjective(
              maptualListObjectives.find((o) => o.id === objectiveId)
            )
          }
          objectiveId={objective?.id}
          currentObjectives={maptualListObjectives}
          minWidth="275px"
        />
      )}

      <ControlsEnd>
        {maptualListMetadata?.listName && objective?.id && (
          <RegionSelection
            regionName={
              anonymize
                ? `Territory ${String(
                    maptualListMetadata?.sfMaptualListId
                  ).slice(-3)}`
                : maptualListMetadata?.listName
            }
            anonymizeRegions={anonymize}
          />
        )}
        <MapToggle />
      </ControlsEnd>
    </Container>
  );
};
