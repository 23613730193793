import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRequiredAction as getRequiredActionRequest } from '../request/requests';
import { trackAppSelectionButton } from '../trackers/mixpanel';
import { trackAppSelection } from '../trackers/appEventTracker';

export const getRequiredAction = createAsyncThunk(
  'app/getRequiredAction',
  async () => {
    const response = await getRequiredActionRequest();
    return JSON.parse(response.data);
  }
);

export const appInitialState = {
  appView: null,
  isBusy: false,
  maptualAccess: null,
  requiredAction: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {
    selectApp: (state, { payload }) => {
      state.appView = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRequiredAction.pending, (state) => {
        state.requiredAction = null;
      })
      .addCase(getRequiredAction.fulfilled, (state, { payload }) => {
        state.requiredAction = payload.requiredAction || null;
      })
      .addCase(getRequiredAction.rejected, (state) => {
        state.requiredAction = null;
      });
  },
});

export const { selectApp } = appSlice.actions;

export function selectAppThunk(app) {
  return (dispatch) => {
    dispatch(selectApp(app));
    if (app === null) {
      trackAppSelectionButton();
    } else {
      trackAppSelection(app);
    }
  };
}

export default appSlice.reducer;
