import { Box } from '@mui/material';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSnackbar } from '@odaia/ui/src/components/snackbar';
import { ActionableDataListItem } from '../../actionableDataListItem';
import { LoadingContainer } from '../../../generic/LoadingContainer';
import { ROUTES } from '../../../../constants';
import { useDeleteModuleConfigValuesMutation } from '../../../../request/admin/mutations';
import { useDialogContext } from '../../../../contexts/dialogContext';

interface InstaceListProps {
  instances: string[];
  userGroup: string;
  selectedMarket?: { value: string; label: string };
  isLoading?: boolean;
}

export const InstanceList = ({
  instances,
  userGroup,
  selectedMarket,
  isLoading = false,
}: InstaceListProps) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const showDialog = useDialogContext();
  const { enqueueSnackbar } = useSnackbar();

  const deleteValueMutation = useDeleteModuleConfigValuesMutation();
  const handleUpdateInstance = (schemaName: string) => {
    const searchParams = new URLSearchParams();

    searchParams.set('userGroup', userGroup);
    if (selectedMarket) {
      searchParams.set('marketId', selectedMarket.value);
    }
    history.push({
      pathname: `${path}${ROUTES.SCHEMAS}/${schemaName}${ROUTES.UPDATE_INSTANCE}`,
      search: searchParams.toString(),
    });
  };

  const handleDeleteInstance = (schemaName: string) => {
    const instanceName = `${userGroup}${
      selectedMarket ? `, ${selectedMarket.label}` : ''
    }`;
    showDialog({
      title: 'Delete Config Instance',
      content: `Are you sure you want to delete the ${instanceName} instance of ${schemaName}?`,
      confirmText: 'Confirm and Delete',
      loadingText: 'Deleting Instance',
      onConfirm: async () => {
        await deleteValueMutation.mutateAsync({
          schema_name: schemaName,
          config_user_group: userGroup,
          market_id: selectedMarket?.value,
        });
        enqueueSnackbar(
          `Successfully deleted ${instanceName} instance of ${schemaName}`,
          {
            variant: 'info',
          }
        );
      },
    });
  };

  if (isLoading) {
    return <LoadingContainer height="100px" />;
  }
  return (
    <Box component="ul" width="100%" padding={0}>
      {instances.map((schemaName) => {
        const id = `${schemaName}_${userGroup}${
          selectedMarket ? `_${selectedMarket.label}` : ''
        }`;

        return (
          <ActionableDataListItem
            key={id}
            name={id}
            description=""
            actions={[
              {
                text: 'Edit Config',
                handler: () => handleUpdateInstance(schemaName),
              },
              {
                text: 'Delete',
                handler: () => {
                  handleDeleteInstance(schemaName);
                },
              },
            ]}
          />
        );
      })}
    </Box>
  );
};
