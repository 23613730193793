import { useContext, useState, useEffect } from 'react';
import { styled, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CADENCE } from '@odaia/domain/src';
import { PulseContext } from '../../../context/PulseContext';
import { formatTimestamp } from '../../../shared/utils';

const CADENCE_TO_DATE_FUNCTION = {
  wk: 'week',
  mth: 'month',
  qtr: 'quarter',
  yr: 'year',
};

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
}));

const IconButtonGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  height: '100%',
}));

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'paddingLeft',
})(({ disabled, paddingLeft, theme: { themeColors } }) => ({
  backgroundColor: disabled
    ? themeColors.buttonDisabledColor
    : themeColors.chipBackground,
  height: 40,
  width: 40,
  borderRadius: 4,
  border: disabled ? 'none' : `1px solid ${themeColors.buttonBorderColor}`,
  paddingLeft: paddingLeft ? 18 : 8,
  paddingTop: 8,
  paddingBottom: 8,
  paddingRight: 8,
  '&:hover': {
    backgroundColor: themeColors.buttonHoverBackgroundColor,
    borderColor: themeColors.buttonHoverBorderColor,
    color: themeColors.buttonHoverContentColor,
  },
  '& .MuiSvgIcon-root': {
    color: disabled
      ? themeColors.buttonDisabledContentColor
      : themeColors.menuTextColor,
    padding: 0,
  },
}));

const StyledTypography = styled(Typography)(({ theme: { themeColors } }) => ({
  minWidth: '75px',
  fontSize: 16,
  fontWeight: 400,
  color: themeColors.menuTextColor,
}));

const handleDateChange = ({ timestamp, cadence, increase }) => {
  if (increase) {
    return moment(timestamp).add(1, CADENCE_TO_DATE_FUNCTION[cadence]);
  }
  return moment(timestamp).subtract(1, CADENCE_TO_DATE_FUNCTION[cadence]);
};

export const DateSelector = ({ timestamp, cadence, updateTimestamp }) => {
  const [selectedTimestamp, setSelectedTimestamp] = useState();
  const { latestCurationTimestamp } = useContext(PulseContext);

  useEffect(() => {
    if (timestamp && timestamp !== selectedTimestamp)
      setSelectedTimestamp(timestamp);
  }, [timestamp]);

  const handleButtonClick = ({ increaseDate }) => {
    const newDate = handleDateChange({
      increase: increaseDate,
      timestamp: selectedTimestamp,
      cadence,
    });
    const formattedNewDate = newDate.format('YYYY-MM-DD');
    setSelectedTimestamp(formattedNewDate);
    updateTimestamp(formattedNewDate);
  };

  const prevDate = handleDateChange({
    increase: false,
    timestamp: selectedTimestamp,
    cadence,
  });
  const nextDate = handleDateChange({
    increase: true,
    timestamp: selectedTimestamp,
    cadence,
  });
  const prevYearDate = handleDateChange({
    increase: false,
    timestamp: latestCurationTimestamp,
    cadence: CADENCE.YEAR,
  });

  const isLeftButtonDisabled = moment(prevDate).isBefore(prevYearDate);
  const isRightButtonDisabled = moment(latestCurationTimestamp).isBefore(
    nextDate
  );
  return (
    <Container>
      <IconButtonGroup>
        <StyledIconButton
          aria-label="date-selector-left-button"
          disabled={isLeftButtonDisabled}
          paddingLeft
          onClick={() => handleButtonClick({ increaseDate: false })}
        >
          <ArrowBackIosIcon />
        </StyledIconButton>
        <StyledIconButton
          aria-label="date-selector-right-button"
          disabled={isRightButtonDisabled}
          onClick={() => handleButtonClick({ increaseDate: true })}
        >
          <ArrowForwardIosIcon />
        </StyledIconButton>
      </IconButtonGroup>
      <StyledTypography>
        {formatTimestamp({ timestamp: selectedTimestamp, cadence })}
      </StyledTypography>
    </Container>
  );
};
