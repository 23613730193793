import { useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useDropzone } from 'react-dropzone';
import Typography from '@mui/material/Typography';
import '../styles/DropZone.css';

const useStyles = makeStyles((theme) => ({
  label: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function ChunkDropZone({ clearErrorMsg, setDataFile, fileName }) {
  const classes = useStyles();

  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader();

    // eslint-disable-next-line no-console
    reader.onabort = () => console.log('chunk file reading was aborted');
    // eslint-disable-next-line no-console
    reader.onerror = () => console.log('chunk file reading has failed');

    acceptedFiles.forEach((file) => {
      clearErrorMsg();
      setDataFile(file, file.name);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className="dropzone-control">
      <input {...getInputProps()} />
      {!fileName ? (
        <Typography
          className={classes.label}
          variant="subtitle1"
          color="textSecondary"
        >
          Drag 'n' drop a data file here, or click [HERE] to select a data file
        </Typography>
      ) : (
        <div>
          <Typography
            className={classes.label}
            variant="body1"
            color="textSecondary"
          >
            File Selected:{' '}
          </Typography>
          <Typography className={classes.label} variant="h6">
            [ {fileName} ]
          </Typography>
        </div>
      )}
    </div>
  );
}

export default ChunkDropZone;
