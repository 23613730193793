import { ListItemButton, MenuItem, Popover, Stack } from '@mui/material';
import List from '@mui/material/List';
import { useCreateInstancesSection } from './useCreateInstancesSection';
import { ConfigInstances } from './ConfigInstances';

export const CreateInstancesSection = () => {
  const {
    control,
    customers,
    marketOptions,
    userGroup,
    userGroupHelperText,
    marketIdHelperText,
    selectedMarket,
    userGroupInstances,
    isLoadingUserGroupInstances,
    marketInstances,
    isLoadingMarketInstances,
    createPopoverAnchorEl,
    isCreatePopoverOpen,
    handleClickCreatePopover,
    handleCloseCreatePopoever,
    missingInstances,
    handleClickDropdownItem,
  } = useCreateInstancesSection();

  return (
    <Stack textAlign="left" spacing={3}>
      <ConfigInstances
        control={control}
        name="userGroup"
        label="User Group"
        options={customers.map((customer) => (
          <MenuItem key={customer} value={customer}>
            {customer}
          </MenuItem>
        ))}
        disabledDropdown={!userGroup}
        userGroup={userGroup}
        handleClickDropdownOption={(e) =>
          handleClickCreatePopover(e, 'usergroup')
        }
        helperText={userGroupHelperText}
        instances={userGroupInstances}
        isLoading={isLoadingUserGroupInstances}
      />
      <ConfigInstances
        control={control}
        name="marketId"
        label="Market"
        options={marketOptions.map((market) => (
          <MenuItem key={market.value} value={market.value}>
            {market.label}
          </MenuItem>
        ))}
        disabledDropdown={!userGroup || !selectedMarket}
        userGroup={userGroup}
        handleClickDropdownOption={(e) => handleClickCreatePopover(e, 'market')}
        helperText={marketIdHelperText}
        instances={marketInstances}
        isLoading={isLoadingMarketInstances}
        selectedMarket={selectedMarket}
        showEmptyOption
      />
      <Popover
        open={isCreatePopoverOpen}
        anchorEl={createPopoverAnchorEl}
        onClose={handleCloseCreatePopoever}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          {missingInstances.map((schema) => (
            <ListItemButton
              key={schema.name}
              onClick={() => {
                handleClickDropdownItem(schema.name);
              }}
            >
              {schema.name}
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </Stack>
  );
};
