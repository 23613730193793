/* eslint-disable import/no-cycle */
import { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/lab/ToggleButton';
import ToggleButtonGroup from '@mui/lab/ToggleButtonGroup';
import { Content } from '../analysisview/content';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  textStyle: {
    fontSize: 12,
    fontWeight: 500,
  },
}));

export default function ToggleContent({ toggleContent, match }) {
  const classes = useStyles();
  const defaultContent =
    toggleContent && toggleContent[0] && toggleContent[0].content
      ? toggleContent[0].content
      : null;
  const [content, setContent] = useState(defaultContent);
  const [value, setValue] = useState(0);
  const contentMap = {};

  return (
    <div className={classes.root}>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={(event, newValue) => {
          if (contentMap && contentMap[newValue]) {
            setValue(newValue);
            setContent(contentMap[newValue]);
          }
        }}
        aria-label="toggle content"
        style={{ marginBottom: '1.5vh' }}
      >
        {Array.isArray(toggleContent)
          ? toggleContent.map((item, i) => {
              if (item && item.primaryText && item.content) {
                contentMap[i] = item.content;
                return (
                  <ToggleButton value={i} key={i}>
                    <Typography className={classes.textStyle}>
                      {item.primaryText}{' '}
                    </Typography>
                  </ToggleButton>
                );
              }
              return null;
            })
          : null}
      </ToggleButtonGroup>
      {content ? <Content content={content} match={match} /> : null}
    </div>
  );
}
