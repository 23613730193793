import PropTypes from 'prop-types';
import { styled, Avatar, Tooltip, Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MapsIcon from '../../../assets/icons/googleMaps.png';
import { trackGoogleMapSelection } from '../../../trackers/appEventTracker';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gridGap: 32,
    paddingBottom: 16,
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  secondaryText: {
    maxWidth: '80%',
  },
  linkAvatar: {
    width: 25,
    height: 25,
    '&:hover': {
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
}));

const FlexWrapper = styled(Box)(() => ({
  display: 'flex',
}));

const Subtitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryMaptualListFilteringColor,
}));

export default function ProfilePicNLinks({ entityUData }) {
  const classes = useStyles();
  const { entityName = {}, specialty = {}, location = {} } = entityUData;
  const { firstName = '', lastName = '' } = entityName;
  const { primary = '' } = specialty;
  const {
    stateProvShort = '',
    cityTown = '',
    address = '',
    locationName = '',
  } = location;

  return entityUData ? (
    <div
      className={`${classes.root} intercom-field-connections-tab-entity-profile`}
    >
      <FlexWrapper>
        <Box style={{ flexGrow: 1 }}>
          <Typography noWrap variant="h3">
            {`${firstName} ${lastName}`}
          </Typography>
          <Subtitle
            variant="body2"
            className={classes.secondaryText}
            noWrap
            style={{ marginTop: 5 }}
          >
            {`${primary}, ${locationName}`}
          </Subtitle>
          <Subtitle
            variant="body2"
            className={classes.secondaryText}
            noWrap
            style={{ marginTop: 3 }}
          >
            {`${cityTown}, ${stateProvShort}`}
          </Subtitle>
        </Box>
        <div className={classes.linkContainer}>
          {address !== '' ? (
            <Tooltip title={address} arrow>
              <Avatar
                className={classes.linkAvatar}
                src={MapsIcon}
                onClick={() => {
                  trackGoogleMapSelection(`${firstName} ${lastName}`, address);
                  window.open(
                    `https://www.google.com/maps/place/${encodeURI(address)} `,
                    '_blank'
                  );
                }}
              />
            </Tooltip>
          ) : null}
        </div>
      </FlexWrapper>
    </div>
  ) : null;
}

ProfilePicNLinks.propTypes = {
  entityUData: PropTypes.shape({
    location: PropTypes.shape({
      address: PropTypes.string,
      cityTown: PropTypes.string,
      stateProvShort: PropTypes.string,
      locationName: PropTypes.string,
    }),
    specialty: PropTypes.shape({
      primary: PropTypes.string,
    }),
    entityName: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
};
