/* tslint:disable */
/* eslint-disable */
/**
 * Maptual API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ActivityInsight
 */
export interface ActivityInsight {
    /**
     * 
     * @type {string}
     * @memberof ActivityInsight
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityInsight
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityInsight
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityInsight
     */
    'filterCriteria'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityInsight
     */
    'priority'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityInsight
     */
    'volume'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityInsight
     */
    'product'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityInsight
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityInsight
     */
    'timestamp'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiRoutersFieldEntityChartsModelResponseModelCadence
 */
export interface ApiRoutersFieldEntityChartsModelResponseModelCadence {
    /**
     * 
     * @type {string}
     * @memberof ApiRoutersFieldEntityChartsModelResponseModelCadence
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApiRoutersFieldEntityChartsModelResponseModelCadence
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface ApiRoutersFieldEntityChartsModelResponseModelMetric
 */
export interface ApiRoutersFieldEntityChartsModelResponseModelMetric {
    /**
     * 
     * @type {string}
     * @memberof ApiRoutersFieldEntityChartsModelResponseModelMetric
     */
    'rxType': string;
    /**
     * 
     * @type {string}
     * @memberof ApiRoutersFieldEntityChartsModelResponseModelMetric
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof ApiRoutersFieldEntityChartsModelResponseModelMetric
     */
    'unit': string | null;
    /**
     * 
     * @type {Array<ApiRoutersFieldEntityChartsModelResponseModelCadence>}
     * @memberof ApiRoutersFieldEntityChartsModelResponseModelMetric
     */
    'cadences': Array<ApiRoutersFieldEntityChartsModelResponseModelCadence>;
}
/**
 * 
 * @export
 * @interface ApiRoutersFieldEntityOverviewModelsDrawerMetadataResponseMetric
 */
export interface ApiRoutersFieldEntityOverviewModelsDrawerMetadataResponseMetric {
    /**
     * 
     * @type {Array<DomainsEnumsCadenceCadence>}
     * @memberof ApiRoutersFieldEntityOverviewModelsDrawerMetadataResponseMetric
     */
    'cadences': Array<DomainsEnumsCadenceCadence>;
    /**
     * 
     * @type {string}
     * @memberof ApiRoutersFieldEntityOverviewModelsDrawerMetadataResponseMetric
     */
    'rxType': string;
    /**
     * 
     * @type {string}
     * @memberof ApiRoutersFieldEntityOverviewModelsDrawerMetadataResponseMetric
     */
    'tooltipText': string;
    /**
     * 
     * @type {string}
     * @memberof ApiRoutersFieldEntityOverviewModelsDrawerMetadataResponseMetric
     */
    'unit': string;
}
/**
 * 
 * @export
 * @interface ApiRoutersFieldEntityOverviewModelsDrawerMetadataResponseObjective
 */
export interface ApiRoutersFieldEntityOverviewModelsDrawerMetadataResponseObjective {
    /**
     * 
     * @type {string}
     * @memberof ApiRoutersFieldEntityOverviewModelsDrawerMetadataResponseObjective
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApiRoutersFieldEntityOverviewModelsDrawerMetadataResponseObjective
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof ApiRoutersFieldEntityOverviewModelsDrawerMetadataResponseObjective
     */
    'maptualDisplayScore': number;
    /**
     * 
     * @type {Array<Chart>}
     * @memberof ApiRoutersFieldEntityOverviewModelsDrawerMetadataResponseObjective
     */
    'charts': Array<Chart>;
}
/**
 * 
 * @export
 * @interface ApiRoutersFieldTerritoryOverviewModelsResponseModelMetric
 */
export interface ApiRoutersFieldTerritoryOverviewModelsResponseModelMetric {
    /**
     * 
     * @type {string}
     * @memberof ApiRoutersFieldTerritoryOverviewModelsResponseModelMetric
     */
    'rxType': string;
    /**
     * 
     * @type {string}
     * @memberof ApiRoutersFieldTerritoryOverviewModelsResponseModelMetric
     */
    'label': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiRoutersFieldTerritoryOverviewModelsResponseModelMetric
     */
    'cadences': Array<string>;
}
/**
 * 
 * @export
 * @interface ApiRoutersFieldTerritoryOverviewModelsResponseModelObjective
 */
export interface ApiRoutersFieldTerritoryOverviewModelsResponseModelObjective {
    /**
     * 
     * @type {string}
     * @memberof ApiRoutersFieldTerritoryOverviewModelsResponseModelObjective
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApiRoutersFieldTerritoryOverviewModelsResponseModelObjective
     */
    'label': string;
    /**
     * 
     * @type {Array<ApiRoutersFieldTerritoryOverviewModelsResponseModelMetric>}
     * @memberof ApiRoutersFieldTerritoryOverviewModelsResponseModelObjective
     */
    'metrics': Array<ApiRoutersFieldTerritoryOverviewModelsResponseModelMetric>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ApplicationEntityOverviewSrcEnumsCadenceCadence = {
    Wk: 'wk',
    Mth: 'mth',
    Qtr: 'qtr',
    Yr: 'yr',
    Ytd: 'ytd',
    Mat: 'mat',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    Annually: 'Annually',
    Ytd2: 'YTD',
    Mat2: 'MAT'
} as const;

export type ApplicationEntityOverviewSrcEnumsCadenceCadence = typeof ApplicationEntityOverviewSrcEnumsCadenceCadence[keyof typeof ApplicationEntityOverviewSrcEnumsCadenceCadence];


/**
 * 
 * @export
 * @interface Basket
 */
export interface Basket {
    /**
     * 
     * @type {ParamType}
     * @memberof Basket
     */
    'type': ParamType;
    /**
     * 
     * @type {Array<string>}
     * @memberof Basket
     */
    'products': Array<string>;
    /**
     * 
     * @type {Array<BasketMetric>}
     * @memberof Basket
     */
    'metrics': Array<BasketMetric>;
}


/**
 * 
 * @export
 * @interface BasketData
 */
export interface BasketData {
    /**
     * 
     * @type {string}
     * @memberof BasketData
     */
    'label': string;
    /**
     * 
     * @type {Array<ChartValue>}
     * @memberof BasketData
     */
    'chartValues': Array<ChartValue>;
    /**
     * 
     * @type {string}
     * @memberof BasketData
     */
    'basketType': string;
}
/**
 * 
 * @export
 * @interface BasketMetric
 */
export interface BasketMetric {
    /**
     * 
     * @type {string}
     * @memberof BasketMetric
     */
    'metric': string;
    /**
     * 
     * @type {string}
     * @memberof BasketMetric
     */
    'paramType': string;
    /**
     * 
     * @type {number}
     * @memberof BasketMetric
     */
    'scoringWeight': number;
    /**
     * 
     * @type {number}
     * @memberof BasketMetric
     */
    'displayPriority': number;
    /**
     * 
     * @type {string}
     * @memberof BasketMetric
     */
    'entityType': string;
    /**
     * 
     * @type {string}
     * @memberof BasketMetric
     */
    'dataType': string;
}
/**
 * BasketMetric pydantic model.
 * @export
 * @interface BasketMetrics
 */
export interface BasketMetrics {
    /**
     * 
     * @type {string}
     * @memberof BasketMetrics
     */
    'entity': string;
    /**
     * 
     * @type {string}
     * @memberof BasketMetrics
     */
    'rxType': string;
    /**
     * 
     * @type {string}
     * @memberof BasketMetrics
     */
    'rxSourceType': string;
    /**
     * 
     * @type {number}
     * @memberof BasketMetrics
     */
    'displayPriority': number | null;
    /**
     * 
     * @type {Scoringweight}
     * @memberof BasketMetrics
     */
    'scoringWeight': Scoringweight;
}
/**
 * 
 * @export
 * @interface Basketmetrics
 */
export interface Basketmetrics {
}
/**
 * 
 * @export
 * @interface Basketscoringweight
 */
export interface Basketscoringweight {
}
/**
 * 
 * @export
 * @interface BasketscoringweightAnyOfValue
 */
export interface BasketscoringweightAnyOfValue {
}
/**
 * 
 * @export
 * @interface Chart
 */
export interface Chart {
    /**
     * 
     * @type {string}
     * @memberof Chart
     */
    'title': string;
    /**
     * 
     * @type {ChartType}
     * @memberof Chart
     */
    'type': ChartType;
    /**
     * 
     * @type {Array<ApiRoutersFieldEntityOverviewModelsDrawerMetadataResponseMetric>}
     * @memberof Chart
     */
    'metrics': Array<ApiRoutersFieldEntityOverviewModelsDrawerMetadataResponseMetric>;
}


/**
 * 
 * @export
 * @interface ChartMetadataResponse
 */
export interface ChartMetadataResponse {
    /**
     * 
     * @type {Array<MetricDataTypeOutput>}
     * @memberof ChartMetadataResponse
     */
    'metricDataTypes': Array<MetricDataTypeOutput>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChartType = {
    Line: 'line',
    Bar: 'bar',
    Circle: 'circle'
} as const;

export type ChartType = typeof ChartType[keyof typeof ChartType];


/**
 * 
 * @export
 * @interface ChartValue
 */
export interface ChartValue {
    /**
     * 
     * @type {number}
     * @memberof ChartValue
     */
    'data': number;
    /**
     * 
     * @type {string}
     * @memberof ChartValue
     */
    'timestamp': string;
    /**
     * 
     * @type {Trend}
     * @memberof ChartValue
     */
    'trend': Trend;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ComparisonTimeFrame = {
    CadenceLastYear: 'cadenceLastYear',
    PreviousCadence: 'previousCadence'
} as const;

export type ComparisonTimeFrame = typeof ComparisonTimeFrame[keyof typeof ComparisonTimeFrame];


/**
 * 
 * @export
 * @enum {string}
 */

export const ComparisonTimeFrameId = {
    CadenceLastYear: 'cadenceLastYear',
    PreviousCadence: 'previousCadence'
} as const;

export type ComparisonTimeFrameId = typeof ComparisonTimeFrameId[keyof typeof ComparisonTimeFrameId];


/**
 * 
 * @export
 * @interface ContactInfo
 */
export interface ContactInfo {
    /**
     * 
     * @type {string}
     * @memberof ContactInfo
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfo
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfo
     */
    'address': string;
}
/**
 * 
 * @export
 * @interface Contribution
 */
export interface Contribution {
    /**
     * 
     * @type {number}
     * @memberof Contribution
     */
    'value': number;
    /**
     * 
     * @type {Array<ContributionDataInner>}
     * @memberof Contribution
     */
    'data': Array<ContributionDataInner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Contribution
     */
    'contributingFactors': Array<string>;
}
/**
 * 
 * @export
 * @interface ContributionBreakdownResponse
 */
export interface ContributionBreakdownResponse {
    /**
     * 
     * @type {Contribution}
     * @memberof ContributionBreakdownResponse
     */
    'currentValue': Contribution;
    /**
     * 
     * @type {Contribution}
     * @memberof ContributionBreakdownResponse
     */
    'potential': Contribution;
}
/**
 * 
 * @export
 * @interface ContributionCategoricalMetric
 */
export interface ContributionCategoricalMetric {
    /**
     * 
     * @type {string}
     * @memberof ContributionCategoricalMetric
     */
    'type': ContributionCategoricalMetricTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ContributionCategoricalMetric
     */
    'metric': string;
    /**
     * 
     * @type {Array<ContributionCategory>}
     * @memberof ContributionCategoricalMetric
     */
    'categories': Array<ContributionCategory>;
}

export const ContributionCategoricalMetricTypeEnum = {
    Categorical: 'categorical'
} as const;

export type ContributionCategoricalMetricTypeEnum = typeof ContributionCategoricalMetricTypeEnum[keyof typeof ContributionCategoricalMetricTypeEnum];

/**
 * 
 * @export
 * @interface ContributionCategory
 */
export interface ContributionCategory {
    /**
     * 
     * @type {string}
     * @memberof ContributionCategory
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ContributionCategory
     */
    'value': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ContributionContentType = {
    Metrics: 'metrics',
    Segments: 'segments'
} as const;

export type ContributionContentType = typeof ContributionContentType[keyof typeof ContributionContentType];


/**
 * 
 * @export
 * @interface ContributionDataInner
 */
export interface ContributionDataInner {
    /**
     * 
     * @type {ContributionContentType}
     * @memberof ContributionDataInner
     */
    'type': ContributionContentType;
    /**
     * 
     * @type {Array<ContributionMetricsMetricsInner>}
     * @memberof ContributionDataInner
     */
    'metrics': Array<ContributionMetricsMetricsInner>;
    /**
     * 
     * @type {Array<ContributionSegment>}
     * @memberof ContributionDataInner
     */
    'segments': Array<ContributionSegment>;
}


/**
 * 
 * @export
 * @interface ContributionMetric
 */
export interface ContributionMetric {
    /**
     * 
     * @type {number}
     * @memberof ContributionMetric
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof ContributionMetric
     */
    'type': ContributionMetricTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ContributionMetric
     */
    'basketName': string;
    /**
     * 
     * @type {string}
     * @memberof ContributionMetric
     */
    'metric': string;
    /**
     * 
     * @type {string}
     * @memberof ContributionMetric
     */
    'dateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof ContributionMetric
     */
    'dateTo': string;
    /**
     * 
     * @type {string}
     * @memberof ContributionMetric
     */
    'unit': string | null;
}

export const ContributionMetricTypeEnum = {
    Continuous: 'continuous'
} as const;

export type ContributionMetricTypeEnum = typeof ContributionMetricTypeEnum[keyof typeof ContributionMetricTypeEnum];

/**
 * 
 * @export
 * @interface ContributionMetrics
 */
export interface ContributionMetrics {
    /**
     * 
     * @type {ContributionContentType}
     * @memberof ContributionMetrics
     */
    'type': ContributionContentType;
    /**
     * 
     * @type {Array<ContributionMetricsMetricsInner>}
     * @memberof ContributionMetrics
     */
    'metrics': Array<ContributionMetricsMetricsInner>;
}


/**
 * 
 * @export
 * @interface ContributionMetricsMetricsInner
 */
export interface ContributionMetricsMetricsInner {
    /**
     * 
     * @type {number}
     * @memberof ContributionMetricsMetricsInner
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof ContributionMetricsMetricsInner
     */
    'type': ContributionMetricsMetricsInnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ContributionMetricsMetricsInner
     */
    'basketName': string;
    /**
     * 
     * @type {string}
     * @memberof ContributionMetricsMetricsInner
     */
    'metric': string;
    /**
     * 
     * @type {string}
     * @memberof ContributionMetricsMetricsInner
     */
    'dateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof ContributionMetricsMetricsInner
     */
    'dateTo': string;
    /**
     * 
     * @type {string}
     * @memberof ContributionMetricsMetricsInner
     */
    'unit': string;
    /**
     * 
     * @type {Array<ContributionCategory>}
     * @memberof ContributionMetricsMetricsInner
     */
    'categories': Array<ContributionCategory>;
}

export const ContributionMetricsMetricsInnerTypeEnum = {
    Continuous: 'continuous',
    Categorical: 'categorical'
} as const;

export type ContributionMetricsMetricsInnerTypeEnum = typeof ContributionMetricsMetricsInnerTypeEnum[keyof typeof ContributionMetricsMetricsInnerTypeEnum];

/**
 * 
 * @export
 * @interface ContributionSegment
 */
export interface ContributionSegment {
    /**
     * 
     * @type {string}
     * @memberof ContributionSegment
     */
    'name': string;
    /**
     * 
     * @type {ContributionSegmentDetails}
     * @memberof ContributionSegment
     */
    'details': ContributionSegmentDetails;
}
/**
 * 
 * @export
 * @interface ContributionSegmentDetails
 */
export interface ContributionSegmentDetails {
    /**
     * 
     * @type {string}
     * @memberof ContributionSegmentDetails
     */
    'metric': string;
    /**
     * 
     * @type {string}
     * @memberof ContributionSegmentDetails
     */
    'targetBasket': string;
    /**
     * 
     * @type {string}
     * @memberof ContributionSegmentDetails
     */
    'competitiveBasket': string;
}
/**
 * 
 * @export
 * @interface ContributionSegments
 */
export interface ContributionSegments {
    /**
     * 
     * @type {ContributionContentType}
     * @memberof ContributionSegments
     */
    'type': ContributionContentType;
    /**
     * 
     * @type {Array<ContributionSegment>}
     * @memberof ContributionSegments
     */
    'segments': Array<ContributionSegment>;
}


/**
 * Create objective request body model.
 * @export
 * @interface CreateObjectivePostRequest
 */
export interface CreateObjectivePostRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateObjectivePostRequest
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateObjectivePostRequest
     */
    'typeDisplay': string;
    /**
     * 
     * @type {Sharemetric}
     * @memberof CreateObjectivePostRequest
     */
    'shareMetric'?: Sharemetric;
    /**
     * 
     * @type {boolean}
     * @memberof CreateObjectivePostRequest
     */
    'nonScoringObjective': boolean;
    /**
     * 
     * @type {ParamsCustomerObjective}
     * @memberof CreateObjectivePostRequest
     */
    'params': ParamsCustomerObjective;
}
/**
 * Customer project request body model.
 * @export
 * @interface CreateProjectRequest
 */
export interface CreateProjectRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'templateId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'productLineId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'dueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'changeLog'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'importType'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'projectImportId'?: string;
    /**
     * 
     * @type {Array<CreateObjectivePostRequest>}
     * @memberof CreateProjectRequest
     */
    'objectives': Array<CreateObjectivePostRequest>;
}
/**
 * 
 * @export
 * @interface Data
 */
export interface Data {
    /**
     * 
     * @type {number}
     * @memberof Data
     */
    'maptualDisplayScore': number;
    /**
     * 
     * @type {number}
     * @memberof Data
     */
    'maptualFractionalScore': number;
    /**
     * 
     * @type {number}
     * @memberof Data
     */
    'maptualScoreChange': number;
}
/**
 * 
 * @export
 * @interface Demographics
 */
export interface Demographics {
    /**
     * 
     * @type {string}
     * @memberof Demographics
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof Demographics
     */
    'ethnicity': string;
    /**
     * 
     * @type {string}
     * @memberof Demographics
     */
    'age': string | null;
}
/**
 * 
 * @export
 * @interface DeployCsvRequest
 */
export interface DeployCsvRequest {
    /**
     * 
     * @type {string}
     * @memberof DeployCsvRequest
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof DeployCsvRequest
     */
    's3_path': string;
    /**
     * 
     * @type {string}
     * @memberof DeployCsvRequest
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof DeployCsvRequest
     */
    'new_filename': string;
}
/**
 * 
 * @export
 * @interface Displayablemetrics
 */
export interface Displayablemetrics {
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DomainsEnumsCadenceCadence = {
    Wk: 'wk',
    Mth: 'mth',
    R3m: 'r3m',
    Qtr: 'qtr',
    Yr: 'yr',
    Mat: 'mat'
} as const;

export type DomainsEnumsCadenceCadence = typeof DomainsEnumsCadenceCadence[keyof typeof DomainsEnumsCadenceCadence];


/**
 * 
 * @export
 * @interface DrawerMetadataResponse
 */
export interface DrawerMetadataResponse {
    /**
     * 
     * @type {Array<ApiRoutersFieldEntityOverviewModelsDrawerMetadataResponseObjective>}
     * @memberof DrawerMetadataResponse
     */
    'objectives': Array<ApiRoutersFieldEntityOverviewModelsDrawerMetadataResponseObjective>;
}
/**
 * 
 * @export
 * @interface DrawerTimeline
 */
export interface DrawerTimeline {
    /**
     * 
     * @type {string}
     * @memberof DrawerTimeline
     */
    'timestamp': string;
    /**
     * 
     * @type {string}
     * @memberof DrawerTimeline
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof DrawerTimeline
     */
    'icon': string;
    /**
     * 
     * @type {string}
     * @memberof DrawerTimeline
     */
    'primaryText': string;
    /**
     * 
     * @type {string}
     * @memberof DrawerTimeline
     */
    'secondaryText': string;
    /**
     * 
     * @type {string}
     * @memberof DrawerTimeline
     */
    'channel': string;
    /**
     * 
     * @type {Data}
     * @memberof DrawerTimeline
     */
    'data': Data | null;
}
/**
 * 
 * @export
 * @interface DrawerTimelineResponse
 */
export interface DrawerTimelineResponse {
    /**
     * 
     * @type {Array<DrawerTimeline>}
     * @memberof DrawerTimelineResponse
     */
    'timeline': Array<DrawerTimeline>;
}
/**
 * 
 * @export
 * @interface Education
 */
export interface Education {
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'undergradUniversity': string;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'graduateUniversity': string;
    /**
     * 
     * @type {number}
     * @memberof Education
     */
    'graduationYear': number | null;
}
/**
 * 
 * @export
 * @interface EntityDataResponse
 */
export interface EntityDataResponse {
    /**
     * 
     * @type {Metadata}
     * @memberof EntityDataResponse
     */
    'metadata': Metadata;
    /**
     * 
     * @type {EntityUData}
     * @memberof EntityDataResponse
     */
    'entityUData': EntityUData | null;
}
/**
 * 
 * @export
 * @interface EntityGeoCode
 */
export interface EntityGeoCode {
    /**
     * 
     * @type {number}
     * @memberof EntityGeoCode
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof EntityGeoCode
     */
    'longitude': number;
    /**
     * 
     * @type {string}
     * @memberof EntityGeoCode
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof EntityGeoCode
     */
    'entityId': number;
}
/**
 * 
 * @export
 * @interface EntityJourneyResponse
 */
export interface EntityJourneyResponse {
    /**
     * 
     * @type {number}
     * @memberof EntityJourneyResponse
     */
    'timestamp': number;
    /**
     * 
     * @type {EventMetadata}
     * @memberof EntityJourneyResponse
     */
    'eventMetadata': EventMetadata;
    /**
     * 
     * @type {string}
     * @memberof EntityJourneyResponse
     */
    'icon': string;
    /**
     * 
     * @type {string}
     * @memberof EntityJourneyResponse
     */
    'primaryText': string;
    /**
     * 
     * @type {string}
     * @memberof EntityJourneyResponse
     */
    'secondaryText': string;
    /**
     * 
     * @type {Eventdata}
     * @memberof EntityJourneyResponse
     */
    'eventData': Eventdata | null;
}
/**
 * 
 * @export
 * @interface EntityName
 */
export interface EntityName {
    /**
     * 
     * @type {string}
     * @memberof EntityName
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof EntityName
     */
    'middleName': string;
    /**
     * 
     * @type {string}
     * @memberof EntityName
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface EntityPrecallInsights
 */
export interface EntityPrecallInsights {
    /**
     * 
     * @type {number}
     * @memberof EntityPrecallInsights
     */
    'entityId': number;
    /**
     * 
     * @type {string}
     * @memberof EntityPrecallInsights
     */
    'entityType': string;
    /**
     * 
     * @type {Array<PrecallInsight>}
     * @memberof EntityPrecallInsights
     */
    'insights': Array<PrecallInsight>;
}
/**
 * 
 * @export
 * @interface EntityTopAttributionResponse
 */
export interface EntityTopAttributionResponse {
    /**
     * 
     * @type {Array<TopChannel>}
     * @memberof EntityTopAttributionResponse
     */
    'maptualTopChannels': Array<TopChannel>;
    /**
     * 
     * @type {Array<TopMessage>}
     * @memberof EntityTopAttributionResponse
     */
    'maptualTopMessages': Array<TopMessage>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EntityType = {
    Hcp: 'hcp',
    Zone: 'zone',
    Fsa: 'fsa',
    Outlet: 'outlet',
    DirectAccount: 'direct_account',
    RegionalTeam: 'regional_team'
} as const;

export type EntityType = typeof EntityType[keyof typeof EntityType];


/**
 * 
 * @export
 * @interface EntityUData
 */
export interface EntityUData {
    /**
     * 
     * @type {string}
     * @memberof EntityUData
     */
    'entityUDataType': string;
    /**
     * 
     * @type {string}
     * @memberof EntityUData
     */
    'originalUserId': string;
    /**
     * 
     * @type {string}
     * @memberof EntityUData
     */
    'designation': string;
    /**
     * 
     * @type {string}
     * @memberof EntityUData
     */
    'designationShort': string;
    /**
     * 
     * @type {EntityName}
     * @memberof EntityUData
     */
    'entityName': EntityName;
    /**
     * 
     * @type {Specialty}
     * @memberof EntityUData
     */
    'specialty': Specialty;
    /**
     * 
     * @type {Demographics}
     * @memberof EntityUData
     */
    'demographics': Demographics;
    /**
     * 
     * @type {Location}
     * @memberof EntityUData
     */
    'location': Location;
    /**
     * 
     * @type {ContactInfo}
     * @memberof EntityUData
     */
    'contactInfo': ContactInfo;
    /**
     * 
     * @type {Education}
     * @memberof EntityUData
     */
    'education': Education;
    /**
     * 
     * @type {boolean}
     * @memberof EntityUData
     */
    'amaOptOut': boolean | null;
}
/**
 * 
 * @export
 * @interface Entitydisplayablemetrics
 */
export interface Entitydisplayablemetrics {
}
/**
 * 
 * @export
 * @interface EventData
 */
export interface EventData {
    /**
     * 
     * @type {number}
     * @memberof EventData
     */
    'maptualDisplayScore': number;
    /**
     * 
     * @type {number}
     * @memberof EventData
     */
    'maptualFractionalScore': number;
    /**
     * 
     * @type {number}
     * @memberof EventData
     */
    'maptualScoreChange': number;
}
/**
 * 
 * @export
 * @interface EventMetadata
 */
export interface EventMetadata {
    /**
     * 
     * @type {string}
     * @memberof EventMetadata
     */
    'eventCategory': string;
    /**
     * 
     * @type {string}
     * @memberof EventMetadata
     */
    'eventChannel': string;
    /**
     * 
     * @type {string}
     * @memberof EventMetadata
     */
    'eventType': string;
}
/**
 * 
 * @export
 * @interface Eventdata
 */
export interface Eventdata {
    /**
     * 
     * @type {number}
     * @memberof Eventdata
     */
    'maptualDisplayScore': number;
    /**
     * 
     * @type {number}
     * @memberof Eventdata
     */
    'maptualFractionalScore': number;
    /**
     * 
     * @type {number}
     * @memberof Eventdata
     */
    'maptualScoreChange': number;
}
/**
 * 
 * @export
 * @interface GeoCode
 */
export interface GeoCode {
    /**
     * 
     * @type {number}
     * @memberof GeoCode
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof GeoCode
     */
    'longitude': number;
    /**
     * 
     * @type {string}
     * @memberof GeoCode
     */
    'address': string;
}
/**
 * 
 * @export
 * @interface GeoCodePostModel
 */
export interface GeoCodePostModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof GeoCodePostModel
     */
    'addresses': Array<string>;
}
/**
 * 
 * @export
 * @interface GetProjectResponse
 */
export interface GetProjectResponse {
    [key: string]: any;

    /**
     * 
     * @type {object}
     * @memberof GetProjectResponse
     */
    'features': object;
    /**
     * 
     * @type {object}
     * @memberof GetProjectResponse
     */
    'metadata': object;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetProjectResponse
     */
    'objectives': Array<object>;
}
/**
 * 
 * @export
 * @interface HCPLists
 */
export interface HCPLists {
    /**
     * 
     * @type {Array<number>}
     * @memberof HCPLists
     */
    'starred'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof HCPLists
     */
    'longTermLeave'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof HCPLists
     */
    'noSee'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'locationType': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'locationName': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'locationSubUnit': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'continent': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'countryShort': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'stateProv': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'stateProvShort': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'county': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'cityTown': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'fsa': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'zipPostal': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'hospital': string;
}
/**
 * 
 * @export
 * @interface MarketPerformanceChartResponse
 */
export interface MarketPerformanceChartResponse {
    /**
     * 
     * @type {Array<BasketData>}
     * @memberof MarketPerformanceChartResponse
     */
    'data': Array<BasketData>;
    /**
     * 
     * @type {string}
     * @memberof MarketPerformanceChartResponse
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * 
     * @type {number}
     * @memberof Metadata
     */
    'entityId': number;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'entityType': string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'entityName': string;
    /**
     * 
     * @type {boolean}
     * @memberof Metadata
     */
    'isNonDld': boolean;
}
/**
 * 
 * @export
 * @interface MetadataActivityFilter
 */
export interface MetadataActivityFilter {
    /**
     * 
     * @type {string}
     * @memberof MetadataActivityFilter
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof MetadataActivityFilter
     */
    'id': number | null;
}
/**
 * 
 * @export
 * @interface MetadataBehaviouralSegmentFilter
 */
export interface MetadataBehaviouralSegmentFilter {
    /**
     * 
     * @type {string}
     * @memberof MetadataBehaviouralSegmentFilter
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataBehaviouralSegmentFilter
     */
    'name': string;
    /**
     * 
     * @type {Array<MetadataBehaviouralSegmentFilterOption>}
     * @memberof MetadataBehaviouralSegmentFilter
     */
    'options': Array<MetadataBehaviouralSegmentFilterOption>;
    /**
     * 
     * @type {string}
     * @memberof MetadataBehaviouralSegmentFilter
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface MetadataBehaviouralSegmentFilterOption
 */
export interface MetadataBehaviouralSegmentFilterOption {
    /**
     * 
     * @type {{ [key: string]: MetadataBehaviouralSegmentFilterOptionDescription; }}
     * @memberof MetadataBehaviouralSegmentFilterOption
     */
    'description': { [key: string]: MetadataBehaviouralSegmentFilterOptionDescription; } | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataBehaviouralSegmentFilterOption
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataBehaviouralSegmentFilterOption
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface MetadataBehaviouralSegmentFilterOptionDescription
 */
export interface MetadataBehaviouralSegmentFilterOptionDescription {
    /**
     * 
     * @type {object}
     * @memberof MetadataBehaviouralSegmentFilterOptionDescription
     */
    'params': object;
    /**
     * 
     * @type {string}
     * @memberof MetadataBehaviouralSegmentFilterOptionDescription
     */
    'template': string;
}
/**
 * 
 * @export
 * @interface MetadataObjective
 */
export interface MetadataObjective {
    /**
     * 
     * @type {string}
     * @memberof MetadataObjective
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MetadataObjective
     */
    'cadences': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MetadataObjective
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface MetadataParentFilterRegions
 */
export interface MetadataParentFilterRegions {
    /**
     * 
     * @type {string}
     * @memberof MetadataParentFilterRegions
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataParentFilterRegions
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface MetadataResponse
 */
export interface MetadataResponse {
    /**
     * 
     * @type {Array<MetadataActivityFilter>}
     * @memberof MetadataResponse
     */
    'activityFilters': Array<MetadataActivityFilter>;
    /**
     * 
     * @type {Array<MetadataBehaviouralSegmentFilter>}
     * @memberof MetadataResponse
     */
    'behaviouralSegmentFilters': Array<MetadataBehaviouralSegmentFilter>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MetadataResponse
     */
    'entitySubtypes': Array<string>;
    /**
     * 
     * @type {Array<MetadataObjective>}
     * @memberof MetadataResponse
     */
    'objectives': Array<MetadataObjective>;
    /**
     * 
     * @type {string}
     * @memberof MetadataResponse
     */
    'parentEntityType': string;
    /**
     * 
     * @type {Array<MetadataParentFilterRegions>}
     * @memberof MetadataResponse
     */
    'parentFilterRegions': Array<MetadataParentFilterRegions>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MetricDataTypeInput = {
    Volume: 'volume',
    Share: 'share'
} as const;

export type MetricDataTypeInput = typeof MetricDataTypeInput[keyof typeof MetricDataTypeInput];


/**
 * 
 * @export
 * @interface MetricDataTypeOutput
 */
export interface MetricDataTypeOutput {
    /**
     * 
     * @type {Array<ApiRoutersFieldEntityChartsModelResponseModelMetric>}
     * @memberof MetricDataTypeOutput
     */
    'metrics': Array<ApiRoutersFieldEntityChartsModelResponseModelMetric>;
    /**
     * 
     * @type {string}
     * @memberof MetricDataTypeOutput
     */
    'chartType': string;
    /**
     * 
     * @type {string}
     * @memberof MetricDataTypeOutput
     */
    'metricDataType': string;
}
/**
 * 
 * @export
 * @interface OpportunityChartMetadataResponse
 */
export interface OpportunityChartMetadataResponse {
    /**
     * 
     * @type {Array<ApiRoutersFieldEntityChartsModelResponseModelMetric>}
     * @memberof OpportunityChartMetadataResponse
     */
    'metrics': Array<ApiRoutersFieldEntityChartsModelResponseModelMetric>;
}
/**
 * 
 * @export
 * @interface OpportunityChartResponse
 */
export interface OpportunityChartResponse {
    /**
     * 
     * @type {Array<BasketData>}
     * @memberof OpportunityChartResponse
     */
    'data': Array<BasketData>;
    /**
     * 
     * @type {string}
     * @memberof OpportunityChartResponse
     */
    'title': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ParamType = {
    ToProduct: 'TO_PRODUCT',
    ProductFrom: 'PRODUCT_FROM',
    PrecursorProduct: 'PRECURSOR_PRODUCT',
    AnalogueProduct: 'ANALOGUE_PRODUCT',
    UnderlyingProduct: 'UNDERLYING_PRODUCT',
    IcsLabaClass: 'ICS_LABA_CLASS'
} as const;

export type ParamType = typeof ParamType[keyof typeof ParamType];


/**
 * Params Customer Objectives class.
 * @export
 * @interface ParamsCustomerObjective
 */
export interface ParamsCustomerObjective {
    [key: string]: any;

    /**
     * 
     * @type {Array<ProductCustomerObjective>}
     * @memberof ParamsCustomerObjective
     */
    'products': Array<ProductCustomerObjective>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PatchProjectActions = {
    Add: 'ADD',
    Remove: 'REMOVE'
} as const;

export type PatchProjectActions = typeof PatchProjectActions[keyof typeof PatchProjectActions];


/**
 * 
 * @export
 * @interface PowerScoreLlmSummaryResponse
 */
export interface PowerScoreLlmSummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof PowerScoreLlmSummaryResponse
     */
    'summary': string | null;
}
/**
 * 
 * @export
 * @interface PrecallInsight
 */
export interface PrecallInsight {
    /**
     * 
     * @type {string}
     * @memberof PrecallInsight
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof PrecallInsight
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PrecallInsight
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof PrecallInsight
     */
    'priority': number;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'label': string;
    /**
     * 
     * @type {Array<ApiRoutersFieldTerritoryOverviewModelsResponseModelMetric>}
     * @memberof Product
     */
    'metrics': Array<ApiRoutersFieldTerritoryOverviewModelsResponseModelMetric>;
}
/**
 * Pydantic class to model CustomerObjectives.
 * @export
 * @interface ProductCustomerObjective
 */
export interface ProductCustomerObjective {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProductCustomerObjective
     */
    'paramText': string;
    /**
     * 
     * @type {Basketscoringweight}
     * @memberof ProductCustomerObjective
     */
    'basketScoringWeight'?: Basketscoringweight;
    /**
     * 
     * @type {string}
     * @memberof ProductCustomerObjective
     */
    'paramType': string;
    /**
     * 
     * @type {string}
     * @memberof ProductCustomerObjective
     */
    'productFriendlyName': string;
    /**
     * 
     * @type {Array<ProductLine>}
     * @memberof ProductCustomerObjective
     */
    'productLines'?: Array<ProductLine>;
    /**
     * 
     * @type {{ [key: string]: BasketscoringweightAnyOfValue; }}
     * @memberof ProductCustomerObjective
     */
    'metricsScoringWeight': { [key: string]: BasketscoringweightAnyOfValue; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: BasketscoringweightAnyOfValue; }; }}
     * @memberof ProductCustomerObjective
     */
    'entityMetricsScoringWeight': { [key: string]: { [key: string]: BasketscoringweightAnyOfValue; }; };
    /**
     * 
     * @type {Basketmetrics}
     * @memberof ProductCustomerObjective
     */
    'basketMetrics'?: Basketmetrics;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductCustomerObjective
     */
    'specialties'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductCustomerObjective
     */
    'indications'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductCustomerObjective
     */
    'therapeuticAreas'?: Array<string>;
    /**
     * 
     * @type {Displayablemetrics}
     * @memberof ProductCustomerObjective
     */
    'displayableMetrics'?: Displayablemetrics;
    /**
     * 
     * @type {Entitydisplayablemetrics}
     * @memberof ProductCustomerObjective
     */
    'entityDisplayableMetrics'?: Entitydisplayablemetrics;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface ProductLine
 */
export interface ProductLine {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProductLine
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductLine
     */
    'productName': string;
}
/**
 * 
 * @export
 * @interface ProductPerformanceChartResponse
 */
export interface ProductPerformanceChartResponse {
    /**
     * 
     * @type {Array<BasketData>}
     * @memberof ProductPerformanceChartResponse
     */
    'data': Array<BasketData>;
    /**
     * 
     * @type {string}
     * @memberof ProductPerformanceChartResponse
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface ProjectPayload
 */
export interface ProjectPayload {
    /**
     * 
     * @type {PatchProjectActions}
     * @memberof ProjectPayload
     */
    'action': PatchProjectActions;
    /**
     * 
     * @type {string}
     * @memberof ProjectPayload
     */
    'projectId': string;
}


/**
 * 
 * @export
 * @interface RegionalActivityInsightsResponse
 */
export interface RegionalActivityInsightsResponse {
    /**
     * 
     * @type {{ [key: string]: Array<ActivityInsight>; }}
     * @memberof RegionalActivityInsightsResponse
     */
    'activity_insights': { [key: string]: Array<ActivityInsight>; };
}
/**
 * 
 * @export
 * @interface Rxsourcetype
 */
export interface Rxsourcetype {
}
/**
 * 
 * @export
 * @interface Rxtype
 */
export interface Rxtype {
}
/**
 * 
 * @export
 * @interface Scoringweight
 */
export interface Scoringweight {
}
/**
 * Share Metric pydantic model.
 * @export
 * @interface ShareMetric
 */
export interface ShareMetric {
    [key: string]: any;

    /**
     * 
     * @type {Uuid}
     * @memberof ShareMetric
     */
    'uuid'?: Uuid;
    /**
     * 
     * @type {Rxtype}
     * @memberof ShareMetric
     */
    'rxType'?: Rxtype;
    /**
     * 
     * @type {Rxsourcetype}
     * @memberof ShareMetric
     */
    'rxSourceType'?: Rxsourcetype;
}
/**
 * 
 * @export
 * @interface Sharemetric
 */
export interface Sharemetric {
    /**
     * 
     * @type {Uuid}
     * @memberof Sharemetric
     */
    'uuid'?: Uuid;
    /**
     * 
     * @type {Rxtype}
     * @memberof Sharemetric
     */
    'rxType'?: Rxtype;
    /**
     * 
     * @type {Rxsourcetype}
     * @memberof Sharemetric
     */
    'rxSourceType'?: Rxsourcetype;
}
/**
 * 
 * @export
 * @interface Specialty
 */
export interface Specialty {
    /**
     * 
     * @type {string}
     * @memberof Specialty
     */
    'primary': string;
    /**
     * 
     * @type {string}
     * @memberof Specialty
     */
    'secondary': string;
}
/**
 * 
 * @export
 * @interface TableResponse
 */
export interface TableResponse {
    /**
     * 
     * @type {Array<object>}
     * @memberof TableResponse
     */
    'headers': Array<object>;
    /**
     * 
     * @type {Array<Array<object>>}
     * @memberof TableResponse
     */
    'items': Array<Array<object>>;
}
/**
 * 
 * @export
 * @interface TerritoryOverviewMetadataResponse
 */
export interface TerritoryOverviewMetadataResponse {
    /**
     * 
     * @type {Array<ApiRoutersFieldTerritoryOverviewModelsResponseModelObjective>}
     * @memberof TerritoryOverviewMetadataResponse
     */
    'objectives': Array<ApiRoutersFieldTerritoryOverviewModelsResponseModelObjective>;
    /**
     * 
     * @type {Array<Product>}
     * @memberof TerritoryOverviewMetadataResponse
     */
    'products': Array<Product>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TerritoryOverviewMetadataResponse
     */
    'availableDistributions': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TerritoryOverviewMetadataResponse
     */
    'activityChannels': Array<string>;
}
/**
 * 
 * @export
 * @interface TopChannel
 */
export interface TopChannel {
    /**
     * 
     * @type {string}
     * @memberof TopChannel
     */
    'channelName': string;
    /**
     * 
     * @type {string}
     * @memberof TopChannel
     */
    'icon': string;
}
/**
 * 
 * @export
 * @interface TopMessage
 */
export interface TopMessage {
    /**
     * 
     * @type {string}
     * @memberof TopMessage
     */
    'messageName': string;
}
/**
 * 
 * @export
 * @interface Trend
 */
export interface Trend {
    /**
     * 
     * @type {number}
     * @memberof Trend
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof Trend
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof Trend
     */
    'value': number | null;
}
/**
 * 
 * @export
 * @interface UserBody
 */
export interface UserBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserBody
     */
    'assignedTerritoryIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UserBody
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserBody
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserBody
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserBody
     */
    'group'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserBody
     */
    'indications'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UserBody
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserBody
     */
    'maptualAccess'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserBody
     */
    'markets'?: Array<string> | null;
    /**
     * 
     * @type {object}
     * @memberof UserBody
     */
    'permission'?: object | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserBody
     */
    'projectFilters'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserBody
     */
    'projects'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UserBody
     */
    'role'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserBody
     */
    'specialType'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserBody
     */
    'specialty'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserBody
     */
    'territoryIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserBody
     */
    'therapeuticAreas'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserCreatedLists
 */
export interface UserCreatedLists {
    /**
     * 
     * @type {{ [key: string]: HCPLists; }}
     * @memberof UserCreatedLists
     */
    'lists': { [key: string]: HCPLists; };
}
/**
 * 
 * @export
 * @interface Uuid
 */
export interface Uuid {
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clears the cache for all_projects
         * @param {string} clearCacheFlag 
         * @param {string} [cacheFolder] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCacheAllProjectsAdminClearcacheGet: async (clearCacheFlag: string, cacheFolder?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clearCacheFlag' is not null or undefined
            assertParamExists('clearCacheAllProjectsAdminClearcacheGet', 'clearCacheFlag', clearCacheFlag)
            const localVarPath = `/admin/clearcache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clearCacheFlag !== undefined) {
                localVarQueryParameter['clear_cache_flag'] = clearCacheFlag;
            }

            if (cacheFolder !== undefined) {
                localVarQueryParameter['cache_folder'] = cacheFolder;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clears the cache for a single project
         * @param {string} projectId 
         * @param {string} clearCacheFlag 
         * @param {string} [cacheFolder] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCacheSingleProjectAdminClearcacheProjectIdGet: async (projectId: string, clearCacheFlag: string, cacheFolder?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('clearCacheSingleProjectAdminClearcacheProjectIdGet', 'projectId', projectId)
            // verify required parameter 'clearCacheFlag' is not null or undefined
            assertParamExists('clearCacheSingleProjectAdminClearcacheProjectIdGet', 'clearCacheFlag', clearCacheFlag)
            const localVarPath = `/admin/clearcache/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clearCacheFlag !== undefined) {
                localVarQueryParameter['clear_cache_flag'] = clearCacheFlag;
            }

            if (cacheFolder !== undefined) {
                localVarQueryParameter['cache_folder'] = cacheFolder;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns metrics with signal given a product line and product ids
         * @param {string} productLineId 
         * @param {GetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGetFilterCriteriaEnum} filterCriteria 
         * @param {Array<string>} [productIds] 
         * @param {Array<string>} [therapeuticAreas] 
         * @param {Array<string>} [indications] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet: async (productLineId: string, filterCriteria: GetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGetFilterCriteriaEnum, productIds?: Array<string>, therapeuticAreas?: Array<string>, indications?: Array<string>, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet', 'productLineId', productLineId)
            // verify required parameter 'filterCriteria' is not null or undefined
            assertParamExists('getAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet', 'filterCriteria', filterCriteria)
            const localVarPath = `/admin/product-lines/{product_line_id}/metricavailability`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterCriteria !== undefined) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (productIds) {
                localVarQueryParameter['product_ids'] = productIds;
            }

            if (therapeuticAreas) {
                localVarQueryParameter['therapeutic_areas'] = therapeuticAreas;
            }

            if (indications) {
                localVarQueryParameter['indications'] = indications;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Curation Events
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string} mode 
         * @param {string} [timestamp] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet: async (productLineId: string, projectId: string, mode: string, timestamp?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet', 'productLineId', productLineId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet', 'projectId', projectId)
            // verify required parameter 'mode' is not null or undefined
            assertParamExists('getCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet', 'mode', mode)
            const localVarPath = `/admin/product-lines/{product_line_id}/projects/{project_id}/curation/events/latest`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns summary information about the pipeline run status for the provided project
         * @param {string} projectId 
         * @param {string} productLineId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGet: async (projectId: string, productLineId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGet', 'projectId', projectId)
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGet', 'productLineId', productLineId)
            const localVarPath = `/admin/product-lines/{product_line_id}/projects/{project_id}/pipeline-status`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns project specific quality report checks
         * @param {string} projectId 
         * @param {string} productLineId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGet: async (projectId: string, productLineId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGet', 'projectId', projectId)
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGet', 'productLineId', productLineId)
            const localVarPath = `/admin/product-lines/{product_line_id}/projects/{project_id}/project-quality-checks`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns an overall summary of quality report checks
         * @param {string} productLineId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGet: async (productLineId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGet', 'productLineId', productLineId)
            const localVarPath = `/admin/product-lines/{product_line_id}/projects/quality-report-checks`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns user group specific quality report checks
         * @param {string} productLineId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGet: async (productLineId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGet', 'productLineId', productLineId)
            const localVarPath = `/admin/product-lines/{product_line_id}/projects/user-group-quality-checks`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns user group reports
         * @param {string} productLineId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGet: async (productLineId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGet', 'productLineId', productLineId)
            const localVarPath = `/admin/product-lines/{product_line_id}/projects/user-group-report`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create dynamo entry and kickoff subsequent processes.
         * @summary Post a new customer project
         * @param {CreateProjectRequest} createProjectRequest 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomerProjectAdminCustomerProjectsPost: async (createProjectRequest: CreateProjectRequest, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProjectRequest' is not null or undefined
            assertParamExists('postCustomerProjectAdminCustomerProjectsPost', 'createProjectRequest', createProjectRequest)
            const localVarPath = `/admin/customer-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Root
         * @param {string} productLineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRootAdminGet: async (productLineId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('readRootAdminGet', 'productLineId', productLineId)
            const localVarPath = `/admin/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productLineId !== undefined) {
                localVarQueryParameter['product_line_id'] = productLineId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rollback Curation
         * @param {string} marketId 
         * @param {string} productLineId 
         * @param {string} mode 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost: async (marketId: string, productLineId: string, mode: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketId' is not null or undefined
            assertParamExists('rollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost', 'marketId', marketId)
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('rollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost', 'productLineId', productLineId)
            // verify required parameter 'mode' is not null or undefined
            assertParamExists('rollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost', 'mode', mode)
            const localVarPath = `/admin/markets/{market_id}/product-lines/{product_line_id}/curation/rollback`
                .replace(`{${"market_id"}}`, encodeURIComponent(String(marketId)))
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Clears the cache for all_projects
         * @param {string} clearCacheFlag 
         * @param {string} [cacheFolder] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearCacheAllProjectsAdminClearcacheGet(clearCacheFlag: string, cacheFolder?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearCacheAllProjectsAdminClearcacheGet(clearCacheFlag, cacheFolder, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.clearCacheAllProjectsAdminClearcacheGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Clears the cache for a single project
         * @param {string} projectId 
         * @param {string} clearCacheFlag 
         * @param {string} [cacheFolder] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearCacheSingleProjectAdminClearcacheProjectIdGet(projectId: string, clearCacheFlag: string, cacheFolder?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearCacheSingleProjectAdminClearcacheProjectIdGet(projectId, clearCacheFlag, cacheFolder, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.clearCacheSingleProjectAdminClearcacheProjectIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns metrics with signal given a product line and product ids
         * @param {string} productLineId 
         * @param {GetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGetFilterCriteriaEnum} filterCriteria 
         * @param {Array<string>} [productIds] 
         * @param {Array<string>} [therapeuticAreas] 
         * @param {Array<string>} [indications] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet(productLineId: string, filterCriteria: GetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGetFilterCriteriaEnum, productIds?: Array<string>, therapeuticAreas?: Array<string>, indications?: Array<string>, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet(productLineId, filterCriteria, productIds, therapeuticAreas, indications, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.getAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Curation Events
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string} mode 
         * @param {string} [timestamp] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet(productLineId: string, projectId: string, mode: string, timestamp?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet(productLineId, projectId, mode, timestamp, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.getCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns summary information about the pipeline run status for the provided project
         * @param {string} projectId 
         * @param {string} productLineId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGet(projectId: string, productLineId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGet(projectId, productLineId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.getPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns project specific quality report checks
         * @param {string} projectId 
         * @param {string} productLineId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGet(projectId: string, productLineId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGet(projectId, productLineId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.getProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns an overall summary of quality report checks
         * @param {string} productLineId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGet(productLineId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGet(productLineId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.getQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns user group specific quality report checks
         * @param {string} productLineId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGet(productLineId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGet(productLineId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.getUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns user group reports
         * @param {string} productLineId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGet(productLineId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGet(productLineId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.getUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create dynamo entry and kickoff subsequent processes.
         * @summary Post a new customer project
         * @param {CreateProjectRequest} createProjectRequest 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCustomerProjectAdminCustomerProjectsPost(createProjectRequest: CreateProjectRequest, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCustomerProjectAdminCustomerProjectsPost(createProjectRequest, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.postCustomerProjectAdminCustomerProjectsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Root
         * @param {string} productLineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readRootAdminGet(productLineId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readRootAdminGet(productLineId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.readRootAdminGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Rollback Curation
         * @param {string} marketId 
         * @param {string} productLineId 
         * @param {string} mode 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost(marketId: string, productLineId: string, mode: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost(marketId, productLineId, mode, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.rollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Clears the cache for all_projects
         * @param {AdminApiClearCacheAllProjectsAdminClearcacheGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCacheAllProjectsAdminClearcacheGet(requestParameters: AdminApiClearCacheAllProjectsAdminClearcacheGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.clearCacheAllProjectsAdminClearcacheGet(requestParameters.clearCacheFlag, requestParameters.cacheFolder, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clears the cache for a single project
         * @param {AdminApiClearCacheSingleProjectAdminClearcacheProjectIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCacheSingleProjectAdminClearcacheProjectIdGet(requestParameters: AdminApiClearCacheSingleProjectAdminClearcacheProjectIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.clearCacheSingleProjectAdminClearcacheProjectIdGet(requestParameters.projectId, requestParameters.clearCacheFlag, requestParameters.cacheFolder, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns metrics with signal given a product line and product ids
         * @param {AdminApiGetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet(requestParameters: AdminApiGetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet(requestParameters.productLineId, requestParameters.filterCriteria, requestParameters.productIds, requestParameters.therapeuticAreas, requestParameters.indications, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Curation Events
         * @param {AdminApiGetCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet(requestParameters: AdminApiGetCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.mode, requestParameters.timestamp, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns summary information about the pipeline run status for the provided project
         * @param {AdminApiGetPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGet(requestParameters: AdminApiGetPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGet(requestParameters.projectId, requestParameters.productLineId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns project specific quality report checks
         * @param {AdminApiGetProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGet(requestParameters: AdminApiGetProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGet(requestParameters.projectId, requestParameters.productLineId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns an overall summary of quality report checks
         * @param {AdminApiGetQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGet(requestParameters: AdminApiGetQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGet(requestParameters.productLineId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns user group specific quality report checks
         * @param {AdminApiGetUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGet(requestParameters: AdminApiGetUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGet(requestParameters.productLineId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns user group reports
         * @param {AdminApiGetUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGet(requestParameters: AdminApiGetUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGet(requestParameters.productLineId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * Create dynamo entry and kickoff subsequent processes.
         * @summary Post a new customer project
         * @param {AdminApiPostCustomerProjectAdminCustomerProjectsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomerProjectAdminCustomerProjectsPost(requestParameters: AdminApiPostCustomerProjectAdminCustomerProjectsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.postCustomerProjectAdminCustomerProjectsPost(requestParameters.createProjectRequest, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Root
         * @param {AdminApiReadRootAdminGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRootAdminGet(requestParameters: AdminApiReadRootAdminGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.readRootAdminGet(requestParameters.productLineId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Rollback Curation
         * @param {AdminApiRollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost(requestParameters: AdminApiRollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.rollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost(requestParameters.marketId, requestParameters.productLineId, requestParameters.mode, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for clearCacheAllProjectsAdminClearcacheGet operation in AdminApi.
 * @export
 * @interface AdminApiClearCacheAllProjectsAdminClearcacheGetRequest
 */
export interface AdminApiClearCacheAllProjectsAdminClearcacheGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiClearCacheAllProjectsAdminClearcacheGet
     */
    readonly clearCacheFlag: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiClearCacheAllProjectsAdminClearcacheGet
     */
    readonly cacheFolder?: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiClearCacheAllProjectsAdminClearcacheGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiClearCacheAllProjectsAdminClearcacheGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiClearCacheAllProjectsAdminClearcacheGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiClearCacheAllProjectsAdminClearcacheGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for clearCacheSingleProjectAdminClearcacheProjectIdGet operation in AdminApi.
 * @export
 * @interface AdminApiClearCacheSingleProjectAdminClearcacheProjectIdGetRequest
 */
export interface AdminApiClearCacheSingleProjectAdminClearcacheProjectIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiClearCacheSingleProjectAdminClearcacheProjectIdGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiClearCacheSingleProjectAdminClearcacheProjectIdGet
     */
    readonly clearCacheFlag: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiClearCacheSingleProjectAdminClearcacheProjectIdGet
     */
    readonly cacheFolder?: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiClearCacheSingleProjectAdminClearcacheProjectIdGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiClearCacheSingleProjectAdminClearcacheProjectIdGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiClearCacheSingleProjectAdminClearcacheProjectIdGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiClearCacheSingleProjectAdminClearcacheProjectIdGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet operation in AdminApi.
 * @export
 * @interface AdminApiGetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGetRequest
 */
export interface AdminApiGetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiGetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {'volume' | 'share'}
     * @memberof AdminApiGetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet
     */
    readonly filterCriteria: GetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGetFilterCriteriaEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof AdminApiGetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet
     */
    readonly productIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AdminApiGetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet
     */
    readonly therapeuticAreas?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AdminApiGetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet
     */
    readonly indications?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet operation in AdminApi.
 * @export
 * @interface AdminApiGetCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGetRequest
 */
export interface AdminApiGetCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiGetCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet
     */
    readonly mode: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet
     */
    readonly timestamp?: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGet operation in AdminApi.
 * @export
 * @interface AdminApiGetPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGetRequest
 */
export interface AdminApiGetPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiGetPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGet operation in AdminApi.
 * @export
 * @interface AdminApiGetProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGetRequest
 */
export interface AdminApiGetProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiGetProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGet operation in AdminApi.
 * @export
 * @interface AdminApiGetQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGetRequest
 */
export interface AdminApiGetQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiGetQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGet operation in AdminApi.
 * @export
 * @interface AdminApiGetUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGetRequest
 */
export interface AdminApiGetUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiGetUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGet operation in AdminApi.
 * @export
 * @interface AdminApiGetUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGetRequest
 */
export interface AdminApiGetUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiGetUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for postCustomerProjectAdminCustomerProjectsPost operation in AdminApi.
 * @export
 * @interface AdminApiPostCustomerProjectAdminCustomerProjectsPostRequest
 */
export interface AdminApiPostCustomerProjectAdminCustomerProjectsPostRequest {
    /**
     * 
     * @type {CreateProjectRequest}
     * @memberof AdminApiPostCustomerProjectAdminCustomerProjectsPost
     */
    readonly createProjectRequest: CreateProjectRequest

    /**
     * 
     * @type {string}
     * @memberof AdminApiPostCustomerProjectAdminCustomerProjectsPost
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiPostCustomerProjectAdminCustomerProjectsPost
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiPostCustomerProjectAdminCustomerProjectsPost
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiPostCustomerProjectAdminCustomerProjectsPost
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for readRootAdminGet operation in AdminApi.
 * @export
 * @interface AdminApiReadRootAdminGetRequest
 */
export interface AdminApiReadRootAdminGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiReadRootAdminGet
     */
    readonly productLineId: string
}

/**
 * Request parameters for rollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost operation in AdminApi.
 * @export
 * @interface AdminApiRollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPostRequest
 */
export interface AdminApiRollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiRollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost
     */
    readonly marketId: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiRollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiRollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost
     */
    readonly mode: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiRollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiRollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiRollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof AdminApiRollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary Clears the cache for all_projects
     * @param {AdminApiClearCacheAllProjectsAdminClearcacheGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public clearCacheAllProjectsAdminClearcacheGet(requestParameters: AdminApiClearCacheAllProjectsAdminClearcacheGetRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).clearCacheAllProjectsAdminClearcacheGet(requestParameters.clearCacheFlag, requestParameters.cacheFolder, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Clears the cache for a single project
     * @param {AdminApiClearCacheSingleProjectAdminClearcacheProjectIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public clearCacheSingleProjectAdminClearcacheProjectIdGet(requestParameters: AdminApiClearCacheSingleProjectAdminClearcacheProjectIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).clearCacheSingleProjectAdminClearcacheProjectIdGet(requestParameters.projectId, requestParameters.clearCacheFlag, requestParameters.cacheFolder, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns metrics with signal given a product line and product ids
     * @param {AdminApiGetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet(requestParameters: AdminApiGetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGetRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGet(requestParameters.productLineId, requestParameters.filterCriteria, requestParameters.productIds, requestParameters.therapeuticAreas, requestParameters.indications, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Curation Events
     * @param {AdminApiGetCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet(requestParameters: AdminApiGetCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGetRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).getCurationEventsAdminProductLinesProductLineIdProjectsProjectIdCurationEventsLatestGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.mode, requestParameters.timestamp, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns summary information about the pipeline run status for the provided project
     * @param {AdminApiGetPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGet(requestParameters: AdminApiGetPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGetRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).getPipelineStatusAdminProductLinesProductLineIdProjectsProjectIdPipelineStatusGet(requestParameters.projectId, requestParameters.productLineId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns project specific quality report checks
     * @param {AdminApiGetProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGet(requestParameters: AdminApiGetProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGetRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).getProjectQualityChecksAdminProductLinesProductLineIdProjectsProjectIdProjectQualityChecksGet(requestParameters.projectId, requestParameters.productLineId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns an overall summary of quality report checks
     * @param {AdminApiGetQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGet(requestParameters: AdminApiGetQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGetRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).getQualityReportChecksAdminProductLinesProductLineIdProjectsQualityReportChecksGet(requestParameters.productLineId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns user group specific quality report checks
     * @param {AdminApiGetUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGet(requestParameters: AdminApiGetUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGetRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).getUserGroupQualityChecksAdminProductLinesProductLineIdProjectsUserGroupQualityChecksGet(requestParameters.productLineId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns user group reports
     * @param {AdminApiGetUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGet(requestParameters: AdminApiGetUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGetRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).getUserGroupReportAdminProductLinesProductLineIdProjectsUserGroupReportGet(requestParameters.productLineId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create dynamo entry and kickoff subsequent processes.
     * @summary Post a new customer project
     * @param {AdminApiPostCustomerProjectAdminCustomerProjectsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postCustomerProjectAdminCustomerProjectsPost(requestParameters: AdminApiPostCustomerProjectAdminCustomerProjectsPostRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).postCustomerProjectAdminCustomerProjectsPost(requestParameters.createProjectRequest, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Root
     * @param {AdminApiReadRootAdminGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public readRootAdminGet(requestParameters: AdminApiReadRootAdminGetRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).readRootAdminGet(requestParameters.productLineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Rollback Curation
     * @param {AdminApiRollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public rollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost(requestParameters: AdminApiRollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPostRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).rollbackCurationAdminMarketsMarketIdProductLinesProductLineIdCurationRollbackPost(requestParameters.marketId, requestParameters.productLineId, requestParameters.mode, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGetFilterCriteriaEnum = {
    Volume: 'volume',
    Share: 'share'
} as const;
export type GetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGetFilterCriteriaEnum = typeof GetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGetFilterCriteriaEnum[keyof typeof GetAvailableMetricsAdminProductLinesProductLineIdMetricavailabilityGetFilterCriteriaEnum];


/**
 * CSVDeliveryDashboardApi - axios parameter creator
 * @export
 */
export const CSVDeliveryDashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Csv Delivery Project
         * @param {object} body 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCsvDeliveryProjectCsvDeliveryDashboardAddProjectPost: async (body: object, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addCsvDeliveryProjectCsvDeliveryDashboardAddProjectPost', 'body', body)
            const localVarPath = `/csv-delivery-dashboard/add-project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Recent Projects Route
         * @param {string} userGroup 
         * @param {string} businessUnit 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGet: async (userGroup: string, businessUnit: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGet', 'userGroup', userGroup)
            // verify required parameter 'businessUnit' is not null or undefined
            assertParamExists('getRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGet', 'businessUnit', businessUnit)
            const localVarPath = `/csv-delivery-dashboard/recent-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }

            if (businessUnit !== undefined) {
                localVarQueryParameter['business_unit'] = businessUnit;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Statuses
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusesCsvDeliveryDashboardStatusesGet: async (referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/csv-delivery-dashboard/statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset Project Status
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetProjectStatusCsvDeliveryDashboardResetDashboardPost: async (referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/csv-delivery-dashboard/reset-dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset Project Status
         * @param {object} body 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetProjectStatusCsvDeliveryDashboardResetProjectPost: async (body: object, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('resetProjectStatusCsvDeliveryDashboardResetProjectPost', 'body', body)
            const localVarPath = `/csv-delivery-dashboard/reset-project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Status Route
         * @param {string} projectId 
         * @param {object} body 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusRouteCsvDeliveryDashboardStatusesProjectIdPut: async (projectId: string, body: object, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateStatusRouteCsvDeliveryDashboardStatusesProjectIdPut', 'projectId', projectId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateStatusRouteCsvDeliveryDashboardStatusesProjectIdPut', 'body', body)
            const localVarPath = `/csv-delivery-dashboard/statuses/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CSVDeliveryDashboardApi - functional programming interface
 * @export
 */
export const CSVDeliveryDashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CSVDeliveryDashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Csv Delivery Project
         * @param {object} body 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCsvDeliveryProjectCsvDeliveryDashboardAddProjectPost(body: object, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCsvDeliveryProjectCsvDeliveryDashboardAddProjectPost(body, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CSVDeliveryDashboardApi.addCsvDeliveryProjectCsvDeliveryDashboardAddProjectPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Recent Projects Route
         * @param {string} userGroup 
         * @param {string} businessUnit 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGet(userGroup: string, businessUnit: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGet(userGroup, businessUnit, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CSVDeliveryDashboardApi.getRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Statuses
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatusesCsvDeliveryDashboardStatusesGet(referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatusesCsvDeliveryDashboardStatusesGet(referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CSVDeliveryDashboardApi.getStatusesCsvDeliveryDashboardStatusesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reset Project Status
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetProjectStatusCsvDeliveryDashboardResetDashboardPost(referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetProjectStatusCsvDeliveryDashboardResetDashboardPost(referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CSVDeliveryDashboardApi.resetProjectStatusCsvDeliveryDashboardResetDashboardPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reset Project Status
         * @param {object} body 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetProjectStatusCsvDeliveryDashboardResetProjectPost(body: object, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetProjectStatusCsvDeliveryDashboardResetProjectPost(body, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CSVDeliveryDashboardApi.resetProjectStatusCsvDeliveryDashboardResetProjectPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Status Route
         * @param {string} projectId 
         * @param {object} body 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStatusRouteCsvDeliveryDashboardStatusesProjectIdPut(projectId: string, body: object, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStatusRouteCsvDeliveryDashboardStatusesProjectIdPut(projectId, body, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CSVDeliveryDashboardApi.updateStatusRouteCsvDeliveryDashboardStatusesProjectIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CSVDeliveryDashboardApi - factory interface
 * @export
 */
export const CSVDeliveryDashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CSVDeliveryDashboardApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Csv Delivery Project
         * @param {CSVDeliveryDashboardApiAddCsvDeliveryProjectCsvDeliveryDashboardAddProjectPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCsvDeliveryProjectCsvDeliveryDashboardAddProjectPost(requestParameters: CSVDeliveryDashboardApiAddCsvDeliveryProjectCsvDeliveryDashboardAddProjectPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.addCsvDeliveryProjectCsvDeliveryDashboardAddProjectPost(requestParameters.body, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Recent Projects Route
         * @param {CSVDeliveryDashboardApiGetRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGet(requestParameters: CSVDeliveryDashboardApiGetRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGet(requestParameters.userGroup, requestParameters.businessUnit, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Statuses
         * @param {CSVDeliveryDashboardApiGetStatusesCsvDeliveryDashboardStatusesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusesCsvDeliveryDashboardStatusesGet(requestParameters: CSVDeliveryDashboardApiGetStatusesCsvDeliveryDashboardStatusesGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getStatusesCsvDeliveryDashboardStatusesGet(requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset Project Status
         * @param {CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetDashboardPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetProjectStatusCsvDeliveryDashboardResetDashboardPost(requestParameters: CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetDashboardPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.resetProjectStatusCsvDeliveryDashboardResetDashboardPost(requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset Project Status
         * @param {CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetProjectPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetProjectStatusCsvDeliveryDashboardResetProjectPost(requestParameters: CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetProjectPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.resetProjectStatusCsvDeliveryDashboardResetProjectPost(requestParameters.body, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Status Route
         * @param {CSVDeliveryDashboardApiUpdateStatusRouteCsvDeliveryDashboardStatusesProjectIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusRouteCsvDeliveryDashboardStatusesProjectIdPut(requestParameters: CSVDeliveryDashboardApiUpdateStatusRouteCsvDeliveryDashboardStatusesProjectIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.updateStatusRouteCsvDeliveryDashboardStatusesProjectIdPut(requestParameters.projectId, requestParameters.body, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addCsvDeliveryProjectCsvDeliveryDashboardAddProjectPost operation in CSVDeliveryDashboardApi.
 * @export
 * @interface CSVDeliveryDashboardApiAddCsvDeliveryProjectCsvDeliveryDashboardAddProjectPostRequest
 */
export interface CSVDeliveryDashboardApiAddCsvDeliveryProjectCsvDeliveryDashboardAddProjectPostRequest {
    /**
     * 
     * @type {object}
     * @memberof CSVDeliveryDashboardApiAddCsvDeliveryProjectCsvDeliveryDashboardAddProjectPost
     */
    readonly body: object

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiAddCsvDeliveryProjectCsvDeliveryDashboardAddProjectPost
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiAddCsvDeliveryProjectCsvDeliveryDashboardAddProjectPost
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiAddCsvDeliveryProjectCsvDeliveryDashboardAddProjectPost
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiAddCsvDeliveryProjectCsvDeliveryDashboardAddProjectPost
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGet operation in CSVDeliveryDashboardApi.
 * @export
 * @interface CSVDeliveryDashboardApiGetRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGetRequest
 */
export interface CSVDeliveryDashboardApiGetRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiGetRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGet
     */
    readonly userGroup: string

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiGetRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGet
     */
    readonly businessUnit: string

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiGetRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiGetRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiGetRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiGetRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getStatusesCsvDeliveryDashboardStatusesGet operation in CSVDeliveryDashboardApi.
 * @export
 * @interface CSVDeliveryDashboardApiGetStatusesCsvDeliveryDashboardStatusesGetRequest
 */
export interface CSVDeliveryDashboardApiGetStatusesCsvDeliveryDashboardStatusesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiGetStatusesCsvDeliveryDashboardStatusesGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiGetStatusesCsvDeliveryDashboardStatusesGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiGetStatusesCsvDeliveryDashboardStatusesGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiGetStatusesCsvDeliveryDashboardStatusesGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for resetProjectStatusCsvDeliveryDashboardResetDashboardPost operation in CSVDeliveryDashboardApi.
 * @export
 * @interface CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetDashboardPostRequest
 */
export interface CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetDashboardPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetDashboardPost
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetDashboardPost
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetDashboardPost
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetDashboardPost
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for resetProjectStatusCsvDeliveryDashboardResetProjectPost operation in CSVDeliveryDashboardApi.
 * @export
 * @interface CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetProjectPostRequest
 */
export interface CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetProjectPostRequest {
    /**
     * 
     * @type {object}
     * @memberof CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetProjectPost
     */
    readonly body: object

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetProjectPost
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetProjectPost
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetProjectPost
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetProjectPost
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for updateStatusRouteCsvDeliveryDashboardStatusesProjectIdPut operation in CSVDeliveryDashboardApi.
 * @export
 * @interface CSVDeliveryDashboardApiUpdateStatusRouteCsvDeliveryDashboardStatusesProjectIdPutRequest
 */
export interface CSVDeliveryDashboardApiUpdateStatusRouteCsvDeliveryDashboardStatusesProjectIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiUpdateStatusRouteCsvDeliveryDashboardStatusesProjectIdPut
     */
    readonly projectId: string

    /**
     * 
     * @type {object}
     * @memberof CSVDeliveryDashboardApiUpdateStatusRouteCsvDeliveryDashboardStatusesProjectIdPut
     */
    readonly body: object

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiUpdateStatusRouteCsvDeliveryDashboardStatusesProjectIdPut
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiUpdateStatusRouteCsvDeliveryDashboardStatusesProjectIdPut
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiUpdateStatusRouteCsvDeliveryDashboardStatusesProjectIdPut
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeliveryDashboardApiUpdateStatusRouteCsvDeliveryDashboardStatusesProjectIdPut
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * CSVDeliveryDashboardApi - object-oriented interface
 * @export
 * @class CSVDeliveryDashboardApi
 * @extends {BaseAPI}
 */
export class CSVDeliveryDashboardApi extends BaseAPI {
    /**
     * 
     * @summary Add Csv Delivery Project
     * @param {CSVDeliveryDashboardApiAddCsvDeliveryProjectCsvDeliveryDashboardAddProjectPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CSVDeliveryDashboardApi
     */
    public addCsvDeliveryProjectCsvDeliveryDashboardAddProjectPost(requestParameters: CSVDeliveryDashboardApiAddCsvDeliveryProjectCsvDeliveryDashboardAddProjectPostRequest, options?: RawAxiosRequestConfig) {
        return CSVDeliveryDashboardApiFp(this.configuration).addCsvDeliveryProjectCsvDeliveryDashboardAddProjectPost(requestParameters.body, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Recent Projects Route
     * @param {CSVDeliveryDashboardApiGetRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CSVDeliveryDashboardApi
     */
    public getRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGet(requestParameters: CSVDeliveryDashboardApiGetRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGetRequest, options?: RawAxiosRequestConfig) {
        return CSVDeliveryDashboardApiFp(this.configuration).getRecentProjectsRouteCsvDeliveryDashboardRecentProjectsGet(requestParameters.userGroup, requestParameters.businessUnit, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Statuses
     * @param {CSVDeliveryDashboardApiGetStatusesCsvDeliveryDashboardStatusesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CSVDeliveryDashboardApi
     */
    public getStatusesCsvDeliveryDashboardStatusesGet(requestParameters: CSVDeliveryDashboardApiGetStatusesCsvDeliveryDashboardStatusesGetRequest = {}, options?: RawAxiosRequestConfig) {
        return CSVDeliveryDashboardApiFp(this.configuration).getStatusesCsvDeliveryDashboardStatusesGet(requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset Project Status
     * @param {CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetDashboardPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CSVDeliveryDashboardApi
     */
    public resetProjectStatusCsvDeliveryDashboardResetDashboardPost(requestParameters: CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetDashboardPostRequest = {}, options?: RawAxiosRequestConfig) {
        return CSVDeliveryDashboardApiFp(this.configuration).resetProjectStatusCsvDeliveryDashboardResetDashboardPost(requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset Project Status
     * @param {CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetProjectPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CSVDeliveryDashboardApi
     */
    public resetProjectStatusCsvDeliveryDashboardResetProjectPost(requestParameters: CSVDeliveryDashboardApiResetProjectStatusCsvDeliveryDashboardResetProjectPostRequest, options?: RawAxiosRequestConfig) {
        return CSVDeliveryDashboardApiFp(this.configuration).resetProjectStatusCsvDeliveryDashboardResetProjectPost(requestParameters.body, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Status Route
     * @param {CSVDeliveryDashboardApiUpdateStatusRouteCsvDeliveryDashboardStatusesProjectIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CSVDeliveryDashboardApi
     */
    public updateStatusRouteCsvDeliveryDashboardStatusesProjectIdPut(requestParameters: CSVDeliveryDashboardApiUpdateStatusRouteCsvDeliveryDashboardStatusesProjectIdPutRequest, options?: RawAxiosRequestConfig) {
        return CSVDeliveryDashboardApiFp(this.configuration).updateStatusRouteCsvDeliveryDashboardStatusesProjectIdPut(requestParameters.projectId, requestParameters.body, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CSVDeploymentsApi - axios parameter creator
 * @export
 */
export const CSVDeploymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get available CSVs
         * @param {string} projectId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCsvCsvDeploymentsGet: async (projectId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getCsvCsvDeploymentsGet', 'projectId', projectId)
            const localVarPath = `/csv-deployments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deploy a CSV
         * @param {DeployCsvRequest} deployCsvRequest 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCsvCsvDeploymentsPost: async (deployCsvRequest: DeployCsvRequest, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deployCsvRequest' is not null or undefined
            assertParamExists('getCsvCsvDeploymentsPost', 'deployCsvRequest', deployCsvRequest)
            const localVarPath = `/csv-deployments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deployCsvRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CSVDeploymentsApi - functional programming interface
 * @export
 */
export const CSVDeploymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CSVDeploymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get available CSVs
         * @param {string} projectId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCsvCsvDeploymentsGet(projectId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCsvCsvDeploymentsGet(projectId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CSVDeploymentsApi.getCsvCsvDeploymentsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deploy a CSV
         * @param {DeployCsvRequest} deployCsvRequest 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCsvCsvDeploymentsPost(deployCsvRequest: DeployCsvRequest, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCsvCsvDeploymentsPost(deployCsvRequest, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CSVDeploymentsApi.getCsvCsvDeploymentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CSVDeploymentsApi - factory interface
 * @export
 */
export const CSVDeploymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CSVDeploymentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get available CSVs
         * @param {CSVDeploymentsApiGetCsvCsvDeploymentsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCsvCsvDeploymentsGet(requestParameters: CSVDeploymentsApiGetCsvCsvDeploymentsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getCsvCsvDeploymentsGet(requestParameters.projectId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deploy a CSV
         * @param {CSVDeploymentsApiGetCsvCsvDeploymentsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCsvCsvDeploymentsPost(requestParameters: CSVDeploymentsApiGetCsvCsvDeploymentsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getCsvCsvDeploymentsPost(requestParameters.deployCsvRequest, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCsvCsvDeploymentsGet operation in CSVDeploymentsApi.
 * @export
 * @interface CSVDeploymentsApiGetCsvCsvDeploymentsGetRequest
 */
export interface CSVDeploymentsApiGetCsvCsvDeploymentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CSVDeploymentsApiGetCsvCsvDeploymentsGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof CSVDeploymentsApiGetCsvCsvDeploymentsGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeploymentsApiGetCsvCsvDeploymentsGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeploymentsApiGetCsvCsvDeploymentsGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeploymentsApiGetCsvCsvDeploymentsGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getCsvCsvDeploymentsPost operation in CSVDeploymentsApi.
 * @export
 * @interface CSVDeploymentsApiGetCsvCsvDeploymentsPostRequest
 */
export interface CSVDeploymentsApiGetCsvCsvDeploymentsPostRequest {
    /**
     * 
     * @type {DeployCsvRequest}
     * @memberof CSVDeploymentsApiGetCsvCsvDeploymentsPost
     */
    readonly deployCsvRequest: DeployCsvRequest

    /**
     * 
     * @type {string}
     * @memberof CSVDeploymentsApiGetCsvCsvDeploymentsPost
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeploymentsApiGetCsvCsvDeploymentsPost
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeploymentsApiGetCsvCsvDeploymentsPost
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CSVDeploymentsApiGetCsvCsvDeploymentsPost
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * CSVDeploymentsApi - object-oriented interface
 * @export
 * @class CSVDeploymentsApi
 * @extends {BaseAPI}
 */
export class CSVDeploymentsApi extends BaseAPI {
    /**
     * 
     * @summary Get available CSVs
     * @param {CSVDeploymentsApiGetCsvCsvDeploymentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CSVDeploymentsApi
     */
    public getCsvCsvDeploymentsGet(requestParameters: CSVDeploymentsApiGetCsvCsvDeploymentsGetRequest, options?: RawAxiosRequestConfig) {
        return CSVDeploymentsApiFp(this.configuration).getCsvCsvDeploymentsGet(requestParameters.projectId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deploy a CSV
     * @param {CSVDeploymentsApiGetCsvCsvDeploymentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CSVDeploymentsApi
     */
    public getCsvCsvDeploymentsPost(requestParameters: CSVDeploymentsApiGetCsvCsvDeploymentsPostRequest, options?: RawAxiosRequestConfig) {
        return CSVDeploymentsApiFp(this.configuration).getCsvCsvDeploymentsPost(requestParameters.deployCsvRequest, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Proxy To Config Api
         * @param {string} configPath 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyToConfigApiConfigConfigPathDelete: async (configPath: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configPath' is not null or undefined
            assertParamExists('proxyToConfigApiConfigConfigPathDelete', 'configPath', configPath)
            const localVarPath = `/config{config_path}`
                .replace(`{${"config_path"}}`, encodeURIComponent(String(configPath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Proxy To Config Api
         * @param {string} configPath 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyToConfigApiConfigConfigPathGet: async (configPath: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configPath' is not null or undefined
            assertParamExists('proxyToConfigApiConfigConfigPathGet', 'configPath', configPath)
            const localVarPath = `/config{config_path}`
                .replace(`{${"config_path"}}`, encodeURIComponent(String(configPath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Proxy To Config Api
         * @param {string} configPath 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyToConfigApiConfigConfigPathPatch: async (configPath: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configPath' is not null or undefined
            assertParamExists('proxyToConfigApiConfigConfigPathPatch', 'configPath', configPath)
            const localVarPath = `/config{config_path}`
                .replace(`{${"config_path"}}`, encodeURIComponent(String(configPath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Proxy To Config Api
         * @param {string} configPath 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyToConfigApiConfigConfigPathPost: async (configPath: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configPath' is not null or undefined
            assertParamExists('proxyToConfigApiConfigConfigPathPost', 'configPath', configPath)
            const localVarPath = `/config{config_path}`
                .replace(`{${"config_path"}}`, encodeURIComponent(String(configPath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Proxy To Config Api
         * @param {string} configPath 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyToConfigApiConfigConfigPathPut: async (configPath: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configPath' is not null or undefined
            assertParamExists('proxyToConfigApiConfigConfigPathPut', 'configPath', configPath)
            const localVarPath = `/config{config_path}`
                .replace(`{${"config_path"}}`, encodeURIComponent(String(configPath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Proxy To Config Api
         * @param {string} configPath 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxyToConfigApiConfigConfigPathDelete(configPath: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxyToConfigApiConfigConfigPathDelete(configPath, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigApi.proxyToConfigApiConfigConfigPathDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Proxy To Config Api
         * @param {string} configPath 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxyToConfigApiConfigConfigPathGet(configPath: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxyToConfigApiConfigConfigPathGet(configPath, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigApi.proxyToConfigApiConfigConfigPathGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Proxy To Config Api
         * @param {string} configPath 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxyToConfigApiConfigConfigPathPatch(configPath: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxyToConfigApiConfigConfigPathPatch(configPath, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigApi.proxyToConfigApiConfigConfigPathPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Proxy To Config Api
         * @param {string} configPath 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxyToConfigApiConfigConfigPathPost(configPath: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxyToConfigApiConfigConfigPathPost(configPath, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigApi.proxyToConfigApiConfigConfigPathPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Proxy To Config Api
         * @param {string} configPath 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxyToConfigApiConfigConfigPathPut(configPath: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxyToConfigApiConfigConfigPathPut(configPath, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigApi.proxyToConfigApiConfigConfigPathPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigApiFp(configuration)
    return {
        /**
         * 
         * @summary Proxy To Config Api
         * @param {ConfigApiProxyToConfigApiConfigConfigPathDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyToConfigApiConfigConfigPathDelete(requestParameters: ConfigApiProxyToConfigApiConfigConfigPathDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.proxyToConfigApiConfigConfigPathDelete(requestParameters.configPath, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Proxy To Config Api
         * @param {ConfigApiProxyToConfigApiConfigConfigPathGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyToConfigApiConfigConfigPathGet(requestParameters: ConfigApiProxyToConfigApiConfigConfigPathGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.proxyToConfigApiConfigConfigPathGet(requestParameters.configPath, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Proxy To Config Api
         * @param {ConfigApiProxyToConfigApiConfigConfigPathPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyToConfigApiConfigConfigPathPatch(requestParameters: ConfigApiProxyToConfigApiConfigConfigPathPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.proxyToConfigApiConfigConfigPathPatch(requestParameters.configPath, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Proxy To Config Api
         * @param {ConfigApiProxyToConfigApiConfigConfigPathPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyToConfigApiConfigConfigPathPost(requestParameters: ConfigApiProxyToConfigApiConfigConfigPathPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.proxyToConfigApiConfigConfigPathPost(requestParameters.configPath, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Proxy To Config Api
         * @param {ConfigApiProxyToConfigApiConfigConfigPathPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyToConfigApiConfigConfigPathPut(requestParameters: ConfigApiProxyToConfigApiConfigConfigPathPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.proxyToConfigApiConfigConfigPathPut(requestParameters.configPath, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for proxyToConfigApiConfigConfigPathDelete operation in ConfigApi.
 * @export
 * @interface ConfigApiProxyToConfigApiConfigConfigPathDeleteRequest
 */
export interface ConfigApiProxyToConfigApiConfigConfigPathDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathDelete
     */
    readonly configPath: string

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathDelete
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathDelete
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathDelete
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathDelete
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for proxyToConfigApiConfigConfigPathGet operation in ConfigApi.
 * @export
 * @interface ConfigApiProxyToConfigApiConfigConfigPathGetRequest
 */
export interface ConfigApiProxyToConfigApiConfigConfigPathGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathGet
     */
    readonly configPath: string

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for proxyToConfigApiConfigConfigPathPatch operation in ConfigApi.
 * @export
 * @interface ConfigApiProxyToConfigApiConfigConfigPathPatchRequest
 */
export interface ConfigApiProxyToConfigApiConfigConfigPathPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathPatch
     */
    readonly configPath: string

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathPatch
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathPatch
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathPatch
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathPatch
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for proxyToConfigApiConfigConfigPathPost operation in ConfigApi.
 * @export
 * @interface ConfigApiProxyToConfigApiConfigConfigPathPostRequest
 */
export interface ConfigApiProxyToConfigApiConfigConfigPathPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathPost
     */
    readonly configPath: string

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathPost
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathPost
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathPost
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathPost
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for proxyToConfigApiConfigConfigPathPut operation in ConfigApi.
 * @export
 * @interface ConfigApiProxyToConfigApiConfigConfigPathPutRequest
 */
export interface ConfigApiProxyToConfigApiConfigConfigPathPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathPut
     */
    readonly configPath: string

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathPut
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathPut
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathPut
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof ConfigApiProxyToConfigApiConfigConfigPathPut
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI {
    /**
     * 
     * @summary Proxy To Config Api
     * @param {ConfigApiProxyToConfigApiConfigConfigPathDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public proxyToConfigApiConfigConfigPathDelete(requestParameters: ConfigApiProxyToConfigApiConfigConfigPathDeleteRequest, options?: RawAxiosRequestConfig) {
        return ConfigApiFp(this.configuration).proxyToConfigApiConfigConfigPathDelete(requestParameters.configPath, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Proxy To Config Api
     * @param {ConfigApiProxyToConfigApiConfigConfigPathGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public proxyToConfigApiConfigConfigPathGet(requestParameters: ConfigApiProxyToConfigApiConfigConfigPathGetRequest, options?: RawAxiosRequestConfig) {
        return ConfigApiFp(this.configuration).proxyToConfigApiConfigConfigPathGet(requestParameters.configPath, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Proxy To Config Api
     * @param {ConfigApiProxyToConfigApiConfigConfigPathPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public proxyToConfigApiConfigConfigPathPatch(requestParameters: ConfigApiProxyToConfigApiConfigConfigPathPatchRequest, options?: RawAxiosRequestConfig) {
        return ConfigApiFp(this.configuration).proxyToConfigApiConfigConfigPathPatch(requestParameters.configPath, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Proxy To Config Api
     * @param {ConfigApiProxyToConfigApiConfigConfigPathPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public proxyToConfigApiConfigConfigPathPost(requestParameters: ConfigApiProxyToConfigApiConfigConfigPathPostRequest, options?: RawAxiosRequestConfig) {
        return ConfigApiFp(this.configuration).proxyToConfigApiConfigConfigPathPost(requestParameters.configPath, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Proxy To Config Api
     * @param {ConfigApiProxyToConfigApiConfigConfigPathPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public proxyToConfigApiConfigConfigPathPut(requestParameters: ConfigApiProxyToConfigApiConfigConfigPathPutRequest, options?: RawAxiosRequestConfig) {
        return ConfigApiFp(this.configuration).proxyToConfigApiConfigConfigPathPut(requestParameters.configPath, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CoreApi - axios parameter creator
 * @export
 */
export const CoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get activity insights
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {number} entityId 
         * @param {string} productLineId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet: async (projectId: string, regionId: string, entityId: number, productLineId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet', 'regionId', regionId)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet', 'entityId', entityId)
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet', 'productLineId', productLineId)
            const localVarPath = `/core/projects/{project_id}/activity-insights/regions/{region_id}/entities/{entity_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"region_id"}}`, encodeURIComponent(String(regionId)))
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productLineId !== undefined) {
                localVarQueryParameter['product_line_id'] = productLineId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get activity insights for region
         * @param {string} projectId 
         * @param {number} regionId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGet: async (projectId: string, regionId: number, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGet', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGet', 'regionId', regionId)
            const localVarPath = `/core/projects/{project_id}/activity-insights/regions/{region_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"region_id"}}`, encodeURIComponent(String(regionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated child entities for the given region
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {Array<any>} [targetProducts] 
         * @param {string} [timestamp] 
         * @param {boolean} [score] 
         * @param {boolean} [product] 
         * @param {boolean} [called] 
         * @param {string} [curatedType] 
         * @param {string} [cadence] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet: async (productLineId: string, projectId: string, regionId: string, skip?: number, limit?: number, targetProducts?: Array<any>, timestamp?: string, score?: boolean, product?: boolean, called?: boolean, curatedType?: string, cadence?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet', 'productLineId', productLineId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet', 'regionId', regionId)
            const localVarPath = `/core/product-lines/{product_line_id}/projects/{project_id}/regions/{region_id}/entities`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"region_id"}}`, encodeURIComponent(String(regionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (targetProducts) {
                localVarQueryParameter['target_products'] = targetProducts;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }

            if (score !== undefined) {
                localVarQueryParameter['score'] = score;
            }

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }

            if (called !== undefined) {
                localVarQueryParameter['called'] = called;
            }

            if (curatedType !== undefined) {
                localVarQueryParameter['curatedType'] = curatedType;
            }

            if (cadence !== undefined) {
                localVarQueryParameter['cadence'] = cadence;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity coordinates
         * @param {string} productLineId 
         * @param {string} regionId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGet: async (productLineId: string, regionId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGet', 'productLineId', productLineId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGet', 'regionId', regionId)
            const localVarPath = `/core/product-lines/{product_line_id}/regions/{region_id}/entities/coordinates`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)))
                .replace(`{${"region_id"}}`, encodeURIComponent(String(regionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity rule based insights
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} regionId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet: async (productLineId: string, projectId: string, objectiveId: string, regionId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet', 'productLineId', productLineId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet', 'projectId', projectId)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet', 'objectiveId', objectiveId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet', 'regionId', regionId)
            const localVarPath = `/core/product-lines/{product_line_id}/projects/{project_id}/regions/{region_id}/objectives/{objective_id}/entities/insights`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"objective_id"}}`, encodeURIComponent(String(objectiveId)))
                .replace(`{${"region_id"}}`, encodeURIComponent(String(regionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get geocodes for a list of addresses
         * @param {GeoCodePostModel} geoCodePostModel 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeocodesForUserSuppliedListCoreGeocodesPost: async (geoCodePostModel: GeoCodePostModel, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'geoCodePostModel' is not null or undefined
            assertParamExists('getGeocodesForUserSuppliedListCoreGeocodesPost', 'geoCodePostModel', geoCodePostModel)
            const localVarPath = `/core/geocodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(geoCodePostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all products
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsCoreProductsGet: async (referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a project by id
         * @param {string} projectId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById: async (projectId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectById', 'projectId', projectId)
            const localVarPath = `/core/project/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of projects for user
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectListCoreProjectListGet: async (referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/project-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get baskets per objectives for project
         * @param {string} projectId 
         * @param {EntityType} entityType 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectObjectiveBaskets: async (projectId: string, entityType: EntityType, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectObjectiveBaskets', 'projectId', projectId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getProjectObjectiveBaskets', 'entityType', entityType)
            const localVarPath = `/core/projects/{project_id}/objectives/baskets`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get objectives for project
         * @param {string} projectId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectObjectivesCoreProjectsProjectIdObjectivesGet: async (projectId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectObjectivesCoreProjectsProjectIdObjectivesGet', 'projectId', projectId)
            const localVarPath = `/core/projects/{project_id}/objectives`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get geocodes for region
         * @param {number} regionId 
         * @param {string} productLineId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGet: async (regionId: number, productLineId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGet', 'regionId', regionId)
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGet', 'productLineId', productLineId)
            const localVarPath = `/core/product-lines/{product_line_id}/regions/{region_id}/geocodes`
                .replace(`{${"region_id"}}`, encodeURIComponent(String(regionId)))
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get region metadata
         * @param {string} productLineId 
         * @param {string} regionId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGet: async (productLineId: string, regionId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGet', 'productLineId', productLineId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGet', 'regionId', regionId)
            const localVarPath = `/core/product-lines/{product_line_id}/regions/{region_id}/metadata`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)))
                .replace(`{${"region_id"}}`, encodeURIComponent(String(regionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoreApi - functional programming interface
 * @export
 */
export const CoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoreApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get activity insights
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {number} entityId 
         * @param {string} productLineId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet(projectId: string, regionId: string, entityId: number, productLineId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet(projectId, regionId, entityId, productLineId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get activity insights for region
         * @param {string} projectId 
         * @param {number} regionId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGet(projectId: string, regionId: number, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegionalActivityInsightsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGet(projectId, regionId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get paginated child entities for the given region
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {Array<any>} [targetProducts] 
         * @param {string} [timestamp] 
         * @param {boolean} [score] 
         * @param {boolean} [product] 
         * @param {boolean} [called] 
         * @param {string} [curatedType] 
         * @param {string} [cadence] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet(productLineId: string, projectId: string, regionId: string, skip?: number, limit?: number, targetProducts?: Array<any>, timestamp?: string, score?: boolean, product?: boolean, called?: boolean, curatedType?: string, cadence?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet(productLineId, projectId, regionId, skip, limit, targetProducts, timestamp, score, product, called, curatedType, cadence, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.getEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity coordinates
         * @param {string} productLineId 
         * @param {string} regionId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGet(productLineId: string, regionId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGet(productLineId, regionId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.getEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity rule based insights
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} regionId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet(productLineId: string, projectId: string, objectiveId: string, regionId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet(productLineId, projectId, objectiveId, regionId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.getEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get geocodes for a list of addresses
         * @param {GeoCodePostModel} geoCodePostModel 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeocodesForUserSuppliedListCoreGeocodesPost(geoCodePostModel: GeoCodePostModel, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeoCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeocodesForUserSuppliedListCoreGeocodesPost(geoCodePostModel, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.getGeocodesForUserSuppliedListCoreGeocodesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all products
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsCoreProductsGet(referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string | null>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsCoreProductsGet(referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.getProductsCoreProductsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a project by id
         * @param {string} projectId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectById(projectId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectById(projectId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.getProjectById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get list of projects for user
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectListCoreProjectListGet(referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectListCoreProjectListGet(referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.getProjectListCoreProjectListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get baskets per objectives for project
         * @param {string} projectId 
         * @param {EntityType} entityType 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectObjectiveBaskets(projectId: string, entityType: EntityType, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: Basket; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectObjectiveBaskets(projectId, entityType, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.getProjectObjectiveBaskets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get objectives for project
         * @param {string} projectId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectObjectivesCoreProjectsProjectIdObjectivesGet(projectId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectObjectivesCoreProjectsProjectIdObjectivesGet(projectId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.getProjectObjectivesCoreProjectsProjectIdObjectivesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get geocodes for region
         * @param {number} regionId 
         * @param {string} productLineId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGet(regionId: number, productLineId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityGeoCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGet(regionId, productLineId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.getRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get region metadata
         * @param {string} productLineId 
         * @param {string} regionId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGet(productLineId: string, regionId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGet(productLineId, regionId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.getRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CoreApi - factory interface
 * @export
 */
export const CoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoreApiFp(configuration)
    return {
        /**
         * 
         * @summary Get activity insights
         * @param {CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet(requestParameters: CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet(requestParameters.projectId, requestParameters.regionId, requestParameters.entityId, requestParameters.productLineId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get activity insights for region
         * @param {CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGet(requestParameters: CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RegionalActivityInsightsResponse> {
            return localVarFp.getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGet(requestParameters.projectId, requestParameters.regionId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated child entities for the given region
         * @param {CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet(requestParameters: CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.regionId, requestParameters.skip, requestParameters.limit, requestParameters.targetProducts, requestParameters.timestamp, requestParameters.score, requestParameters.product, requestParameters.called, requestParameters.curatedType, requestParameters.cadence, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity coordinates
         * @param {CoreApiGetEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGet(requestParameters: CoreApiGetEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGet(requestParameters.productLineId, requestParameters.regionId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity rule based insights
         * @param {CoreApiGetEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet(requestParameters: CoreApiGetEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.objectiveId, requestParameters.regionId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get geocodes for a list of addresses
         * @param {CoreApiGetGeocodesForUserSuppliedListCoreGeocodesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeocodesForUserSuppliedListCoreGeocodesPost(requestParameters: CoreApiGetGeocodesForUserSuppliedListCoreGeocodesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<GeoCode>> {
            return localVarFp.getGeocodesForUserSuppliedListCoreGeocodesPost(requestParameters.geoCodePostModel, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all products
         * @param {CoreApiGetProductsCoreProductsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsCoreProductsGet(requestParameters: CoreApiGetProductsCoreProductsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<string | null>> {
            return localVarFp.getProductsCoreProductsGet(requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a project by id
         * @param {CoreApiGetProjectByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(requestParameters: CoreApiGetProjectByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetProjectResponse> {
            return localVarFp.getProjectById(requestParameters.projectId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of projects for user
         * @param {CoreApiGetProjectListCoreProjectListGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectListCoreProjectListGet(requestParameters: CoreApiGetProjectListCoreProjectListGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getProjectListCoreProjectListGet(requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get baskets per objectives for project
         * @param {CoreApiGetProjectObjectiveBasketsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectObjectiveBaskets(requestParameters: CoreApiGetProjectObjectiveBasketsRequest, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: { [key: string]: Basket; }; }> {
            return localVarFp.getProjectObjectiveBaskets(requestParameters.projectId, requestParameters.entityType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get objectives for project
         * @param {CoreApiGetProjectObjectivesCoreProjectsProjectIdObjectivesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectObjectivesCoreProjectsProjectIdObjectivesGet(requestParameters: CoreApiGetProjectObjectivesCoreProjectsProjectIdObjectivesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getProjectObjectivesCoreProjectsProjectIdObjectivesGet(requestParameters.projectId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get geocodes for region
         * @param {CoreApiGetRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGet(requestParameters: CoreApiGetRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityGeoCode>> {
            return localVarFp.getRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGet(requestParameters.regionId, requestParameters.productLineId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get region metadata
         * @param {CoreApiGetRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGet(requestParameters: CoreApiGetRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGet(requestParameters.productLineId, requestParameters.regionId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet operation in CoreApi.
 * @export
 * @interface CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGetRequest
 */
export interface CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet
     */
    readonly regionId: string

    /**
     * 
     * @type {number}
     * @memberof CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet
     */
    readonly entityId: number

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGet operation in CoreApi.
 * @export
 * @interface CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGetRequest
 */
export interface CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGet
     */
    readonly projectId: string

    /**
     * 
     * @type {number}
     * @memberof CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGet
     */
    readonly regionId: number

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet operation in CoreApi.
 * @export
 * @interface CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGetRequest
 */
export interface CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet
     */
    readonly regionId: string

    /**
     * 
     * @type {number}
     * @memberof CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<any>}
     * @memberof CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet
     */
    readonly targetProducts?: Array<any>

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet
     */
    readonly timestamp?: string

    /**
     * 
     * @type {boolean}
     * @memberof CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet
     */
    readonly score?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet
     */
    readonly product?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet
     */
    readonly called?: boolean

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet
     */
    readonly curatedType?: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet
     */
    readonly cadence?: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGet operation in CoreApi.
 * @export
 * @interface CoreApiGetEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGetRequest
 */
export interface CoreApiGetEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGet
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet operation in CoreApi.
 * @export
 * @interface CoreApiGetEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGetRequest
 */
export interface CoreApiGetEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getGeocodesForUserSuppliedListCoreGeocodesPost operation in CoreApi.
 * @export
 * @interface CoreApiGetGeocodesForUserSuppliedListCoreGeocodesPostRequest
 */
export interface CoreApiGetGeocodesForUserSuppliedListCoreGeocodesPostRequest {
    /**
     * 
     * @type {GeoCodePostModel}
     * @memberof CoreApiGetGeocodesForUserSuppliedListCoreGeocodesPost
     */
    readonly geoCodePostModel: GeoCodePostModel

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetGeocodesForUserSuppliedListCoreGeocodesPost
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetGeocodesForUserSuppliedListCoreGeocodesPost
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetGeocodesForUserSuppliedListCoreGeocodesPost
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetGeocodesForUserSuppliedListCoreGeocodesPost
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getProductsCoreProductsGet operation in CoreApi.
 * @export
 * @interface CoreApiGetProductsCoreProductsGetRequest
 */
export interface CoreApiGetProductsCoreProductsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProductsCoreProductsGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProductsCoreProductsGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProductsCoreProductsGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProductsCoreProductsGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getProjectById operation in CoreApi.
 * @export
 * @interface CoreApiGetProjectByIdRequest
 */
export interface CoreApiGetProjectByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectById
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectById
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectById
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectById
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectById
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getProjectListCoreProjectListGet operation in CoreApi.
 * @export
 * @interface CoreApiGetProjectListCoreProjectListGetRequest
 */
export interface CoreApiGetProjectListCoreProjectListGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectListCoreProjectListGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectListCoreProjectListGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectListCoreProjectListGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectListCoreProjectListGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getProjectObjectiveBaskets operation in CoreApi.
 * @export
 * @interface CoreApiGetProjectObjectiveBasketsRequest
 */
export interface CoreApiGetProjectObjectiveBasketsRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectObjectiveBaskets
     */
    readonly projectId: string

    /**
     * 
     * @type {EntityType}
     * @memberof CoreApiGetProjectObjectiveBaskets
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectObjectiveBaskets
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectObjectiveBaskets
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectObjectiveBaskets
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectObjectiveBaskets
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getProjectObjectivesCoreProjectsProjectIdObjectivesGet operation in CoreApi.
 * @export
 * @interface CoreApiGetProjectObjectivesCoreProjectsProjectIdObjectivesGetRequest
 */
export interface CoreApiGetProjectObjectivesCoreProjectsProjectIdObjectivesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectObjectivesCoreProjectsProjectIdObjectivesGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectObjectivesCoreProjectsProjectIdObjectivesGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectObjectivesCoreProjectsProjectIdObjectivesGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectObjectivesCoreProjectsProjectIdObjectivesGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetProjectObjectivesCoreProjectsProjectIdObjectivesGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGet operation in CoreApi.
 * @export
 * @interface CoreApiGetRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGetRequest
 */
export interface CoreApiGetRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CoreApiGetRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGet
     */
    readonly regionId: number

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGet operation in CoreApi.
 * @export
 * @interface CoreApiGetRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGetRequest
 */
export interface CoreApiGetRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CoreApiGetRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGet
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof CoreApiGetRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * CoreApi - object-oriented interface
 * @export
 * @class CoreApi
 * @extends {BaseAPI}
 */
export class CoreApi extends BaseAPI {
    /**
     * 
     * @summary Get activity insights
     * @param {CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet(requestParameters: CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGetRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdEntitiesEntityIdGet(requestParameters.projectId, requestParameters.regionId, requestParameters.entityId, requestParameters.productLineId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get activity insights for region
     * @param {CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGet(requestParameters: CoreApiGetActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGetRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).getActivityInsightsCoreProjectsProjectIdActivityInsightsRegionsRegionIdGet(requestParameters.projectId, requestParameters.regionId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated child entities for the given region
     * @param {CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public getEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet(requestParameters: CoreApiGetEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGetRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).getEntitiesCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.regionId, requestParameters.skip, requestParameters.limit, requestParameters.targetProducts, requestParameters.timestamp, requestParameters.score, requestParameters.product, requestParameters.called, requestParameters.curatedType, requestParameters.cadence, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity coordinates
     * @param {CoreApiGetEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public getEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGet(requestParameters: CoreApiGetEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGetRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).getEntityCoordinatesCoreProductLinesProductLineIdRegionsRegionIdEntitiesCoordinatesGet(requestParameters.productLineId, requestParameters.regionId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity rule based insights
     * @param {CoreApiGetEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public getEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet(requestParameters: CoreApiGetEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGetRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).getEntityInsightsCoreProductLinesProductLineIdProjectsProjectIdRegionsRegionIdObjectivesObjectiveIdEntitiesInsightsGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.objectiveId, requestParameters.regionId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get geocodes for a list of addresses
     * @param {CoreApiGetGeocodesForUserSuppliedListCoreGeocodesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public getGeocodesForUserSuppliedListCoreGeocodesPost(requestParameters: CoreApiGetGeocodesForUserSuppliedListCoreGeocodesPostRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).getGeocodesForUserSuppliedListCoreGeocodesPost(requestParameters.geoCodePostModel, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all products
     * @param {CoreApiGetProductsCoreProductsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public getProductsCoreProductsGet(requestParameters: CoreApiGetProductsCoreProductsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).getProductsCoreProductsGet(requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a project by id
     * @param {CoreApiGetProjectByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public getProjectById(requestParameters: CoreApiGetProjectByIdRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).getProjectById(requestParameters.projectId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of projects for user
     * @param {CoreApiGetProjectListCoreProjectListGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public getProjectListCoreProjectListGet(requestParameters: CoreApiGetProjectListCoreProjectListGetRequest = {}, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).getProjectListCoreProjectListGet(requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get baskets per objectives for project
     * @param {CoreApiGetProjectObjectiveBasketsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public getProjectObjectiveBaskets(requestParameters: CoreApiGetProjectObjectiveBasketsRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).getProjectObjectiveBaskets(requestParameters.projectId, requestParameters.entityType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get objectives for project
     * @param {CoreApiGetProjectObjectivesCoreProjectsProjectIdObjectivesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public getProjectObjectivesCoreProjectsProjectIdObjectivesGet(requestParameters: CoreApiGetProjectObjectivesCoreProjectsProjectIdObjectivesGetRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).getProjectObjectivesCoreProjectsProjectIdObjectivesGet(requestParameters.projectId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get geocodes for region
     * @param {CoreApiGetRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public getRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGet(requestParameters: CoreApiGetRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGetRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).getRegionGeocodesCoreProductLinesProductLineIdRegionsRegionIdGeocodesGet(requestParameters.regionId, requestParameters.productLineId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get region metadata
     * @param {CoreApiGetRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public getRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGet(requestParameters: CoreApiGetRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGetRequest, options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).getRegionMetadataCoreProductLinesProductLineIdRegionsRegionIdMetadataGet(requestParameters.productLineId, requestParameters.regionId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Temporary Server health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readHealthGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Server health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPingPingGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Temporary Server health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readHealthGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readHealthGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.readHealthGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Server health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPingPingGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPingPingGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.readPingPingGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Temporary Server health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readHealthGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.readHealthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Server health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPingPingGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.readPingPingGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Temporary Server health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readHealthGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).readHealthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Server health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readPingPingGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).readPingPingGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FieldApi - axios parameter creator
 * @export
 */
export const FieldApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns an object of categorical data for each objective, displayed on the HCP entity page
         * @param {string} projectId 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoricalData: async (projectId: string, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getCategoricalData', 'projectId', projectId)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getCategoricalData', 'entityId', entityId)
            const localVarPath = `/field/entity/categorical`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contribution breakdown
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} regionId 
         * @param {string} entityId 
         * @param {EntityType} entityType 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContributionBreakdown: async (projectId: string, objectiveId: string, regionId: string, entityId: string, entityType: EntityType, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getContributionBreakdown', 'projectId', projectId)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getContributionBreakdown', 'objectiveId', objectiveId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getContributionBreakdown', 'regionId', regionId)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getContributionBreakdown', 'entityId', entityId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getContributionBreakdown', 'entityType', entityType)
            const localVarPath = `/field/entity/powerscore-explainability/contribution-breakdown`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of curation entities
         * @param {string} regionId 
         * @param {string} objectiveId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedEnities: async (regionId: string, objectiveId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getCuratedEnities', 'regionId', regionId)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getCuratedEnities', 'objectiveId', objectiveId)
            const localVarPath = `/field/curation/entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns array of customized messages for a given objective and entity combination
         * @param {string} productLineId 
         * @param {string} entityId 
         * @param {string} objectiveId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomizedMessagesFieldEntityMessagesGet: async (productLineId: string, entityId: string, objectiveId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getCustomizedMessagesFieldEntityMessagesGet', 'productLineId', productLineId)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getCustomizedMessagesFieldEntityMessagesGet', 'entityId', entityId)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getCustomizedMessagesFieldEntityMessagesGet', 'objectiveId', objectiveId)
            const localVarPath = `/field/entity/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productLineId !== undefined) {
                localVarQueryParameter['product_line_id'] = productLineId;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of precall insights for multiple entities
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} regionId 
         * @param {EntityType} entityType 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntitiesPrecallInsights: async (projectId: string, objectiveId: string, regionId: string, entityType: EntityType, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEntitiesPrecallInsights', 'projectId', projectId)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getEntitiesPrecallInsights', 'objectiveId', objectiveId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getEntitiesPrecallInsights', 'regionId', regionId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getEntitiesPrecallInsights', 'entityType', entityType)
            const localVarPath = `/field/precall/entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity data
         * @param {string} productLineId 
         * @param {string} entityId 
         * @param {EntityType} [entityType] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityData: async (productLineId: string, entityId: string, entityType?: EntityType, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getEntityData', 'productLineId', productLineId)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getEntityData', 'entityId', entityId)
            const localVarPath = `/field/entity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productLineId !== undefined) {
                localVarQueryParameter['product_line_id'] = productLineId;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity journey
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityJourney: async (projectId: string, regionId: string, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEntityJourney', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getEntityJourney', 'regionId', regionId)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getEntityJourney', 'entityId', entityId)
            const localVarPath = `/field/entity/journey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity overview CSV
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {EntityType} entityType 
         * @param {string} objectiveId 
         * @param {ApplicationEntityOverviewSrcEnumsCadenceCadence} cadence 
         * @param {Array<any>} dates 
         * @param {Array<any>} columns 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOverviewCsvFieldEntityOverviewCsvGet: async (productLineId: string, projectId: string, regionId: string, entityType: EntityType, objectiveId: string, cadence: ApplicationEntityOverviewSrcEnumsCadenceCadence, dates: Array<any>, columns: Array<any>, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getEntityOverviewCsvFieldEntityOverviewCsvGet', 'productLineId', productLineId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEntityOverviewCsvFieldEntityOverviewCsvGet', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getEntityOverviewCsvFieldEntityOverviewCsvGet', 'regionId', regionId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getEntityOverviewCsvFieldEntityOverviewCsvGet', 'entityType', entityType)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getEntityOverviewCsvFieldEntityOverviewCsvGet', 'objectiveId', objectiveId)
            // verify required parameter 'cadence' is not null or undefined
            assertParamExists('getEntityOverviewCsvFieldEntityOverviewCsvGet', 'cadence', cadence)
            // verify required parameter 'dates' is not null or undefined
            assertParamExists('getEntityOverviewCsvFieldEntityOverviewCsvGet', 'dates', dates)
            // verify required parameter 'columns' is not null or undefined
            assertParamExists('getEntityOverviewCsvFieldEntityOverviewCsvGet', 'columns', columns)
            const localVarPath = `/field/entity-overview/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productLineId !== undefined) {
                localVarQueryParameter['product_line_id'] = productLineId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (cadence !== undefined) {
                localVarQueryParameter['cadence'] = cadence;
            }

            if (dates) {
                localVarQueryParameter['dates'] = dates;
            }

            if (columns) {
                localVarQueryParameter['columns'] = columns;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity overview CSV metadata
         * @param {string} projectId 
         * @param {EntityType} entityType 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGet: async (projectId: string, entityType: EntityType, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGet', 'projectId', projectId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGet', 'entityType', entityType)
            const localVarPath = `/field/entity-overview/csv-metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity overview drawer chart data
         * @param {string} productLineId 
         * @param {string} regionId 
         * @param {EntityType} entityType 
         * @param {string} objectiveId 
         * @param {string} entityId 
         * @param {ChartType} chartType 
         * @param {string} metric 
         * @param {ApplicationEntityOverviewSrcEnumsCadenceCadence} cadence 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOverviewDrawerChart: async (productLineId: string, regionId: string, entityType: EntityType, objectiveId: string, entityId: string, chartType: ChartType, metric: string, cadence: ApplicationEntityOverviewSrcEnumsCadenceCadence, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getEntityOverviewDrawerChart', 'productLineId', productLineId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getEntityOverviewDrawerChart', 'regionId', regionId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getEntityOverviewDrawerChart', 'entityType', entityType)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getEntityOverviewDrawerChart', 'objectiveId', objectiveId)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getEntityOverviewDrawerChart', 'entityId', entityId)
            // verify required parameter 'chartType' is not null or undefined
            assertParamExists('getEntityOverviewDrawerChart', 'chartType', chartType)
            // verify required parameter 'metric' is not null or undefined
            assertParamExists('getEntityOverviewDrawerChart', 'metric', metric)
            // verify required parameter 'cadence' is not null or undefined
            assertParamExists('getEntityOverviewDrawerChart', 'cadence', cadence)
            const localVarPath = `/field/entity-overview/drawer-chart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productLineId !== undefined) {
                localVarQueryParameter['product_line_id'] = productLineId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (chartType !== undefined) {
                localVarQueryParameter['chart_type'] = chartType;
            }

            if (metric !== undefined) {
                localVarQueryParameter['metric'] = metric;
            }

            if (cadence !== undefined) {
                localVarQueryParameter['cadence'] = cadence;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity overview drawer metadata
         * @param {string} productLineId 
         * @param {string} marketId 
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {EntityType} entityType 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOverviewDrawerMetadata: async (productLineId: string, marketId: string, projectId: string, regionId: string, entityType: EntityType, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getEntityOverviewDrawerMetadata', 'productLineId', productLineId)
            // verify required parameter 'marketId' is not null or undefined
            assertParamExists('getEntityOverviewDrawerMetadata', 'marketId', marketId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEntityOverviewDrawerMetadata', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getEntityOverviewDrawerMetadata', 'regionId', regionId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getEntityOverviewDrawerMetadata', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getEntityOverviewDrawerMetadata', 'entityId', entityId)
            const localVarPath = `/field/entity-overview/drawer-metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productLineId !== undefined) {
                localVarQueryParameter['product_line_id'] = productLineId;
            }

            if (marketId !== undefined) {
                localVarQueryParameter['market_id'] = marketId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity overview drawer profile data
         * @param {string} marketId 
         * @param {EntityType} entityType 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOverviewDrawerProfile: async (marketId: string, entityType: EntityType, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketId' is not null or undefined
            assertParamExists('getEntityOverviewDrawerProfile', 'marketId', marketId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getEntityOverviewDrawerProfile', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getEntityOverviewDrawerProfile', 'entityId', entityId)
            const localVarPath = `/field/entity-overview/drawer-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (marketId !== undefined) {
                localVarQueryParameter['market_id'] = marketId;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity overview drawer timeline data
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {EntityType} entityType 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOverviewDrawerTimeline: async (projectId: string, regionId: string, entityType: EntityType, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEntityOverviewDrawerTimeline', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getEntityOverviewDrawerTimeline', 'regionId', regionId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getEntityOverviewDrawerTimeline', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getEntityOverviewDrawerTimeline', 'entityId', entityId)
            const localVarPath = `/field/entity-overview/drawer-timeline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity overview table data
         * @param {string} productLineId 
         * @param {string} regionId 
         * @param {EntityType} entityType 
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {ApplicationEntityOverviewSrcEnumsCadenceCadence} cadence 
         * @param {ComparisonTimeFrameId} comparisonTimeFrameId 
         * @param {string} [entitySubtype] 
         * @param {string} [filters] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOverviewTableData: async (productLineId: string, regionId: string, entityType: EntityType, projectId: string, objectiveId: string, cadence: ApplicationEntityOverviewSrcEnumsCadenceCadence, comparisonTimeFrameId: ComparisonTimeFrameId, entitySubtype?: string, filters?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getEntityOverviewTableData', 'productLineId', productLineId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getEntityOverviewTableData', 'regionId', regionId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getEntityOverviewTableData', 'entityType', entityType)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEntityOverviewTableData', 'projectId', projectId)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getEntityOverviewTableData', 'objectiveId', objectiveId)
            // verify required parameter 'cadence' is not null or undefined
            assertParamExists('getEntityOverviewTableData', 'cadence', cadence)
            // verify required parameter 'comparisonTimeFrameId' is not null or undefined
            assertParamExists('getEntityOverviewTableData', 'comparisonTimeFrameId', comparisonTimeFrameId)
            const localVarPath = `/field/entity-overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productLineId !== undefined) {
                localVarQueryParameter['product_line_id'] = productLineId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (cadence !== undefined) {
                localVarQueryParameter['cadence'] = cadence;
            }

            if (comparisonTimeFrameId !== undefined) {
                localVarQueryParameter['comparison_time_frame_id'] = comparisonTimeFrameId;
            }

            if (entitySubtype !== undefined) {
                localVarQueryParameter['entity_subtype'] = entitySubtype;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity overview metadata
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {number} regionId 
         * @param {string} marketId 
         * @param {EntityType} entityType 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOverviewTableMetadata: async (productLineId: string, projectId: string, regionId: number, marketId: string, entityType: EntityType, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getEntityOverviewTableMetadata', 'productLineId', productLineId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEntityOverviewTableMetadata', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getEntityOverviewTableMetadata', 'regionId', regionId)
            // verify required parameter 'marketId' is not null or undefined
            assertParamExists('getEntityOverviewTableMetadata', 'marketId', marketId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getEntityOverviewTableMetadata', 'entityType', entityType)
            const localVarPath = `/field/entity-overview/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productLineId !== undefined) {
                localVarQueryParameter['product_line_id'] = productLineId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (marketId !== undefined) {
                localVarQueryParameter['market_id'] = marketId;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns precall insights for an entity
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} regionId 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityPrecallInsights: async (projectId: string, objectiveId: string, regionId: string, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEntityPrecallInsights', 'projectId', projectId)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getEntityPrecallInsights', 'objectiveId', objectiveId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getEntityPrecallInsights', 'regionId', regionId)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getEntityPrecallInsights', 'entityId', entityId)
            const localVarPath = `/field/precall/entity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity top attribution data
         * @param {string} projectId 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityTopAttributionData: async (projectId: string, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEntityTopAttributionData', 'projectId', projectId)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getEntityTopAttributionData', 'entityId', entityId)
            const localVarPath = `/field/entity/top-attribution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity views config
         * @param {string} marketId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityViewsConfigFieldEntityViewsConfigGet: async (marketId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketId' is not null or undefined
            assertParamExists('getEntityViewsConfigFieldEntityViewsConfigGet', 'marketId', marketId)
            const localVarPath = `/field/entity-views-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (marketId !== undefined) {
                localVarQueryParameter['market_id'] = marketId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get csv export of maptual list given a region
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaptualListCsvExportFieldMaptualListCsvExportGet: async (projectId: string, regionId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getMaptualListCsvExportFieldMaptualListCsvExportGet', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getMaptualListCsvExportFieldMaptualListCsvExportGet', 'regionId', regionId)
            const localVarPath = `/field/maptual-list/csv-export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get maptual list metadata, regions and objective data
         * @param {string} projectId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaptualListMetadataFieldMaptualListMetadataGet: async (projectId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getMaptualListMetadataFieldMaptualListMetadataGet', 'projectId', projectId)
            const localVarPath = `/field/maptual-list/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get maptual list for a given region and objective
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string} [objectiveId] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaptualListRegionFieldMaptualListGet: async (projectId: string, regionId: string, objectiveId?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getMaptualListRegionFieldMaptualListGet', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getMaptualListRegionFieldMaptualListGet', 'regionId', regionId)
            const localVarPath = `/field/maptual-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get market performance chart data
         * @param {EntityType} entityType 
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string} entityId 
         * @param {MetricDataTypeInput} metricDataType 
         * @param {string} metric 
         * @param {string} objectiveId 
         * @param {DomainsEnumsCadenceCadence} cadence 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketPerformanceChart: async (entityType: EntityType, projectId: string, regionId: string, entityId: string, metricDataType: MetricDataTypeInput, metric: string, objectiveId: string, cadence: DomainsEnumsCadenceCadence, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getMarketPerformanceChart', 'entityType', entityType)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getMarketPerformanceChart', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getMarketPerformanceChart', 'regionId', regionId)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getMarketPerformanceChart', 'entityId', entityId)
            // verify required parameter 'metricDataType' is not null or undefined
            assertParamExists('getMarketPerformanceChart', 'metricDataType', metricDataType)
            // verify required parameter 'metric' is not null or undefined
            assertParamExists('getMarketPerformanceChart', 'metric', metric)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getMarketPerformanceChart', 'objectiveId', objectiveId)
            // verify required parameter 'cadence' is not null or undefined
            assertParamExists('getMarketPerformanceChart', 'cadence', cadence)
            const localVarPath = `/field/entity/market-performance-chart/chart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (metricDataType !== undefined) {
                localVarQueryParameter['metric_data_type'] = metricDataType;
            }

            if (metric !== undefined) {
                localVarQueryParameter['metric'] = metric;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (cadence !== undefined) {
                localVarQueryParameter['cadence'] = cadence;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get market performance chart metadata
         * @param {EntityType} entityType 
         * @param {string} objectiveId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketPerformanceMetadata: async (entityType: EntityType, objectiveId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getMarketPerformanceMetadata', 'entityType', entityType)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getMarketPerformanceMetadata', 'objectiveId', objectiveId)
            const localVarPath = `/field/entity/market-performance-chart/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get opportunity chart data
         * @param {EntityType} entityType 
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string} entityId 
         * @param {string} metric 
         * @param {string} objectiveId 
         * @param {DomainsEnumsCadenceCadence} cadence 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpportunityChart: async (entityType: EntityType, projectId: string, regionId: string, entityId: string, metric: string, objectiveId: string, cadence: DomainsEnumsCadenceCadence, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getOpportunityChart', 'entityType', entityType)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getOpportunityChart', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getOpportunityChart', 'regionId', regionId)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getOpportunityChart', 'entityId', entityId)
            // verify required parameter 'metric' is not null or undefined
            assertParamExists('getOpportunityChart', 'metric', metric)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getOpportunityChart', 'objectiveId', objectiveId)
            // verify required parameter 'cadence' is not null or undefined
            assertParamExists('getOpportunityChart', 'cadence', cadence)
            const localVarPath = `/field/entity/opportunity-chart/chart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (metric !== undefined) {
                localVarQueryParameter['metric'] = metric;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (cadence !== undefined) {
                localVarQueryParameter['cadence'] = cadence;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get opportunity chart metadata
         * @param {string} productLineId 
         * @param {string} objectiveId 
         * @param {EntityType} entityType 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpportunityChartMetadata: async (productLineId: string, objectiveId: string, entityType: EntityType, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getOpportunityChartMetadata', 'productLineId', productLineId)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getOpportunityChartMetadata', 'objectiveId', objectiveId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getOpportunityChartMetadata', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getOpportunityChartMetadata', 'entityId', entityId)
            const localVarPath = `/field/entity/opportunity-chart/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productLineId !== undefined) {
                localVarQueryParameter['product_line_id'] = productLineId;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get PowerScore LLM summary
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} regionId 
         * @param {EntityType} entityType 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerScoreLlmSummary: async (projectId: string, objectiveId: string, regionId: string, entityType: EntityType, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getPowerScoreLlmSummary', 'projectId', projectId)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getPowerScoreLlmSummary', 'objectiveId', objectiveId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getPowerScoreLlmSummary', 'regionId', regionId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getPowerScoreLlmSummary', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getPowerScoreLlmSummary', 'entityId', entityId)
            const localVarPath = `/field/entity/powerscore-explainability/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product performance chart data
         * @param {EntityType} entityType 
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string} entityId 
         * @param {MetricDataTypeInput} metricDataType 
         * @param {string} metric 
         * @param {string} objectiveId 
         * @param {DomainsEnumsCadenceCadence} cadence 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductPerformanceChart: async (entityType: EntityType, projectId: string, regionId: string, entityId: string, metricDataType: MetricDataTypeInput, metric: string, objectiveId: string, cadence: DomainsEnumsCadenceCadence, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getProductPerformanceChart', 'entityType', entityType)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProductPerformanceChart', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getProductPerformanceChart', 'regionId', regionId)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getProductPerformanceChart', 'entityId', entityId)
            // verify required parameter 'metricDataType' is not null or undefined
            assertParamExists('getProductPerformanceChart', 'metricDataType', metricDataType)
            // verify required parameter 'metric' is not null or undefined
            assertParamExists('getProductPerformanceChart', 'metric', metric)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getProductPerformanceChart', 'objectiveId', objectiveId)
            // verify required parameter 'cadence' is not null or undefined
            assertParamExists('getProductPerformanceChart', 'cadence', cadence)
            const localVarPath = `/field/entity/product-performance-chart/chart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (metricDataType !== undefined) {
                localVarQueryParameter['metric_data_type'] = metricDataType;
            }

            if (metric !== undefined) {
                localVarQueryParameter['metric'] = metric;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (cadence !== undefined) {
                localVarQueryParameter['cadence'] = cadence;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product performance chart metadata
         * @param {EntityType} entityType 
         * @param {string} objectiveId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductPerformanceMetadata: async (entityType: EntityType, objectiveId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getProductPerformanceMetadata', 'entityType', entityType)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getProductPerformanceMetadata', 'objectiveId', objectiveId)
            const localVarPath = `/field/entity/product-performance-chart/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available segment filters for a given region and objective
         * @param {string} regionId 
         * @param {string} objectiveId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSegmentFiltersFieldMaptualListSegmentFiltersGet: async (regionId: string, objectiveId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getSegmentFiltersFieldMaptualListSegmentFiltersGet', 'regionId', regionId)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getSegmentFiltersFieldMaptualListSegmentFiltersGet', 'objectiveId', objectiveId)
            const localVarPath = `/field/maptual-list/segment-filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get territory overview demand data
         * @param {string} projectId 
         * @param {number} regionId 
         * @param {string} objectiveId 
         * @param {string} metricName 
         * @param {string} cadence 
         * @param {ComparisonTimeFrame} comparisonTimeFrame 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerritoryOverviewDemandData: async (projectId: string, regionId: number, objectiveId: string, metricName: string, cadence: string, comparisonTimeFrame: ComparisonTimeFrame, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTerritoryOverviewDemandData', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getTerritoryOverviewDemandData', 'regionId', regionId)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getTerritoryOverviewDemandData', 'objectiveId', objectiveId)
            // verify required parameter 'metricName' is not null or undefined
            assertParamExists('getTerritoryOverviewDemandData', 'metricName', metricName)
            // verify required parameter 'cadence' is not null or undefined
            assertParamExists('getTerritoryOverviewDemandData', 'cadence', cadence)
            // verify required parameter 'comparisonTimeFrame' is not null or undefined
            assertParamExists('getTerritoryOverviewDemandData', 'comparisonTimeFrame', comparisonTimeFrame)
            const localVarPath = `/field/territory-overview/demand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (objectiveId !== undefined) {
                localVarQueryParameter['objective_id'] = objectiveId;
            }

            if (metricName !== undefined) {
                localVarQueryParameter['metric_name'] = metricName;
            }

            if (cadence !== undefined) {
                localVarQueryParameter['cadence'] = cadence;
            }

            if (comparisonTimeFrame !== undefined) {
                localVarQueryParameter['comparison_time_frame'] = comparisonTimeFrame;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get territory overview metadata
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerritoryOverviewMetadata: async (projectId: string, regionId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTerritoryOverviewMetadata', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getTerritoryOverviewMetadata', 'regionId', regionId)
            const localVarPath = `/field/territory-overview/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get territory overview supply data
         * @param {string} projectId 
         * @param {number} regionId 
         * @param {string} productName 
         * @param {string} metricName 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerritoryOverviewSupplyData: async (projectId: string, regionId: number, productName: string, metricName: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTerritoryOverviewSupplyData', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getTerritoryOverviewSupplyData', 'regionId', regionId)
            // verify required parameter 'productName' is not null or undefined
            assertParamExists('getTerritoryOverviewSupplyData', 'productName', productName)
            // verify required parameter 'metricName' is not null or undefined
            assertParamExists('getTerritoryOverviewSupplyData', 'metricName', metricName)
            const localVarPath = `/field/territory-overview/supply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (productName !== undefined) {
                localVarQueryParameter['product_name'] = productName;
            }

            if (metricName !== undefined) {
                localVarQueryParameter['metric_name'] = metricName;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FieldApi - functional programming interface
 * @export
 */
export const FieldApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FieldApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns an object of categorical data for each objective, displayed on the HCP entity page
         * @param {string} projectId 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoricalData(projectId: string, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoricalData(projectId, entityId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getCategoricalData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get contribution breakdown
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} regionId 
         * @param {string} entityId 
         * @param {EntityType} entityType 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContributionBreakdown(projectId: string, objectiveId: string, regionId: string, entityId: string, entityType: EntityType, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContributionBreakdownResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContributionBreakdown(projectId, objectiveId, regionId, entityId, entityType, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getContributionBreakdown']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of curation entities
         * @param {string} regionId 
         * @param {string} objectiveId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCuratedEnities(regionId: string, objectiveId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCuratedEnities(regionId, objectiveId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getCuratedEnities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns array of customized messages for a given objective and entity combination
         * @param {string} productLineId 
         * @param {string} entityId 
         * @param {string} objectiveId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomizedMessagesFieldEntityMessagesGet(productLineId: string, entityId: string, objectiveId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomizedMessagesFieldEntityMessagesGet(productLineId, entityId, objectiveId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getCustomizedMessagesFieldEntityMessagesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of precall insights for multiple entities
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} regionId 
         * @param {EntityType} entityType 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntitiesPrecallInsights(projectId: string, objectiveId: string, regionId: string, entityType: EntityType, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityPrecallInsights>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntitiesPrecallInsights(projectId, objectiveId, regionId, entityType, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getEntitiesPrecallInsights']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity data
         * @param {string} productLineId 
         * @param {string} entityId 
         * @param {EntityType} [entityType] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityData(productLineId: string, entityId: string, entityType?: EntityType, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityData(productLineId, entityId, entityType, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getEntityData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity journey
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityJourney(projectId: string, regionId: string, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityJourneyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityJourney(projectId, regionId, entityId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getEntityJourney']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity overview CSV
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {EntityType} entityType 
         * @param {string} objectiveId 
         * @param {ApplicationEntityOverviewSrcEnumsCadenceCadence} cadence 
         * @param {Array<any>} dates 
         * @param {Array<any>} columns 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityOverviewCsvFieldEntityOverviewCsvGet(productLineId: string, projectId: string, regionId: string, entityType: EntityType, objectiveId: string, cadence: ApplicationEntityOverviewSrcEnumsCadenceCadence, dates: Array<any>, columns: Array<any>, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityOverviewCsvFieldEntityOverviewCsvGet(productLineId, projectId, regionId, entityType, objectiveId, cadence, dates, columns, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getEntityOverviewCsvFieldEntityOverviewCsvGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity overview CSV metadata
         * @param {string} projectId 
         * @param {EntityType} entityType 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGet(projectId: string, entityType: EntityType, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGet(projectId, entityType, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity overview drawer chart data
         * @param {string} productLineId 
         * @param {string} regionId 
         * @param {EntityType} entityType 
         * @param {string} objectiveId 
         * @param {string} entityId 
         * @param {ChartType} chartType 
         * @param {string} metric 
         * @param {ApplicationEntityOverviewSrcEnumsCadenceCadence} cadence 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityOverviewDrawerChart(productLineId: string, regionId: string, entityType: EntityType, objectiveId: string, entityId: string, chartType: ChartType, metric: string, cadence: ApplicationEntityOverviewSrcEnumsCadenceCadence, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityOverviewDrawerChart(productLineId, regionId, entityType, objectiveId, entityId, chartType, metric, cadence, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getEntityOverviewDrawerChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity overview drawer metadata
         * @param {string} productLineId 
         * @param {string} marketId 
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {EntityType} entityType 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityOverviewDrawerMetadata(productLineId: string, marketId: string, projectId: string, regionId: string, entityType: EntityType, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrawerMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityOverviewDrawerMetadata(productLineId, marketId, projectId, regionId, entityType, entityId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getEntityOverviewDrawerMetadata']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity overview drawer profile data
         * @param {string} marketId 
         * @param {EntityType} entityType 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityOverviewDrawerProfile(marketId: string, entityType: EntityType, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityOverviewDrawerProfile(marketId, entityType, entityId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getEntityOverviewDrawerProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity overview drawer timeline data
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {EntityType} entityType 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityOverviewDrawerTimeline(projectId: string, regionId: string, entityType: EntityType, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrawerTimelineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityOverviewDrawerTimeline(projectId, regionId, entityType, entityId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getEntityOverviewDrawerTimeline']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity overview table data
         * @param {string} productLineId 
         * @param {string} regionId 
         * @param {EntityType} entityType 
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {ApplicationEntityOverviewSrcEnumsCadenceCadence} cadence 
         * @param {ComparisonTimeFrameId} comparisonTimeFrameId 
         * @param {string} [entitySubtype] 
         * @param {string} [filters] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityOverviewTableData(productLineId: string, regionId: string, entityType: EntityType, projectId: string, objectiveId: string, cadence: ApplicationEntityOverviewSrcEnumsCadenceCadence, comparisonTimeFrameId: ComparisonTimeFrameId, entitySubtype?: string, filters?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityOverviewTableData(productLineId, regionId, entityType, projectId, objectiveId, cadence, comparisonTimeFrameId, entitySubtype, filters, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getEntityOverviewTableData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity overview metadata
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {number} regionId 
         * @param {string} marketId 
         * @param {EntityType} entityType 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityOverviewTableMetadata(productLineId: string, projectId: string, regionId: number, marketId: string, entityType: EntityType, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityOverviewTableMetadata(productLineId, projectId, regionId, marketId, entityType, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getEntityOverviewTableMetadata']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns precall insights for an entity
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} regionId 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityPrecallInsights(projectId: string, objectiveId: string, regionId: string, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityPrecallInsights>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityPrecallInsights(projectId, objectiveId, regionId, entityId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getEntityPrecallInsights']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity top attribution data
         * @param {string} projectId 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityTopAttributionData(projectId: string, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityTopAttributionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityTopAttributionData(projectId, entityId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getEntityTopAttributionData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity views config
         * @param {string} marketId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityViewsConfigFieldEntityViewsConfigGet(marketId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityViewsConfigFieldEntityViewsConfigGet(marketId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getEntityViewsConfigFieldEntityViewsConfigGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get csv export of maptual list given a region
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaptualListCsvExportFieldMaptualListCsvExportGet(projectId: string, regionId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMaptualListCsvExportFieldMaptualListCsvExportGet(projectId, regionId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getMaptualListCsvExportFieldMaptualListCsvExportGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get maptual list metadata, regions and objective data
         * @param {string} projectId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaptualListMetadataFieldMaptualListMetadataGet(projectId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMaptualListMetadataFieldMaptualListMetadataGet(projectId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getMaptualListMetadataFieldMaptualListMetadataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get maptual list for a given region and objective
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string} [objectiveId] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaptualListRegionFieldMaptualListGet(projectId: string, regionId: string, objectiveId?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMaptualListRegionFieldMaptualListGet(projectId, regionId, objectiveId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getMaptualListRegionFieldMaptualListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get market performance chart data
         * @param {EntityType} entityType 
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string} entityId 
         * @param {MetricDataTypeInput} metricDataType 
         * @param {string} metric 
         * @param {string} objectiveId 
         * @param {DomainsEnumsCadenceCadence} cadence 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketPerformanceChart(entityType: EntityType, projectId: string, regionId: string, entityId: string, metricDataType: MetricDataTypeInput, metric: string, objectiveId: string, cadence: DomainsEnumsCadenceCadence, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketPerformanceChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketPerformanceChart(entityType, projectId, regionId, entityId, metricDataType, metric, objectiveId, cadence, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getMarketPerformanceChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get market performance chart metadata
         * @param {EntityType} entityType 
         * @param {string} objectiveId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketPerformanceMetadata(entityType: EntityType, objectiveId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketPerformanceMetadata(entityType, objectiveId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getMarketPerformanceMetadata']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get opportunity chart data
         * @param {EntityType} entityType 
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string} entityId 
         * @param {string} metric 
         * @param {string} objectiveId 
         * @param {DomainsEnumsCadenceCadence} cadence 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpportunityChart(entityType: EntityType, projectId: string, regionId: string, entityId: string, metric: string, objectiveId: string, cadence: DomainsEnumsCadenceCadence, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunityChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpportunityChart(entityType, projectId, regionId, entityId, metric, objectiveId, cadence, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getOpportunityChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get opportunity chart metadata
         * @param {string} productLineId 
         * @param {string} objectiveId 
         * @param {EntityType} entityType 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpportunityChartMetadata(productLineId: string, objectiveId: string, entityType: EntityType, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunityChartMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpportunityChartMetadata(productLineId, objectiveId, entityType, entityId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getOpportunityChartMetadata']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get PowerScore LLM summary
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} regionId 
         * @param {EntityType} entityType 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPowerScoreLlmSummary(projectId: string, objectiveId: string, regionId: string, entityType: EntityType, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PowerScoreLlmSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPowerScoreLlmSummary(projectId, objectiveId, regionId, entityType, entityId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getPowerScoreLlmSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get product performance chart data
         * @param {EntityType} entityType 
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string} entityId 
         * @param {MetricDataTypeInput} metricDataType 
         * @param {string} metric 
         * @param {string} objectiveId 
         * @param {DomainsEnumsCadenceCadence} cadence 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductPerformanceChart(entityType: EntityType, projectId: string, regionId: string, entityId: string, metricDataType: MetricDataTypeInput, metric: string, objectiveId: string, cadence: DomainsEnumsCadenceCadence, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPerformanceChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductPerformanceChart(entityType, projectId, regionId, entityId, metricDataType, metric, objectiveId, cadence, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getProductPerformanceChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get product performance chart metadata
         * @param {EntityType} entityType 
         * @param {string} objectiveId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductPerformanceMetadata(entityType: EntityType, objectiveId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductPerformanceMetadata(entityType, objectiveId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getProductPerformanceMetadata']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get available segment filters for a given region and objective
         * @param {string} regionId 
         * @param {string} objectiveId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSegmentFiltersFieldMaptualListSegmentFiltersGet(regionId: string, objectiveId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSegmentFiltersFieldMaptualListSegmentFiltersGet(regionId, objectiveId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getSegmentFiltersFieldMaptualListSegmentFiltersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get territory overview demand data
         * @param {string} projectId 
         * @param {number} regionId 
         * @param {string} objectiveId 
         * @param {string} metricName 
         * @param {string} cadence 
         * @param {ComparisonTimeFrame} comparisonTimeFrame 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerritoryOverviewDemandData(projectId: string, regionId: number, objectiveId: string, metricName: string, cadence: string, comparisonTimeFrame: ComparisonTimeFrame, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTerritoryOverviewDemandData(projectId, regionId, objectiveId, metricName, cadence, comparisonTimeFrame, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getTerritoryOverviewDemandData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get territory overview metadata
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerritoryOverviewMetadata(projectId: string, regionId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerritoryOverviewMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTerritoryOverviewMetadata(projectId, regionId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getTerritoryOverviewMetadata']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get territory overview supply data
         * @param {string} projectId 
         * @param {number} regionId 
         * @param {string} productName 
         * @param {string} metricName 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerritoryOverviewSupplyData(projectId: string, regionId: number, productName: string, metricName: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTerritoryOverviewSupplyData(projectId, regionId, productName, metricName, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FieldApi.getTerritoryOverviewSupplyData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FieldApi - factory interface
 * @export
 */
export const FieldApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FieldApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns an object of categorical data for each objective, displayed on the HCP entity page
         * @param {FieldApiGetCategoricalDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoricalData(requestParameters: FieldApiGetCategoricalDataRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getCategoricalData(requestParameters.projectId, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contribution breakdown
         * @param {FieldApiGetContributionBreakdownRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContributionBreakdown(requestParameters: FieldApiGetContributionBreakdownRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContributionBreakdownResponse> {
            return localVarFp.getContributionBreakdown(requestParameters.projectId, requestParameters.objectiveId, requestParameters.regionId, requestParameters.entityId, requestParameters.entityType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of curation entities
         * @param {FieldApiGetCuratedEnitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedEnities(requestParameters: FieldApiGetCuratedEnitiesRequest, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.getCuratedEnities(requestParameters.regionId, requestParameters.objectiveId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns array of customized messages for a given objective and entity combination
         * @param {FieldApiGetCustomizedMessagesFieldEntityMessagesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomizedMessagesFieldEntityMessagesGet(requestParameters: FieldApiGetCustomizedMessagesFieldEntityMessagesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getCustomizedMessagesFieldEntityMessagesGet(requestParameters.productLineId, requestParameters.entityId, requestParameters.objectiveId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of precall insights for multiple entities
         * @param {FieldApiGetEntitiesPrecallInsightsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntitiesPrecallInsights(requestParameters: FieldApiGetEntitiesPrecallInsightsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityPrecallInsights>> {
            return localVarFp.getEntitiesPrecallInsights(requestParameters.projectId, requestParameters.objectiveId, requestParameters.regionId, requestParameters.entityType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity data
         * @param {FieldApiGetEntityDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityData(requestParameters: FieldApiGetEntityDataRequest, options?: RawAxiosRequestConfig): AxiosPromise<EntityDataResponse> {
            return localVarFp.getEntityData(requestParameters.productLineId, requestParameters.entityId, requestParameters.entityType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity journey
         * @param {FieldApiGetEntityJourneyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityJourney(requestParameters: FieldApiGetEntityJourneyRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityJourneyResponse>> {
            return localVarFp.getEntityJourney(requestParameters.projectId, requestParameters.regionId, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity overview CSV
         * @param {FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOverviewCsvFieldEntityOverviewCsvGet(requestParameters: FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getEntityOverviewCsvFieldEntityOverviewCsvGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.regionId, requestParameters.entityType, requestParameters.objectiveId, requestParameters.cadence, requestParameters.dates, requestParameters.columns, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity overview CSV metadata
         * @param {FieldApiGetEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGet(requestParameters: FieldApiGetEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGet(requestParameters.projectId, requestParameters.entityType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity overview drawer chart data
         * @param {FieldApiGetEntityOverviewDrawerChartRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOverviewDrawerChart(requestParameters: FieldApiGetEntityOverviewDrawerChartRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getEntityOverviewDrawerChart(requestParameters.productLineId, requestParameters.regionId, requestParameters.entityType, requestParameters.objectiveId, requestParameters.entityId, requestParameters.chartType, requestParameters.metric, requestParameters.cadence, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity overview drawer metadata
         * @param {FieldApiGetEntityOverviewDrawerMetadataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOverviewDrawerMetadata(requestParameters: FieldApiGetEntityOverviewDrawerMetadataRequest, options?: RawAxiosRequestConfig): AxiosPromise<DrawerMetadataResponse> {
            return localVarFp.getEntityOverviewDrawerMetadata(requestParameters.productLineId, requestParameters.marketId, requestParameters.projectId, requestParameters.regionId, requestParameters.entityType, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity overview drawer profile data
         * @param {FieldApiGetEntityOverviewDrawerProfileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOverviewDrawerProfile(requestParameters: FieldApiGetEntityOverviewDrawerProfileRequest, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getEntityOverviewDrawerProfile(requestParameters.marketId, requestParameters.entityType, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity overview drawer timeline data
         * @param {FieldApiGetEntityOverviewDrawerTimelineRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOverviewDrawerTimeline(requestParameters: FieldApiGetEntityOverviewDrawerTimelineRequest, options?: RawAxiosRequestConfig): AxiosPromise<DrawerTimelineResponse> {
            return localVarFp.getEntityOverviewDrawerTimeline(requestParameters.projectId, requestParameters.regionId, requestParameters.entityType, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity overview table data
         * @param {FieldApiGetEntityOverviewTableDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOverviewTableData(requestParameters: FieldApiGetEntityOverviewTableDataRequest, options?: RawAxiosRequestConfig): AxiosPromise<TableResponse> {
            return localVarFp.getEntityOverviewTableData(requestParameters.productLineId, requestParameters.regionId, requestParameters.entityType, requestParameters.projectId, requestParameters.objectiveId, requestParameters.cadence, requestParameters.comparisonTimeFrameId, requestParameters.entitySubtype, requestParameters.filters, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity overview metadata
         * @param {FieldApiGetEntityOverviewTableMetadataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOverviewTableMetadata(requestParameters: FieldApiGetEntityOverviewTableMetadataRequest, options?: RawAxiosRequestConfig): AxiosPromise<MetadataResponse> {
            return localVarFp.getEntityOverviewTableMetadata(requestParameters.productLineId, requestParameters.projectId, requestParameters.regionId, requestParameters.marketId, requestParameters.entityType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns precall insights for an entity
         * @param {FieldApiGetEntityPrecallInsightsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityPrecallInsights(requestParameters: FieldApiGetEntityPrecallInsightsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityPrecallInsights>> {
            return localVarFp.getEntityPrecallInsights(requestParameters.projectId, requestParameters.objectiveId, requestParameters.regionId, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity top attribution data
         * @param {FieldApiGetEntityTopAttributionDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityTopAttributionData(requestParameters: FieldApiGetEntityTopAttributionDataRequest, options?: RawAxiosRequestConfig): AxiosPromise<EntityTopAttributionResponse> {
            return localVarFp.getEntityTopAttributionData(requestParameters.projectId, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity views config
         * @param {FieldApiGetEntityViewsConfigFieldEntityViewsConfigGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityViewsConfigFieldEntityViewsConfigGet(requestParameters: FieldApiGetEntityViewsConfigFieldEntityViewsConfigGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getEntityViewsConfigFieldEntityViewsConfigGet(requestParameters.marketId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get csv export of maptual list given a region
         * @param {FieldApiGetMaptualListCsvExportFieldMaptualListCsvExportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaptualListCsvExportFieldMaptualListCsvExportGet(requestParameters: FieldApiGetMaptualListCsvExportFieldMaptualListCsvExportGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getMaptualListCsvExportFieldMaptualListCsvExportGet(requestParameters.projectId, requestParameters.regionId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get maptual list metadata, regions and objective data
         * @param {FieldApiGetMaptualListMetadataFieldMaptualListMetadataGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaptualListMetadataFieldMaptualListMetadataGet(requestParameters: FieldApiGetMaptualListMetadataFieldMaptualListMetadataGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getMaptualListMetadataFieldMaptualListMetadataGet(requestParameters.projectId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get maptual list for a given region and objective
         * @param {FieldApiGetMaptualListRegionFieldMaptualListGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaptualListRegionFieldMaptualListGet(requestParameters: FieldApiGetMaptualListRegionFieldMaptualListGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getMaptualListRegionFieldMaptualListGet(requestParameters.projectId, requestParameters.regionId, requestParameters.objectiveId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get market performance chart data
         * @param {FieldApiGetMarketPerformanceChartRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketPerformanceChart(requestParameters: FieldApiGetMarketPerformanceChartRequest, options?: RawAxiosRequestConfig): AxiosPromise<MarketPerformanceChartResponse> {
            return localVarFp.getMarketPerformanceChart(requestParameters.entityType, requestParameters.projectId, requestParameters.regionId, requestParameters.entityId, requestParameters.metricDataType, requestParameters.metric, requestParameters.objectiveId, requestParameters.cadence, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get market performance chart metadata
         * @param {FieldApiGetMarketPerformanceMetadataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketPerformanceMetadata(requestParameters: FieldApiGetMarketPerformanceMetadataRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChartMetadataResponse> {
            return localVarFp.getMarketPerformanceMetadata(requestParameters.entityType, requestParameters.objectiveId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get opportunity chart data
         * @param {FieldApiGetOpportunityChartRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpportunityChart(requestParameters: FieldApiGetOpportunityChartRequest, options?: RawAxiosRequestConfig): AxiosPromise<OpportunityChartResponse> {
            return localVarFp.getOpportunityChart(requestParameters.entityType, requestParameters.projectId, requestParameters.regionId, requestParameters.entityId, requestParameters.metric, requestParameters.objectiveId, requestParameters.cadence, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get opportunity chart metadata
         * @param {FieldApiGetOpportunityChartMetadataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpportunityChartMetadata(requestParameters: FieldApiGetOpportunityChartMetadataRequest, options?: RawAxiosRequestConfig): AxiosPromise<OpportunityChartMetadataResponse> {
            return localVarFp.getOpportunityChartMetadata(requestParameters.productLineId, requestParameters.objectiveId, requestParameters.entityType, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get PowerScore LLM summary
         * @param {FieldApiGetPowerScoreLlmSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerScoreLlmSummary(requestParameters: FieldApiGetPowerScoreLlmSummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<PowerScoreLlmSummaryResponse> {
            return localVarFp.getPowerScoreLlmSummary(requestParameters.projectId, requestParameters.objectiveId, requestParameters.regionId, requestParameters.entityType, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get product performance chart data
         * @param {FieldApiGetProductPerformanceChartRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductPerformanceChart(requestParameters: FieldApiGetProductPerformanceChartRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProductPerformanceChartResponse> {
            return localVarFp.getProductPerformanceChart(requestParameters.entityType, requestParameters.projectId, requestParameters.regionId, requestParameters.entityId, requestParameters.metricDataType, requestParameters.metric, requestParameters.objectiveId, requestParameters.cadence, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get product performance chart metadata
         * @param {FieldApiGetProductPerformanceMetadataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductPerformanceMetadata(requestParameters: FieldApiGetProductPerformanceMetadataRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChartMetadataResponse> {
            return localVarFp.getProductPerformanceMetadata(requestParameters.entityType, requestParameters.objectiveId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available segment filters for a given region and objective
         * @param {FieldApiGetSegmentFiltersFieldMaptualListSegmentFiltersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSegmentFiltersFieldMaptualListSegmentFiltersGet(requestParameters: FieldApiGetSegmentFiltersFieldMaptualListSegmentFiltersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getSegmentFiltersFieldMaptualListSegmentFiltersGet(requestParameters.regionId, requestParameters.objectiveId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get territory overview demand data
         * @param {FieldApiGetTerritoryOverviewDemandDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerritoryOverviewDemandData(requestParameters: FieldApiGetTerritoryOverviewDemandDataRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getTerritoryOverviewDemandData(requestParameters.projectId, requestParameters.regionId, requestParameters.objectiveId, requestParameters.metricName, requestParameters.cadence, requestParameters.comparisonTimeFrame, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get territory overview metadata
         * @param {FieldApiGetTerritoryOverviewMetadataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerritoryOverviewMetadata(requestParameters: FieldApiGetTerritoryOverviewMetadataRequest, options?: RawAxiosRequestConfig): AxiosPromise<TerritoryOverviewMetadataResponse> {
            return localVarFp.getTerritoryOverviewMetadata(requestParameters.projectId, requestParameters.regionId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get territory overview supply data
         * @param {FieldApiGetTerritoryOverviewSupplyDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerritoryOverviewSupplyData(requestParameters: FieldApiGetTerritoryOverviewSupplyDataRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getTerritoryOverviewSupplyData(requestParameters.projectId, requestParameters.regionId, requestParameters.productName, requestParameters.metricName, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCategoricalData operation in FieldApi.
 * @export
 * @interface FieldApiGetCategoricalDataRequest
 */
export interface FieldApiGetCategoricalDataRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCategoricalData
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCategoricalData
     */
    readonly entityId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCategoricalData
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCategoricalData
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCategoricalData
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCategoricalData
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getContributionBreakdown operation in FieldApi.
 * @export
 * @interface FieldApiGetContributionBreakdownRequest
 */
export interface FieldApiGetContributionBreakdownRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetContributionBreakdown
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetContributionBreakdown
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetContributionBreakdown
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetContributionBreakdown
     */
    readonly entityId: string

    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetContributionBreakdown
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetContributionBreakdown
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetContributionBreakdown
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetContributionBreakdown
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetContributionBreakdown
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getCuratedEnities operation in FieldApi.
 * @export
 * @interface FieldApiGetCuratedEnitiesRequest
 */
export interface FieldApiGetCuratedEnitiesRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCuratedEnities
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCuratedEnities
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCuratedEnities
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCuratedEnities
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCuratedEnities
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCuratedEnities
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getCustomizedMessagesFieldEntityMessagesGet operation in FieldApi.
 * @export
 * @interface FieldApiGetCustomizedMessagesFieldEntityMessagesGetRequest
 */
export interface FieldApiGetCustomizedMessagesFieldEntityMessagesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCustomizedMessagesFieldEntityMessagesGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCustomizedMessagesFieldEntityMessagesGet
     */
    readonly entityId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCustomizedMessagesFieldEntityMessagesGet
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCustomizedMessagesFieldEntityMessagesGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCustomizedMessagesFieldEntityMessagesGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCustomizedMessagesFieldEntityMessagesGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetCustomizedMessagesFieldEntityMessagesGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntitiesPrecallInsights operation in FieldApi.
 * @export
 * @interface FieldApiGetEntitiesPrecallInsightsRequest
 */
export interface FieldApiGetEntitiesPrecallInsightsRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntitiesPrecallInsights
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntitiesPrecallInsights
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntitiesPrecallInsights
     */
    readonly regionId: string

    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetEntitiesPrecallInsights
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntitiesPrecallInsights
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntitiesPrecallInsights
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntitiesPrecallInsights
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntitiesPrecallInsights
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntityData operation in FieldApi.
 * @export
 * @interface FieldApiGetEntityDataRequest
 */
export interface FieldApiGetEntityDataRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityData
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityData
     */
    readonly entityId: string

    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetEntityData
     */
    readonly entityType?: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityData
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityData
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityData
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityData
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntityJourney operation in FieldApi.
 * @export
 * @interface FieldApiGetEntityJourneyRequest
 */
export interface FieldApiGetEntityJourneyRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityJourney
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityJourney
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityJourney
     */
    readonly entityId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityJourney
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityJourney
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityJourney
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityJourney
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntityOverviewCsvFieldEntityOverviewCsvGet operation in FieldApi.
 * @export
 * @interface FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGetRequest
 */
export interface FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGet
     */
    readonly regionId: string

    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGet
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGet
     */
    readonly objectiveId: string

    /**
     * 
     * @type {ApplicationEntityOverviewSrcEnumsCadenceCadence}
     * @memberof FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGet
     */
    readonly cadence: ApplicationEntityOverviewSrcEnumsCadenceCadence

    /**
     * 
     * @type {Array<any>}
     * @memberof FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGet
     */
    readonly dates: Array<any>

    /**
     * 
     * @type {Array<any>}
     * @memberof FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGet
     */
    readonly columns: Array<any>

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGet operation in FieldApi.
 * @export
 * @interface FieldApiGetEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGetRequest
 */
export interface FieldApiGetEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGet
     */
    readonly projectId: string

    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGet
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntityOverviewDrawerChart operation in FieldApi.
 * @export
 * @interface FieldApiGetEntityOverviewDrawerChartRequest
 */
export interface FieldApiGetEntityOverviewDrawerChartRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerChart
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerChart
     */
    readonly regionId: string

    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetEntityOverviewDrawerChart
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerChart
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerChart
     */
    readonly entityId: string

    /**
     * 
     * @type {ChartType}
     * @memberof FieldApiGetEntityOverviewDrawerChart
     */
    readonly chartType: ChartType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerChart
     */
    readonly metric: string

    /**
     * 
     * @type {ApplicationEntityOverviewSrcEnumsCadenceCadence}
     * @memberof FieldApiGetEntityOverviewDrawerChart
     */
    readonly cadence: ApplicationEntityOverviewSrcEnumsCadenceCadence

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerChart
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerChart
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerChart
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerChart
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntityOverviewDrawerMetadata operation in FieldApi.
 * @export
 * @interface FieldApiGetEntityOverviewDrawerMetadataRequest
 */
export interface FieldApiGetEntityOverviewDrawerMetadataRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerMetadata
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerMetadata
     */
    readonly marketId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerMetadata
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerMetadata
     */
    readonly regionId: string

    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetEntityOverviewDrawerMetadata
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerMetadata
     */
    readonly entityId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerMetadata
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerMetadata
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerMetadata
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerMetadata
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntityOverviewDrawerProfile operation in FieldApi.
 * @export
 * @interface FieldApiGetEntityOverviewDrawerProfileRequest
 */
export interface FieldApiGetEntityOverviewDrawerProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerProfile
     */
    readonly marketId: string

    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetEntityOverviewDrawerProfile
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerProfile
     */
    readonly entityId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerProfile
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerProfile
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerProfile
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerProfile
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntityOverviewDrawerTimeline operation in FieldApi.
 * @export
 * @interface FieldApiGetEntityOverviewDrawerTimelineRequest
 */
export interface FieldApiGetEntityOverviewDrawerTimelineRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerTimeline
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerTimeline
     */
    readonly regionId: string

    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetEntityOverviewDrawerTimeline
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerTimeline
     */
    readonly entityId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerTimeline
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerTimeline
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerTimeline
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewDrawerTimeline
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntityOverviewTableData operation in FieldApi.
 * @export
 * @interface FieldApiGetEntityOverviewTableDataRequest
 */
export interface FieldApiGetEntityOverviewTableDataRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewTableData
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewTableData
     */
    readonly regionId: string

    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetEntityOverviewTableData
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewTableData
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewTableData
     */
    readonly objectiveId: string

    /**
     * 
     * @type {ApplicationEntityOverviewSrcEnumsCadenceCadence}
     * @memberof FieldApiGetEntityOverviewTableData
     */
    readonly cadence: ApplicationEntityOverviewSrcEnumsCadenceCadence

    /**
     * 
     * @type {ComparisonTimeFrameId}
     * @memberof FieldApiGetEntityOverviewTableData
     */
    readonly comparisonTimeFrameId: ComparisonTimeFrameId

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewTableData
     */
    readonly entitySubtype?: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewTableData
     */
    readonly filters?: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewTableData
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewTableData
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewTableData
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewTableData
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntityOverviewTableMetadata operation in FieldApi.
 * @export
 * @interface FieldApiGetEntityOverviewTableMetadataRequest
 */
export interface FieldApiGetEntityOverviewTableMetadataRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewTableMetadata
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewTableMetadata
     */
    readonly projectId: string

    /**
     * 
     * @type {number}
     * @memberof FieldApiGetEntityOverviewTableMetadata
     */
    readonly regionId: number

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewTableMetadata
     */
    readonly marketId: string

    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetEntityOverviewTableMetadata
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewTableMetadata
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewTableMetadata
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewTableMetadata
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityOverviewTableMetadata
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntityPrecallInsights operation in FieldApi.
 * @export
 * @interface FieldApiGetEntityPrecallInsightsRequest
 */
export interface FieldApiGetEntityPrecallInsightsRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityPrecallInsights
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityPrecallInsights
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityPrecallInsights
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityPrecallInsights
     */
    readonly entityId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityPrecallInsights
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityPrecallInsights
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityPrecallInsights
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityPrecallInsights
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntityTopAttributionData operation in FieldApi.
 * @export
 * @interface FieldApiGetEntityTopAttributionDataRequest
 */
export interface FieldApiGetEntityTopAttributionDataRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityTopAttributionData
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityTopAttributionData
     */
    readonly entityId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityTopAttributionData
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityTopAttributionData
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityTopAttributionData
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityTopAttributionData
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntityViewsConfigFieldEntityViewsConfigGet operation in FieldApi.
 * @export
 * @interface FieldApiGetEntityViewsConfigFieldEntityViewsConfigGetRequest
 */
export interface FieldApiGetEntityViewsConfigFieldEntityViewsConfigGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityViewsConfigFieldEntityViewsConfigGet
     */
    readonly marketId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityViewsConfigFieldEntityViewsConfigGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityViewsConfigFieldEntityViewsConfigGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityViewsConfigFieldEntityViewsConfigGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetEntityViewsConfigFieldEntityViewsConfigGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getMaptualListCsvExportFieldMaptualListCsvExportGet operation in FieldApi.
 * @export
 * @interface FieldApiGetMaptualListCsvExportFieldMaptualListCsvExportGetRequest
 */
export interface FieldApiGetMaptualListCsvExportFieldMaptualListCsvExportGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListCsvExportFieldMaptualListCsvExportGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListCsvExportFieldMaptualListCsvExportGet
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListCsvExportFieldMaptualListCsvExportGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListCsvExportFieldMaptualListCsvExportGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListCsvExportFieldMaptualListCsvExportGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListCsvExportFieldMaptualListCsvExportGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getMaptualListMetadataFieldMaptualListMetadataGet operation in FieldApi.
 * @export
 * @interface FieldApiGetMaptualListMetadataFieldMaptualListMetadataGetRequest
 */
export interface FieldApiGetMaptualListMetadataFieldMaptualListMetadataGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListMetadataFieldMaptualListMetadataGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListMetadataFieldMaptualListMetadataGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListMetadataFieldMaptualListMetadataGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListMetadataFieldMaptualListMetadataGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListMetadataFieldMaptualListMetadataGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getMaptualListRegionFieldMaptualListGet operation in FieldApi.
 * @export
 * @interface FieldApiGetMaptualListRegionFieldMaptualListGetRequest
 */
export interface FieldApiGetMaptualListRegionFieldMaptualListGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListRegionFieldMaptualListGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListRegionFieldMaptualListGet
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListRegionFieldMaptualListGet
     */
    readonly objectiveId?: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListRegionFieldMaptualListGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListRegionFieldMaptualListGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListRegionFieldMaptualListGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMaptualListRegionFieldMaptualListGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getMarketPerformanceChart operation in FieldApi.
 * @export
 * @interface FieldApiGetMarketPerformanceChartRequest
 */
export interface FieldApiGetMarketPerformanceChartRequest {
    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetMarketPerformanceChart
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMarketPerformanceChart
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMarketPerformanceChart
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMarketPerformanceChart
     */
    readonly entityId: string

    /**
     * 
     * @type {MetricDataTypeInput}
     * @memberof FieldApiGetMarketPerformanceChart
     */
    readonly metricDataType: MetricDataTypeInput

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMarketPerformanceChart
     */
    readonly metric: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMarketPerformanceChart
     */
    readonly objectiveId: string

    /**
     * 
     * @type {DomainsEnumsCadenceCadence}
     * @memberof FieldApiGetMarketPerformanceChart
     */
    readonly cadence: DomainsEnumsCadenceCadence

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMarketPerformanceChart
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMarketPerformanceChart
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMarketPerformanceChart
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMarketPerformanceChart
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getMarketPerformanceMetadata operation in FieldApi.
 * @export
 * @interface FieldApiGetMarketPerformanceMetadataRequest
 */
export interface FieldApiGetMarketPerformanceMetadataRequest {
    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetMarketPerformanceMetadata
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMarketPerformanceMetadata
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMarketPerformanceMetadata
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMarketPerformanceMetadata
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMarketPerformanceMetadata
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetMarketPerformanceMetadata
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getOpportunityChart operation in FieldApi.
 * @export
 * @interface FieldApiGetOpportunityChartRequest
 */
export interface FieldApiGetOpportunityChartRequest {
    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetOpportunityChart
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetOpportunityChart
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetOpportunityChart
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetOpportunityChart
     */
    readonly entityId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetOpportunityChart
     */
    readonly metric: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetOpportunityChart
     */
    readonly objectiveId: string

    /**
     * 
     * @type {DomainsEnumsCadenceCadence}
     * @memberof FieldApiGetOpportunityChart
     */
    readonly cadence: DomainsEnumsCadenceCadence

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetOpportunityChart
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetOpportunityChart
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetOpportunityChart
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetOpportunityChart
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getOpportunityChartMetadata operation in FieldApi.
 * @export
 * @interface FieldApiGetOpportunityChartMetadataRequest
 */
export interface FieldApiGetOpportunityChartMetadataRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetOpportunityChartMetadata
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetOpportunityChartMetadata
     */
    readonly objectiveId: string

    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetOpportunityChartMetadata
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetOpportunityChartMetadata
     */
    readonly entityId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetOpportunityChartMetadata
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetOpportunityChartMetadata
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetOpportunityChartMetadata
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetOpportunityChartMetadata
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getPowerScoreLlmSummary operation in FieldApi.
 * @export
 * @interface FieldApiGetPowerScoreLlmSummaryRequest
 */
export interface FieldApiGetPowerScoreLlmSummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetPowerScoreLlmSummary
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetPowerScoreLlmSummary
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetPowerScoreLlmSummary
     */
    readonly regionId: string

    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetPowerScoreLlmSummary
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetPowerScoreLlmSummary
     */
    readonly entityId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetPowerScoreLlmSummary
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetPowerScoreLlmSummary
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetPowerScoreLlmSummary
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetPowerScoreLlmSummary
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getProductPerformanceChart operation in FieldApi.
 * @export
 * @interface FieldApiGetProductPerformanceChartRequest
 */
export interface FieldApiGetProductPerformanceChartRequest {
    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetProductPerformanceChart
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetProductPerformanceChart
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetProductPerformanceChart
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetProductPerformanceChart
     */
    readonly entityId: string

    /**
     * 
     * @type {MetricDataTypeInput}
     * @memberof FieldApiGetProductPerformanceChart
     */
    readonly metricDataType: MetricDataTypeInput

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetProductPerformanceChart
     */
    readonly metric: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetProductPerformanceChart
     */
    readonly objectiveId: string

    /**
     * 
     * @type {DomainsEnumsCadenceCadence}
     * @memberof FieldApiGetProductPerformanceChart
     */
    readonly cadence: DomainsEnumsCadenceCadence

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetProductPerformanceChart
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetProductPerformanceChart
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetProductPerformanceChart
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetProductPerformanceChart
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getProductPerformanceMetadata operation in FieldApi.
 * @export
 * @interface FieldApiGetProductPerformanceMetadataRequest
 */
export interface FieldApiGetProductPerformanceMetadataRequest {
    /**
     * 
     * @type {EntityType}
     * @memberof FieldApiGetProductPerformanceMetadata
     */
    readonly entityType: EntityType

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetProductPerformanceMetadata
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetProductPerformanceMetadata
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetProductPerformanceMetadata
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetProductPerformanceMetadata
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetProductPerformanceMetadata
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getSegmentFiltersFieldMaptualListSegmentFiltersGet operation in FieldApi.
 * @export
 * @interface FieldApiGetSegmentFiltersFieldMaptualListSegmentFiltersGetRequest
 */
export interface FieldApiGetSegmentFiltersFieldMaptualListSegmentFiltersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetSegmentFiltersFieldMaptualListSegmentFiltersGet
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetSegmentFiltersFieldMaptualListSegmentFiltersGet
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetSegmentFiltersFieldMaptualListSegmentFiltersGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetSegmentFiltersFieldMaptualListSegmentFiltersGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetSegmentFiltersFieldMaptualListSegmentFiltersGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetSegmentFiltersFieldMaptualListSegmentFiltersGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getTerritoryOverviewDemandData operation in FieldApi.
 * @export
 * @interface FieldApiGetTerritoryOverviewDemandDataRequest
 */
export interface FieldApiGetTerritoryOverviewDemandDataRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewDemandData
     */
    readonly projectId: string

    /**
     * 
     * @type {number}
     * @memberof FieldApiGetTerritoryOverviewDemandData
     */
    readonly regionId: number

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewDemandData
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewDemandData
     */
    readonly metricName: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewDemandData
     */
    readonly cadence: string

    /**
     * 
     * @type {ComparisonTimeFrame}
     * @memberof FieldApiGetTerritoryOverviewDemandData
     */
    readonly comparisonTimeFrame: ComparisonTimeFrame

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewDemandData
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewDemandData
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewDemandData
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewDemandData
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getTerritoryOverviewMetadata operation in FieldApi.
 * @export
 * @interface FieldApiGetTerritoryOverviewMetadataRequest
 */
export interface FieldApiGetTerritoryOverviewMetadataRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewMetadata
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewMetadata
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewMetadata
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewMetadata
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewMetadata
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewMetadata
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getTerritoryOverviewSupplyData operation in FieldApi.
 * @export
 * @interface FieldApiGetTerritoryOverviewSupplyDataRequest
 */
export interface FieldApiGetTerritoryOverviewSupplyDataRequest {
    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewSupplyData
     */
    readonly projectId: string

    /**
     * 
     * @type {number}
     * @memberof FieldApiGetTerritoryOverviewSupplyData
     */
    readonly regionId: number

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewSupplyData
     */
    readonly productName: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewSupplyData
     */
    readonly metricName: string

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewSupplyData
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewSupplyData
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewSupplyData
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof FieldApiGetTerritoryOverviewSupplyData
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * FieldApi - object-oriented interface
 * @export
 * @class FieldApi
 * @extends {BaseAPI}
 */
export class FieldApi extends BaseAPI {
    /**
     * 
     * @summary Returns an object of categorical data for each objective, displayed on the HCP entity page
     * @param {FieldApiGetCategoricalDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getCategoricalData(requestParameters: FieldApiGetCategoricalDataRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getCategoricalData(requestParameters.projectId, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contribution breakdown
     * @param {FieldApiGetContributionBreakdownRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getContributionBreakdown(requestParameters: FieldApiGetContributionBreakdownRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getContributionBreakdown(requestParameters.projectId, requestParameters.objectiveId, requestParameters.regionId, requestParameters.entityId, requestParameters.entityType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of curation entities
     * @param {FieldApiGetCuratedEnitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getCuratedEnities(requestParameters: FieldApiGetCuratedEnitiesRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getCuratedEnities(requestParameters.regionId, requestParameters.objectiveId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns array of customized messages for a given objective and entity combination
     * @param {FieldApiGetCustomizedMessagesFieldEntityMessagesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getCustomizedMessagesFieldEntityMessagesGet(requestParameters: FieldApiGetCustomizedMessagesFieldEntityMessagesGetRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getCustomizedMessagesFieldEntityMessagesGet(requestParameters.productLineId, requestParameters.entityId, requestParameters.objectiveId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of precall insights for multiple entities
     * @param {FieldApiGetEntitiesPrecallInsightsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getEntitiesPrecallInsights(requestParameters: FieldApiGetEntitiesPrecallInsightsRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getEntitiesPrecallInsights(requestParameters.projectId, requestParameters.objectiveId, requestParameters.regionId, requestParameters.entityType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity data
     * @param {FieldApiGetEntityDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getEntityData(requestParameters: FieldApiGetEntityDataRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getEntityData(requestParameters.productLineId, requestParameters.entityId, requestParameters.entityType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity journey
     * @param {FieldApiGetEntityJourneyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getEntityJourney(requestParameters: FieldApiGetEntityJourneyRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getEntityJourney(requestParameters.projectId, requestParameters.regionId, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity overview CSV
     * @param {FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getEntityOverviewCsvFieldEntityOverviewCsvGet(requestParameters: FieldApiGetEntityOverviewCsvFieldEntityOverviewCsvGetRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getEntityOverviewCsvFieldEntityOverviewCsvGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.regionId, requestParameters.entityType, requestParameters.objectiveId, requestParameters.cadence, requestParameters.dates, requestParameters.columns, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity overview CSV metadata
     * @param {FieldApiGetEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGet(requestParameters: FieldApiGetEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGetRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getEntityOverviewCsvMetadataFieldEntityOverviewCsvMetadataGet(requestParameters.projectId, requestParameters.entityType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity overview drawer chart data
     * @param {FieldApiGetEntityOverviewDrawerChartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getEntityOverviewDrawerChart(requestParameters: FieldApiGetEntityOverviewDrawerChartRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getEntityOverviewDrawerChart(requestParameters.productLineId, requestParameters.regionId, requestParameters.entityType, requestParameters.objectiveId, requestParameters.entityId, requestParameters.chartType, requestParameters.metric, requestParameters.cadence, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity overview drawer metadata
     * @param {FieldApiGetEntityOverviewDrawerMetadataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getEntityOverviewDrawerMetadata(requestParameters: FieldApiGetEntityOverviewDrawerMetadataRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getEntityOverviewDrawerMetadata(requestParameters.productLineId, requestParameters.marketId, requestParameters.projectId, requestParameters.regionId, requestParameters.entityType, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity overview drawer profile data
     * @param {FieldApiGetEntityOverviewDrawerProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getEntityOverviewDrawerProfile(requestParameters: FieldApiGetEntityOverviewDrawerProfileRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getEntityOverviewDrawerProfile(requestParameters.marketId, requestParameters.entityType, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity overview drawer timeline data
     * @param {FieldApiGetEntityOverviewDrawerTimelineRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getEntityOverviewDrawerTimeline(requestParameters: FieldApiGetEntityOverviewDrawerTimelineRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getEntityOverviewDrawerTimeline(requestParameters.projectId, requestParameters.regionId, requestParameters.entityType, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity overview table data
     * @param {FieldApiGetEntityOverviewTableDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getEntityOverviewTableData(requestParameters: FieldApiGetEntityOverviewTableDataRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getEntityOverviewTableData(requestParameters.productLineId, requestParameters.regionId, requestParameters.entityType, requestParameters.projectId, requestParameters.objectiveId, requestParameters.cadence, requestParameters.comparisonTimeFrameId, requestParameters.entitySubtype, requestParameters.filters, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity overview metadata
     * @param {FieldApiGetEntityOverviewTableMetadataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getEntityOverviewTableMetadata(requestParameters: FieldApiGetEntityOverviewTableMetadataRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getEntityOverviewTableMetadata(requestParameters.productLineId, requestParameters.projectId, requestParameters.regionId, requestParameters.marketId, requestParameters.entityType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns precall insights for an entity
     * @param {FieldApiGetEntityPrecallInsightsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getEntityPrecallInsights(requestParameters: FieldApiGetEntityPrecallInsightsRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getEntityPrecallInsights(requestParameters.projectId, requestParameters.objectiveId, requestParameters.regionId, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity top attribution data
     * @param {FieldApiGetEntityTopAttributionDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getEntityTopAttributionData(requestParameters: FieldApiGetEntityTopAttributionDataRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getEntityTopAttributionData(requestParameters.projectId, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity views config
     * @param {FieldApiGetEntityViewsConfigFieldEntityViewsConfigGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getEntityViewsConfigFieldEntityViewsConfigGet(requestParameters: FieldApiGetEntityViewsConfigFieldEntityViewsConfigGetRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getEntityViewsConfigFieldEntityViewsConfigGet(requestParameters.marketId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get csv export of maptual list given a region
     * @param {FieldApiGetMaptualListCsvExportFieldMaptualListCsvExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getMaptualListCsvExportFieldMaptualListCsvExportGet(requestParameters: FieldApiGetMaptualListCsvExportFieldMaptualListCsvExportGetRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getMaptualListCsvExportFieldMaptualListCsvExportGet(requestParameters.projectId, requestParameters.regionId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get maptual list metadata, regions and objective data
     * @param {FieldApiGetMaptualListMetadataFieldMaptualListMetadataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getMaptualListMetadataFieldMaptualListMetadataGet(requestParameters: FieldApiGetMaptualListMetadataFieldMaptualListMetadataGetRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getMaptualListMetadataFieldMaptualListMetadataGet(requestParameters.projectId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get maptual list for a given region and objective
     * @param {FieldApiGetMaptualListRegionFieldMaptualListGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getMaptualListRegionFieldMaptualListGet(requestParameters: FieldApiGetMaptualListRegionFieldMaptualListGetRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getMaptualListRegionFieldMaptualListGet(requestParameters.projectId, requestParameters.regionId, requestParameters.objectiveId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get market performance chart data
     * @param {FieldApiGetMarketPerformanceChartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getMarketPerformanceChart(requestParameters: FieldApiGetMarketPerformanceChartRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getMarketPerformanceChart(requestParameters.entityType, requestParameters.projectId, requestParameters.regionId, requestParameters.entityId, requestParameters.metricDataType, requestParameters.metric, requestParameters.objectiveId, requestParameters.cadence, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get market performance chart metadata
     * @param {FieldApiGetMarketPerformanceMetadataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getMarketPerformanceMetadata(requestParameters: FieldApiGetMarketPerformanceMetadataRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getMarketPerformanceMetadata(requestParameters.entityType, requestParameters.objectiveId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get opportunity chart data
     * @param {FieldApiGetOpportunityChartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getOpportunityChart(requestParameters: FieldApiGetOpportunityChartRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getOpportunityChart(requestParameters.entityType, requestParameters.projectId, requestParameters.regionId, requestParameters.entityId, requestParameters.metric, requestParameters.objectiveId, requestParameters.cadence, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get opportunity chart metadata
     * @param {FieldApiGetOpportunityChartMetadataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getOpportunityChartMetadata(requestParameters: FieldApiGetOpportunityChartMetadataRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getOpportunityChartMetadata(requestParameters.productLineId, requestParameters.objectiveId, requestParameters.entityType, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get PowerScore LLM summary
     * @param {FieldApiGetPowerScoreLlmSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getPowerScoreLlmSummary(requestParameters: FieldApiGetPowerScoreLlmSummaryRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getPowerScoreLlmSummary(requestParameters.projectId, requestParameters.objectiveId, requestParameters.regionId, requestParameters.entityType, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get product performance chart data
     * @param {FieldApiGetProductPerformanceChartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getProductPerformanceChart(requestParameters: FieldApiGetProductPerformanceChartRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getProductPerformanceChart(requestParameters.entityType, requestParameters.projectId, requestParameters.regionId, requestParameters.entityId, requestParameters.metricDataType, requestParameters.metric, requestParameters.objectiveId, requestParameters.cadence, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get product performance chart metadata
     * @param {FieldApiGetProductPerformanceMetadataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getProductPerformanceMetadata(requestParameters: FieldApiGetProductPerformanceMetadataRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getProductPerformanceMetadata(requestParameters.entityType, requestParameters.objectiveId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available segment filters for a given region and objective
     * @param {FieldApiGetSegmentFiltersFieldMaptualListSegmentFiltersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getSegmentFiltersFieldMaptualListSegmentFiltersGet(requestParameters: FieldApiGetSegmentFiltersFieldMaptualListSegmentFiltersGetRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getSegmentFiltersFieldMaptualListSegmentFiltersGet(requestParameters.regionId, requestParameters.objectiveId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get territory overview demand data
     * @param {FieldApiGetTerritoryOverviewDemandDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getTerritoryOverviewDemandData(requestParameters: FieldApiGetTerritoryOverviewDemandDataRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getTerritoryOverviewDemandData(requestParameters.projectId, requestParameters.regionId, requestParameters.objectiveId, requestParameters.metricName, requestParameters.cadence, requestParameters.comparisonTimeFrame, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get territory overview metadata
     * @param {FieldApiGetTerritoryOverviewMetadataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getTerritoryOverviewMetadata(requestParameters: FieldApiGetTerritoryOverviewMetadataRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getTerritoryOverviewMetadata(requestParameters.projectId, requestParameters.regionId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get territory overview supply data
     * @param {FieldApiGetTerritoryOverviewSupplyDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getTerritoryOverviewSupplyData(requestParameters: FieldApiGetTerritoryOverviewSupplyDataRequest, options?: RawAxiosRequestConfig) {
        return FieldApiFp(this.configuration).getTerritoryOverviewSupplyData(requestParameters.projectId, requestParameters.regionId, requestParameters.productName, requestParameters.metricName, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a project and associated assets by id.
         * @summary Delete a project by id
         * @param {string} projectId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (projectId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProject', 'projectId', projectId)
            const localVarPath = `/projects/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a project and associated assets by id.
         * @summary Delete a project by id
         * @param {string} projectId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(projectId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(projectId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.deleteProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * Delete a project and associated assets by id.
         * @summary Delete a project by id
         * @param {ProjectsApiDeleteProjectRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(requestParameters: ProjectsApiDeleteProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteProject(requestParameters.projectId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteProject operation in ProjectsApi.
 * @export
 * @interface ProjectsApiDeleteProjectRequest
 */
export interface ProjectsApiDeleteProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiDeleteProject
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiDeleteProject
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiDeleteProject
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiDeleteProject
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiDeleteProject
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * Delete a project and associated assets by id.
     * @summary Delete a project by id
     * @param {ProjectsApiDeleteProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteProject(requestParameters: ProjectsApiDeleteProjectRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).deleteProject(requestParameters.projectId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PulseApi - axios parameter creator
 * @export
 */
export const PulseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get CRM data for the given region
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} regionId 
         * @param {string} cadence 
         * @param {string} timestamp 
         * @param {Array<any>} products 
         * @param {string} entityType 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet: async (productLineId: string, projectId: string, objectiveId: string, regionId: string, cadence: string, timestamp: string, products: Array<any>, entityType: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet', 'productLineId', productLineId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet', 'projectId', projectId)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet', 'objectiveId', objectiveId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet', 'regionId', regionId)
            // verify required parameter 'cadence' is not null or undefined
            assertParamExists('getCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet', 'cadence', cadence)
            // verify required parameter 'timestamp' is not null or undefined
            assertParamExists('getCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet', 'timestamp', timestamp)
            // verify required parameter 'products' is not null or undefined
            assertParamExists('getCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet', 'products', products)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet', 'entityType', entityType)
            const localVarPath = `/pulse/product-lines/{product_line_id}/projects/{project_id}/objectives/{objective_id}/regions/{region_id}`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"objective_id"}}`, encodeURIComponent(String(objectiveId)))
                .replace(`{${"region_id"}}`, encodeURIComponent(String(regionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cadence !== undefined) {
                localVarQueryParameter['cadence'] = cadence;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }

            if (products) {
                localVarQueryParameter['products'] = products;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get District metric data for the given region
         * @param {string} districtId 
         * @param {string} metricType 
         * @param {string} productLineId 
         * @param {string} objectiveId 
         * @param {string} projectId 
         * @param {Array<any>} products 
         * @param {string} cadence 
         * @param {string} timestamp 
         * @param {string} tableName 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet: async (districtId: string, metricType: string, productLineId: string, objectiveId: string, projectId: string, products: Array<any>, cadence: string, timestamp: string, tableName: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'districtId' is not null or undefined
            assertParamExists('getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet', 'districtId', districtId)
            // verify required parameter 'metricType' is not null or undefined
            assertParamExists('getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet', 'metricType', metricType)
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet', 'productLineId', productLineId)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet', 'objectiveId', objectiveId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet', 'projectId', projectId)
            // verify required parameter 'products' is not null or undefined
            assertParamExists('getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet', 'products', products)
            // verify required parameter 'cadence' is not null or undefined
            assertParamExists('getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet', 'cadence', cadence)
            // verify required parameter 'timestamp' is not null or undefined
            assertParamExists('getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet', 'timestamp', timestamp)
            // verify required parameter 'tableName' is not null or undefined
            assertParamExists('getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet', 'tableName', tableName)
            const localVarPath = `/pulse/product-lines/{product_line_id}/projects/{project_id}/objectives/{objective_id}/districts/{district_id}/metrics/{metric_type}`
                .replace(`{${"district_id"}}`, encodeURIComponent(String(districtId)))
                .replace(`{${"metric_type"}}`, encodeURIComponent(String(metricType)))
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)))
                .replace(`{${"objective_id"}}`, encodeURIComponent(String(objectiveId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (products) {
                localVarQueryParameter['products'] = products;
            }

            if (cadence !== undefined) {
                localVarQueryParameter['cadence'] = cadence;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }

            if (tableName !== undefined) {
                localVarQueryParameter['table_name'] = tableName;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get CRM data for the given entity
         * @param {string} productLineId 
         * @param {string} regionId 
         * @param {string} projectId 
         * @param {string} entityId 
         * @param {Array<any>} products 
         * @param {string} [timestamp] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet: async (productLineId: string, regionId: string, projectId: string, entityId: string, products: Array<any>, timestamp?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet', 'productLineId', productLineId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet', 'regionId', regionId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet', 'projectId', projectId)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet', 'entityId', entityId)
            // verify required parameter 'products' is not null or undefined
            assertParamExists('getEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet', 'products', products)
            const localVarPath = `/pulse/crm/product-lines/{product_line_id}/projects/{project_id}/regions/{region_id}/entities/{entity_id}`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)))
                .replace(`{${"region_id"}}`, encodeURIComponent(String(regionId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (products) {
                localVarQueryParameter['products'] = products;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get segments for the given entity
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} regionId 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet: async (productLineId: string, projectId: string, objectiveId: string, regionId: string, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet', 'productLineId', productLineId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet', 'projectId', projectId)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet', 'objectiveId', objectiveId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet', 'regionId', regionId)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet', 'entityId', entityId)
            const localVarPath = `/pulse/segments/product-lines/{product_line_id}/projects/{project_id}/objectives/{objective_id}/regions/{region_id}/entities/{entity_id}`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"objective_id"}}`, encodeURIComponent(String(objectiveId)))
                .replace(`{${"region_id"}}`, encodeURIComponent(String(regionId)))
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get latest CRM & Curation timestamp
         * @param {string} productLineId 
         * @param {string} [regionId] 
         * @param {boolean} [currentList] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestTimestampPulseProductLinesProductLineIdLatestTimestampGet: async (productLineId: string, regionId?: string, currentList?: boolean, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getLatestTimestampPulseProductLinesProductLineIdLatestTimestampGet', 'productLineId', productLineId)
            const localVarPath = `/pulse/product-lines/{product_line_id}/latest-timestamp`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (currentList !== undefined) {
                localVarQueryParameter['current_list'] = currentList;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Metric data for the given hcp
         * @param {string} entityId 
         * @param {string} metricType 
         * @param {string} objectiveId 
         * @param {string} projectId 
         * @param {string} productLineId 
         * @param {Array<any>} products 
         * @param {string} cadence 
         * @param {string} timestamp 
         * @param {string} tableName 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet: async (entityId: string, metricType: string, objectiveId: string, projectId: string, productLineId: string, products: Array<any>, cadence: string, timestamp: string, tableName: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet', 'entityId', entityId)
            // verify required parameter 'metricType' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet', 'metricType', metricType)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet', 'objectiveId', objectiveId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet', 'projectId', projectId)
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet', 'productLineId', productLineId)
            // verify required parameter 'products' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet', 'products', products)
            // verify required parameter 'cadence' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet', 'cadence', cadence)
            // verify required parameter 'timestamp' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet', 'timestamp', timestamp)
            // verify required parameter 'tableName' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet', 'tableName', tableName)
            const localVarPath = `/pulse/product-lines/{product_line_id}/projects/{project_id}/objectives/{objective_id}/entities/{entity_id}/metrics/{metric_type}`
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"metric_type"}}`, encodeURIComponent(String(metricType)))
                .replace(`{${"objective_id"}}`, encodeURIComponent(String(objectiveId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (products) {
                localVarQueryParameter['products'] = products;
            }

            if (cadence !== undefined) {
                localVarQueryParameter['cadence'] = cadence;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }

            if (tableName !== undefined) {
                localVarQueryParameter['table_name'] = tableName;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Metric data for the given region
         * @param {string} regionId 
         * @param {string} productLineId 
         * @param {string} objectiveId 
         * @param {string} projectId 
         * @param {string} metricType 
         * @param {Array<any>} products 
         * @param {string} cadence 
         * @param {string} timestamp 
         * @param {string} tableName 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet: async (regionId: string, productLineId: string, objectiveId: string, projectId: string, metricType: string, products: Array<any>, cadence: string, timestamp: string, tableName: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet', 'regionId', regionId)
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet', 'productLineId', productLineId)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet', 'objectiveId', objectiveId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet', 'projectId', projectId)
            // verify required parameter 'metricType' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet', 'metricType', metricType)
            // verify required parameter 'products' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet', 'products', products)
            // verify required parameter 'cadence' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet', 'cadence', cadence)
            // verify required parameter 'timestamp' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet', 'timestamp', timestamp)
            // verify required parameter 'tableName' is not null or undefined
            assertParamExists('getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet', 'tableName', tableName)
            const localVarPath = `/pulse/product-lines/{product_line_id}/projects/{project_id}/objectives/{objective_id}/regions/{region_id}/metrics/{metric_type}`
                .replace(`{${"region_id"}}`, encodeURIComponent(String(regionId)))
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)))
                .replace(`{${"objective_id"}}`, encodeURIComponent(String(objectiveId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"metric_type"}}`, encodeURIComponent(String(metricType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (products) {
                localVarQueryParameter['products'] = products;
            }

            if (cadence !== undefined) {
                localVarQueryParameter['cadence'] = cadence;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }

            if (tableName !== undefined) {
                localVarQueryParameter['table_name'] = tableName;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get metrics for objective
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} entityType 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet: async (projectId: string, objectiveId: string, entityType: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet', 'projectId', projectId)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet', 'objectiveId', objectiveId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet', 'entityType', entityType)
            const localVarPath = `/pulse/projects/{project_id}/objectives/{objective_id}/metrics`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"objective_id"}}`, encodeURIComponent(String(objectiveId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get regions
         * @param {string} productLineId 
         * @param {Array<any>} [territories] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionsPulseProductLinesProductLineIdRegionsGet: async (productLineId: string, territories?: Array<any>, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getRegionsPulseProductLinesProductLineIdRegionsGet', 'productLineId', productLineId)
            const localVarPath = `/pulse/product-lines/{product_line_id}/regions`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (territories) {
                localVarQueryParameter['territories'] = territories;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get target products for project
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGet: async (productLineId: string, projectId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGet', 'productLineId', productLineId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGet', 'projectId', projectId)
            const localVarPath = `/pulse/product-lines/{product_line_id}/projects/{project_id}/target-products`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PulseApi - functional programming interface
 * @export
 */
export const PulseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PulseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get CRM data for the given region
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} regionId 
         * @param {string} cadence 
         * @param {string} timestamp 
         * @param {Array<any>} products 
         * @param {string} entityType 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet(productLineId: string, projectId: string, objectiveId: string, regionId: string, cadence: string, timestamp: string, products: Array<any>, entityType: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet(productLineId, projectId, objectiveId, regionId, cadence, timestamp, products, entityType, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PulseApi.getCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get District metric data for the given region
         * @param {string} districtId 
         * @param {string} metricType 
         * @param {string} productLineId 
         * @param {string} objectiveId 
         * @param {string} projectId 
         * @param {Array<any>} products 
         * @param {string} cadence 
         * @param {string} timestamp 
         * @param {string} tableName 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet(districtId: string, metricType: string, productLineId: string, objectiveId: string, projectId: string, products: Array<any>, cadence: string, timestamp: string, tableName: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet(districtId, metricType, productLineId, objectiveId, projectId, products, cadence, timestamp, tableName, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PulseApi.getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get CRM data for the given entity
         * @param {string} productLineId 
         * @param {string} regionId 
         * @param {string} projectId 
         * @param {string} entityId 
         * @param {Array<any>} products 
         * @param {string} [timestamp] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet(productLineId: string, regionId: string, projectId: string, entityId: string, products: Array<any>, timestamp?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet(productLineId, regionId, projectId, entityId, products, timestamp, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PulseApi.getEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get segments for the given entity
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} regionId 
         * @param {string} entityId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet(productLineId: string, projectId: string, objectiveId: string, regionId: string, entityId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet(productLineId, projectId, objectiveId, regionId, entityId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PulseApi.getEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get latest CRM & Curation timestamp
         * @param {string} productLineId 
         * @param {string} [regionId] 
         * @param {boolean} [currentList] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestTimestampPulseProductLinesProductLineIdLatestTimestampGet(productLineId: string, regionId?: string, currentList?: boolean, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestTimestampPulseProductLinesProductLineIdLatestTimestampGet(productLineId, regionId, currentList, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PulseApi.getLatestTimestampPulseProductLinesProductLineIdLatestTimestampGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Metric data for the given hcp
         * @param {string} entityId 
         * @param {string} metricType 
         * @param {string} objectiveId 
         * @param {string} projectId 
         * @param {string} productLineId 
         * @param {Array<any>} products 
         * @param {string} cadence 
         * @param {string} timestamp 
         * @param {string} tableName 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet(entityId: string, metricType: string, objectiveId: string, projectId: string, productLineId: string, products: Array<any>, cadence: string, timestamp: string, tableName: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet(entityId, metricType, objectiveId, projectId, productLineId, products, cadence, timestamp, tableName, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PulseApi.getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Metric data for the given region
         * @param {string} regionId 
         * @param {string} productLineId 
         * @param {string} objectiveId 
         * @param {string} projectId 
         * @param {string} metricType 
         * @param {Array<any>} products 
         * @param {string} cadence 
         * @param {string} timestamp 
         * @param {string} tableName 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet(regionId: string, productLineId: string, objectiveId: string, projectId: string, metricType: string, products: Array<any>, cadence: string, timestamp: string, tableName: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet(regionId, productLineId, objectiveId, projectId, metricType, products, cadence, timestamp, tableName, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PulseApi.getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get metrics for objective
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} entityType 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet(projectId: string, objectiveId: string, entityType: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet(projectId, objectiveId, entityType, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PulseApi.getMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get regions
         * @param {string} productLineId 
         * @param {Array<any>} [territories] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegionsPulseProductLinesProductLineIdRegionsGet(productLineId: string, territories?: Array<any>, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegionsPulseProductLinesProductLineIdRegionsGet(productLineId, territories, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PulseApi.getRegionsPulseProductLinesProductLineIdRegionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get target products for project
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGet(productLineId: string, projectId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGet(productLineId, projectId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PulseApi.getTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PulseApi - factory interface
 * @export
 */
export const PulseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PulseApiFp(configuration)
    return {
        /**
         * 
         * @summary Get CRM data for the given region
         * @param {PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet(requestParameters: PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.objectiveId, requestParameters.regionId, requestParameters.cadence, requestParameters.timestamp, requestParameters.products, requestParameters.entityType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get District metric data for the given region
         * @param {PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet(requestParameters: PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet(requestParameters.districtId, requestParameters.metricType, requestParameters.productLineId, requestParameters.objectiveId, requestParameters.projectId, requestParameters.products, requestParameters.cadence, requestParameters.timestamp, requestParameters.tableName, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get CRM data for the given entity
         * @param {PulseApiGetEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet(requestParameters: PulseApiGetEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet(requestParameters.productLineId, requestParameters.regionId, requestParameters.projectId, requestParameters.entityId, requestParameters.products, requestParameters.timestamp, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get segments for the given entity
         * @param {PulseApiGetEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet(requestParameters: PulseApiGetEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.objectiveId, requestParameters.regionId, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get latest CRM & Curation timestamp
         * @param {PulseApiGetLatestTimestampPulseProductLinesProductLineIdLatestTimestampGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestTimestampPulseProductLinesProductLineIdLatestTimestampGet(requestParameters: PulseApiGetLatestTimestampPulseProductLinesProductLineIdLatestTimestampGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getLatestTimestampPulseProductLinesProductLineIdLatestTimestampGet(requestParameters.productLineId, requestParameters.regionId, requestParameters.currentList, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Metric data for the given hcp
         * @param {PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet(requestParameters: PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet(requestParameters.entityId, requestParameters.metricType, requestParameters.objectiveId, requestParameters.projectId, requestParameters.productLineId, requestParameters.products, requestParameters.cadence, requestParameters.timestamp, requestParameters.tableName, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Metric data for the given region
         * @param {PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet(requestParameters: PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet(requestParameters.regionId, requestParameters.productLineId, requestParameters.objectiveId, requestParameters.projectId, requestParameters.metricType, requestParameters.products, requestParameters.cadence, requestParameters.timestamp, requestParameters.tableName, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get metrics for objective
         * @param {PulseApiGetMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet(requestParameters: PulseApiGetMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet(requestParameters.projectId, requestParameters.objectiveId, requestParameters.entityType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get regions
         * @param {PulseApiGetRegionsPulseProductLinesProductLineIdRegionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionsPulseProductLinesProductLineIdRegionsGet(requestParameters: PulseApiGetRegionsPulseProductLinesProductLineIdRegionsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getRegionsPulseProductLinesProductLineIdRegionsGet(requestParameters.productLineId, requestParameters.territories, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get target products for project
         * @param {PulseApiGetTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGet(requestParameters: PulseApiGetTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet operation in PulseApi.
 * @export
 * @interface PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGetRequest
 */
export interface PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet
     */
    readonly cadence: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet
     */
    readonly timestamp: string

    /**
     * 
     * @type {Array<any>}
     * @memberof PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet
     */
    readonly products: Array<any>

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet
     */
    readonly entityType: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet operation in PulseApi.
 * @export
 * @interface PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGetRequest
 */
export interface PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet
     */
    readonly districtId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet
     */
    readonly metricType: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet
     */
    readonly projectId: string

    /**
     * 
     * @type {Array<any>}
     * @memberof PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet
     */
    readonly products: Array<any>

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet
     */
    readonly cadence: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet
     */
    readonly timestamp: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet
     */
    readonly tableName: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet operation in PulseApi.
 * @export
 * @interface PulseApiGetEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGetRequest
 */
export interface PulseApiGetEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly entityId: string

    /**
     * 
     * @type {Array<any>}
     * @memberof PulseApiGetEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly products: Array<any>

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly timestamp?: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet operation in PulseApi.
 * @export
 * @interface PulseApiGetEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGetRequest
 */
export interface PulseApiGetEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly entityId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getLatestTimestampPulseProductLinesProductLineIdLatestTimestampGet operation in PulseApi.
 * @export
 * @interface PulseApiGetLatestTimestampPulseProductLinesProductLineIdLatestTimestampGetRequest
 */
export interface PulseApiGetLatestTimestampPulseProductLinesProductLineIdLatestTimestampGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PulseApiGetLatestTimestampPulseProductLinesProductLineIdLatestTimestampGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetLatestTimestampPulseProductLinesProductLineIdLatestTimestampGet
     */
    readonly regionId?: string

    /**
     * 
     * @type {boolean}
     * @memberof PulseApiGetLatestTimestampPulseProductLinesProductLineIdLatestTimestampGet
     */
    readonly currentList?: boolean

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetLatestTimestampPulseProductLinesProductLineIdLatestTimestampGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetLatestTimestampPulseProductLinesProductLineIdLatestTimestampGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetLatestTimestampPulseProductLinesProductLineIdLatestTimestampGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetLatestTimestampPulseProductLinesProductLineIdLatestTimestampGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet operation in PulseApi.
 * @export
 * @interface PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGetRequest
 */
export interface PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet
     */
    readonly entityId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet
     */
    readonly metricType: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {Array<any>}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet
     */
    readonly products: Array<any>

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet
     */
    readonly cadence: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet
     */
    readonly timestamp: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet
     */
    readonly tableName: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet operation in PulseApi.
 * @export
 * @interface PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGetRequest
 */
export interface PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet
     */
    readonly metricType: string

    /**
     * 
     * @type {Array<any>}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet
     */
    readonly products: Array<any>

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet
     */
    readonly cadence: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet
     */
    readonly timestamp: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet
     */
    readonly tableName: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet operation in PulseApi.
 * @export
 * @interface PulseApiGetMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGetRequest
 */
export interface PulseApiGetMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet
     */
    readonly entityType: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getRegionsPulseProductLinesProductLineIdRegionsGet operation in PulseApi.
 * @export
 * @interface PulseApiGetRegionsPulseProductLinesProductLineIdRegionsGetRequest
 */
export interface PulseApiGetRegionsPulseProductLinesProductLineIdRegionsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PulseApiGetRegionsPulseProductLinesProductLineIdRegionsGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {Array<any>}
     * @memberof PulseApiGetRegionsPulseProductLinesProductLineIdRegionsGet
     */
    readonly territories?: Array<any>

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetRegionsPulseProductLinesProductLineIdRegionsGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetRegionsPulseProductLinesProductLineIdRegionsGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetRegionsPulseProductLinesProductLineIdRegionsGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetRegionsPulseProductLinesProductLineIdRegionsGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGet operation in PulseApi.
 * @export
 * @interface PulseApiGetTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGetRequest
 */
export interface PulseApiGetTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PulseApiGetTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof PulseApiGetTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * PulseApi - object-oriented interface
 * @export
 * @class PulseApi
 * @extends {BaseAPI}
 */
export class PulseApi extends BaseAPI {
    /**
     * 
     * @summary Get CRM data for the given region
     * @param {PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PulseApi
     */
    public getCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet(requestParameters: PulseApiGetCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGetRequest, options?: RawAxiosRequestConfig) {
        return PulseApiFp(this.configuration).getCrmDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.objectiveId, requestParameters.regionId, requestParameters.cadence, requestParameters.timestamp, requestParameters.products, requestParameters.entityType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get District metric data for the given region
     * @param {PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PulseApi
     */
    public getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet(requestParameters: PulseApiGetDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGetRequest, options?: RawAxiosRequestConfig) {
        return PulseApiFp(this.configuration).getDistrictMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdDistrictsDistrictIdMetricsMetricTypeGet(requestParameters.districtId, requestParameters.metricType, requestParameters.productLineId, requestParameters.objectiveId, requestParameters.projectId, requestParameters.products, requestParameters.cadence, requestParameters.timestamp, requestParameters.tableName, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get CRM data for the given entity
     * @param {PulseApiGetEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PulseApi
     */
    public getEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet(requestParameters: PulseApiGetEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGetRequest, options?: RawAxiosRequestConfig) {
        return PulseApiFp(this.configuration).getEntityCrmDataPulseCrmProductLinesProductLineIdProjectsProjectIdRegionsRegionIdEntitiesEntityIdGet(requestParameters.productLineId, requestParameters.regionId, requestParameters.projectId, requestParameters.entityId, requestParameters.products, requestParameters.timestamp, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get segments for the given entity
     * @param {PulseApiGetEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PulseApi
     */
    public getEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet(requestParameters: PulseApiGetEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGetRequest, options?: RawAxiosRequestConfig) {
        return PulseApiFp(this.configuration).getEntitySegmentsPulseSegmentsProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdEntitiesEntityIdGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.objectiveId, requestParameters.regionId, requestParameters.entityId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get latest CRM & Curation timestamp
     * @param {PulseApiGetLatestTimestampPulseProductLinesProductLineIdLatestTimestampGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PulseApi
     */
    public getLatestTimestampPulseProductLinesProductLineIdLatestTimestampGet(requestParameters: PulseApiGetLatestTimestampPulseProductLinesProductLineIdLatestTimestampGetRequest, options?: RawAxiosRequestConfig) {
        return PulseApiFp(this.configuration).getLatestTimestampPulseProductLinesProductLineIdLatestTimestampGet(requestParameters.productLineId, requestParameters.regionId, requestParameters.currentList, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Metric data for the given hcp
     * @param {PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PulseApi
     */
    public getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet(requestParameters: PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGetRequest, options?: RawAxiosRequestConfig) {
        return PulseApiFp(this.configuration).getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdEntitiesEntityIdMetricsMetricTypeGet(requestParameters.entityId, requestParameters.metricType, requestParameters.objectiveId, requestParameters.projectId, requestParameters.productLineId, requestParameters.products, requestParameters.cadence, requestParameters.timestamp, requestParameters.tableName, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Metric data for the given region
     * @param {PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PulseApi
     */
    public getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet(requestParameters: PulseApiGetMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGetRequest, options?: RawAxiosRequestConfig) {
        return PulseApiFp(this.configuration).getMetricDataPulseProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdMetricsMetricTypeGet(requestParameters.regionId, requestParameters.productLineId, requestParameters.objectiveId, requestParameters.projectId, requestParameters.metricType, requestParameters.products, requestParameters.cadence, requestParameters.timestamp, requestParameters.tableName, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get metrics for objective
     * @param {PulseApiGetMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PulseApi
     */
    public getMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet(requestParameters: PulseApiGetMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGetRequest, options?: RawAxiosRequestConfig) {
        return PulseApiFp(this.configuration).getMetricsForObjectivePulseProjectsProjectIdObjectivesObjectiveIdMetricsGet(requestParameters.projectId, requestParameters.objectiveId, requestParameters.entityType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get regions
     * @param {PulseApiGetRegionsPulseProductLinesProductLineIdRegionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PulseApi
     */
    public getRegionsPulseProductLinesProductLineIdRegionsGet(requestParameters: PulseApiGetRegionsPulseProductLinesProductLineIdRegionsGetRequest, options?: RawAxiosRequestConfig) {
        return PulseApiFp(this.configuration).getRegionsPulseProductLinesProductLineIdRegionsGet(requestParameters.productLineId, requestParameters.territories, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get target products for project
     * @param {PulseApiGetTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PulseApi
     */
    public getTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGet(requestParameters: PulseApiGetTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGetRequest, options?: RawAxiosRequestConfig) {
        return PulseApiFp(this.configuration).getTargetProductsPulseProductLinesProductLineIdProjectsProjectIdTargetProductsGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SphereApi - axios parameter creator
 * @export
 */
export const SphereApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get attribution timeseries data
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string} segmentId 
         * @param {string} productLineId 
         * @param {string} attributionType 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet: async (projectId: string, regionId: string, segmentId: string, productLineId: string, attributionType: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet', 'regionId', regionId)
            // verify required parameter 'segmentId' is not null or undefined
            assertParamExists('getAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet', 'segmentId', segmentId)
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet', 'productLineId', productLineId)
            // verify required parameter 'attributionType' is not null or undefined
            assertParamExists('getAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet', 'attributionType', attributionType)
            const localVarPath = `/sphere/product-lines/{product_line_id}/projects/{project_id}/regions/{region_id}/segments/{segment_id}/attribution`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"region_id"}}`, encodeURIComponent(String(regionId)))
                .replace(`{${"segment_id"}}`, encodeURIComponent(String(segmentId)))
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (attributionType !== undefined) {
                localVarQueryParameter['attribution_type'] = attributionType;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dashboard charts
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string} segmentId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet: async (productLineId: string, projectId: string, regionId: string, segmentId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet', 'productLineId', productLineId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet', 'projectId', projectId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet', 'regionId', regionId)
            // verify required parameter 'segmentId' is not null or undefined
            assertParamExists('getDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet', 'segmentId', segmentId)
            const localVarPath = `/sphere/product-lines/{product_line_id}/projects/{project_id}/regions/{region_id}/segments/{segment_id}/dashboard_charts`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"region_id"}}`, encodeURIComponent(String(regionId)))
                .replace(`{${"segment_id"}}`, encodeURIComponent(String(segmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get hcp view table data
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} regionId 
         * @param {string} segmentId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet: async (productLineId: string, projectId: string, objectiveId: string, regionId: string, segmentId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet', 'productLineId', productLineId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet', 'projectId', projectId)
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet', 'objectiveId', objectiveId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet', 'regionId', regionId)
            // verify required parameter 'segmentId' is not null or undefined
            assertParamExists('getHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet', 'segmentId', segmentId)
            const localVarPath = `/sphere/hcp-view/product-lines/{product_line_id}/projects/{project_id}/objectives/{objective_id}/regions/{region_id}/segments/{segment_id}`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"objective_id"}}`, encodeURIComponent(String(objectiveId)))
                .replace(`{${"region_id"}}`, encodeURIComponent(String(regionId)))
                .replace(`{${"segment_id"}}`, encodeURIComponent(String(segmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get segments
         * @param {string} projectId 
         * @param {string} productLineId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGet: async (projectId: string, productLineId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGet', 'projectId', projectId)
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('getSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGet', 'productLineId', productLineId)
            const localVarPath = `/sphere/product-lines/{product_line_id}/projects/{project_id}/segments`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRootSphereGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sphere/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SphereApi - functional programming interface
 * @export
 */
export const SphereApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SphereApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get attribution timeseries data
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string} segmentId 
         * @param {string} productLineId 
         * @param {string} attributionType 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet(projectId: string, regionId: string, segmentId: string, productLineId: string, attributionType: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet(projectId, regionId, segmentId, productLineId, attributionType, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SphereApi.getAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get dashboard charts
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string} regionId 
         * @param {string} segmentId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet(productLineId: string, projectId: string, regionId: string, segmentId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet(productLineId, projectId, regionId, segmentId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SphereApi.getDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get hcp view table data
         * @param {string} productLineId 
         * @param {string} projectId 
         * @param {string} objectiveId 
         * @param {string} regionId 
         * @param {string} segmentId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet(productLineId: string, projectId: string, objectiveId: string, regionId: string, segmentId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet(productLineId, projectId, objectiveId, regionId, segmentId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SphereApi.getHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get segments
         * @param {string} projectId 
         * @param {string} productLineId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGet(projectId: string, productLineId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGet(projectId, productLineId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SphereApi.getSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readRootSphereGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readRootSphereGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SphereApi.readRootSphereGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SphereApi - factory interface
 * @export
 */
export const SphereApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SphereApiFp(configuration)
    return {
        /**
         * 
         * @summary Get attribution timeseries data
         * @param {SphereApiGetAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet(requestParameters: SphereApiGetAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet(requestParameters.projectId, requestParameters.regionId, requestParameters.segmentId, requestParameters.productLineId, requestParameters.attributionType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dashboard charts
         * @param {SphereApiGetDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet(requestParameters: SphereApiGetDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.regionId, requestParameters.segmentId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get hcp view table data
         * @param {SphereApiGetHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet(requestParameters: SphereApiGetHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.objectiveId, requestParameters.regionId, requestParameters.segmentId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get segments
         * @param {SphereApiGetSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGet(requestParameters: SphereApiGetSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGet(requestParameters.projectId, requestParameters.productLineId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRootSphereGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.readRootSphereGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet operation in SphereApi.
 * @export
 * @interface SphereApiGetAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGetRequest
 */
export interface SphereApiGetAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGetRequest {
    /**
     * 
     * @type {string}
     * @memberof SphereApiGetAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet
     */
    readonly segmentId: string

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet
     */
    readonly attributionType: string

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet operation in SphereApi.
 * @export
 * @interface SphereApiGetDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGetRequest
 */
export interface SphereApiGetDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof SphereApiGetDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet
     */
    readonly segmentId: string

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet operation in SphereApi.
 * @export
 * @interface SphereApiGetHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGetRequest
 */
export interface SphereApiGetHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof SphereApiGetHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet
     */
    readonly segmentId: string

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGet operation in SphereApi.
 * @export
 * @interface SphereApiGetSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGetRequest
 */
export interface SphereApiGetSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof SphereApiGetSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGet
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof SphereApiGetSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * SphereApi - object-oriented interface
 * @export
 * @class SphereApi
 * @extends {BaseAPI}
 */
export class SphereApi extends BaseAPI {
    /**
     * 
     * @summary Get attribution timeseries data
     * @param {SphereApiGetAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SphereApi
     */
    public getAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet(requestParameters: SphereApiGetAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGetRequest, options?: RawAxiosRequestConfig) {
        return SphereApiFp(this.configuration).getAttributionDataSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdAttributionGet(requestParameters.projectId, requestParameters.regionId, requestParameters.segmentId, requestParameters.productLineId, requestParameters.attributionType, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dashboard charts
     * @param {SphereApiGetDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SphereApi
     */
    public getDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet(requestParameters: SphereApiGetDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGetRequest, options?: RawAxiosRequestConfig) {
        return SphereApiFp(this.configuration).getDashboardChartsSphereProductLinesProductLineIdProjectsProjectIdRegionsRegionIdSegmentsSegmentIdDashboardChartsGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.regionId, requestParameters.segmentId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get hcp view table data
     * @param {SphereApiGetHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SphereApi
     */
    public getHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet(requestParameters: SphereApiGetHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGetRequest, options?: RawAxiosRequestConfig) {
        return SphereApiFp(this.configuration).getHcpViewDataSphereHcpViewProductLinesProductLineIdProjectsProjectIdObjectivesObjectiveIdRegionsRegionIdSegmentsSegmentIdGet(requestParameters.productLineId, requestParameters.projectId, requestParameters.objectiveId, requestParameters.regionId, requestParameters.segmentId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get segments
     * @param {SphereApiGetSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SphereApi
     */
    public getSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGet(requestParameters: SphereApiGetSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGetRequest, options?: RawAxiosRequestConfig) {
        return SphereApiFp(this.configuration).getSegmentsSphereProductLinesProductLineIdProjectsProjectIdSegmentsGet(requestParameters.projectId, requestParameters.productLineId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SphereApi
     */
    public readRootSphereGet(options?: RawAxiosRequestConfig) {
        return SphereApiFp(this.configuration).readRootSphereGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserManagementApi - axios parameter creator
 * @export
 */
export const UserManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete RBAC cache contents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRbacCacheUsermanagementRbacCacheDelete: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usermanagement/rbac/cache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user
         * @param {string} userId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsermanagementUserUserIdDelete: async (userId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserUsermanagementUserUserIdDelete', 'userId', userId)
            const localVarPath = `/usermanagement/user/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current user
         * @param {string} [userEmail] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (userEmail?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usermanagement/current-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userEmail !== undefined) {
                localVarQueryParameter['user_email'] = userEmail;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current user with required action response
         * @param {string} [userName] 
         * @param {string} [userEmail] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGet: async (userName?: string, userEmail?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usermanagement/current-user/required-action`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userName !== undefined) {
                localVarQueryParameter['user_name'] = userName;
            }

            if (userEmail !== undefined) {
                localVarQueryParameter['user_email'] = userEmail;
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user\'s customer form data
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerDataUsermanagementCustomerFormGet: async (referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usermanagement/customer-form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current user created lists
         * @param {string} userId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGet: async (userId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGet', 'userId', userId)
            const localVarPath = `/usermanagement/user-created-lists/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get permissions for the current user
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsermanagementUserPermissionsGet: async (referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usermanagement/user-permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all users
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUsermanagementUsersGet: async (referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usermanagement/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify user\'s projects
         * @param {ProjectPayload} projectPayload 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserProjectUsermanagementUsersProjectPatch: async (projectPayload: ProjectPayload, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectPayload' is not null or undefined
            assertParamExists('patchUserProjectUsermanagementUsersProjectPatch', 'projectPayload', projectPayload)
            const localVarPath = `/usermanagement/users/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new user
         * @param {UserBody} userBody 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserUsermanagementPost: async (userBody: UserBody, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userBody' is not null or undefined
            assertParamExists('postUserUsermanagementPost', 'userBody', userBody)
            const localVarPath = `/usermanagement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary PUT current user created lists operation
         * @param {string} userId 
         * @param {UserCreatedLists} userCreatedLists 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPut: async (userId: string, userCreatedLists: UserCreatedLists, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('putCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPut', 'userId', userId)
            // verify required parameter 'userCreatedLists' is not null or undefined
            assertParamExists('putCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPut', 'userCreatedLists', userCreatedLists)
            const localVarPath = `/usermanagement/user-created-lists/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreatedLists, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user based on ID
         * @param {string} userId 
         * @param {UserBody} userBody 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserUsermanagementUserUserIdPut: async (userId: string, userBody: UserBody, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('putUserUsermanagementUserUserIdPut', 'userId', userId)
            // verify required parameter 'userBody' is not null or undefined
            assertParamExists('putUserUsermanagementUserUserIdPut', 'userBody', userBody)
            const localVarPath = `/usermanagement/user/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reactivate user
         * @param {string} userId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateUserUsermanagementReactivateUserIdPut: async (userId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('reactivateUserUsermanagementReactivateUserIdPut', 'userId', userId)
            const localVarPath = `/usermanagement/reactivate/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset user\'s password
         * @param {object} body 
         * @param {string} [origin] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPasswordUsermanagementResetPasswordPut: async (body: object, origin?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('resetUserPasswordUsermanagementResetPasswordPut', 'body', body)
            const localVarPath = `/usermanagement/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (origin != null) {
                localVarHeaderParameter['origin'] = String(origin);
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send user\'s invite email
         * @param {object} body 
         * @param {string} [origin] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvitationEmailUsermanagementSendInviteEmailPut: async (body: object, origin?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('sendInvitationEmailUsermanagementSendInviteEmailPut', 'body', body)
            const localVarPath = `/usermanagement/send-invite-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (origin != null) {
                localVarHeaderParameter['origin'] = String(origin);
            }

            if (referer != null) {
                localVarHeaderParameter['referer'] = String(referer);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (usergroup != null) {
                localVarHeaderParameter['usergroup'] = String(usergroup);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserManagementApi - functional programming interface
 * @export
 */
export const UserManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete RBAC cache contents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRbacCacheUsermanagementRbacCacheDelete(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRbacCacheUsermanagementRbacCacheDelete(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserManagementApi.deleteRbacCacheUsermanagementRbacCacheDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete user
         * @param {string} userId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserUsermanagementUserUserIdDelete(userId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserUsermanagementUserUserIdDelete(userId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserManagementApi.deleteUserUsermanagementUserUserIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get current user
         * @param {string} [userEmail] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(userEmail?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(userEmail, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserManagementApi.getCurrentUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get current user with required action response
         * @param {string} [userName] 
         * @param {string} [userEmail] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGet(userName?: string, userEmail?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGet(userName, userEmail, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserManagementApi.getCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get user\'s customer form data
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerDataUsermanagementCustomerFormGet(referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerDataUsermanagementCustomerFormGet(referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserManagementApi.getCustomerDataUsermanagementCustomerFormGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get current user created lists
         * @param {string} userId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGet(userId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGet(userId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserManagementApi.getUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get permissions for the current user
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPermissionsUsermanagementUserPermissionsGet(referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPermissionsUsermanagementUserPermissionsGet(referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserManagementApi.getUserPermissionsUsermanagementUserPermissionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all users
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersUsermanagementUsersGet(referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersUsermanagementUsersGet(referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserManagementApi.getUsersUsermanagementUsersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Modify user\'s projects
         * @param {ProjectPayload} projectPayload 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUserProjectUsermanagementUsersProjectPatch(projectPayload: ProjectPayload, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUserProjectUsermanagementUsersProjectPatch(projectPayload, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserManagementApi.patchUserProjectUsermanagementUsersProjectPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new user
         * @param {UserBody} userBody 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserUsermanagementPost(userBody: UserBody, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserUsermanagementPost(userBody, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserManagementApi.postUserUsermanagementPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary PUT current user created lists operation
         * @param {string} userId 
         * @param {UserCreatedLists} userCreatedLists 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPut(userId: string, userCreatedLists: UserCreatedLists, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPut(userId, userCreatedLists, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserManagementApi.putCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update user based on ID
         * @param {string} userId 
         * @param {UserBody} userBody 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserUsermanagementUserUserIdPut(userId: string, userBody: UserBody, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserUsermanagementUserUserIdPut(userId, userBody, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserManagementApi.putUserUsermanagementUserUserIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reactivate user
         * @param {string} userId 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactivateUserUsermanagementReactivateUserIdPut(userId: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactivateUserUsermanagementReactivateUserIdPut(userId, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserManagementApi.reactivateUserUsermanagementReactivateUserIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reset user\'s password
         * @param {object} body 
         * @param {string} [origin] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetUserPasswordUsermanagementResetPasswordPut(body: object, origin?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetUserPasswordUsermanagementResetPasswordPut(body, origin, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserManagementApi.resetUserPasswordUsermanagementResetPasswordPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send user\'s invite email
         * @param {object} body 
         * @param {string} [origin] 
         * @param {string | null} [referer] 
         * @param {string | null} [authorization] 
         * @param {string | null} [usergroup] 
         * @param {string | null} [userAuthCookieV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInvitationEmailUsermanagementSendInviteEmailPut(body: object, origin?: string, referer?: string | null, authorization?: string | null, usergroup?: string | null, userAuthCookieV2?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInvitationEmailUsermanagementSendInviteEmailPut(body, origin, referer, authorization, usergroup, userAuthCookieV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserManagementApi.sendInvitationEmailUsermanagementSendInviteEmailPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserManagementApi - factory interface
 * @export
 */
export const UserManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserManagementApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete RBAC cache contents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRbacCacheUsermanagementRbacCacheDelete(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteRbacCacheUsermanagementRbacCacheDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user
         * @param {UserManagementApiDeleteUserUsermanagementUserUserIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsermanagementUserUserIdDelete(requestParameters: UserManagementApiDeleteUserUsermanagementUserUserIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.deleteUserUsermanagementUserUserIdDelete(requestParameters.userId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current user
         * @param {UserManagementApiGetCurrentUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(requestParameters: UserManagementApiGetCurrentUserRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getCurrentUser(requestParameters.userEmail, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current user with required action response
         * @param {UserManagementApiGetCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGet(requestParameters: UserManagementApiGetCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGet(requestParameters.userName, requestParameters.userEmail, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user\'s customer form data
         * @param {UserManagementApiGetCustomerDataUsermanagementCustomerFormGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerDataUsermanagementCustomerFormGet(requestParameters: UserManagementApiGetCustomerDataUsermanagementCustomerFormGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getCustomerDataUsermanagementCustomerFormGet(requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current user created lists
         * @param {UserManagementApiGetUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGet(requestParameters: UserManagementApiGetUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGet(requestParameters.userId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get permissions for the current user
         * @param {UserManagementApiGetUserPermissionsUsermanagementUserPermissionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsermanagementUserPermissionsGet(requestParameters: UserManagementApiGetUserPermissionsUsermanagementUserPermissionsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getUserPermissionsUsermanagementUserPermissionsGet(requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all users
         * @param {UserManagementApiGetUsersUsermanagementUsersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUsermanagementUsersGet(requestParameters: UserManagementApiGetUsersUsermanagementUsersGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getUsersUsermanagementUsersGet(requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Modify user\'s projects
         * @param {UserManagementApiPatchUserProjectUsermanagementUsersProjectPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserProjectUsermanagementUsersProjectPatch(requestParameters: UserManagementApiPatchUserProjectUsermanagementUsersProjectPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.patchUserProjectUsermanagementUsersProjectPatch(requestParameters.projectPayload, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new user
         * @param {UserManagementApiPostUserUsermanagementPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserUsermanagementPost(requestParameters: UserManagementApiPostUserUsermanagementPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.postUserUsermanagementPost(requestParameters.userBody, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary PUT current user created lists operation
         * @param {UserManagementApiPutCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPut(requestParameters: UserManagementApiPutCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.putCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPut(requestParameters.userId, requestParameters.userCreatedLists, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user based on ID
         * @param {UserManagementApiPutUserUsermanagementUserUserIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserUsermanagementUserUserIdPut(requestParameters: UserManagementApiPutUserUsermanagementUserUserIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.putUserUsermanagementUserUserIdPut(requestParameters.userId, requestParameters.userBody, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reactivate user
         * @param {UserManagementApiReactivateUserUsermanagementReactivateUserIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateUserUsermanagementReactivateUserIdPut(requestParameters: UserManagementApiReactivateUserUsermanagementReactivateUserIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.reactivateUserUsermanagementReactivateUserIdPut(requestParameters.userId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset user\'s password
         * @param {UserManagementApiResetUserPasswordUsermanagementResetPasswordPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPasswordUsermanagementResetPasswordPut(requestParameters: UserManagementApiResetUserPasswordUsermanagementResetPasswordPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.resetUserPasswordUsermanagementResetPasswordPut(requestParameters.body, requestParameters.origin, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send user\'s invite email
         * @param {UserManagementApiSendInvitationEmailUsermanagementSendInviteEmailPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvitationEmailUsermanagementSendInviteEmailPut(requestParameters: UserManagementApiSendInvitationEmailUsermanagementSendInviteEmailPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.sendInvitationEmailUsermanagementSendInviteEmailPut(requestParameters.body, requestParameters.origin, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteUserUsermanagementUserUserIdDelete operation in UserManagementApi.
 * @export
 * @interface UserManagementApiDeleteUserUsermanagementUserUserIdDeleteRequest
 */
export interface UserManagementApiDeleteUserUsermanagementUserUserIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof UserManagementApiDeleteUserUsermanagementUserUserIdDelete
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiDeleteUserUsermanagementUserUserIdDelete
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiDeleteUserUsermanagementUserUserIdDelete
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiDeleteUserUsermanagementUserUserIdDelete
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiDeleteUserUsermanagementUserUserIdDelete
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getCurrentUser operation in UserManagementApi.
 * @export
 * @interface UserManagementApiGetCurrentUserRequest
 */
export interface UserManagementApiGetCurrentUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetCurrentUser
     */
    readonly userEmail?: string

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetCurrentUser
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetCurrentUser
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetCurrentUser
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetCurrentUser
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGet operation in UserManagementApi.
 * @export
 * @interface UserManagementApiGetCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGetRequest
 */
export interface UserManagementApiGetCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGet
     */
    readonly userName?: string

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGet
     */
    readonly userEmail?: string

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getCustomerDataUsermanagementCustomerFormGet operation in UserManagementApi.
 * @export
 * @interface UserManagementApiGetCustomerDataUsermanagementCustomerFormGetRequest
 */
export interface UserManagementApiGetCustomerDataUsermanagementCustomerFormGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetCustomerDataUsermanagementCustomerFormGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetCustomerDataUsermanagementCustomerFormGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetCustomerDataUsermanagementCustomerFormGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetCustomerDataUsermanagementCustomerFormGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGet operation in UserManagementApi.
 * @export
 * @interface UserManagementApiGetUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGetRequest
 */
export interface UserManagementApiGetUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGet
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getUserPermissionsUsermanagementUserPermissionsGet operation in UserManagementApi.
 * @export
 * @interface UserManagementApiGetUserPermissionsUsermanagementUserPermissionsGetRequest
 */
export interface UserManagementApiGetUserPermissionsUsermanagementUserPermissionsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetUserPermissionsUsermanagementUserPermissionsGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetUserPermissionsUsermanagementUserPermissionsGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetUserPermissionsUsermanagementUserPermissionsGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetUserPermissionsUsermanagementUserPermissionsGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for getUsersUsermanagementUsersGet operation in UserManagementApi.
 * @export
 * @interface UserManagementApiGetUsersUsermanagementUsersGetRequest
 */
export interface UserManagementApiGetUsersUsermanagementUsersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetUsersUsermanagementUsersGet
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetUsersUsermanagementUsersGet
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetUsersUsermanagementUsersGet
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiGetUsersUsermanagementUsersGet
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for patchUserProjectUsermanagementUsersProjectPatch operation in UserManagementApi.
 * @export
 * @interface UserManagementApiPatchUserProjectUsermanagementUsersProjectPatchRequest
 */
export interface UserManagementApiPatchUserProjectUsermanagementUsersProjectPatchRequest {
    /**
     * 
     * @type {ProjectPayload}
     * @memberof UserManagementApiPatchUserProjectUsermanagementUsersProjectPatch
     */
    readonly projectPayload: ProjectPayload

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPatchUserProjectUsermanagementUsersProjectPatch
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPatchUserProjectUsermanagementUsersProjectPatch
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPatchUserProjectUsermanagementUsersProjectPatch
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPatchUserProjectUsermanagementUsersProjectPatch
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for postUserUsermanagementPost operation in UserManagementApi.
 * @export
 * @interface UserManagementApiPostUserUsermanagementPostRequest
 */
export interface UserManagementApiPostUserUsermanagementPostRequest {
    /**
     * 
     * @type {UserBody}
     * @memberof UserManagementApiPostUserUsermanagementPost
     */
    readonly userBody: UserBody

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPostUserUsermanagementPost
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPostUserUsermanagementPost
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPostUserUsermanagementPost
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPostUserUsermanagementPost
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for putCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPut operation in UserManagementApi.
 * @export
 * @interface UserManagementApiPutCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPutRequest
 */
export interface UserManagementApiPutCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPutCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPut
     */
    readonly userId: string

    /**
     * 
     * @type {UserCreatedLists}
     * @memberof UserManagementApiPutCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPut
     */
    readonly userCreatedLists: UserCreatedLists

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPutCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPut
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPutCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPut
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPutCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPut
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPutCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPut
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for putUserUsermanagementUserUserIdPut operation in UserManagementApi.
 * @export
 * @interface UserManagementApiPutUserUsermanagementUserUserIdPutRequest
 */
export interface UserManagementApiPutUserUsermanagementUserUserIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPutUserUsermanagementUserUserIdPut
     */
    readonly userId: string

    /**
     * 
     * @type {UserBody}
     * @memberof UserManagementApiPutUserUsermanagementUserUserIdPut
     */
    readonly userBody: UserBody

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPutUserUsermanagementUserUserIdPut
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPutUserUsermanagementUserUserIdPut
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPutUserUsermanagementUserUserIdPut
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiPutUserUsermanagementUserUserIdPut
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for reactivateUserUsermanagementReactivateUserIdPut operation in UserManagementApi.
 * @export
 * @interface UserManagementApiReactivateUserUsermanagementReactivateUserIdPutRequest
 */
export interface UserManagementApiReactivateUserUsermanagementReactivateUserIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof UserManagementApiReactivateUserUsermanagementReactivateUserIdPut
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiReactivateUserUsermanagementReactivateUserIdPut
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiReactivateUserUsermanagementReactivateUserIdPut
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiReactivateUserUsermanagementReactivateUserIdPut
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiReactivateUserUsermanagementReactivateUserIdPut
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for resetUserPasswordUsermanagementResetPasswordPut operation in UserManagementApi.
 * @export
 * @interface UserManagementApiResetUserPasswordUsermanagementResetPasswordPutRequest
 */
export interface UserManagementApiResetUserPasswordUsermanagementResetPasswordPutRequest {
    /**
     * 
     * @type {object}
     * @memberof UserManagementApiResetUserPasswordUsermanagementResetPasswordPut
     */
    readonly body: object

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiResetUserPasswordUsermanagementResetPasswordPut
     */
    readonly origin?: string

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiResetUserPasswordUsermanagementResetPasswordPut
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiResetUserPasswordUsermanagementResetPasswordPut
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiResetUserPasswordUsermanagementResetPasswordPut
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiResetUserPasswordUsermanagementResetPasswordPut
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * Request parameters for sendInvitationEmailUsermanagementSendInviteEmailPut operation in UserManagementApi.
 * @export
 * @interface UserManagementApiSendInvitationEmailUsermanagementSendInviteEmailPutRequest
 */
export interface UserManagementApiSendInvitationEmailUsermanagementSendInviteEmailPutRequest {
    /**
     * 
     * @type {object}
     * @memberof UserManagementApiSendInvitationEmailUsermanagementSendInviteEmailPut
     */
    readonly body: object

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiSendInvitationEmailUsermanagementSendInviteEmailPut
     */
    readonly origin?: string

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiSendInvitationEmailUsermanagementSendInviteEmailPut
     */
    readonly referer?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiSendInvitationEmailUsermanagementSendInviteEmailPut
     */
    readonly authorization?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiSendInvitationEmailUsermanagementSendInviteEmailPut
     */
    readonly usergroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof UserManagementApiSendInvitationEmailUsermanagementSendInviteEmailPut
     */
    readonly userAuthCookieV2?: string | null
}

/**
 * UserManagementApi - object-oriented interface
 * @export
 * @class UserManagementApi
 * @extends {BaseAPI}
 */
export class UserManagementApi extends BaseAPI {
    /**
     * 
     * @summary Delete RBAC cache contents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public deleteRbacCacheUsermanagementRbacCacheDelete(options?: RawAxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).deleteRbacCacheUsermanagementRbacCacheDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user
     * @param {UserManagementApiDeleteUserUsermanagementUserUserIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public deleteUserUsermanagementUserUserIdDelete(requestParameters: UserManagementApiDeleteUserUsermanagementUserUserIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).deleteUserUsermanagementUserUserIdDelete(requestParameters.userId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current user
     * @param {UserManagementApiGetCurrentUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getCurrentUser(requestParameters: UserManagementApiGetCurrentUserRequest = {}, options?: RawAxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getCurrentUser(requestParameters.userEmail, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current user with required action response
     * @param {UserManagementApiGetCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGet(requestParameters: UserManagementApiGetCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGetRequest = {}, options?: RawAxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getCurrentUserRequiredActionUsermanagementCurrentUserRequiredActionGet(requestParameters.userName, requestParameters.userEmail, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user\'s customer form data
     * @param {UserManagementApiGetCustomerDataUsermanagementCustomerFormGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getCustomerDataUsermanagementCustomerFormGet(requestParameters: UserManagementApiGetCustomerDataUsermanagementCustomerFormGetRequest = {}, options?: RawAxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getCustomerDataUsermanagementCustomerFormGet(requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current user created lists
     * @param {UserManagementApiGetUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGet(requestParameters: UserManagementApiGetUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGetRequest, options?: RawAxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getUserCreatedListsRouteUsermanagementUserCreatedListsUserIdGet(requestParameters.userId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get permissions for the current user
     * @param {UserManagementApiGetUserPermissionsUsermanagementUserPermissionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getUserPermissionsUsermanagementUserPermissionsGet(requestParameters: UserManagementApiGetUserPermissionsUsermanagementUserPermissionsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getUserPermissionsUsermanagementUserPermissionsGet(requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all users
     * @param {UserManagementApiGetUsersUsermanagementUsersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getUsersUsermanagementUsersGet(requestParameters: UserManagementApiGetUsersUsermanagementUsersGetRequest = {}, options?: RawAxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getUsersUsermanagementUsersGet(requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Modify user\'s projects
     * @param {UserManagementApiPatchUserProjectUsermanagementUsersProjectPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public patchUserProjectUsermanagementUsersProjectPatch(requestParameters: UserManagementApiPatchUserProjectUsermanagementUsersProjectPatchRequest, options?: RawAxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).patchUserProjectUsermanagementUsersProjectPatch(requestParameters.projectPayload, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new user
     * @param {UserManagementApiPostUserUsermanagementPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public postUserUsermanagementPost(requestParameters: UserManagementApiPostUserUsermanagementPostRequest, options?: RawAxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).postUserUsermanagementPost(requestParameters.userBody, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary PUT current user created lists operation
     * @param {UserManagementApiPutCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public putCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPut(requestParameters: UserManagementApiPutCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPutRequest, options?: RawAxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).putCurrentUserCreatedListsUsermanagementUserCreatedListsUserIdPut(requestParameters.userId, requestParameters.userCreatedLists, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user based on ID
     * @param {UserManagementApiPutUserUsermanagementUserUserIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public putUserUsermanagementUserUserIdPut(requestParameters: UserManagementApiPutUserUsermanagementUserUserIdPutRequest, options?: RawAxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).putUserUsermanagementUserUserIdPut(requestParameters.userId, requestParameters.userBody, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reactivate user
     * @param {UserManagementApiReactivateUserUsermanagementReactivateUserIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public reactivateUserUsermanagementReactivateUserIdPut(requestParameters: UserManagementApiReactivateUserUsermanagementReactivateUserIdPutRequest, options?: RawAxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).reactivateUserUsermanagementReactivateUserIdPut(requestParameters.userId, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset user\'s password
     * @param {UserManagementApiResetUserPasswordUsermanagementResetPasswordPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public resetUserPasswordUsermanagementResetPasswordPut(requestParameters: UserManagementApiResetUserPasswordUsermanagementResetPasswordPutRequest, options?: RawAxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).resetUserPasswordUsermanagementResetPasswordPut(requestParameters.body, requestParameters.origin, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send user\'s invite email
     * @param {UserManagementApiSendInvitationEmailUsermanagementSendInviteEmailPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public sendInvitationEmailUsermanagementSendInviteEmailPut(requestParameters: UserManagementApiSendInvitationEmailUsermanagementSendInviteEmailPutRequest, options?: RawAxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).sendInvitationEmailUsermanagementSendInviteEmailPut(requestParameters.body, requestParameters.origin, requestParameters.referer, requestParameters.authorization, requestParameters.usergroup, requestParameters.userAuthCookieV2, options).then((request) => request(this.axios, this.basePath));
    }
}



