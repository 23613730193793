import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useTheme } from '@mui/material';
import { useOpportunityChart } from './useOpportunityChart';
import { OpportunityMetadataContextType } from './useOpportunityMetadataContext';
import { ChartDataset, ExtendedChartDataset } from '../../types';

export interface OpportunityChartContextType {
  isDataLoading: boolean;
  isDataError: boolean;
  isDataIdle: boolean;
  refetchData: () => void;
  title: string;
  data: ExtendedChartDataset[];
  setData: Dispatch<SetStateAction<ExtendedChartDataset[]>>;
}

export const useOpportunityChartContext = (
  opportunityMetadataContext: OpportunityMetadataContextType
): OpportunityChartContextType => {
  const {
    projectId,
    regionId,
    objectiveId,
    entityId,
    entityType,
    selectedMetric,
    selectedCadence,
  } = opportunityMetadataContext;

  const {
    isLoading: isDataLoading,
    data: dataFromApi,
    isError: isDataError,
    isIdle: isDataIdle,
    refetch: refetchData,
  } = useOpportunityChart({
    projectId,
    regionId,
    objectiveId,
    entityId,
    entityType,
    metric: selectedMetric,
    cadence: selectedCadence?.id,
  });

  const { themeColors } = useTheme();

  const [data, setData] = useState<ExtendedChartDataset[]>([]);

  const addLineColors = (data: ChartDataset[]): ExtendedChartDataset[] =>
    data.map((lineData) => ({
      ...lineData,
      chartLineColor: themeColors.dataPrecursor3,
    }));

  const title = dataFromApi?.title ?? '';
  const rawData = dataFromApi?.data;

  useEffect(() => {
    if (!isDataLoading && rawData) {
      const dataWithLineColors = addLineColors(rawData);
      setData(dataWithLineColors);
    }
  }, [rawData, isDataLoading]);

  return {
    isDataLoading,
    isDataError,
    isDataIdle,
    refetchData,
    title,
    data,
    setData,
  };
};
