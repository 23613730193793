export const PROJECT_CREATION_STEPS = {
  PROJECT: 'project',
  ASSET_MIGRATION: 'assetMigration',
  CLOSE_PROJECT: 'closeProject',
  OBJECTIVE: 'objective',
  SUMMARY: 'summary',
  CONFIRMATION: 'confirmation',
  SUCCESS: 'success',
  ERROR: 'error',
};
