import { isEqual } from 'lodash';
import { useTheme, styled, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { METRIC_UNIT, TREND_STATUS } from './constants';
import TrendValue from '../trendValues';
import { toCapitalize } from '../../../utils/toCapitalize';
import { formatYAxisValue, isPredictionCadence } from './helpers';
import { Trend } from './types';

export const TOOLTIP_WIDTH = 227;
const TOOLTIP_X_OFFSET = 8;

interface LineValue {
  label: string;
  value: string;
  color: string;
  trend: Trend;
}

export interface TooltipData {
  display: string;
  opacity: number;
  top: 0;
  left: number | string;
  right: number | string;
  lineValues: LineValue[];
  date: string;
}

export const INITIAL_TOOLTIP_STATE: TooltipData = {
  opacity: 0,
  display: 'none',
  top: 0,
  left: 0,
  right: 0,
  lineValues: [],
  date: '',
};

const Wrapper = styled(Box)(({ tooltip, theme: { themeColors } }) => ({
  display: tooltip.display,
  flexDirection: 'column',
  gap: 12,
  top: 0,
  left: tooltip.left,
  right: tooltip.right,
  opacity: tooltip.opacity,
  position: 'absolute',
  pointerEvents: 'none',
  padding: 12,
  zIndex: 10,
  backgroundColor: themeColors.tooltipBackgroundColor,
  borderRadius: 4,
  border: `1px solid ${themeColors.contentCardBorderColor}`,
  textAlign: 'left',
  width: TOOLTIP_WIDTH,
}));

const Title = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
}));

const Text = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
}));

const Body = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 4,
}));

const DataContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: 'fit-content',
  flexWrap: 'nowrap',
  alignItems: 'center',
  gap: 4,
}));

const DataPoint = styled(Box)(({ color }) => ({
  width: 10,
  minWidth: 10,
  height: 10,
  backgroundColor: color,
  borderRadius: '50%',
}));

const buildTooltipData = (
  tooltipModel,
  chartPosition,
  metricDisplayName,
  unit
) => {
  const firstLineData = tooltipModel.dataPoints[0];

  return {
    display: 'flex',
    opacity: 1,
    left:
      tooltipModel.caretX + TOOLTIP_WIDTH < chartPosition.width
        ? tooltipModel.caretX + TOOLTIP_X_OFFSET
        : 'unset',
    right:
      tooltipModel.caretX + TOOLTIP_WIDTH >= chartPosition.width
        ? chartPosition.width - tooltipModel.caretX + TOOLTIP_X_OFFSET
        : 'unset',
    lineValues: tooltipModel.dataPoints.map((dataPoint) => ({
      label: dataPoint.dataset.productName,
      value: `${formatDataPoint(dataPoint.raw, unit)} ${metricDisplayName}`,
      color:
        dataPoint.dataset.pointBackgroundColor ||
        dataPoint.dataset.backgroundColor,
      trend: dataPoint.dataset.trends[dataPoint.dataIndex],
    })),
    date: firstLineData.dataset.timestamps[firstLineData.dataIndex],
  };
};

const formatDataPoint = (
  dataPoint: number,
  unit: METRIC_UNIT
): string | number => {
  if (unit === METRIC_UNIT.RATE) {
    return formatYAxisValue(dataPoint, unit);
  }
  const nthDecimalDigits = 10;
  return (Math.round(dataPoint * nthDecimalDigits) / nthDecimalDigits).toFixed(
    1
  );
};

export const createTooltip = ({
  context,
  currentTooltip,
  updateTooltip,
  metricDisplayName,
  unit,
}) => {
  const { chart, tooltip: tooltipModel } = context;
  if (!chart) return;
  if (tooltipModel.opacity === 0) {
    if (currentTooltip.opacity !== 0) {
      updateTooltip({ ...currentTooltip, opacity: 0, display: 'none' });
    }
    return;
  }

  const chartPosition = context.chart.canvas.getBoundingClientRect();
  const newTooltipData = buildTooltipData(
    tooltipModel,
    chartPosition,
    metricDisplayName,
    unit
  );

  if (!isEqual(currentTooltip, newTooltipData)) {
    updateTooltip(newTooltipData);
  }
};

export const Tooltip = ({
  tooltip,
  metricName,
  selectedCadence,
  selectedCardTab = null,
  showLineValueLabel = true,
}) => {
  const { themeColors, spacing } = useTheme();

  const isLowConfidencePrediction = (trend) =>
    isPredictionCadence(selectedCadence?.id, selectedCardTab) &&
    trend?.status < TREND_STATUS.VALID_METRIC_DATA_STATUS;

  const getDatapointText = (lineValue: LineValue): string => {
    if (isLowConfidencePrediction(lineValue.trend)) {
      return lineValue.label;
    }

    return showLineValueLabel
      ? `${lineValue.label}: ${lineValue.value}`
      : lineValue.value;
  };

  return (
    <Wrapper tooltip={tooltip} data-testid="entity-charts-tooltip">
      <Box display="flex" flexDirection="column" gap={spacing(0.5)}>
        <Title variant="title3">
          {metricName} {toCapitalize(selectedCardTab)}
        </Title>
        <Typography variant="label" color={themeColors.generalSecondaryColor}>
          {tooltip.date}
        </Typography>
      </Box>

      {tooltip?.lineValues?.map((lineValue, index) => (
        <Body key={index}>
          <DataPoint color={lineValue.color} />
          <DataContainer>
            <TrendValue
              type={lineValue.trend?.type}
              status={lineValue.trend?.status}
              value={
                lineValue.trend?.status >= TREND_STATUS.VALID_METRIC_DATA_STATUS
                  ? lineValue.trend?.value
                  : null
              }
              noMargin
              filled
            />
            <Text variant="label" data-testid="entity-charts-tooltip-value">
              {getDatapointText(lineValue)}
            </Text>
          </DataContainer>
        </Body>
      ))}
    </Wrapper>
  );
};
