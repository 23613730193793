import { useContext } from 'react';
import {
  styled,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  DeployFileContext,
  useDeployDialogContext,
} from './deployDialogContext';

export const DEPLOY_CSV_STATES = {
  IDLE: 'idle',
  SELECTION: 'selection',
  CONFIRMATION: 'confirmation',
  IN_PROGRESS: 'in_progress',
  SUCCESS: 'success',
  FAILED: 'failed',
};

export const errorRed = '#FF5959';

export const SubheaderTypography = styled(Typography)({
  marginBottom: 5,
  color: '#E0E4EB',
});

export const BodyTypography = styled(Typography)({
  color: '#8595AD',
});

export const ContentContainer = styled('div')({
  '&.flex': {
    display: 'flex',
  },
  '&.smalldialog': {
    width: 431,
  },
  '&.largedialog': {
    minWidth: 569,
  },
  '&.header': {
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 88,
    padding: 20,
  },
  '&.loadingbody': {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
    height: 324,
  },
  '&.csvselectionblock': {
    padding: 20,
  },
  '&.errorblock': {
    gap: 18,
    marginTop: 26,
  },
  '&.valigntop': {
    alignItems: 'start',
  },
  '&.valigncenter': {
    alignItems: 'center',
  },
});

const noSelect = {
  webkitUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none',
};

export const StyledDialogTitle = styled(DialogTitle)({
  padding: 0,
  color: '#E0E4EB',
  ...noSelect,
  '&.smallmodal': {
    marginBottom: 24,
  },
});

export const StyledDialogContent = styled(DialogContent)({
  padding: 0,
  backgroundColor: '#191C24',
  ...noSelect,
});

export const ColorText = styled('span')(({ color }) => ({
  color,
}));

export const StyledIconButton = styled(IconButton)({
  flex: '0 0',
  padding: 0,
  color: '#8595AD',
});

export const HeaderBar = () => {
  const { setDeployDialogState } = useDeployDialogContext();
  const { setFilename, setNewFilename } = useContext(DeployFileContext);
  return (
    <ContentContainer className="header flex">
      <StyledDialogTitle>
        <Typography variant="h3" component="span">
          Deploy CSV to GSK
        </Typography>
      </StyledDialogTitle>

      <StyledIconButton
        onClick={() => {
          setDeployDialogState(DEPLOY_CSV_STATES.IDLE);
          setFilename('');
          setNewFilename('');
        }}
      >
        <CloseIcon />
      </StyledIconButton>
    </ContentContainer>
  );
};
