import { CADENCES_ENUM, UNITS_ENUM, LINE_CHART_TOP_GAP } from '../../constants';

const dollarFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

const unitFormat = new Intl.NumberFormat('en-US');

export const formatYAxisValue = (value, unit) => {
  if (unit === UNITS_ENUM.dollar) {
    return dollarFormat.format(Math.round(value));
  }
  if (unit === UNITS_ENUM.unit) {
    return unitFormat.format(Math.round(value));
  }
  return value;
};

export const findRowDataInfo = (rowDataEntries, key) => {
  let info;
  // eslint-disable-next-line no-unused-vars
  rowDataEntries.forEach(([_, value]) => {
    if (value?.data && value.data[key]) {
      info = value.data[key];
    }
  });

  return info;
};

export const getYAxisMaxValue = (data) => {
  const allValues = data.datasets.flatMap((dataset) => dataset.data);
  if (allValues.length === 0) {
    return 0;
  }

  return Math.max(...allValues) * LINE_CHART_TOP_GAP;
};

export const getCadences = (metrics) => {
  const combinedList = metrics.reduce(
    (acc, curr) => acc.concat(curr.cadences),
    []
  );

  const cadences = [...new Set(combinedList)];

  const tempCadences = [];
  cadences.forEach((cadenceKey) => {
    const cadenceName = CADENCES_ENUM[cadenceKey];
    tempCadences.push({ id: cadenceKey, name: cadenceName });
  });

  return tempCadences;
};

export const getUnits = (metrics) => {
  const units = [...new Set(metrics.map((metric) => metric.unit))];

  const tempUnits = [];
  units.forEach((unitKey) => {
    const unitName = UNITS_ENUM[unitKey];
    tempUnits.push({ id: unitKey, name: unitName });
  });

  return tempUnits;
};
