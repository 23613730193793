import { useContext } from 'react';
import { useTheme, Typography } from '@mui/material';
import { TREND_STATUS } from '../constants';
import { MarketPerformanceMetadataContext } from './data/marketPerformanceMetadataContext';
import { MarketPerformanceChartContext } from './data/marketPerformanceChartContext';
import { isPredictionCadence } from '../helpers';
import { ChartDataset } from '../types';

export const formatPredictionDisclaimer = (
  datasets: ChartDataset[]
): string | null => {
  const datasetsWithMissingPredictions = datasets.filter((dataset) =>
    dataset.chartValues.some(
      (value) => value.trend.status === TREND_STATUS.CANNOT_PREDICT
    )
  );

  const label = datasetsWithMissingPredictions?.[0]?.label;

  switch (datasetsWithMissingPredictions.length) {
    case 2:
      return `Not enough data to generate predictions.`;
    case 1:
      return `Not enough data to generate predictions for ${label}.`;
    default:
      return null;
  }
};

export const PredictionDisclaimer = () => {
  const { selectedCadence, selectedCardTab } = useContext(
    MarketPerformanceMetadataContext
  );
  const { data, isDataLoading } = useContext(MarketPerformanceChartContext);

  if (
    !data ||
    isDataLoading ||
    !isPredictionCadence(selectedCadence?.id, selectedCardTab)
  ) {
    return null;
  }

  const { themeColors } = useTheme();

  const disclaimer = formatPredictionDisclaimer(data);

  return disclaimer ? (
    <Typography
      variant="caption"
      color={themeColors.tertiaryColor}
      textAlign="left"
      fontStyle="italic"
      data-testid="prediction-disclaimer"
    >
      {disclaimer}
    </Typography>
  ) : null;
};
