import { Button, Typography, styled } from '@mui/material';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';

const SuggestedHcpsButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme: { themeColors }, isActive }) => ({
  minWidth: '176px',
  color: isActive
    ? themeColors.buttonActiveContentColor
    : themeColors.buttonContentColor,
  backgroundColor: isActive
    ? themeColors.buttonActiveBackgroundColor
    : themeColors.inputBackground,
  borderColor: isActive
    ? themeColors.buttonActiveBorderColor
    : themeColors.buttonBorderColor,
  '&:hover': {
    backgroundColor: themeColors.inputBackgroundHover,
  },
}));

const StyledAutoAwesomeOutlinedIcon = styled(AutoAwesomeOutlinedIcon)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.anchorIconsGeneralSecondary,
    height: 20,
    width: 20,
    marginRight: 4,
  })
);

const SuggestedHcpsButtonText = styled(Typography)(() => ({
  fontSize: 16,
  lineHeight: '24px',
}));

interface SuggestedEntitiesButtonProps {
  isActive: boolean;
  handleClick: () => void;
}

export const SuggestedEntitiesButton = ({
  isActive,
  handleClick,
}: SuggestedEntitiesButtonProps) => (
  <SuggestedHcpsButton
    variant="outlined"
    isActive={isActive}
    onClick={() => handleClick()}
  >
    <StyledAutoAwesomeOutlinedIcon />
    <SuggestedHcpsButtonText>Suggested HCPs</SuggestedHcpsButtonText>
  </SuggestedHcpsButton>
);
