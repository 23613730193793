import { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Button, CircularProgress, Stack, styled } from '@mui/material';
import { isEmpty } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { StyledBreadcrumb, StyledButton } from '../styledComponents';
import { JsonInputSection } from '../../configview/jsonInput';
import { validateJson } from '../validateJson';
import { FormGenerator } from '../FormGenerator';
import { translateJsonToSchema } from '../translations';
import { ROUTES } from '../../../constants';
import { formatJsonString } from '../../../utils/formatJsonString';

import { HEADER_HEIGHT } from '../../../containers/application/appViews/constants';

export const CreateSchemaPageWrapper = styled('div')(
  ({ theme: { spacing } }) => ({
    padding: `${spacing(3)} ${spacing(4.5)}`,
    display: 'grid',
    gridTemplateRows: 'auto minmax(0, 1fr)',
    gap: spacing(3),
    height: ` calc(100vh - ${HEADER_HEIGHT})`,
  })
);

export const SchemaFormWrapper = styled('div')(
  ({ theme: { themeColors } }) => ({
    display: 'flex',
    padding: 24,
    border: `2px solid ${themeColors.activeForm.border}`,
    borderRadius: 8,
    gap: 36,
  })
);

const SubHeader = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
  textAlign: 'left',
}));

type SchemaDefinitionFormProps = {
  schemaMetadata: { name: string; description: string };
  initialJson: string;
  initialSchema: string;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  onSave: (schemaName: string, schema: any, values: any) => Promise<void>;
};
export const SchemaDefinitionForm = ({
  schemaMetadata,
  initialJson,
  initialSchema,
  isLoading,
  isSuccess,
  isError,
  onSave,
}: SchemaDefinitionFormProps) => {
  const history = useHistory();

  const [jsonData, setJsonData] = useState<string>(
    formatJsonString(initialJson)
  );
  const [jsonError, setJsonError] = useState(null);

  const [schemaData, setSchemaData] = useState<string>(
    formatJsonString(initialSchema)
  );
  const [schemaError, setSchemaError] = useState(null);

  const [parsedSchema, setParsedSchema] =
    useState<Record<string, unknown>>(null);
  const [parsedJsonData, setParsedJsonData] =
    useState<Record<string, unknown>>(null);

  const [formData, setFormData] = useState<Record<string, 'unknown'> | null>(
    null
  );

  const [formError, setFormError] = useState(null);

  useEffect(() => {
    if (initialJson && initialSchema) {
      setParsedJsonData(JSON.parse(initialJson));
      handleGenerateForm(initialSchema);
      setFormData(JSON.parse(initialJson));
    }
  }, [initialJson, initialSchema]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        history.push(`${ROUTES.ADMIN}${ROUTES.APP_CONFIG}`);
      }, 3000);
    }
  }, [isSuccess]);

  const saveButtonContents = () => {
    if (isLoading) {
      return (
        <>
          <span>Saving...</span>
          <span>
            <CircularProgress size={12} />
          </span>
        </>
      );
    }
    if (isError) {
      return 'Error saving';
    }
    if (isSuccess) {
      return 'Saved';
    }
    return 'Save Config & Schema';
  };
  const handleJsonDataChange = (
    e: ChangeEvent<HTMLTextAreaElement> | FocusEvent<HTMLTextAreaElement>
  ) => {
    const isBlurEvent = e.type === 'blur';
    const data = e.target.value;
    const validated = validateJson(data);
    const isValidJson = validated === true;
    if (!isValidJson) {
      setJsonError(validated);
    } else {
      setJsonError(null);
    }
    setJsonData(isBlurEvent ? formatJsonString(data) : data);
  };

  const handleSchemaDataChange = (
    e: ChangeEvent<HTMLTextAreaElement> | FocusEvent<HTMLTextAreaElement>
  ) => {
    const isBlurEvent = e.type === 'blur';
    const data = e.target.value;
    const validated = validateJson(data);
    setSchemaData(isBlurEvent ? formatJsonString(data) : data);
    if (!(validated === true)) {
      setSchemaError(validated);
    } else {
      setSchemaError(null);
      handleGenerateForm(data);
    }
  };

  const handleGenerateSchema = () => {
    const translatedSchema = translateJsonToSchema(
      schemaMetadata.name,
      schemaMetadata.description,
      JSON.parse(jsonData)
    );
    const stringifiedSchema = JSON.stringify(translatedSchema);
    setSchemaData(formatJsonString(stringifiedSchema));
    setParsedJsonData(JSON.parse(jsonData));
    setFormData(JSON.parse(jsonData));
    handleGenerateForm(stringifiedSchema);
  };

  const handleGenerateForm = (schemaData: string) => {
    setFormError(null);
    setSchemaError(null);
    setParsedSchema(JSON.parse(schemaData));
  };

  const handleExitForm = () => {
    history.push(`${ROUTES.ADMIN}${ROUTES.APP_CONFIG}`);
  };

  return (
    <CreateSchemaPageWrapper>
      <StyledBreadcrumb>
        <Link to={`${ROUTES.ADMIN}${ROUTES.APP_CONFIG}`}>
          Application Configurations
        </Link>
        <a>{schemaMetadata.name}</a>
      </StyledBreadcrumb>
      <SchemaFormWrapper>
        <Stack flexDirection="column" gap={3} style={{ width: '40%' }}>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Stack alignItems="flex-start" gap={0.5}>
                <Typography variant="h5">JSON Data</Typography>
                <SubHeader>Paste JSON config to generate form. </SubHeader>
              </Stack>
              <Button
                variant="outlined"
                onClick={handleGenerateSchema}
                disabled={!!jsonError}
              >
                Generate Schema & Form
              </Button>
            </Stack>
            <JsonInputSection
              value={jsonData}
              useHeader={false}
              onBlur={handleJsonDataChange}
              error={jsonError !== null}
              helperText={jsonError}
              onChange={handleJsonDataChange}
              styleOverrides={{
                minHeight: 'unset',
                ...(isEmpty(parsedSchema)
                  ? {
                      border: `2px solid #336FCC`,
                    }
                  : {}),
              }}
              header={undefined}
              subheader={undefined}
              footerText={undefined}
            />
          </div>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Stack alignItems="flex-start" gap={0.5}>
                <Typography variant="h5">Schema</Typography>
                <SubHeader>
                  Model used to generate form, add titles and descriptions.
                  Ensure you set default values config via the form.
                </SubHeader>
              </Stack>
            </Stack>
            <JsonInputSection
              value={schemaData}
              useHeader={false}
              onBlur={handleSchemaDataChange}
              onChange={handleSchemaDataChange}
              error={schemaError !== null}
              helperText={schemaError}
              footerText="Review both schema and form after editing."
              styleOverrides={{ minHeight: 'unset' }}
            />
          </div>
        </Stack>

        <Stack style={{ width: '60%', overflow: 'scroll' }}>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button variant="outlined" onClick={handleExitForm}>
              Cancel
            </Button>
            <StyledButton
              variant="contained"
              disabled={
                (parsedSchema === null || parsedJsonData === null) &&
                !formError &&
                !schemaError
              }
              onClick={() =>
                onSave(schemaMetadata.name, parsedSchema, formData)
              }
            >
              {saveButtonContents()}
            </StyledButton>
          </Stack>
          {schemaError === null &&
          parsedJsonData !== null &&
          parsedSchema !== null &&
          !formError ? (
            <FormGenerator
              schema={parsedSchema}
              formData={formData}
              setFormData={setFormData}
              setFormError={setFormError}
            />
          ) : (
            <ErrorSchemaForm formError={!!formError || !!schemaError} />
          )}
        </Stack>
      </SchemaFormWrapper>
    </CreateSchemaPageWrapper>
  );
};
const EmptyDisplay = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 4,
});

const NoFormText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
}));

const ErrorSchemaForm = ({ formError }) => {
  if (formError) {
    return (
      <EmptyDisplay>
        <NoFormText variant="title3">Unable to load form</NoFormText>
        <NoFormText variant="subtitle1">
          Changes to the JSON and/or schema caused an error. Please check inputs
          and try again.
        </NoFormText>
      </EmptyDisplay>
    );
  }
  return (
    <EmptyDisplay>
      <NoFormText variant="title3">
        Paste JSON config to generate form.
      </NoFormText>
      <NoFormText variant="subtitle1">
        Remember to add default values to fields in the form before saving.
      </NoFormText>
    </EmptyDisplay>
  );
};
