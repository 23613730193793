import Typography from '@mui/material/Typography';
import { Popover, Button, styled } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { trackTooltipSelection } from '../../../trackers/mixpanel';
import { selectMaptualListSegmentName } from '../../../selectors/project';
import { FieldContext } from '../../../containers/application/appViews/field/fieldContext';

const StyledPopover = styled(Popover)(() => ({
  marginTop: 4,
}));

const StyledTypography = styled(Typography)(() => ({
  display: 'inline',
  overflowWrap: 'break-word',
}));

const PopoverBody = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gridGap: 16,
  flexGrow: 1,
  padding: 19,
}));

const OpenTimelinePopoverButton = styled(Button)(
  ({ theme: { themeColors } }) => ({
    padding: 0,
    minWidth: 0,
    background: 'transparent',
    textDecoration: 'underline',
    textTransform: 'none',
    border: 'none',
    color: themeColors.primaryMaptualListFilteringColor,
    '&:hover': {
      textDecoration: 'underline',
    },
  })
);

const SegmentDescriptionPopover = ({
  buttonText = '',
  title = '',
  description = '',
}) => {
  const { themeColors } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'simple-popover' : undefined;
  const { maptualList } = useContext(FieldContext);
  const maptualListName = maptualList?.metadata?.listName;
  const segment = useSelector(selectMaptualListSegmentName);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    trackTooltipSelection(
      maptualListName,
      'Segment Description',
      segment,
      title
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <OpenTimelinePopoverButton onClick={handleClick} variant="text">
        <StyledTypography variant="body2">{buttonText}</StyledTypography>
      </OpenTimelinePopoverButton>
      <StyledPopover
        id={popoverId}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { maxWidth: '262px' },
        }}
      >
        <PopoverBody>
          <Typography
            variant="title2"
            color={themeColors.secondaryMaptualListFilteringColor}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            color={themeColors.primaryMaptualListFilteringColor}
          >
            {description}
          </Typography>
        </PopoverBody>
      </StyledPopover>
    </>
  );
};

export default SegmentDescriptionPopover;
