import { Button, MenuItem, Stack, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MouseEventHandler, ReactNode } from 'react';
import { Control } from 'react-hook-form';
import { InstanceList } from './InstanceList';
import { FormTextField } from '../../../configview/components/form/FormTextField';
import { FormData } from './useCreateInstancesSection';

type ConfigInstancesProps = {
  control: Control<FormData, any>;
  name: 'userGroup' | 'marketId';
  label: string;
  options: ReactNode[];
  userGroup: string;
  disabledDropdown: boolean;
  selectedMarket?: { label: string; value: string };
  handleClickDropdownOption: MouseEventHandler<HTMLButtonElement>;
  helperText: string;
  instances: string[];
  isLoading: boolean;
  showEmptyOption?: boolean;
};
export function ConfigInstances({
  control,
  name,
  label,
  options,
  userGroup,
  disabledDropdown,
  handleClickDropdownOption,
  helperText,
  instances,
  isLoading,
  selectedMarket,
  showEmptyOption = false,
}: ConfigInstancesProps) {
  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <FormTextField
          control={control}
          select
          name={name}
          label={label}
          size="small"
          sx={{
            width: '220px',
          }}
        >
          {showEmptyOption && <MenuItem value="">N/A</MenuItem>}
          {options}
        </FormTextField>
        <Button
          disabled={disabledDropdown}
          variant="outlined"
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleClickDropdownOption}
        >
          Add Config Instance
        </Button>
      </Stack>

      <Typography
        color={(theme) => theme.themeColors.neutral60}
        variant="body2"
      >
        {helperText}
      </Typography>
      <InstanceList
        instances={instances}
        userGroup={userGroup}
        isLoading={isLoading}
        selectedMarket={selectedMarket}
      />
    </Stack>
  );
}
