const EM_DASH = '—';

export enum GRAPH_VALUE_TYPE {
  PERCENT = 'percent',
  PERCENT_NOT_MULTIPLIED = 'percentNotMultiplied',
  PERCENT_NO_SYMBOL = 'percentNoSymbol',
  RATE = 'rate',
  DOLLAR = 'dollar',
  DOLLARS = 'dollars',
  NUMBER = 'number',
  UNIT = 'unit',
}
export const formatCellValue = (
  value: number,
  type: GRAPH_VALUE_TYPE,
  decimalPlaces: number = 0
) => {
  const dollarFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  const multiplier = decimalPlaces ? 10 ** decimalPlaces : 1;

  switch (type) {
    case GRAPH_VALUE_TYPE.PERCENT:
      return `${Math.round(value * 100 * multiplier) / multiplier}%`;
    case GRAPH_VALUE_TYPE.PERCENT_NOT_MULTIPLIED:
    case GRAPH_VALUE_TYPE.RATE:
      return `${Math.round(value * multiplier) / multiplier}%`;
    case GRAPH_VALUE_TYPE.PERCENT_NO_SYMBOL:
      return Math.round(value * 100 * multiplier) / multiplier;
    case GRAPH_VALUE_TYPE.DOLLAR:
    case GRAPH_VALUE_TYPE.DOLLARS:
      return dollarFormat.format(Math.round(value));
    case GRAPH_VALUE_TYPE.NUMBER:
    case GRAPH_VALUE_TYPE.UNIT:
      return Math.round(value * multiplier) / multiplier;
    default:
      return value;
  }
};

export const formatModalValue = (value, modalType) => {
  switch (modalType) {
    case 'percent':
      return Math.round(value * 100);
    case 'percentNotMultiplied':
    case 'number':
    case 'unit':
    case 'dollar':
    case 'dollars':
      return Math.round(value);
    default:
      return value;
  }
};

export const getCellValue = (rowData, type) => {
  if (!rowData || rowData.length === 0) return EM_DASH;

  const allValuesNull = rowData.every((value) => value === null);
  if (allValuesNull) {
    return EM_DASH;
  }

  const value = Number(rowData[rowData.length - 1]);

  return formatCellValue(value, type);
};

const MAX_Y_AXIS_LABEL_LENGTH = 18;

export const formatYAxisTitle = (title: string): string =>
  !!title && title.length > MAX_Y_AXIS_LABEL_LENGTH
    ? `${title.substring(0, MAX_Y_AXIS_LABEL_LENGTH)}…`
    : title;
