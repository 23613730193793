import {
  FormControl,
  MenuItem,
  Checkbox,
  Chip,
  InputLabel,
  Select,
  Box,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DropdownWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const StyledFormControl = styled(FormControl)({
  width: '60%',
});

const StyledInputLabel = styled(InputLabel)(({ theme: { themeColors } }) => ({
  '&.Mui-focused': { color: themeColors.neutral60 },
}));

const Placeholder = styled(InputLabel)(({ theme: { themeColors } }) => ({
  '&.Mui-focused': { color: themeColors.primary99 },
}));

const StyledSelect = styled(Select)(({ theme: { themeColors } }) => ({
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: 1,
    borderColor: themeColors.neutral60,
  },
}));

const StyledCheckbox = styled(Checkbox)({
  padding: 3,
});

const ChipRow = styled(Box)({
  display: 'flex',
  gap: 8,
  flexWrap: 'wrap',
});

const StyledChip = styled(Chip)(({ theme: { themeColors } }) => ({
  fontSize: 14,
  borderColor: themeColors.borderMidContrast,
  color: themeColors.primary99,
  '.MuiChip-deleteIcon': {
    color: themeColors.neutral60,
  },
}));

export function DropdownWithChips({
  items,
  selectedItems,
  setSelectedItems,
  inputLabel,
  placeholder,
  disabled = false,
}) {
  const handleOnChange = (event) => {
    const value = event.target.value[0];
    const newItems = selectedItems.includes(value)
      ? selectedItems.filter((item) => item !== value)
      : [...selectedItems, value];

    setSelectedItems(newItems);
  };

  const handleDeleteItem = (item) => {
    const newItems = selectedItems.filter(
      (selectedItem) => selectedItem !== item
    );
    setSelectedItems(newItems);
  };

  return (
    <DropdownWrapper>
      <StyledFormControl focused disabled={disabled}>
        {placeholder && !disabled && (
          <StyledInputLabel shrink>{placeholder}</StyledInputLabel>
        )}
        <Placeholder shrink={false}>{inputLabel}</Placeholder>
        <StyledSelect
          multiple
          value={[]}
          onChange={handleOnChange}
          label={placeholder}
        >
          {items?.length > 0 ? (
            items.map((item) => (
              <MenuItem key={item} value={item}>
                <StyledCheckbox checked={selectedItems.includes(item)} />
                {item.toUpperCase()}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No options</MenuItem>
          )}
        </StyledSelect>
      </StyledFormControl>
      {selectedItems.length > 0 && (
        <ChipRow>
          {selectedItems.map((item) => (
            <StyledChip
              key={item}
              value={item}
              variant="outlined"
              label={item.toUpperCase()}
              deleteIcon={<CloseIcon />}
              onMouseDown={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
              onDelete={() => handleDeleteItem(item)}
            />
          ))}
        </ChipRow>
      )}
    </DropdownWrapper>
  );
}
