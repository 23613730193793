import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ComponentProps } from 'react';
import { StyledTextField } from '../../styledComponents';

type FormTextFieldProps<TFieldValues> = Pick<
  ComponentProps<typeof Controller<TFieldValues>>,
  'name' | 'control' | 'rules'
> &
  ComponentProps<typeof TextField>;
export const FormTextField = <TFieldValues,>({
  name,
  control,
  rules,
  ...rest
}: FormTextFieldProps<TFieldValues>) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field, fieldState }) => (
      <TextField {...field} error={fieldState.invalid} {...rest} />
    )}
  />
);

export const StyledFormTextField = <TFieldValues,>({
  name,
  control,
  rules,
  ...rest
}: FormTextFieldProps<TFieldValues>) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field, fieldState }) => (
      <StyledTextField {...field} error={fieldState.invalid} {...rest} />
    )}
  />
);
