import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  changeChip: {
    height: 18,
    padding: "1px 5px",
    borderRadius: 16,
    display: 'flex',
    alignItems: 'center',
  },
  changeText: {
    width: '100%',
    fontSize: 14,
    fontWeight: 600,
    color: themeColors.increaseColorChipContrasting,
  },
}));

const StyledTypography = styled(Typography)(({ theme : { themeColors }}) => ({
  width: '100%',
  fontSize: 14,
  fontWeight: 600,
  color: themeColors.increaseColorChipContrasting,
}))

interface ChangeChipProps {
  change: number;
  selected?: boolean;
  simple?: boolean;
}

export default function ChangeChip({
  change = 0,
  selected = false,
  simple = false,
}: ChangeChipProps) {
  const classes = useStyles();
  const { themeColors } = useTheme();

  const IncreasingChipChange = () => (
    <div
      className={classes.changeChip}
      style={{
        backgroundColor: selected
          ? themeColors.increaseColorMain
          : themeColors.increaseColorMainDefault,
      }}
    >
      <StyledTypography
        noWrap
        align="center"
        style={{
          color: selected
            ? themeColors.increaseColorMainContent
            : themeColors.increaseColorMainDefaultContent,
        }}
      >
        {`+${change}`}
      </StyledTypography>
    </div>
  );

  const DecreasingChipChange = () => (
    <div
      className={classes.changeChip}
      style={{
        backgroundColor: selected
          ? themeColors.decreaseColorMain
          : themeColors.decreaseColorMainDefault,
      }}
    >
      <StyledTypography
        noWrap
        align="center"
        style={{
          color: selected
            ? themeColors.decreaseColorMainContent
            : themeColors.decreaseColorMainDefaultContent,
        }}
      >
        {change}
      </StyledTypography>
    </div>
  );

  return (
    <div style={{ display: 'flex' }}>
      {change !== 0 && (
        <div className={classes.root}>
          {change > 0 ? <IncreasingChipChange /> : <DecreasingChipChange />}
        </div>
      )}
      {change === 0 && !simple && <div style={{ flexGrow: 1 }}>&nbsp;</div>}
    </div>
  );
}