import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getCustomerProductLines,
  updateCustomerSegment,
} from '../../request/configViewRequests';
import { SegmentEdit } from './segmentEdit';
import { ConfigViewContext } from './configViewContext';
import { CONFIG_ALERT_STATUSES } from './configAlertStatuses';

export function SegmentEditProvider({
  selectedSegment,
  setEditView,
  segmentOrders,
}) {
  const { showConfigAlert } = useContext(ConfigViewContext);
  const queryClient = useQueryClient();

  const { data: productLines, isLoading: productLinesLoading } = useQuery(
    'productLines',
    async () => {
      const response = await getCustomerProductLines();
      return response.data;
    }
  );
  const { mutate: onSaveSegment, isLoading: segmentIsSaving } = useMutation(
    ({ segmentId, requestBody }) =>
      updateCustomerSegment(segmentId, requestBody),
    {
      onSuccess: () => {
        setEditView(false);
        queryClient.invalidateQueries('segments');
        showConfigAlert(
          CONFIG_ALERT_STATUSES.SUCCESS,
          `Saved changes to ${selectedSegment.primaryText}`
        );
      },
      onError: () => {
        showConfigAlert(CONFIG_ALERT_STATUSES.ERROR);
      },
    }
  );

  if (!productLinesLoading && productLines) {
    return (
      <SegmentEdit
        selectedSegment={selectedSegment}
        setEditView={setEditView}
        productLines={productLines}
        onSaveSegment={onSaveSegment}
        segmentOrders={segmentOrders}
        segmentIsSaving={segmentIsSaving}
      />
    );
  }
  return null;
}
