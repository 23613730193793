import { ComponentProps } from 'react';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export const LoadingContainer = (
  overrides?: Partial<ComponentProps<typeof Box>>['sx']
) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...overrides,
    }}
  >
    <CircularProgress />
  </Box>
);
