import { useEffect, useState } from 'react';
import { styled } from '@mui/material';

const NavigationRoot = styled('div', {
  shouldForwardProp: (prop) =>
    !['navigationOpenChanged', 'navigationOpen'].includes(prop),
})(({ theme: { themeColors }, navigationOpenChanged, navigationOpen }) => ({
  height: '100%',
  maxWidth: '375px',
  width: '375px',
  marginLeft: '-375px',
  display: 'flex',
  flexDirection: 'column',
  borderRight: `1px solid ${themeColors.borderLowContrast}`,
  backgroundColor: themeColors.mainBackground,
  ...(navigationOpenChanged && {
    animation: navigationOpen ? '0.5s slide' : '0.5s slideOut',
  }),
  animationFillMode: 'forwards',
  '@keyframes slide': {
    from: { marginLeft: '-375px' },
    to: { marginLeft: '0px' },
  },
  '@keyframes slideOut': {
    from: { marginLeft: '0px' },
    to: { marginLeft: '-375px' },
  },
}));

export const NavigationContainer = ({ navigationOpen, children }) => {
  const [prevNavigationOpen, setPrevNavigationOpen] = useState(false);
  const [navigationOpenChanged, setNavigationOpenChanged] = useState(false);

  useEffect(() => {
    if (!!navigationOpen !== !!prevNavigationOpen) {
      setNavigationOpenChanged(true);
      setPrevNavigationOpen(!!navigationOpen);
    } else {
      setNavigationOpenChanged(false);
    }
  }, [navigationOpen]);

  return (
    <NavigationRoot
      navigationOpen={navigationOpen}
      navigationOpenChanged={navigationOpenChanged}
    >
      {children}
    </NavigationRoot>
  );
};
