import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

const TileContainer = styled('li')(({ theme: { themeColors } }) => ({
  padding: '16px',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  background: themeColors.objectiveTypeSaveButtonDisabledColor,
  border: `1px solid ${themeColors.projectCardBorder}`,
  alignItems: 'center',
  'not(:first-of-type)': {
    borderTop: 'none',
  },
}));

const MetadataContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
}));

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '24px',
  alignItems: 'center',
}));

const Name = styled('div')(({ theme: { themeColors } }) => ({
  fontSize: '14px',
  fontWeight: '500',
  color: themeColors.neutral90,
  textAlign: 'left',
}));

const Description = styled('div')(({ theme: { themeColors } }) => ({
  fontSize: '14px',
  fontWeight: '400',
  color: themeColors.graphAxisColor,
  textAlign: 'left',
}));

type ActionableDataListItemProps = {
  name: string;
  description: string;
  actions: {
    handler: () => void;
    text: string;
  }[];
};

export const ActionableDataListItem = ({
  name,
  description,
  actions,
}: ActionableDataListItemProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TileContainer>
      <Container>
        <MetadataContainer>
          <Name>{name}</Name>
          <Description>{description}</Description>
        </MetadataContainer>
      </Container>
      <>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {actions.map(({ handler, text }) => (
            <MenuItem
              key={text}
              onClick={() => {
                handler();
                handleClose();
              }}
            >
              {text}
            </MenuItem>
          ))}
        </Menu>
      </>
    </TileContainer>
  );
};
