/* eslint-disable no-restricted-syntax */
import { getDemoAccountStatus } from '../request/config';
import Logger from './logger';
import { toCapitalize } from '../utils/toCapitalize';
import {
  anonymizeEntityNameOnGetMaptualList,
  anonymizeSegmentsOnGetMaptualList,
} from '../middlewares/anonymize/getMaptualList';

const censorMap = {
  rheumatology: 'Specialty 1',
  dermatology: 'Specialty 2',
  rhe: 'Spc',
  myrbetriq: 'Target Product',
  xtandi: 'Target Product',
  'simponi sc': 'Target Product',
  'simponi ra': 'Target Product',
  'simponi spa': 'Target Product',
  simponi: 'Target Product',
  tremfya: 'Target Product',
  'tremfya pso': 'Target Product',
  'tremfya psa': 'Target Product',
  'one-press': 'Injectable',
  'SOMATULINE DEPOT': 'Target Product',
  MYCAPSSA: 'Target Product',
  'IL-17': 'Competitor Class A',
  bioadvance: 'PSP',
  saxenda: 'Target Product',
  contrave: 'Competitor A',
  xenical: 'Competitor B',

  janssen: 'Demo Customer A',
  ipsen: 'Demo Customer B',
  janssendev4: 'Demo Customer A',
  janssendev4_ser: 'Demo Customer A',
  ipsendev: 'Demo Customer B',

  humira: 'Competitor A',
  cosentyx: 'Competitor C',
  brenzys: 'Competitor D',
  orencia: 'Competitor E',
  enbrel: 'Competitor F',
  'xeljanz xr': 'Competitor G',
  xeljanz: 'Competitor G',
  actemra: 'Competitor H',
  remicade: 'Competitor I',
  erelzi: 'Competitor J',
  cimzia: 'Competitor K',
  SOMAVERT: 'Competitor L',
  'SANDOSTATIN LAR': 'Competitor M',
  STELAINDICATION: 'Competitor N',
  SKYRIZI: 'Competitor O',
  RINVOQ: 'Competitor P',
  RUXIENCE: 'Competitor Q',
  STELARA: 'Competitor R',
  TALTZ: 'Competitor S',
  hadlima: 'Competitor T',
  rituxan: 'Competitor U',
  kevzara: 'Competitor V',
  adalimumab: 'Competitor Class C',
  JAK: 'Competitive Class B',
  OAB: '',
  'TTL FESOTERODINE': 'Competitor',
  'REMAINING ARI': 'Competitor',
  ERLEADA: 'Competitor',
  'TOTAL ADT': 'Target',
};

const censorMapCaseSens = {
  Spa: 'Indication 1',
  SpA: 'Indication 1',
  RA: 'Indication 2',
  PsA: 'Indication 3',
  PsO: 'Indication 4',
  ASs: 'Indication 5',
  Der: 'Spc',
  Derm: 'Spc',
  End: 'Spc',
  JAK: 'Competitive Class B',
};

export const censorInsights = (response) => {
  if (!getDemoAccountStatus()) return response;
  const censored = JSON.parse(JSON.stringify(response));
  if (!censored.insights) {
    return censored;
  }
  censored.insights.entityName = `Entity Name ${censored.entityId}`;
  for (const objective of censored.insights.objectives) {
    objective.objectiveDescription = objective.objectiveName;
    objective.objectiveProduct = 'Target Product';
    for (const card of objective.cards) {
      const metric = card.metric
        ? card.metric.toUpperCase().slice(0, -1) +
          card.metric.slice(-1).toLowerCase()
        : '';
      if (card.cardType === 'share') {
        card.title = `VS. Competitor ${metric}`;
      } else {
        card.title = `${toCapitalize(card.productGroup)} Product ${metric}`;
      }
    }
  }
  return censored;
};

export const censorMaptualList = (maptualList) => {
  if (getDemoAccountStatus()) {
    const maptualListWithoutEntities =
      anonymizeEntityNameOnGetMaptualList(maptualList);
    const maptualListAnon = anonymizeSegmentsOnGetMaptualList(
      maptualListWithoutEntities
    );
    return maptualListAnon;
  }

  return maptualList;
};

export const censorHCPTableData = (tableData) => {
  if (!getDemoAccountStatus()) return tableData;
  // deep copy to prevent mutation
  const censored = JSON.parse(JSON.stringify(tableData));

  // censor columns
  for (const col of censored.columnConfig) {
    for (const [key, value] of Object.entries(censorMap)) {
      const regex = new RegExp(key, 'ig');
      col.headerName = col.headerName.replace(regex, value);
    }
  }

  return censored;
};

export const censorObjectives = (objectivesData) => {
  if (!getDemoAccountStatus()) return objectivesData;
  // deep copy to prevent mutation
  const censored = JSON.parse(JSON.stringify(objectivesData));

  // censor objectives
  for (const obj of censored) {
    for (const [key, value] of Object.entries(censorMap)) {
      const regex = new RegExp(key, 'ig');
      obj.title = obj.title.replace(regex, value);
    }
  }

  return censored;
};

const censorResponse = (response) => {
  let strRes = response;
  let deSerialize = false;

  if (getDemoAccountStatus()) {
    Logger.log('********* CENSORING');
    if (response && typeof response === 'object') {
      deSerialize = true;
      strRes = JSON.stringify(response);
    }

    if (strRes && typeof strRes === 'string') {
      let keys = Object.keys(censorMap);
      if (Array.isArray(keys)) {
        keys.forEach((key) => {
          const regEx = new RegExp(key, 'ig');
          const replaceTerm = censorMap[key];
          strRes = strRes.replace(regEx, replaceTerm);
        });
      }
      keys = Object.keys(censorMapCaseSens);
      if (Array.isArray(keys)) {
        keys.forEach((key) => {
          const filter = `/( ${key}|${key} )/`;
          const regEx = new RegExp(filter, 'g');
          const replaceTerm = censorMapCaseSens[key];
          strRes = strRes.replace(regEx, replaceTerm);
        });
      }
    }

    if (deSerialize) {
      return JSON.parse(strRes);
    }
    return strRes;
  }
  return response;
};

export default censorResponse;
