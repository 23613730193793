import { useQuery } from 'react-query';
import { maptualApiInstance } from '../../../../../../request/config';

export interface ProjectRunStatusResponse {
  status: string;
  currentRuntime: number;
  estimatedRemainingTime: number;
  estimatedPercentageComplete: number;
}

export default function useProjectStatus({ projectId }: { projectId: string }) {
  return useQuery({
    queryKey: `projectStatus-${projectId}`,
    queryFn: async () => {
      try {
        const response = await maptualApiInstance.get(
          `/admin/product-lines/none/projects/${projectId}/pipeline-status`,
          {}
        );
        return response.data;
      } catch (error) {
        console.error('Error while fetching project status', error);

        return {
          status: 'UNKNOWN',
          currentRuntime: 0,
          estimatedRemainingTime: 0,
          estimatedPercentageComplete: 0,
        };
      }
    },
  });
}
