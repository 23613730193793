export const formatMarketUpdateRequest = (existingMarket, fieldsToUpdate) => ({
  ...existingMarket,
  ...fieldsToUpdate,
});

const extractDataGridIds = (basketInformation) =>
  basketInformation.map((basket) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...basketWithoutDataGridIndex } = basket;
    return basketWithoutDataGridIndex;
  });

export function formatObjectiveTypeUpdateRequest(
  existingObjectiveType,
  basketInformationUpdate,
  nonBasketInformationFieldsToUpdate
) {
  const updateObjectiveType = {
    ...existingObjectiveType,
    ...nonBasketInformationFieldsToUpdate,
  };
  updateObjectiveType.params = {
    ...existingObjectiveType.params,
    products: extractDataGridIds(basketInformationUpdate),
  };
  return updateObjectiveType;
}

export function formatObjectiveTypeAddRequest(
  basketInformation,
  nonBasketInformationFieldsToAdd
) {
  const addObjectiveType = {
    ...nonBasketInformationFieldsToAdd,
    params: {},
  };
  addObjectiveType.params = {
    products: extractDataGridIds(basketInformation),
  };
  return addObjectiveType;
}
