import {
  Dispatch,
  JSX,
  ReactNode,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  styled,
  Typography,
  IconButton,
  MenuItem,
  Badge,
  Box,
} from "@mui/material";
import moment from "moment";
import Grow from "@mui/material/Grow";
import TuneIcon from "@mui/icons-material/Tune";
import { EntityMap } from "./entityMap";
import { MapList } from "./mapList/mapList.tsx";
import EmptyView from "../emptyView";
import { ObjectiveSelector } from "../objectiveSelector";
import { AutocompleteDisplay } from "@odaia/ui/src/components/autocompleteDisplay";
import { MapRoute } from "./mapRoute";
import { useMapViewContext } from "../contexts/mapViewContext.ts";
import { Stack } from "@mui/system";
import { FilterSidebar } from "./filtering/filterSidebar.tsx";
import { MaptualList } from "@odaia/domain/src/types.ts";
import { lastContactedIds } from "./filtering/notContactedFilter.tsx";
import { Assistant } from "../assistant/Assistant.tsx";
import { MaptualAssistant } from "../assistant/maptualAssistant.tsx";

const StyledMapContainer = styled("div")(({ moveZoomControlsUp }) => ({
  margin: 0,
  width: "100%",
  height: "100%",
  display: "grid",
  gridTemplateRows: "min-content minmax(0, 1fr)",
  "> * a": {
    backgroundColor: "unset",
  },
  ".gm-bundled-control-on-bottom": {
    bottom: moveZoomControlsUp ? "155px !important" : "",
    right: moveZoomControlsUp ? "38px !important" : "",
  },
}));

const RegionButtonContainer = styled("div")(() => ({
  height: 34,
  marginTop: 6,
  display: "flex",
  gap: 8,
  alignItems: "center",
}));

const StyledProjectAndRegion = styled("div")(
  ({ theme: { themeColors }, isVeeva }) => ({
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    minHeight: isVeeva ? 0 : 90,
    padding: "16px 12px 12px",
    borderBottom: `1px solid ${themeColors.dividerPrimaryColor}`,
  })
);

const StyledNavigationButtonContainer = styled("div")(() => ({
  display: "flex",
  minHeight: "24px",
  alignItems: "center",
  alignSelf: "flex-end",
}));

const HCPTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: "18px",
  color: themeColors.secondaryMaptualListFilteringColor,
}));

const StyledMapContentContainer = styled("div")(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "row",
  height: "100%",
}));

const StyledMap = styled("div")(() => ({
  minWidth: 500,
  height: "100%",
  marginRight: "16px",
  flexGrow: 1,
}));

const StyledMapList = styled("div")(({ isFilterOpen }) => ({
  animation: isFilterOpen ? "0.5s slideListOut" : "0.5s slideListIn",
  animationFillMode: "forwards",
  "@keyframes slideListOut": {
    from: { marginRight: 0 },
    to: { marginRight: "-400px" },
  },
  "@keyframes slideListIn": {
    from: { marginRight: "-400px" },
    to: { marginRight: 0 },
  },
}));

const StyledOptionsContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  marginLeft: "auto",
  gap: 16,
  alignItems: "flex-end",
}));

const StyledLabel = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: 26,
  fontWeight: 600,
  maxWidth: 800,
  color: themeColors.secondaryMaptualListFilteringColor,
  marginTop: 15,
}));

const StyledSecondaryLabel = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    fontSize: 16,
    fontWeight: 400,
    maxWidth: 800,
    color: themeColors.secondaryMaptualListFilteringColor,
    marginTop: 15,
  })
);

const StyledUnavailableContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: "grow",
  alignItems: "center",
  justifyContent: "center",
  align: "center",
  width: "100%",
  height: "100%",
}));

const Controls = styled("div")(({ theme: { themeColors, spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  minWidth: "343px",
  padding: "12px",
  gap: "10px",
  position: "absolute",
  zIndex: 2,
  top: spacing(0.5),
  right: "26px",
  backgroundColor: themeColors.mainBackground,
  borderRadius: "4px",
  boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.23)",
}));

const ControlsSection = styled("div")(() => ({
  display: "flex",
  height: "42px",
  flexDirection: "row",
  gap: "8px",
}));

const StyledBadge = styled(Badge)(({ theme: { themeColors } }) => ({
  display: "block",
  height: "100%",
  width: "56px",
  "& .MuiBadge-badge": {
    backgroundColor: themeColors.notificationBadgeSurface,
    color: themeColors.notificationBadgeContent,
  },
}));

const StyledOption = styled(MenuItem)(({ theme: { themeColors } }) => ({
  padding: "12px !important",
  color: themeColors.segmentSelectionCaptionColor,
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "24px",
  borderRadius: 3,
  whiteSpace: "pre",
  display: "flex !important",
  flexDirection: "column !important",
  alignItems: "start !important",
}));

const StyledOptionDetails = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    fontSize: 12,
    fontWeight: 400,
    color: themeColors.tertiaryColor,
    width: "100%",
    overflowX: "hidden",
    textOverflow: "ellipsis",
  })
);

const StyledIconButton = styled(IconButton)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.buttonBackgroundColor,
  color: themeColors.buttonContentColor,
  border: `1px solid ${themeColors.buttonBorderColor}`,
  borderRadius: "4px",
  boxShadow: themeColors.boxShadowLevelOne,
  "&:hover": {
    backgroundColor: themeColors.buttonBorderColor,
    borderColor: themeColors.buttonBorderColor,
  },
  padding: "8px 12px",
}));

const filterMaptualList = (
  maptualList,
  coordinates,
  selectedObjectiveIndex
) => {
  const filteredList = [];
  const { items } = maptualList.list;
  coordinates.forEach((coordinate) => {
    const matchingItem = items.find(
      (item) => item.snowflakeEntityId === coordinate.snowflakeEntityId
    );
    if (matchingItem) {
      filteredList.push(matchingItem);
    }
  });
  const sortedList = filteredList.sort((a, b) =>
    sortByPowerScore(a, b, selectedObjectiveIndex)
  );
  return sortedList;
};

const sortByPowerScore = (a, b, selectedObjectiveIndex) => {
  const aScores = a.objectiveScores || [a.maptualDisplayScore];
  const bScores = b.objectiveScores || [b.maptualDisplayScore];
  if (aScores[selectedObjectiveIndex] >= bScores[selectedObjectiveIndex]) {
    return -1;
  }

  if (aScores[selectedObjectiveIndex] < bScores[selectedObjectiveIndex]) {
    return 1;
  }

  return 0;
};

const convertSecondaryTextToDate = (text: string) => {
  try {
    const splitText = text.split(",").map((item) => item.trim());
    const date = splitText[1];
    const year = splitText[2].split(" ")[0];

    return moment(date.concat(", ", year)).format("YYYY-MM-DD");
  } catch (error) {
    return moment().format("YYYY-MM-DD");
  }
};

const defaultFiltersOff = {
  userCreatedListFilter: {
    noSee: true,
    longTermLeave: true,
    starred: false,
  },
  specialtyFilter: [],
  powerscoreFilter: {
    min: 0,
    max: 10,
  },
  notContactedFilter: lastContactedIds.OFF,
  neverSeenFilter: false,
  segmentFilter: [],
};

const defaultFiltersOn = {
  userCreatedListFilter: {
    noSee: false,
    longTermLeave: false,
    starred: false,
  },
  specialtyFilter: [],
  powerscoreFilter: {
    min: 3,
    max: 10,
  },
  notContactedFilter: lastContactedIds.OFF,
  neverSeenFilter: true,
  segmentFilter: [],
};

const applyListFilters = (list, objectiveIndex, filters: Filters) => {
  let newList = list;
  newList = newList?.filter((i) => {
    const score = i.objectiveScores
      ? i.objectiveScores[objectiveIndex]
      : i.maptualFractionalScore;

    return (
      score >= filters.powerscoreFilter.min &&
      score <= filters.powerscoreFilter.max
    );
  });
  if (filters.specialtyFilter.length !== 0) {
    newList = newList?.filter((i) =>
      filters.specialtyFilter.includes(i.specialty.toLowerCase())
    );
  }
  if (filters.userCreatedListFilter.starred) {
    newList = newList?.filter((i) => i.listMemberships.starred);
  }
  if (!filters.userCreatedListFilter.noSee) {
    newList = newList?.filter((i) => !i.listMemberships.noSee);
  }
  if (!filters.userCreatedListFilter.longTermLeave) {
    newList = newList?.filter((i) => !i.listMemberships.longTermLeave);
  }
  if (
    filters.notContactedFilter !== lastContactedIds.OFF ||
    filters.neverSeenFilter
  ) {
    newList = newList?.filter((i) => {
      let contactedDaysAgo = 999;
      if (i.secondaryText) {
        contactedDaysAgo = moment().diff(
          convertSecondaryTextToDate(i.secondaryText),
          "days"
        );
      }
      if (
        filters.neverSeenFilter &&
        filters.notContactedFilter !== lastContactedIds.OFF
      ) {
        return (
          contactedDaysAgo > filters.notContactedFilter &&
          contactedDaysAgo < 365
        );
      }
      if (filters.neverSeenFilter) {
        return contactedDaysAgo < 365;
      }
      if (filters.notContactedFilter !== lastContactedIds.OFF) {
        return contactedDaysAgo > filters.notContactedFilter;
      }
    });
  }
  if (filters.segmentFilter.length !== 0) {
    const historical = filters.segmentFilter.includes("Recent");
    const predicted = filters.segmentFilter.includes("Predicted");
    const all = (historical && predicted) || (!historical && !predicted);
    newList = newList?.filter((i) => {
      return filters.segmentFilter.find((segment) => {
        const matchingSegment = i.segments.find(
          (s) => s.segmentName === segment
        );
        if (matchingSegment) {
          if (all) {
            return true;
          }
          if (historical && matchingSegment.isHistoricalMember) {
            return true;
          }
          if (predicted && matchingSegment.isPredictedMember) {
            return true;
          }
        }
      });
    });
  }

  return newList;
};

const getActiveFilterCount = (
  activeFilters: Filters,
  defaultFiltersArg: Filters
): number => {
  let count = 0;
  for (const [key, value] of Object.entries(activeFilters)) {
    if (typeof value === "object" && value !== null) {
      count += getActiveFilterCount(value, defaultFiltersArg[key]);
    } else if (value !== defaultFiltersArg[key]) {
      count += 1;
    }
  }
  return count;
};

export interface userCreatedListFilters {
  noSee: boolean;
  longTermLeave: boolean;
  starred: boolean;
}

export interface Filters {
  specialtyFilter: string[];
  userCreatedListFilter: userCreatedListFilters;
  powerscoreFilter: {
    min: number;
    max: number;
  };
  notContactedFilter: number | null;
  neverSeenFilter: boolean;
  segmentFilter: string[];
}

export type EntityPopoverProps = {
  isOpen: boolean;
  anchorEl: HTMLElement | undefined;
  setIsEntityFullModalOpen: Dispatch<SetStateAction<boolean>>;
  objective: Record<string, unknown> | null;
  entityData: MaptualList["list"]["items"][number];
  topPosition: number;
  handleClose: () => void;
  routeEntities: unknown[];
  setRoute: (entities: unknown) => void;
  setIsRouteLoading: Dispatch<SetStateAction<boolean>>;
  mapContentContainerRef: RefObject<HTMLElement>;
};

export type EntityFullModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  entityId: string;
};

export type EntityMapData = {
  snowflakeEntityId: number;
  score: number;
  objectiveScores: number[];
  coordinates: {
    latitude: number;
    longitude: number;
  };
  keywords: string[];
  specialty: string;
  primaryText: string;
  address: string;
};

type MapViewProps = {
  listMapToggle?: ReactNode;
  regionSelection?: ReactNode;
  EntityPopover?: (props: EntityPopoverProps) => JSX.Element;
  EntityFullModal?: (props: EntityFullModalProps) => JSX.Element;
  isVeeva?: boolean;
  mapIdOverride?: string;
  defaultZoom?: number;
  moveZoomControlsUp?: boolean;
};

export const MapView = ({
  listMapToggle = null,
  regionSelection = null,
  EntityPopover,
  EntityFullModal,
  isVeeva = false,
  mapIdOverride,
  defaultZoom,
  moveZoomControlsUp = false,
}: MapViewProps) => {
  const {
    projectId,
    maptualList,
    isMaptualListLoading,
    isMaptualListsLoading,
    maptualListObjectives,
    entityCoordinatesData,
    isLoadingEntityCoords,
    isErrorEntityCoords,
    objective,
    objectiveIndex,
    canUserFilterMapView,
    voiceEnabled,
    maptualVoiceEnabled,
    anonymize,
    setMapObjective,
    relevantUserSpecialties,
    filterMetadata,
    filterMetadataLoading,
    usergroup,
    maptualListMetadata,
  } = useMapViewContext();
  const [entityMapData, setEntityMapData] = useState<EntityMapData[]>([]);
  const [filteredEntityMapData, setFilteredEntityMapData] = useState(null);

  const [visibleMaptualList, setVisibleMaptualList] = useState(
    maptualList?.list
  );
  const [filteredMaptualList, setFilteredMaptualList] = useState(
    maptualList?.list
  );
  const [visibleMaptualListLoading, setVisibleMaptualListLoading] =
    useState(false);
  const [mapCenter, setMapCenter] = useState(null);
  const [selectedMaptualListItem, setSelectedMaptualListItem] = useState(null);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [selectedGroupCoordinates, setSelectedGroupCoordinates] =
    useState(null);
  const [groupSelected, setGroupSelected] = useState(false);
  const [isEntityPopoverOpen, setIsEntityPopoverOpen] = useState(false);
  const [isEntityFullModalOpen, setIsEntityFullModalOpen] = useState(false);

  const [routeEntities, setRouteEntities] = useState<Record<string, unknown>[]>(
    []
  );
  const [routeInfo, setRouteInfo] = useState(null);
  const [isRouteLoading, setIsRouteLoading] = useState(false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const mapContentContainerRef = useRef<HTMLDivElement | null>(null);
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const [biggestCity, setBiggestCity] = useState("");
  const [mapZoom, setMapZoom] = useState(9);

  const defaultFilters = canUserFilterMapView
    ? defaultFiltersOn
    : defaultFiltersOff;

  const [filters, setFilters] = useState<Filters>(defaultFilters);

  const setRoute = (entities: Record<string, unknown>[]) => {
    setRouteEntities(
      entities.map((entity) => ({
        ...entityMapData.find(
          (e) => e.snowflakeEntityId === entity.snowflakeEntityId
        ),
        ...entity,
      }))
    );
  };

  const saveFilters = (filters: Filters) => {
    sessionStorage.setItem(
      `map-view-filters-${projectId}`,
      JSON.stringify(filters)
    );
  };

  const getFilters = () => {
    const sessionFilters = sessionStorage.getItem(
      `map-view-filters-${projectId}`
    );
    if (sessionFilters) {
      const filtersFromSession = JSON.parse(sessionFilters);
      if (
        filtersFromSession.powerscoreFilter &&
        filtersFromSession.userCreatedListFilter &&
        filtersFromSession.specialtyFilter &&
        filtersFromSession.segmentFilter
      ) {
        return filtersFromSession;
      }
    }
    return defaultFilters;
  };

  useEffect(() => {
    if (projectId) {
      const sessionFilters = getFilters();
      setFilters(sessionFilters);
    }
  }, [projectId]);

  const findBiggestCity = (entityList) => {
    const cities = {};
    entityList.forEach((entity) => {
      if (entity.address) {
        const city = entity.address.split(",")[1].trim();
        if (cities[city]) {
          cities[city] += 1;
        } else {
          cities[city] = 1;
        }
      }
    });
    return Object.keys(cities).reduce((a, b) =>
      cities[a] > cities[b] ? a : b
    );
  };

  useEffect(() => {
    if (
      !isMaptualListLoading &&
      !isLoadingEntityCoords &&
      maptualList?.list?.items?.length > 0 &&
      entityMapData?.length > 0 &&
      projectId &&
      objectiveIndex !== null
    ) {
      const newList = applyListFilters(
        maptualList?.list?.items,
        objectiveIndex,
        filters
      );
      const newFilteredMaptualList = {
        list: {
          items: newList,
        },
      };
      const newListEntityIds = newList.map((i) => i.snowflakeEntityId);
      const newFilteredEntityMapData = entityMapData?.filter((i) =>
        newListEntityIds.includes(i.snowflakeEntityId)
      );
      const numActiveFilters = getActiveFilterCount(filters, defaultFiltersOff);
      setFilteredMaptualList(newFilteredMaptualList);
      setFilteredEntityMapData(newFilteredEntityMapData);
      setActiveFilterCount(numActiveFilters);

      saveFilters(filters);
    }
  }, [filters, entityMapData, maptualList, objectiveIndex]);

  useEffect(() => {
    if (isMaptualListsLoading) {
      setSelectedCoordinates(null);
      setVisibleMaptualListLoading(true);
      setVisibleMaptualList(null);
    }
  }, [isMaptualListsLoading]);

  useEffect(() => {
    if (!objective?.id) {
      if (maptualListObjectives?.length > 0) {
        setMapObjective(maptualListObjectives[0], 0);
      }
    }
  }, [maptualListObjectives, objective?.id, setMapObjective]);

  const updateObjective = (objectiveId: string) => {
    const newObjective = maptualListObjectives.find(
      (i) => i.id === objectiveId
    );
    setVisibleMaptualListLoading(true);
    if (!newObjective) {
      return;
    }
    setMapObjective(
      newObjective,
      maptualListObjectives.findIndex((x) => x.id === objectiveId)
    );
  };

  const selectMaptualListItem = useCallback(
    (selectedEntity, zoomToEntity = true) => {
      const { items } = maptualList.list;
      const selectedIndex = items.findIndex(
        (item) => item.snowflakeEntityId === selectedEntity.snowflakeEntityId
      );
      const entityCoordinates = filteredEntityMapData.find(
        (entity) =>
          entity.snowflakeEntityId === selectedEntity.snowflakeEntityId
      );
      if (zoomToEntity) {
        setMapCenter({
          lat: entityCoordinates.coordinates?.latitude,
          lng: entityCoordinates.coordinates?.longitude,
        });
      }
      setIsFilterDrawerOpen(false);
      setSelectedMaptualListItem(items[selectedIndex]);
      setIsEntityPopoverOpen(true);
    },
    [
      maptualList?.list,
      filteredEntityMapData,
      setMapCenter,
      setVisibleMaptualListLoading,
      setIsEntityPopoverOpen,
      setSelectedMaptualListItem,
      setIsFilterDrawerOpen,
    ]
  );

  const selectMaptualListItemByEntityId = useCallback(
    (selectedEntityId, zoomToEntity = true) => {
      const { items } = maptualList.list;
      const selectedIndex = items.findIndex(
        (item) => item.snowflakeEntityId === selectedEntityId
      );
      const entityCoordinates = filteredEntityMapData.find(
        (entity) => entity.snowflakeEntityId === selectedEntityId
      );
      if (zoomToEntity) {
        setMapCenter({
          lat: entityCoordinates.coordinates?.latitude,
          lng: entityCoordinates.coordinates?.longitude,
        });
      }
      setIsFilterDrawerOpen(false);
      setSelectedMaptualListItem(items[selectedIndex]);
      setIsEntityPopoverOpen(true);
    },
    [
      maptualList?.list,
      filteredEntityMapData,
      setMapCenter,
      setVisibleMaptualListLoading,
      setIsEntityPopoverOpen,
      setSelectedMaptualListItem,
      setIsFilterDrawerOpen,
    ]
  );

  useEffect(() => {
    if (
      selectedGroupCoordinates &&
      selectedMaptualListItem &&
      !selectedGroupCoordinates.find(
        (i) => i.snowflakeEntityId === selectedMaptualListItem.snowflakeEntityId
      )
    ) {
      selectGroupCoordinates([]);
    }
  }, [selectedMaptualListItem, selectedGroupCoordinates]);

  const selectCoordinates = (coordinates) => {
    setSelectedCoordinates(coordinates);
  };

  const selectGroupCoordinates = useCallback(
    (coordinates) => {
      if (coordinates.length > 0) {
        setSelectedGroupCoordinates(coordinates);
        setGroupSelected(true);
        setSelectedMaptualListItem(null);
        setIsFilterDrawerOpen(false);
      } else {
        setGroupSelected(false);
      }
    },
    [
      setSelectedGroupCoordinates,
      setGroupSelected,
      setSelectedMaptualListItem,
      setIsFilterDrawerOpen,
    ]
  );

  const clearSelectedMaptualListItem = () => {
    setSelectedMaptualListItem(null);
  };

  useEffect(() => {
    if (!selectedCoordinates) {
      if (visibleMaptualList?.items?.length > 0) {
        setVisibleMaptualList({
          items: [],
        });
      }
      return;
    }
    const newList = filterMaptualList(
      filteredMaptualList,
      groupSelected ? selectedGroupCoordinates : selectedCoordinates,
      objectiveIndex
    );
    if (
      (voiceEnabled || maptualVoiceEnabled) &&
      selectedCoordinates &&
      selectedCoordinates.length > 0
    ) {
      setBiggestCity(findBiggestCity(selectedCoordinates));
    }
    setVisibleMaptualList({
      items: newList,
    });
    setVisibleMaptualListLoading(false);
  }, [
    selectedCoordinates,
    selectedGroupCoordinates,
    filteredMaptualList,
    groupSelected,
    objectiveIndex,
  ]);

  useEffect(() => {
    if (
      entityCoordinatesData &&
      entityCoordinatesData.length > 0 &&
      maptualList?.list?.items?.length > 0
    ) {
      const mapData = [];
      for (const item of maptualList.list.items) {
        const entityCoordinates = entityCoordinatesData?.find(
          (entity) => Number(entity.entityId) === Number(item.snowflakeEntityId)
        );
        if (!entityCoordinates) {
          continue;
        }
        const entityMapItem = {
          snowflakeEntityId: item.snowflakeEntityId,
          score: item.maptualDisplayScore,
          objectiveScores: item.objectiveScores || [item.maptualDisplayScore],
          coordinates: {
            latitude: entityCoordinates.latitude,
            longitude: entityCoordinates.longitude,
          },
          keywords: item.keywords,
          specialty: item.specialty,
          primaryText: item.primaryText,
          address: entityCoordinates.address,
        };
        mapData.push(entityMapItem);
      }
      setEntityMapData(mapData);
      clearSelectedMaptualListItem();
    }
  }, [entityCoordinatesData, maptualList]);

  useEffect(() => {
    if (isErrorEntityCoords) setEntityMapData([]);
  }, [isErrorEntityCoords]);

  const isLoadingData = () =>
    isLoadingEntityCoords ||
    isMaptualListLoading ||
    isMaptualListsLoading ||
    !objective?.id;

  const centerMapOnEntity = (entity) => {
    setMapCenter({
      lat: entity.coordinates?.latitude,
      lng: entity.coordinates?.longitude,
    });
  };

  const searchItems = (options, { inputValue }) =>
    options.filter(
      (option) =>
        option.primaryText.toLowerCase().includes(inputValue.toLowerCase()) ||
        (option.address &&
          option.address.toLowerCase().includes(inputValue.toLowerCase()))
    );

  const searchItemsRender = (props, option, optionLabel, itemKey) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { key, ...rest } = props;
    return (
      <StyledOption {...rest} key={option[itemKey]}>
        <div>
          {anonymize
            ? `Entity Name ${option.snowflakeEntityId.slice(-5)}`
            : option[optionLabel]}
        </div>
        <StyledOptionDetails>{option.address}</StyledOptionDetails>
      </StyledOption>
    );
  };

  return (
    <>
      <StyledMapContainer moveZoomControlsUp={moveZoomControlsUp}>
        <StyledProjectAndRegion isVeeva={isVeeva}>
          <StyledNavigationButtonContainer>
            <HCPTitle variant="h3">HCPs</HCPTitle>
          </StyledNavigationButtonContainer>
          <StyledOptionsContainer>
            {regionSelection && (
              <RegionButtonContainer>
                {voiceEnabled && (
                  <Box>
                    <Assistant biggestCity={biggestCity} />
                  </Box>
                )}
                {maptualVoiceEnabled && (
                  <Box>
                    <MaptualAssistant
                      usergroup={usergroup}
                      projectId={projectId}
                      regionId={maptualListMetadata?.sfMaptualListId}
                      selectMaptualListItemByEntityId={
                        selectMaptualListItemByEntityId
                      }
                    />
                  </Box>
                )}
                {regionSelection}
              </RegionButtonContainer>
            )}
            {listMapToggle && (
              <Stack
                direction={"column"}
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                {listMapToggle}
              </Stack>
            )}
          </StyledOptionsContainer>
        </StyledProjectAndRegion>
        <StyledMapContentContainer ref={mapContentContainerRef}>
          {isLoadingData() && !isErrorEntityCoords && <EmptyView isLoading />}
          {(isErrorEntityCoords ||
            (!isLoadingData() && !maptualList?.list?.items?.length)) && (
            <Grow in timeout={500}>
              <StyledUnavailableContainer>
                <StyledLabel>Map Unavailable</StyledLabel>
                <StyledSecondaryLabel>
                  We do not currently have a map available for the selected
                  region. Please either switch to hcp view, or select another
                  region.
                </StyledSecondaryLabel>
              </StyledUnavailableContainer>
            </Grow>
          )}
          {!isLoadingData() &&
            maptualList?.list?.items &&
            filteredEntityMapData && (
              <>
                <StyledMapList isFilterOpen={isFilterDrawerOpen}>
                  <MapList
                    mapZoom={mapZoom}
                    visibleMaptualList={visibleMaptualList}
                    visibleMaptualListLoading={visibleMaptualListLoading}
                    selectedMaptualListItem={selectedMaptualListItem}
                    onSelectMaptualListItem={selectMaptualListItem}
                    groupSelected={groupSelected}
                    isPopoverOpen={isEntityPopoverOpen}
                    setIsPopoverOpen={setIsEntityPopoverOpen}
                    setIsEntityFullModalOpen={setIsEntityFullModalOpen}
                    objective={objective}
                    selectedObjectiveIndex={objectiveIndex}
                    routeEntities={routeEntities}
                    setRoute={setRoute}
                    setIsRouteLoading={setIsRouteLoading}
                    mapContentContainerRef={mapContentContainerRef}
                    filteredEntityMapData={filteredEntityMapData}
                    EntityPopover={EntityPopover}
                  />
                </StyledMapList>
                <StyledMap>
                  <Controls>
                    <ControlsSection>
                      <AutocompleteDisplay
                        itemsForSearch={filteredEntityMapData}
                        handleSelection={selectMaptualListItem}
                        optionLabel="primaryText"
                        noOptionsText={
                          filteredEntityMapData.length === 0
                            ? "No HCPs found. Try adjusting your filters"
                            : "No HCPs found for that search term"
                        }
                        textFieldLabel="Find an HCP"
                        itemKey="snowflakeEntityId"
                        filterOptions={searchItems}
                        renderOption={searchItemsRender}
                      />
                      {canUserFilterMapView && (
                        <StyledBadge badgeContent={activeFilterCount}>
                          <StyledIconButton
                            data-testid="filter-button"
                            onClick={() => {
                              setIsFilterDrawerOpen(!isFilterDrawerOpen);
                              setSelectedMaptualListItem(null);
                            }}
                          >
                            <TuneIcon />
                          </StyledIconButton>
                        </StyledBadge>
                      )}
                    </ControlsSection>
                    <ObjectiveSelector
                      updateFilterObjective={(objectiveId) =>
                        updateObjective(objectiveId)
                      }
                      objectiveId={objective?.id}
                      currentObjectives={maptualListObjectives}
                      fullWidth
                      minWidth="275px"
                    />
                  </Controls>
                  {routeEntities?.length > 0 && (
                    <MapRoute
                      setRouteEntities={setRouteEntities}
                      routeEntities={routeEntities}
                      routeInfo={routeInfo}
                      setRouteInfo={setRouteInfo}
                      isRouteLoading={isRouteLoading}
                      setIsRouteLoading={setIsRouteLoading}
                      handleRouteEntityClick={centerMapOnEntity}
                    />
                  )}
                  <EntityMap
                    setMapZoom={setMapZoom}
                    setMapCenter={(center) => setMapCenter(center)}
                    mapCenter={mapCenter}
                    allCoordinateData={entityMapData}
                    coordinateData={filteredEntityMapData}
                    updateBoundedCoordinates={(coordinates) => {
                      selectCoordinates(coordinates);
                    }}
                    updateIsNavigating={(newIsNavigating) =>
                      newIsNavigating
                        ? setVisibleMaptualListLoading(true)
                        : null
                    }
                    onPointClick={selectMaptualListItem}
                    onSpiderClick={selectGroupCoordinates}
                    selectedObjectiveIndex={objectiveIndex}
                    selectedEntity={selectedMaptualListItem}
                    routeEntities={routeEntities}
                    setIsPopoverOpen={setIsEntityPopoverOpen}
                    clearSelectedMaptualListItem={clearSelectedMaptualListItem}
                    setRouteInfo={(info) => setRouteInfo(info)}
                    mapIdOverride={mapIdOverride}
                    defaultZoom={defaultZoom}
                  />
                </StyledMap>
              </>
            )}
        </StyledMapContentContainer>
        {selectedMaptualListItem &&
          selectedMaptualListItem.snowflakeEntityId &&
          maptualList?.metadata?.sfMaptualListId &&
          EntityFullModal && (
            <EntityFullModal
              open={isEntityFullModalOpen}
              setOpen={setIsEntityFullModalOpen}
              snowflakeEntityId={selectedMaptualListItem.snowflakeEntityId}
              snowflakeMaptualListId={maptualList.metadata.sfMaptualListId}
            />
          )}
      </StyledMapContainer>
      {canUserFilterMapView &&
        !isLoadingData() &&
        maptualList?.list?.items.length > 0 && (
          <FilterSidebar
            isFilterDrawerOpen={isFilterDrawerOpen}
            setIsFilterDrawerOpen={setIsFilterDrawerOpen}
            filters={filters}
            setFilters={setFilters}
            relevantUserSpecialties={relevantUserSpecialties}
            filterMetadata={filterMetadata}
            filterMetadataLoading={filterMetadataLoading}
            objective={objective}
          />
        )}
    </>
  );
};
