import { Box, Typography, TypographyProps, styled } from '@mui/material';
import { TypographyDefinitions } from '@odaia/ui/src/typography';
import { addSpacesBetweenSeparators } from '../../../../utils/formatEntityName';

interface StyledTypographyProps extends TypographyProps {
  isCaption?: boolean;
}

const StyledNameCellTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isCaption' || prop !== 'variant',
})<StyledTypographyProps>(({ theme, isCaption }) => ({
  fontWeight: isCaption
    ? TypographyDefinitions.fontWeight.regular
    : TypographyDefinitions.fontWeight.medium,
  color: isCaption
    ? theme.themeColors.tertiaryColor
    : theme.themeColors.secondaryTextColor,
}));

export const EntityTableNameCell = ({ entityName, address }) => (
  <Box textAlign="left">
    <StyledNameCellTypography variant="body2">
      {addSpacesBetweenSeparators(entityName.toUpperCase())}
    </StyledNameCellTypography>
    <StyledNameCellTypography variant="caption" isCaption>
      {address}
    </StyledNameCellTypography>
  </Box>
);
