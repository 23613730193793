import { Box, styled } from '@mui/material';
import { ValueContainer } from '../ValueContainer';
import { SparklineBarGraph } from './barGraph';
import TrendValue from '../../../../components/fieldView/trendValues';

const BarGraphBox = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  marginRight: 16,
  maxWidth: 50,
}));

export const BarGraphContainer = ({ row, valueType, chartLabels }) => {
  const { data, trend } = row;

  return (
    <>
      <BarGraphBox data-testid={`sales-rep-bar-graph-${data.join('-')}`}>
        <SparklineBarGraph data={data} labels={chartLabels} />
      </BarGraphBox>
      <ValueContainer data={data} valueType={valueType}>
        <TrendValue
          type={trend.type}
          status={trend.status}
          value={trend?.value}
          noMargin
        />
      </ValueContainer>
    </>
  );
};
