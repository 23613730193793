import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { EntityType } from '@odaia/clients/maptualAPI';
import { useProductPerformanceMetadataContext } from './data/useProductPerformanceMetadataContext';
import { ProductPerformanceMetadataContext } from './data/productPerformanceMetadataContext';
import { ProductPerformanceHeader } from './ProductPerformanceHeader';
import { ProductPerformanceLineChart } from './ProductPerformanceLineChart';
import { ProductPerformanceTable } from './ProductPerformanceTable';
import { ProductPerformanceChartContext } from './data/productPerformanceChartContext';
import { useProductPerformanceChartContext } from './data/useProductPerformanceChartContext';
import { METRIC_DATA_TYPE } from '../constants';
import { ProductPerformanceBarChart } from './ProductPerformanceBarChart';
import { PredictionDisclaimer } from './ProductPerformancePredictionDisclaimer';

const Wrapper = styled(Box)(({ theme: { themeColors, spacing } }) => ({
  maxHeight: 676,
  borderRadius: 10,
  backgroundColor: themeColors.cardBackgroundColor,
  border: `1px solid ${themeColors.contentCardBorderColor}`,
  marginBottom: spacing(2),
  padding: spacing(2.5),
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(2),
  h4: {
    color: themeColors.tableHeaderContentColor,
  },
}));

interface ProductPerformanceCardProps {
  projectId: string;
  regionId: string;
  objectiveId: string;
  entityId: string;
  entityType?: EntityType;
}

export const ProductPerformanceCard: React.FC<ProductPerformanceCardProps> = ({
  projectId,
  regionId,
  objectiveId,
  entityId,
  entityType = EntityType.Hcp,
}) => {
  const productPerformanceMetadataContext =
    useProductPerformanceMetadataContext(
      projectId,
      regionId,
      objectiveId,
      entityId,
      entityType
    );

  const productPerformanceChartContext = useProductPerformanceChartContext(
    productPerformanceMetadataContext
  );

  return (
    <ProductPerformanceMetadataContext.Provider
      value={productPerformanceMetadataContext}
    >
      <Wrapper data-testid="product-performance-card">
        <Typography variant="h4" align="left">
          Product Performance
        </Typography>

        <ProductPerformanceHeader />
        <ProductPerformanceChartContext.Provider
          value={productPerformanceChartContext}
        >
          {productPerformanceMetadataContext?.selectedCardTab ===
            METRIC_DATA_TYPE.VOLUME && <ProductPerformanceLineChart />}

          {productPerformanceMetadataContext?.selectedCardTab ===
            METRIC_DATA_TYPE.SHARE && <ProductPerformanceBarChart />}

          <ProductPerformanceTable />

          <PredictionDisclaimer />
        </ProductPerformanceChartContext.Provider>
      </Wrapper>
    </ProductPerformanceMetadataContext.Provider>
  );
};
