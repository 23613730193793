import { useContext } from 'react';
import { styled, Button, Stack } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import {
  ModalBody,
  ModalDisplay,
  ModalFooter,
  ModalHeader,
} from '../generic/modalDisplay';
import { ModalDisplayCustomStyle } from './styles/styledComponents';
import { NewProjectContext } from './newProjectContext';

const StyledModalBody = styled(ModalBody)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
  padding: '24px',
}));

const StyledProjectDetails = styled('span')(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
}));

export default function NewProjectErrorModal({ isOpen, onClose }) {
  const { projectConfig } = useContext(NewProjectContext);

  return (
    <ModalDisplay isOpen={isOpen} style={ModalDisplayCustomStyle}>
      <ModalHeader onClose={onClose} shouldShowXIcon={false}>
        <Stack direction="row" gap={1}>
          <ErrorIcon color="error" sx={{ fontSize: 24 }} />{' '}
          <span>Project Creation Failed</span>
        </Stack>
      </ModalHeader>
      <StyledModalBody>
        <div>
          There was an error with creating{' '}
          <StyledProjectDetails>
            {projectConfig.projectName}
          </StyledProjectDetails>
          {'. '}
          Please try again later.
        </div>
      </StyledModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </ModalFooter>
    </ModalDisplay>
  );
}
