import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

export default function ExpandButton({
  handleExpandClick = null,
  isExpanded = false,
}) {
  return (
    <Button
      variant="contained"
      color="primary"
      endIcon={isExpanded ? <FullscreenExitIcon /> : <FullscreenIcon />}
      onClick={handleExpandClick}
    >
      Resize
    </Button>
  );
}

ExpandButton.propTypes = {
  handleExpandClick: PropTypes.func,
  isExpanded: PropTypes.bool,
};
