import { useContext } from 'react';
import { useTheme, Typography } from '@mui/material';
import { TREND_STATUS } from '../constants';
import { OpportunityMetadataContext } from './data/opportunityMetadataContext';
import { OpportunityChartContext } from './data/opportunityChartContext';
import { isPredictionCadence } from '../helpers';

export const PredictionDisclaimer = () => {
  const { selectedCadence } = useContext(OpportunityMetadataContext);
  const { data, isDataLoading } = useContext(OpportunityChartContext);

  if (
    isDataLoading ||
    !data ||
    data.length === 0 ||
    !isPredictionCadence(selectedCadence?.id)
  ) {
    return null;
  }

  const isMissingPrediction = data[0].chartValues.some(
    (value) => value.trend.status === TREND_STATUS.CANNOT_PREDICT
  );
  if (!isMissingPrediction) {
    return null;
  }

  const { themeColors } = useTheme();

  return (
    <Typography
      variant="caption"
      color={themeColors.tertiaryColor}
      textAlign="left"
      fontStyle="italic"
      data-testid="prediction-disclaimer"
    >
      Not enough data to generate a prediction.
    </Typography>
  );
};
