import { Box } from '@mui/system';
import { styled } from '@mui/material';

export const GraphSection = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  width: '100%',
  color: themeColors.primaryTextColor,
  backgroundColor: themeColors.contentCardBackgroundColor,
  flexDirection: 'column',
  gap: 16,
  border: '1px solid',
  borderColor: themeColors.borderPrimaryColor,
  borderRadius: 10,
  padding: 24,
}));

export const GraphControls = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
  alignItems: 'center',
});

export const GraphTitle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 6,
  alignItems: 'flex-start',
}));
