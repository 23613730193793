import { useMemo, useState } from 'react';
import { Dialog, StyledEngineProvider } from '@mui/material';
import { ContentRenderer, actionButtonRenderer } from './modalElements';
import { DEPLOY_CSV_STATES, StyledDialogContent } from './sharedComponents';
import {
  DeployFileContext,
  useDeployDialogContext,
} from './deployDialogContext';

/*
  Sample GET request query object for debugging on DEV/DEMO:

  data = {
    isLoading: false,
    data: {
      csvList: [
        {
          path: 'gskussbu4/exports/veeva',
          filename: 'ODAIA_OUTPUT_2023_06_05_SBU_TEST_CSV.csv',
        },
      ],
    },
    refetch: () => {
      console.log('refetching');
    },
  };
*/

export function DeployCsvDialog({ onClose, data, deploy }) {
  const [filename, setFilename] = useState('');
  const [newFilename, setNewFilename] = useState('');

  const { deployDialogState } = useDeployDialogContext();

  const isOpen = deployDialogState !== DEPLOY_CSV_STATES.IDLE;

  const fileContextValues = useMemo(
    () => ({
      filename,
      setFilename,
      newFilename,
      setNewFilename,
      data,
      deploy,
    }),
    [filename, setFilename, newFilename, setNewFilename, data, deploy]
  );

  return (
    <DeployFileContext.Provider value={fileContextValues}>
      <StyledEngineProvider injectFirst>
        <Dialog
          open={isOpen}
          onClose={onClose}
          data-testid="test-deploy-csv-dialog"
        >
          <StyledDialogContent>
            <ContentRenderer
              deployDialogState={deployDialogState}
              data={data}
            />
            {actionButtonRenderer(deployDialogState)}
          </StyledDialogContent>
        </Dialog>
      </StyledEngineProvider>
    </DeployFileContext.Provider>
  );
}
