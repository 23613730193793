import { styled, Box } from '@mui/material';
import { Container } from './Container';
import { TerritoryOverviewContext } from './territoryOverviewContext';
import { useFieldTerritoryOverviewContext } from './useFieldTerritoryOverviewContext';

const Root = styled(Box)(({ theme: { themeColors } }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  background: themeColors.mainBackground,
  overflow: 'auto',
}));

export const Wrapper = () => {
  const fieldTerritoryOverviewContext = useFieldTerritoryOverviewContext();

  return (
    <Root>
      <TerritoryOverviewContext.Provider value={fieldTerritoryOverviewContext}>
        <ContainerBox
          data-testid="territory-overview-container"
          className="intercom-field-territory-overview-page"
        >
          <Content>
            <Container />
          </Content>
        </ContainerBox>
      </TerritoryOverviewContext.Provider>
    </Root>
  );
};

const ContainerBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  height: '100%',
}));

const FlexColumnBase = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const Content = styled(FlexColumnBase)(() => ({
  width: '100%',
  minWidth: 560,
}));
