import moment from 'moment'
import { CADENCE } from '../constants/cadence';

export const getObjectiveBasketName = (products, filterObjectiveId) => {
    if (!products || !filterObjectiveId) return [];
    const objectiveProducts = products.find(
      (product) => product.objectiveId === filterObjectiveId
    );
    return objectiveProducts.name;
  };

  export const formatTimestamp = ({ timestamp, cadence, nextWeek = true }) => {
    if (!timestamp) return '';
    if (cadence === CADENCE.DAY) {
      return moment(timestamp).format('MMM D');
    }
    if (cadence === CADENCE.WEEK) {
      if (nextWeek) {
        const deltaFromFriday = 5 - moment(timestamp).day();
        const endDayNextWeek = moment(timestamp).add(deltaFromFriday + 7, 'day');
        const startDayNextWeek = moment(timestamp).add(
          3 + deltaFromFriday,
          'day'
        );
        if (startDayNextWeek.format('MMM') !== endDayNextWeek.format('MMM')) {
          return `${startDayNextWeek.format('MMM')} ${startDayNextWeek.format(
            'D'
          )}-${endDayNextWeek.format('MMM')} ${endDayNextWeek.format('D')}`;
        }
        return `${startDayNextWeek.format('MMM')} ${startDayNextWeek.format(
          'D'
        )}-${endDayNextWeek.format('D')}`;
      }
      const endDay = moment(timestamp).format('D');
      const startDay = moment(timestamp).subtract(4, 'day').format('MMM D');
      return `${startDay}-${endDay}`;
    }
    if (cadence === CADENCE.MONTH) {
      return moment(timestamp).format('MMM YYYY');
    }
    if (cadence === CADENCE.QUARTER) {
      const quarter = moment(timestamp).utc().quarter();
      return `Q${quarter}, ${moment(timestamp).format('YYYY')}`;
    }
    if (cadence === CADENCE.PREV_QUARTER) {
      const prevQuarterTimestamp = moment(
        moment(timestamp).subtract(3, 'months')
      );
      return `Q${prevQuarterTimestamp.utc().quarter()}, ${moment(
        prevQuarterTimestamp
      ).format('YYYY')}`;
    }
    return timestamp;
  };