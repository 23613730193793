import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import BackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import * as layoutStyles from '../../styles/layoutStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
  },
  rowContainerNoWrap: {
    ...layoutStyles.rowContainerNoWrap,
    alignItems: 'center',
  },
  fabContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
    paddingRight: 80,
  },
  sectionTitle: {
    fontSize: 24,
    width: 'auto',
    height: 'auto',
    align: 'center',
  },
  dividerStyle: {
    marginTop: 0,
  },
  button: {
    marginLeft: 25,
  },
  backButton: {
    marginRight: 15,
  },
  textFieldStyle: {
    minWidth: 300,
    width: '30%',
    height: '100%',
    margin: 0,
  },
}));

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: grey[500],
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: grey[500],
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: grey[500],
      },
    },
  },
})(TextField);
/*
=======Buttons Input Model=====
 buttons = [
  { label: "",
    color : "primary" / "secondary",
    icon : <icon>,
    action : ()=>{},
  }
]
backButton = {action}
*/
export default function SectionHeader({
  title,
  marginTop = 0,
  marginBottom = 0,
  buttons,
  backButtonAction,
  prefixItems,
  appendingItems,
  isMainTitle = false,
  backgroundImage = null,
  isPaper = false,
  fab = null,
  onTitleChange = null,
  disableControls = false,
}) {
  const classes = useStyles();

  return (
    <div
      className={isPaper ? classes.container : null}
      style={{
        width: isMainTitle ? '100%' : '95%',
        'text-align': 'left',
        marginBottom,
        backgroundImage,
      }}
    >
      <div
        className={classes.rowContainerNoWrap}
        style={{
          marginTop,
          marginLeft: isMainTitle ? 25 : 50,
          paddingTop: 5,
          paddingBottom: 7,
        }}
      >
        {backButtonAction ? (
          <IconButton
            aria-label="back"
            className={classes.backButton}
            size="large"
            onClick={backButtonAction}
          >
            <BackIcon fontSize="inherit" />
          </IconButton>
        ) : null}
        {prefixItems || null}
        {onTitleChange ? (
          <CustomTextField
            className={classes.textFieldStyle}
            required
            disabled={disableControls}
            variant="filled"
            label="New Analysis Name"
            defaultValue={title}
            onChange={(event) => {
              onTitleChange(event.target.value);
            }}
            error={title === '' || title === null || title === undefined}
            helperText={
              title === '' || title === null || title === undefined
                ? 'Cannot be empty!'
                : null
            }
          />
        ) : (
          <Typography
            variant={isMainTitle ? 'h6' : 'h5'}
            className={classes.sectionTitle}
            color="textSecondary"
          >
            {title}
          </Typography>
        )}
        {appendingItems || null}
        {Array.isArray(buttons)
          ? buttons.map((button) => (
              <Button
                variant="contained"
                color={button.color ? button.color : 'primary'}
                size="medium"
                className={classes.button}
                startIcon={button.icon ? button.icon : null}
                onClick={button.action ? button.action : () => {}}
                key={button.label}
              >
                {button.label ? button.label : ''}
              </Button>
            ))
          : null}
        {fab ? <div className={classes.fabContainer}>{fab}</div> : null}
      </div>
      <Divider
        className={classes.dividerStyle}
        style={{
          marginLeft: isMainTitle ? 10 : 55,
          marginRight: isMainTitle ? 5 : 0,
        }}
      />
    </div>
  );
}

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      color: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  backButtonAction: PropTypes.func,
  prefixItems: PropTypes.node,
  appendingItems: PropTypes.node,
  isMainTitle: PropTypes.bool,
  backgroundImage: PropTypes.string,
  isPaper: PropTypes.bool,
  fab: PropTypes.any,
  onTitleChange: PropTypes.func,
  disableControls: PropTypes.bool,
};
