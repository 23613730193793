import { useContext } from 'react';
import isValidProp from '@emotion/is-prop-valid';
import {
  styled,
  Tabs,
  Tab,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';

import { TerritoryOverviewContext } from '../territoryOverviewContext';
import { Distribution } from '../constants';

export const DistributionTypeSelectorTabs = ({
  setIsDemandLoading,
  setIsSupplyLoading,
}) => {
  const types = [Distribution.DEMAND, Distribution.SUPPLY];
  const {
    distributionTypesAvailable,
    distributionType,
    updateDistributionType,
  } = useContext(TerritoryOverviewContext);

  const onTabClick = (newDistributionType) => {
    if (distributionType === newDistributionType) {
      return;
    }

    if (newDistributionType === Distribution.DEMAND) {
      setIsDemandLoading(true);
    }

    if (newDistributionType === Distribution.SUPPLY) {
      setIsSupplyLoading(true);
    }

    updateDistributionType(newDistributionType);
  };

  return (
    types && (
      <SettingsTabs value={distributionType}>
        {types.map((type) => {
          const firstCharCapitalized =
            type.charAt(0).toUpperCase() + type.slice(1);

          const optionAvailable = distributionTypesAvailable[type];

          return optionAvailable ? (
            <DistributionTypeTab
              key={`${type}-key`}
              label={firstCharCapitalized}
              value={type}
              onClick={() => {
                onTabClick(type);
              }}
              isCurrentSelection={
                distributionType.toUpperCase() === type.toUpperCase()
              }
              optionAvailable={optionAvailable}
            />
          ) : (
            <CustomTooltip
              title="We do not have the data to generate this report."
              arrow
            >
              <DistributionTypeTab
                key={`${type}-key`}
                label={firstCharCapitalized}
                value={type}
                optionAvailable={optionAvailable}
              />
            </CustomTooltip>
          );
        })}
      </SettingsTabs>
    )
  );
};

const getTabCursor = (isCurrentSelection, optionAvailable) => {
  if (!optionAvailable) {
    return 'not-allowed';
  }

  return isCurrentSelection ? 'default' : 'pointer';
};

const SettingsTabs = styled(Tabs)({
  display: 'flex',
  alignItems: 'center',
  minHeight: 40,
  svg: {
    height: 20,
  },
  '.MuiTabs-indicator': {
    display: 'none',
  },

  '.MuiTabs-flexContainer': {
    height: 40,
  },
});

const DistributionTypeTab = styled(Tab, { shouldForwardProp: isValidProp })(
  ({ theme: { themeColors }, optionAvailable, isCurrentSelection }) => ({
    opacity: 1,
    minHeight: 'auto',
    padding: '8px 10px',
    border: '1px solid',
    borderColor: optionAvailable
      ? themeColors.buttonBorderColor
      : themeColors.buttonDisabledSurfaceColor,
    textTransform: 'none',
    color: optionAvailable
      ? themeColors.buttonContentColor
      : themeColors.buttonDisabledContentColor,
    fontSize: 16,
    cursor: getTabCursor(isCurrentSelection, optionAvailable),
    backgroundColor: optionAvailable
      ? themeColors.buttonBackgroundColor
      : themeColors.buttonDisabledSurfaceColor,
    marginRight: -1,
    fontWeight: 400,

    '&:hover': optionAvailable && {
      color: themeColors.buttonHoverContentColor,
      backgroundColor: themeColors.buttonHoverBackgroundColor,
    },

    '&.Mui-selected': {
      color: themeColors.buttonActiveContentColor,
      backgroundColor: themeColors.buttonActiveBackgroundColor,
    },

    '.MuiTab-iconWrapper': {
      margin: '0 6px 0 0',
    },

    '&:first-of-type': {
      borderRadius: '4px 0px 0px 4px',
    },

    '&:last-of-type': {
      borderRadius: '0px 4px 4px 0px',
      marginRight: 0,
    },
  })
);

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...(props as TooltipProps)} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.themeColors.tooltipContentColor,
    backgroundColor: theme.themeColors.tooltipSurfaceColor,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.themeColors.tooltipSurfaceColor,
  },
}));
