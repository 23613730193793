import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  secondaryText: {
    color: themeColors.secondaryTextColor,
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 8,
    columnGap: 5,
  },
  labelContainer: {
    width: 120,
    maxWidth: 120,
  },
  contentContainer: {
    width: '100%',
    flexGrow: 2,
  },
}));

const InfoItem = ({ label = '', content = '' }) => {
  const classes = useStyles();

  return label !== '' && content !== '' ? (
    <div className={classes.itemContainer}>
      <div className={classes.labelContainer}>
        <Typography
          variant="caption"
          className={classes.secondaryText}
          align="left"
          noWrap
        >
          {label}
        </Typography>
      </div>
      <div className={classes.contentContainer}>
        <Typography variant="caption" align="left">
          {content}
        </Typography>
      </div>
    </div>
  ) : null;
};

export default function ProfileDetails({ entityUData }) {
  const classes = useStyles();
  const {
    location = {},
    contactInfo = {},
    education = {},
    originalUserId,
  } = entityUData;
  const { locationName = '', locationSubUnit = '', hospital = '' } = location;
  const { phone = '', email = '', address = '' } = contactInfo;
  const {
    undergradUniversity = '',
    graduateUniversity = '',
    graduationYear = '',
  } = education;

  return entityUData ? (
    <div className={classes.root}>
      <InfoItem label="Phone" content={phone} />
      <InfoItem label="Email" content={email} />
      <InfoItem label="Address" content={address} />
      <InfoItem label="Location" content={locationName} />
      <InfoItem label="Department" content={locationSubUnit} />
      <InfoItem label="Undergraduate" content={undergradUniversity} />
      <InfoItem label="Graduate" content={graduateUniversity} />
      <InfoItem label="Grad Year" content={graduationYear} />
      <InfoItem label="Practice" content={hospital} />
      <InfoItem label="HCP ID" content={originalUserId} />
    </div>
  ) : null;
}
