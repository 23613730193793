import { useContext } from 'react';
import { Box, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { EntityDrawerContext } from './data/entityDrawerContext';
import { ERROR_TYPES_ENUM } from '../../constants';
import { EntityDrawerError } from './EntityDrawerError';
import { DrawerProfileSkeleton } from '../skeletons/DrawerProfileSkeleton';
import { useEntityDrawerProfile } from './data/useEntityDrawerData';

const ProfileBox = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  width: '100%',
  color: themeColors.neutral90,
  backgroundColor: themeColors.contentCardBackgroundColor,
  flexDirection: 'column',
  gap: 16,
  border: '1px solid',
  borderColor: themeColors.borderPrimaryColor,
  borderRadius: 10,
  padding: 16,
}));

const ProfileField = styled(Box)(() => ({
  display: 'flex',
  gap: 8,
}));

const ProfileKey = styled(Typography)(({ theme: { themeColors } }) => ({
  width: 97,
  color: themeColors.tertiaryColor,
}));

const ProfileValue = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
}));

export const EntityDrawerProfileTab = () => {
  const { productLineId, marketId, projectId, regionId, entityType, entityId } =
    useContext(EntityDrawerContext);

  const { data, isFetching, isError } = useEntityDrawerProfile({
    productLineId,
    marketId,
    projectId,
    regionId,
    entityType,
    entityId,
  });

  if (isFetching) {
    return (
      <Box pr={3} pl={3} pt={3} width="100%">
        <DrawerProfileSkeleton />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box pr={3} pl={3} pt={3} width="100%">
        <EntityDrawerError
          errorType={ERROR_TYPES_ENUM.RETRY}
          retryType="profile"
        />
      </Box>
    );
  }
  return (
    <Box pr={3} pl={3} pt={3} width="100%">
      <ProfileBox>
        {data &&
          Object.entries(data).map(([key, value], index) => (
            <ProfileField key={index}>
              <ProfileKey variant="body2">{key}</ProfileKey>
              <ProfileValue variant="body2">{value}</ProfileValue>
            </ProfileField>
          ))}
      </ProfileBox>
    </Box>
  );
};
