import Button from '@mui/material/Button';

import { styled } from '@mui/material';
import {
  ModalDisplay,
  ModalFooter,
  ModalHeader,
} from '../../generic/modalDisplay';
import { StyledModalBody } from '../../configview/styledComponents';

export const ConfirmationModalBody = styled(StyledModalBody)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.neutral60,
    height: 'fit-content',
    overflow: 'auto',
    padding: 20,
  })
);

export function RefreshConfirmationModal({
  isOpen,
  onClose,
  onAccept,
  acceptButtonText,
  body,
  titleText,
  showCloseButton = true,
  showAcceptButton = true,
  disableCloseButton = false,
  disableAcceptButton = false,
}) {
  return (
    <ModalDisplay
      isOpen={isOpen}
      style={{
        minHeight: 228,
        height: 'fit-content',
        maxWidth: 431,
      }}
    >
      <ModalHeader onClose={onClose} shouldShowXIcon={!disableCloseButton}>
        {titleText}
      </ModalHeader>
      <ConfirmationModalBody>{body}</ConfirmationModalBody>
      <ModalFooter>
        {showCloseButton && (
          <Button
            variant="outlined"
            onClick={onClose}
            disabled={disableCloseButton}
          >
            Close
          </Button>
        )}
        {showAcceptButton && (
          <Button
            variant="contained"
            onClick={onAccept}
            disabled={disableAcceptButton}
          >
            {acceptButtonText}
          </Button>
        )}
      </ModalFooter>
    </ModalDisplay>
  );
}
