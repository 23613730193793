import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { UserMenuButton } from './userMenuButton';
import { trackSignInOrOut } from '../../../trackers/appEventTracker';
import { clearCookie as clearCookieAction } from '../../../slices/login';
import {
  environmentConfigs,
  findEnvironmentByHostname,
} from '../../../request/config';

export const SignOutButton = ({ errorOnLoading = false }) => {
  const dispatch = useDispatch();
  const clearCookie = async () => {
    const response = await dispatch(clearCookieAction());
    return response;
  };

  const [loggingOut, setLoggingOut] = useState(false);

  const onSignOut = async () => {
    trackSignInOrOut('sign out');
    setLoggingOut(true);
    const { payload, error } = await clearCookie();
    const currentEnvironment = findEnvironmentByHostname(
      window.location.hostname
    );

    switch (currentEnvironment) {
      case 'localhost':
        window.location.href = environmentConfigs.localhost.url;
        break;

      default:
        if (!error && payload.data?.logout_uri) {
          window.location.href = payload.data.logout_uri;
        }
        break;
    }

    setLoggingOut(false);
  };

  return errorOnLoading ? (
    <Button
      variant="outlined"
      disabled={loggingOut}
      onClick={onSignOut}
      startIcon={<LogoutIcon />}
      sx={{
        marginTop: 30,
        color: undefined,
      }}
    >
      Sign-out
    </Button>
  ) : (
    <UserMenuButton
      onClick={onSignOut}
      disabled={loggingOut}
      startIcon={<LogoutIcon />}
      buttonText="Sign-out"
    />
  );
};
