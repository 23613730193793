import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';

const useStyles = makeStyles((theme) => ({
  iconButtonStyle: {
    padding: '0.4rem',
    marginTop: '0.6rem',
    marginRight: '0.6rem',
    size: 'medium',
    color: 'inherit',
    backgroundColor: theme.palette.background.default,
  },
  iconStyle: {
    height: '2.5rem',
    width: '2.5rem',
    margin: 0,
  },
}));

export default function QuickCloseButton() {
  const classes = useStyles();
  const routeHistory = useHistory();

  return (
    <Fade direction="left" in timeout={2000} mountOnEnter unmountOnExit>
      <div style={{ width: '100%', height: 'auto', textAlign: 'right' }}>
        <IconButton
          onClick={() => {
            routeHistory.push('/');
          }}
          className={classes.iconButtonStyle}
          size="large"
        >
          <CloseIcon className={classes.iconStyle} />
        </IconButton>
      </div>
    </Fade>
  );
}
