import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Popover, Slide, styled, Tooltip } from '@mui/material';
import {
  AccountCircle,
  Dns as DataIcon,
  Settings,
  Group,
  Widgets,
  BarChart,
  ToggleOn,
  ScienceOutlined,
  Assignment,
  DisplaySettings,
} from '@mui/icons-material';

import MaptualLogo from '../../assets/maptual_logo_only_dark.svg?react';
import DataSourceMenu from '../dataconnectors/dataSourceMenu';
import { isProd } from '../../request/config';
import { APPVIEWS, RBAC_PERMISSIONS, ROUTES } from '../../constants';
import { themeColors as adminColors } from '../../styles/adminStyles';
import { EventLoggerNavButton } from '../eventLogger/eventLoggerNavButton';
import { CustomIconButton, Separator } from './navBarCommon';
import { NAVBAR_DRAWER_WIDTH } from '../../containers/application/appViews/constants';
import { UserMenuPopover } from '../accountCard/usermenu/userMenuPopover';
import { useIsAuthorized } from '../../hooks/useIsAuthorized';

const NAVBAR_POPUP_TYPES = {
  DATA: 'DATA',
  PROFILE: 'PROFILE',
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'fixed',
    gap: 32,
    width: NAVBAR_DRAWER_WIDTH,
    maxWidth: NAVBAR_DRAWER_WIDTH,
    padding: '16px 12px',
    height: '100%',
    backgroundColor: adminColors.darkTheme.mainBackground,
    borderRight: `1px solid ${adminColors.darkTheme.borderLowContrast}`,
    zIndex: 1,
  },
  logoStyle: {
    height: 'auto',
    width: '1.8rem',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  appBarStyle: {
    padding: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  tabsStyle: {
    flexGrow: 1,
    margin: 0,
    height: '100%',
  },
  tabStyle: {
    fontSize: '0.8rem',
    minWidth: '0.5rem',
    marginRight: '0.6rem',
    width: 'auto',
  },
}));

const IconGrouping = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
});

export default function NavBar({ appView }) {
  const classes = useStyles();
  const routeHistory = useHistory();

  const [popupType, setPopupType] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const isAuthorizedFor = useIsAuthorized();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const isSphereView =
    appView === APPVIEWS.SPHERE || appView === APPVIEWS.HEADOFFICE_OLDNAME;

  return (
    <Slide direction="right" in mountOnEnter unmountOnExit>
      <div className={classes.root}>
        <MaptualLogo
          className={classes.logoStyle}
          onClick={() => {
            routeHistory.push(ROUTES.ROOT);
          }}
        />
        <IconGrouping>
          {isAuthorizedFor([RBAC_PERMISSIONS.VIEW_FE_EVENT_LOG]) && (
            <EventLoggerNavButton />
          )}
          {isSphereView ? null : (
            <>
              {isAuthorizedFor([RBAC_PERMISSIONS.FEATURE_TOGGLES_ADMIN]) && (
                <CustomIconButton
                  onClick={() => {
                    routeHistory.push(`${ROUTES.ADMIN}/FeatureToggleView`);
                  }}
                >
                  <ToggleOn />
                </CustomIconButton>
              )}
              <CustomIconButton
                onClick={() => {
                  routeHistory.push(`${ROUTES.ADMIN}${ROUTES.CONFIG}`);
                }}
              >
                <Settings />
              </CustomIconButton>
              <CustomIconButton
                onClick={() => {
                  routeHistory.push(`${ROUTES.ADMIN}${ROUTES.USERS}`);
                }}
              >
                <Group />
              </CustomIconButton>
              <CustomIconButton
                onClick={() => {
                  routeHistory.push(`${ROUTES.ADMIN}${ROUTES.APP_CONFIG}`);
                }}
              >
                <DisplaySettings />
              </CustomIconButton>
              <CustomIconButton
                onClick={() => {
                  routeHistory.push(`${ROUTES.ADMIN}${ROUTES.PROJECTS}`);
                }}
              >
                <Widgets />
              </CustomIconButton>
              <CustomIconButton
                onClick={() => {
                  routeHistory.push(ROUTES.ADMIN_CURATION);
                }}
              >
                <BarChart />
              </CustomIconButton>
              {isAuthorizedFor([RBAC_PERMISSIONS.VIEW_DATA_REPORTS]) && (
                <Tooltip title="Data Report">
                  <CustomIconButton
                    onClick={() => {
                      routeHistory.push(`${ROUTES.ADMIN}${ROUTES.DATA_REPORT}`);
                    }}
                  >
                    <Assignment />
                  </CustomIconButton>
                </Tooltip>
              )}

              {/* Hide for now since it's not used */}
              {/* <Tooltip title="CSV Delivery Dashboard"> */}
              {/*  <CustomIconButton */}
              {/*    onClick={() => { */}
              {/*      routeHistory.push(ROUTES.ADMIN_CSV_DELIVERY); */}
              {/*    }} */}
              {/*  > */}
              {/*    <UploadFile /> */}
              {/*  </CustomIconButton> */}
              {/* </Tooltip> */}
            </>
          )}
        </IconGrouping>
        <Separator />
        <IconGrouping>
          {isSphereView ? null : (
            <>
              {isAuthorizedFor([RBAC_PERMISSIONS.VIEW_DATA_CONNECTORS]) && (
                <CustomIconButton
                  onClick={(event) => {
                    setPopupType(NAVBAR_POPUP_TYPES.DATA);
                    handleClick(event);
                  }}
                >
                  <DataIcon />
                </CustomIconButton>
              )}
              {!isProd && (
                <CustomIconButton
                  onClick={() => {
                    routeHistory.push(`${ROUTES.ADMIN}${ROUTES.SETTINGS}`);
                  }}
                >
                  <ScienceOutlined />
                </CustomIconButton>
              )}
            </>
          )}
          <CustomIconButton
            onClick={(event) => {
              setPopupType(NAVBAR_POPUP_TYPES.PROFILE);
              handleClick(event);
            }}
          >
            <AccountCircle />
          </CustomIconButton>
          {popupType === NAVBAR_POPUP_TYPES.PROFILE && (
            <UserMenuPopover
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              compact
            />
          )}

          {!isSphereView && popupType === NAVBAR_POPUP_TYPES.DATA && (
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <DataSourceMenu onClose={handleClose} />
            </Popover>
          )}
        </IconGrouping>
      </div>
    </Slide>
  );
}
