import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
// import CircularProgress from '@mui/material/CircularProgress';
// import { CURATION_GRAPHS } from './constants';

const GRAPH_TYPE_TO_NAME_MAPPING = {
  Region: 'Region Distribution',
  Active: 'List Distribution: Active',
  Previous: 'List Distribution: Previous',
};

const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  header: {
    height: '22px',
    fontSize: 18,
    fontWeight: 600,
    color: '#E0E4EB',
    marginBottom: '16px',
  },
  graphContainer: {
    display: 'flex',
    height: '100%',
    maxWidth: '850px',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: '30px',
    marginBottom: '24px',
  },
  graphContent: {
    display: 'flex',
    height: '280px',
    width: '410px',
    borderRadius: 4,
  },
  graphLoading: {
    display: 'flex',
    height: '280px',
    width: '410px',
    justifyContent: 'center',
    alignItems: 'center',
    background: themeColors.mainSurfaceBackground,
    padding: '0px 8px',
    borderRadius: 4,
    flexGrow: 1,
  },
  titleLoading: {
    display: 'flex',
    height: '20px',
    width: '80px',
    marginBottom: '18px',
    paddingLeft: '24px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleCircularProgress: {
    maxHeight: '20px',
    maxWidth: '20px',
    size: 20,
  },
}));

function CurationChartProvider(props) {
  const { childGraph, className } = props;
  const classes = useStyles();
  // const [graphTitle, setGraphTitle] = useState(childGraph.title || '');

  // if (!graphTitle && childGraph.type !== CURATION_GRAPHS.CPS) {
  //   setGraphTitle(childGraph.title);
  // }

  return (
    <div className={[className, classes.root].join(' ')}>
      <Typography className={classes.header}>{childGraph.title}</Typography>
      {/* {graphTitle ? (
        <Typography className={classes.header}>{graphTitle}</Typography>
      ) : (
        <div className={classes.titleLoading}>
          <CircularProgress className={classes.titleCircularProgress} />
        </div>
      )} */}
      <div className={classes.graphContainer}>
        {Object.entries(GRAPH_TYPE_TO_NAME_MAPPING).map(([key, value]) => (
          <childGraph.component
            {...props}
            graphType={key}
            key={key}
            title={value}
            // onLoad={(titleSuffix) =>
            //   setGraphTitle(`${childGraph.title} - ${titleSuffix}`)
            // }
            onLoad={() => {}}
            classGraphContent={classes.graphContent}
            classLoading={classes.graphLoading}
          />
        ))}
      </div>
    </div>
  );
}

export default CurationChartProvider;
