import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { styled, FormControlLabel, FormLabel } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { theme } from '@odaia/ui/src/theme';
import {
  CLEAR_CACHE_STATES,
  clearProjectCache,
  closeClearProjectCacheDialog,
} from '../../slices/adminView';

const ClearFailedIcon = styled(ClearIcon)({
  color: 'red',
  verticalAlign: 'middle',
  fontSize: theme.typography.h1.fontSize,
});

const ClearCompleteIcon = styled(DoneIcon)({
  color: 'green',
  verticalAlign: 'middle',
  fontSize: theme.typography.h1.fontSize,
});

const StyledDialogContentText = styled(DialogContentText)({
  minHeight: 50,
});

const StyledDialogContent = styled(DialogContent)({
  minWidth: 600,
});

const CLEAR_CACHE_FLAGS = {
  ALL: 'all',
  CDN: 'cdn',
  S3: 's3',
};

const renderInProgress = (projectName) => (
  <>
    <DialogTitle>
      <h1>
        <CircularProgress size={24} /> In Progress
      </h1>
    </DialogTitle>
    <StyledDialogContentText>
      <Typography>
        {`Please wait while the cache is cleared for ${
          projectName || `all projects`
        }...`}
      </Typography>
    </StyledDialogContentText>
  </>
);

const renderError = () => (
  <>
    <DialogTitle>
      <h1>
        <ClearFailedIcon /> Error
      </h1>
    </DialogTitle>
    <StyledDialogContentText>
      <Typography>
        An error occurred while attempting to clear cache.
      </Typography>
      <Typography>Please check application logs for more details. </Typography>
    </StyledDialogContentText>
  </>
);

const renderConfirmation = (projectName, setClearCacheFlag) => (
  <>
    <DialogTitle id="alert-dialog-title">
      {`Are you sure you want to clear cache for ${
        projectName || `all projects`
      }?`}
    </DialogTitle>
    <DialogContent>
      <FormControl>
        <FormLabel>Select what cache to clear:</FormLabel>
        <RadioGroup
          row
          defaultValue={CLEAR_CACHE_FLAGS.ALL}
          onChange={(event, value) => {
            setClearCacheFlag(value);
          }}
        >
          <FormControlLabel
            value={CLEAR_CACHE_FLAGS.ALL}
            control={<Radio />}
            label="All"
          />
          <FormControlLabel
            value={CLEAR_CACHE_FLAGS.CDN}
            control={<Radio />}
            label="CDN"
          />
          <FormControlLabel
            value={CLEAR_CACHE_FLAGS.S3}
            control={<Radio />}
            label="S3"
          />
        </RadioGroup>
      </FormControl>
    </DialogContent>
  </>
);

const renderSuccess = () => (
  <>
    <DialogTitle>
      <h1>
        <ClearCompleteIcon /> Success
      </h1>
    </DialogTitle>
    <StyledDialogContentText>
      <Typography>Cache successfully cleared.</Typography>
    </StyledDialogContentText>
  </>
);

export default function clearCacheDialog({ onClose }) {
  const clearCacheState = useSelector((state) => state.app.clearCacheState);
  const clearCacheProjectName = useSelector(
    (state) => state.app.clearCacheProjectName
  );
  const clearCacheProjectId = useSelector(
    (state) => state.app.clearCacheProjectId
  );
  const dispatch = useDispatch();
  const [clearCacheFlag, setClearCacheFlag] = useState(CLEAR_CACHE_FLAGS.ALL);
  const isOpen = clearCacheState !== CLEAR_CACHE_STATES.IDLE;
  const isCloseButtonEnabled = clearCacheState !== CLEAR_CACHE_STATES.PENDING;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <StyledDialogContent>
        {clearCacheState === CLEAR_CACHE_STATES.PENDING &&
          renderInProgress(clearCacheProjectName)}
        {clearCacheState === CLEAR_CACHE_STATES.ERROR && renderError()}
        {clearCacheState === CLEAR_CACHE_STATES.FULFILLED && renderSuccess()}
        {clearCacheState === CLEAR_CACHE_STATES.CONFIRM &&
          renderConfirmation(clearCacheProjectName, setClearCacheFlag)}
        <DialogActions>
          <Button
            size="large"
            aria-label="Close"
            onClick={() => dispatch(closeClearProjectCacheDialog())}
            disabled={!isCloseButtonEnabled}
          >
            Close
          </Button>
          {clearCacheState === CLEAR_CACHE_STATES.CONFIRM && (
            <Button
              size="large"
              aria-label="Clear Cache"
              onClick={() => {
                dispatch(
                  clearProjectCache({
                    projectId: clearCacheProjectId,
                    clearCacheFlag,
                  })
                );
              }}
              disabled={!isCloseButtonEnabled}
            >
              Yes, clear cache
            </Button>
          )}
        </DialogActions>
      </StyledDialogContent>
    </Dialog>
  );
}
