import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { EntityDrawerError } from '../EntityDrawerError';
import { ERROR_TYPES_ENUM } from '../../../constants';
import { EntityDrawerContext } from '../data/entityDrawerContext';
import { CHART_TYPES_ENUM } from '../../../../shared/graphs/constants';
import { useEntityDrawerChartData } from '../data/useEntityDrawerData';
import { ProgressCircle } from '../../../../shared/graphs/circleGraph/progressCircle';
import { DrawerChartSkeleton } from '../../skeletons/DrawerChartSkeleton';

export const EntityDrawerCircleGraphCard = ({
  objectiveId,
  sectionTitle,
  metrics,
}) => {
  const { entityType, entityId, regionId, projectId, productLineId } =
    useContext(EntityDrawerContext);

  const selectedMetric = metrics?.length > 0 ? metrics[0] : null;

  if (!selectedMetric) {
    return null;
  }

  const { cadences, rxType, tooltipText, tooltipList } = selectedMetric;
  const {
    data: chartResponse,
    isFetching,
    isError,
  } = useEntityDrawerChartData({
    productLineId,
    projectId,
    regionId,
    objectiveId,
    chartType: CHART_TYPES_ENUM.CIRCLE,
    metric: rxType,
    cadence: cadences[0],
    entityType,
    entityId,
  });

  const [progressValue, setProgressValue] = useState(0);
  const [valueDescription, setValueDescription] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [title, setTitle] = useState('');
  const [tooltip, setTooltip] = useState();

  useEffect(() => {
    if (chartResponse) {
      setProgressValue(chartResponse.data?.percentage);
      setValueDescription(chartResponse.data?.description);
      setTimestamp(chartResponse.timestamp);
      setTitle(
        `${sectionTitle ? `${sectionTitle}, ` : ''}${chartResponse?.title}`
      );
    }
  }, [chartResponse, isFetching, isError]);

  useEffect(() => {
    if (
      title?.length > 0 &&
      tooltipText?.length > 0 &&
      tooltipList?.length > 0
    ) {
      setTooltip({ title, description: tooltipText, listData: tooltipList });
    }
  }, [title, tooltipText, tooltipList]);

  return (
    <>
      {isFetching && (
        <Box width="50%" height="290px">
          <DrawerChartSkeleton width="50%" height="290px" />
        </Box>
      )}
      {isError && (
        <Box width="50%">
          <EntityDrawerError
            errorType={ERROR_TYPES_ENUM.RETRY}
            retryType="chart"
          />
        </Box>
      )}

      {!!chartResponse && (
        <ProgressCircle
          title={title}
          progressValue={progressValue}
          valueDescription={valueDescription}
          caption={timestamp}
          isLoading={isFetching}
          containerWidth="50%"
          containerMinWidth="320px"
          tooltip={tooltip}
          isGraphAvailable={!!progressValue}
        />
      )}
    </>
  );
};
