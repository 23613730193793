import { useState } from 'react';
import { Box, Skeleton, useTheme, styled } from '@mui/material';
import { BarGraphContainer } from './barGraph/barGraphContainer';
import { LineGraphContainer } from './lineGraph/lineGraphContainer';
import { GraphModal } from './graphModal';

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'graphType',
})(({ graphType }) => ({
  height: '35px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: graphType === 'line' ? '140px' : '120px',
}));

const LoadingContainer = styled(Box)(() => ({
  margin: 'auto',
}));

const StyledProgress = styled(Skeleton)(({ theme: { themeColors } }) => ({
  color: themeColors.buttonContentColor,
  transform: 'none',
}));

export const GraphCellContainer = ({
  isLoading,
  row,
  valueType,
  graphType,
  chartLabels,
  graphTitle = '',
  yTitle = '',
  tooltipDataPointTitle = '',
  basicColor = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { themeColors } = useTheme();
  return (
    graphType && (
      <>
        <Container
          graphType={graphType}
          onClick={(e) => {
            if (!isLoading) {
              e.preventDefault();
              e.stopPropagation();
              setIsModalOpen(true);
            }
          }}
        >
          {isLoading && (
            <LoadingContainer>
              <StyledProgress
                width={graphType === 'line' ? 140 : 120}
                height={35}
              />
            </LoadingContainer>
          )}
          {graphType === 'bar' && !isLoading && (
            <BarGraphContainer
              row={row}
              chartLabels={chartLabels}
              valueType={valueType}
            />
          )}

          {graphType === 'line' && !isLoading && (
            <LineGraphContainer
              row={row}
              chartLabels={chartLabels}
              valueType={valueType}
              basicColor={basicColor}
            />
          )}
        </Container>
        <GraphModal
          isOpen={isModalOpen}
          handleClose={() => {
            setIsModalOpen(false);
          }}
          valueType={valueType}
          graphType={graphType}
          data={row}
          chartLabels={chartLabels}
          graphTitle={graphTitle}
          yTitle={yTitle}
          tooltipDataPointTitle={tooltipDataPointTitle}
          graphColor={themeColors.dataGeneralColor}
        />
      </>
    )
  );
};
