import { TableComponentProps } from '@odaia/shared-components/src/callPlan/callPlanTable';
import { EntitiesTable } from '@odaia/shared-components/src/entitiesTable';

export const TableComponent = (props: TableComponentProps) => (
  <EntitiesTable
    {...props}
    refetch={() => {}}
    isFetching={false}
    shouldShowError={false}
  />
);
