import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import { useContext } from 'react';
import { styled } from '@mui/material';
import { userCreatedListOptions } from '@odaia/shared-components/src/userCreatedListsConfig';
import { trackSegmentSelection } from '../../../trackers/appEventTracker';
import { trackLearnMoreSelection } from '../../../trackers/mixpanel';
import SegmentDescriptionPopover from './segmentDescriptionPopover';
import { FieldContext } from '../../../containers/application/appViews/field/fieldContext';
import toKebabCase from '../../../utils/toKebabCase';
import {
  DescriptionBody,
  ListSelectionDescription,
  ListSelectionItemButton,
} from '../../../containers/application/appViews/field/list/listSelectionComponents';

const SegmentDescription = styled('div')(({ theme: { themeColors } }) => ({
  marginTop: 9,
  marginBottom: 10,
  display: 'inline',
  color: themeColors.primaryMaptualListFilteringColor,
}));

const StyledTypography = styled(Typography)(() => ({
  display: 'inline',
  overflowWrap: 'break-word',
}));

const SegmentMetric = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
  marginTop: 10,
  marginBottom: 10,
}));

const SegmentSelection = styled('div')(({ theme: { themeColors } }) => ({
  borderTop: '1px solid',
  borderColor: themeColors.borderLowContrast,
  padding: 9,
  paddingTop: 13,
  paddingLeft: 21,
}));

const Subtitle = styled(Typography)(() => ({
  lineHeight: 1.4,
}));

const SegmentButtonGroup = styled('div')(() => ({
  marginTop: 13,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
}));

export function deleteFilterFromFilters(currentFilters, filterToDelete) {
  const newFilters = { ...currentFilters };
  delete newFilters[filterToDelete];
  return newFilters;
}

function generateUserCreatedListDescription(selectedUserCreatedList) {
  return (
    <SegmentDescription>
      <Typography variant="body2">
        {userCreatedListOptions[selectedUserCreatedList].segmentDescription ||
          null}
      </Typography>
    </SegmentDescription>
  );
}

const generateSegmentDescription = (descriptionsList) => {
  if (descriptionsList.length === 0) {
    return null;
  }
  const components = descriptionsList.map((desc) => {
    if (desc.type === 'typography' || desc.type === 'totalMarket') {
      return (
        <StyledTypography key={desc.label} variant="body2">
          {desc.label}
        </StyledTypography>
      );
    }
    if (desc.type === 'timePeriod') {
      return (
        <SegmentDescriptionPopover
          key="timePeriodPopover"
          buttonText={desc.label}
          title="Timeline"
          description="The current timeframe is based on when the metric data was last
            ingested"
        />
      );
    }
    if (desc.type === 'productName') {
      return (
        <SegmentDescriptionPopover
          key="productNamePopover"
          buttonText={desc.label}
          title="Target Product"
          description={desc.longProductName}
        />
      );
    }
    return null;
  });
  return <DescriptionBody>{components}</DescriptionBody>;
};

const INTERCOM_ID_NOT_AVAILABLE = -1;

const SegmentPopover = ({
  segment,
  defaultSegment,
  handleDropdownClose,
  selectMaptualListSegment,
  selectCustomList,
  segmentState = {},
  selectedUserCreatedList,
  setSelectedUserCreatedList,
}) => {
  const { maptualList } = useContext(FieldContext);
  const segmentNames = maptualList?.segments?.map((s) => s.name);
  const fullSegmentData = maptualList?.segments;
  const { themeColors } = useTheme();

  const maptualListName = maptualList?.metadata?.listName;
  const showLearnMoreButton =
    !selectedUserCreatedList &&
    segmentState.intercomId !== INTERCOM_ID_NOT_AVAILABLE;
  const handleLearnMoreClick = () => {
    window.Intercom('showArticle', segmentState.intercomId);
    trackLearnMoreSelection(maptualListName, 'Segment Description', segment);
  };

  const handleSegmentSelect = (e) => {
    const selectedSegment = e?.target?.textContent;

    handleDropdownClose();
    setSelectedUserCreatedList(null);
    const currentSegmentData = fullSegmentData?.find(
      (seg) => seg.name.toLowerCase() === selectedSegment.toLowerCase()
    );
    const subsegments = currentSegmentData
      ? currentSegmentData.subSegments
      : [];

    if (selectedSegment) {
      selectCustomList(null);
      if (selectedSegment === defaultSegment) {
        selectMaptualListSegment(null);
      } else if (subsegments.length !== 2) {
        selectMaptualListSegment(selectedSegment);
      } else {
        selectMaptualListSegment(selectedSegment);
      }

      trackSegmentSelection(selectedSegment, maptualListName);
    }
  };

  const generateDescription = (descriptionsList, userCreatedListSelection) => {
    if (userCreatedListSelection) {
      return generateUserCreatedListDescription(userCreatedListSelection);
    }
    return generateSegmentDescription(descriptionsList);
  };

  const showDescription =
    showLearnMoreButton ||
    segmentState.metric ||
    selectedUserCreatedList ||
    segmentState.descriptionConfig?.length > 0;

  return (
    <>
      {showDescription && (
        <ListSelectionDescription
          showLearnMoreButton
          onLearnMoreClick={handleLearnMoreClick}
        >
          {segmentState.descriptionConfig
            ? generateDescription(
                segmentState.descriptionConfig,
                selectedUserCreatedList
              )
            : null}

          {segmentState.metric ? (
            <SegmentMetric>
              <Subtitle
                variant="subtitle1"
                color={themeColors.segmentSelectionCaptionColor}
              >
                Metric data
              </Subtitle>

              <Typography
                data-testid="test-segment-metric-data"
                variant="body2"
                color={themeColors.primaryMaptualListFilteringColor}
              >
                {segmentState.metric}
              </Typography>
            </SegmentMetric>
          ) : null}
        </ListSelectionDescription>
      )}
      <SegmentSelection>
        <Subtitle
          variant="subtitle1"
          color={themeColors.primaryMaptualListFilteringColor}
        >
          Segments
        </Subtitle>

        <SegmentButtonGroup>
          <ListSelectionItemButton
            className={`test-segment-default intercom-field-segment-${toKebabCase(
              defaultSegment
            )}`}
            onClick={handleSegmentSelect}
            key="default"
            selected={defaultSegment === segment && !selectedUserCreatedList}
          >
            {defaultSegment}
          </ListSelectionItemButton>

          {Array.isArray(segmentNames)
            ? segmentNames.map((segmentName, i) => (
                <ListSelectionItemButton
                  className={`test-segment-list intercom-field-segment-${toKebabCase(
                    segmentName
                  )}`}
                  onClick={handleSegmentSelect}
                  key={i}
                  selected={segmentName === segment && !selectedUserCreatedList}
                >
                  {segmentName}
                </ListSelectionItemButton>
              ))
            : null}
        </SegmentButtonGroup>
      </SegmentSelection>
    </>
  );
};

export default SegmentPopover;
