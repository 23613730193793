import censorResponse, { censorMaptualList } from '../modules/demoCensor';

export function listMembershipEntriesForHcp(newUserCreatedLists, hcpId) {
  let newEntries = {};

  if (newUserCreatedLists) {
    newEntries = Object.entries(newUserCreatedLists).reduce(
      (previousValue, [listName, listValues]) => ({
        ...previousValue,
        [listName]: listValues.includes(hcpId),
      }),
      {}
    );
  }

  return newEntries;
}

export const addNewHcpsToListItems = (
  maptualListItems,
  newUserCreatedLists
) => {
  const listWithMembershipValues = maptualListItems.map((hcp) => {
    const hcpId = Number(hcp.snowflakeEntityId);
    const newHcp = { ...hcp };

    const existentMembershipItems = listMembershipEntriesForHcp(
      newUserCreatedLists,
      hcpId
    );

    return {
      ...newHcp,
      listMemberships: {
        starred: existentMembershipItems?.starred ?? false,
        noSee: existentMembershipItems?.noSee ?? false,
        longTermLeave: existentMembershipItems?.longTermLeave ?? false,
      },
    };
  });

  return listWithMembershipValues;
};

export function addListCountsToMaptualList(
  updatedMaptualList,
  userCreatedLists
) {
  if (!userCreatedLists) {
    return {
      ...updatedMaptualList,
    };
  }

  const listCounts = Object.fromEntries(
    Object.entries(userCreatedLists).map(([k, v]) => [k, v.length])
  );

  return {
    ...updatedMaptualList,
    userCreatedListCounts: listCounts,
  };
}

export const deserializeMaptualList = (data) => {
  if (!data || !data.list || !Array.isArray(data.list.items)) {
    return null;
  }

  const dataToFormat = { ...data };

  dataToFormat.list = {
    ...dataToFormat.list,
    items: dataToFormat.list.items.map((item) => ({
      ...item,
      entityId: String(item.entityId),
      snowflakeEntityId: String(item.snowflakeEntityId),
    })),
  };

  const formattedData = censorResponse(censorMaptualList(dataToFormat));
  const entityList =
    formattedData?.list && Array.isArray(formattedData.list.items)
      ? formattedData.list.items
      : [];
  const sortedList = entityList.sort(
    (a, b) => b.maptualFractionalScore - a.maptualFractionalScore
  );

  const newMaptualList = { ...formattedData };
  const newList = { ...newMaptualList.list, items: [...sortedList] };
  newMaptualList.list = newList;

  newMaptualList.segments = newMaptualList.segments.map((segment) => ({
    name: segment.name,
    description: segment.description,
    metric: segment.metric,
    intercomId: segment.intercomId,
    subSegments: segment.subSegments,
  }));

  return newMaptualList;
};

export const updateMaptualListUserCreatedListProperties = (
  state,
  newUserCreatedListsFromPayload
) => {
  const projectProductLineId = state.projectItem?.metadata?.productLineId;
  const maptualListItems = state.maptualList.list.items;

  if (
    !newUserCreatedListsFromPayload ||
    !newUserCreatedListsFromPayload?.[projectProductLineId]
  ) {
    return {
      ...state.maptualList,
      list: {
        ...state.maptualList?.list,
        items: maptualListItems,
      },
    };
  }

  const updatedHcps = addNewHcpsToListItems(
    maptualListItems,
    newUserCreatedListsFromPayload[projectProductLineId]
  );

  const updatedMaptualList = {
    ...state.maptualList,
    list: {
      ...state.maptualList.list,
      items: updatedHcps,
    },
  };

  const finalMaptualList = addListCountsToMaptualList(
    updatedMaptualList,
    newUserCreatedListsFromPayload[projectProductLineId]
  );

  return finalMaptualList;
};

export const addUserCreatedListPropertiesToMaptualList = (
  maptualList,
  newUserCreatedLists
) => {
  const maptualListItems = maptualList?.list.items;

  if (!newUserCreatedLists) {
    return {
      ...maptualList,
      list: {
        ...maptualList?.list,
        items: maptualListItems,
      },
    };
  }

  const updatedHcps = addNewHcpsToListItems(
    maptualListItems,
    newUserCreatedLists
  );

  const updatedMaptualList = {
    ...maptualList,
    list: {
      ...maptualList?.list,
      items: updatedHcps,
    },
  };

  const finalMaptualList = addListCountsToMaptualList(
    updatedMaptualList,
    newUserCreatedLists
  );

  return finalMaptualList;
};
