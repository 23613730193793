import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import Chart from 'react-apexcharts';
import { darkThemeColors } from '@odaia/ui/src/colorConfig';
import GraphConfig from './graphConfig';
import DataLabelAtom from '../labels/dataLabelAtom';

const maxStatsWidth = '15rem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    paddingTop: '1rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    paddingBottom: '1rem',
    marginBottom: '1vh',
    marginRight: '3rem',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 3,
  },
  horiContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  lengendContainer: {
    flexGrow: 1,
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  graphTitleContainer: {
    width: '100%',
    height: 'auto',
    margin: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  primaryText: {
    fontSize: 24,
    fontWeight: 900,
    color: theme.palette.text.primary,
    lineHeight: 1.1,
    maxWidth: maxStatsWidth,
    marginBottom: '0.25rem',
  },
  secondaryText: {
    fontSize: 16,
    fontWeight: 500,
    maxWidth: maxStatsWidth,
    lineHeight: 1.1,
    marginBottom: '0.05rem',
    color: theme.palette.text.secondary,
  },
  tertiaryText: {
    fontSize: 12,
    fontWeight: 400,
    maxWidth: maxStatsWidth,
    lineHeight: 1.1,
    color: darkThemeColors.tertiaryColor,
  },
  legendBar: {
    height: '100%',
    width: '0.3rem',
    borderRadius: '0.15rem',
    marginRight: 15,
  },
  statsContainer: {
    display: 'flex',
    margin: '0.5rem',
    height: '3.8rem',
  },
}));

export default function DonutChart(props) {
  const classes = useStyles();
  const { graphName, graphHighlight } = props;
  const { graphContent, graphLabelStats, graphStats, match } = props;

  const labels = Object.keys(graphContent);
  const series = Object.values(graphContent);
  const options = {
    dataLabels: {
      enabled: false,
    },
    labels,
    colors: GraphConfig.graphColors,
    chart: {
      width: '100%',
      type: 'donut',
    },
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: grey[800],
      width: 1,
      dashArray: 0,
    },
    legend: {
      show: false,
    },
  };

  return (
    <div className={classes.root}>
      {graphName || graphHighlight ? (
        <div className={classes.graphTitleContainer}>
          <Typography className={classes.primaryText} align="left">
            {graphName}
          </Typography>
          <Typography className={classes.secondaryText} align="left">
            {graphHighlight}
          </Typography>
        </div>
      ) : null}
      <div className={classes.horiContainer}>
        <div
          style={{
            widht: 'auto',
            height: GraphConfig.graphHeight,
            marginRight: '3vw',
          }}
        >
          <Chart
            type="donut"
            options={options}
            series={series}
            height={GraphConfig.graphHeight}
            width={GraphConfig.graphHeight}
          />
        </div>
        <div className={classes.lengendContainer}>
          <div
            style={{ display: 'flex', flexWrap: 'wrap', marginRight: '1rem' }}
          >
            {Array.isArray(graphStats)
              ? graphStats.map((item, i) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '0.5vw',
                      marginRight: 30,
                    }}
                    key={i}
                  >
                    {item.primaryText ? (
                      <Typography
                        className={classes.primaryText}
                        noWrap
                        align="left"
                      >
                        {item.primaryText}
                      </Typography>
                    ) : null}
                    {item.secondaryText ? (
                      <Typography
                        className={classes.secondaryText}
                        noWrap
                        align="left"
                      >
                        {item.secondaryText}
                      </Typography>
                    ) : null}
                    {item.tertiaryText ? (
                      <Typography
                        className={classes.tertiaryText}
                        noWrap
                        align="left"
                      >
                        {item.tertiaryText}
                      </Typography>
                    ) : null}
                  </div>
                ))
              : null}
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {Array.isArray(graphLabelStats)
              ? graphLabelStats.map((item, i) => (
                  <div
                    className={classes.statsContainer}
                    style={{ margin: '0.5vw', marginRight: 30 }}
                    key={i}
                  >
                    <div
                      className={classes.legendBar}
                      style={{
                        backgroundColor:
                          GraphConfig.graphColors[
                            i % GraphConfig.graphColors.length
                          ],
                      }}
                    >
                      &nbsp;
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {item.primaryText ? (
                        <Typography
                          className={classes.primaryText}
                          noWrap
                          align="left"
                        >
                          {item.primaryText}
                        </Typography>
                      ) : null}
                      {item.secondaryText ? (
                        <DataLabelAtom
                          text={item.secondaryText}
                          type="secondaryText"
                          match={match}
                        />
                      ) : null}
                      {item.tertiaryText ? (
                        <Typography
                          className={classes.tertiaryText}
                          noWrap
                          align="left"
                        >
                          {item.tertiaryText}
                        </Typography>
                      ) : null}
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}
