import DeployedProjectStatus from './statuses/deployedProjectStatus';
import DeployableProjectStatus from './statuses/deployableProjectStatus';
import RealtimeProjectRunStatus from './statuses/realtimeProjectRunStatus';

interface Project {
  projectId: string;
  projectStatus: string;
}

export default function ProjectRunStatus({
  project: { projectId, projectStatus: ddbProjectStatus },
}: {
  project: Project;
}) {
  if (ddbProjectStatus === 'deployed') return <DeployedProjectStatus />;
  if (ddbProjectStatus === 'ready') return <DeployableProjectStatus />;

  return <RealtimeProjectRunStatus projectId={projectId} />;
}
