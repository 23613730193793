import { useContext } from 'react';
import { useTheme, Typography } from '@mui/material';
import { TREND_STATUS } from '../constants';
import { ProductPerformanceMetadataContext } from './data/productPerformanceMetadataContext';
import { ProductPerformanceChartContext } from './data/productPerformanceChartContext';
import { isPredictionCadence } from '../helpers';

export const formatProductString = (products) => {
  if (products.length === 1) {
    return products[0];
  }
  const productCount = products.length - 1;
  return `${products.slice(0, productCount).join(', ')} or ${
    products[productCount]
  }`;
};

export const formatPredictionDisclaimer = (
  productsMissingPredictions,
  productData
) => {
  const productString = formatProductString(productsMissingPredictions);
  if (productsMissingPredictions.length === 1) {
    return `Not enough data to generate a prediction for ${productString}.`;
  }

  if (productsMissingPredictions.length < productData.length) {
    return `Not enough data to generate predictions for ${productString}.`;
  }

  return `Not enough data to generate predictions for all products.`;
};

export const PredictionDisclaimer = () => {
  const { selectedCadence, selectedCardTab } = useContext(
    ProductPerformanceMetadataContext
  );
  const { data, isDataLoading } = useContext(ProductPerformanceChartContext);

  if (
    !data ||
    isDataLoading ||
    !isPredictionCadence(selectedCadence?.id, selectedCardTab)
  ) {
    return null;
  }

  const { themeColors } = useTheme();

  const productsMissingPredictions = [];

  data.forEach((productData) => {
    const isMissingPredictions = productData.chartValues.some(
      (value) => value.trend.status === TREND_STATUS.CANNOT_PREDICT
    );

    if (isMissingPredictions) {
      productsMissingPredictions.push(productData.label);
    }
  });

  return productsMissingPredictions.length ? (
    <Typography
      variant="caption"
      color={themeColors.tertiaryColor}
      textAlign="left"
      fontStyle="italic"
      data-testid="prediction-disclaimer"
    >
      {formatPredictionDisclaimer(productsMissingPredictions, data)}
    </Typography>
  ) : null;
};
