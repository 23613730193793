import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  ModalDisplay,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '../../../../../../components/generic/modalDisplay';

const StyledButton = styled(Button)(({ theme: { themeColors } }) => ({
  color: themeColors.maptualListFilteringBadgeColor,
  height: 32,
  borderRadius: 4,
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
}));

export const ConfirmationModal = ({
  onClose,
  onConfirm,
  isModalOpen,
  handleUpperClose,
  mode = 'live',
}) => {
  const handleClose = () => {
    onClose();
    handleUpperClose();
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  const sanitizedMode = mode.toLowerCase();

  return (
    <ModalDisplay
      isOpen={isModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalHeader onClose={handleClose}>
        <Typography variant="h3">
          {sanitizedMode === 'test' ? '[TEST] ' : ''}Rollback Curation?
          {sanitizedMode === 'test' ? ' [TEST]' : ''}
        </Typography>
      </ModalHeader>
      <ModalBody>
        <Typography variant="h3">WARNING</Typography>
        <Typography variant="body1">
          You are about to permanately delete the current{' '}
          {sanitizedMode === 'test' ? '[test]' : ''}curated list. Clicking
          confirm will re-publish the previous list and update customer facing
          data.
        </Typography>
        <Typography variant="body1">
          Please contact a member of the Maverick team or QA team to ensure
          rolling back curation is acceptable.
        </Typography>
      </ModalBody>
      <ModalFooter>
        <>
          <StyledButton
            style={{ border: '1px solid #313B49', backgroundColor: '#1F232C' }}
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </StyledButton>
          <StyledButton
            style={{ backgroundColor: '#0D47A0' }}
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleConfirm}
          >
            Confirm
          </StyledButton>
        </>
      </ModalFooter>
    </ModalDisplay>
  );
};
