import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import {
  resetPassword,
  getUsersData,
  clearStatus,
} from '../../slices/userManagement';
import getUserManagementState from '../../hooks/userManagement';
import EmptyView from '../emptyview/emptyView';
import { userManagementColors } from './styles/styledComponents';
import {
  ModalDisplay,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalError,
} from '../generic/modalDisplay';

const StyledTypography = styled(Typography)(() => ({
  color: userManagementColors.primaryColor,
}));

const StyledLoadingButton = styled(LoadingButton)(
  ({ theme: { themeColors } }) => ({
    textTransform: 'none',
    marginLeft: '8px',
    background: themeColors.maptualListFilteringButtonBackgroundColor,
  })
);

const PasswordResetModal = ({
  isPasswordResetModalOpen,
  name,
  userId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const state = getUserManagementState();

  const handleClose = () => {
    dispatch(getUsersData);
    dispatch(clearStatus('userPasswordResetStatus'));
    onClose();
  };

  const requestFailed = (status) => status === 'FAILED' || status === 'ERROR';
  const requestSuccess = (status) => status === 'SUCCESS';
  const requestPending = (status) => status === 'PENDING';

  return (
    <ModalDisplay
      isOpen={isPasswordResetModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {requestFailed(state.userPasswordResetStatus) ? (
        <>
          <ModalHeader onClose={handleClose}>
            <Typography variant="h3">
              We couldn't send the password reset email
            </Typography>
          </ModalHeader>
          <ModalBody>
            <ModalError>{state.errorMessage}</ModalError>
          </ModalBody>
          <ModalFooter>
            <Button variant="contained" onClick={handleClose}>
              Done
            </Button>
          </ModalFooter>
        </>
      ) : (
        <>
          <ModalHeader onClose={handleClose}>
            <Typography variant="h3">
              {requestSuccess(state.userPasswordResetStatus)
                ? 'Password Reset Email Sent'
                : 'Send Password Reset Email'}
            </Typography>
          </ModalHeader>
          <ModalBody>
            {requestPending(state.userPasswordResetStatus) ? (
              <EmptyView isLoading />
            ) : (
              <StyledTypography variant="body2">
                {requestSuccess(state.userPasswordResetStatus)
                  ? state.message
                  : `An email will be sent to ${name}. The password in the email will expire in 7 days.`}
              </StyledTypography>
            )}
          </ModalBody>
          <ModalFooter>
            {requestSuccess(state.userPasswordResetStatus) ? (
              <Button variant="contained" onClick={handleClose}>
                Done
              </Button>
            ) : (
              <>
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
                <StyledLoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={state.userPasswordResetStatus === 'PENDING'}
                  onClick={() => dispatch(resetPassword(userId))}
                >
                  Send Password Reset
                </StyledLoadingButton>
              </>
            )}
          </ModalFooter>
        </>
      )}
    </ModalDisplay>
  );
};

export default PasswordResetModal;
