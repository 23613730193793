import { useEffect } from 'react';
import { styled, Button } from '@mui/material';
import Popover from '@mui/material/Popover';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import {
  clearStatus,
  getUsersData,
  sendInviteEmail,
} from '../../slices/userManagement';
import getUserManagementState from '../../hooks/userManagement';
import { PopoverWindow, userManagementColors } from './styles/styledComponents';
import {
  ModalBody,
  ModalDisplay,
  ModalError,
  ModalFooter,
  ModalHeader,
} from '../generic/modalDisplay';
import { toCapitalize } from '../../utils/toCapitalize';

const PopoverWrapper = styled(Popover)(() => ({
  '>.MuiPaper-root': {
    width: '20rem',
  },
}));

const PopoverContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '17px',
  marginBottom: '25px',
}));

const PopoverFooter = styled('div')(() => ({
  justifyContent: 'right',
  display: 'flex',
  flexDirection: 'row',
  gap: '5px',
  paddingTop: '10px',
}));

const StyledTypography = styled(Typography)(() => ({
  color: userManagementColors.primaryColor,
}));

const SendInviteEmailPopover = ({
  isSendInviteEmailPopoverOpen,
  anchor,
  name,
  userId,
  inviteEmailSent,
  onClose,
}) => {
  const dispatch = useDispatch();
  const state = getUserManagementState();

  const requestFailed = (status) => status === 'FAILED' || status === 'ERROR';
  const requestSuccess = (status) => status === 'SUCCESS';

  const handleSendEmailClick = () => {
    dispatch(sendInviteEmail(userId));
    onClose();
  };

  useEffect(() => {
    if (requestSuccess(state.sendInviteEmailStatus)) dispatch(getUsersData());
  }, [state.sendInviteEmailStatus]);

  const handleErrorModalClose = () => {
    dispatch(clearStatus('sendInviteEmailStatus'));
    dispatch(getUsersData());
  };

  const sendVerb = inviteEmailSent ? 're-send' : 'send';
  return (
    <div>
      {requestFailed(state.sendInviteEmailStatus) ? (
        <ModalDisplay
          isOpen
          onClose={handleErrorModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalHeader onClose={handleErrorModalClose}>
            <Typography variant="h3">
              We couldn't send the invite email
            </Typography>
          </ModalHeader>
          <ModalBody>
            <ModalError>{state.errorMessage}</ModalError>
          </ModalBody>
          <ModalFooter>
            <Button variant="contained" onClick={handleErrorModalClose}>
              Done
            </Button>
          </ModalFooter>
        </ModalDisplay>
      ) : (
        <PopoverWrapper
          open={isSendInviteEmailPopoverOpen}
          anchorEl={anchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={onClose}
        >
          <PopoverWindow>
            <PopoverContent>
              <Typography variant="h3">
                Are you sure you want to {sendVerb} an invite to {name}?
              </Typography>
              <StyledTypography variant="body2">
                Invite will expire in 14 days.
              </StyledTypography>
            </PopoverContent>
            <PopoverFooter>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                onClick={handleSendEmailClick}
              >
                {toCapitalize(sendVerb)} Email
              </Button>
            </PopoverFooter>
          </PopoverWindow>
        </PopoverWrapper>
      )}
    </div>
  );
};

export default SendInviteEmailPopover;
