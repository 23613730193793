import { useState, useEffect } from 'react';
import { theme, lightTheme } from '@odaia/ui/src/theme';
import { useLocalStorageValue } from '../../hooks/useLocalStorageValue';
import useFeatureToggles from '../../hooks/useFeatureToggles';

const ThemeNameOptions = {
  LIGHT: 'Light',
  DARK: 'Dark',
  SETTINGS: 'Device Setting',
};

export const useThemeContext = (AppContext) => {
  const { profile } = AppContext;
  const featureToggles = useFeatureToggles();

  const [activeTheme, setActiveTheme] = useState(theme);
  const [activeThemeName, setActiveThemeName] = useState(ThemeNameOptions.DARK);
  const [savedTheme, setSavedTheme, removeSavedTheme] =
    useLocalStorageValue('theme');

  useEffect(() => {
    // feature toggle coverage
    if (!profile) return;
    if (profile && !featureToggles(profile.userGroup, 'lightTheme')) {
      removeSavedTheme();
      return;
    }

    if (
      savedTheme &&
      typeof savedTheme === 'string' &&
      Object.values(ThemeNameOptions).includes(savedTheme)
    ) {
      saveActiveTheme(savedTheme);
    } else {
      saveActiveTheme(ThemeNameOptions.SETTINGS);
    }
  }, [profile]);

  const setActiveThemeByName = (name) => {
    if (name === ThemeNameOptions.LIGHT) {
      setActiveTheme(lightTheme);
    } else {
      setActiveTheme(theme);
    }
  };

  const saveActiveTheme = (name) => {
    setSavedTheme(name);
    setActiveThemeName(name);
    if (name === ThemeNameOptions.SETTINGS) {
      const settingsTheme = window?.matchMedia?.('(prefers-color-scheme:dark)')
        ?.matches
        ? ThemeNameOptions.DARK
        : ThemeNameOptions.LIGHT;
      setActiveThemeByName(settingsTheme);
    } else {
      setActiveThemeByName(name);
    }
  };

  return {
    activeTheme,
    activeThemeName,
    setActiveThemeName,
    setActiveThemeByName,
    ThemeNameOptions,
    saveActiveTheme,
  };
};
