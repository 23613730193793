import { styled, Box } from '@mui/material';
import { UnfoldMore } from '@mui/icons-material';

const StyledExpandIcon = styled(UnfoldMore)(({ theme }) => ({
  color: theme.themeColors.neutral70,
  width: 16,
  height: 16,
  transform: 'rotate(45deg)',
  transformOrigin: 'center',
}));

export const EntityTableDrawerCell = () => (
  <Box
    data-testid="drawer-expander-button"
    display="flex"
    justifyContent="flex-end"
    alignSelf="center"
  >
    <Box>
      <StyledExpandIcon />
    </Box>
  </Box>
);
