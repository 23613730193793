import { styled, CircularProgress, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  ModalDisplay,
  ModalHeader,
} from '../../../../../../components/generic/modalDisplay';

const BodyContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 50,
}));

const Header = styled(Typography)(() => ({
  marginBottom: '16px',
}));

const StatusContainer = styled(Typography)(({ color }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  color,
}));

export const StatusModal = ({
  isModalOpen,
  loading,
  success,
  error,
  onClose,
}) => {
  const { themeColors } = useTheme();
  const handleClose = () => {
    onClose();
  };

  return (
    <ModalDisplay
      isOpen={isModalOpen}
      onClose={handleClose}
      aria-labelledby="rollback-status-modal-title"
      aria-describedby="rollback-status-modal-description"
    >
      <ModalHeader onClose={handleClose}>
        <Header variant="h3">Rolling back Curation</Header>
        <BodyContainer>
          {loading && <CircularProgress size={40} />}
          {success && (
            <StatusContainer color={themeColors.alertPrimarySuccessFillColor}>
              <CheckCircleOutlineIcon />
              <Typography>Curation rollback successful</Typography>
            </StatusContainer>
          )}
          {error && (
            <StatusContainer color={themeColors.alertPrimaryDangerFillColor}>
              <ErrorOutlineIcon />
              <Typography>Curation rollback encountered an error</Typography>
            </StatusContainer>
          )}
        </BodyContainer>
      </ModalHeader>
    </ModalDisplay>
  );
};
