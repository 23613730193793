import { Box, styled } from '@mui/material';

export const NavigationContentWrapper = ({
  isNavigationOpen,
  setIsNavigationOpen,
  children,
}) => {
  const handleContentClick = () => {
    if (isNavigationOpen) {
      setIsNavigationOpen(false);
    }
  };

  return (
    <ContentWrapper
      onClick={handleContentClick}
      isNavigationOpen={isNavigationOpen}
    >
      {children}
    </ContentWrapper>
  );
};

const ContentWrapper = styled(Box)(({ isNavigationOpen }) => ({
  height: '100%',
  flexGrow: 1,
  '*': {
    pointerEvents: isNavigationOpen && 'none',
  },
  '.scrollable, .MuiDataGrid-virtualScroller': {
    pointerEvents: isNavigationOpen && 'auto',
  },
}));
