import { useContext } from 'react';
import { useMutation } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import {
  createCustomerMarket,
  updateCustomerMarket,
} from '../../request/configViewRequests';
import MarketEdit from './marketEdit';
import { ConfigViewContext } from './configViewContext';
import { CONFIG_ALERT_STATUSES } from './configAlertStatuses';
import { useProductTreesQuery } from '../../request/queries';
import { CenteredContentContainer } from './styledComponents';
import { useAttachProductTreeMutation } from '../../request/mutations';

export default function MarketsEditProvider({
  marketBeingEdited,
  setMarketBeingEdited,
}) {
  const { showConfigAlert } = useContext(ConfigViewContext);
  const {
    mutateAsync: attachProductLine,
    isLoading: isAttachProductLineLoading,
  } = useAttachProductTreeMutation({
    onError: () => {
      showConfigAlert(
        CONFIG_ALERT_STATUSES.ERROR,
        'Failed to attach product line to market'
      );
    },
  });

  const { mutateAsync: createMarket, isLoading: isCreateLoading } = useMutation(
    createCustomerMarket,
    {
      onSuccess: async (data, variables) => {
        const { createCustomerMarketRequest: newMarket } = variables;
        showConfigAlert(
          CONFIG_ALERT_STATUSES.SUCCESS,
          `Successfully created ${newMarket.market}`
        );
      },
      onError: () => {
        showConfigAlert(
          CONFIG_ALERT_STATUSES.ERROR,
          'Cannot create or save changes. Please try again'
        );
      },
    }
  );

  const { mutateAsync: updateMarket, isLoading: isUpdateLoading } = useMutation(
    updateCustomerMarket,
    {
      onSuccess: async (data, variables) => {
        const { requestBody: marketToUpdate } = variables;
        showConfigAlert(
          CONFIG_ALERT_STATUSES.SUCCESS,
          `Saved changes to ${marketToUpdate.market}`
        );
      },
      onError: () => {
        showConfigAlert(CONFIG_ALERT_STATUSES.ERROR);
      },
    }
  );

  const { data: productTrees = [], isLoading: isLoadingProductTrees } =
    useProductTreesQuery();

  const productTreeOptions = productTrees
    .filter((p) => !p.marketId)
    .map((p) => ({
      label: p.productLine,
      value: p.productLineId,
    }));

  if (isLoadingProductTrees) {
    return (
      <CenteredContentContainer>
        <CircularProgress />
      </CenteredContentContainer>
    );
  }

  return (
    <MarketEdit
      marketBeingEdited={marketBeingEdited}
      setMarketBeingEdited={setMarketBeingEdited}
      productTreeOptions={productTreeOptions}
      createMarket={createMarket}
      updateMarket={updateMarket}
      attachProductLine={attachProductLine}
      isCreateLoading={isCreateLoading}
      isUpdateLoading={isUpdateLoading}
      isAttachProductLineLoading={isAttachProductLineLoading}
    />
  );
}
