import { Box, styled } from '@mui/material';
import { formatEntityName } from '../../../../utils/formatEntityName';
import { TrendValue } from '../../../shared/trend/TrendValue';
import { ActivityDescriptionPopover } from '../../components/ActivityDescriptionPopover';
import { getCellValue } from '../../../shared/graphs/utils';
import { getRegionLabel } from '../../utils';

const ValueTrendBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  fontSize: 16,
  fontWeight: 400,
}));

const ActivityTrendBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
}));

export const SupplyTableCell = ({ row, type }) => {
  const { data, trend, activity } = row;
  const joinedDataId = Array(data).flat().join('-');

  if (type === 'label') {
    const label = getRegionLabel(row);

    return (
      <>
        {formatEntityName(label ?? activity)}
        {activity && <ActivityDescriptionPopover />}
      </>
    );
  }

  return (
    <>
      {activity && (
        <ActivityTrendBox data-testid={`value-trend-box-${joinedDataId}`}>
          {getCellValue(activity, 'number')}
        </ActivityTrendBox>
      )}
      {data && (
        <ValueTrendBox data-testid={`value-trend-box-${joinedDataId}`}>
          {getCellValue(data, type)}
          <TrendValue
            type={trend.type}
            status={trend.status}
            value={trend?.value}
          />
        </ValueTrendBox>
      )}
    </>
  );
};
