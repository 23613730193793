import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import {
  ModalDisplay,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '../generic/modalDisplay';

const StyledLoadingButton = styled(LoadingButton)(
  ({ theme: { themeColors } }) => ({
    textTransform: 'none',
    marginLeft: '8px',
    background: themeColors.maptualListFilteringButtonBackgroundColor,
  })
);

const ToggleChangeModal = ({ isModalOpen, onClose, onConfirm, title }) => {
  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <ModalDisplay
      isOpen={isModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalHeader onClose={handleClose}>
        <Typography variant="h3">{title}</Typography>
      </ModalHeader>
      <ModalBody>
        <Typography variant="body2">
          Are you sure you want to change the status of this feature toggle?
          Feature toggles can have wide-reaching effects in the application.
        </Typography>
      </ModalBody>
      <ModalFooter>
        <>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <StyledLoadingButton
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleConfirm}
          >
            Confirm
          </StyledLoadingButton>
        </>
      </ModalFooter>
    </ModalDisplay>
  );
};

export default ToggleChangeModal;
