/* eslint-disable no-restricted-syntax */

import Chart from 'react-apexcharts';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';
import { DEFAULT_TABS } from './constants';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    padding: '0px 20px',
    backgroundColor: '#191C24',
  },
}));

function FrequencyBarChart({ selectedTab, chartTabs, series }) {
  const { themeColors } = useTheme();
  const classes = useStyles();

  const getXAxisTitle = (tab) =>
    tab !== DEFAULT_TABS.ALL ? `Customer Guidance Segment ${tab}` : undefined;

  const getXAxisLabels = (tab, tabs) =>
    tab === DEFAULT_TABS.ALL
      ? tabs.filter((v) => v !== DEFAULT_TABS.ALL)
      : undefined;

  const getYAxisTitle = (tab) =>
    tab === DEFAULT_TABS.ALL
      ? 'Average dynamic frequency of HCPs'
      : 'HCPs dynamic frequency';

  const yAxisTitle = getYAxisTitle(selectedTab);
  const xAxisTitle = getXAxisTitle(selectedTab);
  const xAxisLabels = getXAxisLabels(selectedTab, chartTabs);

  let options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      height: '100%',
      background: '#191C24',
    },
    fill: {
      opacity: 1,
    },
    plotOptions: {
      bar: {
        columnWidth: '95%',
      },
    },
    colors: themeColors.graphLegendColors.slice(0, series.length),
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      title: {
        text: yAxisTitle,
        offsetX: -6,
        style: {
          color: themeColors.graphAxisColor,
          fontWeight: 400,
        },
      },
      labels: {
        style: {
          fontSize: '12px',
          colors: [themeColors.graphAxisColor],
        },
      },
      axisBorder: {
        show: true,
        color: '#52627A',
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      show: true,
      borderColor: '#313B49',
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: [
        'Actual Frequency',
        'Customer guidance frequency targets',
      ],
      offsetX: 35,
      markers: {
        width: 8,
        height: 8,
        radius: 14,
        offsetX: -2,
        fillColors: ['#1A5CC3', '#FBFCFE'],
      },
      position: 'bottom',
      horizontalAlign: 'left',
      labels: {
        colors: themeColors.graphAxisColor,
      },
      itemMargin: {
        vertical: 15,
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      inverseOrder: false,
      theme: 'dark',
      onDatasetHover: {
        highlightDataSeries: false,
      },
      marker: {
        show: true,
      },
    },
  };

  let xAxis = {
    axisBorder: {
      show: true,
      color: themeColors.graphAxisColor,
    },
    axisTicks: {
      show: false,
    },
  };
  if (xAxisLabels === undefined) {
    xAxis = {
      ...xAxis,
      ...{
        type: 'numeric',
        title: {
          text: xAxisTitle,
          style: {
            color: themeColors.graphAxisColor,
            fontWeight: 400,
          },
        },
        labels: {
          show: false,
        },
      },
    };
  } else {
    xAxis = {
      ...xAxis,
      ...{
        type: 'category',
        categories: xAxisLabels,
        title: {
          text: undefined,
        },
        labels: {
          show: true,
          maxWidth: 200,
          style: {
            fontSize: '12px',
            colors: Array(series[0].data.length).fill(
              themeColors.graphAxisColor
            ),
          },
        },
      },
    };
  }

  options = { ...options, ...{ xaxis: xAxis } };

  return (
    // apex charts cannot be properly rendered in parent flex container, so this ensures it doesnt
    <div className={classes.container}>
      <Chart
        key={options.xaxis.type}
        options={options}
        series={series}
        type="bar"
      />
    </div>
  );
}

export default FrequencyBarChart;
