import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Grow from '@mui/material/Grow';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 0,
    gap: '24px',
  },
}));

export default function ProjectsList({
  projects = null,
  match,
  deleteProject,
  updateProject,
  setProjectId,
  renderTile,
  userGroup,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {Array.isArray(projects)
        ? projects.map((project, i) => (
            <Grow in direction="left" timeout={800 + i * 300} key={i}>
              <div>
                {renderTile({
                  project,
                  match,
                  deleteProject,
                  setProjectId,
                  updateProject,
                  userGroup,
                })}
              </div>
            </Grow>
          ))
        : null}
    </div>
  );
}

ProjectsList.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object),
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
  deleteProject: PropTypes.func,
  setProjectId: PropTypes.func,
  renderTile: PropTypes.func,
};
