import axios from 'axios';
import find from 'lodash/find';

const PROD_CLIENT_ID = '9q4olfgiuuekijsvtoac44l6j';
const LOCALHOST_CLIENT_ID = '79ckkmeim4rc8ctl021k244043';
const pointToProd = import.meta.env.VITE_POINT_LOCAL_TO_PROD === 'true';

export const environmentConfigs = {
  localhost: {
    url: 'http://localhost:3000/',
    clientId: pointToProd ? PROD_CLIENT_ID : LOCALHOST_CLIENT_ID,
    mixpanelToken: '1bb78ffbccc0670633965fd87d67757d',
    internalMixpanelToken: '1bb78ffbccc0670633965fd87d67757d',
    intercomAppId: 'aiigobau',
    fastUrl: 'http://localhost:8000/',
  },
  prod: {
    url: '/',
    clientId: PROD_CLIENT_ID,
    mixpanelToken: '31c8d7be83d6938fb6cda325244e80a6',
    internalMixpanelToken: 'f28a05136656ed4eb4147f6041beebd3',
    intercomAppId: 'sm7xuxs1',
    fastUrl: 'https://maptual-api.odaia.ai/',
  },
  dev: {
    url: 'https://dev.maptual.odaia.ai/',
    clientId: LOCALHOST_CLIENT_ID,
    mixpanelToken: '1bb78ffbccc0670633965fd87d67757d',
    internalMixpanelToken: '1bb78ffbccc0670633965fd87d67757d',
    intercomAppId: 'aiigobau',
    fastUrl: 'https://dev.maptual.odaia.ai/alb2apigw-proxy-lm/',
  },
  ml: {
    url: 'https://ml.maptual.odaia.ai/',
    clientId: '7tlqcevcrt54et3ovmhoi4vmbc',
    mixpanelToken: 'ee01c0250458bb3bde1ee4b856670dcc',
    internalMixpanelToken: 'ee01c0250458bb3bde1ee4b856670dcc',
    intercomAppId: 'aiigobau',
    fastUrl: 'https://ml.maptual.odaia.ai/alb2apigw-proxy-lm/',
  },
  demo: {
    url: 'https://demo.maptual.odaia.ai/',
    clientId: '1ivolm3j04qp52isoh9iseh5lj',
    mixpanelToken: '7cbc3d23492b2c93cfe4093f38bcef55',
    internalMixpanelToken: '7cbc3d23492b2c93cfe4093f38bcef55',
    intercomAppId: 'aiigobau',
    fastUrl: 'https://maptual-api-demo.odaia.ai/',
  },
};

const findEnvironmentByHostname = (hostname) => {
  const environmentNames = Object.keys(environmentConfigs);
  const matchingEnvironmentName = find(environmentNames, (environmentName) =>
    environmentConfigs[environmentName].url.includes(hostname)
  );

  return matchingEnvironmentName || 'prod';
};
const currentEnvironment = findEnvironmentByHostname(window.location.hostname);

const isProd = currentEnvironment === 'prod';
let isDemoAccount = false;
let isInternalAccount = false;
const {
  url: baseURL,
  clientId,
  mixpanelToken,
  internalMixpanelToken,
  fastUrl,
} = environmentConfigs[currentEnvironment];
export const { intercomAppId } = environmentConfigs[currentEnvironment];
const service = isProd ? 'service' : 'dev-service';
const baseAlbUrl = `${baseURL}alb2apigw-proxy-lm/`; // Application Load Balancer
const baseFastUrl = `${fastUrl}`; // Fast API
const baseConfigUrl = `${fastUrl}config/api/v1`; // Config API Proxy
const baseConfigUrlOpenApi = `${fastUrl}config`; // Config API Proxy

const commonRequestParams = {
  timeout: 300000,
  headers: {
    'Access-Control-Allow-Methods': 'POST,GET,OPTIONS',
    'Access-Control-Allow-Origin': '*',
  },
};

const baseInstance = axios.create({ baseURL, ...commonRequestParams });

const baseAlbInstance = axios.create({
  baseURL: baseAlbUrl,
  ...commonRequestParams,
});

export const baseFastInstance = axios.create({
  withCredentials: true,
  baseURL: baseFastUrl,
  ...commonRequestParams,
});

export const configProxyApiInstance = axios.create({
  withCredentials: true,
  baseURL: baseConfigUrl,
  ...commonRequestParams,
});

const configProxyApiInstanceOpenApi = axios.create({
  withCredentials: true,
  baseURL: baseConfigUrlOpenApi,
  ...commonRequestParams,
});

export const maptualApiInstance = baseFastInstance;

export const isLocal = currentEnvironment === 'localhost';

function getDemoAccountStatus() {
  return isDemoAccount;
}

function setDemoAccountStatus(status = false) {
  if (status === false || status === true) isDemoAccount = status;
}

function getInternalAccountStatus() {
  return isInternalAccount;
}

function isAdminAccount(profile) {
  return profile?.role?.type === 'ADMIN';
}

function setInternalAccountStatus(status = false) {
  if (status === false || status === true) isInternalAccount = status;
}

function handleSpecialTypes(userProfile) {
  const specialType = userProfile.role?.specialType;

  if (specialType === 'TEST') {
    setInternalAccountStatus(true);
  } else if (specialType === 'DEMO') {
    setDemoAccountStatus(true);
  }
}

const userHasCallPlanAccess = (profile, regionHasCuratedList) =>
  (profile?.role?.role === 'Sales Rep' && regionHasCuratedList) ||
  profile?.role?.type === 'ADMIN';

const enabledFixtures = import.meta.env.VITE_ENABLED_FIXTURES?.split(',');

export {
  baseInstance,
  baseAlbInstance,
  baseURL,
  baseFastUrl,
  baseConfigUrl,
  baseConfigUrlOpenApi,
  configProxyApiInstanceOpenApi,
  baseAlbUrl,
  clientId,
  service,
  isProd,
  mixpanelToken,
  internalMixpanelToken,
  currentEnvironment,
  findEnvironmentByHostname,
  getDemoAccountStatus,
  setDemoAccountStatus,
  getInternalAccountStatus,
  setInternalAccountStatus,
  handleSpecialTypes,
  enabledFixtures,
  userHasCallPlanAccess,
  isAdminAccount,
};
