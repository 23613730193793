import { useQuery } from 'react-query';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { fieldApi } from '../../request/maptualApiClient';
import { AppContext } from '../application/appContext';

export const useEntity = ({ entityId }) => {
  const { projectId } = useParams();
  const { projectList } = useContext(AppContext);
  const productLineId = projectList.find(
    (proj) => proj.projectId === projectId
  )?.productLineId;

  const { data: entity, isLoading: isEntityBusy } = useQuery({
    queryKey: ['entity-data', entityId],
    queryFn: async () => {
      const response = await fieldApi.getEntityData({
        entityId,
        productLineId,
      });
      return response.data;
    },
    enabled: !!entityId,
  });

  return {
    entity,
    isEntityBusy,
  };
};
