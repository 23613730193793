import { Box, Typography, MenuItem, styled } from '@mui/material';

const RegionListWrapper = styled('div')(() => ({
  marginTop: 16,
}));

const RegionListTitle = styled('div')(({ theme: { themeColors } }) => ({
  marginBottom: 8,
  padding: '0px 12px',
  color: themeColors.neutral60,
  fontFamily: 'Roboto',
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
}));

const StyledMenuItem = styled(MenuItem)(({ theme: { themeColors } }) => ({
  padding: 12,
  color: themeColors.segmentSelectionCaptionColor,
  borderRadius: 3,
  whiteSpace: 'pre',
}));

const ErrorMessage = styled(Typography)(({ theme: { themeColors } }) => ({
  borderRadius: 3,
  padding: 12,
  fontSize: 14,
  lineHeight: '16px',
  color: themeColors.segmentSelectionCaptionColor,
}));

export const RegionSelectionList = ({ regionList, handleRegionSelection }) => (
  <RegionListWrapper>
    <RegionListTitle>My Regions</RegionListTitle>
    <Box>
      {regionList?.length ? (
        regionList.map((region, i) => (
          <StyledMenuItem
            key={i}
            value={`${' '.repeat(region.depth * 2)}${region.listName}`}
            onClick={() => {
              handleRegionSelection(region);
            }}
          >
            {`${' '.repeat(region.depth * 2)}${region.listName}`}
          </StyledMenuItem>
        ))
      ) : (
        <ErrorMessage>
          There are no regions assigned to you, contact support for help.
        </ErrorMessage>
      )}
    </Box>
  </RegionListWrapper>
);
