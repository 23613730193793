import { useContext, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Radio,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  BodyTypography,
  ColorText,
  ContentContainer,
  DEPLOY_CSV_STATES,
  HeaderBar,
  StyledDialogTitle,
  SubheaderTypography,
  errorRed,
} from './sharedComponents';
import {
  DeployFileContext,
  useDeployDialogContext,
} from './deployDialogContext';

const BottomPaddedDialogContent = styled(DialogContent)({
  padding: '0 0 32px',
});

const TopBorderlessDialogContent = styled(DialogContent)({ borderTop: 0 });

const StyledDialogActions = styled(DialogActions)({
  padding: 20,
});

const FullWidthFormControl = styled(FormControl)({
  width: '100%',
});

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#5e708d',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      border: '1px solid #5e708d',
    },
  },
  marginTop: 24,
});

const StyledButton = styled(Button)({
  '&.action': {
    background: '#1A5CC3',
    '&.Mui-disabled': {
      background:
        'linear-gradient(0deg, rgba(68, 83, 106, 0.22), rgba(68, 83, 106, 0.22)),#15161a',
    },
  },
  '&.default': {
    background:
      'linear-gradient(0deg, rgba(68, 83, 106, 0.22), rgba(68, 83, 106, 0.22)),#15161a',
  },
  '&.MuiButton-contained': {
    padding: '8px 16px',
    border: '1px solid #313B49',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    color: 'white',
    fontSize: 14,
    fontWeight: 400,
    height: 33,
  },
  '&.Mui-disabled': {
    border: 0,
    boxShadow: 0,
    color: '#5E708D',
  },
});

const StyledLinearProgress = styled(LinearProgress)({
  marginBottom: 28,
});

const FormControlLabelSpaced = styled(FormControlLabel)({
  margin: 0,
});

const RadioSpaced = styled(Radio)({
  marginRight: 6,
});

const StyledErrorIcon = styled(ErrorOutlineIcon)({
  color: errorRed,
});

const SmallModalBodyTypography = styled(BodyTypography)({
  marginBottom: 28,
});

const Options = styled(Box)({
  padding: '12px 0 0',
});

const DataLoadingModal = () => (
  <ContentContainer className="largedialog">
    <HeaderBar />
    <DialogContent dividers>
      <ContentContainer className="loadingbody flex">
        <CircularProgress />
        <BodyTypography variant="body2">Loading CSV Selection</BodyTypography>
      </ContentContainer>
    </DialogContent>
  </ContentContainer>
);

const renderCsvSelectionElements = (filename, setFilename, data) => {
  const csvList = data?.data;
  if (Array.isArray(csvList) && csvList.length) {
    return (
      <Options>
        {csvList.map((fileData, index) => {
          const selectionFilename = fileData.filename;
          return (
            <FormControlLabelSpaced
              value={selectionFilename}
              label={selectionFilename}
              control={<RadioSpaced checked={filename === selectionFilename} />}
              onChange={(e) => setFilename(e.target.value)}
              key={index}
            />
          );
        })}
      </Options>
    );
  }

  return (
    <ContentContainer className="errorblock flex valigncenter">
      <StyledErrorIcon />
      <Typography variant="body1">
        <ColorText color={errorRed}>
          There are no CSVs available for deployment.
        </ColorText>
      </Typography>
    </ContentContainer>
  );
};

export const SelectionModal = () => {
  const { filename, setFilename, newFilename, setNewFilename, data } =
    useContext(DeployFileContext);

  return (
    <ContentContainer className="largedialog">
      <HeaderBar />
      <BottomPaddedDialogContent dividers>
        <FullWidthFormControl>
          <ContentContainer className="csvselectionblock">
            <SubheaderTypography variant="h5">
              CSV Selection
            </SubheaderTypography>
            <BodyTypography variant="body1">
              Select a CSV from the dropdown to deploy to GSK
            </BodyTypography>
            {renderCsvSelectionElements(filename, setFilename, data)}
          </ContentContainer>
          <ContentContainer className="csvselectionblock">
            <SubheaderTypography variant="h5">
              CSV Display Name
            </SubheaderTypography>
            <BodyTypography variant="body1">
              Confirm the selected CSV and its display name
            </BodyTypography>
            <StyledTextField
              focused
              fullWidth
              label="CSV Name"
              placeholder="Select CSV to view name"
              value={newFilename || filename}
              onChange={(e) => {
                if (e.target.value.endsWith('.csv'))
                  setNewFilename(e.target.value);
              }}
              InputProps={{
                spellCheck: false,
                ...(!filename && { readOnly: true }),
              }}
            />
          </ContentContainer>
        </FullWidthFormControl>
      </BottomPaddedDialogContent>
    </ContentContainer>
  );
};

const GenericSmallModal = ({ title, children }) => (
  <ContentContainer className="smalldialog">
    <TopBorderlessDialogContent dividers>
      <StyledDialogTitle className="smallmodal">
        <Typography variant="h3" component="span">
          {title}
        </Typography>
      </StyledDialogTitle>
      {children}
    </TopBorderlessDialogContent>
  </ContentContainer>
);

export const ConfirmationModal = () => {
  const { filename, newFilename } = useContext(DeployFileContext);
  return (
    <GenericSmallModal title="Confirmation">
      <SmallModalBodyTypography variant="body2">
        Are you sure you want to deploy{' '}
        <ColorText color="white">{filename}</ColorText>{' '}
        {newFilename ? (
          <>
            as <ColorText color="white">{newFilename}</ColorText>
          </>
        ) : null}{' '}
        to GSK? You will not be able to undo this.
      </SmallModalBodyTypography>
    </GenericSmallModal>
  );
};

const InProgressModal = () => (
  <GenericSmallModal title="Preparing to Deploy">
    <SmallModalBodyTypography variant="body2">
      Preparing to deploy CSV to GSK, this may take a few minutes. Do not close
      this modal during the preparation process.
    </SmallModalBodyTypography>
    <StyledLinearProgress />
  </GenericSmallModal>
);

const SuccessModal = () => (
  <GenericSmallModal title="Deployment Initiated Successfully">
    <SmallModalBodyTypography variant="body2">
      Deployment of CSV to GSK was successfully initiated. A member of the
      SpaceBear team will be notified if any errors occur. You can now close
      this modal.
    </SmallModalBodyTypography>
  </GenericSmallModal>
);

const FailureModal = () => (
  <GenericSmallModal title="Failed to deploy">
    <ContentContainer className="errorblock flex valigntop">
      <StyledErrorIcon />
      <SmallModalBodyTypography variant="body2">
        <ColorText color={errorRed}>
          There was an issue with deploying the CSV to GSK. Please try again
          later.
        </ColorText>
      </SmallModalBodyTypography>
    </ContentContainer>
  </GenericSmallModal>
);

const buttonOnClickHandler = ({
  deployDialogState,
  setDeployDialogState,
  filename,
  setFilename,
  newFilename,
  setNewFilename,
  data,
  deploy,
}) => {
  const modalEndClearState = () => {
    setDeployDialogState(DEPLOY_CSV_STATES.IDLE);
    setFilename('');
    setNewFilename('');
  };
  switch (deployDialogState) {
    case DEPLOY_CSV_STATES.SELECTION:
      return () => setDeployDialogState(DEPLOY_CSV_STATES.CONFIRMATION);
    case DEPLOY_CSV_STATES.CONFIRMATION:
      return async () => {
        const csvList = data?.data;
        if (Array.isArray(csvList) && csvList.length) {
          setDeployDialogState(DEPLOY_CSV_STATES.IN_PROGRESS);

          const fileData = csvList.find((file) => file.filename === filename);
          if (!fileData) {
            setDeployDialogState(DEPLOY_CSV_STATES.FAILED);
            return;
          }
          try {
            await deploy.mutateAsync({
              ...fileData,
              newFilename,
            });
            setDeployDialogState(DEPLOY_CSV_STATES.SUCCESS);
          } catch {
            setDeployDialogState(DEPLOY_CSV_STATES.FAILED);
          }
        } else setDeployDialogState(DEPLOY_CSV_STATES.FAILED);
      };
    case DEPLOY_CSV_STATES.IN_PROGRESS:
      return () => setDeployDialogState(DEPLOY_CSV_STATES.SUCCESS);
    case DEPLOY_CSV_STATES.SUCCESS:
      return modalEndClearState;
    case DEPLOY_CSV_STATES.FAILED:
      return modalEndClearState;
    default:
      return () => {};
  }
};

const actionButtonText = {
  [DEPLOY_CSV_STATES.SELECTION]: 'Deploy CSV',
  [DEPLOY_CSV_STATES.CONFIRMATION]: 'Confirm and Deploy',
};

const ActionButtons = () => {
  const { deployDialogState, setDeployDialogState } = useDeployDialogContext();
  const { filename, setFilename, newFilename, setNewFilename, data, deploy } =
    useContext(DeployFileContext);

  return (
    <StyledDialogActions>
      <StyledButton
        className="default"
        size="large"
        variant="contained"
        aria-label="Cancel"
        onClick={() => {
          setDeployDialogState(DEPLOY_CSV_STATES.IDLE);
          setFilename('');
          setNewFilename('');
        }}
        data-testid="test-deploy-csv-close-button"
      >
        Cancel
      </StyledButton>
      <StyledButton
        className="action"
        size="large"
        variant="contained"
        aria-label="Action Button"
        disabled={!filename}
        onClick={buttonOnClickHandler({
          deployDialogState,
          setDeployDialogState,
          filename,
          setFilename,
          newFilename,
          setNewFilename,
          data,
          deploy,
        })}
        data-testid="test-deploy-csv-button"
      >
        {actionButtonText[deployDialogState]}
      </StyledButton>
    </StyledDialogActions>
  );
};

const CloseButton = () => {
  const { deployDialogState, setDeployDialogState } = useDeployDialogContext();
  const { filename, setFilename, newFilename, setNewFilename, data, deploy } =
    useContext(DeployFileContext);

  return (
    <StyledDialogActions>
      <StyledButton
        className="default"
        size="large"
        variant="contained"
        aria-label="Close"
        disabled={deployDialogState === DEPLOY_CSV_STATES.IN_PROGRESS}
        onClick={buttonOnClickHandler({
          deployDialogState,
          setDeployDialogState,
          filename,
          setFilename,
          newFilename,
          setNewFilename,
          data,
          deploy,
        })}
      >
        Close
      </StyledButton>
    </StyledDialogActions>
  );
};

export const ContentRenderer = ({ deployDialogState, data }) => {
  useEffect(() => {
    data.refetch();
  }, []);

  if (data.isLoading) return <DataLoadingModal />;
  switch (deployDialogState) {
    case DEPLOY_CSV_STATES.SELECTION:
      return <SelectionModal />;
    case DEPLOY_CSV_STATES.CONFIRMATION:
      return <ConfirmationModal />;
    case DEPLOY_CSV_STATES.IN_PROGRESS:
      return <InProgressModal />;
    case DEPLOY_CSV_STATES.SUCCESS:
      return <SuccessModal />;
    case DEPLOY_CSV_STATES.FAILED:
      return <FailureModal />;
    default:
      return null;
  }
};

export const actionButtonRenderer = (deployDialogState) => {
  if (
    deployDialogState === DEPLOY_CSV_STATES.IDLE ||
    deployDialogState === DEPLOY_CSV_STATES.SELECTION ||
    deployDialogState === DEPLOY_CSV_STATES.CONFIRMATION
  )
    return <ActionButtons />;
  if (
    deployDialogState === DEPLOY_CSV_STATES.IN_PROGRESS ||
    deployDialogState === DEPLOY_CSV_STATES.SUCCESS ||
    deployDialogState === DEPLOY_CSV_STATES.FAILED
  )
    return <CloseButton />;
  return null;
};
