import { useQuery } from 'react-query';
import { MetricsTableView } from './metricsTableView';
import { getCustomerMetrics } from '../../request/configViewRequests';
import { ConfigSectionTitle } from './configViewTitle';
import { DEFAULT_TABS } from './configTabs';
import { ConfigSectionBody } from './styledComponents';

export const MetricsViewProvider = () => {
  const { data: customerMetrics, isLoading } = useQuery('metrics', async () => {
    const response = await getCustomerMetrics();
    return response.data.metrics;
  });

  return (
    <>
      <ConfigSectionTitle currentTabTitle={DEFAULT_TABS.METRICS} />
      <ConfigSectionBody>
        <MetricsTableView data={customerMetrics || []} isLoading={isLoading} />
      </ConfigSectionBody>
    </>
  );
};
