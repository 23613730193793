import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { DeployCsvDialog } from './deployCsvDialog';
import { deployGskCsv, getGskCsv } from '../../../request/projectRequests';
import { useDeployDialogContext } from './deployDialogContext';

const getCacheKey = (object) => Object.values(object);

export function DeployCsvProvider() {
  const { activeProject } = useDeployDialogContext();
  const [projectParams, setProjectParamState] = useState({
    projectId: '',
  });

  useEffect(() => {
    setProjectParamState({ projectId: activeProject.projectId });
  }, [activeProject]);

  const deployMutation = useMutation(({ path, filename, newFilename }) =>
    deployGskCsv({
      projectId: projectParams.projectId,
      path,
      filename,
      newFilename,
    })
  );

  const fileQuery = useQuery(
    getCacheKey(projectParams),
    async () => {
      if (!projectParams.projectId) return null;
      const response = await getGskCsv({ projectId: projectParams.projectId });
      return response.data;
    },
    { enabled: false }
  );

  return <DeployCsvDialog data={fileQuery} deploy={deployMutation} />;
}
