import { useContext } from 'react';
import { FieldContext } from '../../fieldContext';

const getParentDistrict = (region, hierarchy) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const node of hierarchy.nodes) {
    if (node.metadata.maptualListId === region) {
      return hierarchy.metadata.maptualListId;
    }

    const parent = getParentDistrict(region, node);
    if (parent) {
      return parent;
    }
  }
  return null;
};

export const useRegions = () => {
  const { maptualListHierarchy } = useContext(FieldContext);

  const getRegionDistrict = (region) => {
    const parent = getParentDistrict(region, maptualListHierarchy);
    return parent;
  };

  return { getRegionDistrict };
};
