import {
  Cluster,
  ClusterStats,
  MarkerUtils,
  Renderer,
} from '@googlemaps/markerclusterer';
import { Marker } from '@vis.gl/react-google-maps';

export const entityClusterRenderer: Renderer = {
  render: (
    { count, position }: Cluster,
    stats: ClusterStats,
    map: window.google.maps.Map
  ): Marker => {
    const color = '#0765C2';

    // change size if this cluster has more markers than the mean cluster
    const size = Math.max(50 + (count / stats.clusters.markers.sum) * 200);

    // create svg literal with fill color
    const svg = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="${size}" height="${size}">
  <circle cx="120" cy="120" opacity=".6" r="70" />
  <circle cx="120" cy="120" opacity=".3" r="90" />
  <circle cx="120" cy="120" opacity=".2" r="110" />
  <text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="50" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${count}</text>
  </svg>`;

    const title = `Cluster of ${count} markers`;
    // adjust zIndex to be above other markers
    const zIndex: number = Number(window.google.maps.Marker.MAX_ZINDEX) + count;

    if (MarkerUtils.isAdvancedMarkerAvailable(map)) {
      // create cluster SVG element
      const parser = new DOMParser();
      const svgEl = parser.parseFromString(
        svg,
        'image/svg+xml'
      ).documentElement;
      svgEl.setAttribute('transform', 'translate(0 25)');

      const clusterOptions: window.google.maps.marker.AdvancedMarkerElementOptions =
        {
          map,
          position,
          zIndex,
          title,
          content: svgEl,
        };
      return new window.google.maps.marker.AdvancedMarkerElement(
        clusterOptions
      );
    }
    return null;
  },
};
