import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  styled,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ProductBasketModalForm from '../productBasketModalForm';
import { NewProjectContext } from '../newProjectContext';

const StyledAccordion = styled(Accordion)(({ theme: { themeColors } }) => ({
  width: '100%',
  padding: 0,
  margin: 0,
  background: themeColors.buttonBackgroundColor,
  '&:before': {
    display: 'none',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)({
  padding: '0 20px',
  justifyContent: 'start',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  justifyContent: 'start',
});

const AccordionText = styled(Typography)({
  padding: '22px 0',
  paddingRight: 4,
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
});

const AccordionLabel = styled(AccordionText)({
  lineHeight: '135%',
  fontWeight: 500,
  fontSize: 16,
  width: 264,
  flexShrink: 1,
});

const DropdownIconContainer = styled(`div`)({
  padding: 'auto 0',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px ',
  marginLeft: 'auto',
  marginRight: '9px',
});

const LabelContainer = styled(`div`)({
  padding: 'auto 0',
  maxWidth: '88%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
});

const AccordianRowContainer = styled(`div`)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
});

const AccordionSelection = styled(AccordionText)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.buttonSecondaryBackgroundColour,
    flexShrink: 2,
    maxWidth: 240,
  })
);

const StyledButton = styled(`div`)(({ theme: { themeColors } }) => ({
  color: themeColors.buttonSecondaryBackgroundColour,
}));

const StyledDeleteButton = styled(`div`)(({ theme: { themeColors } }) => ({
  color: themeColors.precursorDeleteFromListButtonColor,
}));

const getComparableBasketProperties = ({
  basketScoringWeight,
  therapeuticAreas,
  products,
  indications,
  specialties,
  metrics,
}) => ({
  basketScoringWeight,
  therapeuticAreas,
  products,
  indications,
  specialties,
  metrics,
});

export const ProductBasketDropdown = ({
  basketKey,
  title,
  basketType,
  basketText,
  showActionButtons = false,
  hasDeleteButton,
  hasNewBasketButton,
  onPrecursorAdd,
  onPrecursorDelete,
  activeDropdown,
  activeDropdownToggle,
  preloadData,
  expandContractDisabled,
  setExpandContractDisabled,
}) => {
  const { marketBasketsScoringWeight } = useContext(NewProjectContext);
  const initialState = useMemo(() => {
    const defaultBasketScoringWeight =
      marketBasketsScoringWeight?.[basketText.toLowerCase()];
    return {
      basketName: '',
      basketType: '',
      basketText: '',
      basketKey: 0,
      basketScoringWeight: defaultBasketScoringWeight ?? 0,
      therapeuticAreas: [],
      products: [],
      indications: [],
      specialties: [],
      metrics: [],
    };
  }, [marketBasketsScoringWeight]);
  const [basketConfig, setBasketConfig] = useState(initialState);
  const [isPreLoaded, setIsPreLoaded] = useState(false);
  const summaryText = useMemo(
    () => `
  ${[
    basketConfig.products
      .map((product) => product.productName.toUpperCase())
      .join(', '),
    basketConfig.therapeuticAreas
      .map((therapeuticArea) => therapeuticArea.toUpperCase())
      .join(', '),
    basketConfig.indications
      .map((indication) => indication.toUpperCase())
      .join(', '),
    basketConfig.specialties
      .map((specialty) => specialty.toUpperCase())
      .join(', '),
  ]
    .filter((value) => value !== '')
    .join(', ')}
  `,
    [basketConfig]
  );
  useEffect(() => {
    if (preloadData) {
      setBasketConfig(preloadData);
      setIsPreLoaded(true);
    }
  }, [preloadData]);

  const basketIsSet = (basket) => {
    const basketProperties = JSON.stringify(
      getComparableBasketProperties(basket)
    );
    const defaultStateProperties = JSON.stringify(
      getComparableBasketProperties(initialState)
    );
    return basketProperties !== defaultStateProperties;
  };

  const handleAccordionChange = () => {
    if (!expandContractDisabled) {
      activeDropdownToggle();
    }
  };

  return (
    <StyledAccordion
      onChange={handleAccordionChange}
      expanded={basketKey === activeDropdown}
    >
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordianRowContainer>
          <LabelContainer>
            <AccordionLabel variant="body1">{title}</AccordionLabel>
            {basketKey !== activeDropdown && (
              <AccordionSelection>
                {basketIsSet(basketConfig) ? summaryText : 'No selection'}
              </AccordionSelection>
            )}
          </LabelContainer>
          {showActionButtons && (
            <DropdownIconContainer>
              {hasDeleteButton && (
                <StyledDeleteButton onClick={onPrecursorDelete}>
                  <HighlightOffIcon />
                </StyledDeleteButton>
              )}
              {hasNewBasketButton && (
                <StyledButton onClick={onPrecursorAdd}>
                  <ControlPointDuplicateIcon />
                </StyledButton>
              )}
            </DropdownIconContainer>
          )}
        </AccordianRowContainer>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <ProductBasketModalForm
          basketName={basketConfig.basketName}
          basketKey={basketKey}
          basketType={basketType}
          basketText={basketText}
          basketConfig={basketConfig}
          setBasketConfig={setBasketConfig}
          isPreLoaded={isPreLoaded}
          setIsError={setExpandContractDisabled}
        />
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};
