import { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { FilterSidebar } from '@odaia/shared-components/src/map/filtering/filterSidebar';
import { ROUTES } from '~/constants';
import { useUserCreatedLists } from '~/containers/fieldview/useUserCreatedLists';
import { useSegmentFilters } from '~/containers/fieldview/useSegmentFilters';
import { useRouteMatch } from 'react-router-dom';
import { useEntityCuration } from '~/request/queries';
import EmptyView from '~/components/emptyview/emptyView';
import { FieldContext } from './fieldContext';
import { ListProvider } from './list/ListProvider';
import { MapProvider } from './map/mapProvider';
import { CallPlanDrawer } from './callPlan/CallPlanDrawer';
import {
  applyListFilters,
  defaultFiltersOff,
  defaultFiltersOn,
  Filters,
  getActiveFilterCount,
  getFilters,
  saveFilters,
  searchItems,
} from './FieldMainFilters';
import { useEntityCoordinates } from './map/hooks/useEntityCoordinates';
import { useEntityPrecallInsights } from './map/hooks/useEntityPrecallInsights';
import { useEntityRuleInsights } from './map/hooks/useEntityRuleInsights';
import { useProjectTargetProducts } from '../pulse/context/hooks/useProjectTargetProducts';
import { useHcpCrmData } from '../pulse/views/rep/table/row/hooks/useHcpCrmData';
import { AppContext } from '../../appContext';
import {
  getCustomListMembership,
  getEntityInsightToDisplay,
} from './FieldMainUtils';
import { ControlBar } from './FieldMainControls';
import { getObjectiveProducts } from '../pulse/shared/utils';
import useFeatureToggles from '../../../../hooks/useFeatureToggles';

const PageContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',
}));

const ViewContainer = styled('div')(() => ({
  padding: 16,
  height: '100%',
}));

const MapViewContainer = styled('div')(({ isVisible }) => ({
  padding: '24px 0 0 0',
  height: 'calc(100vh - 120px)',
  display: isVisible ? 'flex' : 'none',
}));

const VIEW = {
  LIST: 'LIST',
  MAP: 'MAP',
};

const EmptyViewContainer = styled('div')(() => ({
  height: 400,
}));

export const FieldMain = () => {
  const { profile } = useContext(AppContext);
  const {
    maptualListMetadata,
    maptualList,
    projectMaptualList,
    objective,
    setObjective,
    maptualListObjectives,
    relevantUserSpecialties,
    project,
    isMaptualListLoading,
    projectMaptualListLoading,
  } = useContext(FieldContext);

  const [mergedMaptualList, setMergedMaptualList] = useState(null);
  const [filteredMaptualList, setFilteredMaptualList] = useState(null);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<Filters>(defaultFiltersOn);
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  const [entityDrawerOpen, setEntityDrawerOpen] = useState(false);
  const [entityDrawerId, setEntityDrawerId] = useState<string | null>(null);

  const [isSuggestedEntities, setIsSuggestedEntities] = useState(false);
  const [isListUpdating, setIsListUpdating] = useState(false);

  const featureToggles = useFeatureToggles();

  const showCurationData = featureToggles(
    profile.userGroup,
    'showFieldCoreCurationData'
  );

  const openEntityDrawer = (entityId: string) => {
    setEntityDrawerOpen(true);
    setEntityDrawerId(entityId);
  };

  const { data: entityCoordinatesData, isLoading: isLoadingEntityCoords } =
    useEntityCoordinates({
      maptualListMetadata,
      project,
    });

  const { data: projectTargetProducts, isLoading: isProductsLoading } =
    useProjectTargetProducts(
      project?.metadata?.productLineId,
      project?.metadata?.projectId
    );

  const {
    data: entityPrecallInsights,
    isLoading: isEntityPrecallInsightsLoading,
  } = useEntityPrecallInsights({
    maptualListMetadata,
    projectId: project?.metadata?.projectId,
    objectiveId: objective?.id,
  });

  const { data: entityRuleInsights, isLoading: isEntityRuleInsightsLoading } =
    useEntityRuleInsights({
      regionId: maptualListMetadata.sfMaptualListId,
      productLineId: project?.metadata?.productLineId,
      objectiveId: objective?.id,
      projectId: project?.metadata?.projectId,
    });

  const { data: entityCuration, isLoading: isEntityCurationLoading } =
    useEntityCuration({
      regionId: maptualListMetadata.sfMaptualListId,
      objectiveId: objective?.id,
    });

  const { data: entityCrmData, isLoading: isEntityCrmDataLoading } =
    useHcpCrmData({
      productLineId: project?.metadata?.productLineId,
      projectId: project?.metadata?.projectId,
      regionId: maptualListMetadata.sfMaptualListId,
      entityId: null,
      products: getObjectiveProducts(projectTargetProducts, objective?.id),
      latestCurationTimestamp: null,
    });

  const { userCreatedLists } = useUserCreatedLists({
    userId: profile?.userId,
  });

  const productLineUserCreatedLists =
    userCreatedLists?.[project?.metadata?.productLineId];

  // setting up objective level list
  useEffect(() => {
    if (maptualListObjectives && (!objective || !objective.id)) {
      setObjective(maptualListObjectives[0]);
    }
  }, [maptualListObjectives]);

  // setting up filters
  useEffect(() => {
    if (project?.metadata?.projectId) {
      const sessionFilters = getFilters(project?.metadata?.projectId);
      setFilters(sessionFilters);
    }
  }, [project?.metadata?.projectId]);

  // setting up maptual list with objective scores and coordinate data
  useEffect(() => {
    if (
      projectMaptualList?.list?.items &&
      maptualList?.list?.items &&
      projectMaptualList?.metadata?.sfMaptualListId ===
        maptualList?.metadata?.sfMaptualListId &&
      entityCoordinatesData?.length !== 0 &&
      entityPrecallInsights &&
      entityRuleInsights &&
      entityCuration &&
      entityCrmData
    ) {
      const updatedList = {
        ...maptualList,
        list: {
          ...maptualList.list,
          items: maptualList.list.items.map((item) => {
            const entityCoordinates = entityCoordinatesData?.find(
              (entity) =>
                Number(entity.entityId) === Number(item.snowflakeEntityId)
            );
            const insight = getEntityInsightToDisplay(
              item.snowflakeEntityId,
              entityPrecallInsights,
              entityRuleInsights?.data
            );
            const entityObjecitveScores = projectMaptualList.list.items.find(
              (i) => i.entityId === item.entityId
            )?.objectiveScores;

            return {
              ...item,
              objectiveScores: entityObjecitveScores,
              coordinates: {
                latitude: entityCoordinates?.latitude,
                longitude: entityCoordinates?.longitude,
              },
              address: entityCoordinates?.address,
              insight: {
                title: insight?.title,
                description: insight?.description,
              },
              customListMembership: getCustomListMembership(
                item.snowflakeEntityId,
                productLineUserCreatedLists
              ),
              curationRank: entityCuration?.[item.snowflakeEntityId],
              crmData: {
                qtdCallCount: entityCrmData?.[Number(item.snowflakeEntityId)],
              },
            };
          }),
        },
      };

      setMergedMaptualList(updatedList);
    }
  }, [
    projectMaptualList,
    maptualList,
    entityCoordinatesData,
    entityPrecallInsights,
    entityRuleInsights,
    entityCuration,
  ]);

  useEffect(() => {
    if (mergedMaptualList?.list?.items?.length > 0 && objective?.id) {
      let newList = applyListFilters(
        mergedMaptualList?.list?.items,
        maptualListObjectives.findIndex((o) => o.id === objective.id),
        filters,
        isSuggestedEntities
      );

      newList = searchItems(newList, search);

      const newFilteredMaptualList = {
        ...mergedMaptualList,
        list: {
          items: newList,
        },
      };

      const numActiveFilters = getActiveFilterCount(filters, defaultFiltersOff);
      setFilteredMaptualList(newFilteredMaptualList);
      setActiveFilterCount(numActiveFilters);

      saveFilters(filters, project?.metadata?.projectId);
      setIsListUpdating(false);
    }
  }, [filters, mergedMaptualList, search, isSuggestedEntities]);

  const { segmentFilters, isSegmentFiltersBusy } = useSegmentFilters({
    regionId: maptualListMetadata.sfMaptualListId,
    objectiveId: objective?.id,
  });

  const match = useRouteMatch();

  const view = match.url.includes(`${ROUTES.HCPS}${ROUTES.MAP}`)
    ? VIEW.MAP
    : VIEW.LIST;

  const isLoading =
    isLoadingEntityCoords ||
    isEntityPrecallInsightsLoading ||
    isEntityRuleInsightsLoading ||
    isEntityCurationLoading ||
    isEntityCrmDataLoading ||
    isProductsLoading ||
    isMaptualListLoading ||
    projectMaptualListLoading ||
    isListUpdating;

  return (
    <>
      <PageContainer>
        <ControlBar
          mergedMaptualList={mergedMaptualList}
          showCurationData={showCurationData}
          setSearch={setSearch}
          activeFilterCount={activeFilterCount}
          setIsFilterDrawerOpen={() => {
            setIsFilterDrawerOpen(!isFilterDrawerOpen);
          }}
          isSuggestedEntities={isSuggestedEntities}
          setIsSuggestedEntities={() => {
            setIsSuggestedEntities(!isSuggestedEntities);
            setIsListUpdating(true);
          }}
        />
        {isLoading && (
          <EmptyViewContainer>
            <EmptyView isLoading />
          </EmptyViewContainer>
        )}
        {!isLoading && (
          <>
            {view === VIEW.LIST && (
              <ViewContainer>
                <ListProvider
                  maptualList={filteredMaptualList}
                  onRowClick={(entityId) => openEntityDrawer(entityId)}
                  isProductsLoading={isProductsLoading}
                  projectTargetProducts={projectTargetProducts}
                  showCurationData={showCurationData}
                  isSuggestedEntities={isSuggestedEntities}
                />
              </ViewContainer>
            )}
            <MapViewContainer isVisible={view === VIEW.MAP}>
              <MapProvider
                mergedMaptuaList={mergedMaptualList}
                filteredList={filteredMaptualList}
                objective={objective}
                isLoading={isLoading}
                onEntityDetailsClick={(entityId) => openEntityDrawer(entityId)}
              />
            </MapViewContainer>
            <CallPlanDrawer
              open={entityDrawerOpen}
              entityId={entityDrawerId}
              snowflakeMaptualListId={maptualListMetadata.sfMaptualListId}
              setOpen={setEntityDrawerOpen}
            />
          </>
        )}
      </PageContainer>
      {mergedMaptualList?.list?.items.length > 0 && (
        <FilterSidebar
          isFilterDrawerOpen={isFilterDrawerOpen}
          setIsFilterDrawerOpen={setIsFilterDrawerOpen}
          filters={filters}
          setFilters={setFilters}
          relevantUserSpecialties={relevantUserSpecialties}
          filterMetadata={segmentFilters}
          filterMetadataLoading={isSegmentFiltersBusy}
          objective={objective}
        />
      )}
    </>
  );
};
