import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Typography from '@mui/material/Typography';

import { ProjectRunStatusResponse } from '../hooks/useProjectStatus';
import TaskIconWrapper from '../../common/taskIconWrapper';
import humanizeDuration from '../../common/humanizeDuration';

export default function FailedProjectStatus({
  status,
}: {
  status: ProjectRunStatusResponse;
}) {
  const totalRuntime = humanizeDuration(status.currentRuntime);
  return (
    <TaskIconWrapper color="error">
      <CancelOutlinedIcon fontSize="small" color="error" />
      <Typography variant="body2">
        Project encountered an error after {totalRuntime}
      </Typography>
    </TaskIconWrapper>
  );
}
