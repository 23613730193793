import Chart from 'react-apexcharts';
import { useContext, useEffect, useState, useMemo } from 'react';
import { useTheme } from '@mui/styles';
import { TerritoryOverviewContext } from '../../territoryOverviewContext';
import {
  formatSupplyChartSeries,
  formatSupplyChartXAxis,
  territoryOverviewDataExists,
} from '../../utils';

const LineWeights = {
  Solid: 0,
  Dashed: 5,
};

export const SupplyChart = ({ isLoading }) => {
  const { data, selectedTimeframeID, selectedSupplyRows } = useContext(
    TerritoryOverviewContext
  );
  const { themeColors } = useTheme();

  const [series, setSeries] = useState([]);
  const [lineColors, setLineColors] = useState([]);
  const [dashArray, setDashArray] = useState([]);
  const [xLabels, setXLabels] = useState([]);
  const [yAxis, setYAxis] = useState([]);

  const labelStyles = {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 500,
  };

  const xAxisColors = xLabels.length > 0 && [
    ...Array(xLabels.length - 1).fill(
      themeColors.primaryMaptualListFilteringColor
    ),
    themeColors.primaryTextColor,
  ];

  useEffect(() => {
    const hasTerritoryOverviewData = territoryOverviewDataExists(
      data,
      selectedTimeframeID
    );

    if (hasTerritoryOverviewData) {
      const timescaleData = data.timescaleData[selectedTimeframeID];

      setXLabels(formatSupplyChartXAxis(timescaleData.headers));

      const seriesToGraph = formatSupplyChartSeries(selectedSupplyRows);

      setSeries(seriesToGraph);
      setDashArray(
        seriesToGraph.map(({ name }) => {
          if (name === 'activity') {
            return LineWeights.Dashed;
          }
          return LineWeights.Solid;
        })
      );
      setLineColors(selectedSupplyRows.map((item) => item.color));
    }
  }, [data, selectedSupplyRows, selectedTimeframeID]);

  useEffect(() => {
    let seenVolume = false;
    let seenActivity = false;

    setYAxis(
      series.map(({ name }) => {
        if (name === 'volume') {
          if (seenVolume) {
            return {
              seriesName: 'volume',
              show: false,
            };
          }

          seenVolume = true;
          return {
            seriesName: 'volume',
            forceNiceScale: true,
            labels: {
              show: true,
              style: {
                ...labelStyles,
                colors: themeColors.primaryMaptualListFilteringColor,
              },
              formatter: (value) => Math.round(value),
            },
            title: {
              text: 'Volume',
              style: {
                ...labelStyles,
                color: themeColors.primaryMaptualListFilteringColor,
              },
            },
          };
        }

        if (name === 'activity') {
          if (seenActivity) {
            return {
              seriesName: 'activity',
              opposite: true,
              show: false,
            };
          }

          seenActivity = true;
          return {
            seriesName: 'activity',
            opposite: true,
            forceNiceScale: true,
            labels: {
              show: true,
              style: {
                ...labelStyles,
                colors: themeColors.primaryMaptualListFilteringColor,
              },
              formatter: (value) => Math.round(value),
            },
            title: {
              rotate: 90,
              text: 'Activity',
              style: {
                ...labelStyles,
                color: themeColors.primaryMaptualListFilteringColor,
              },
            },
          };
        }

        return {};
      })
    );
  }, [series]);

  const chartOptions = useMemo(
    () => ({
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: lineColors,
      stroke: {
        curve: 'straight',
        width: 2,
        dashArray,
      },
      grid: {
        show: true,
        borderColor: '#202227',
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        labels: {
          show: true,
          style: { ...labelStyles, colors: xAxisColors },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        categories: xLabels,
      },
      yaxis: yAxis,
      tooltip: {
        enabled: false,
      },
    }),
    [xLabels, yAxis]
  );

  if (isLoading) {
    return null;
  }

  return (
    <Chart
      options={chartOptions}
      series={series}
      type="line"
      height={214}
      width="100%"
    />
  );
};
