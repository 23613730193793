import { useState } from 'react';
import { styled, Button, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import {
  ModalDisplay,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '../../../../../../components/generic/modalDisplay';
import { startCurationExecution } from '../../../../../../request/projectRequests';
import { capitalizeFirstLetter } from '../timeline/Timeline';
import { ExecutionConfirmationModal } from './executionConfirmationModal';

const StyledTypography = styled(Typography)(() => ({
  padding: '8px 16px',
  color: '#E0E4EB',
  textAlign: 'left',
  fontFamily: 'Roboto',
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '135%',
}));
const StyledButton = styled(Button)(() => ({
  color: '#D6E2F5',
  height: 32,
  borderRadius: 4,
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
}));

function CurationExecutionModal({ isOpen, handleClose, projectId, mode }) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { mutate: startCuration } = useMutation(() =>
    startCurationExecution({
      projectId,
      mode,
    })
  );

  const handleStartExecution = () => {
    startCuration();
    handleClose();
  };

  const handleConfirmationClose = () => {
    setShowConfirmationModal(false);
  };

  const handleUpperClose = () => {
    handleClose();
  };

  return (
    <>
      <ModalDisplay onClose={() => handleClose()} isOpen={isOpen}>
        <ModalHeader shouldShowXIcon={false}>{`${capitalizeFirstLetter(
          mode || ''
        )} Curation Run`}</ModalHeader>
        <ModalBody>
          <StyledTypography>
            {`Clicking "Start Execution" will begin the ${mode} curation pipeline, please be
          cognizant of starting multiple executions.`}
          </StyledTypography>
        </ModalBody>
        <ModalFooter style={{ padding: 16 }}>
          <StyledButton
            style={{ border: '1px solid #313B49', backgroundColor: '#1F232C' }}
            onClick={() => handleClose()}
          >
            Cancel
          </StyledButton>
          <StyledButton
            style={{ backgroundColor: '#0D47A0' }}
            onClick={() => {
              if (mode === 'live') {
                setShowConfirmationModal(true);
              } else {
                handleStartExecution();
              }
            }}
          >
            Start Run
          </StyledButton>
        </ModalFooter>
      </ModalDisplay>
      <ExecutionConfirmationModal
        isModalOpen={showConfirmationModal}
        onClose={handleConfirmationClose}
        onConfirm={() => handleStartExecution()}
        handleUpperClose={handleUpperClose}
      />
    </>
  );
}

export default CurationExecutionModal;
