import { useContext } from 'react';
import Fade from '@mui/material/Fade';
import { Box, CardActionArea, styled } from '@mui/material';
import { useHistory } from 'react-router-dom';
import AdminViewIcon from '../../assets/appIcons/Admin.svg?react';
import FieldViewIcon from '../../assets/appIcons/Field.svg?react';
import SphereViewIcon from '../../assets/appIcons/Sphere.svg?react';
import CoachIcon from '../../assets/appIcons/Coach.svg?react';
import LabsViewIcon from '../../assets/appIcons/Labs.svg?react';
import AdminLightIcon from '../../assets/appIcons/Admin-light.svg?react';
import FieldLightIcon from '../../assets/appIcons/Field-light.svg?react';
import SphereLightIcon from '../../assets/appIcons/Sphere-light.svg?react';
import CoachLightIcon from '../../assets/appIcons/Coach-light.svg?react';
import LabsLightIcon from '../../assets/appIcons/Labs-light.svg?react';
import { APPVIEWS, ROUTES } from '../../constants';
import useFeatureToggles from '../../hooks/useFeatureToggles';
import { AppContext } from '../../containers/application/appContext';
import { ThemeContext } from '../../containers/application/themeContext';

const CardContainer = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  backgroundColor: themeColors.mainBackground,
  gap: '30px',
}));

const Card = styled(Box)(
  ({ theme: { insightContentCardStyle, themeColors, palette } }) => ({
    ...insightContentCardStyle(`${palette.mode}Theme`),
    width: '20%',
    height: '30%',
    minWidth: 150,
    minHeight: 150,
    padding: 0,
    marginBottom: 0,
    backgroundColor: themeColors.contentCardBackgroundColor,
    borderColor: themeColors.contentCardBorderColor,
  })
);

const StyledCardActionArea = styled(CardActionArea)({
  padding: 15,
  width: '100%',
  height: '100%',
  display: 'flex',
  svg: {
    height: '80%',
  },
});

const isAdminUser = (profile) => profile?.role?.type === 'ADMIN';

export default function AppSelectView({
  maptualAccess = [],
  selectApp = () => {},
}) {
  const routeHistory = useHistory();
  const { profile } = useContext(AppContext);
  const {
    activeTheme: { palette },
  } = useContext(ThemeContext);

  const selectedTheme = palette.mode;
  const getFeatureToggle = useFeatureToggles();

  const appIconMap = {
    dark: {
      [APPVIEWS.FIELD]: <FieldViewIcon />,
      [APPVIEWS.SPHERE]: <SphereViewIcon />,
      [APPVIEWS.ADMIN]: <AdminViewIcon />,
      [APPVIEWS.LABS]: <LabsViewIcon />,
      [APPVIEWS.PULSE]: <CoachIcon />,
      [APPVIEWS.HEADOFFICE_OLDNAME]: <SphereViewIcon />,
    },
    light: {
      [APPVIEWS.FIELD]: <FieldLightIcon />,
      [APPVIEWS.SPHERE]: <SphereLightIcon />,
      [APPVIEWS.ADMIN]: <AdminLightIcon />,
      [APPVIEWS.LABS]: <LabsLightIcon />,
      [APPVIEWS.PULSE]: <CoachLightIcon />,
      [APPVIEWS.HEADOFFICE_OLDNAME]: <SphereLightIcon />,
    },
  };

  const showMaptualLabs =
    getFeatureToggle(profile?.userGroup, 'showMaptualLabs') &&
    isAdminUser(profile);

  if (maptualAccess.length === 1) {
    selectApp(maptualAccess[0]);
    routeHistory.push(ROUTES.ROOT);
    return null;
  }

  return (
    <Fade in timeout={1400}>
      <CardContainer data-testid="app-selection-cards">
        {showMaptualLabs ? (
          <Card key="LABS">
            <StyledCardActionArea
              data-testid="test-app-select-LABS"
              onClick={() => {
                window.location.assign('https://labs.odaia.ai');
              }}
            >
              {appIconMap[selectedTheme].LABS || null}
            </StyledCardActionArea>
          </Card>
        ) : null}

        {maptualAccess.map((item) => (
          <Card key={item}>
            <StyledCardActionArea
              data-testid={`test-app-select-${item}`}
              onClick={() => {
                selectApp(item);
                routeHistory.push(ROUTES.ROOT);
              }}
            >
              {appIconMap[selectedTheme][item] || null}
            </StyledCardActionArea>
          </Card>
        ))}
      </CardContainer>
    </Fade>
  );
}
