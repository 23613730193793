import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import TextInput from './formInputs/textInput';
import ChipSelectionInput from './formInputs/chipSelectionInput';
import { postUser, putUser } from '../../slices/userManagement';
import getUserManagementState from '../../hooks/userManagement';
import ProjectsSelectionInput from './formInputs/projectsSelectionInput';
import PermissionToggle from './formInputs/permissionToggle';
import TerritoriesSelectionInput from './formInputs/territoriesSelectionInput';
import AccountTypeSelectionInput from './formInputs/accountTypeSelectionInput';
import UserRoleSelectionInput from './formInputs/userRoleSelectionInput';
import { ModalBody, ModalFooter } from '../generic/modalDisplay';

const formInitState = {
  firstName: '',
  lastName: '',
  email: '',
  indications: [],
  territoryIds: [],
  assignedTerritoryIds: [],
  maptualAccess: ['FIELD'],
  permission: {
    ALLOW_CSV_DOWNLOAD: false,
  },
  projects: [],
  specialty: [],
  therapeuticAreas: [],
  markets: [],
  projectFilters: [],
  specialType: 'N/A',
  group: '',
  department: '',
  role: '',
};

let requiredInputs = [
  'firstName',
  'lastName',
  'email',
  'maptualAccess',
  'projectFilters',
  'group',
  'department',
  'role',
];

const InputRow = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  gridGap: 20,
}));

const checkValidity = (value) => value && value.length > 0;

const checkEmailValidity = (email, domains) => {
  const valid = /^([^@\n])+@.+\..+$/.test(email);
  if (!valid) {
    return 'Please enter a valid email address';
  }
  const validDomain = domains.some((domain) => {
    const emailRegex = new RegExp(`@${domain}$`);
    return emailRegex.test(email);
  });

  if (!validDomain) {
    return `You can only use email addresses that are @${domains.join(', @')}`;
  }
  return '';
};

const UserForm = ({ onClose }) => {
  const state = getUserManagementState();
  const customerProjects = state?.customerFormData?.projects;
  const customerIndications = state?.customerFormData?.indications;
  const customerSpecialty = state?.customerFormData?.specialty;
  const customerTherapeuticAreas = state?.customerFormData?.therapeuticAreas;
  const customerTerritories = state?.customerFormData?.territories;
  const customerDomains = state?.customerFormData?.domains;
  const customerMarkets = state?.customerFormData?.markets;
  const projectFilterOptions = {
    ...customerIndications,
    ...customerSpecialty,
    ...customerTherapeuticAreas,
    ...customerMarkets,
  };

  const [formInput, setFormInput] = useState(
    state.userId
      ? state.usersData.filter((user) => user.id === state.userId)[0]
      : formInitState
  );

  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [enabledErrors, setEnabledErrors] = useState(
    state.userId
      ? {}
      : requiredInputs.reduce((obj, field) => {
          if (field === 'email') {
            const newEmailErrorMsg = checkEmailValidity(
              formInput.email,
              customerDomains
            );
            return newEmailErrorMsg ? { ...obj, email: false } : obj;
          }
          return !checkValidity(formInput[field])
            ? { ...obj, [field]: false }
            : obj;
        }, {})
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const indications = new Set();
    const specialty = new Set();
    const therapeuticAreas = new Set();
    const markets = new Set();

    formInput.projectFilters.forEach((filter) => {
      const id = filter.split('_')[1];
      if (filter in customerIndications) {
        indications.add(id);
      } else if (filter in customerSpecialty) {
        specialty.add(id);
      } else if (filter in customerTherapeuticAreas) {
        therapeuticAreas.add(id);
      } else if (filter in customerMarkets) {
        markets.add(id);
      }
    });

    setFormInput({
      ...formInput,
      indications: Array.from(indications),
      specialty: Array.from(specialty),
      therapeuticAreas: Array.from(therapeuticAreas),
      markets: Array.from(markets),
    });
  }, [formInput.projectFilters]);

  useEffect(() => {
    const roleTypes = ['group', 'department', 'role'];

    if (formInput.specialType === 'N/A') {
      const updatedEnabledErrors = {};
      roleTypes.forEach((roleType) => {
        if (requiredInputs.indexOf(roleType) === -1) {
          requiredInputs.push(roleType);
        }
        if (!formInput?.[roleType]) {
          updatedEnabledErrors[roleType] = false;
        }
      });
      setEnabledErrors({ ...enabledErrors, ...updatedEnabledErrors });
    } else {
      requiredInputs = requiredInputs.filter(
        (item) => !roleTypes.includes(item)
      );
      const updatedEnabledErrors = { ...enabledErrors };
      roleTypes.forEach((roleType) => delete updatedEnabledErrors[roleType]);
      setEnabledErrors(updatedEnabledErrors);
    }
  }, [formInput.specialType]);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    let formToSubmit = { ...formInput };
    if (formInput?.specialType !== 'N/A') {
      formToSubmit = {
        ...formInput,
        group: '',
        department: '',
        role: '',
      };
      setFormInput(formToSubmit);
    }
    if (state.userId) {
      dispatch(putUser(formToSubmit));
    } else {
      dispatch(postUser(formToSubmit));
    }
  };

  const handleInput = (id, value) => {
    setFormInput({ ...formInput, [id]: value });
    if (id === 'email') {
      const newEmailErrorMsg = checkEmailValidity(value, customerDomains);
      setEmailErrorMsg(newEmailErrorMsg);
      if (!enabledErrors?.email && newEmailErrorMsg) {
        setEnabledErrors({ ...enabledErrors, email: true });
      } else if ('email' in enabledErrors && !newEmailErrorMsg) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { email: omit, ...filteredError } = enabledErrors;
        setEnabledErrors(filteredError);
      }
    } else if (requiredInputs.includes(id)) {
      if (!enabledErrors?.[id] && !checkValidity(value)) {
        setEnabledErrors({ ...enabledErrors, [id]: true });
      } else if (id in enabledErrors && checkValidity(value)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [id]: omit, ...filteredError } = enabledErrors;
        setEnabledErrors(filteredError);
      }
    }
  };
  return (
    <>
      <ModalBody>
        <InputRow>
          <TextInput
            label="First Name"
            id="firstName"
            onChange={handleInput}
            error={enabledErrors}
            value={formInput?.firstName}
          />
          <TextInput
            label="Last Name"
            id="lastName"
            error={enabledErrors}
            onChange={handleInput}
            value={formInput?.lastName}
          />
        </InputRow>
        <InputRow>
          <TextInput
            label="Email"
            id="email"
            type="email"
            error={enabledErrors}
            onChange={handleInput}
            errorMessage={emailErrorMsg}
            value={formInput?.email}
          />
        </InputRow>
        <ChipSelectionInput
          id="maptualAccess"
          options={[
            { label: 'Field', id: 'FIELD' },
            { label: 'Sphere', id: 'SPHERE' },
            { label: 'Pulse', id: 'PULSE' },
          ]}
          value={formInput?.maptualAccess}
          error={enabledErrors}
          errorMessage="At least one app must be selected."
          onChange={handleInput}
          title="Apps the user will have access to"
        />

        <AccountTypeSelectionInput
          id="specialType"
          onChange={handleInput}
          value={formInput?.specialType}
        />

        {formInput?.specialType === 'N/A' ? (
          <UserRoleSelectionInput
            onChange={handleInput}
            group={formInput?.group}
            department={formInput?.department}
            formRole={formInput?.role}
            error={enabledErrors}
          />
        ) : null}

        <PermissionToggle
          id="permission"
          label="ALLOW_CSV_DOWNLOAD"
          isChecked={formInput?.permission?.ALLOW_CSV_DOWNLOAD}
          handleChange={handleInput}
          toggleText="Allow the user to download script charts and Maptual list CSVs"
        />
        <ProjectsSelectionInput
          id="projects"
          options={projectFilterOptions}
          onChange={handleInput}
          projects={customerProjects}
          value={formInput.projectFilters}
          title="Projects the user has access to"
          subtitle="The user will automatically join projects that meet the following conditions:"
          error={enabledErrors}
          errorMessage="At least one value is required"
        />
        <TerritoriesSelectionInput
          territoryId="territoryIds"
          assignedTerritoryId="assignedTerritoryIds"
          options={customerTerritories}
          onChange={handleInput}
          initialTerritoryIds={formInput.territoryIds}
          initialAssignedTerritoryIds={formInput.assignedTerritoryIds}
          title="User territories"
          subtitle="The user will be able to access the following territories"
        />
      </ModalBody>

      <ModalFooter>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={Object.keys(enabledErrors).length !== 0}
          variant="contained"
          type="submit"
          onClick={(ev) => handleSubmit(ev)}
        >
          {state.userId ? 'Update User' : 'Create User'}
        </Button>
      </ModalFooter>
    </>
  );
};

export default UserForm;
