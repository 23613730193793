/* eslint-disable no-nested-ternary */
import makeStyles from '@mui/styles/makeStyles';
import { Switch } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';
import { SentryRoute } from '../../sentryRoute';
import AnalysisItem from '../analysisview/analysisItem';
import EmptyView from '../emptyview/emptyView';
import TestDetailTabContainer from '../testDetails/testDetailTabContainer';

const useStyles = makeStyles(() => ({
  scrollAreaStyle: {
    width: '100%',
    height: '100%',
  },
  root: {
    width: '100%',
    height: '100%',
    paddingRight: '3vw',
    paddingLeft: '2vw',
    paddingTop: '2vh',
    paddingBottom: '1vh',
  },
  columnContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 20,
  },
}));

export default function ProjectFeatureView({
  getAnalysis,
  match,
  viewer: { analysisList, fullAnalysisItem, isAnalysisBusy },
}) {
  const classes = useStyles();

  const isListSingleItem = (list) =>
    list && Array.isArray(list) && list.length === 1;
  const isSingleXGridTable = (analysisItem) =>
    isListSingleItem(analysisItem.sections) &&
    analysisItem.sections[0].content.xGridTables;

  // eslint-disable-next-line default-param-last
  const singleAnalysisView = (external = false, renderProps) =>
    isAnalysisBusy || !fullAnalysisItem ? (
      <EmptyView
        isLoading={isAnalysisBusy}
        refreshAction={() => {
          const searchParams = new URLSearchParams(renderProps.location.search);
          getAnalysis(
            renderProps.match.params.externalAnalysisId,
            searchParams.get('userId')
          );
        }}
      />
    ) : fullAnalysisItem &&
      (fullAnalysisItem.headeredSection ||
        fullAnalysisItem.tabSection ||
        isSingleXGridTable(fullAnalysisItem)) ? (
      <AnalysisItem
        analysisItem={fullAnalysisItem}
        match={renderProps.match}
        external={external}
      />
    ) : (
      <Scrollbar
        disableTracksWidthCompensation
        noScrollX
        className={classes.scrollAreaStyle}
      >
        <AnalysisItem
          analysisItem={fullAnalysisItem}
          match={renderProps.match}
          external={external}
        />
      </Scrollbar>
    );

  return (
    <div className={classes.root}>
      <Switch>
        <SentryRoute
          path={match.path}
          render={(renderProps) =>
            match.params.externalAnalysisId ? (
              singleAnalysisView(true, renderProps)
            ) : isListSingleItem(analysisList) ? (
              <AnalysisItem
                analysisItem={analysisList[0]}
                match={renderProps.match}
              />
            ) : (
              <Scrollbar
                disableTracksWidthCompensation
                noScrollX
                className={classes.scrollAreaStyle}
              >
                <div className={classes.columnContainer}>
                  <TestDetailTabContainer />
                </div>
              </Scrollbar>
            )
          }
        />
      </Switch>
    </div>
  );
}
