/* eslint-disable no-restricted-syntax */

import Chart from 'react-apexcharts';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

import { darkThemeColors } from '@odaia/ui/src/colorConfig';
import { numberWithCommas } from '../../modules/commonModule';
import GraphConfig from './graphConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    paddingTop: '1rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    paddingBottom: '1rem',
    marginBottom: '1vh',
    marginRight: '3rem',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 3,
    backgroundColor: grey[900],
  },
  graphTitleContainer: {
    width: '100%',
    height: 'auto',
    margin: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  primaryText: {
    fontSize: 17,
    fontWeight: 600,
    color: theme.palette.text.secondary,
    marginBottom: '0.25rem',
  },
  secondaryText: {
    fontSize: 13,
    fontWeight: 400,
    marginBottom: '0.25rem',
    maxWidth: '15rem',
    lineHeight: 1.1,
    color: darkThemeColors.tertiaryColor,
  },
}));

export default function TimeSeries({
  graphName,
  graphHighlight,
  graphType,
  graphContent,
  graphCustomOptions,
}) {
  const classes = useStyles();

  const reduceValues = (curValues, newVal) => {
    const newCurValues = curValues.concat(Object.entries(newVal));
    return newCurValues;
  };
  const series = [];
  const MARKER_THRESHOLD = 12;
  let strokeType = 'smooth';

  for (const [seriesName, seriesValues] of Object.entries(graphContent)) {
    series.push({
      name: seriesName,
      data: seriesValues.reduce(reduceValues, []),
    });
  }

  let minY = null;
  let maxY = 0;

  series.forEach((item) => {
    item.data.forEach((s) => {
      if (s && s[1]) {
        if (s[1] !== 0 && (s[1] < minY || minY === null)) {
          // eslint-disable-next-line prefer-destructuring
          minY = s[1];
        } else {
          maxY = Math.max(maxY, s[1]);
        }
      }
    });
  });

  if (series[0].data.length <= MARKER_THRESHOLD) {
    strokeType = 'straight';
    if (graphType && graphType === 'area') {
      strokeType = 'stepline';
    }
  }

  const options = {
    chart: {
      type: graphType || 'line',
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          // eslint-disable-next-line no-bitwise
          reset: true | '<img src="../../assets/" width="20">',
        },
      },
    },
    colors: GraphConfig.graphColors,
    dataLabels: {
      enabled: false,
    },
    // if these arent set the high and low value colours are switched for some reason :/
    markers: {
      size: series[0].data.length <= MARKER_THRESHOLD ? 4 : 0,
      colors: GraphConfig.graphColors,
      strokeWidth: 1,
      strokeOpacity: 0.5,
      hover: {
        size: 4,
      },
    },
    stroke: {
      curve: strokeType,
      width: 3,
    },
    grid: {
      show: false,
    },
    fill: {
      type: graphType === 'area' ? 'gradient' : 'solid',
      gradient:
        graphType === 'area'
          ? {
              shade: 'light',
              type: 'vertical',
              shadeIntensity: 0.2,
              opacityFrom: 0.3,
              opacityTo: 0.7,
              stops: [0, 90, 100],
            }
          : {},
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'left',
      labels: {
        useSeriesColors: true,
      },
      itemMargin: {
        horizontal: 8,
        vertical: 5,
      },
      markers: {
        width: 12,
        height: 12,
        radius: 2,
      },
    },

    xaxis: {
      type: 'datetime',
      labels: {
        show: true,
        hideOverlappingLabels: true,
        style: {
          colors: grey[400],
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      min: minY,
      max: maxY,
      forceNiceScale: true,
      labels: {
        formatter: numberWithCommas,
        show: true,
        hideOverlappingLabels: true,
        style: {
          colors: grey[400],
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 400,
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      theme: 'dark',
      style: {
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: true,
        format: 'dd MMM',
        formatter: undefined,
      },
      y: {
        formatter: numberWithCommas,
      },
      marker: {
        show: true,
      },
      fixed: {
        enabled: false,
        position: 'topRight',
      },
    },
    ...graphCustomOptions,
  };

  return (
    <div className={classes.root}>
      <div className={classes.graphTitleContainer}>
        <Typography className={classes.primaryText} align="left">
          {graphName}
        </Typography>
        <Typography className={classes.secondaryText} align="left">
          {graphHighlight}
        </Typography>
      </div>
      <div style={{ width: '100%', height: GraphConfig.graphHeight }}>
        <Chart
          options={options}
          series={series}
          type={graphType || 'line'}
          height="90%"
          width="100%"
          margin="10"
        />
      </div>
    </div>
  );
}
