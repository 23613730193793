import { useContext } from 'react';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { EntityOverviewContext } from '../../data/entityOverviewContext';

const ChipsGroup = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 10,
  overflow: 'auto',
}));

const StyledChip = styled(Chip)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.chipInputDefaultSurface,
  border: '1px solid',
  borderColor: themeColors.chipInputDefaultBorder,
  color: themeColors.chipInputDefaultContent,
  borderRadius: 16,
  '& .MuiChip-deleteIcon': {
    color: themeColors.chipInputDefaultIcon,
    width: 20,
    height: 20,
  },
}));

export const FilterChips = () => {
  const { filters, setFilters } = useContext(EntityOverviewContext);

  const handleChipDelete = ({ type, value }) => {
    const newFilters = { ...filters };
    const newFilterConfig = { ...filters[type] };

    delete newFilterConfig[value];
    delete newFilterConfig.id;
    delete newFilterConfig.label;

    if (Object.keys(newFilterConfig).length === 0) {
      delete newFilters[type];
    } else {
      newFilters[type] = newFilterConfig;
    }
    setFilters(newFilters);
  };

  return (
    <ChipsGroup>
      {filters && Object.keys(filters).length > 0 && (
        <>
          {Object.entries(filters).map(([filter, filterConfig]) => {
            const renderChip = (id, label) => (
              <StyledChip
                key={`${filter}-${id}`}
                label={<Typography variant="body2">{label}</Typography>}
                variant="outlined"
                onDelete={() => handleChipDelete({ type: filter, value: id })}
                deleteIcon={<ClearIcon />}
              />
            );

            if (filterConfig.label) {
              return renderChip(filterConfig.id, filterConfig.label);
            }

            return Object.values(filterConfig).map(({ id, label }) =>
              renderChip(id, label)
            );
          })}
        </>
      )}
    </ChipsGroup>
  );
};
