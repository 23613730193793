import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Popover,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { PowerScore } from '@odaia/shared-components/src/powerScore';
import { useParams } from 'react-router-dom';
import { EntityType } from '@odaia/clients/maptualAPI';
import { SnackbarDisplay } from '../generic/snackbar';
import { trackPowerscoreFeedback } from '../../trackers/mixpanel';
import { EntityPowerscoreExplainability } from './entityPowerscore';
import useFeatureToggles from '../../hooks/useFeatureToggles';
import { isAdminAccount } from '../../request/config';
import { useEntityPowerscoreContribution } from './entityPowerscore/hooks/useEntityPowerscoreContribution';
import { ErrorContainer } from '../generic/errorContainer';
import { SkeletonBar } from '../generic/skeletonBars';
import { AppContext } from '../../containers/application/appContext';

export const PowerScorePopover = ({
  powerscore,
  objectiveScores = [],
  locality,
  regionId,
  objectiveId,
  entityType,
  entityId,
  entityName,
  isNonDld = false,
  isAmaOptOut,
  shouldPreloadData = true,
}: PowerScorePopoverProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopoverOpen = Boolean(anchorEl);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [snackPack, setSnackPack] = useState([]);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [selectionState, setSelectionState] = useState(SELECTION_STATES.NONE);
  const [shouldFetch, setShouldFetch] = useState(shouldPreloadData);

  const { profile, projectList } = useContext(AppContext);
  const { projectId } = useParams();

  const project = projectList.find((proj) => proj.projectId === projectId);
  const projectName = project?.projectName;
  const handlePowerScoreClick = (event) => {
    setShouldFetch(true);
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setIsSnackBarOpen(true);
    } else if (snackPack.length && messageInfo && isSnackBarOpen) {
      setIsSnackBarOpen(false);
    }
  }, [snackPack, messageInfo, isSnackBarOpen]);

  const isOverallPowerscore = objectiveScores.length > 1;

  const handleFeedbackButtonClick = (event, selection) => {
    const newSelection = selection || SELECTION_STATES.NONE;
    if (newSelection === SELECTION_STATES.NONE) {
      setIsSnackBarOpen(false);
    } else {
      setSnackPack((prev) => [...prev, { key: new Date().getTime() }]);
    }
    trackPowerscoreFeedback({
      userInput: newSelection,
      numberObjectives: isOverallPowerscore ? 'Multiple' : 'Single',
      scoreType: isOverallPowerscore ? 'Overall' : 'Objective',
      score: powerscore,
      projectName,
      entityName,
      entityType,
      region: locality,
    });
    setSelectionState(newSelection);
  };

  const featureToggles = useFeatureToggles();

  const hidePowerScoreExplainability = () => {
    if (isOverallPowerscore || isAmaOptOut) {
      return true;
    }

    if (isAdminAccount(profile)) {
      return false;
    }

    return (
      !featureToggles(profile.userGroup, 'showPowerScoreExplainability') ||
      isNonDld
    );
  };
  const {
    data: contributionData,
    isLoading: isContributionDataLoading,
    isError: isContributionDataError,
  } = useEntityPowerscoreContribution({
    entityId,
    entityType: entityType?.toLowerCase(),
    projectId,
    regionId,
    objectiveId,
    shouldFetch,
  });

  if (hidePowerScoreExplainability()) {
    return (
      <>
        <StyledButton onClick={handlePowerScoreClick} isActive={isPopoverOpen}>
          <PowerScore score={powerscore} objectiveScoreList={objectiveScores} />
          <ExpandMoreRoundedIcon data-testid="powerscore-arrow-drop-down-button" />
        </StyledButton>
        <StyledPopover
          open={isPopoverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <FlexBox gap={4}>
            <FlexBox gap={1}>
              <TitleText variant="h5"> PowerScore Breakdown</TitleText>
              {isOverallPowerscore ? (
                <DescriptionText variant="body2">
                  <IntercomLink
                    variant="body2"
                    onClick={() => handleIntercomClick(POWERSCORE_ARTICLE_ID)}
                  >
                    Overall PowerScore
                  </IntercomLink>{' '}
                  represents an {entityType}’s likelihood to support all of your
                  products relative to other {entityType}s in your territory.
                </DescriptionText>
              ) : (
                <>
                  <DescriptionText variant="body2">
                    PowerScore signifies an {entityType}’s propensity to help
                    you achieve your business objective.
                  </DescriptionText>
                  <DescriptionText variant="body2">
                    The PowerScore takes into account information like
                    historical Rx as well as data points specific to MAPTUAL’s
                    algorithms, like Segment memberships and predicted
                    behaviors.
                  </DescriptionText>
                </>
              )}
            </FlexBox>
            <PowerScoreFeedback>
              <AcknowledgementText>
                Do you understand why this {entityType} has this PowerScore?
              </AcknowledgementText>
              <StyledToggleButtonGroup
                value={selectionState}
                exclusive
                onChange={handleFeedbackButtonClick}
              >
                <StyledToggleButton
                  value={SELECTION_STATES.YES}
                  variant="contained"
                >
                  Yes
                </StyledToggleButton>
                <StyledToggleButton
                  value={SELECTION_STATES.NO}
                  variant="contained"
                >
                  No
                </StyledToggleButton>
                <StyledToggleButton
                  value={SELECTION_STATES.NOT_SURE}
                  variant="contained"
                >
                  I'm Not Sure
                </StyledToggleButton>
              </StyledToggleButtonGroup>
            </PowerScoreFeedback>
          </FlexBox>
        </StyledPopover>
        <SnackbarDisplay
          key={
            messageInfo
              ? `powerscore-feedback-${entityId}-${messageInfo.key}`
              : undefined
          }
          isOpen={isSnackBarOpen}
          handleClose={() => setIsSnackBarOpen(false)}
          handleUndo={() =>
            handleFeedbackButtonClick(null, SELECTION_STATES.NONE)
          }
          handleExited={() => {
            setMessageInfo(undefined);
          }}
          message={
            <Box display="flex" flexDirection="column" gap={0.5}>
              <StyledSnackbarTitle variant="title3">
                Thank you for submitting your feedback.
              </StyledSnackbarTitle>
              <StyledSnackbarBody variant="subtitle1">
                This data will help us improve our PowerScore explainability.
              </StyledSnackbarBody>
            </Box>
          }
        />
      </>
    );
  }

  return (
    <>
      <StyledButton onClick={handlePowerScoreClick} isActive={isPopoverOpen}>
        <PowerScore score={powerscore} objectiveScoreList={objectiveScores} />
        <ExpandMoreRoundedIcon data-testid="powerscore-arrow-drop-down-button" />
      </StyledButton>
      <StyledPopover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {isContributionDataLoading && (
          <FlexBox gap={3}>
            <FlexBox gap={2}>
              <PowerscoreTitle name={entityName} />
              <SkeletonBar animation="wave" height={68} />
              <SkeletonBar animation="wave" height={42} />
              <SkeletonBar animation="wave" height={96} />
            </FlexBox>
            <SkeletonBar animation="wave" height={70} />
          </FlexBox>
        )}
        {!isContributionDataLoading && isContributionDataError && (
          <FlexBox gap={1} height={285}>
            <PowerscoreTitle name={entityName} />
            <ErrorContainer
              title="PowerScore breakdown unavailable"
              style={{
                height: '100%',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            />
          </FlexBox>
        )}
        {!isContributionDataLoading &&
          !isContributionDataError &&
          contributionData && (
            <FlexBox gap={4}>
              <FlexBox gap={2}>
                <PowerscoreTitle name={entityName} />
                <EntityPowerscoreExplainability
                  powerscore={powerscore}
                  contributionBreakdown={contributionData}
                  entityType={entityType}
                />
              </FlexBox>
              <PowerScoreFeedback>
                <AcknowledgementText>
                  Do you understand why this {entityType} has this PowerScore?
                </AcknowledgementText>
                <StyledToggleButtonGroup
                  value={selectionState}
                  exclusive
                  onChange={handleFeedbackButtonClick}
                >
                  <StyledToggleButton
                    value={SELECTION_STATES.YES}
                    variant="contained"
                  >
                    Yes
                  </StyledToggleButton>
                  <StyledToggleButton
                    value={SELECTION_STATES.NO}
                    variant="contained"
                  >
                    No
                  </StyledToggleButton>
                  <StyledToggleButton
                    value={SELECTION_STATES.NOT_SURE}
                    variant="contained"
                  >
                    I'm Not Sure
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
              </PowerScoreFeedback>
            </FlexBox>
          )}
      </StyledPopover>
      <SnackbarDisplay
        key={
          messageInfo
            ? `powerscore-feedback-${entityId}-${messageInfo.key}`
            : undefined
        }
        isOpen={isSnackBarOpen}
        handleClose={() => setIsSnackBarOpen(false)}
        handleUndo={() =>
          handleFeedbackButtonClick(null, SELECTION_STATES.NONE)
        }
        handleExited={() => {
          setMessageInfo(undefined);
        }}
        message={
          <Box display="flex" flexDirection="column" gap={0.5}>
            <StyledSnackbarTitle variant="title3">
              Thank you for submitting your feedback.
            </StyledSnackbarTitle>
            <StyledSnackbarBody variant="subtitle1">
              This data will help us improve our PowerScore explainability.
            </StyledSnackbarBody>
          </Box>
        }
      />
    </>
  );
};

interface PowerScorePopoverProps {
  powerscore: number;
  objectiveScores?: number[];
  locality: string;
  regionId: string;
  objectiveId?: string;
  entityType: EntityType;
  entityId: string;
  entityName: string;
  shouldPreloadData: boolean;
  isAmaOptOut: boolean;
  isNonDld: boolean;
}

const PowerscoreTitle = ({ name }: { name: string }) => (
  <FlexBox gap={2} dir="column">
    <TitleText variant="h6"> PowerScore Breakdown</TitleText>
    <EntityName variant="h5">{name}</EntityName>
  </FlexBox>
);
const SELECTION_STATES = {
  YES: 'YES',
  NO: 'NO',
  NOT_SURE: 'NOT SURE',
  NONE: 'NONE',
};

const POWERSCORE_ARTICLE_ID = 6576953;

const StyledButton = styled(Button)(({ theme: { themeColors }, isActive }) => ({
  '& .MuiSvgIcon-root': {
    color: themeColors.utilityActiveContentColor,
  },
  backgroundColor: isActive
    ? themeColors.utilityActiveSurfaceColor
    : 'transparent',
  gap: 4,
  padding: 8,
  height: '100%',
}));

const StyledPopover = styled(Popover)(({ theme: { themeColors } }) => ({
  marginTop: 3,
  '& .MuiPopover-paper': {
    width: 430,
    padding: 24,
    backgroundColor: themeColors.popoverBackground,
    borderColor: themeColors.borderPrimaryColor,
  },
}));

const FlexBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const PowerScoreFeedback = styled(FlexBox)(({ theme: { themeColors } }) => ({
  gap: 16,
  padding: 16,
  border: `1px solid ${themeColors.borderPrimaryColor}`,
  borderRadius: 4,
}));

const TitleText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
}));

const EntityName = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
}));

const DescriptionText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tertiaryColor,
}));

const IntercomLink = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tertiaryColor,
  display: 'inline',
  textDecoration: 'underline',
  cursor: 'pointer',
}));

const AcknowledgementText = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.tertiaryColor,
    fontSize: 14,
  })
);

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  display: 'flex',
  gap: 8,
}));

const StyledToggleButton = styled(ToggleButton)(
  ({ theme: { themeColors } }) => ({
    fontSize: 14,
    height: 32,
    '&:first-of-type, :not(:first-child):not(:last-child), &:last-of-type': {
      borderRadius: 4,
      border: `1px solid ${themeColors.buttonBorderColor}`,
    },
  })
);

const StyledSnackbarTitle = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    textAlign: 'left',
    color: themeColors.alertPrimaryInfoTitleColor,
  })
);

const StyledSnackbarBody = styled(Typography)(({ theme: { themeColors } }) => ({
  textAlign: 'left',
  color: themeColors.alertPrimaryInfoDescriptionColor,
}));

const handleIntercomClick = (articleId) => {
  window.Intercom('showArticle', articleId);
};
