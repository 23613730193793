import { Stack, TextField } from '@mui/material';
import { ProductTreeSectionBody, TwoPaneLayout } from '../styledComponents';
import { FormAutocomplete } from '../../components/form/FormAutocomplete';
import { useCreateProductTree } from './useCreateProductTree';
import { NewProductTreePlaceholder } from './newProductTreePlaceholder';
import { ModalSectionHeader } from '../../../generic/modalSectionHeader';
import { FormActions } from './formActions';
import { FormTextField } from '../../components/form/FormTextField';
import { FormWrapper } from './styledComponents';

export const ProductTreeCreateForm = ({
  products,
  markets,
  sourceProductLineForClone,
}) => {
  const {
    onSubmit,
    marketOptions,
    control,
    formState,
    setValue,
    isLoading,
    onCancel,
  } = useCreateProductTree({ markets, sourceProductLineForClone });

  return (
    <ProductTreeSectionBody>
      <TwoPaneLayout>
        <NewProductTreePlaceholder />
        <FormWrapper>
          <form onSubmit={onSubmit}>
            <Stack spacing={2}>
              <Stack spacing={2}>
                <ModalSectionHeader
                  title="Tree information"
                  subtitle="You can add this tree to a customer market later."
                />
                <Stack direction="row" spacing={2}>
                  <FormAutocomplete
                    name="productLine"
                    control={control}
                    rules={{ required: true }}
                    autoCompleteProps={{
                      fullWidth: true,
                      options: products,
                      freeSolo: true,
                    }}
                    textFieldProps={{
                      label: 'Product Line',
                      placeholder: 'Add product line',
                      required: true,
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Product Line ID"
                    placeholder="Save node to generate"
                    disabled
                  />
                </Stack>
                <FormAutocomplete
                  name="customerMarketId"
                  control={control}
                  autoCompleteProps={{
                    fullWidth: true,
                    options: marketOptions,
                  }}
                  textFieldProps={{
                    label: 'Attach to customer market',
                    placeholder: 'Select customer market',
                    helperText:
                      'You can add this tree to a customer market later',
                  }}
                />
              </Stack>
              <Stack spacing={2}>
                <ModalSectionHeader
                  title="Parent Node Information"
                  subtitle="Add defining information for the parent node of this tree."
                />
                <FormAutocomplete
                  name="productName"
                  control={control}
                  rules={{ required: true }}
                  autoCompleteProps={{
                    freeSolo: true,
                    fullWidth: true,
                    options: products,
                  }}
                  onChange={(
                    event,
                    newValue,
                    { field, formState: currentFormState }
                  ) => {
                    field.onChange(newValue);
                    setValue('friendlyName', newValue ?? '', {
                      shouldValidate:
                        currentFormState.touchedFields.friendlyName,
                    });
                  }}
                  textFieldProps={{
                    label: 'Product Name',
                    placeholder: 'Add Product Name',
                    required: true,
                  }}
                />
                <FormTextField
                  name="friendlyName"
                  control={control}
                  rules={{ required: true }}
                  label="Friendly name"
                  placeholder="Add Friendly Name"
                  fullWidth
                  required
                />
                <FormTextField
                  name="level"
                  control={control}
                  rules={{ required: true }}
                  label="Level"
                  placeholder="Add level"
                  fullWidth
                  required
                />
              </Stack>
            </Stack>
            <FormActions
              onCancel={onCancel}
              disableSubmit={!formState.isValid}
              isLoading={isLoading}
            />
          </form>
        </FormWrapper>
      </TwoPaneLayout>
    </ProductTreeSectionBody>
  );
};
