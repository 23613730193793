import { createContext, useContext } from "react";

type CallPlanContextType = {
  latestCurationTimestamp: string | null | undefined;
  productLineId: string;
  projectTargetProducts: unknown[];
  isTimestampLoading: boolean;
  isProductsLoading: boolean;
  projectId: string;
  regionId: string;
  profile?: unknown;
  sfRegionId?: string;
  rows?: any;
  showCurationData?: boolean;
  isSuggestedEntities?: boolean;
};

export const CallPlanContext = createContext<CallPlanContextType | undefined>(
  undefined
);

export const useCallPlanContext = () => {
  const context = useContext(CallPlanContext);
  if (!context) {
    throw new Error(
      "useCallPlanContext must be used within a CallPlanContextProvider"
    );
  }
  return context;
};
