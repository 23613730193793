export enum METRIC_UNIT {
  PERCENT = 'percent',
  PERCENT_NOT_MULTIPLIED = 'percentNotMultiplied',
  NUMBER = 'number',
  UNIT = 'unit',
  DOLLAR = 'dollar',
  DOLLARS = 'dollars',
  RATE = 'rate',
  RX = 'rx',
  PATIENT_COUNT = 'patient_count',
}

export const formatMetricValue = (
  value: number,
  unit: string | null
): number | string => {
  const dollarFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  switch (unit) {
    case METRIC_UNIT.PERCENT:
    case METRIC_UNIT.RATE:
      return `${Math.round(value * 100)}%`;
    case METRIC_UNIT.DOLLAR:
    case METRIC_UNIT.DOLLARS:
      return dollarFormat.format(Math.round(value));
    default:
      return Math.round(value);
  }
};

export const commaSeparatedStringList = (stringList: string[]): string => {
  if (stringList.length === 0) {
    return '';
  }

  if (stringList.length === 1) {
    return stringList[0];
  }

  return `${stringList.slice(0, -1).join(', ')} or ${stringList.at(-1)}`;
};
