import { Drawer, Box, styled } from '@mui/material';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import { useContext, useEffect } from 'react';
import { EventLogger } from './eventLogger';
import { APPVIEWS } from '../../constants';
import { AppContext } from '../../containers/application/appContext';
import { getDemoAccountStatus } from '../../request/config';
import { CustomIconButton } from '../app/navBarCommon';

const EventBadge = styled(Box)((props) => ({
  borderRadius: '100%',
  background: props.theme.themeColors.maptualListFilteringButtonBackgroundColor,
  position: 'absolute',
  width: 18,
  height: 18,
  color: 'white',
  fontSize: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: props.isField ? 0 : -8,
  bottom: props.isField ? 0 : -8,
  textAlign: 'center',
}));

const EventDrawer = styled(Drawer)(({ theme: { themeColors } }) => ({
  backgroudColor: themeColors.contentCardBorderColor,
  textAlign: 'left',
}));
export const EventLoggerNavButton = ({ isField }) => {
  const { profile, events, settings, setSettings } = useContext(AppContext);
  const isDemoAccount = getDemoAccountStatus();
  const isNotAdminAccount = profile?.role?.type !== APPVIEWS.ADMIN;
  if (isNotAdminAccount || isDemoAccount) {
    return null;
  }

  const setOpen = (val) => {
    setSettings({ ...settings, showEvents: val });
  };

  useEffect(() => () => setSettings({ ...settings, showEvents: false }), []);

  return (
    <Box>
      <CustomIconButton
        data-testid="event-button"
        onClick={() => {
          setOpen(!settings.showEvents);
        }}
        isField={isField}
      >
        <IntegrationInstructionsOutlinedIcon />
        {events.length > 0 ? (
          <EventBadge data-testid="event-badge" isField={isField}>
            {events.length}
          </EventBadge>
        ) : null}
      </CustomIconButton>
      <EventDrawer
        anchor="right"
        variant="persistent"
        open={settings.showEvents}
        onClose={() => {
          setOpen(false);
        }}
        PaperProps={{ style: { zIndex: 1300 } }}
      >
        <EventLogger />
      </EventDrawer>
    </Box>
  );
};
