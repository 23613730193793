import { useContext, useEffect, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';

import { EntityDrawerError } from '../EntityDrawerError';
import {
  CADENCES_ENUM,
  ERROR_TYPES_ENUM,
  SHORT_FORM_CADENCE_ENUM,
  TREND_STATUSES_ENUM,
  UNITS_ENUM,
} from '../../../constants';
import { getCadences, getUnits } from '../drawerHelpers';
import {
  GraphControls,
  GraphSection,
  GraphTitle,
} from './sharedStyledComponents';
import { useEntityDrawerChartData } from '../data/useEntityDrawerData';
import { EntityDrawerContext } from '../data/entityDrawerContext';
import { CHART_TYPES_ENUM } from '../../../../shared/graphs/constants';
import { DrawerChartSkeleton } from '../../skeletons/DrawerChartSkeleton';
import { TabGroup } from '../EntityDrawerChartTabs';
import { TooltipPopover } from '../../../../shared/TooltipPopover';
import { EntityDrawerLineGraph } from './EntityDrawerLineGraph';

const PredictionDisclaimer = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.tertiaryColor,
    fontStyle: 'italic',
  })
);
export const EntityDrawerLineGraphCard = ({
  sectionTitle,
  metrics,
  objectiveId,
}) => {
  const { entityType, entityId, regionId, projectId, productLineId } =
    useContext(EntityDrawerContext);

  const [availableCadences, setAvailableCadences] = useState([]);
  const [availableUnits, setAvailableUnits] = useState([]);
  const [cadence, setCadence] = useState();
  const [unit, setUnit] = useState();
  const [selectedMetric, setSelectedMetric] = useState();
  const [predictionTrendStatusForMAT, setPredictionTrendStatusForMAT] =
    useState();

  useEffect(() => {
    const cadences = getCadences(metrics);
    const units = getUnits(metrics);

    setAvailableCadences(cadences);
    setAvailableUnits(units);
  }, [metrics]);

  useEffect(() => {
    if (availableCadences && availableCadences.length > 0) {
      const defaultCadence = availableCadences[0];
      const selectedCadenceNotAvailable = !availableCadences.find(
        (c) => c.id === cadence
      );

      if (!cadence || selectedCadenceNotAvailable) {
        setCadence(defaultCadence?.id);
      }
    }
  }, [availableCadences]);

  useEffect(() => {
    if (availableUnits && availableUnits.length > 0) {
      const defaultUnit = availableUnits[0];
      const selectedUnitNotAvailable = !availableUnits.find(
        (u) => u.id === unit
      );

      if (!unit || selectedUnitNotAvailable) {
        setUnit(defaultUnit.id);
      }
    }
  }, [availableUnits]);

  useEffect(() => {
    if (unit && cadence) {
      const metricObject = metrics.find(
        (metric) => metric.unit === unit && metric.cadences.includes(cadence)
      );

      setSelectedMetric(metricObject);
    } else if (unit) {
      const metricObject = metrics.find((metric) => metric.unit === unit);

      setSelectedMetric(metricObject);
    }
  }, [cadence, unit]);

  const { data, isFetching, isError } = useEntityDrawerChartData({
    productLineId,
    projectId,
    regionId,
    objectiveId,
    chartType: CHART_TYPES_ENUM.LINE,
    metric: selectedMetric?.rxType,
    cadence,
    entityType,
    entityId,
  });

  const [graphTitle, setGraphTitle] = useState();
  useEffect(() => {
    if (data && cadence) {
      setGraphTitle(`${data?.title}, ${CADENCES_ENUM[cadence]}`);

      if (cadence === SHORT_FORM_CADENCE_ENUM.MAT) {
        setPredictionTrendStatusForMAT(data.recentYear?.at(-1)?.trend?.status);
      }
    }
  }, [data, cadence]);

  return (
    <GraphSection data-testid="entity-drawer-line-graph-section">
      <Typography variant="h4">{sectionTitle}</Typography>
      <GraphControls>
        <TabGroup
          availableTabs={availableUnits}
          tab={unit}
          setTab={setUnit}
          sectionTitle={sectionTitle}
          graphType={CHART_TYPES_ENUM.LINE}
        />
        <TabGroup
          availableTabs={availableCadences}
          tab={cadence}
          setTab={setCadence}
          sectionTitle={sectionTitle}
          graphType={CHART_TYPES_ENUM.LINE}
        />
      </GraphControls>
      {isFetching && <DrawerChartSkeleton />}
      {isError && (
        <EntityDrawerError
          errorType={ERROR_TYPES_ENUM.RETRY}
          retryType="chart"
        />
      )}

      {!!data && (
        <>
          <GraphTitle>
            <Typography variant="h6">{graphTitle}</Typography>
            <TooltipPopover
              title={data?.title}
              description={selectedMetric?.tooltipText}
            />
          </GraphTitle>

          <Box height="240px" position="relative">
            <EntityDrawerLineGraph
              unit={UNITS_ENUM[unit]}
              rawData={data}
              cadence={cadence}
            />
          </Box>
          {cadence === SHORT_FORM_CADENCE_ENUM.MAT &&
            predictionTrendStatusForMAT ===
              TREND_STATUSES_ENUM.CANNOT_PREDICT && (
              <PredictionDisclaimer variant="caption">
                Not enough data to generate a prediction.
              </PredictionDisclaimer>
            )}
        </>
      )}
    </GraphSection>
  );
};
