import { styled, Box } from '@mui/material';
import { useEntityDrawerContext } from './data/useEntityDrawerContext';
import { EntityDrawerContext } from './data/entityDrawerContext';
import { ENTITY_TYPES_ENUM } from '../../constants';
import { EntityDrawerContent } from './EntityDrawerContent';

const DrawerContainer = styled(Box)(({ theme: { themeColors, spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 800,
  alignItems: 'flex-start',
  paddingBottom: spacing(2),
  overflowX: 'hidden',
  colorScheme: themeColors.colorScheme,
}));

// To be pulled from Config when possible
const tabsMap = {
  [ENTITY_TYPES_ENUM.DIRECT_ACCOUNT]: ['Data', 'Profile'],
  [ENTITY_TYPES_ENUM.OUTLET]: ['Data', 'Timeline'],
};

export const EntityOverviewDrawer = ({ drawerData, setDrawerVisibility }) => {
  if (!drawerData) return null;

  const { name, address, id: entityId, entityType } = drawerData;
  const entityDrawerContext = useEntityDrawerContext(entityType, entityId);

  return (
    <DrawerContainer data-testid="entity-overview-drawer">
      <EntityDrawerContext.Provider value={entityDrawerContext}>
        <EntityDrawerContent
          tabs={tabsMap[entityType]}
          name={name}
          address={address}
          setDrawerVisibility={setDrawerVisibility}
        />
      </EntityDrawerContext.Provider>
    </DrawerContainer>
  );
};
