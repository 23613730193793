import { useContext } from 'react';
import {
  Box,
  Skeleton,
  Tab,
  Tabs,
  styled,
  MenuItem,
  Select,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ProductPerformanceMetadataContext } from './data/productPerformanceMetadataContext';
import { ErrorContainer } from '../../../generic/errorContainer';
import { CADENCES_ORDER, METRIC_DATA_TYPE, METRIC_UNIT } from '../constants';
import { hasMetricDataType } from '../helpers';
import { useProductPerformanceMixpanelTracker } from './useProductPerformanceMixpanelTracker';
import { toCapitalize } from '../../../../utils/toCapitalize';
import { Cadence, Metric } from '../types';

const SkeletonBar = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  backgroundColor: themeColors.surfaceEmpty,
}));

const StyledTabs = styled(Tabs)(({ theme: { themeColors, spacing } }) => ({
  padding: spacing(0, 1.5),
  borderBottom: `1px solid ${themeColors.dividerPrimaryColor}`,
  minHeight: 30,
  margin: spacing(0, -2.5, 0, -2.5),
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: 24,
    backgroundColor: themeColors.tabActiveIndicator,
  },
  '.MuiTab-root': {
    paddingTop: 0,
    minHeight: 30,
    padding: '0 12px 11px 12px',
    minWidth: 'fit-content',
    textTransform: 'capitalize',
    fontSize: 16,
    color: themeColors.tabDefaultContent,
    '&.Mui-selected': {
      color: themeColors.tabActiveContent,
    },
  },
}));

const Controls = styled(Box)(() => ({
  display: 'flex',
  gap: 16,
  flexWrap: 'wrap',
}));

const MetricSelect = styled(Select)(({ theme: { themeColors } }) => ({
  minWidth: 237,
  maxWidth: 340,
  backgroundColor: themeColors.buttonBackgroundColor,
  color: themeColors.menuTextColor,
  textAlign: 'left',
  '.MuiSelect-select': {
    padding: '8px 12px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: themeColors.buttonBorderColor,
  },
  svg: {
    color: themeColors.buttonContentColor,
  },
}));

const CardTabs = () => {
  const { metadata, selectedCardTab, setSelectedCardTab } = useContext(
    ProductPerformanceMetadataContext
  );

  const { trackGraphTabSelection } = useProductPerformanceMixpanelTracker();

  const handleTabChange = (value: METRIC_DATA_TYPE) => {
    trackGraphTabSelection(toCapitalize(value));
    setSelectedCardTab(value);
  };
  return (
    <StyledTabs
      value={selectedCardTab}
      onChange={(_, value) => handleTabChange(value)}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    >
      <Tab
        label="Volume"
        value={METRIC_DATA_TYPE.VOLUME}
        disabled={
          !hasMetricDataType(metadata?.metricDataTypes, METRIC_DATA_TYPE.VOLUME)
        }
        data-testid="product-performance-volume-tab"
      />
      <Tab
        label="Share"
        value={METRIC_DATA_TYPE.SHARE}
        disabled={
          !hasMetricDataType(metadata?.metricDataTypes, METRIC_DATA_TYPE.SHARE)
        }
        data-testid="product-performance-share-tab"
      />
    </StyledTabs>
  );
};

const MetricTabs = () => {
  const {
    currentTabMetadata,
    selectedMetric,
    setSelectedMetric,
    selectedCadence,
    setSelectedUnit,
  } = useContext(ProductPerformanceMetadataContext);

  const { trackGraphVolumeMetric } = useProductPerformanceMixpanelTracker();

  const handleMetricChange = (metric: string, unit: METRIC_UNIT) => {
    trackGraphVolumeMetric(metric, selectedCadence?.id);
    setSelectedMetric(metric);
    setSelectedUnit(unit);
  };

  if (!currentTabMetadata || currentTabMetadata.metrics.length <= 1)
    return null;

  if (currentTabMetadata.metrics.length <= 5) {
    return (
      <Tabs variant="filled" value={selectedMetric}>
        {currentTabMetadata.metrics.map((metric: Metric) => (
          <Tab
            key={`${metric.rxType}-metric-key`}
            label={metric.displayName}
            value={metric.rxType}
            onClick={() => {
              handleMetricChange(metric.rxType, metric.unit);
            }}
          />
        ))}
      </Tabs>
    );
  }

  return (
    <MetricSelect
      value={selectedMetric}
      IconComponent={KeyboardArrowDownIcon}
      data-testid="metrics-dropdown"
    >
      {currentTabMetadata.metrics.map((metric) => (
        <MenuItem
          key={`${metric.rxType}-metric-key`}
          value={metric.rxType}
          onClick={() => handleMetricChange(metric.rxType, metric.unit)}
        >
          {metric.displayName}
        </MenuItem>
      ))}
    </MetricSelect>
  );
};

export const getAllCadences = (metrics: Metric[]): Cadence[] => {
  const uniqueCadencesMap = new Map();
  metrics.forEach((metric: Metric) => {
    metric.cadences.forEach((cadence: Cadence) => {
      uniqueCadencesMap.set(cadence.id, cadence);
    });
  });

  const uniqueCadences = [...uniqueCadencesMap.values()];
  uniqueCadences.sort(
    (a: Cadence, b: Cadence) => CADENCES_ORDER[a.id] - CADENCES_ORDER[b.id]
  );

  return uniqueCadences;
};

const CadenceTabs = () => {
  const {
    currentTabMetadata,
    selectedCardTab,
    selectedCadence,
    setSelectedCadence,
    selectedMetric,
  } = useContext(ProductPerformanceMetadataContext);

  if (!currentTabMetadata?.metrics) return null;
  const allCadences = getAllCadences(currentTabMetadata.metrics);

  const isCadenceDisabled = (cadence: Cadence): boolean =>
    !currentTabMetadata.metrics.find(
      (metric) =>
        metric.rxType === selectedMetric &&
        metric.cadences.some((c) => c.id === cadence.id)
    );

  const { trackGraphCardTimescale } = useProductPerformanceMixpanelTracker();

  const handleCadenceChange = (cadence: Cadence) => {
    trackGraphCardTimescale(
      toCapitalize(selectedCardTab),
      selectedMetric,
      cadence.id
    );
    setSelectedCadence(cadence);
  };

  return (
    <Tabs
      variant="filled"
      value={selectedCadence?.id}
      data-testid="product-performance-cadence-tabs"
    >
      {allCadences.map((cadence) => (
        <Tab
          key={`${cadence.id}-cadence-key`}
          label={cadence.label}
          value={cadence.id}
          onClick={() => {
            handleCadenceChange(cadence);
          }}
          disabled={isCadenceDisabled(cadence)}
        />
      ))}
    </Tabs>
  );
};

export const ProductPerformanceHeader = () => {
  const {
    isMetadataLoading,
    metadata,
    refetchMetadata,
    isMetadataError,
    isMetadataIdle,
  } = useContext(ProductPerformanceMetadataContext);

  if (isMetadataIdle || isMetadataLoading) {
    return (
      <>
        <SkeletonBar animation="wave" height={30} />
        <SkeletonBar animation="wave" height={40} />
      </>
    );
  }

  if (isMetadataError || !metadata) {
    return <ErrorContainer handleRetry={refetchMetadata} />;
  }

  return (
    <>
      <CardTabs />

      <Controls>
        <MetricTabs />
        <CadenceTabs />
      </Controls>
    </>
  );
};
