import { useParams } from 'react-router-dom';
import { styled } from '@mui/material';
import { CadenceSelector } from '../../salesReps/repTable/table/controls/cadenceSelector';
import { DateSelector } from './dateSelector';
import { ObjectiveSelector } from '../../../../shared/objectiveSelector/objectiveSelector';

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 16,
  height: 40,
  marginBottom: 32,
}));

export const Controls = ({
  timestamp,
  cadence,
  updateCadence,
  updateTimestamp,
  objectiveId,
  updateFilterObjective,
}) => {
  const { projectId } = useParams();
  return (
    <Container>
      <ObjectiveSelector
        projectId={projectId}
        objectiveId={objectiveId}
        updateFilterObjective={updateFilterObjective}
        minWidth="275px"
      />
      <CadenceSelector
        cadence={cadence}
        updateFilterCadence={(newCadence) => {
          updateCadence(newCadence);
        }}
      />
      <DateSelector
        timestamp={timestamp}
        cadence={cadence}
        updateTimestamp={updateTimestamp}
      />
    </Container>
  );
};
