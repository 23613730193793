import { styled } from '@mui/material';
import { useTheme } from '@mui/styles';

const StyledSVG = styled('svg')`
  width: ${(props) => (props.collapsed ? 20 : 10)}px;
  height: ${(props) => (props.collapsed ? 20 : 15)}px;
  padding: 0;
  margin: 0;
`;

export const TrendIcon = ({
  direction = 'up',
  collapsed = false,
  testId = '',
}) => {
  const { themeColors } = useTheme();
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      collapsed={collapsed}
      viewBox="0 0 10 15"
      fill="none"
      data-testid={testId}
    >
      {direction === 'up' ? (
        <UpIcon fillColor={themeColors.increaseColorMainDefaultContent} />
      ) : (
        <DownIcon fillColor={themeColors.decreaseColorMainDefaultContent} />
      )}
    </StyledSVG>
  );
};

const DownIcon = ({ fillColor }) => (
  <>
    <rect width="10" height="15" />
    <path d="M2 6.25L5.125 9.375L8.25 6.25H2Z" fill={fillColor} />
  </>
);
const UpIcon = ({ fillColor }) => (
  <>
    <rect width="10" height="15" />
    <path d="M2 8.75L5.125 5.625L8.25 8.75H2Z" fill={fillColor} />
  </>
);
