import makeStyles from '@mui/styles/makeStyles';
import DataLabelAtom from './dataLabelAtom';

const maxWidth = '12rem';

const useStyles = makeStyles(() => ({
  root: {
    width: 'auto',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    margin: '0.5vw',
  },
}));

export default function DataLabel({
  primaryText = null,
  secondaryText = null,
  tertiaryText = null,
  isColumn = true,
  highContrast = false,
  match,
}) {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={isColumn ? {} : { maxWidth, marginRight: '1rem' }}
    >
      {primaryText ? (
        <DataLabelAtom
          text={primaryText}
          type="primaryText"
          match={match}
          isColumn={isColumn}
          customStyle={isColumn ? {} : { maxWidth }}
          highContrast={highContrast}
        />
      ) : null}
      {secondaryText ? (
        <DataLabelAtom
          text={secondaryText}
          type="secondaryText"
          match={match}
          isColumn={isColumn}
          customStyle={isColumn ? {} : { maxWidth }}
          highContrast={highContrast}
        />
      ) : null}
      {tertiaryText ? (
        <DataLabelAtom
          text={tertiaryText}
          type="tertiaryText"
          match={match}
          isColumn={isColumn}
          customStyle={isColumn ? {} : { maxWidth }}
          highContrast={highContrast}
        />
      ) : null}
    </div>
  );
}
