import { Typography, styled, FormControlLabel, Checkbox } from "@mui/material";
import { AutocompleteCombobox } from "@odaia/ui/src/components/autocompleteCombobox";

const CheckboxContainer = styled("div")(() => ({
  display: "flex",
  gap: 9,
  alignItems: "flex-start",
  flexWrap: "wrap",

  "& .MuiFormControlLabel-label": {
    paddingTop: 1.5,
  },
}));

const SectionTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryMaptualListFilteringColor,
}));

const SegmentFilterDescription = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.primaryMaptualListFilteringColor,
    marginBottom: "32px",
    marginTop: "4px",
  })
);

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme: { themeColors } }) => ({
    marginLeft: 0,
    color: themeColors.segmentSelectionCaptionColor,
    ".MuiCheckbox-root": {
      padding: 0,
      marginRight: 9,
      alignSelf: "start",
    },
  })
);

const StyledCheckbox = styled(Checkbox)(({ theme: { themeColors } }) => ({
  "&.Mui-checked": {
    color: themeColors.checkboxSelected,
  },
}));

const COMBOBOX_CUTOFF: number = 12;

export interface filterOption {
  id: string;
  label: string;
}

interface MultiSelectFilteringProps {
  title: string;
  description: string;
  filterType: string;
  filterOptions: filterOption[];
  selectedFilters: filterOption[];
  updateFilters: (arg0: string, arg1: string, arg2: boolean) => void;
}

export const MultiSelectFiltering = ({
  title,
  description,
  filterType,
  filterOptions,
  selectedFilters,
  updateFilters,
}: MultiSelectFilteringProps) => {
  return (
    filterOptions.length > 0 && (
      <>
        <SectionTitle variant="h4">{title}</SectionTitle>
        <SegmentFilterDescription variant="body2">
          {description}
        </SegmentFilterDescription>
        {filterOptions?.length > COMBOBOX_CUTOFF && (
          <AutocompleteCombobox
            options={filterOptions}
            selection={selectedFilters || {}}
            onOptionChange={(item, checked) => {
              updateFilters(item.id, item.label, checked);
            }}
            type={filterType}
          />
        )}
        {filterOptions?.length <= COMBOBOX_CUTOFF && (
          <CheckboxContainer>
            {filterOptions.map((item) => (
              <StyledFormControlLabel
                key={item.id}
                control={
                  <StyledCheckbox
                    name={item.id}
                    onChange={(event) =>
                      updateFilters(item.id, item.label, event.target.checked)
                    }
                    checked={!!selectedFilters?.[item.id]}
                  />
                }
                label={item.label}
              />
            ))}
          </CheckboxContainer>
        )}
      </>
    )
  );
};
