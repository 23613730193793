import { Box, Typography, Tooltip, styled, Popper } from "@mui/material";

const StyledTooltipPopper = styled(Popper)(({ theme: { themeColors } }) => ({
  ".MuiTooltip-arrow::before": {
    backgroundColor: themeColors.tooltipSurfaceColor,
  },
  ".MuiTooltip-tooltip": {
    backgroundColor: themeColors.tooltipSurfaceColor,
    color: themeColors.tooltipContentColor,
    fontSize: 14,
    fontWeight: 400,
  },
  '&.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip': {
    marginTop: 7,
  },
}));

export const GenericTooltip = ({ title, children }) => (
  <Tooltip
    title={title}
    placement="bottom"
    enterTouchDelay={400}
    arrow
    PopperComponent={StyledTooltipPopper}
  >
    {children}
  </Tooltip>
);

const TooltipContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  rowGap: "2px",
  padding: "4px 8px",
}));

const StyledListItem = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "16px",
  padding: "4px 0px",
  textTransform: "capitalize",
}));

export const TooltipContent = (content) => (
  <TooltipContainer>
    {content.map((value) => (
      <StyledListItem key={value.id}>{value.title}</StyledListItem>
    ))}
  </TooltipContainer>
);
