const overallPowerscoreConfig = {
  gskcan5: {
    'b415a935-c00d-4237-b7ca-3aa4d63004fc': false,
    '82f870e8-f735-454f-8c08-a9eb053c56be': false,
    '8792a97e-b22e-4f92-9805-c764f36d9531': false,
  },
};

export function useShowOverallPowerscore(userGroup, marketId) {
  return overallPowerscoreConfig?.[userGroup]?.[marketId] ?? true;
}
