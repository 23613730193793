import { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Checkbox from '@mui/material/Checkbox';
import UniqueIDIcon from '@mui/icons-material/AccountCircle';
import TimeIcon from '@mui/icons-material/Alarm';
import ActivityIcon from '@mui/icons-material/DirectionsRun';
import EncryptionIcon from '@mui/icons-material/Lock';
import { ColTagTypeModel } from '../../models/ingestDataModel';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    'align-items': 'center',
    flex: 1,
    'flex-direction': 'column',
    'justify-content': 'center',
    overflowX: 'auto',
  },
  horizontalContainer: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    'flex-direction': 'Row',
    'justify-content': 'flex-start',
    'align-items': 'center',
    'align-content': 'center',
  },
  labelGroup: {
    'justify-content': 'flex-end',
    'flex-direction': 'column',
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    'text-align': 'left',
    padding: theme.spacing(1),
  },
  selectionGroup: {
    'margin-top': '50px',
    'margin-bottom': '50px',
    display: 'flex',
    'flex-direction': 'row',
    'justify-content': 'center',
  },

  selectionContainer: {
    display: 'flex',
    'flex-direction': 'column',
    'align-items': 'flex-start',
    'margin-right': '20px',
    'margin-left': '20px',
    // 'justify-content' : 'center',
  },
  selectionLabel: {
    fontSize: 24,
  },
  selectionSubLabel: {
    fontSize: 16,
    maxWidth: '250px',
  },
  listContainer: {
    'margin-top': '15px',
    width: '100%',
    height: '40vh',
    overflowX: 'auto',
    overflowY: 'auto',
  },
  iconContainer: {
    marginRight: 10,
  },
}));

export default function IngestionKeyColumns({
  ingestNav,
  ingest,
  myIndex,
  validKeyColumns,
  updateColumnMeta,
  onComplete,
}) {
  const classes = useStyles();
  const [previousUIDField, setPreviousUIDField] = useState(null);
  const [UIDField, setUIDField] = useState(null);

  useEffect(() => {
    if (previousUIDField) {
      setColumnField(previousUIDField, 'colTag', '');
    }
    setColumnField(UIDField, 'colTag', ColTagTypeModel.USER_ID);
  }, [UIDField]);

  // eslint-disable-next-line no-param-reassign
  onComplete.action = () => {
    validKeyColumns(ingest, myIndex);
  };

  const setColumnField = (metaData, fieldName, value) => {
    if (metaData && fieldName) {
      const newMeta = { ...metaData };
      newMeta[fieldName] = value;
      updateColumnMeta(newMeta);
    }
  };

  return (
    <div className={classes.container}>
      <Grow in>
        <div className={classes.labelGroup}>
          <div className={classes.paddingBox} />
          <Typography className={classes.title} variant="h2" component="h3">
            Identify Key Columns
          </Typography>
          <Typography className={classes.subtitle} variant="h5">
            Select a column for: User ID, Activity, and Time Stamp
          </Typography>
        </div>
      </Grow>
      <Fade in timeout={1000}>
        <div className={classes.selectionGroup}>
          <div className={classes.selectionContainer}>
            <div className={classes.horizontalContainer}>
              <UniqueIDIcon className={classes.iconContainer} />
              <Typography
                className={classes.selectionLabel}
                variant="subtitle1"
                color="textPrimary"
              >
                User ID
              </Typography>
            </div>
            <Typography
              className={classes.selectionSubLabel}
              variant="subtitle2"
              align="left"
              color="textSecondary"
            >
              Select a column that identifies unique customers.{' '}
            </Typography>
            <div className={classes.listContainer}>
              <List
                component="nav"
                aria-label="userID"
                dense
                disabled={ingestNav.completedSteps[myIndex] || ingest.isBusy}
              >
                {ingest.columnMeta
                  ? ingest.columnMeta
                      .filter(
                        (item) =>
                          !item.isExcluded &&
                          (item.associatedColumn === null ||
                            (item.associatedColumn &&
                              item.colTag.includes(ColTagTypeModel.USER_ID)))
                      )
                      .map((item, i) => (
                        <ListItem
                          key={i}
                          disabled={item.associatedColumn !== null}
                          button={
                            !(
                              ingestNav.completedSteps[myIndex] || ingest.isBusy
                            )
                          }
                          selected={item.colTag.includes(
                            ColTagTypeModel.USER_ID
                          )}
                          onClick={() => {
                            if (
                              !(
                                ingestNav.completedSteps[myIndex] ||
                                ingest.isBusy
                              )
                            ) {
                              setPreviousUIDField(UIDField);
                              setUIDField(item);
                            }
                          }}
                        >
                          {item.colTag.includes(ColTagTypeModel.USER_ID) ? (
                            <ListItemIcon>
                              <CheckCircleIcon color="secondary" />
                            </ListItemIcon>
                          ) : null}
                          <ListItemText
                            primary={
                              item.associatedColumn
                                ? item.associatedColumn
                                : item.friendlyName
                            }
                          />
                        </ListItem>
                      ))
                  : null}
              </List>
            </div>
          </div>
          <div className={classes.selectionContainer}>
            <div className={classes.horizontalContainer}>
              <ActivityIcon className={classes.iconContainer} />
              <Typography
                className={classes.selectionLabel}
                variant="subtitle1"
                color="textPrimary"
              >
                Activities
              </Typography>
            </div>
            <Typography
              className={classes.selectionSubLabel}
              variant="subtitle2"
              color="textSecondary"
              align="left"
            >
              Select column(s) that identify customer activities.{' '}
            </Typography>
            <div className={classes.listContainer}>
              <List component="nav" aria-label="activities" dense>
                {ingest.columnMeta
                  ? ingest.columnMeta
                      .filter(
                        (item) =>
                          !item.isExcluded &&
                          (item.associatedColumn === null ||
                            (item.associatedColumn &&
                              item.colTag.includes(
                                ColTagTypeModel.ACTIVITY_LABEL_ID
                              )))
                      )
                      .map((item, i) => (
                        <ListItem
                          key={i}
                          disabled={item.associatedColumn !== null}
                          button={
                            !(
                              ingestNav.completedSteps[myIndex] || ingest.isBusy
                            )
                          }
                          selected={item.colTag.includes(
                            ColTagTypeModel.ACTIVITY_LABEL_ID
                          )}
                          onClick={() => {
                            if (
                              !(
                                ingestNav.completedSteps[myIndex] ||
                                ingest.isBusy
                              )
                            ) {
                              let newVal;
                              if (
                                item.colTag.includes(
                                  ColTagTypeModel.ACTIVITY_LABEL_ID
                                )
                              ) {
                                newVal = '';
                              } else {
                                newVal = ColTagTypeModel.ACTIVITY_LABEL_ID;
                              }
                              setColumnField(item, 'colTag', newVal);
                            }
                          }}
                        >
                          {item.colTag.includes(
                            ColTagTypeModel.ACTIVITY_LABEL_ID
                          ) ? (
                            <ListItemIcon>
                              <CheckCircleIcon color="secondary" />
                            </ListItemIcon>
                          ) : null}
                          <ListItemText
                            primary={
                              item.associatedColumn
                                ? item.associatedColumn
                                : item.friendlyName
                            }
                          />
                        </ListItem>
                      ))
                  : null}
              </List>
            </div>
          </div>
          <div className={classes.selectionContainer}>
            <div className={classes.horizontalContainer}>
              <TimeIcon className={classes.iconContainer} />
              <Typography
                className={classes.selectionLabel}
                variant="subtitle1"
                color="textPrimary"
              >
                Time Stamp
              </Typography>
            </div>
            <Typography
              className={classes.selectionSubLabel}
              variant="subtitle2"
              color="textSecondary"
              align="left"
            >
              Select column(s) that identify the activities occurance time.{' '}
            </Typography>
            <div className={classes.listContainer}>
              <List component="nav" aria-label="timeStamp" dense>
                {ingest.columnMeta
                  ? ingest.columnMeta
                      .filter(
                        (item) =>
                          !item.isExcluded &&
                          (item.associatedColumn === null ||
                            (item.associatedColumn &&
                              item.colTag.includes(
                                ColTagTypeModel.ACTIVITY_TIMESTAMP
                              )))
                      )
                      .map((item, i) => (
                        <ListItem
                          key={i}
                          disabled={item.associatedColumn !== null}
                          button={
                            !(
                              ingestNav.completedSteps[myIndex] || ingest.isBusy
                            )
                          }
                          selected={item.colTag.includes(
                            ColTagTypeModel.ACTIVITY_TIMESTAMP
                          )}
                          onClick={() => {
                            if (
                              !(
                                ingestNav.completedSteps[myIndex] ||
                                ingest.isBusy
                              )
                            ) {
                              let newVal;
                              if (
                                item.colTag.includes(
                                  ColTagTypeModel.ACTIVITY_TIMESTAMP
                                )
                              ) {
                                newVal = '';
                              } else {
                                newVal = ColTagTypeModel.ACTIVITY_TIMESTAMP;
                              }
                              setColumnField(item, 'colTag', newVal);
                            }
                          }}
                        >
                          {item.colTag.includes(
                            ColTagTypeModel.ACTIVITY_TIMESTAMP
                          ) ? (
                            <ListItemIcon>
                              <CheckCircleIcon color="secondary" />
                            </ListItemIcon>
                          ) : null}
                          <ListItemText
                            primary={
                              item.associatedColumn
                                ? item.associatedColumn
                                : item.friendlyName
                            }
                          />
                        </ListItem>
                      ))
                  : null}
              </List>
            </div>
          </div>
          <div className={classes.selectionContainer}>
            <div className={classes.horizontalContainer}>
              <EncryptionIcon className={classes.iconContainer} />
              <Typography
                className={classes.selectionLabel}
                variant="subtitle1"
                color="textPrimary"
              >
                Privacy Encryption
              </Typography>
            </div>
            <Typography
              className={classes.selectionSubLabel}
              variant="subtitle2"
              color="textSecondary"
              align="left"
            >
              Select sensitive columns to be encrypted before submitting data.{' '}
            </Typography>
            <div className={classes.listContainer}>
              <List aria-label="encryption" dense>
                {ingest.columnMeta
                  ? ingest.columnMeta
                      .filter(
                        (item) =>
                          !item.isExcluded &&
                          (item.associatedColumn === null ||
                            (item.associatedColumn && item.isEncrypted))
                      )
                      .map((item, i) => (
                        <ListItem
                          key={item.friendlyName}
                          disabled={item.associatedColumn !== null}
                          button={
                            !(
                              ingestNav.completedSteps[myIndex] || ingest.isBusy
                            )
                          }
                          onClick={() => {
                            if (
                              !(
                                ingestNav.completedSteps[myIndex] ||
                                ingest.isBusy
                              )
                            ) {
                              setColumnField(
                                item,
                                'isEncrypted',
                                !item.isEncrypted
                              );
                            }
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={item.isEncrypted}
                              tabIndex={-1}
                              disableRipple
                              color="secondary"
                              disabled={
                                ingestNav.completedSteps[myIndex] ||
                                ingest.isBusy
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={`encrypt_${i}`}
                            primary={
                              item.associatedColumn
                                ? item.associatedColumn
                                : item.friendlyName
                            }
                          />
                        </ListItem>
                      ))
                  : null}
              </List>
            </div>
          </div>
        </div>
      </Fade>
      {ingest.errorMessage ? (
        <Typography className={classes.subtitle} variant="body1" color="error">
          {' '}
          ERROR: {ingest.errorMessage}
        </Typography>
      ) : null}
    </div>
  );
}
