import {
  red,
  blue,
  teal,
  green,
  amber,
  grey,
  lightBlue,
  purple,
} from '@mui/material/colors';
import { TypographyDefinitions } from './typography';
import { designColors } from './designColors';

export type ThemePalette = keyof typeof themeColors

export type ThemeColors = typeof themeColors.darkTheme &
  typeof themeColors.lightTheme;

export const themeColors = {
  darkTheme: {
    mainBackground: designColors.dark.surface.page,
    mainSurfaceBackground: designColors.dark.surface.page,
    primaryTextColor: designColors.dark.text.general.primary,
    secondaryTextColor: designColors.dark.text.general.secondary,
    secondaryTextColorHighContrast: designColors.dark.text.general.secondary,
    primaryColorLight: designColors.dark.inputs.primary.hover.surface,
    primaryColor: designColors.dark.inputs.primary.default.surface,
    primaryColorDark: designColors.dark.inputs.primary.default.surface,
    selectedRowNowColumn: `#284181`,
    secondaryColor: designColors.dark.inputs.secondary.default.surface,
    tertiaryColor: designColors.dark.text.general.tertiary,
    menuTextColor: designColors.dark.inputs.secondary.default.content,
    inputs: designColors.dark.inputs,
    menuTextColorContrasting: 'green',
    inputBackground: designColors.dark.inputs.secondary.default.surface,
    inputBackgroundHover: designColors.dark.inputs.secondary.hover.surface,
    chipBackground: designColors.dark.inputs.secondary.default.surface,
    chipForeground: designColors.dark.inputs.secondary.default.content,
    chipInputDefaultSurface: designColors.dark.chip.input.default.surface,
    chipInputDefaultBorder: designColors.dark.chip.input.default.border,
    chipInputDefaultContent: designColors.dark.chip.input.default.content,
    chipInputDefaultIcon: designColors.dark.chip.input.default.icon,
    chipChoiceDefaultSurface: designColors.dark.chip.choice.default.surface,
    chipChoiceDefaultContent: designColors.dark.chip.choice.default.content,
    chipChoiceDefaultBorder: designColors.dark.chip.choice.default.border,
    chipChoiceActiveSurface: designColors.dark.chip.choice.active.surface,
    chipChoiceActiveContent: designColors.dark.chip.choice.active.content,
    chipChoiceActiveBorder: designColors.dark.chip.choice.active.border,
    chipChoiceHoverSurface: designColors.dark.chip.choice.hover.surface,
    chipChoiceHoverContent: designColors.dark.chip.choice.hover.content,
    chipChoiceHoverBorder: designColors.dark.chip.choice.hover.border,
    increaseColorMain: designColors.dark.data.trend.positive.active.surface,
    decreaseColorMain: designColors.dark.data.trend.negative.active.surface,
    increaseColorMainDefault:
      designColors.dark.data.trend.positive.default.surface,
    decreaseColorMainDefault:
      designColors.dark.data.trend.negative.default.surface,
    increaseColorMainContent:
      designColors.dark.data.trend.positive.active.content,
    decreaseColorMainContent:
      designColors.dark.data.trend.negative.active.content,
    increaseColorMainDefaultContent:
      designColors.dark.data.trend.positive.default.content,
    decreaseColorMainDefaultContent:
      designColors.dark.data.trend.negative.default.content,
    HCPListItemBackground: designColors.dark.HCPListItem.default.surface,
    HCPListItemContent: designColors.dark.HCPListItem.default.contentPrimary,
    HCPListItemContentSecondary:
      designColors.dark.HCPListItem.default.contentSecondary,
    HCPListItemIcon: designColors.dark.HCPListItem.default.icon,
    HCPListItemBackgroundActive: designColors.dark.HCPListItem.active.surface,
    HCPListItemContentActive:
      designColors.dark.HCPListItem.active.contentPrimary,
    HCPListItemContentSecondaryActive:
      designColors.dark.HCPListItem.active.contentSecondary,
    HCPListItemIconActive: designColors.dark.HCPListItem.active.icon,
    HCPListItemBackgroundHover: designColors.dark.HCPListItem.hover.surface,
    HCPListItemContentHover: designColors.dark.HCPListItem.hover.contentPrimary,
    HCPListItemContentSecondaryHover:
      designColors.dark.HCPListItem.hover.contentSecondary,
    HCPListItemIconHover: designColors.dark.HCPListItem.hover.icon,
    decreaseColorMainLight:
      designColors.dark.data.trend.negative.default.content,
    increaseColorMainContrasting:
      designColors.dark.data.trend.positive.active.content,
    decreaseColorMainContrasting:
      designColors.dark.data.trend.negative.active.content,
    increaseColorChip: designColors.dark.data.trend.positive.default.content,
    decreaseColorChip: designColors.dark.data.trend.negative.default.content,
    increaseColorChipContrasting:
      designColors.dark.data.trend.positive.active.surface, // "#094F18",
    decreaseColorChipContrasting:
      designColors.dark.data.trend.negative.active.surface, // "#FFC3C3",
    dropDownBackground: designColors.dark.surface.popover,
    popoverBackground: designColors.dark.surface.popover,
    templateCardBackground: designColors.color.dark[16],
    journeyDrawerBackgroundColor: designColors.dark.surface.page,
    contentCardBackgroundColor: designColors.dark.surface.card,
    contentCardAltBackgroundColor: designColors.dark.surface.page,
    contentCardHighlightColor:
      designColors.dark.inputs.secondary.active.surface,
    contentCardHighlightSelectedColor: '#284181', // todo
    contentCardBorderColor: designColors.dark.border.primary,
    buttonBackgroundColor: designColors.dark.inputs.secondary.default.surface,
    buttonContentColor: designColors.dark.inputs.secondary.default.content,
    buttonBorderColor: designColors.dark.inputs.secondary.default.border,
    buttonHoverBackgroundColor:
      designColors.dark.inputs.secondary.hover.surface,
    buttonHoverContentColor: designColors.dark.inputs.secondary.hover.content,
    buttonHoverBorderColor: designColors.dark.inputs.secondary.hover.border,
    buttonActiveBackgroundColor:
      designColors.dark.inputs.secondary.active.surface,
    buttonActiveContentColor: designColors.dark.inputs.secondary.active.content,
    buttonActiveBorderColor: designColors.dark.inputs.secondary.active.border,
    textButtonColor: designColors.dark.text.infoLink.primary,
    csvDownloadColor: designColors.dark.text.infoLink.primary,
    listIconColor: designColors.dark.inputs.secondary.default.iconSecondary,
    badgePrimaryColor: designColors.dark.badge.default.surface,
    badgePrimaryContentColor: designColors.dark.badge.default.content,
    badgeActiveColor: designColors.dark.badge.active.surface,
    badgeActiveContentColor: designColors.dark.badge.active.content,
    badgeHighlightedColor: designColors.dark.badge.highlighted.surface,
    badgeHighlightedContentColor: designColors.dark.badge.highlighted.content,
    checkboxUnselected: designColors.dark.checkbox.unselected,
    checkboxSelected: designColors.dark.checkbox.selected,
    checkboxCheck: designColors.dark.checkbox.check,
    insightsPositiveBackground:
      designColors.dark.data.trend.positive.default.surface,
    insightsPositiveText: designColors.dark.data.trend.positive.default.content,
    insightsNegativeBackground:
      designColors.dark.data.trend.negative.default.surface,
    insightsNegativeText: designColors.dark.data.trend.negative.default.content,
    anchorIconsGeneralPrimary:
      designColors.dark['anchor-icons'].general.primary,
    anchorIconsGeneralSecondary:
      designColors.dark['anchor-icons'].general.secondary,
    insightsIcon: designColors.dark['anchor-icons']['segment-general'].primary,
    insightsIconBackground:
      designColors.dark['anchor-icons']['segment-general'].secondary,
    insightsSegmentPositiveIconBackground:
      designColors.dark['anchor-icons']['segment-general'].primary,
    insightsSegmentPositiveIconColor:
      designColors.dark['anchor-icons']['segment-general'].secondary,
    insightsSegmentNegativeIconBackground:
      designColors.dark['anchor-icons']['segment-negative'].primary,
    insightsSegmentNegativeIconColor:
      designColors.dark['anchor-icons']['segment-negative'].secondary,
    insightsAreaChartPrimary:
      designColors.dark['anchor-icons']['segment-general'].primary,
    insightsAreaChartSecondary:
      designColors.dark['anchor-icons']['segment-general'].secondary,
    insightsObjectiveBorder: '#336FCC',
    primaryMaptualListFilteringColor: designColors.dark.text.general.tertiary,
    emptyText: designColors.dark.text.general.empty,
    secondaryMaptualListFilteringColor:
      designColors.dark.text.general.secondary,
    tertiaryMaptualListFilteringColor: designColors.dark.text.general.primary,
    segmentSelectionCaptionColor: designColors.dark.text.general.secondary,
    segmentSelectionSelectedBackgroundColor:
      designColors.dark.inputs.secondary.active.surface,
    segmentSelectionHoverBackgroundColor:
      designColors.dark.inputs.secondary.hover.surface,
    selectedSegmentColor: designColors.dark.inputs.secondary.active.content,
    paperColor: designColors.dark.surface.page,
    codeBlockBackground: '#16161b',
    maptualListFilteringButtonBackgroundColor:
      designColors.dark.inputs.primary.default.surface,
    maptualListFilteringBadgeColor:
      designColors.dark.inputs.primary.default.content,
    maptualListIconColor:
      designColors.dark.inputs.secondary.default['iconPrimary'],
    maptualListInverseIconColor:
      designColors.dark.inputs.secondary.active.content,
    buttonSecondaryBackgroundColour:
      designColors.dark.inputs.secondary.active.surface,
    insightsCardTitle: designColors.dark.text.general.primary,
    tableHoverColor: designColors.dark.inputs.secondary.hover.surface,
    maptualScoreEventColor: teal.A400,
    maptualScoreEventTextColor: teal[100],
    maptualSegmentJoinEventColor: blue.A400,
    maptualSegmentJoinEventTextColor: blue[100],
    maptualSegmentJoinEventIconColor: blue.A200,
    maptualSegmentLeaveEventColor: blue.A700,
    maptualSegmentLeaveEventTextColor: blue[100],
    maptualSegmentLeaveEventIconColor: blue.A200,
    maptualUserEventColor: amber.A400,
    maptualUserEventTextColor: amber[200],
    maptualSalesEventColor: lightBlue[700],
    maptualMarketingEventTextColor: purple[500],
    dangerHoverText: '#F3A5B7',
    dangerHoverBackground: '#5A0C1E',
    projectCardBorder: designColors.color.neutral[20],
    colorScheme: 'dark',
    markerLine: designColors.dark.chart.markerLine,
    chartLines: designColors.dark.chart.lines,
    chartAxisLabel: designColors.dark.chart.axisLabel,
    chartDataLabel: designColors.dark.chart.dataLabel,
    rankedCategoricalScaleCategory:
      designColors.dark.rankedCategoricalScale.category,
    rankedCategoricalScaleCategoryLabel:
      designColors.dark.rankedCategoricalScale.categoryLabel,
    rankedCategoricalScaleActiveChevron:
      designColors.dark.rankedCategoricalScale.activeChevron,
    powerScoreText: designColors.dark.powerscore.text,
    moduleConfigurationFormDescription: designColors.color.neutral['60'],
    powerscore: {
      10: {
        borderColor: designColors.dark.powerscore.legendary.primary,
        background: designColors.dark.powerscore.legendary.secondary,
      },
      9: {
        borderColor: designColors.dark.powerscore.artifact.primary,
        background: designColors.dark.powerscore.artifact.secondary,
      },
      8: {
        borderColor: designColors.dark.powerscore.epic.primary,
        background: designColors.dark.powerscore.epic.secondary,
      },
      7: {
        borderColor: designColors.dark.powerscore.epic.primary,
        background: designColors.dark.powerscore.epic.secondary,
      },
      6: {
        borderColor: designColors.dark.powerscore.rare.primary,
        background: designColors.dark.powerscore.rare.secondary,
      },
      5: {
        borderColor: designColors.dark.powerscore.rare.primary,
        background: designColors.dark.powerscore.rare.secondary,
      },
      4: {
        borderColor: designColors.dark.powerscore.rare.primary,
        background: designColors.dark.powerscore.rare.secondary,
      },
      3: {
        borderColor: designColors.dark.powerscore.common.primary,
        background: designColors.dark.powerscore.common.secondary,
      },
      2: {
        borderColor: designColors.dark.powerscore.common.primary,
        background: designColors.dark.powerscore.common.secondary,
      },
      1: {
        borderColor: designColors.dark.powerscore.common.primary,
        background: designColors.dark.powerscore.common.secondary,
      },
      0: {
        borderColor: designColors.dark.powerscore.zero.primary,
        background: designColors.dark.powerscore.zero.secondary,
      },
      "no-score": {
        borderColor: designColors.dark.powerscore["no-score"].primary,
        background: designColors.dark.powerscore["no-score"].secondary,
      }
    },
    maptualBinColors: [
      {
        primaryColor: '#000000',
        secondaryColor: '#121212',
        contrastingColor: grey[400],
      },
      {
        primaryColor: grey[500],
        secondaryColor: grey[800],
        contrastingColor: '#ffffff',
      },
      {
        primaryColor: '#2E6EF9',
        secondaryColor: '#062A78',
        contrastingColor: '#ffffff',
      },
      {
        primaryColor: '#F0D438',
        secondaryColor: '#624600',
        contrastingColor: '#ffffff',
      },
      {
        primaryColor: '#FF800B',
        secondaryColor: '#652A00',
        contrastingColor: '#ffffff',
      },
    ],
    graphAxisColor: '#8595AD',
    graphLegendColors: [
      '#047DDA',
      '#D09431',
      '#C9464F',
      '#5D4DAA',
      '#8DA8D2',
      '#008FFB',
      '#00E396',
      '#FEB019',
      '#FF4560',
      '#775DD0',
      '#3F51B5',
      '#03A9F4',
      '#4CAF50',
      '#F9CE1D',
      '#A5978B',
      '#546E7A',
      '#2B908F',
    ],
    borderMidContrast: designColors.dark.inputs.secondary.default.border,
    borderLowContrast: designColors.dark.inputs.secondary.default.border,
    quartileRowColor: 'green',
    entitySelectionHoverBackgroundColor:
      designColors.dark.inputs.secondary.hover.surface,
    neutral10: designColors.color.neutral['10'],
    neutral30: designColors.color.neutral[30],
    neutral50: designColors.color.neutral[50],
    neutral60: designColors.dark.text.general.tertiary,
    neutral70: designColors.color.neutral['70'],
    neutral90: designColors.color.neutral['90'],
    primary40: designColors.dark.feedback.info.primary,
    primary99: designColors.dark.text.general.primary,
    danger40: designColors.dark.feedback.danger.primary,
    danger60: designColors.dark.text.danger.primary,
    tooltipBackgroundColor: designColors.dark.surface.popover,
    offWhiteFontColor: designColors.dark.text.general.secondary,
    userConfigEditHighlight: '#5C8CD6',
    objectiveTypeEditButtonColor: '#FCFCFD',
    objectiveTypeSaveButtonDisabledColor: '#16191F',
    objectiveTypeDeleteIconColor: '#C2CAD6',
    popoverBorderColor: designColors.dark.border.primary,
    modalBackgroundColor: designColors.dark.surface.modal,
    closeButtonBackgroundColor:
      designColors.dark.inputs.secondary.default.surface,
    modalTextErrorColor: designColors.dark.text.danger.primary,
    precursorDeleteFromListButtonColor: designColors.dark.text.danger.primary,
    objectiveSummaryBackgroundColor: designColors.dark.surface.page,
    navigationButtonBackgroundColor: '#1A5CC3',
    navBarButtonIconColor: '#667A99',
    navBarButtonTextColor: '#FCFCFD',
    graphBlue: '#85C2E0',
    graphGridColor: '#252C37',
    generalSecondaryColor: designColors.dark.text.general.secondary,
    buttonGroupHeaderColor: designColors.dark.text.general.secondary,
    selectedTableCellColor: designColors.dark.table.cell.selected.surface,
    selectedTableRowColor: designColors.dark.table.row.selected.surface,
    tableCellDefaultContent: designColors.dark.table.cell.default.content,
    tableCellHeaderDefaultContent:
      designColors.dark.table.cellHeader.default.content,
    tableCellHeaderDefaultContentSecondary:
      designColors.dark.table.cellHeader.default.contentSecondary,
    activeForm: { border: designColors.color.blue['60'] },
    dataGeneralColor: designColors.dark.data.general[1],
    dataGeneralColor2: designColors.dark.data.general[2],
    dataGeneralColor3: designColors.dark.data.general[3],
    dataGeneralColor4: designColors.dark.data.general[4],
    dataGeneralColor5: designColors.dark.data.general[5],
    dataUnfocused4: designColors.dark.progressBar.barSurface,
    dividerPrimaryColor: designColors.dark.divider.primary,
    borderPrimaryColor: designColors.dark.border.primary,
    emptyDataColor: designColors.dark.data.trend.none.surface,
    feedbackInfoSecondary: designColors.dark.feedback.info.secondary,
    feedbackSuccessPrimary: designColors.dark.feedback.success.primary,
    cardBackgroundColor: designColors.dark.surface.card,
    badgeBackgroundColor: designColors.dark.badge.default.surface,
    badgeContentColor: designColors.dark.badge.default.content,
    badgeHoverSurface: designColors.dark.badge.hover.surface,
    utilityContentColor: designColors.dark.inputs.utility.default.content,
    utilityHoverSurfaceColor: designColors.dark.inputs.utility.hover.surface,
    utilityHoverContentColor: designColors.dark.inputs.utility.hover.content,
    tableHeaderContentColor: designColors.dark.table.headerContent,
    tableHeaderContentColorHighlight:
      designColors.dark.table.headerContentHighlight,
    tableHeaderBorder: designColors.dark.table.headerBorder,
    tableBodyBorder: designColors.dark.table.bodyBorder,
    utilityActiveSurfaceColor: designColors.dark.inputs.utility.active.surface,
    utilityActiveContentColor: designColors.dark.inputs.utility.active.content,
    surfaceEmpty: designColors.dark.surface.empty,
    alertPrimaryInfoTitleColor: designColors.dark.alerts.primary.info.title,
    alertPrimaryInfoColor: designColors.dark.alerts.primary.info.fill,
    alertPrimaryInfoIconColor: designColors.dark.alerts.primary.info.icon,
    alertButtonHighContrastDefaultContent:
      designColors.dark.alertButton.highContrast.default.content,
    alertPrimaryInfoDescriptionColor:
      designColors.dark.alerts.primary.info.description,
    alertButtonHighContrastHoverSurface:
      designColors.dark.alertButton.highContrast.hover.surface,
    textFieldSelectionsDefaultIconColor:
      designColors.dark.textFieldSelections.default.icon,
    textFieldSelectionsDefaultBorderColor:
      designColors.dark.textFieldSelections.default.border,
    textFieldSelectionsDefaultTextColor:
      designColors.dark.textFieldSelections.default.text,
    textFieldSelectionsHoverBorderColor:
      designColors.dark.textFieldSelections.hover.border,
    textFieldSelectionsActiveBorderColor:
      designColors.dark.textFieldSelections.active.border,
    generalScrollbarTrackColor: designColors.dark.general.scrollbar.track,
    generalScrollbarButtonColor: designColors.dark.general.scrollbar.button,
    timelineActivitySurfaceColor:
      designColors.dark.HCPTimelineItem.activity.surface,
    timelineActivityContentSecondaryColor:
      designColors.dark.HCPTimelineItem.activity.contentSecondary,
    timelineActivityContentPrimaryColor:
      designColors.dark.HCPTimelineItem.activity.contentPrimary,
    timelineActivityBorderColor:
      designColors.dark.HCPTimelineItem.activity.border,
    timelineActivityIconBorderColor:
      designColors.dark.HCPTimelineItem.activity.iconBorder,
    timelineActivityIconBackgroundColor:
      designColors.dark.HCPTimelineItem.activity.iconBackground,
    timelineScoreIncreaseBorderColor:
      designColors.dark.HCPTimelineItem.scoreIncrease.border,
    timelineScoreDecreaseBorderColor:
      designColors.dark.HCPTimelineItem.scoreDecrease.border,
    timelineScoreDecreaseSurface:
      designColors.dark.HCPTimelineItem.scoreDecrease.surface,
    timelineScoreIncreaseSurface:
      designColors.dark.HCPTimelineItem.scoreIncrease.surface,
    timelineScoreIncreaseContentPrimary:
      designColors.dark.HCPTimelineItem.scoreIncrease.contentPrimary,
    timelineScoreIncreaseContentSecondary:
      designColors.dark.HCPTimelineItem.scoreIncrease.contentSecondary,
    timelineScoreDecreaseContentPrimary:
      designColors.dark.HCPTimelineItem.scoreDecrease.contentPrimary,
    timelineScoreDecreaseContentSecondary:
      designColors.dark.HCPTimelineItem.scoreDecrease.contentSecondary,
    tooltipSurfaceColor: designColors.dark.tooltip.surface,
    tooltipContentColor: designColors.dark.tooltip.content,
    progressBar: designColors.dark.progressBar.barProgress,
    progressBarBackground: designColors.dark.progressBar.barSurface,
    progressBarPrimaryMetric: designColors.dark.progressBar.primaryMetric,
    progressBarSecondaryMetric: designColors.dark.progressBar.secondaryMetric,
    buttonDisabledSurfaceColor:
      designColors.dark.inputs.secondary.disabled.surface,
    buttonDisabledContentColor:
      designColors.dark.inputs.secondary.disabled.content,
    secondaryDefaultIconPrimaryColor:
      designColors.dark.inputs.secondary.default.iconPrimary,
    disabledUtilitySurface: designColors.dark.inputs.utility.disabled.surface,
    disabledUtilityContent: designColors.dark.inputs.utility.disabled.content,
    inputsPrimaryDefaultContent:
      designColors.dark.inputs.primary.default.content,
    inputsPrimaryHoverSurface: designColors.dark.inputs.primary.hover.surface,
    inputsPrimaryDisabledSurface:
      designColors.dark.inputs.primary.disabled.surface,
    inputsPrimaryDisabledContent:
      designColors.dark.inputs.primary.disabled.content,
    notificationBadgeSurface: designColors.dark.notificationBadge.surface,
    notificationBadgeContent: designColors.dark.notificationBadge.content,
    boxShadowLevelOne: designColors.dark.shadow['level-1'],
    borderFocus: designColors.dark.border.focus,
    tabDefaultContent: designColors.dark.tab.default.content,
    tabActiveContent: designColors.dark.tab.active.content,
    tabActiveIndicator: designColors.dark.tab.active.indicator,
    dataCompetition1: designColors.dark.data.competition[1],
    dataCompetition3: designColors.dark.data.competition[3],
    dataCompetition5: designColors.dark.data.competition[5],
    dataPrecursor3: designColors.dark.data.precursor[3],
    tableCellHighlightedSurface:
      designColors.dark.table.cell.highlighted.surface,
    alertPrimarySuccessFillColor: designColors.dark.alerts.primary.success.fill,
    alertPrimarySuccessContentColor:
      designColors.dark.alerts.primary.success.title,
    tablePopoverHoverSurface: designColors.dark.table.cell.hover.surface,
    alertPrimaryDangerFillColor: designColors.dark.alerts.primary.danger.fill,
    inputsPrimaryFocusedSurfaceColor:
      designColors.dark.inputs.primary.focused.surface,
    colorBlue70: designColors.color.blue[70],
    anchorIconInsightGeneralPrimary: designColors.dark.anchorIconInsight.general.primary,
    anchorIconInsightGeneralSecondary: designColors.dark.anchorIconInsight.general.secondary
  },
  lightTheme: {
    mainBackground: designColors.light.surface.page,
    mainSurfaceBackground: designColors.light.surface.page,
    primaryTextColor: designColors.light.text.general.primary,
    secondaryTextColor: designColors.light.text.general.secondary,
    secondaryTextColorHighContrast: designColors.light.text.general.secondary,
    primaryColorLight: designColors.light.inputs.primary.hover.surface,
    primaryColor: designColors.light.inputs.primary.default.surface,
    primaryColorDark: designColors.light.inputs.primary.default.surface,
    selectedRowNowColumn: `#284181`,
    secondaryColor: designColors.light.inputs.secondary.default.surface,
    tertiaryColor: designColors.light.text.general.tertiary,
    menuTextColor: designColors.light.inputs.secondary.default.content,
    inputs: designColors.light.inputs,
    menuTextColorContrasting: 'green',
    inputBackground: designColors.light.inputs.secondary.default.surface,
    inputBackgroundHover: designColors.light.inputs.secondary.hover.surface,
    chipBackground: designColors.light.inputs.secondary.default.surface,
    chipForeground: designColors.light.inputs.secondary.default.content,
    chipInputDefaultSurface: designColors.light.chip.input.default.surface,
    chipInputDefaultBorder: designColors.light.chip.input.default.border,
    chipInputDefaultContent: designColors.light.chip.input.default.content,
    chipInputDefaultIcon: designColors.light.chip.input.default.icon,
    chipChoiceDefaultSurface: designColors.light.chip.choice.default.surface,
    chipChoiceDefaultContent: designColors.light.chip.choice.default.content,
    chipChoiceDefaultBorder: designColors.light.chip.choice.default.border,
    chipChoiceActiveSurface: designColors.light.chip.choice.active.surface,
    chipChoiceActiveContent: designColors.light.chip.choice.active.content,
    chipChoiceActiveBorder: designColors.light.chip.choice.active.border,
    chipChoiceHoverSurface: designColors.light.chip.choice.hover.surface,
    chipChoiceHoverContent: designColors.light.chip.choice.hover.content,
    chipChoiceHoverBorder: designColors.light.chip.choice.hover.border,
    increaseColorMain: designColors.light.data.trend.positive.active.surface,
    decreaseColorMain: designColors.light.data.trend.negative.active.surface,
    increaseColorMainDefault:
      designColors.light.data.trend.positive.default.surface,
    decreaseColorMainDefault:
      designColors.light.data.trend.negative.default.surface,
    increaseColorMainContent:
      designColors.light.data.trend.positive.active.content,
    decreaseColorMainContent:
      designColors.light.data.trend.negative.active.content,
    increaseColorMainDefaultContent:
      designColors.light.data.trend.positive.default.content,
    decreaseColorMainDefaultContent:
      designColors.light.data.trend.negative.default.content,
    HCPListItemBackground: designColors.light.HCPListItem.default.surface,
    HCPListItemContent: designColors.light.HCPListItem.default.contentPrimary,
    HCPListItemContentSecondary:
      designColors.light.HCPListItem.default.contentSecondary,
    HCPListItemIcon: designColors.light.HCPListItem.default.icon,
    HCPListItemBackgroundActive: designColors.light.HCPListItem.active.surface,
    HCPListItemContentActive:
      designColors.light.HCPListItem.active.contentPrimary,
    HCPListItemContentSecondaryActive:
      designColors.light.HCPListItem.active.contentSecondary,
    HCPListItemIconActive: designColors.light.HCPListItem.active.icon,
    HCPListItemBackgroundHover: designColors.light.HCPListItem.hover.surface,
    HCPListItemContentHover:
      designColors.light.HCPListItem.hover.contentPrimary,
    HCPListItemContentSecondaryHover:
      designColors.light.HCPListItem.hover.contentSecondary,
    HCPListItemIconHover: designColors.light.HCPListItem.hover.icon,
    decreaseColorMainLight:
      designColors.light.data.trend.negative.default.content,
    increaseColorMainContrasting:
      designColors.light.data.trend.positive.active.content,
    decreaseColorMainContrasting:
      designColors.light.data.trend.negative.active.content,
    increaseColorChip: designColors.light.data.trend.positive.default.content,
    decreaseColorChip: designColors.light.data.trend.negative.default.content,
    increaseColorChipContrasting:
      designColors.light.data.trend.positive.active.surface, // "#094F18",
    decreaseColorChipContrasting:
      designColors.light.data.trend.negative.active.surface, // "#FFC3C3",
    dropDownBackground: designColors.light.surface.popover,
    popoverBackground: designColors.light.surface.popover,
    templateCardBackground: designColors.color.dark[16],
    journeyDrawerBackgroundColor: designColors.light.surface.page,
    contentCardBackgroundColor: designColors.light.surface.card,
    contentCardAltBackgroundColor: designColors.light.surface.page,
    contentCardHighlightColor:
      designColors.light.inputs.secondary.active.surface,
    contentCardHighlightSelectedColor: '#284181', // todo
    contentCardBorderColor: designColors.light.border.primary,
    buttonBackgroundColor: designColors.light.inputs.secondary.default.surface,
    buttonContentColor: designColors.light.inputs.secondary.default.content,
    buttonBorderColor: designColors.light.inputs.secondary.default.border,
    buttonHoverBackgroundColor:
      designColors.light.inputs.secondary.hover.surface,
    buttonHoverContentColor: designColors.light.inputs.secondary.hover.content,
    buttonHoverBorderColor: designColors.light.inputs.secondary.hover.border,
    buttonActiveBackgroundColor:
      designColors.light.inputs.secondary.active.surface,
    buttonActiveContentColor:
      designColors.light.inputs.secondary.active.content,
    buttonActiveBorderColor: designColors.light.inputs.secondary.active.border,

    textButtonColor: designColors.light.text.infoLink.primary,
    csvDownloadColor: designColors.light.text.infoLink.primary,
    listIconColor: designColors.light.inputs.secondary.default.iconSecondary,
    badgePrimaryColor: designColors.light.badge.default.surface,
    badgePrimaryContentColor: designColors.light.badge.default.content,
    badgeActiveColor: designColors.light.badge.active.surface,
    badgeActiveContentColor: designColors.light.badge.active.content,
    badgeHighlightedColor: designColors.light.badge.highlighted.surface,
    badgeHighlightedContentColor: designColors.light.badge.highlighted.content,
    checkboxUnselected: designColors.light.checkbox.unselected,
    checkboxSelected: designColors.light.checkbox.selected,
    checkboxCheck: designColors.light.checkbox.check,
    insightsPositiveBackground:
      designColors.light.data.trend.positive.default.surface,
    insightsPositiveText:
      designColors.light.data.trend.positive.default.content,
    insightsNegativeBackground:
      designColors.light.data.trend.negative.default.surface,
    insightsNegativeText:
      designColors.light.data.trend.negative.default.content,
    anchorIconsGeneralPrimary:
      designColors.light['anchor-icons'].general.primary,
    anchorIconsGeneralSecondary:
      designColors.light['anchor-icons'].general.secondary,
    insightsIcon: designColors.light['anchor-icons']['segment-general'].primary,
    insightsIconBackground:
      designColors.light['anchor-icons']['segment-general'].secondary,
    insightsSegmentPositiveIconBackground:
      designColors.light['anchor-icons']['segment-general'].primary,
    insightsSegmentPositiveIconColor:
      designColors.light['anchor-icons']['segment-general'].secondary,
    insightsSegmentNegativeIconBackground:
      designColors.light['anchor-icons']['segment-negative'].primary,
    insightsSegmentNegativeIconColor:
      designColors.light['anchor-icons']['segment-negative'].secondary,
    insightsAreaChartPrimary:
      designColors.light['anchor-icons']['segment-general'].primary,
    insightsAreaChartSecondary:
      designColors.light['anchor-icons']['segment-general'].secondary,
    insightsObjectiveBorder: '#336FCC',
    primaryMaptualListFilteringColor: designColors.light.text.general.tertiary,
    emptyText: designColors.light.text.general.empty,
    secondaryMaptualListFilteringColor:
      designColors.light.text.general.secondary,
    tertiaryMaptualListFilteringColor: designColors.light.text.general.primary,
    segmentSelectionCaptionColor: designColors.light.text.general.secondary,
    segmentSelectionSelectedBackgroundColor:
      designColors.light.inputs.secondary.active.surface,
    segmentSelectionHoverBackgroundColor:
      designColors.light.inputs.secondary.hover.surface,
    selectedSegmentColor: designColors.light.inputs.secondary.active.content,
    paperColor: designColors.light.surface.page,
    codeBlockBackground: '#16161b',
    maptualListFilteringButtonBackgroundColor:
      designColors.light.inputs.primary.default.surface,
    maptualListFilteringBadgeColor:
      designColors.light.inputs.primary.default.content,
    maptualListIconColor:
      designColors.light.inputs.secondary.default['iconPrimary'],
    maptualListInverseIconColor:
      designColors.light.inputs.secondary.active.content,
    buttonSecondaryBackgroundColour:
      designColors.light.inputs.secondary.active.surface,
    insightsCardTitle: designColors.light.text.general.primary,
    tableHoverColor: designColors.light.inputs.secondary.hover.surface,
    maptualScoreEventColor: teal.A400,
    maptualScoreEventTextColor: teal[100],
    maptualSegmentJoinEventColor: blue.A400,
    maptualSegmentJoinEventTextColor: blue[100],
    maptualSegmentJoinEventIconColor: blue.A200,
    maptualSegmentLeaveEventColor: blue.A700,
    maptualSegmentLeaveEventTextColor: blue[100],
    maptualSegmentLeaveEventIconColor: blue.A200,
    maptualUserEventColor: amber.A400,
    maptualUserEventTextColor: amber[200],
    maptualSalesEventColor: lightBlue[700],
    maptualMarketingEventTextColor: purple[500],
    dangerHoverText: '#F3A5B7',
    dangerHoverBackground: '#5A0C1E',
    projectCardBorder: designColors.color.neutral[20],
    colorScheme: 'light',
    markerLine: designColors.light.chart.markerLine,
    chartLines: designColors.light.chart.lines,
    chartAxisLabel: designColors.light.chart.axisLabel,
    chartDataLabel: designColors.light.chart.dataLabel,
    rankedCategoricalScaleCategory:
      designColors.light.rankedCategoricalScale.category,
    rankedCategoricalScaleCategoryLabel:
      designColors.light.rankedCategoricalScale.categoryLabel,
    rankedCategoricalScaleActiveChevron:
      designColors.light.rankedCategoricalScale.activeChevron,
    powerScoreText: designColors.light.powerscore.text,
    moduleConfigurationFormDescription: designColors.color.neutral['60'],
    powerscore: {
      10: {
        borderColor: designColors.light.powerscore.legendary.primary,
        background: designColors.light.powerscore.legendary.secondary,
      },
      9: {
        borderColor: designColors.light.powerscore.artifact.primary,
        background: designColors.light.powerscore.artifact.secondary,
      },
      8: {
        borderColor: designColors.light.powerscore.epic.primary,
        background: designColors.light.powerscore.epic.secondary,
      },
      7: {
        borderColor: designColors.light.powerscore.epic.primary,
        background: designColors.light.powerscore.epic.secondary,
      },
      6: {
        borderColor: designColors.light.powerscore.rare.primary,
        background: designColors.light.powerscore.rare.secondary,
      },
      5: {
        borderColor: designColors.light.powerscore.rare.primary,
        background: designColors.light.powerscore.rare.secondary,
      },
      4: {
        borderColor: designColors.light.powerscore.rare.primary,
        background: designColors.light.powerscore.rare.secondary,
      },
      3: {
        borderColor: designColors.light.powerscore.common.primary,
        background: designColors.light.powerscore.common.secondary,
      },
      2: {
        borderColor: designColors.light.powerscore.common.primary,
        background: designColors.light.powerscore.common.secondary,
      },
      1: {
        borderColor: designColors.light.powerscore.common.primary,
        background: designColors.light.powerscore.common.secondary,
      },
      0: {
        borderColor: designColors.light.powerscore.zero.primary,
        background: designColors.light.powerscore.zero.secondary,
      },
      "no-score": {
        borderColor: designColors.light.powerscore["no-score"].primary,
        background: designColors.light.powerscore["no-score"].secondary,
      }
    },
    maptualBinColors: [
      {
        primaryColor: '#000000',
        secondaryColor: '#121212',
        contrastingColor: grey[400],
      },
      {
        primaryColor: grey[500],
        secondaryColor: grey[800],
        contrastingColor: '#ffffff',
      },
      {
        primaryColor: '#2E6EF9',
        secondaryColor: '#062A78',
        contrastingColor: '#ffffff',
      },
      {
        primaryColor: '#F0D438',
        secondaryColor: '#624600',
        contrastingColor: '#ffffff',
      },
      {
        primaryColor: '#FF800B',
        secondaryColor: '#652A00',
        contrastingColor: '#ffffff',
      },
    ],
    graphAxisColor: '#8595AD',
    graphLegendColors: [
      '#047DDA',
      '#D09431',
      '#C9464F',
      '#5D4DAA',
      '#8DA8D2',
      '#008FFB',
      '#00E396',
      '#FEB019',
      '#FF4560',
      '#775DD0',
      '#3F51B5',
      '#03A9F4',
      '#4CAF50',
      '#F9CE1D',
      '#A5978B',
      '#546E7A',
      '#2B908F',
    ],
    borderMidContrast: designColors.light.inputs.secondary.default.border,
    borderLowContrast: designColors.light.inputs.secondary.default.border,
    quartileRowColor: 'green',
    entitySelectionHoverBackgroundColor:
      designColors.light.inputs.secondary.hover.surface,
    neutral10: designColors.color.neutral['10'],
    neutral30: designColors.color.neutral[30],
    neutral60: designColors.light.text.general.tertiary,
    neutral70: designColors.color.neutral['70'],
    neutral90: designColors.color.neutral['90'],
    primary40: designColors.light.feedback.info.primary,
    primary99: designColors.light.text.general.primary,
    danger40: designColors.light.feedback.danger.primary,
    danger60: designColors.light.text.danger.primary,
    tooltipBackgroundColor: designColors.light.surface.popover,
    offWhiteFontColor: designColors.light.text.general.secondary,
    userConfigEditHighlight: '#5C8CD6',
    objectiveTypeEditButtonColor: '#FCFCFD',
    objectiveTypeSaveButtonDisabledColor: '#16191F',
    objectiveTypeDeleteIconColor: '#C2CAD6',
    popoverBorderColor: designColors.light.border.primary,
    modalBackgroundColor: designColors.light.surface.modal,
    closeButtonBackgroundColor:
      designColors.light.inputs.secondary.default.surface,
    modalTextErrorColor: designColors.light.text.danger.primary,
    precursorDeleteFromListButtonColor: designColors.light.text.danger.primary,
    objectiveSummaryBackgroundColor: designColors.light.surface.page,
    navigationButtonBackgroundColor: '#1A5CC3',
    navBarButtonIconColor: '#667A99',
    navBarButtonTextColor: '#FCFCFD',
    graphBlue: designColors.light.data.divergent[2],
    graphGridColor: '#252C37',
    generalSecondaryColor: designColors.light.text.general.secondary,
    buttonGroupHeaderColor: designColors.light.text.general.secondary,
    selectedTableCellColor: designColors.light.table.cell.selected.surface,
    selectedTableRowColor: designColors.light.table.row.selected.surface,
    tableCellDefaultContent: designColors.light.table.cell.default.content,
    tableCellHeaderDefaultContent:
      designColors.light.table.cellHeader.default.content,
    tableCellHeaderDefaultContentSecondary:
      designColors.light.table.cellHeader.default.contentSecondary,
    activeForm: { border: designColors.color.blue['60'] },
    dataGeneralColor: designColors.light.data.general[1],
    dataGeneralColor2: designColors.light.data.general[2],
    dataGeneralColor3: designColors.light.data.general[3],
    dataGeneralColor4: designColors.light.data.general[4],
    dataGeneralColor5: designColors.light.data.general[5],
    dataUnfocused4: designColors.light.progressBar.barSurface,
    dividerPrimaryColor: designColors.light.divider.primary,
    borderPrimaryColor: designColors.light.border.primary,
    emptyDataColor: designColors.light.data.trend.none.surface,
    feedbackInfoSecondary: designColors.light.feedback.info.secondary,
    feedbackSuccessPrimary: designColors.light.feedback.success.primary,
    cardBackgroundColor: designColors.light.surface.card,
    badgeBackgroundColor: designColors.light.badge.default.surface,
    badgeContentColor: designColors.light.badge.default.content,
    badgeHoverSurface: designColors.light.badge.hover.surface,
    utilityContentColor: designColors.light.inputs.utility.default.content,
    utilityHoverSurfaceColor: designColors.light.inputs.utility.hover.surface,
    utilityHoverContentColor: designColors.light.inputs.utility.hover.content,
    tableHeaderContentColor: designColors.light.table.headerContent,
    tableHeaderContentColorHighlight:
      designColors.light.table.headerContentHighlight,
    tableHeaderBorder: designColors.light.table.headerBorder,
    tableBodyBorder: designColors.light.table.bodyBorder,
    utilityActiveSurfaceColor: designColors.light.inputs.utility.active.surface,
    utilityActiveContentColor: designColors.light.inputs.utility.active.content,
    surfaceEmpty: designColors.light.surface.empty,
    alertPrimaryInfoTitleColor: designColors.light.alerts.primary.info.title,
    alertPrimaryInfoColor: designColors.light.alerts.primary.info.fill,
    alertPrimaryInfoIconColor: designColors.light.alerts.primary.info.icon,
    alertButtonHighContrastDefaultContent:
      designColors.light.alertButton.highContrast.default.content,
    alertButtonHighContrastHoverSurface:
      designColors.light.alertButton.highContrast.hover.surface,
    alertPrimaryInfoDescriptionColor:
      designColors.light.alerts.primary.info.description,
    textFieldSelectionsDefaultIconColor:
      designColors.light.textFieldSelections.default.icon,
    textFieldSelectionsDefaultBorderColor:
      designColors.light.textFieldSelections.default.border,
    textFieldSelectionsDefaultTextColor:
      designColors.light.textFieldSelections.default.text,
    textFieldSelectionsHoverBorderColor:
      designColors.light.textFieldSelections.hover.border,
    textFieldSelectionsActiveBorderColor:
      designColors.light.textFieldSelections.active.border,
    generalScrollbarTrackColor: designColors.light.general.scrollbar.track,
    generalScrollbarButtonColor: designColors.light.general.scrollbar.button,
    timelineActivitySurfaceColor:
      designColors.light.HCPTimelineItem.activity.surface,
    timelineActivityContentSecondaryColor:
      designColors.light.HCPTimelineItem.activity.contentSecondary,
    timelineActivityContentPrimaryColor:
      designColors.light.HCPTimelineItem.activity.contentPrimary,
    timelineActivityBorderColor:
      designColors.light.HCPTimelineItem.activity.border,
    timelineActivityIconBorderColor:
      designColors.light.HCPTimelineItem.activity.iconBorder,
    timelineActivityIconBackgroundColor:
      designColors.light.HCPTimelineItem.activity.iconBackground,
    timelineScoreIncreaseBorderColor:
      designColors.light.HCPTimelineItem.scoreIncrease.border,
    timelineScoreDecreaseBorderColor:
      designColors.light.HCPTimelineItem.scoreDecrease.border,
    timelineScoreIncreaseSurface:
      designColors.light.HCPTimelineItem.scoreIncrease.surface,
    timelineScoreIncreaseContentPrimary:
      designColors.light.HCPTimelineItem.scoreIncrease.contentPrimary,
    timelineScoreIncreaseContentSecondary:
      designColors.light.HCPTimelineItem.scoreIncrease.contentSecondary,
    timelineScoreDecreaseSurface:
      designColors.light.HCPTimelineItem.scoreDecrease.surface,
    timelineScoreDecreaseContentPrimary:
      designColors.light.HCPTimelineItem.scoreDecrease.contentPrimary,
    timelineScoreDecreaseContentSecondary:
      designColors.light.HCPTimelineItem.scoreDecrease.contentSecondary,
    tooltipSurfaceColor: designColors.light.tooltip.surface,
    tooltipContentColor: designColors.light.tooltip.content,
    progressBar: designColors.light.progressBar.barProgress,
    progressBarBackground: designColors.light.progressBar.barSurface,
    progressBarPrimaryMetric: designColors.light.progressBar.primaryMetric,
    progressBarSecondaryMetric: designColors.light.progressBar.secondaryMetric,
    buttonDisabledSurfaceColor:
      designColors.light.inputs.secondary.disabled.surface,
    buttonDisabledContentColor:
      designColors.light.inputs.secondary.disabled.content,
    secondaryDefaultIconPrimaryColor:
      designColors.light.inputs.secondary.default.iconPrimary,
    disabledUtilitySurface: designColors.light.inputs.utility.disabled.surface,
    disabledUtilityContent: designColors.light.inputs.utility.disabled.content,
    inputsPrimaryDefaultContent:
      designColors.light.inputs.primary.default.content,
    inputsPrimaryHoverSurface: designColors.light.inputs.primary.hover.surface,
    inputsPrimaryDisabledSurface:
      designColors.light.inputs.primary.disabled.surface,
    inputsPrimaryDisabledContent:
      designColors.light.inputs.primary.disabled.content,
    notificationBadgeSurface: designColors.light.notificationBadge.surface,
    notificationBadgeContent: designColors.light.notificationBadge.content,
    boxShadowLevelOne: designColors.light.shadow['level-1'],
    borderFocus: designColors.light.border.focus,
    tabDefaultContent: designColors.light.tab.default.content,
    tabActiveContent: designColors.light.tab.active.content,
    tabActiveIndicator: designColors.light.tab.active.indicator,
    dataCompetition1: designColors.light.data.competition[1],
    dataCompetition3: designColors.light.data.competition[3],
    dataCompetition5: designColors.light.data.competition[5],
    dataPrecursor3: designColors.light.data.precursor[3],
    tableCellHighlightedSurface:
      designColors.light.table.cell.highlighted.surface,
    alertPrimarySuccessFillColor:
      designColors.light.alerts.primary.success.fill,
    alertPrimarySuccessContentColor:
      designColors.light.alerts.primary.success.title,
    tablePopoverHoverSurface: designColors.light.table.cell.hover.surface,
    alertPrimaryDangerFillColor: designColors.light.alerts.primary.danger.fill,
    inputsPrimaryFocusedSurfaceColor:
      designColors.light.inputs.primary.focused.surface,
    colorBlue70: designColors.color.blue[70],
    anchorIconInsightGeneralPrimary: designColors.light.anchorIconInsight.general.primary,
    anchorIconInsightGeneralSecondary: designColors.light.anchorIconInsight.general.secondary
  },
};
export type InsightsContentCardStyle = ReturnType<
  typeof insightContentCardStyle
>;

export const insightContentCardStyle = (themePalette: ThemePalette) => ({
  width: '100%',
  minWidth: 500,
  padding: 20,
  marginBottom: 30,
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 7,
  borderColor: themeColors[themePalette].contentCardBorderColor,
  backgroundColor: themeColors[themePalette].contentCardAltBackgroundColor,
  display: 'flex',
  alignItems: 'center',
});

export type ContentPrimaryTextStyle = ReturnType<
  typeof contentPrimaryTextStyle
>;

export const contentPrimaryTextStyle = (themePalette: ThemePalette) => ({
  fontSize: 18,
  fontWeight: TypographyDefinitions.fontWeight.semiBold,
  color: themeColors[themePalette].primaryTextColor,
});

export type ContentSecondaryTextStyle = ReturnType<
  typeof contentSecondaryTextStyle
>;

export const contentSecondaryTextStyle = (themePalette: ThemePalette) => ({
  fontSize: 14,
  fontWeight: TypographyDefinitions.fontWeight.medium,
  color: themeColors[themePalette].secondaryTextColorHighContrast,
});

export type ColorToneMapping = typeof colorToneMapping;

export const colorToneMapping = {
  POSITIVE: green[600],
  NEGATIVE: red[600],
  NEUTRAL: grey[800],
};

export const customStyles = {
  themeColors,
  insightContentCardStyle,
  contentPrimaryTextStyle,
  contentSecondaryTextStyle,
  colorToneMapping,
};
