import { useTheme } from "@mui/styles";
import { styled, FormControl, Select, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== "minWidth",
})(({ theme: { themeColors }, minWidth }) => ({
  minWidth,
  textAlign: "left",
  ".MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl": {
    color: themeColors.primaryMaptualListFilteringColor,
  },
}));

const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== "outlined",
})(({ theme: { themeColors }, outlined }) => ({
  backgroundColor: outlined ? themeColors.inputBackground : "unset",
  border: outlined ? `1px solid ${themeColors.buttonBorderColor}` : "none",
  borderRadius: "4px",
  color: themeColors.buttonContentColor,
  boxShadow: "none",
  ".MuiInputBase-input": {
    padding: "8px 14px",
  },
  "&:hover": {
    backgroundColor: themeColors.inputBackgroundHover,
  },
  "&.Mui-focused": {
    backgroundColor: themeColors.inputBackgroundHover,
  },
  ".MuiFilledInput-input:focus": {
    backgroundColor: themeColors.inputBackgroundHover,
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme: { themeColors } }) => ({
  color: `${themeColors.buttonContentColor} !important`,
  "&:hover": {
    backgroundColor: `${themeColors.buttonHoverBackgroundColor} !important`,
    color: `${themeColors.buttonHoverContentColor} !important`,
  },
  "&.Mui-selected": {
    backgroundColor: `${themeColors.buttonActiveBackgroundColor} !important`,
    color: `${themeColors.buttonActiveContentColor} !important`,
  },
}));

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.menuTextColor,
    marginLeft: "4px",
    width: "20px",
    height: "20px",
  })
);

export const ObjectiveSelector = ({
  objectiveId: selectedObjectiveId,
  updateFilterObjective,
  currentObjectives,
  fullWidth = false,
  outlined = true,
  minWidth = "",
}) => {
  const { themeColors } = useTheme();
  const currentObjective = currentObjectives.find(
    (obj) => obj.id === selectedObjectiveId
  );
  const handleObjectiveChange = (newObjectiveId) => {
    updateFilterObjective(newObjectiveId);
  };

  return currentObjectives?.length > 0 ? (
    <StyledFormControl
      variant="filled"
      fullWidth={fullWidth}
      minWidth={minWidth}
    >
      <StyledSelect
        disableUnderline
        IconComponent={StyledKeyboardArrowDownIcon}
        value={currentObjective?.id || ""}
        onChange={(e) => handleObjectiveChange(e.target.value)}
        MenuProps={{
          PaperProps: {
            sx: {
              marginTop: "4px",
              padding: "8px",
              backgroundColor: themeColors.inputBackground,
              boxShadow: `0px 1px 4px 0px ${themeColors.boxShadowLevelOne}`,
              gap: "8px",
            },
          },
        }}
        outlined={outlined}
      >
        {Array.isArray(currentObjectives)
          ? currentObjectives.map((item) => (
              <StyledMenuItem key={item.title || item.name} value={item.id}>
                {item.title || item.name || ""}
              </StyledMenuItem>
            ))
          : null}
      </StyledSelect>
    </StyledFormControl>
  ) : null;
};
