export const isEmptyItem = (item) =>
  Array.isArray(item?.data) &&
  item.data.every((data) => data === null) &&
  item.trend &&
  item.trend.value === null &&
  item.trend.type === 'TREND' &&
  item.trend.status === -1;

export const buildTableItems = (serverItems, specializedItem) => {
  if (!serverItems) {
    return [];
  }

  if (!specializedItem && serverItems.length > 0) {
    return serverItems;
  }

  return serverItems?.map((item) => [...item, specializedItem]);
};

export const isNotNumeric = (value) => !/^\d+$/.test(value);

export const filterRowsBySearchValue = (headers, rows, searchValue) => {
  const labelColumnIndex = headers.findIndex((obj) => obj.type === 'label');
  const lowerCaseSearchValue = searchValue.toLowerCase();

  return rows.filter((row) => {
    const rowData = row[labelColumnIndex].data;
    const combinedString = Object.values(rowData)
      .filter((value) => isNotNumeric(value))
      .join('')
      .toLowerCase();
    return combinedString.includes(lowerCaseSearchValue);
  });
};

export const formatMixpanelFilters = (filters) => {
  const mixpanelFilters = { ...filters };

  if (mixpanelFilters.regions) {
    mixpanelFilters.regions = Object.keys(mixpanelFilters.regions).map(
      (regionId) => mixpanelFilters.regions[regionId].label
    );
  }

  return mixpanelFilters;
};

export const sortWithNullValuesAtEnd = (
  v1: number,
  v2: number,
  direction?: string
) => {
  if (v1 === v2) return 0;

  if (v1 === null || v2 === null) {
    if (direction === 'desc') {
      return v1 === null ? -1 : 1;
    }
    return v1 === null ? 1 : -1;
  }

  return v1 - v2;
};
