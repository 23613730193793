import { useMemo } from "react";
import { styled, Skeleton, Box, Typography, Badge } from "@mui/material";
import { GenericTooltip, TooltipContent } from "@odaia/ui/src/tooltip";
import HistoryIcon from "@mui/icons-material/History";
import TimelineIcon from "@mui/icons-material/Timeline";

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "wrap",
})(({ width, wrap }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flexWrap: wrap ? "wrap" : "nowrap",
  gap: "4px",
  maxWidth: "fit-content",
  minWidth: width,
}));

const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "4px",
}));

const StyledBadge = styled(Badge)<{ isSelected: boolean }>(
  ({ theme: { themeColors, spacing }, isSelected }) => ({
    display: "flex",
    alignItems: "center",
    backgroundColor: isSelected
      ? themeColors.badgeActiveColor
      : themeColors.badgePrimaryColor,
    paddingInline: spacing(0.5),
    paddingBlock: spacing(0.25),
    borderRadius: "3px",
    width: "fit-content",
    height: "fit-content",
    maxWidth: "120px",
    color: isSelected
      ? themeColors.badgeActiveContentColor
      : themeColors.badgeContentColor,
    gap: spacing(0.25),
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: 400,

    "&:hover": {
      backgroundColor: themeColors.badgeHoverSurface,
    },
  })
);

const LoadingContainer = styled(Box)(() => ({
  margin: "auto",
}));

const StyledSkeleton = styled(Skeleton)(({ theme: { themeColors } }) => ({
  color: themeColors.buttonContentColor,
  transform: "none",
}));

interface BadgeContainerProps {
  content: {
    id: number;
    title: string;
    isHistoricalMember?: boolean;
    isPredictedMember?: boolean;
  }[];
  isLoading?: boolean;
  maxItems?: number;
  width?: number;
  wrap?: boolean;
  isSelected?: boolean;
}

export const BadgeContainer = ({
  content,
  isLoading = false,
  maxItems = 3,
  width = 150,
  wrap = true,
  isSelected = false,
}: BadgeContainerProps) => {
  const initialContent = useMemo(
    () => content.slice(0, maxItems),
    [content, maxItems]
  );

  const ellipsesTooltipContent = useMemo(
    () => TooltipContent(content),
    [content]
  );

  return (
    <Wrapper width={width} wrap={wrap}>
      {isLoading && (
        <LoadingContainer>
          <StyledSkeleton height={35} width={width} />
        </LoadingContainer>
      )}
      {!isLoading &&
        initialContent &&
        initialContent.length > 0 &&
        initialContent.map((value, i) => (
          <Container key={value.id}>
            <GenericTooltip title={value.title}>
              <StyledBadge isSelected={!!isSelected}>
                {value.isHistoricalMember && <HistoryIcon fontSize="inherit" />}
                {value.isPredictedMember && <TimelineIcon fontSize="inherit" />}
                <Typography variant="label" noWrap>
                  {value.title}
                </Typography>
              </StyledBadge>
            </GenericTooltip>
            {i === initialContent.length - 1 &&
              content.length !== initialContent.length && (
                <GenericTooltip title={ellipsesTooltipContent}>
                  <StyledBadge isSelected={!!isSelected}>
                    <Typography variant="label">{`+${
                      content.length - initialContent.length
                    }`}</Typography>
                  </StyledBadge>
                </GenericTooltip>
              )}
          </Container>
        ))}
    </Wrapper>
  );
};
