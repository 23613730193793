import { styled } from '@mui/material';
import { useMemo, useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import { ConfigViewTitle } from '../configview/configView';
import { FeatureToggleTile } from './FeatureToggleTile';
import { CreateToggleModal } from './createToggleModal';

const ConfigMainContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '30px 70px 30px 40px',
}));

const FlexSpaceApartRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
}));

export const SubTitle = styled('div')(() => ({
  textAlign: 'left',
  fontSize: '16px',
  fontWeight: 500,
  marginTop: '24px',
  marginBottom: '16px',
}));

const StyledSelect = styled(Select)(({ theme: { themeColors } }) => ({
  width: '220px',
  '& label.Mui-focused': {
    color: themeColors.neutral60,
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: themeColors.neutral60,
    borderWidth: '1px',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: themeColors.neutral60,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: themeColors.neutral60,
    borderWidth: '1px',
  },
  '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    borderColor: themeColors.listIconColor,
  },
}));

const GLOBAL_CLIENT_GROUP = 'Global';
export const FeatureToggleView = ({
  clientGroups,
  toggles,
  onSave,
  onToggleChange,
  onToggleDelete,
  onEnableToggleForAllClientGroups,
  onDisableToggleForAllClientGroups,
}) => {
  const [selectedClientGroup, setSelectedClientGroup] =
    useState(GLOBAL_CLIENT_GROUP);
  const [modalState, setModalState] = useState(null);
  const selections = useMemo(
    () => [...clientGroups, GLOBAL_CLIENT_GROUP],
    [clientGroups]
  );
  const handleOnChange = (event) => {
    setSelectedClientGroup(event.target.value);
  };

  const handleOnToggleChange = (updatedToggle) => {
    onToggleChange([updatedToggle]);
  };

  const handleNewToggleOpen = () => {
    setModalState('NEW_TOGGLE_CREATION');
  };

  const handleNewToggleClose = () => {
    setModalState(null);
  };
  return (
    <ConfigMainContainer>
      <CreateToggleModal
        isOpen={modalState !== null}
        onClose={handleNewToggleClose}
        onSave={onSave}
      />
      <FlexSpaceApartRow>
        <ConfigViewTitle>Feature Toggles </ConfigViewTitle>
        <Button variant="contained" onClick={handleNewToggleOpen}>
          Create New Toggle
        </Button>
      </FlexSpaceApartRow>

      <StyledSelect
        defaultValue=""
        value={selectedClientGroup}
        onChange={handleOnChange}
        displayEmpty
      >
        {selections.map((clientGroup) => (
          <MenuItem key={clientGroup} value={clientGroup}>
            {clientGroup}
          </MenuItem>
        ))}
      </StyledSelect>
      <SubTitle>Release Toggles</SubTitle>
      {toggles
        .filter((toggle) => toggle.type.toUpperCase() === 'RELEASE')
        .map((toggle) => (
          <FeatureToggleTile
            key={toggle.name}
            name={toggle.name}
            description={toggle.description}
            isEnabled={toggle.enabledFor.includes(selectedClientGroup)}
            isGlobal={selectedClientGroup === 'Global'}
            handleOnToggleChange={handleOnToggleChange}
            selectedClientGroup={selectedClientGroup}
            onToggleDelete={onToggleDelete}
            onEnableToggleForAllClientGroups={onEnableToggleForAllClientGroups}
            onDisableToggleForAllClientGroups={
              onDisableToggleForAllClientGroups
            }
          />
        ))}
      <SubTitle>Module Toggles</SubTitle>
      {toggles
        .filter((toggle) => toggle.type.toUpperCase() === 'MODULE')
        .map((toggle) => (
          <FeatureToggleTile
            key={toggle.name}
            name={toggle.name}
            description={toggle.description}
            isEnabled={toggle.enabledFor.includes(selectedClientGroup)}
            isGlobal={selectedClientGroup === GLOBAL_CLIENT_GROUP}
            handleOnToggleChange={handleOnToggleChange}
            selectedClientGroup={selectedClientGroup}
            onToggleDelete={onToggleDelete}
            onEnableToggleForAllClientGroups={onEnableToggleForAllClientGroups}
            onDisableToggleForAllClientGroups={
              onDisableToggleForAllClientGroups
            }
          />
        ))}
    </ConfigMainContainer>
  );
};
