import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import { PowerScore } from '@odaia/shared-components/src/powerScore';
import { useContext } from 'react';
import { PowerScorePopover } from '../../../../components/fieldView/PowerscorePopover';
import useFeatureToggles from '../../../../hooks/useFeatureToggles';
import { isAdminAccount } from '../../../../request/config';
import { AppContext } from '../../../application/appContext';
import { FieldContext } from '../../../application/appViews/field/fieldContext';
import { EntityOverviewContext } from '../../data/entityOverviewContext';
import { findRowDataInfo } from '../drawer/drawerHelpers';
import { addSpacesBetweenSeparators } from '../../../../utils/formatEntityName';

export const EntityTableScoreCell = ({
  row,
  maptualDisplayScore,
  maptualScoreChange,
}) => {
  const theme = useTheme();
  const featureToggles = useFeatureToggles();

  const { profile } = useContext(AppContext);

  const showPowerScoreExplainability =
    featureToggles(profile.userGroup, 'showPowerScoreExplainability') ||
    isAdminAccount(profile);

  if (!showPowerScoreExplainability) {
    return (
      <Box ml={theme.spacing(2)}>
        <PowerScore
          score={maptualDisplayScore}
          scoreChangeValue={maptualScoreChange}
        />
      </Box>
    );
  }

  const { maptualListMetadata } = useContext(FieldContext);
  const { objectiveId, entityType } = useContext(EntityOverviewContext);

  const sfMaptualListId = maptualListMetadata?.sfMaptualListId;
  const locality = maptualListMetadata?.locality || 'N/A';

  const entityName = findRowDataInfo(Object.entries(row), 'name');
  const formattedEntityName = addSpacesBetweenSeparators(
    entityName.toUpperCase()
  );
  const entityId = findRowDataInfo(Object.entries(row), 'id');

  return (
    <Box ml={theme.spacing(2)}>
      <PowerScorePopover
        powerscore={maptualDisplayScore}
        locality={locality}
        regionId={sfMaptualListId}
        objectiveId={objectiveId}
        entityType={entityType}
        entityId={entityId}
        entityName={formattedEntityName}
        shouldPreloadData={false}
      />
    </Box>
  );
};
