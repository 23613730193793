import moment from 'moment'

export const formatLastContactedDate = (lastContactedDate: string) => {
    if (!lastContactedDate) return 'Never Contacted';
    return moment(lastContactedDate).format('MMM D, YYYY');
  };

  export const formatLastContactedDateRelative = (lastContactedDate: string) => {
    if (!lastContactedDate) return 'Never Contacted';
    return moment(lastContactedDate).fromNow();
  };


export const formatToTimeAgo = (lastConfactedMetadata) => {
  if (!lastConfactedMetadata) return "Never contacted";
  const contactedDate = new Date(lastConfactedMetadata.split("-")[0].trim());
  const timeAgo = moment([contactedDate.getFullYear(), contactedDate.getMonth(), contactedDate.getDate()]);
  const contactMethod = lastConfactedMetadata.split("-")[1].trim();

  return `${contactMethod} ${timeAgo.fromNow()}`;

}
