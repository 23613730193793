import { useContext, useEffect, useState, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { styled, Box, Select, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useQuery } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import { cloneDeep } from 'lodash';
import { StyledFieldTitle } from './styles/styledComponents';
import {
  NewProjectContext,
  ObjectiveModalActionsContext,
} from './newProjectContext';
import MetricRenderer from './metrics/metricRenderer';
import BasketHeaderFields from './basketHeaderFields';
import { ProductTree } from './productTree';
import { getAvailableMetrics } from '../../request/newProjectRequests';
import { ShareMetricsProvider } from './metrics/shareMetricsProvider';
import { DropdownWithChips } from './dropdownWithChips';

const SpinnerContainer = styled('div')({
  padding: '15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const ObjectiveFormWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gridGap: 24,
});

const ProductTreeWrapper = styled(Box)({
  overflow: 'auto',
});

const StyledFieldSubtitle = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.neutral60,
  })
);

const DropdownWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const StyledFormControl = styled(FormControl)({
  width: '60%',
});

const StyledInputLabel = styled(InputLabel)(({ theme: { themeColors } }) => ({
  '&.Mui-focused': { color: themeColors.neutral60 },
}));

const DropdownPlaceholder = styled(InputLabel)(
  ({ theme: { themeColors } }) => ({
    '&.Mui-focused': { color: themeColors.primary99 },
  })
);

const StyledSelect = styled(Select)(({ theme: { themeColors } }) => ({
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: 1,
    borderColor: themeColors.neutral60,
  },
}));

const ChipRow = styled(Box)({
  display: 'flex',
  gap: 8,
  flexWrap: 'wrap',
});

const StyledChip = styled(Chip)(({ theme: { themeColors } }) => ({
  fontSize: 14,
  borderColor: themeColors.borderMidContrast,
  color: themeColors.primary99,
  '.MuiChip-deleteIcon': {
    color: themeColors.neutral60,
  },
}));

const SHORT_FORM_BASKET_TYPES = {
  PRODUCT_FROM: 'from',
  TO_PRODUCT: 'to',
  PRECURSOR_PRODUCT: 'precursor',
  ANALOGUE_PRODUCT: 'analogue',
};

const CustomMenuItem = ({
  productLines,
  selectedProducts,
  setSelectedProducts,
  onProductChange,
  setProductMenuDropdownDirectionFlip,
}) => (
  <ProductTreeWrapper>
    <ProductTree
      productLineTree={productLines}
      selectedProducts={selectedProducts}
      setSelectedProducts={setSelectedProducts}
      onProductChange={onProductChange}
      setProductMenuDropdownDirectionFlip={setProductMenuDropdownDirectionFlip}
    />
  </ProductTreeWrapper>
);

const addParamsToAvailableMetrics = (
  basketConfig,
  perEntityAvailableMetrics,
  perEntityDefaultScoringWeights,
  basketType
) =>
  Object.keys(basketConfig.metrics).length > 0
    ? syncMetricsWithState(perEntityAvailableMetrics, basketConfig)
    : addDefaultMetricValues(
        perEntityAvailableMetrics,
        perEntityDefaultScoringWeights,
        basketType
      );

const syncMetricsWithState = (availableMetrics, basketConfig) => {
  const updatedMetrics = cloneDeep(availableMetrics);
  Object.entries(availableMetrics).forEach(([entityType, metrics]) => {
    updatedMetrics[entityType] = metrics.map((metric) => {
      let metricState = {};
      if (basketConfig.metrics[entityType]) {
        metricState = basketConfig.metrics[entityType].find(
          (m) =>
            m.metricRxType === metric.metricRxType &&
            m.rxSourceType === metric.rxSourceType
        );
      }
      return {
        ...metric,
        scoringWeight: metricState?.scoringWeight || 0,
        visualize: metricState?.visualize,
      };
    });
  });
  return updatedMetrics;
};

const addDefaultMetricValues = (
  availableMetrics,
  scoringWeights,
  basketType
) => {
  const updatedMetrics = cloneDeep(availableMetrics);

  Object.keys(updatedMetrics).forEach((entityType) => {
    updatedMetrics[entityType] = updatedMetrics[entityType].map((metric) => {
      const weight =
        scoringWeights[entityType]?.[basketType]?.[metric.metricRxType];
      return {
        ...metric,
        scoringWeight: weight || 0,
        visualize: weight > 0,
      };
    });
  });

  return updatedMetrics;
};

const ProductBasketModalForm = ({
  basketKey,
  basketName,
  basketType,
  basketText,
  basketConfig,
  setBasketConfig,
  isPreLoaded,
  setIsError,
}) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [metrics, setMetrics] = useState({});
  const { therapeuticAreas, indications, specialties } =
    useContext(NewProjectContext);
  const { setObjectiveModalErrors } = useContext(ObjectiveModalActionsContext);
  const [isMetricAvailabilityLoading, setIsMetricAvailabilityLoading] =
    useState(false);
  const [shareMetricsProviderLoading, setShareMetricsProviderLoading] =
    useState(false);

  const { updateBaskets, productLines } = useContext(
    ObjectiveModalActionsContext
  );
  const { projectConfig, metricsScoringWeight, entityMetricsScoringWeight } =
    useContext(NewProjectContext);
  const metricParams = {
    productIds: basketConfig.products.map((product) => product.productId),
    therapeuticAreas: basketConfig.therapeuticAreas,
    indications: basketConfig.indications,
    productLine: projectConfig.productLineId,
    filterCriteria: 'volume',
  };

  const queryCacheKey = Object.values({
    ...metricParams,
    basketName: basketConfig.basketName,
  })
    .map((value) => JSON.stringify(value))
    .join('_');
  const { refetch, isError } = useQuery(
    queryCacheKey,
    async ({ signal }) => {
      setIsMetricAvailabilityLoading(true);
      const response = await getAvailableMetrics({ ...metricParams, signal });
      const testAvailableMetrics = response.data;

      const testScoringWeights =
        entityMetricsScoringWeight || metricsScoringWeight;

      const availableMetrics = addParamsToAvailableMetrics(
        basketConfig,
        testAvailableMetrics,
        testScoringWeights,
        SHORT_FORM_BASKET_TYPES[basketType]
      );
      setMetrics(availableMetrics);

      const newBasketState = {
        ...basketConfig,
        metrics: availableMetrics,
      };
      setBasketConfig(newBasketState);
      updateBaskets(newBasketState);
      setIsMetricAvailabilityLoading(false);
    },
    { enabled: false }
  );

  useEffect(() => {
    if (isError) {
      setIsMetricAvailabilityLoading(false);
    }
  }, [isError]);

  useEffect(() => {
    if (!isPreLoaded) {
      setBasketConfig({
        ...basketConfig,
        basketKey,
        basketName,
        basketType,
        basketText,
        uuid: uuidv4(),
      });
    }
  }, []);

  useEffect(() => {
    setSelectedProducts(basketConfig.products);
  }, [basketConfig]);

  useEffect(() => {
    if (basketConfig.products.length > 0) {
      refetch();
    }
  }, [
    useMemo(() => basketConfig.products, [basketConfig.products]),
    useMemo(
      () => basketConfig.therapeuticAreas,
      [basketConfig.therapeuticAreas]
    ),
    useMemo(() => basketConfig.indications, [basketConfig.indications]),
    projectConfig.productLineId,
  ]);

  useEffect(() => {
    setObjectiveModalErrors((prev) => ({
      ...prev,
      metricAvailabilityLoading:
        isMetricAvailabilityLoading || shareMetricsProviderLoading,
    }));
  }, [isMetricAvailabilityLoading, shareMetricsProviderLoading]);

  const handleOnChange = (newState) => {
    setBasketConfig(newState);
    updateBaskets(newState);
  };

  const handleOnProductChange = (newProducts) => {
    const newBasketState = { ...basketConfig, products: newProducts };
    setBasketConfig(newBasketState);
    if (basketConfig.basketKey) updateBaskets(newBasketState);
  };

  const handleDeleteProduct = (deletedProduct) => {
    const newSelectedProducts = selectedProducts.filter(
      (item) => item.productId !== deletedProduct.productId
    );
    setSelectedProducts(newSelectedProducts);
    handleOnChange({ ...basketConfig, products: newSelectedProducts });
  };

  const handleIndicationsChange = (value) => {
    handleOnChange({
      ...basketConfig,
      indications: value,
    });
  };

  const handleSpecialtiesChange = (value) => {
    handleOnChange({
      ...basketConfig,
      specialties: value,
    });
  };

  const handleTherapeuticAreasChange = (value) => {
    handleOnChange({
      ...basketConfig,
      therapeuticAreas: value,
    });
  };

  const [
    productMenuDropdownDirectionFlip,
    setProductMenuDropdownDirectionFlip,
  ] = useState(false);

  const ProductMenuProps = {
    PaperProps: {
      style: {
        maxHeight: '95%',
        overflow: 'auto',
        top: '0',
      },
    },
    anchorOrigin: {
      vertical: productMenuDropdownDirectionFlip ? 'top' : 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: productMenuDropdownDirectionFlip ? 'bottom' : 'top',
      horizontal: 'left',
    },
  };

  return (
    <ObjectiveFormWrapper>
      <BasketHeaderFields
        basketConfig={basketConfig}
        setBasketConfig={setBasketConfig}
        handleOnChange={handleOnChange}
        setIsError={setIsError}
      />
      <div>
        <StyledFieldTitle variant="h5">Basket Configurations</StyledFieldTitle>
        <StyledFieldSubtitle>
          Assign items to the configurations below to view available metrics
        </StyledFieldSubtitle>
      </div>
      <DropdownWrapper>
        <StyledFormControl focused>
          <StyledInputLabel shrink>Products</StyledInputLabel>
          <DropdownPlaceholder shrink={false}>
            Select Products
          </DropdownPlaceholder>
          <StyledSelect
            multiple
            value={[]}
            onChange={() => {
              handleOnChange({
                ...basketConfig,
                products: selectedProducts,
              });
            }}
            label="Products"
            MenuProps={ProductMenuProps}
          >
            <CustomMenuItem
              productLines={productLines}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              onProductChange={handleOnProductChange}
              setProductMenuDropdownDirectionFlip={
                setProductMenuDropdownDirectionFlip
              }
            />
          </StyledSelect>
        </StyledFormControl>
        {selectedProducts.length > 0 && (
          <ChipRow>
            {selectedProducts.map((product) => (
              <StyledChip
                key={product}
                value={product}
                variant="outlined"
                label={product.productName.toUpperCase()}
                deleteIcon={<CloseIcon />}
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
                onDelete={() => handleDeleteProduct(product)}
              />
            ))}
          </ChipRow>
        )}
      </DropdownWrapper>
      <DropdownWithChips
        items={therapeuticAreas}
        selectedItems={basketConfig.therapeuticAreas}
        setSelectedItems={handleTherapeuticAreasChange}
        inputLabel="Select Therapeutic Areas"
        placeholder="Therapeutic Areas"
      />
      <DropdownWithChips
        items={indications}
        selectedItems={basketConfig.indications}
        setSelectedItems={handleIndicationsChange}
        inputLabel="Select Indications (Optional)"
        placeholder="Indications"
      />
      <DropdownWithChips
        items={specialties}
        selectedItems={basketConfig.specialties}
        setSelectedItems={handleSpecialtiesChange}
        inputLabel="Select Specialties (Optional)"
        placeholder="Specialties"
        disabled={isMetricAvailabilityLoading}
      />
      {basketType === 'PRODUCT_FROM' && (
        <ShareMetricsProvider
          setShareMetricsProviderLoading={setShareMetricsProviderLoading}
        />
      )}
      {isMetricAvailabilityLoading && (
        <SpinnerContainer>
          <CircularProgress />
        </SpinnerContainer>
      )}
      {Object.keys(metrics).length &&
      !isMetricAvailabilityLoading &&
      selectedProducts.length > 0 ? (
        <MetricRenderer
          metricsList={metrics}
          basketType={SHORT_FORM_BASKET_TYPES[basketType]}
          basketConfig={basketConfig}
          setBasketConfig={setBasketConfig}
        />
      ) : null}
    </ObjectiveFormWrapper>
  );
};

export default ProductBasketModalForm;
