import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSearchParams } from '../../../hooks/useSearchParams';
import {
  useModuleConfigSchemaQuery,
  useModuleConfigSchemaValuesQuery,
} from '../../../request/admin/queries';
import { useUpdateModuleConfigValuesMutation } from '../../../request/admin/mutations';

type PathParams = {
  userGroup?: string;
  marketId?: string;
  schemaName: string;
};

export function useMutateInstance(isCreate: boolean = false) {
  const { schemaName } = useParams<PathParams>();

  const history = useHistory();
  const searchParams = useSearchParams();
  const userGroup = searchParams.get('userGroup');
  const marketId = searchParams.get('marketId');
  const [showJsonData, setShowJsonData] = useState(false);

  const [formData, setFormData] = useState(null);

  const { data: schemaData, isLoading: isLoadingSchema } =
    useModuleConfigSchemaQuery(schemaName);

  const getValuesArgs = isCreate
    ? {
        schemaName,
      }
    : {
        schemaName,
        userGroup,
        marketId,
      };
  const { data: defaultValues, isLoading: isLoadingDefaultValues } =
    useModuleConfigSchemaValuesQuery(getValuesArgs);

  const navigateBack = () => {
    history.goBack();
  };

  const moduleConfigValuesMutation = useUpdateModuleConfigValuesMutation();

  useEffect(() => {
    setFormData(defaultValues);
  }, [defaultValues]);

  const onSave = () => {
    moduleConfigValuesMutation.mutate({
      requestBody: formData,
      schemaName,
      configUserGroup: userGroup,
      marketId,
    });
  };

  useEffect(() => {
    if (moduleConfigValuesMutation.isSuccess) {
      setTimeout(() => {
        navigateBack();
      }, 3000);
    }
  }, [moduleConfigValuesMutation.isSuccess]);

  const toggleJsonData = () => {
    setShowJsonData((prev) => !prev);
  };

  return {
    schemaName,
    userGroup,
    formData,
    setFormData,
    schemaData,
    isLoadingSchema,
    isLoadingDefaultValues,
    onCancel: navigateBack,
    onSave,
    isSaving: moduleConfigValuesMutation.isLoading,
    savedSuccessfully: moduleConfigValuesMutation.isSuccess,
    showJsonData,
    toggleJsonData,
  };
}
