import { useContext, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import Chart from 'react-apexcharts';

import { numberWithCommas } from '../../../modules/commonModule';
import { AppContext } from '../../../containers/application/appContext';
import TrendValue from '../../fieldView/trendValues';

const graphHeight = 220;
const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    color: '#000000',
  },
  graphAuxContainer: {
    width: '100%',
    height: 'auto',
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 15,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  graphAuxItemContainer: {
    marginRight: 15,
    display: 'flex',
    alignItems: 'center',
  },
  auxKeyText: {
    width: 'auto',
    marginRight: 10,
    fontSize: 14,
    fontWeight: 600,
    color: themeColors.secondaryTextColorHighContrast,
  },
  auxValueText: {
    width: 'auto',
    marginRight: 0,
    fontSize: 14,
    fontWeight: 400,
    color: themeColors.primaryTextColor,
  },
  singleAuxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
  },
}));

export default function DataMultilineSeries({
  data,
  rowSelected,
  dropdownSelection,
  rowsHidden = {},
  customHeight = graphHeight,
}) {
  const classes = useStyles();
  const { themeColors } = useTheme();

  const { profile } = useContext(AppContext);
  const [showToolbar, setShowToolbar] = useState(false);

  useEffect(() => {
    if (
      profile.role.type !== 'UNKNOWN' &&
      typeof profile.role.permission.ALLOW_CSV_DOWNLOAD === 'boolean'
    ) {
      /**
       * TODO: Using ALLOW_CSV_DOWNLOAD to determine whether we should
       *  show the toolbar (that contains more than just CSV) is misleading.
       *
       *  Action: we should change the userProfileRole response
       */
      setShowToolbar(profile.role.permission.ALLOW_CSV_DOWNLOAD);
    }
  }, [profile.role]);

  const { dataList } = data;

  const timeSeries = dataList ? dataList.multiTimeSeries : null;
  const selectedSeries =
    timeSeries && timeSeries[rowSelected] ? timeSeries[rowSelected] : null;
  const series = selectedSeries ? selectedSeries.series : [];

  const listItems = dataList ? dataList.listItems : null;
  const selectedList =
    listItems && listItems[rowSelected] ? listItems[rowSelected] : null;
  const auxData = selectedList ? selectedList.auxData : {};

  const allSeriesNoAvg = Array.isArray(timeSeries)
    ? timeSeries.map((item) => {
        if (item && Array.isArray(item.series)) return item.series[0];
        return [];
      })
    : null;
  const hiddenFilter = (item, i) =>
    rowsHidden[`${dropdownSelection}drop${i}`] !== true;

  const allVisibleSeries = allSeriesNoAvg.filter(hiddenFilter);

  const colourStartIndex = 5;

  const colorArray = [...themeColors.graphLegendColors].slice(
    colourStartIndex,
    allSeriesNoAvg.length + colourStartIndex
  );
  const visibleColorArray = colorArray.filter(hiddenFilter);
  let dashArray = [];

  if (
    series &&
    series[1] &&
    !rowsHidden[`${dropdownSelection}drop${rowSelected}`]
  ) {
    const avgColor = `${
      colorArray[rowSelected] ? colorArray[rowSelected] : colorArray[0]
    }88`;

    visibleColorArray.splice(rowSelected + 1, 0, avgColor);
    allVisibleSeries.splice(rowSelected + 1, 0, series[1]);
    dashArray = Array(allVisibleSeries.length - 1).fill(0);
    dashArray.splice(rowSelected + 1, 0, 1);
  }

  const xCategories = selectedSeries?.xaxis?.categories || null;

  const strokeType = 'straight';

  const options = {
    chart: {
      type: 'line',
      toolbar: {
        show: showToolbar,
      },
      animations: {
        enabled: false,
      },
    },
    colors: [...visibleColorArray],
    dataLabels: {
      enabled: false,
    },

    stroke: {
      curve: strokeType,
      width: 2,
      dashArray: dashArray || [],
    },
    grid: {
      show: true,
      borderColor: themeColors.contentCardBorderColor,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
    },

    xaxis: {
      tickPlacement: 'between',
      labels: {
        show: true,
        hideOverlappingLabels: true,
        style: {
          colors: themeColors.secondaryTextColor,
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 400,
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: xCategories || [],
    },
    yaxis: {
      forceNiceScale: true,
      labels: {
        formatter: numberWithCommas,
        show: true,
        hideOverlappingLabels: true,
        style: {
          colors: themeColors.secondaryTextColor,
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 400,
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      theme: 'dark',
      style: {
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: true,
        formatter: undefined,
      },
      y: {
        formatter: numberWithCommas,
      },
      marker: {
        show: true,
      },
      fixed: {
        enabled: false,
        position: 'topRight',
      },
    },
  };

  return (
    <div className={classes.root}>
      <div className={classes.graphAuxContainer}>
        {Object.keys(auxData).map((key) => (
          <div className={classes.graphAuxItemContainer} key={key}>
            <Typography className={classes.auxKeyText} noWrap align="left">
              {key}
            </Typography>

            {Object.keys(auxData[key] || []).map((item) => (
              <div className={classes.singleAuxContainer} key={item}>
                <Typography
                  className={classes.auxValueText}
                  noWrap
                  align="left"
                >
                  {`${item}:`}
                </Typography>
                {auxData[key][item] ? (
                  <TrendValue
                    type={auxData[key][item].type}
                    value={String(auxData[key][item].value)}
                    useZero
                  />
                ) : null}
              </div>
            ))}
          </div>
        ))}
      </div>
      <Chart
        options={options}
        series={allVisibleSeries}
        type="line"
        height={customHeight}
        width="100%"
        margin="10"
      />
    </div>
  );
}
