export const useProfilePreferences = ({ project, preference }) => {
  let projectId = null;

  let projectMetadataPref = null;
  let maptualListMetadataPref = null;
  let maptualListSegmentPref = null;
  let entityMetadataPref = null;

  projectId = project?.metadata?.projectId || null;

  if (projectId && projectId !== preference?.lastProjectMetadata?.projectId) {
    projectMetadataPref = project.metadata;
    maptualListMetadataPref = null;
  } else {
    projectMetadataPref = preference?.lastProjectMetadata || null;
    maptualListMetadataPref = preference?.lastMaptualListMetadata || null;
  }

  maptualListSegmentPref = preference?.lastSegmentSelection || null;
  entityMetadataPref = preference?.lastEntityMetadata || null;

  return {
    projectMetadataPref,
    maptualListMetadataPref,
    maptualListSegmentPref,
    entityMetadataPref,
  };
};
