import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { forwardRef } from 'react';
import * as ViewerAction from '../../actions/projectViewActions';
import { trimUrl } from '../../modules/commonModule';
import { baseInstance } from '../../request/config';

const mapStateToProps = (state) => ({
  viewer: state.projectViewer,
});

const mapDispatchToProps = (dispatch) => ({
  getAnalysis: (analysisId, userId = null) =>
    dispatch(ViewerAction.getAnalysis(analysisId, userId)),
  getProject: (projectId) =>
    dispatch(ViewerAction.getProjectFromAPI(projectId)),
  setProjectItem: (item) => dispatch(ViewerAction.setProjectItem(item)),
  setFullAnalysisItem: (item) =>
    dispatch(ViewerAction.setFullAnalysisItem(item)),
  setAnalysisExpanded: (item) =>
    dispatch(ViewerAction.setAnalysisExpanded(item)),
});

const openExternalUrl = async (url, setLoading = () => {}) => {
  setLoading(true);
  baseInstance
    .get(url, { timeout: 90000 })
    .then((response) => {
      setLoading(false);
      window.open(response.data, '_blank');
      return response;
    })
    .catch(() => {
      setLoading(false);
    });
};

function LinkControllerUnconnected({
  type,
  analysisId,
  linkLocation,
  linkUrl,
  userId = null,
  myRef,
  setLoading,
  getAnalysis,
  match,
}) {
  const routeHistory = useHistory();

  const baseUrl =
    (match?.params?.projectId &&
      match.url &&
      (match.params.externalAnalysisId
        ? match.url.replace(match.params.externalAnalysisId, '')
        : `${trimUrl(match.url)}/`)) ||
    null;

  const handleClick = () => {
    // eslint-disable-next-line no-console
    console.log('clicked');
    if (type === 'ANALYSIS_HYPERLINK') {
      if (linkLocation.toUpperCase() === 'HCP_EXTERNAL' && analysisId) {
        if (userId) {
          window.open(`/hcp/${analysisId}/${userId}`, '_blank');
        } else {
          routeHistory.push(`/hcp/${analysisId}`);
        }
      } else if (linkLocation.toUpperCase() === 'EXTERNAL' && analysisId) {
        if (userId) {
          window.open(`${baseUrl}${analysisId}?userId=${userId}`, '_blank');
        } else {
          getAnalysis(analysisId);
          routeHistory.push(`${baseUrl}${analysisId}`);
        }
      }
    } else if (type === 'URL_HYPERLINK') {
      if (linkLocation.toUpperCase() === 'EXTERNAL' && linkUrl) {
        openExternalUrl(linkUrl, setLoading);
      }
    }
  };

  return type ? <div ref={myRef} onClick={handleClick} /> : null;
}

const LinkControllerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkControllerUnconnected);

const LinkController = forwardRef((props, ref) => (
  <LinkControllerConnected {...props} myRef={ref} />
));
LinkController.displayName = 'LinkController';

export default LinkController;
