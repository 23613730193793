import {
  useCallback,
  useContext,
  useMemo,
  Suspense,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  styled,
} from '@mui/material';
import { useQuery } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import {
  ModalDisplay,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '../generic/modalDisplay';
import { ModalDisplayCustomStyle } from './styles/styledComponents';
import {
  NewProjectContext,
  ObjectiveModalActionsContext,
} from './newProjectContext';
import { getProductLines } from '../../request/newProjectRequests';
import ObjectiveDropdownGroup from './objectives/objectiveDropdownGroup';
import { PROJECT_CREATION_STEPS } from './common/constants';

const StyledModalBody = styled(ModalBody)({
  padding: '0 0 10% 0',
});

const LoadingScreenContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: 16,
  minHeight: 356,
});

const LoadingScreenText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
}));

const LoadingScreen = () => (
  <LoadingScreenContainer>
    <CircularProgress />
    <LoadingScreenText variant="body2">
      Loading Objective Configurations
    </LoadingScreenText>
  </LoadingScreenContainer>
);

const initialObjectiveModalErrorsState = {
  noBaskets: false,
  emptyBasketName: false,
  invalidBasketScoringWeight: false,
  metricAvailabilityLoading: false,
};

export default function NewObjectiveModal({ isOpen }) {
  const {
    setProjectCreationStep,
    projectConfig,
    objectives,
    setObjectives,
    objectiveType,
    baskets,
    setBaskets,
    objectiveBeingEdited,
    setObjectiveBeingEdited,
    shareMetric,
    setShareMetric,
    nonScoringObjective,
    setNonScoringObjective,
  } = useContext(NewProjectContext);

  const [objectiveModalErrors, setObjectiveModalErrors] = useState(
    initialObjectiveModalErrorsState
  );

  const [canSaveObjectiveModal, setCanSaveObjectiveModal] = useState(false);

  const productLines = useQuery(projectConfig.productLineId, async () => {
    const response = await getProductLines(projectConfig.productLineId);
    return JSON.parse(response.data);
  }).data;

  useEffect(() => {
    if (objectiveBeingEdited) {
      const objective = objectives.find(
        (obj) => obj.uuid === objectiveBeingEdited
      );
      setBaskets(objective.baskets);
    }
  }, [objectiveBeingEdited]);

  useEffect(() => {
    const noBaskets = !baskets.length;
    const missingBasketName = baskets
      .map((basket) => basket.basketName)
      .includes('');
    setObjectiveModalErrors((prev) => ({
      ...prev,
      noBaskets,
      missingBasketName,
    }));
  }, [baskets]);

  useEffect(() => {
    setCanSaveObjectiveModal(
      !Object.values(objectiveModalErrors).some(
        (errorState) => errorState === true
      )
    );
  }, [objectiveModalErrors]);

  const hasUniqueEntityMetrics = (basket) => {
    if (!basket.metrics) {
      return true;
    }
    return Object.entries(basket.metrics).every(([_, metrics]) => {
      const entityMetricScoringWeights = metrics
        .map((metric) => metric.scoringWeight)
        .filter((scoringWeight) => scoringWeight > 0);
      const uniqueEntityMetrics = new Set(entityMetricScoringWeights);
      return entityMetricScoringWeights.length === uniqueEntityMetrics.size;
    });
  };

  const hasConflictingEntityMetrics = useMemo(() => {
    if (!baskets.length) {
      return false;
    }
    return !baskets.every((basket) => hasUniqueEntityMetrics(basket));
  }, [baskets]);

  const handleClose = () => {
    setBaskets([]);
    setObjectiveBeingEdited('');
    setObjectiveModalErrors(initialObjectiveModalErrorsState);
    setShareMetric(null);
    setNonScoringObjective(false);
    setProjectCreationStep(PROJECT_CREATION_STEPS.PROJECT);
  };
  const updateBaskets = useCallback((newBasket) => {
    setBaskets((previousBaskets) => {
      const temp = [...previousBaskets];

      const existingBasketIndex = previousBaskets.findIndex(
        (basket) => basket.basketKey === newBasket.basketKey
      );

      if (existingBasketIndex !== -1) {
        temp.splice(existingBasketIndex, 1, newBasket);
      } else {
        temp.push(newBasket);
      }

      return temp;
    });
  }, []);

  const deleteBasket = useCallback(
    (basketKey, uuid) => {
      setBaskets((previousBaskets) => {
        let res = previousBaskets.filter(
          (basket) => basket.basketKey !== basketKey
        );
        if (uuid) {
          res = res.filter((basket) => basket.uuid !== uuid);
        }
        return res;
      });
    },
    [baskets]
  );

  const handleSaveObjective = () => {
    if (objectiveBeingEdited) {
      const updatedObjective = {
        objectiveType,
        baskets,
        uuid: objectiveBeingEdited,
        ...(shareMetric?.uuid ? { shareMetric } : {}),
        nonScoringObjective,
      };
      const updatedObjectives = objectives.map((objective) => {
        if (objective.uuid === objectiveBeingEdited) {
          return updatedObjective;
        }
        return objective;
      });
      setObjectives(updatedObjectives);
      setObjectiveBeingEdited('');
    } else {
      const newObjective = {
        objectiveType,
        baskets,
        uuid: uuidv4(),
        shareMetric,
        nonScoringObjective,
      };
      setObjectives([...objectives, newObjective]);
    }
    setBaskets([]);
    setProjectCreationStep(PROJECT_CREATION_STEPS.PROJECT);
    setShareMetric(null);
    setNonScoringObjective(false);
  };

  const objectiveFormContextValues = useMemo(
    () => ({
      updateBaskets,
      deleteBasket,
      productLines,
      objectiveModalErrors,
      setObjectiveModalErrors,
    }),
    [
      updateBaskets,
      deleteBasket,
      productLines,
      objectiveModalErrors,
      setObjectiveModalErrors,
    ]
  );

  if (objectiveBeingEdited && !baskets.length) {
    return null;
  }

  return (
    <ObjectiveModalActionsContext.Provider value={objectiveFormContextValues}>
      <ModalDisplay isOpen={isOpen} style={ModalDisplayCustomStyle}>
        <ModalHeader onClose={handleClose}>
          {projectConfig.projectName}: New Objective
        </ModalHeader>
        <Suspense fallback={<LoadingScreen />}>
          <StyledModalBody>
            <ObjectiveDropdownGroup preloadBaskets={baskets} />
          </StyledModalBody>
        </Suspense>
        <ModalFooter>
          <Button onClick={handleClose} variant="outlined">
            {baskets.length ? 'Discard Changes' : 'Back to project'}
          </Button>
          <Button
            disabled={!canSaveObjectiveModal || hasConflictingEntityMetrics}
            onClick={handleSaveObjective}
            variant="contained"
          >
            Save objective
          </Button>
        </ModalFooter>
      </ModalDisplay>
    </ObjectiveModalActionsContext.Provider>
  );
}
