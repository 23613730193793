import { useMemo, useState } from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { styled, Switch as MuiSwitch } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '@odaia/ui/src/theme';
import { SentryRoute } from '../../../sentryRoute';
import { ROUTES } from '../../../constants';
import LoginHandler from '../loginHandler';
import NavBar from '../../../components/app/navBar';
import { ContentWrapper } from '../contentWrapper';
import ProjectViewer from '../../projectviewer/projectViewer';
import PhysicianView from '../../physicianview/physicianView';
import IngestionGuide from '../../ingestionguide/IngestionGuide';
import DataConnectorView from '../../../components/dataconnectors/dataconnectors';
import ConstructionView from '../../../components/constructionview/constructionView';
import { UserManagementView } from '../../../components/usermanagement/userManagementView';
import { NAVBAR_DRAWER_WIDTH } from './constants';
import CurationReports from './curation';
import { CsvDeliveryContainer } from './csvDelivery/CsvDeliveryContainer';
import { AdminContext } from './adminContext';
import { getDemoAccountStatus } from '../../../request/config';
import { FeatureToggleViewProvider } from '../../../components/featureToggle/FeatureToggleViewProvider';
import { ConfigViewProvider } from '../../../components/configview/configViewProvider';
import DataReport from './dataReport';
import AppConfig from '../../../components/appConfig/appConfig';

export const StyledHeader = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px 3vw 15px 15px',
  borderBottom: '1px solid',
  borderColor: themeColors.borderMidContrast,
}));

export const UserGroupTitle = styled('div')(({ theme: { themeColors } }) => ({
  color: themeColors.menuTextColor,
  fontWeight: 500,
  fontSize: 20,
}));

const Container = styled('div')(({ theme: { themeColors } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: NAVBAR_DRAWER_WIDTH,
  background: themeColors.mainBackground,
  borderColor: themeColors.borderMidContrast,
  overflowX: 'auto',
  color: 'white',
}));

const StyledContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Admin = ({ profile, appView }) => {
  const isDemoAccount = getDemoAccountStatus();

  const [isProjectBuilderDemoMode, setIsProjectBuilderDemoMode] =
    useState(true);
  const [
    showProjectBuilderDemoModeToggle,
    setShowProjectBuilderDemoModeToggle,
  ] = useState(false);

  const contextValues = useMemo(
    () => ({
      isProjectBuilderDemoMode,
      setIsProjectBuilderDemoMode,
      setShowProjectBuilderDemoModeToggle,
    }),
    [
      isProjectBuilderDemoMode,
      setIsProjectBuilderDemoMode,
      setShowProjectBuilderDemoModeToggle,
    ]
  );

  return (
    <ThemeProvider theme={theme}>
      <AdminContext.Provider value={contextValues}>
        <Router>
          <SentryRoute
            path={ROUTES.ADMIN}
            render={(innerProps) => (
              <NavBar
                navBarWidth={NAVBAR_DRAWER_WIDTH}
                appView={appView}
                {...innerProps}
              />
            )}
          />
          <Container>
            {!isDemoAccount && (
              <StyledHeader>
                <StyledContainer>
                  <UserGroupTitle>{profile.userGroup}</UserGroupTitle>
                </StyledContainer>
                {showProjectBuilderDemoModeToggle && (
                  <MuiSwitch
                    checked={isProjectBuilderDemoMode}
                    onClick={() => {
                      setIsProjectBuilderDemoMode(!isProjectBuilderDemoMode);
                    }}
                  />
                )}
              </StyledHeader>
            )}
            <ContentWrapper>
              <Switch>
                <SentryRoute
                  path={`${ROUTES.ADMIN}${ROUTES.PROJECTS}`}
                  render={(innerProps) => (
                    <ProjectViewer {...innerProps} profile={profile} />
                  )}
                />
                <SentryRoute exact path={ROUTES.ROOT}>
                  <Redirect to={`${ROUTES.ADMIN}${ROUTES.PROJECTS}`} />
                </SentryRoute>
                <SentryRoute exact path={ROUTES.INDEX_HTML}>
                  <Redirect to={`${ROUTES.ADMIN}${ROUTES.PROJECTS}`} />
                </SentryRoute>
                <SentryRoute exact path={ROUTES.ADMIN}>
                  <Redirect to={`${ROUTES.ADMIN}${ROUTES.PROJECTS}`} />
                </SentryRoute>
                <SentryRoute path={ROUTES.PROJECTS}>
                  <Redirect to={`${ROUTES.ADMIN}${ROUTES.PROJECTS}`} />
                </SentryRoute>
                <SentryRoute path={ROUTES.USERS}>
                  <Redirect to={`${ROUTES.ADMIN}${ROUTES.USERS}`} />
                </SentryRoute>
                <SentryRoute
                  exact
                  path={ROUTES.LOGIN}
                  render={() => (
                    <LoginHandler
                      redirectTo={`${ROUTES.ADMIN}${ROUTES.PROJECTS}`}
                    />
                  )}
                />
                <SentryRoute
                  path={`${ROUTES.ADMIN}/hcp/:analysisId/:physicianId?`}
                  render={(innerProps) => (
                    <PhysicianView {...innerProps} profile={profile} />
                  )}
                />
                <SentryRoute
                  path={`${ROUTES.ADMIN}/datasets/adddata/`}
                  render={(innerProps) => (
                    <IngestionGuide {...innerProps} startIndex={0} />
                  )}
                />
                <SentryRoute
                  path={`${ROUTES.ADMIN}/datasets/dataconnectors/`}
                  render={(innerProps) => (
                    <DataConnectorView {...innerProps} profile={profile} />
                  )}
                />
                <SentryRoute
                  path={`${ROUTES.ADMIN}/settings/`}
                  render={(innerProps) => <ConstructionView {...innerProps} />}
                />
                <SentryRoute
                  path={`${ROUTES.ADMIN}/users/`}
                  render={(innerProps) => (
                    <UserManagementView {...innerProps} />
                  )}
                />
                <SentryRoute
                  path={`${ROUTES.ADMIN}/config/`}
                  render={(innerProps) => (
                    <ConfigViewProvider {...innerProps} profile={profile} />
                  )}
                />
                <SentryRoute
                  path={`${ROUTES.ADMIN}${ROUTES.APP_CONFIG}`}
                  render={(innerProps) => (
                    <AppConfig {...innerProps} profile={profile} />
                  )}
                />
                <SentryRoute
                  path={`${ROUTES.ADMIN}/FeatureToggleView`}
                  render={(innerProps) => (
                    <FeatureToggleViewProvider
                      {...innerProps}
                      profile={profile}
                    />
                  )}
                />
                <SentryRoute
                  path={ROUTES.ADMIN_CURATION}
                  render={(innerProps) => <CurationReports {...innerProps} />}
                />
                <SentryRoute
                  path={ROUTES.ADMIN_CSV_DELIVERY}
                  render={(innerProps) => (
                    <CsvDeliveryContainer {...innerProps} />
                  )}
                />
                <SentryRoute
                  path={`${ROUTES.ADMIN}${ROUTES.DATA_REPORT}`}
                  render={(innerProps) => <DataReport {...innerProps} />}
                />
              </Switch>
            </ContentWrapper>
          </Container>
        </Router>
      </AdminContext.Provider>
    </ThemeProvider>
  );
};
