import { useContext } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material';
import EmptyView from '../../emptyview/emptyView';
import TableView from './tableView';
import CustomToolbar from './customToolbar/customToolbar';
import { trackSphereColumnVisibilityToggle } from '../../../trackers/mixpanel';
import useSphereContext from '../useSphereContext';
import { getTableViewColumnWithChangedVisibility } from '../helpers';
import { defaultTablePreferences } from './defaultTablePreferences';
import useColumnPreferences from '../../../hooks/useColumnPreferences';
import { useHCPTableData } from '../../../hooks/useHCPTableData';
import { ROUTES } from '../../../constants';
import { ObjectiveContext } from '../contexts/objectiveContext';
import { AppContext } from '../../../containers/application/appContext';

import { useUpdateUserPreferencesMutation } from '../../../request/mutations';

const TableViewContentRoot = styled('div')(() => ({
  maxHeight: '100%',
  width: '100%',
  overflow: 'hidden',
}));

const TableViewContainer = ({ selectedRegion, isBusy }) => {
  const { profile } = useContext(AppContext);
  const sphereContext = useSphereContext();
  const { data: ObjectiveTableData, isLoading: ObjectiveTableDataLoading } =
    useHCPTableData();
  const match = useRouteMatch(`${ROUTES.SPHERE}${ROUTES.PROJECTS}/:projectId`);
  const currentProjectPreferences = useColumnPreferences();
  const { selectedObjective } = useContext(ObjectiveContext);
  const updateUserPreferencesMutation = useUpdateUserPreferencesMutation();
  let isError = false;

  const tableRows = ObjectiveTableData?.rows;
  const tableColumns = ObjectiveTableData?.columns;

  if (!tableRows || !tableColumns) {
    isError = true;
  }

  const handleColumnVisibilityModelChange = (newVisibilityModel) => {
    if (match?.params?.projectId) {
      const newColumnsPreferences = {
        ...profile?.preference?.columnPreferences,
      };
      newColumnsPreferences[match.params.projectId] = {
        ...newColumnsPreferences[match.params.projectId],
        visibility: newVisibilityModel,
      };

      if (
        Object.values(newVisibilityModel).some(
          (isVisible) => isVisible === true
        )
      ) {
        const [columnName, isVisible] = getTableViewColumnWithChangedVisibility(
          {
            newList:
              Object.keys(newVisibilityModel).length === 0
                ? Object.keys(defaultTablePreferences.visibility).reduce(
                    (cols, col) => ({ ...cols, [col]: true }),
                    {}
                  )
                : newVisibilityModel,
            oldList:
              currentProjectPreferences?.visibility ||
              defaultTablePreferences.visibility,
          }
        );

        trackSphereColumnVisibilityToggle({
          ...sphereContext,
          columnName,
          isVisible,
        });
      }

      updateUserPreferencesMutation.mutate({
        columnPreferences: newColumnsPreferences,
      });
    }
  };

  const handleColumnOrderModelChange = (changedOrder) => {
    if (match?.params?.projectId && currentProjectPreferences?.order) {
      const newPreferences = { ...profile?.preference?.columnPreferences };
      const newOrderModel = [];
      currentProjectPreferences.order.forEach((col, index) => {
        if (newOrderModel.length === changedOrder.targetIndex) {
          newOrderModel.push(
            currentProjectPreferences.order[changedOrder.oldIndex]
          );
        }

        if (index !== changedOrder.oldIndex) {
          newOrderModel.push(col);
        }
      });

      newPreferences[match.params.projectId] = {
        ...newPreferences[match.params.projectId],
        order: newOrderModel,
      };
      // TEMP disabled for demo
      // TODO: fix
      // onColumnPreferenceChange(newPreferences);
      // saveTableColumnPreference(profile, newPreferences);
    }
  };

  const handleColumnFilterModelChange = (newFilterModel) => {
    if (match?.params?.projectId) {
      const newColumnPreferences = {
        ...profile?.preference?.columnPreferences,
      };
      newColumnPreferences[match.params.projectId] = {
        ...newColumnPreferences[match.params.projectId],
        filter: newFilterModel,
      };
      updateUserPreferencesMutation.mutate({
        columnPreferences: newColumnPreferences,
      });
    }
  };
  const isLoading = isBusy || !selectedObjective || ObjectiveTableDataLoading;

  if (isLoading)
    return (
      <Fade in timeout={600} key="loading">
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <EmptyView isLoading={isLoading} />
        </div>
      </Fade>
    );

  if (isError)
    return (
      <Fade in timeout={600} key="loading">
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <EmptyView />
        </div>
      </Fade>
    );

  return (
    <Fade in timeout={600} key="table">
      <TableViewContentRoot>
        <TableView
          columns={tableColumns}
          rows={tableRows}
          columnOrderModel={currentProjectPreferences?.order}
          columnVisibilityModel={currentProjectPreferences?.visibility}
          columnFilterModel={currentProjectPreferences?.filter}
          selectedRegion={selectedRegion}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          toolbar={CustomToolbar}
          onColumnOrderChange={handleColumnOrderModelChange}
          onColumnFilterChange={handleColumnFilterModelChange}
        />
      </TableViewContentRoot>
    </Fade>
  );
};

const mapStateToProps = (state) => ({
  selectedRegion: state.projectViewer.selectedRegion,
  isBusy: state.projectViewer.isTableRowsBusy,
});

export default connect(mapStateToProps)(TableViewContainer);
