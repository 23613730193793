import { useQuery } from 'react-query';
import { EntityType } from '@odaia/clients/maptualAPI';
import { getObjectiveBaskets } from '../../../../../request/projectRequests';

export const useObjectiveBaskets = ({
  projectId,
  entityType,
}: {
  projectId?: string;
  entityType: EntityType;
}) => {
  const {
    data: basketsByObjective,
    isError: isBasketsByObjectiveError,
    isFetching: isBasketsByObjectiveFetching,
    isLoading: isBasketsByObjectiveLoading,
  } = useQuery(
    [projectId, entityType],
    async () => {
      if (projectId) {
        const response = await getObjectiveBaskets({ projectId, entityType });
        return response?.data;
      }
      return null;
    },
    { enabled: Boolean(projectId && entityType) }
  );

  return {
    basketsByObjective,
    isBasketsByObjectiveError,
    isBasketsByObjectiveFetching,
    isBasketsByObjectiveLoading,
  };
};
