import { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { styled, Box } from '@mui/material';
import { CADENCE_SINGLE_NAME_MAPPING } from '@odaia/domain/src';
import { Controls } from './table/controls/controls';
import { trackPulseTeamView } from '../../../../../../../trackers/mixpanel';
import { PulseContext } from '../../../context/PulseContext';
import { getProjectObjectives } from '../../../../../../../request/projectRequests';
import { useRegions } from '../regionSelector/useRegions';
import { AppContext } from '../../../../../appContext';
import { InfiniteEntitiesTable } from '../../../../../../shared/entitiesTable/InfiniteEntitiesTable';
import { RepRowProvider } from './table/row/repRowProvider';

const TableContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
  padding: 16,
  overflow: 'hidden',
}));

const activityColumns = [
  {
    id: 'listCoverage',
    label: 'List Coverage',
    subLabel: '% of curated list called',
    message: 'What percentage of the curated list was called during that week.',
    visible: true,
    width: 140,
  },
  {
    id: 'callCoverage',
    label: 'Call Coverage',
    subLabel: '% of calls that were curated HCPs',
    message: `What percentage of the total calls were curated HCPs. 
    
    A curated HCP is an HCP that was called during the week they were curated.`,
    visible: true,
    width: 140,
  },
  {
    id: 'totalCalls',
    subLabel: 'Total Calls',
    visible: true,
    width: 120,
  },
  {
    id: 'nonCuratedCalls',
    subLabel: 'Non Curated Calls',
    visible: true,
    width: 120,
  },
];

const initialColumns = [
  {
    id: 'name',
    label: 'Name',
    visible: true,
    width: 150,
  },
];

export const RepTableProvider = ({ timestamp }) => {
  const [objectiveId, setObjectiveId] = useState();
  const [metric, setMetric] = useState();
  const [cadences, setCadences] = useState([]);
  const [cadence, setCadence] = useState();
  const [metricType, setMetricType] = useState([]);
  const [metricTypes] = useState(['volume', 'share']);
  const { projectId, regionId } = useParams();
  const { marketName, productLineId, projectTargetProducts } =
    useContext(PulseContext);
  const { profile } = useContext(AppContext);

  const { data: objectives } = useQuery(projectId, async () => {
    try {
      const response = await getProjectObjectives({ projectId });
      return response.data;
    } catch {
      return [];
    }
  });

  const metricColumns = [
    {
      id: 'volume',
      label: `${metric?.name || 'Loading'}`,
      sublabel: `${metric?.name || ''} Volume`,
      visible: metricType?.includes('volume'),
      width: 140,
    },
    {
      id: 'share',
      label: `${metric?.name || 'Loading'}`,
      sublabel: `${metric?.name || ''} Share`,
      visible: metricType?.includes('share'),
      width: 140,
    },
  ];

  const { data: allRegions } = useRegions({
    productLineId,
    territories: [],
    isHookEnabled: !!productLineId,
  });

  useEffect(() => {
    if (
      !objectiveId ||
      !objectives ||
      !regionId ||
      !allRegions ||
      allRegions.length === 0 ||
      !marketName ||
      !profile?.userGroup
    )
      return;
    trackPulseTeamView({
      userGroup: profile.userGroup,
      marketName,
      objectiveName:
        objectives.find((objective) => objective.id === objectiveId)?.title ||
        'no objective selected',
      districtName: allRegions?.find((i) => i.entity_id === regionId)?.listName,
    });
  }, [objectiveId, objectives, regionId, allRegions, marketName, profile]);

  const updateFilterObjective = (newObjectiveId) => {
    if (newObjectiveId !== objectiveId) {
      setCadences([]);
      setMetric(null);
      setObjectiveId(newObjectiveId);
    }
  };

  const updateFilterMetric = (newMetric) => {
    if (newMetric.type !== metric) {
      setMetric(newMetric);
      setCadences(newMetric.cadence);
    }
  };

  const updateFilterCadence = (newCadence) => {
    if (newCadence !== cadence) {
      setCadence(newCadence);
    }
  };

  const updateFilterMetricType = (newMetricType) => {
    if (newMetricType !== metricType) {
      setMetricType(newMetricType);
    }
  };

  return (
    <TableContainer>
      <Controls
        updateFilterObjective={updateFilterObjective}
        updateFilterMetric={updateFilterMetric}
        updateFilterCadence={updateFilterCadence}
        updateFilterMetricType={updateFilterMetricType}
        objectiveId={objectiveId}
        metric={metric}
        cadences={cadences}
        metricTypes={metricTypes}
      />
      <InfiniteEntitiesTable
        objectiveId={objectiveId}
        timestamp={timestamp}
        cadence={cadence}
        metric={metric}
        metricType={metricType}
        regionId={regionId}
        RowContentComponent={RepRowProvider}
        stickyColumns={initialColumns}
        metricColumns={metricColumns}
        activityColumns={activityColumns}
        showAverageRow
        activitySubtitle={`Latest ${CADENCE_SINGLE_NAME_MAPPING[cadence]}`}
        activityCadence={cadence}
        showPowerscore={false}
        productLineId={productLineId}
        projectTargetProducts={projectTargetProducts}
        projectId={projectId}
      />
    </TableContainer>
  );
};
