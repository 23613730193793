import {
  EntityTableCustomerSegmentCell,
  EntityTableBehaviouralSegmentCell,
} from './tableCell/EntityTableSegmentCell';
import { EntityTableNameCell } from './tableCell/EntityTableNameCell';
import { EntityTableTableDefaultCell } from './tableCell/EntityTableDefaultCell';
import { EntityTableScoreCell } from './tableCell/EntityTableScoreCell';
import { TABLE_HEADER_TYPES_ENUM } from '../constants';
import { EntityTableDrawerCell } from './tableCell/EntityTableDrawerCell';

interface Trend {
  status: number;
  type: string;
  value: number;
}

interface Cell {
  data: any;
  trend?: Trend;
}

interface EntityTableCellProps {
  row: Cell[];
  cell: Cell;
  type: TABLE_HEADER_TYPES_ENUM;
  graph: 'line' | 'bar';
  chartLabels: string[];
  label: string;
  subLabel: string;
  chartTitlePrefix: string;
}

export const EntityTableCell = ({
  row,
  cell,
  type,
  graph,
  chartLabels,
  label,
  subLabel,
  chartTitlePrefix,
}: EntityTableCellProps) => {
  const { data, trend } = cell;

  const props = {
    data,
    trend,
    graph,
    chartLabels,
    label,
    subLabel,
    chartTitlePrefix,
  };

  switch (type) {
    case TABLE_HEADER_TYPES_ENUM.LABEL:
      return (
        <EntityTableNameCell entityName={data?.name} address={data?.address} />
      );

    case TABLE_HEADER_TYPES_ENUM.SCORE:
      return (
        <EntityTableScoreCell
          row={row}
          maptualDisplayScore={data?.maptualDisplayScore}
          maptualScoreChange={data?.maptualScoreChange}
        />
      );

    case TABLE_HEADER_TYPES_ENUM.NUMERICAL_BADGE:
    case TABLE_HEADER_TYPES_ENUM.CUSTOMER_SEGMENT:
      return <EntityTableCustomerSegmentCell data={data} />;

    case TABLE_HEADER_TYPES_ENUM.BEHAVIOURAL_SEGMENT:
      return <EntityTableBehaviouralSegmentCell data={data} />;

    case TABLE_HEADER_TYPES_ENUM.DRAWER:
      return <EntityTableDrawerCell />;

    default:
      return <EntityTableTableDefaultCell type={type} {...props} />;
  }
};
