import ReactJson from 'react-json-view';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from 'react-query';
import { getUserGroupDataReport } from '../../../request/projectRequests';
import { Wrapper } from '../../../components/projectviewer/projectInformation/generic';
import EmptyView from '../../../components/emptyview/emptyView';

const useStyles = makeStyles(({ themeColors }) => ({
  headerContainer: {
    background: themeColors.mainBackground,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px',
    margin: 0,
    color: '#E0E4EB',
    fontWeight: 600,
    font: 'Roboto',
  },
  mainContainer: {
    padding: '16px 32px',
  },
  jsonContainer: {
    display: 'flex',
    textAlign: 'left',
  },
}));

const getDataReport = async () => {
  const dataReport = await getUserGroupDataReport();
  return dataReport;
};

export default function DataReport() {
  const classes = useStyles();
  const reactJsonStyle = {
    padding: '10px',
    borderRadius: '3px',
    margin: '10px 0px',
  };

  const { isLoading: isUserGroupDataReportLoading, data: userGroupDataReport } =
    useQuery([`user-group-data-report`], () => getDataReport());

  if (isUserGroupDataReportLoading) {
    return (
      <Wrapper data-testid="projectOverviewLoading">
        <EmptyView isLoading />
      </Wrapper>
    );
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.headerContainer}>
        <h1 className={classes.title}>Data Report</h1>
      </div>
      <div className={classes.jsonContainer}>
        <ReactJson
          src={userGroupDataReport?.data}
          theme="isotope"
          iconStyle="circle"
          indentWidth={4}
          collapsed={1}
          style={reactJsonStyle}
        />
      </div>
    </div>
  );
}
