import { useEffect, useState } from 'react';
import { Box, Button, styled, Typography } from '@mui/material';
import ModeIcon from '@mui/icons-material/Mode';
import { cloneDeep } from 'lodash';
import { findProductById, ProductTreeHierarchy } from './userConfigProductTree';
import { NodeEdit } from '../nodeEdit';
import { DETAIL_DISPLAY } from '../detailDisplay';
import { NodeDeleteModal } from '../nodeDeleteModal';
import { TwoPaneLayout } from './styledComponents';
import { useIsAuthorized } from '../../../hooks/useIsAuthorized';
import { RBAC_PERMISSIONS } from '../../../constants';

export function ProductTreeDetails({
  productTree,
  setDisplayTreeDetails,
  editExistingTree,
  totalProductsList,
  isTreeUpdating,
}) {
  const [selectedTreeNode, setSelectedTreeNode] = useState({
    productId: '',
    shouldHide: true,
  });
  const [editedNode, setEditedNode] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [productTreeInState, setProductTreeInState] = useState(productTree);

  useEffect(() => {
    setProductTreeInState(productTree);
    if (selectedTreeNode.productId) {
      const newSelectedNode = findProductById(
        productTree.productTree,
        selectedTreeNode.productId
      );
      if (newSelectedNode) {
        setSelectedTreeNode(newSelectedNode);
      } else {
        setSelectedTreeNode({ productId: '', shouldHide: true });
      }
    }
  }, [JSON.stringify(productTree)]);

  const handlePlaceholderDelete = (nodeId) => {
    const newProductTree = cloneDeep(productTreeInState);
    const nodeToRemove = findProductById(newProductTree.productTree, nodeId);
    if (nodeToRemove.isNewNode) {
      removeById(newProductTree.productTree, nodeId);
      setProductTreeInState(newProductTree);
    }
    setSelectedTreeNode({ productId: '', shouldHide: true });
  };

  function removeById(tree, targetProductId) {
    if (tree.productId === targetProductId) {
      return null;
    }
    // eslint-disable-next-line no-param-reassign
    tree.subCategories = tree.subCategories
      .map((subCategory) => removeById(subCategory, targetProductId))
      .filter(Boolean);

    return tree;
  }

  return (
    <TwoPaneLayout>
      <ProductTreeHierarchy
        productTree={productTreeInState}
        setProductTreeInState={setProductTreeInState}
        selectedTreeNode={selectedTreeNode}
        setSelectedTreeNode={setSelectedTreeNode}
        editedNode={editedNode}
        setEditedNode={setEditedNode}
        setDisplayTreeDetails={setDisplayTreeDetails}
      />
      {editedNode ? (
        <NodeEdit
          editedNode={editedNode}
          node={findProductById(productTreeInState.productTree, editedNode)}
          setEditedNode={setEditedNode}
          handlePlaceholderDelete={handlePlaceholderDelete}
          setDisplayTreeDetails={setDisplayTreeDetails}
          attachedMarketId={productTreeInState.marketId}
          editExistingTree={editExistingTree}
          isTreeUpdating={isTreeUpdating}
          totalProductsList={totalProductsList}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
        />
      ) : (
        <ProductTreeNodeMetadata
          productTreeNode={selectedTreeNode}
          setEditedNode={setEditedNode}
          setDisplayTreeDetails={setDisplayTreeDetails}
        />
      )}
      <NodeDeleteModal
        isOpen={isDeleteModalOpen}
        node={findProductById(productTreeInState.productTree, editedNode)}
        attachedMarketId={productTreeInState.marketId}
        onClose={() => setIsDeleteModalOpen(false)}
        editExistingTree={editExistingTree}
        isTreeUpdating={isTreeUpdating}
        setEditedNode={setEditedNode}
      />
    </TwoPaneLayout>
  );
}

const StyledNotSelectedContainer = styled(Box)(
  ({ theme: { themeColors } }) => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    color: themeColors.neutral60,
  })
);

const StyledProductContainer = styled(Box)({
  flex: 1,
  padding: 24,
  textAlign: 'left',
  overflowY: 'scroll',
});

const HeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledEditButton = styled(Button)(({ theme: { themeColors } }) => ({
  '&:hover': {
    color: themeColors.adminCardHeader,
    cursor: 'pointer',
  },
}));

const SecondaryText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
}));

const StyledEditIcon = styled(ModeIcon)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
  fontSize: '16px',
  marginLeft: '4px',
}));

const BodyWrapper = styled('div')({
  marginTop: 16,
});

const StyledBodyText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
}));

function ProductTreeNodeMetadata({
  productTreeNode,
  setEditedNode,
  setDisplayTreeDetails,
}) {
  const hasConfigAdminPermissions = useIsAuthorized([
    RBAC_PERMISSIONS.USER_GROUP_CONFIG_ADMIN,
  ]);

  if (!productTreeNode || productTreeNode.shouldHide) {
    return (
      <StyledNotSelectedContainer>
        Select a node to view information
      </StyledNotSelectedContainer>
    );
  }
  return (
    <StyledProductContainer>
      <HeaderWrapper>
        <Typography variant="h5">{productTreeNode.productName}</Typography>
        {hasConfigAdminPermissions && (
          <StyledEditButton
            onClick={() => {
              setEditedNode(productTreeNode.productId);
              setDisplayTreeDetails(DETAIL_DISPLAY.DISABLE);
            }}
          >
            <SecondaryText variant="body2">Edit</SecondaryText>
            <StyledEditIcon />
          </StyledEditButton>
        )}
      </HeaderWrapper>
      <BodyWrapper>
        <StyledBodyText variant="body2">
          Product Name: {productTreeNode.productName}
        </StyledBodyText>
        <StyledBodyText variant="body2">
          Friendly Name: {productTreeNode.friendlyName}
        </StyledBodyText>
        <StyledBodyText variant="body2">
          Level: {productTreeNode.level ? productTreeNode.level : 'N/A'}
        </StyledBodyText>
        <StyledBodyText variant="body2">
          Product ID: {productTreeNode.productId}
        </StyledBodyText>
      </BodyWrapper>
    </StyledProductContainer>
  );
}
