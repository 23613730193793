export const CADENCE_NAME_MAPPING = {
  wk: 'weekly',
  mth: 'monthly',
  qtr: 'quarterly',
};

export const CADENCE_SINGLE_NAME_MAPPING = {
  wk: 'Week',
  mth: 'Month',
  qtr: 'Quarter',
};

export const CADENCE = {
  DAY: 'day',
  WEEK: 'wk',
  MONTH: 'mth',
  QUARTER: 'qtr',
  PREV_QUARTER: 'prev_qtr',
  YEAR: 'yr',
};
