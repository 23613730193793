import {
  GridToolbarExportContainer,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import MenuItem from '@mui/material/MenuItem';
import { trackSphereTableViewExport } from '../../../../trackers/mixpanel';
import useSphereContext from '../../useSphereContext';

const GridCsvExportMenuItem = ({ hideMenu, options, onClick }) => {
  const apiRef = useGridApiContext();

  return (
    <MenuItem
      onClick={() => {
        apiRef.current.exportDataAsCsv(options);
        hideMenu?.();
        onClick();
      }}
    >
      {apiRef.current.getLocaleText('toolbarExportCSV')}
    </MenuItem>
  );
};

const GridPrintExportMenuItem = ({ hideMenu, options, onClick }) => {
  const apiRef = useGridApiContext();

  return (
    <MenuItem
      onClick={() => {
        apiRef.current.exportDataAsPrint(options);
        hideMenu?.();
        onClick();
      }}
    >
      {apiRef.current.getLocaleText('toolbarExportPrint')}
    </MenuItem>
  );
};

const CustomExportButton = () => {
  const apiRef = useGridApiContext();
  const sphereContext = useSphereContext();

  const handleCsvClick = () => {
    trackSphereTableViewExport({
      exportType: apiRef.current.getLocaleText('toolbarExportCSV'),
      segmentName: sphereContext.segmentName,
      maptualListSelectionLabel: sphereContext.maptualListSelectionLabel,
    });
  };
  const handlePrintClick = () => {
    trackSphereTableViewExport({
      exportType: apiRef.current.getLocaleText('toolbarExportPrint'),
      segmentName: sphereContext.segmentName,
      maptualListSelectionLabel: sphereContext.maptualListSelectionLabel,
    });
  };

  return (
    <GridToolbarExportContainer>
      <GridCsvExportMenuItem onClick={handleCsvClick} />
      <GridPrintExportMenuItem onClick={handlePrintClick} />
    </GridToolbarExportContainer>
  );
};

export default CustomExportButton;
