import { Button, MenuItem, OutlinedInput, Stack, styled } from '@mui/material';
import { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { isEqual } from 'lodash';
import {
  ModalBody,
  ModalDisplay,
  ModalFooter,
  ModalHeader,
} from '../generic/modalDisplay';
import { StyledFormControl, StyledSelect } from './styles/styledComponents';
import { ModalSectionHeader } from '../generic/modalSectionHeader';
import { PROJECT_CREATION_STEPS } from './common/constants';
import { NewProjectContext } from './newProjectContext';
import { DeployDialogContext } from '../projectviewer/deployCsvDialog/deployDialogContext';
import { IMPORT_TYPES } from './newProjectTransformation';

const StyledModalBody = styled(ModalBody)({
  gridGap: 24,
});

const StyledDescription = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '120%',
  color: themeColors.neutral60,
}));

const FullWidthStyledSelect = styled(StyledSelect)({
  width: '100%',
});

export const AssetMigrationModal = ({ projects, isOpen, onClose }) => {
  const { activeProject } = useContext(DeployDialogContext);
  const { setProjectCreationStep, setProjectConfig } =
    useContext(NewProjectContext);
  const defaultValues = {
    project: {},
    cloneWithoutMigration: false,
  };
  const { control, setValue, watch, handleSubmit, reset } = useForm({
    mode: 'all',
    defaultValues,
  });
  const onClick = handleSubmit((formData) => {
    setProjectConfig((prev) => ({
      ...prev,
      importType: formData.cloneWithoutMigration
        ? IMPORT_TYPES.NONE
        : IMPORT_TYPES.CLONE_WITH_SCORES,
      projectImportId: formData.project.projectId,
      migratedProjectName: formData.project.projectName,
    }));
    setProjectCreationStep(PROJECT_CREATION_STEPS.PROJECT);
  });
  const formValues = watch();
  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };
  const canContinue =
    (formValues.project.projectId || formValues.cloneWithoutMigration) &&
    !isEqual(defaultValues, formValues);
  return (
    <ModalDisplay isOpen={isOpen} style={{ maxWidth: '480px' }}>
      <ModalHeader onClose={handleClose}>Run Project Setup</ModalHeader>
      <StyledModalBody>
        <Stack spacing={2}>
          <ModalSectionHeader
            title="Run Project Setup"
            subtitle="Select an existing project to migrate computed assets from"
          />
          <Controller
            name="project"
            control={control}
            render={({ field }) => (
              <StyledFormControl>
                <FullWidthStyledSelect
                  {...field}
                  input={<OutlinedInput notched />}
                  onChange={(event) => {
                    field.onChange(event);
                    setValue('cloneWithoutMigration', false);
                  }}
                  displayEmpty
                  renderValue={(value) =>
                    value?.projectName || 'Select Project'
                  }
                >
                  {projects
                    .filter(
                      (project) =>
                        project.marketId === activeProject.marketId &&
                        project.deployed
                    )
                    .map((project) => (
                      <MenuItem key={project.projectId} value={project}>
                        {project.projectName}
                      </MenuItem>
                    ))}
                </FullWidthStyledSelect>
              </StyledFormControl>
            )}
          />
        </Stack>
        <Stack gap={2} flexDirection="row" alignItems="flex-start">
          <Controller
            name="cloneWithoutMigration"
            control={control}
            render={({ field }) => (
              <Radio
                {...field}
                checked={field.value === true}
                onChange={(event) => {
                  setValue('project', {});
                  field.onChange(event.target.checked);
                }}
                size="small"
              />
            )}
          />
          <Stack alignItems="flex-start">
            <Typography variant="body1">
              Continue without migrating assets
            </Typography>
            <StyledDescription>
              Project will run from template without any existing assets
            </StyledDescription>
          </Stack>
        </Stack>
      </StyledModalBody>
      <ModalFooter>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button disabled={!canContinue} onClick={onClick} variant="contained">
          Continue
        </Button>
      </ModalFooter>
    </ModalDisplay>
  );
};
