import { styled, Box, Button, Typography } from '@mui/material';
import { PowerScore } from '@odaia/shared-components/src/powerScore';
import { useContext } from 'react';
import { PowerScorePopover } from '../../components/fieldView/PowerscorePopover';
import { FieldContext } from '../application/appViews/field/fieldContext';
import { isAdminAccount } from '../../request/config';
import { AppContext } from '../application/appContext';
import useFeatureToggles from '../../hooks/useFeatureToggles';

const StyledTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme: { themeColors }, isSelected }) => ({
  color: isSelected
    ? themeColors.primaryTextColor
    : themeColors.buttonContentColor,
}));

export const StyledMultiObjectivesRow = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'objectiveCount',
})(({ theme, objectiveCount }) => ({
  width: '100%',
  gridGap: theme.spacing(1),
  display: 'grid',
  gridTemplateColumns:
    objectiveCount >= 4 ? '1fr 1fr' : `repeat(${objectiveCount}, 1fr)`,
}));

const ObjectiveCard = styled(Button)(
  ({ theme: { themeColors, spacing }, showPowerScoreExplainability }) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    justifyContent: 'start',
    gap: spacing(1),
    alignItems: 'center',
    color: themeColors.buttonContentColor,
    backgroundColor: themeColors.buttonBackgroundColor,
    border: `1px solid ${themeColors.contentCardBorderColor}`,
    borderRadius: 5,
    padding: showPowerScoreExplainability ? 0 : 16,
    ':hover, &.Mui-focusVisible': {
      background: themeColors.buttonBackgroundColor,
    },
    '&.selectedObjective': {
      backgroundColor: themeColors.buttonActiveBackgroundColor,
      color: themeColors.buttonActiveColor,
      border: `1px solid ${themeColors.buttonActiveBorderColor}`,
    },
    '&.Mui-focusVisible': {
      borderColor: 'white',
    },
  })
);

const ObjectiveInfo = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  textAlign: 'left',
  flexGrow: 1,
  padding: 8,
}));

const ObjectiveWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'applyMargins',
})(({ applyMargins }) => ({
  textAlign: 'left',
  marginTop: applyMargins.top ? 16 : 0,
  marginBottom: applyMargins.bottom ? 16 : 0,
  width: '100%',
}));

export const MultiObjectivesRow = ({
  objectives,
  handleClick,
  selectedObjective,
  locality = 'N/A',
  applyMargins = { top: true, bottom: true },
  entityType,
  entityId,
  entityName,
  isNonDld = false,
  isAmaOptOut = false,
}) => {
  const { profile } = useContext(AppContext);
  const { maptualListMetadata } = useContext(FieldContext);

  const sfMaptualListId = maptualListMetadata?.sfMaptualListId;

  const featureToggles = useFeatureToggles();
  const showPowerScoreExplainability =
    featureToggles(profile.userGroup, 'showPowerScoreExplainability') ||
    isAdminAccount(profile);

  return (
    <ObjectiveWrapper
      className="intercom-field-objective-scores"
      data-testid="insightsObjectiveScores"
      applyMargins={applyMargins}
    >
      <StyledMultiObjectivesRow objectiveCount={objectives.length}>
        {objectives.map((obj) => (
          <ObjectiveCard
            color="secondary"
            focusRipple={false}
            key={`objective_card_${obj.id}`}
            onClick={() => !showPowerScoreExplainability && handleClick(obj)}
            className={
              selectedObjective.id === obj.id ? 'selectedObjective' : ''
            }
            objectiveCount={objectives.length}
            showPowerScoreExplainability={showPowerScoreExplainability}
          >
            {obj.maptualDisplayScore !== -1 &&
              (showPowerScoreExplainability ? (
                <PowerScorePopover
                  powerscore={Math.round(obj.maptualDisplayScore)}
                  objectiveId={obj.id}
                  regionId={sfMaptualListId}
                  locality={locality}
                  entityType={entityType}
                  entityId={entityId}
                  entityName={entityName}
                  isAmaOptOut={isAmaOptOut}
                  isNonDld={isNonDld}
                />
              ) : (
                <PowerScore
                  score={Math.round(obj.maptualDisplayScore)}
                  isSelected={selectedObjective.id === obj.id}
                />
              ))}

            <ObjectiveInfo onClick={() => handleClick(obj)}>
              <StyledTitle
                variant="title2"
                isSelected={selectedObjective.id === obj.id}
              >
                {obj.label}
              </StyledTitle>
            </ObjectiveInfo>
          </ObjectiveCard>
        ))}
      </StyledMultiObjectivesRow>
    </ObjectiveWrapper>
  );
};
