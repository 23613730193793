import { Bar } from 'react-chartjs-2';
import { useTheme } from '@mui/styles';

export const BarGraph = ({ labels, datasets }) => {
  const { themeColors } = useTheme();
  const options = {
    responsive: false,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: themeColors.chartDataLabel,
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: themeColors.chartDataLabel,
        },
        grid: {
          display: true,
          color: themeColors.chartLines,
        },
        stacked: true,
      },
    },
  };
  return (
    <Bar
      options={options}
      data={{ labels, datasets }}
      width="208px"
      height="165px"
    />
  );
};
