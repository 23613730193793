import { styled, Chip } from "@mui/material";

export const StyledPrimaryChip = styled(Chip)(
  ({ theme: { themeColors }, selected }) => ({
    "&.MuiChip-colorPrimary": {
      backgroundColor: themeColors.chipChoiceActiveSurface,
    },
    backgroundColor: selected
      ? themeColors.chipChoiceActiveSurface
      : themeColors.chipChoiceDefaultSurface,
    color: selected
      ? themeColors.chipChoiceActiveContent
      : themeColors.chipChoiceDefaultContent,
    borderColor: selected
      ? themeColors.chipChoiceActiveBorder
      : themeColors.chipChoiceDefaultBorder,
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "22px",
    "&:hover": {
      opacity: 1,
      backgroundColor: themeColors.chipChoiceHoverSurface,
      color: themeColors.chipChoiceHoverContent,
      borderColor: themeColors.chipChoiceHoverBorder,
    },
  })
);
