import { useQuery } from 'react-query';
import { maptualApiInstance } from '../../../../../request/config';

export const useEntitySegments = ({
  productLineId,
  projectId,
  objectiveId,
  regionId,
  entityId,
}) => {
  const queryCacheKey = Object.values({
    key: 'entity-segments',
    productLineId,
    projectId,
    objectiveId,
    regionId,
    entityId,
  });
  return useQuery(queryCacheKey, async () => {
    try {
      const response = await getEntitySegments({
        productLineId,
        projectId,
        objectiveId,
        regionId,
        entityId,
      });
      return response.data;
    } catch {
      return null;
    }
  });
};

export function getEntitySegments({
  productLineId,
  projectId,
  objectiveId,
  regionId,
  entityId,
}) {
  if (!productLineId || !projectId || !regionId || !objectiveId || !entityId) {
    return Promise.reject();
  }
  return maptualApiInstance.get(
    `pulse/segments/product-lines/${productLineId}/projects/${projectId}/objectives/${objectiveId}/regions/${regionId}/entities/${entityId}`,
    {
      timeout: 600000,
    }
  );
}
