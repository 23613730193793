import { useState, useContext } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { Typography, Button, styled } from '@mui/material';
import { UserMenuButton } from './userMenuButton';
import {
  ModalDisplay,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '../../generic/modalDisplay';
import { ThemeContext } from '../../../containers/application/themeContext';
import ChipSelectionInput from '../../usermanagement/formInputs/chipSelectionInput';

const BodyContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 0px',
  marginRight: 'auto',
}));

const TextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Title = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '120%',
  marginBottom: '4px',
}));

const StyledChipSelectionContainer = styled('div')(() => ({
  width: '100%',
}));

const BodyText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tertiaryColor,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '120%',
}));

const CloseButton = styled(Button)(({ theme: { themeColors } }) => ({
  height: '32px',
  backgroundColor: themeColors.primaryColor,
  color: themeColors.maptualListFilteringBadgeColor,
  borderRadius: '4px',
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16px',
  '&:hover': {
    backgroundColor: themeColors.primaryColorLight,
  },
}));

export const Preferences = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalClose = () => setIsModalOpen(false);
  const { ThemeNameOptions, activeThemeName, saveActiveTheme } =
    useContext(ThemeContext);
  const [themeOptions] = useState(
    Object.values(ThemeNameOptions).map((theme) => ({
      label: theme,
      id: theme,
    }))
  );

  const handleThemeChange = (themeName) => {
    saveActiveTheme(themeName);
  };

  return (
    <>
      <UserMenuButton
        onClick={() => setIsModalOpen(true)}
        startIcon={<SettingsIcon />}
        buttonText="Preferences"
      />
      <ModalDisplay isOpen={isModalOpen} style={{ width: 480 }}>
        <ModalHeader onClose={handleModalClose}>Preferences</ModalHeader>
        <ModalBody>
          <BodyContainer>
            <TextContainer>
              <Title>Color Theme</Title>
              <BodyText>Customize how Maptual looks on your device.</BodyText>
            </TextContainer>
            <StyledChipSelectionContainer>
              <ChipSelectionInput
                id="maptualThemeSelect"
                options={themeOptions}
                value={[activeThemeName]}
                onChange={handleThemeChange}
                singleSelect
              />
            </StyledChipSelectionContainer>
          </BodyContainer>
        </ModalBody>
        <ModalFooter>
          <CloseButton onClick={handleModalClose} variant="outlined">
            Done
          </CloseButton>
        </ModalFooter>
      </ModalDisplay>
    </>
  );
};
