import { segmentTypes } from '../components/fieldView/constants';

export function filterEntitiesBySegmentType(list, segmentConfig) {
  const ret = list.filter((hcp) => checkSegments(hcp, segmentConfig));

  return ret;
}

export function filterEntitiesBySpecialties(
  list,
  specialtiesToInclude,
  specialtiesToExclude
) {
  const lowerCaseSpecialtiesToInclude = specialtiesToInclude.map((specialty) =>
    specialty.toLowerCase()
  );

  const lowerCaseSpecialtiesToExclude = specialtiesToExclude?.map((specialty) =>
    specialty.toLowerCase()
  );

  return list.filter((hcp) => {
    const hcpSpecialty = hcp.specialty.toLowerCase();

    return (
      lowerCaseSpecialtiesToInclude.includes(hcpSpecialty) ||
      (lowerCaseSpecialtiesToExclude?.length &&
        !lowerCaseSpecialtiesToExclude.includes(hcpSpecialty))
    );
  });
}

export function checkSegments(hcp, segmentConfig) {
  const hcpSegment = hcp.segments.find(
    (seg) => seg.segmentName === segmentConfig.segmentName
  );

  if (hcpSegment) {
    if (segmentConfig.id === segmentTypes.RECENT) {
      return hcpSegment.isHistoricalMember;
    }
    if (segmentConfig.id === segmentTypes.PREDICTED) {
      return hcpSegment.isPredictedMember;
    }
  }
  return false;
}

export function filterEntitiesBySearch(list, searchTerm) {
  return list.filter(
    (item) =>
      (item.primaryText &&
        item.primaryText.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.secondaryText &&
        item.secondaryText.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (Array.isArray(item.chips) &&
        item.chips.find(
          (term) =>
            term && term.toLowerCase().includes(searchTerm.toLowerCase())
        )) ||
      (Array.isArray(item.segments) &&
        item.segments.find((term) =>
          term?.segmentName?.toLowerCase()?.includes(searchTerm.toLowerCase())
        )) ||
      (Array.isArray(item.keywords) &&
        item.keywords.find(
          (term) =>
            term && term.toLowerCase().includes(searchTerm.toLowerCase())
        ))
  );
}

export function filterForEntitiesBySelectedUserCreatedList(
  list,
  selectedUserCreatedList
) {
  return list.filter(
    (item) =>
      item?.listMemberships && item?.listMemberships[selectedUserCreatedList]
  );
}

export function filterOutEntitiesInUserCreatedLists(list) {
  return list.filter(
    (item) =>
      !item?.listMemberships?.noSee && !item?.listMemberships?.longTermLeave
  );
}

export function includeEntitiesFromSpecificUserCreatedList(
  list,
  userCreatedListToInclude
) {
  const userCreatedListToExclude =
    userCreatedListToInclude === 'noSee' ? 'longTermLeave' : 'noSee';

  return list.filter((item) => {
    if (!item?.listMemberships) {
      return true;
    }

    return !(
      item?.listMemberships[userCreatedListToExclude] &&
      !item?.listMemberships[userCreatedListToInclude]
    );
  });
}
