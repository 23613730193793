import { Box, Divider, IconButton, Typography, styled } from '@mui/material';
import { useTheme } from '@mui/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Icon } from '../entityInsights/insightsGeneric';

const HelpButtonWrapper = styled(IconButton)(({ theme: { themeColors } }) => ({
  width: 13,
  height: 13,
  color: themeColors.secondaryTextColor,
  svg: {
    width: 14,
    height: 14,
  },
  marginLeft: 4,
  padding: 5,
  boxSizing: 'content-box',
}));

const LevelBarWrapper = styled(Box)(({ theme: { themeColors } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  svg: {
    color: themeColors.rankedCategoricalScaleActiveChevron,
  },
}));

const Bar = styled(Box)(() => ({
  marginTop: -4,
  width: '100%',
  height: 4,
  opacity: 0.6,
  '&.selectedBar': {
    opacity: 1,
  },
}));

const Title = styled(Box)(() => ({
  fontWeight: 500,
  fontSize: 14,
  marginBottom: 4,
  display: 'flex',
  alignItems: 'center',
}));

const EmptyLevelWrapper = styled(Box)(({ theme: { themeColors } }) => ({
  padding: 24,
  backgroundColor: themeColors.surfaceEmpty,
  borderRadius: 3,
  color: themeColors.emptyText,
  marginTop: 14,
}));

const EmptyLevelTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gridGap: 10,
  fontSize: 12,
  justifyContent: 'center',
}));

const HelpButton = ({ intercomId }) => (
  <HelpButtonWrapper onClick={() => window.Intercom('showArticle', intercomId)}>
    <InfoOutlinedIcon />
  </HelpButtonWrapper>
);

const LevelBar = ({ color, isSelected, ...props }) => (
  <LevelBarWrapper {...props}>
    {isSelected ? <ArrowDropDownIcon /> : null}
    <Bar
      sx={{
        background: color,
      }}
      className={isSelected ? 'selectedBar' : ''}
    />
  </LevelBarWrapper>
);

const LevelMeterWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  gridGap: 1,
  alignItems: 'flex-end',
}));

const ContextBox = styled(Box)(({ theme: { themeColors } }) => ({
  borderRadius: 10,
  backgroundColor: themeColors.cardBackgroundColor,
  border: '1px solid',
  borderColor: themeColors.contentCardBorderColor,
}));

const Card = styled(ContextBox)(() => ({
  display: 'flex',
  textAlign: 'left',
  padding: '24px 0 24px 0',
}));

const VerticalCard = styled(Card)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gridGap: 16,
}));

const CardHalf = styled(Box)(() => ({
  padding: '0 24px 0 24px',
  width: '100%',
}));

const CategoryHalf = styled(CardHalf)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gridGap: 14,
}));

const CardIcon = styled(Icon)(({ theme: { themeColors } }) => ({
  color: themeColors.anchorIconsGeneralSecondary,
  backgroundColor: themeColors.anchorIconsGeneralPrimary,
  marginBottom: 8,
}));

export const LevelMeter = ({ selectedCategoryIndex, numberOfCategories }) => {
  const { themeColors } = useTheme();

  const levelsColorMap = {
    level1: { color: themeColors.dataGeneralColor5 },
    level2: { color: themeColors.dataGeneralColor4 },
    level3: { color: themeColors.dataGeneralColor3 },
    level4: { color: themeColors.dataGeneralColor2 },
    level5: { color: themeColors.dataGeneralColor },
  };

  return (
    <LevelMeterWrapper>
      {[...Array(numberOfCategories).keys()].map((levelNumber) => (
        <LevelBar
          data-testid={
            selectedCategoryIndex === levelNumber
              ? `${levelNumber}-${numberOfCategories}-bar-selected-${levelNumber}`
              : `${levelNumber}-${numberOfCategories}-bar`
          }
          key={`level-${levelNumber}-maxLevel-title`}
          color={levelsColorMap[`level${levelNumber + 1}`]?.color}
          isSelected={selectedCategoryIndex === levelNumber}
        />
      ))}
    </LevelMeterWrapper>
  );
};

const CardDescription = ({ title, tooltip, subTitle }) => {
  const { themeColors } = useTheme();
  return (
    <CardHalf>
      <CardIcon>
        <QueryStatsIcon />
      </CardIcon>
      <Title color={themeColors.secondaryTextColor}>
        {title} {tooltip && <HelpButton intercomId={tooltip} />}
      </Title>
      <Typography variant="body2">{subTitle}</Typography>
    </CardHalf>
  );
};

const CardCategories = ({ categoricalData }) => {
  const { themeColors } = useTheme();

  if (!categoricalData.length) {
    return (
      <CategoryHalf>
        <EmptyLevelWrapper>
          <EmptyLevelTitle>No data on patient volume</EmptyLevelTitle>
        </EmptyLevelWrapper>
      </CategoryHalf>
    );
  }

  return (
    <CategoryHalf>
      {categoricalData.map(
        ({ title, subTitle, selectedCategoryIndex, numberOfCategories }, i) => (
          <Box key={`category-card-${i}`}>
            <Title color={themeColors.rankedCategoricalScaleCategory}>
              {title}
            </Title>
            <Typography
              color={themeColors.rankedCategoricalScaleCategoryLabel}
              variant="body2"
            >
              {subTitle}
            </Typography>
            <LevelMeter
              selectedCategoryIndex={selectedCategoryIndex}
              numberOfCategories={numberOfCategories}
            />
          </Box>
        )
      )}
    </CategoryHalf>
  );
};

export const CategoricalDataCard = ({ data, orientation = 'horizontal' }) => {
  if (orientation === 'vertical') {
    return (
      <VerticalCard data-testid="vertical-card">
        <CardDescription {...data} />
        <CardCategories {...data} />
      </VerticalCard>
    );
  }

  return (
    <Card data-testid="horizontal-card">
      <CardDescription {...data} />
      <Divider light orientation="vertical" />
      <CardCategories {...data} />
    </Card>
  );
};
