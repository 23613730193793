import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import NoData from '@mui/icons-material/CloudOff';
import { styled, Box } from '@mui/material';
import { darkThemeColors } from '@odaia/ui/src/colorConfig';
import { PROJECT_STATUS } from '../../../constants';
import { themeColors as adminColors } from '../../../styles/adminStyles';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 'auto',
    padding: 16,
  },
  textStyle: {
    margin: 0,
    padding: 0,
  },
  textStyleDisabled: {
    margin: 0,
    padding: 0,
    color: theme.palette.text.disabled,
  },
  mediaStyle: {
    width: '100%',
    height: '12rem',
    minHeight: '12rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconStyle: {
    width: '3rem',
    height: '3rem',
    marginBottom: '1rem',
    color: darkThemeColors.tertiaryColor,
  },
  mediaText: {
    color: darkThemeColors.tertiaryColor,
    maxWidth: '10rem',
    fontWeight: 500,
  },
}));

const StyledCardContent = styled(CardContent)({
  height: '100%',
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
});

const ObjectiveList = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: '8px',
  padding: 16,
});

const CardBodyText = styled('span')({
  color: adminColors.darkTheme.adminCardBodyText,
  fontWeight: 400,
});

const ObjectiveTypeText = styled('span')({
  color: adminColors.darkTheme.adminCardSubheader,
  fontWeight: 600,
});

const CardBodyWrapper = styled('div')({
  textAlign: 'left',
});

export default function ProjectCardContent({
  isBusy,
  projectStatus,
  isAdmin = false,
  objectives = [],
  ...props
}) {
  const classes = useStyles();

  const busyComponent = () => (
    <div className={classes.mediaStyle}>
      <CircularProgress className={classes.iconStyle} />
      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        className={classes.mediaText}
      >
        Creating Project ...
      </Typography>
    </div>
  );

  const createdComponent = () => (
    <div className={classes.mediaStyle}>
      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        className={classes.mediaText}
      >
        Project Currently Running
      </Typography>
    </div>
  );

  const nonDeployedComponent = () => (
    <div className={classes.mediaStyle}>
      <NoData className={classes.iconStyle} />
      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        className={classes.mediaText}
      >
        Currently Insufficient Performance Data
      </Typography>
    </div>
  );

  const deployedComponent = () => (
    <div
      style={{
        width: '100%',
        height: '12rem',
        borderBottom: '2px solid green',
      }}
    >
      &nbsp;
    </div>
  );

  const ProjectPerformance = () => {
    if (isBusy) return busyComponent();
    if (projectStatus === PROJECT_STATUS.READY) return nonDeployedComponent();
    if (projectStatus === PROJECT_STATUS.DEPLOYED) return deployedComponent();

    return createdComponent();
  };

  return (
    <StyledCardContent>
      {isAdmin ? null : (
        <CardMedia className={classes.media}>
          <ProjectPerformance />
        </CardMedia>
      )}
      {isAdmin ? (
        <>
          <ObjectiveList>
            {objectives.map((objectiveFullName, i) => {
              const [objectiveType, objectiveName] =
                objectiveFullName.split(':');
              return (
                <CardBodyWrapper key={`${objectiveName}-${i}`}>
                  <ObjectiveTypeText>
                    {objectiveType}:{' '}
                    <CardBodyText>{objectiveName}</CardBodyText>
                  </ObjectiveTypeText>
                </CardBodyWrapper>
              );
            })}
          </ObjectiveList>
          <div>{props.children}</div>
        </>
      ) : null}
    </StyledCardContent>
  );
}
