import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import Typography from '@mui/material/Typography';

import TaskIconWrapper from '../../common/taskIconWrapper';

export default function DeployedProjectStatus() {
  return (
    <TaskIconWrapper color="success">
      <CheckCircleOutlineOutlinedIcon fontSize="small" color="success" />
      <Typography variant="body2">Deployed</Typography>
    </TaskIconWrapper>
  );
}
