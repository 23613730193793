import { useCallback } from 'react';
import {
  TextField,
  Checkbox,
  Box,
  Stack,
  IconButton,
  styled,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DeleteOutline } from '@mui/icons-material';
import getValueFromHierarchy from './getValueFromHierarchy';
import { schemaToInitialValues } from './formHelpers';
import { SchemaType } from './constants';

export const StyledTextField = styled(TextField)(
  ({ theme: { themeColors } }) => ({
    width: '50%',
    marginBottom: 16,
    '& label.Mui-focused': {
      color: themeColors.neutral60,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `1px solid ${themeColors.neutral60}`,
      },
    },
    '& input::placeholder': {
      opacity: 1,
    },
    '.MuiFormHelperText-root.Mui-error': {
      color: themeColors.danger60,
    },
    '.MuiFormHelperText-root ': {
      color: themeColors.neutral60,
    },
  })
);

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: 24,
});

const ObjectIterator = styled(Box)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-start',
});

const ArrayWrapper = styled(Box)(() => ({
  padding: '20px 0px 20px 0px',
  flexDirection: 'column',
}));

const ObjectTitle = styled(Typography)({
  textAlign: 'left',
  marginLeft: 10,
  marginBottom: 4,
});

const BooleanTitle = styled(Typography)({
  textAlign: 'left',
});

const BooleanRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
  marginBottom: 16,
});

const ArrayTitle = styled(Typography)({
  textAlign: 'left',
  marginLeft: 10,
  marginBottom: 4,
});

const ObjectDescription = styled(Typography)(({ theme: { themeColors } }) => ({
  textAlign: 'left',
  marginLeft: 10,
  marginBottom: 16,
  color: themeColors.neutral60,
}));

const ObjectContentWrapper = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  border: `1px solid ${themeColors.buttonBorderColor}`,
  padding: 24,
  marginBottom: 10,
}));

const ObjectContentWrapperInner = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  border: `1px solid ${themeColors.buttonBorderColor}`,
  padding: 24,
  gap: 0,
}));

const ObjectWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
});

const StyledCheckbox = styled(Checkbox)(({ theme: { themeColors } }) => ({
  width: 20,
  height: 20,
  marginLeft: 10,
  radius: 2,
  '&.Mui-checked': {
    color: themeColors.maptualListFilteringButtonBackgroundColor,
  },
}));

const StyledButton = styled(Button)({
  marginTop: 16,
});

const BooleanDescription = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '120%',
  color: themeColors.moduleConfigurationFormDescription,
  textAlign: 'left',
}));

function lastTwoTextKeys(newKeyOrder) {
  return newKeyOrder
    .filter((key) => typeof key === 'string')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .slice(-2);
}

export const FormGenerator = ({
  schema,
  formData,
  setFormData,
  setFormError,
}) => {
  const updateJson = useCallback((keys, newValue) => {
    setFormData((prevState) => {
      const updatedJson = { ...prevState };

      let currentObj = updatedJson;

      for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        if (
          !Object.prototype.hasOwnProperty.call(currentObj, key) ||
          typeof currentObj[key] !== 'object'
        ) {
          currentObj[key] = {};
        }
        currentObj = currentObj[key];
      }

      const lastKey = keys[keys.length - 1];
      currentObj[lastKey] = newValue;

      return updatedJson;
    });
  }, []);

  function renderFieldForDataType(
    propertyName,
    propertyValue,
    keyOrder,
    isArrayItem = false
  ) {
    const { type, title, description } = propertyValue;
    const newKeyOrder = [...keyOrder, propertyName].filter(
      (item) => item !== null
    );
    switch (type) {
      case SchemaType.STRING:
        return (
          <Stack direction="row" justifyContent="space-between">
            <StyledTextField
              focused
              id={propertyName}
              label={title}
              value={getValueFromHierarchy(formData, newKeyOrder)}
              onChange={(event) =>
                updateJson(newKeyOrder, event.currentTarget.value)
              }
              helperText={description}
            />
            {isArrayItem && (
              <Stack>
                <IconButton
                  onClick={() => {
                    truncateArrayInFormData(keyOrder);
                  }}
                >
                  <DeleteOutline />
                </IconButton>
              </Stack>
            )}
          </Stack>
        );
      case SchemaType.NUMBER:
        return (
          <Stack direction="row" justifyContent="space-between">
            <StyledTextField
              type="number"
              focused
              id={propertyName}
              label={title}
              value={getValueFromHierarchy(formData, newKeyOrder)}
              onChange={(event) =>
                updateJson(newKeyOrder, +event.currentTarget.value)
              }
              helperText={description}
            />
            {isArrayItem && (
              <Stack>
                <IconButton
                  onClick={() => {
                    truncateArrayInFormData(keyOrder);
                  }}
                >
                  <DeleteOutline />
                </IconButton>
              </Stack>
            )}
          </Stack>
        );
      case SchemaType.BOOLEAN:
        return (
          <BooleanRow>
            <StyledCheckbox
              checked={getValueFromHierarchy(formData, newKeyOrder)}
              onChange={(event) =>
                updateJson(newKeyOrder, event.currentTarget.checked)
              }
            />
            <Stack useFlexGap gap={0.5}>
              <BooleanTitle>{title}</BooleanTitle>
              <BooleanDescription>{description}</BooleanDescription>
            </Stack>
          </BooleanRow>
        );
      case SchemaType.OBJECT:
        return (
          <ObjectWrapper>
            <ObjectTitle>{title}</ObjectTitle>
            <ObjectDescription>{description}</ObjectDescription>
            <ObjectContentWrapper>
              {renderForm(propertyValue, newKeyOrder)}
            </ObjectContentWrapper>
          </ObjectWrapper>
        );

      case SchemaType.ARRAY:
        return (
          <ArrayWrapper>
            <ArrayTitle>{`${title} (List)`}</ArrayTitle>
            <ObjectDescription>{description}</ObjectDescription>
            {getValueFromHierarchy(formData, newKeyOrder).map((item, index) => {
              const listItemTitle = `${propertyName} - ${index + 1}`;
              return (
                <ObjectWrapper key={index}>
                  <ObjectContentWrapperInner>
                    {propertyValue.items.type === SchemaType.OBJECT
                      ? renderForm(
                          propertyValue.items,
                          [...newKeyOrder, index],
                          true
                        )
                      : renderFieldForDataType(
                          null,
                          {
                            title: listItemTitle,
                            type: propertyValue.items.type,
                            description: propertyValue.items.description,
                          },
                          [...newKeyOrder, index],
                          true
                        )}
                  </ObjectContentWrapperInner>
                </ObjectWrapper>
              );
            })}
            <StyledButton
              variant="contained"
              onClick={() => {
                updateJson(newKeyOrder, [
                  ...getValueFromHierarchy(formData, newKeyOrder),
                  schemaToInitialValues(propertyValue.items),
                ]);
              }}
            >
              Add {lastTwoTextKeys(newKeyOrder).reverse().join(' to ')}
            </StyledButton>
          </ArrayWrapper>
        );

      default:
        throw new Error(`Invalid type: ${type}`);
    }
  }

  function truncateArrayInFormData(keyOrder) {
    const parentArray = getValueFromHierarchy(formData, keyOrder.slice(0, -1));
    const index = keyOrder[keyOrder.length - 1];
    parentArray.splice(index, 1);
    updateJson(keyOrder.slice(0, -1), parentArray);
  }

  const renderForm = (formSchema, keyOrder, isArrayItem = false) => {
    try {
      return (
        <Stack direction="row" justifyContent="space-between">
          <ObjectIterator>
            {Object.entries(formSchema.properties).map(
              ([propertyName, propertyValue]) =>
                renderFieldForDataType(propertyName, propertyValue, keyOrder)
            )}
          </ObjectIterator>
          {isArrayItem && (
            <Stack>
              <IconButton
                onClick={() => {
                  truncateArrayInFormData(keyOrder);
                }}
              >
                <DeleteOutline />
              </IconButton>
            </Stack>
          )}
        </Stack>
      );
    } catch (e) {
      setFormError(true);
      return null;
    }
  };

  return <Wrapper>{renderForm(schema, [])}</Wrapper>;
};
