import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import { PowerScore } from '@odaia/shared-components/src/powerScore';
import { Box } from '@mui/material';
import ChannelIconProvider from '../channelIconProvider';
import { parseDateStringWithResolution } from '../../../utils/dateParser';

export default function ProfileJourneyView({ entityJourney = [] }) {
  const { themeColors } = useTheme();

  const timelineIconStyle = {
    padding: 10,
    width: 48,
    height: 48,
    borderRadius: 30,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: themeColors.timelineActivityIconBorderColor,
    color: themeColors.timelineActivityContentPrimaryColor,
    backgroundColor: themeColors.timelineActivityIconBackgroundColor,
  };

  const getBorderColor = (eventCategory, eventType, scoreChangeValue) => {
    if (eventCategory === 'MAPTUAL' && eventType === 'MAPTUAL_SCORE_UPDATE') {
      if (scoreChangeValue > 0) {
        return themeColors.timelineScoreIncreaseBorderColor;
      }

      if (scoreChangeValue < 0) {
        return themeColors.timelineScoreDecreaseBorderColor;
      }
    }

    return themeColors.timelineActivityBorderColor;
  };

  const isCrmEvent = (eventCategory) => {
    switch (eventCategory) {
      case 'SALES':
      case 'MARKETING':
      case 'MSL':
      case 'CRM':
        return true;
      default:
        return false;
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {entityJourney &&
        entityJourney.map((timelineEvent, index) => {
          const {
            timestamp = '',
            timestampResolution = '',
            eventMetadata = {},
            primaryText = '',
            secondaryText = '',
            eventData = '',
            icon = '',
          } = timelineEvent;
          const {
            eventCategory = '',
            eventType = '',
            eventChannel = '',
          } = eventMetadata;
          const formattedDate = parseDateStringWithResolution(
            timestamp,
            timestampResolution
          );
          return (
            <Box
              key={index}
              bgcolor={themeColors.timelineActivitySurfaceColor}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
              p={2}
              borderLeft={`solid 2px ${getBorderColor(
                eventCategory,
                eventType,
                eventData.maptualScoreChange
              )}`}
            >
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography
                  variant="subtitle1"
                  color={themeColors.timelineActivityContentSecondaryColor}
                >
                  {formattedDate}
                </Typography>

                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography
                    variant="h5"
                    color={themeColors.timelineActivityContentPrimaryColor}
                  >
                    {primaryText}
                  </Typography>
                  <Typography
                    variant="body2"
                    color={themeColors.timelineActivityContentSecondaryColor}
                  >
                    {secondaryText}
                  </Typography>
                </Box>
              </Box>

              <Box>
                {eventCategory === 'MAPTUAL' &&
                  eventType === 'MAPTUAL_SCORE_UPDATE' && (
                    <PowerScore
                      score={eventData.maptualDisplayScore}
                      scoreChangeValue={eventData.maptualScoreChange}
                      objectiveScoreList={eventData.maptualObjectiveScores}
                    />
                  )}

                {isCrmEvent(eventCategory) && (
                  <ChannelIconProvider
                    channelName={eventChannel}
                    iconType={icon}
                    style={timelineIconStyle}
                  />
                )}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
}
