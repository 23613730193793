import { useState, useEffect } from 'react';
import { useTheme } from '@mui/styles';
import { styled, FormControl, Select, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useObjectives } from './useObjectives';

const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== 'minWidth',
})(({ theme: { themeColors }, minWidth }) => ({
  minWidth,
  textAlign: 'left',
  '.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
    color: themeColors.primaryMaptualListFilteringColor,
  },
}));

const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'outlined',
})(({ theme: { themeColors }, outlined }) => ({
  backgroundColor: outlined ? themeColors.inputBackground : 'unset',
  border: outlined ? `1px solid ${themeColors.buttonBorderColor}` : 'none',
  borderRadius: '4px',
  color: themeColors.buttonContentColor,
  boxShadow: 'none',
  '.MuiInputBase-input': {
    padding: '8px 14px',
  },
  '&:hover': {
    backgroundColor: themeColors.inputBackgroundHover,
  },
  '&.Mui-focused': {
    backgroundColor: themeColors.inputBackgroundHover,
  },
  '.MuiFilledInput-input:focus': {
    backgroundColor: themeColors.inputBackgroundHover,
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme: { themeColors } }) => ({
  color: `${themeColors.buttonContentColor} !important`,
  '&:hover': {
    backgroundColor: `${themeColors.buttonHoverBackgroundColor} !important`,
    color: `${themeColors.buttonHoverContentColor} !important`,
  },
  '&.Mui-selected': {
    backgroundColor: `${themeColors.buttonActiveBackgroundColor} !important`,
    color: `${themeColors.buttonActiveContentColor} !important`,
  },
}));

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.menuTextColor,
    marginLeft: '4px',
    width: '20px',
    height: '20px',
  })
);

export const ObjectiveSelector = ({
  projectId,
  objectiveId,
  updateFilterObjective,
  currentObjectives,
  fullWidth = false,
  outlined = true,
  minWidth = '',
}) => {
  const { themeColors } = useTheme();
  const [objective, setObjective] = useState(null);
  const [objectives, setObjectives] = useState(currentObjectives);
  const { data: objectivesData, isLoading: isObjectivesLoading } =
    useObjectives(projectId);

  if (objectiveId && !objective && objectives && objectives.length > 0) {
    const newObjective = objectives.find((i) => i.id === objectiveId);
    setObjective(newObjective);
  }

  useEffect(() => {
    if (
      objectivesData &&
      objectivesData.length > 0 &&
      (!currentObjectives || currentObjectives.length === 0)
    ) {
      setObjectives(objectivesData);
      if (objectiveId) {
        handleObjectiveChange(objectiveId, objectivesData);
      } else {
        handleObjectiveChange(objectivesData[0].id, objectivesData);
      }
    }
  }, [objectivesData]);

  const handleObjectiveChange = (newObjectiveId, objectives) => {
    const newObjective = objectives.find((i) => i.id === newObjectiveId);
    setObjective(newObjective);
    updateFilterObjective(newObjectiveId);
  };

  return (!isObjectivesLoading || currentObjectives?.length > 0) &&
    objectives &&
    objectives.length > 0 ? (
    <StyledFormControl
      variant="filled"
      fullWidth={fullWidth}
      minWidth={minWidth}
    >
      <StyledSelect
        disableUnderline
        IconComponent={StyledKeyboardArrowDownIcon}
        value={objective?.id || ''}
        onChange={(e) => handleObjectiveChange(e.target.value, objectives)}
        MenuProps={{
          PaperProps: {
            sx: {
              marginTop: '4px',
              padding: '8px',
              backgroundColor: themeColors.inputBackground,
              boxShadow: `0px 1px 4px 0px ${themeColors.boxShadowLevelOne}`,
              gap: '8px',
            },
          },
        }}
        outlined={outlined}
      >
        {Array.isArray(objectives)
          ? objectives.map((item) => (
              <StyledMenuItem key={item.title || item.name} value={item.id}>
                {item.title || item.name || ''}
              </StyledMenuItem>
            ))
          : null}
      </StyledSelect>
    </StyledFormControl>
  ) : null;
};
