/* eslint-disable no-nested-ternary */

import makeStyles from '@mui/styles/makeStyles';
import { styled, Tooltip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@mui/styles/withStyles';
import { PROJECT_STATUS } from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '343px',
    minWidth: '343px',
    marginBottom: '8px',
  },
  selectRoot: {
    width: '100%',
    minHeight: '48px',
    paddingLeft: '8px',
    fontSize: '18px',
    fontWeight: 600,
    borderRadius: '3px',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: theme.themeColors.buttonBackgroundColor,
      color: theme.themeColors.buttonHoverContentColor,
      '&:before': {
        borderBottom: 'none !important',
      },
    },
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },

    '& .MuiInput-input': {
      background: 'none',
      '&:focus': {
        background: 'none',
      },
    },
    '& .MuiMenu-list': {
      padding: '16px',
    },
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.paperColor,
  color: themeColors.segmentSelectionCaptionColor,
  padding: '12px',
  margin: '0px 16px 2px 16px',
  textAlign: 'left',
  borderRadius: '3px',
  '&.Mui-selected': {
    color: themeColors.selectedSegmentColor,
    backgroundColor: themeColors.segmentSelectionSelectedBackgroundColor,
    '&:hover': {
      backgroundColor: themeColors.buttonSecondaryBackgroundColour,
      color: themeColors.buttonHoverContentColor,
    },
  },
  '&:hover': {
    color: themeColors.buttonHoverContentColor,
    backgroundColor: themeColors.buttonHoverBackgroundColor,
  },
}));

const SelectPadding = styled('div')(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.paperColor,
  paddingTop: 8,
}));

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.themeColors.tooltipSurfaceColor,
    fontColor: theme.themeColors.tooltipContentColor,
  },
}))(Tooltip);

export default function ProjectSelector({
  isProjectListLoading,
  projectList,
  project,
  handleProjectChange,
}) {
  const classes = useStyles();

  const projectsSelections =
    Array.isArray(projectList) && projectList.length > 0
      ? projectList.map((item, i) => {
          const ret = {};
          if (!item) {
            ret.projectId = i;
            ret.projectName = 'unknown project';
            return ret;
          }

          ret.projectId = item.projectId;
          ret.projectName = item.projectName;
          if (item.projectStatus !== PROJECT_STATUS.DEPLOYED) {
            ret.projectName = `${String.fromCodePoint(0x1f6e0)} ${
              item.projectName
            }`;
          }

          return ret;
        })
      : null;

  const tempSelections = project?.projectId
    ? {
        projectId: project.projectId,
        projectName: project.projectName,
      }
    : null;

  return (
    <FormControl
      className={classes.root}
      disabled={isProjectListLoading}
      variant="standard"
    >
      <StyledTooltip title={project?.projectName} placement="top">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={project?.projectId || ''}
          onChange={(event) => handleProjectChange(event)}
          data-testid="project-dropdown-selection"
          className={classes.selectRoot}
        >
          <SelectPadding />
          {projectsSelections ? (
            projectsSelections.map((item, i) => (
              <StyledMenuItem
                key={i}
                value={item.projectId}
                data-testid={item.projectName}
              >
                {item.projectName}
              </StyledMenuItem>
            ))
          ) : tempSelections?.[0] ? (
            <StyledMenuItem key={0} value={tempSelections[0].projectId}>
              <div className={classes.statusContainer}>
                {isProjectListLoading ? <CircularProgress size={12} /> : null}
                {tempSelections[0].projectName}
              </div>
            </StyledMenuItem>
          ) : null}
          <SelectPadding />
        </Select>
      </StyledTooltip>
      <div className={classes.statusContainer}>
        {isProjectListLoading ? (
          <CircularProgress
            size={12}
            color="primary"
            style={{ marginRight: 5 }}
          />
        ) : null}
      </div>
    </FormControl>
  );
}
