import { useState } from "react";
import { Filters } from "../mapView";
import { Box, Checkbox, FormControlLabel, styled } from "@mui/material";

const CheckboxContainer = styled("div")(() => ({
  display: "flex",
  gap: 9,
  alignItems: "flex-start",
  flexWrap: "wrap",

  "& .MuiFormControlLabel-label": {
    paddingTop: 1.5,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme: { themeColors } }) => ({
    marginLeft: 0,
    color: themeColors.segmentSelectionCaptionColor,
    ".MuiCheckbox-root": {
      padding: 0,
      marginRight: 9,
      alignSelf: "start",
    },
  })
);

const StyledCheckbox = styled(Checkbox)(({ theme: { themeColors } }) => ({
  "&.Mui-checked": {
    color: themeColors.checkboxSelected,
  },
}));

export const NeverSeenFilter = ({
  filters,
  setFilters,
}: {
  filters: Filters;
  setFilters: (arg0: Filters) => void;
}) => {
  const [neverSeenFilter, setNeverSeenFilter] = useState(
    filters.neverSeenFilter
  );

  const updateFilters = (newFilter) => {
    setNeverSeenFilter(newFilter);
    setFilters({
      ...filters,
      neverSeenFilter: newFilter,
    });
  };

  return (
    <Box>
      <CheckboxContainer>
        <StyledFormControlLabel
          key="never seen filter"
          control={
            <StyledCheckbox
              name="never seen filter"
              onChange={(event) => updateFilters(event.target.checked)}
              checked={neverSeenFilter}
            />
          }
          label="Hide HCPs you haven't seen in a year or more"
        />
      </CheckboxContainer>
    </Box>
  );
};
