import { styled, CircularProgress, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import { TooltipPopover } from '../../TooltipPopover';

const StyledContainer = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: themeColors.cardBackgroundColor,
  border: `1px solid ${themeColors.buttonBorderColor}`,
  borderRadius: '6px',
  padding: '24px',
  justifyContent: 'space-between',
}));

const StyledTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '120%',
  textAlign: 'left',
  marginBottom: 4,
}));

const StyledSubtitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'alignLeft',
})(({ alignLeft, theme: { themeColors } }) => ({
  color: themeColors.tertiaryColor,
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '120%',
  textAlign: alignLeft ? 'left' : 'center',
}));

const ErrorContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2),
  justifyContent: 'center',
  padding: '48px 56px',
  flexDirection: 'column',
  borderRadius: 4,
  background: theme.themeColors.surfaceEmpty,
  alignItems: 'center',
  height: '100%',
}));

const ErrorTextContainer = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  textAlign: 'center',
  color: themeColors.emptyText,
}));

const ProgressContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 24,
  position: 'relative',
}));

const StyledCircularProgress = styled(CircularProgress)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.dataGeneralColor,
    strokeLinecap: 'round',
    position: 'absolute',
  })
);

const StyledCircularProgressBackground = styled(CircularProgress)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.emptyDataColor,
  })
);

const StyledNumberContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 60,
  bottom: 60,

  '> p:first-of-type': {
    marginLeft: 10,
  },
}));

const StyledProgressValue = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.primaryTextColor,
    fontWeight: 600,
    fontSize: '36px',
    lineHeight: '120%',
  })
);

const LoadingContainer = styled(Box)(() => ({
  margin: '40px auto 0px',
}));

const StyledProgress = styled(CircularProgress)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.buttonContentColor,
  })
);

const CIRCLE_SIZE = 162;
const CIRCLE_THICKNESS = 3;

export const ProgressCircle = ({
  title,
  subtitle = null,
  progressValue,
  valueDescription,
  caption = null,
  isLoading,
  containerWidth = '260px',
  containerMinWidth = '253px',
  tooltip = null,
  isGraphAvailable = true,
}) => {
  const { themeColors } = useTheme();

  const sanitizedProgressValue =
    typeof progressValue === 'string' && progressValue.includes('%')
      ? parseFloat(progressValue.replace('%', ''), 10)
      : progressValue;

  return (
    <StyledContainer width={containerWidth} minWidth={containerMinWidth}>
      <Box display="flex" flexDirection="row" gap="6px" alignItems="flex-start">
        <StyledTitle>{title}</StyledTitle>
        {tooltip && (
          <TooltipPopover
            title={tooltip?.title}
            description={tooltip?.description}
            listData={tooltip?.listData}
          />
        )}
      </Box>
      {subtitle && <StyledSubtitle alignLeft>{subtitle}</StyledSubtitle>}

      {isLoading && (
        <LoadingContainer>
          <StyledProgress size={100} />
        </LoadingContainer>
      )}

      {!isLoading && !isGraphAvailable && (
        <ErrorContainer>
          <ErrorTextContainer>
            <Typography variant="title3">Graph not available</Typography>
            <Typography variant="subtitle1">
              We currently do not have enough data to generate this graph.
            </Typography>
          </ErrorTextContainer>
        </ErrorContainer>
      )}

      {!isLoading && isGraphAvailable && (
        <>
          <ProgressContainer>
            <StyledCircularProgressBackground
              variant="determinate"
              size={CIRCLE_SIZE}
              thickness={CIRCLE_THICKNESS}
              value={100}
            />
            <StyledCircularProgress
              variant="determinate"
              sx={{
                position: 'absolute',
              }}
              size={CIRCLE_SIZE}
              thickness={CIRCLE_THICKNESS}
              value={sanitizedProgressValue}
            />
            <StyledNumberContainer>
              <StyledProgressValue>{progressValue}</StyledProgressValue>
              {valueDescription && valueDescription.length > 0 && (
                <StyledSubtitle>{valueDescription}</StyledSubtitle>
              )}
            </StyledNumberContainer>
          </ProgressContainer>

          <Typography variant="subtitle2" color={themeColors.tertiaryColor}>
            {caption}
          </Typography>
        </>
      )}
    </StyledContainer>
  );
};
