import { styled, Typography, TextField, Popover, Chip } from '@mui/material';

export const userManagementColors = {
  primaryColor: '#8595AD',
  secondaryColor: '#A3AFC2',
  tertiaryColor: '#E0E4EB',
  selectColor: '#5E708D',
  errorColor: '#F44336',
  highlightColor: '#F7AAB8',
  iconColor: '#ADC5EB',
};

export const Title = styled(Typography)(() => ({
  marginBottom: 4,
}));

export const Description = styled(Typography)(() => ({
  color: userManagementColors.primaryColor,
  marginBottom: 4,
}));

export const ErrorText = styled(Typography)(() => ({
  color: userManagementColors.errorColor,
  marginTop: 4,
}));

export const AdditionalDescriptionText = styled(Typography)(() => ({
  color: userManagementColors.secondaryColor,
  marginTop: 16,
  marginBottom: 4,
}));

export const StyledTextField = styled(TextField)(() => ({
  '.MuiInputLabel-root': { color: userManagementColors.secondaryColor },
  '.MuiOutlinedInput-root': {
    '& fieldset': { borderColor: userManagementColors.secondaryColor },
  },
}));

export const PopoverWindow = styled('div')(({ theme: { themeColors } }) => ({
  background:
    'linear-gradient(0deg, rgba(68, 83, 106, 0.16), rgba(68, 83, 106, 0.16))',
  backgroundColor: themeColors.mainBackground,
  border: '1px solid',
  borderColor: themeColors.borderLowContrast,
  boxShadow: '0px 16px 30px rgba(0, 0, 0, 0.25)',
  borderRadius: '2px',
  padding: 15,
}));

export const StyledPopover = styled(Popover)(() => ({
  '.MuiPaper-elevation8': {
    boxShadow: '0px 16px 70px rgba(0, 0, 0, 0.5)',
    margin: '3px',
  },
}));

export const StyledPrimaryChip = styled(Chip)(
  ({ theme: { themeColors }, selected }) => ({
    '&.MuiChip-colorPrimary': {
      backgroundColor: themeColors.chipChoiceActiveSurface,
    },
    backgroundColor: selected
      ? themeColors.chipChoiceActiveSurface
      : themeColors.chipChoiceDefaultSurface,
    color: selected
      ? themeColors.chipChoiceActiveContent
      : themeColors.chipChoiceDefaultContent,
    borderColor: selected
      ? themeColors.chipChoiceActiveBorder
      : themeColors.chipChoiceDefaultBorder,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '22px',
    '&:hover': {
      opacity: 1,
      backgroundColor: themeColors.chipChoiceHoverSurface,
      color: themeColors.chipChoiceHoverContent,
      borderColor: themeColors.chipChoiceHoverBorder,
    },
  })
);
