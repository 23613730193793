/* eslint-disable no-restricted-syntax */

import CircularProgress from '@mui/material/CircularProgress';
import BarChart from './barchart';
import { useCurationReports } from './hooks/useCurationReports';

function DedupeChart({
  title,
  projectId,
  regionId,
  graphType,
  classGraphContent = '',
  classLoading = '',
}) {
  const { isLoading, data } = useCurationReports({
    projectId,
    regionId,
    report: 'dedupe',
    graphType,
  });

  return (
    (isLoading || !!data?.data) && (
      <div className={classGraphContent}>
        {isLoading ? (
          <div className={classLoading}>
            <CircularProgress />
          </div>
        ) : (
          <BarChart
            title={title}
            series={formatSeries(data.data)}
            labels={formatLabels(data.data)}
          />
        )}
      </div>
    )
  );
}

const formatSeries = (data) => {
  const seriesData = {};
  for (const powerScoreData of data) {
    for (const val of powerScoreData.data || []) {
      seriesData[val.filter] = [
        ...(seriesData[val.filter] ? seriesData[val.filter] : []),
        val.hcpCount,
      ];
    }
  }
  let res = [];
  for (const [dedupeType, hcpCounts] of Object.entries(seriesData)) {
    res = [...res, { name: dedupeType, data: hcpCounts }];
  }
  return res;
};
const formatLabels = (data) =>
  data.map((item) => `${Math.round(item.powerscore)}`);

export default DedupeChart;
