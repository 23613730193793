/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import * as ViewerAction from '../../actions/projectViewActions';
import HCPEntityPage from '../../components/fieldView/hcpEntityPage';
import { useInsights } from '../fieldview/useInsights';

const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    height: '100%',
    'min-height': '100%',
    display: 'flex',
    backgroundColor: themeColors.mainBackground,
  },
}));

const mapStateToProps = (state) => ({
  viewer: state.projectViewer,
});

const mapDispatchToProps = (dispatch) => ({
  selectEntity: (entityMetadata) =>
    dispatch(ViewerAction.selectEntity(entityMetadata)),
});

function HcpInfoContainer({
  viewer,
  match,
  showConnectButton = false,
  className = '',
}) {
  const classes = useStyles();

  const { projectId } = match.params;
  const { isMaptualListBusy } = viewer;

  const { insights, refetchInsights } = useInsights({
    entityId: match.params.snowflakeEntityId,
    projectId,
    regionId: match.params.snowflakeMaptualListId,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (match.params.snowflakeEntityId) {
      dispatch(
        ViewerAction.initializeStandaloneEntityPage({
          entityId: match.params.entityId,
          snowflakeEntityId: match.params.snowflakeEntityId,
          maptualListId: match.params.maptualListId,
          snowflakeMaptualListId: '',
          projectId,
          isStandalonePage: true,
        })
      );
    }
  }, []);

  return (
    <div className={`${classes.root} ${className}`}>
      <HCPEntityPage
        entityId={match?.params?.entityId}
        snowflakeEntityId={match?.params?.snowflakeEntityId}
        insights={insights}
        getInsights={() => refetchInsights()}
        isMaptualListBusy={isMaptualListBusy}
        standaloneParams={{
          maptualListId: match?.params?.maptualListId,
          projectId,
        }}
        showConnectButton={showConnectButton}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(HcpInfoContainer);
