import { styled, Box, Typography } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { CustomTooltip } from "@odaia/ui/src/customTooltip";
import { Stack } from "@mui/system";

export const Column = styled(Box)(
  ({
    width,
    marginBottom,
    theme: { themeColors },
    flexDirection = "column",
  }) => ({
    display: "flex",
    flexDirection,
    width,
    marginBottom: marginBottom ? 16 : 0,
    textAlign: "left",
    color: themeColors.tableHeaderContentColor,
    fontSize: "14px",
    alignItems: "start",
    gap: "4px",
  })
);

export const ColumnTitle = styled("div")((props) => ({
  color: props.theme.themeColors.tableHeaderContentColorHighlight,
  textTransform: "capitalize",
}));

export const ColumnSubTitle = styled("div")((props) => ({
  display: "flex",
  color: props.theme.themeColors.tableHeaderContentColor,
}));

export const ColumnContainer = styled(Box)(({ paddingBottom }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  gap: 32,
  paddingBottom,
}));

export const StyledMessage = styled(Typography)(() => ({
  marginTop: "8px",
  ":first-child": {
    marginTop: 0,
  },
}));

const StickyColumnContainer = styled(Box)(({ theme: { themeColors } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  gap: 32,
  position: "sticky",
  zIndex: 1,
  backgroundColor: themeColors.cardBackgroundColor,
  paddingLeft: 16,
  left: 0,
  paddingBottom: 12,
}));

export const StyledIcon = styled(ErrorOutlineOutlinedIcon)(() => ({
  width: "14px",
  height: "14px",
}));

export const MetricColumns = ({ metricColumns, objectiveBasketName }) => {
  const res = [];
  for (const column of metricColumns) {
    if (!column.visible) continue;
    res.push(
      <Column width={column.width} key={`${column.id}-${objectiveBasketName}`}>
        <ColumnTitle>{objectiveBasketName}</ColumnTitle>
        <ColumnSubTitle>{column.sublabel}</ColumnSubTitle>
      </Column>
    );
  }
  return <ColumnContainer>{res}</ColumnContainer>;
};

export const StickyColumns = ({ cols }) => {
  const res = [];
  for (const column of cols) {
    if (!column.visible) continue;
    res.push(
      <Column width={column.width} key={`${column.id}`} flexDirection="row">
        <Stack
          flexDirection="row"
          gap={0.5}
          alignItems="center"
          justifyContent="center"
        >
          <ColumnSubTitle>{column.label}</ColumnSubTitle>
          {column.message && (
            <CustomTooltip
              title={column.label}
              message={column.message}
              placement="bottom-start"
            >
              <ColumnSubTitle>
                {column.subLabel} <StyledIcon />
              </ColumnSubTitle>
            </CustomTooltip>
          )}
        </Stack>
      </Column>
    );
  }
  return <StickyColumnContainer>{res}</StickyColumnContainer>;
};

export const GenericColumns = ({ columns, paddingBottom }) => (
  <ColumnContainer paddingBottom={paddingBottom}>
    {columns.map((column) => (
      <Column width={column.width} key={column.id}>
        <ColumnTitle>{column.label}</ColumnTitle>
        {column.message && (
          <CustomTooltip
            title={column.label || column.subLabel}
            message={column.message}
            placement="bottom-start"
          >
            <ColumnSubTitle>
              <Stack flexDirection="row" alignItems="center" gap={0.5}>
                <Typography sx={{ fontSize: "14px" }}>
                  {column.subLabel}
                </Typography>
                <StyledIcon />
              </Stack>
            </ColumnSubTitle>
          </CustomTooltip>
        )}
        {!column.message && <ColumnSubTitle>{column.subLabel}</ColumnSubTitle>}
      </Column>
    ))}
  </ColumnContainer>
);
