import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const UserTableMenu = ({
  open,
  anchorEl,
  handleClose,
  handleDeleteUser,
  handlePasswordReset,
}) => (
  <Menu
    id="demo-positioned-menu"
    aria-labelledby="demo-positioned-button"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
  >
    <MenuItem onClick={handleDeleteUser}>Delete User</MenuItem>
    <MenuItem onClick={handlePasswordReset}>Reset Password</MenuItem>
  </Menu>
);

export default UserTableMenu;
