import { useState, useEffect } from "react";
import { styled, Typography } from "@mui/material";

import TimelineIcon from "@mui/icons-material/Timeline";
import { formatLastContactedDate } from "@odaia/domain/src";

import { TooltipContent } from "@odaia/ui/src/tooltip";
import { Row } from "../../entitiesTable/Row";
import { PowerScore } from "../../powerScore";
import { useCallPlanContext } from "../CallPlanContext";
import { BadgeContainer } from "../../BadgeContainer";
import { NumberContainer } from "../../numberContainer";

const StyledCardColumnSection = styled("div")(() => ({
  display: "flex",
  gap: 32,
  alignItems: "center",
  padding: "16px 16px 16px 0px",
  minWidth: 175,
}));

const ScoreContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

const StyledColumn = styled("div")(({ width }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width,
  minWidth: 150,
}));

const StyledTitle = styled(Typography)(
  ({ theme: { themeColors }, marginLeft }) => ({
    color: themeColors.secondaryTextColor,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "120%",
    textAlign: "left",
    textTransform: "capitalize",
    marginLeft,
  })
);

const ItemBody = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 8,
}));

const BodyText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
  fontSize: "14px",
  lineheight: "120%",
  textTransform: "capitalize",
  textAlign: "left",
}));

const InsightIconBackground = styled("div")(({ theme: { themeColors } }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "24px",
  height: "24px",
  minWidth: "24px",
  minHeight: "24px",
  backgroundColor: `${themeColors.dataGeneralColor}20`,
  borderRadius: "50%",
}));

const InsightIcon = styled(TimelineIcon)(({ theme: { themeColors } }) => ({
  width: "16px",
  height: "16px",
  color: themeColors.dataGeneralColor,
  opacity: 1,
}));

type CallPlanRowProps = {
  objectiveId: string;
  cadence: string;
  row: unknown;
  isLoading: boolean;
  rank: unknown;
  onRowClick: (entityId: string) => void;
  crmData: unknown;
  isCrmDataLoading: boolean;
  entitySegments: unknown;
  isSegmentsLoading: boolean;
};

export const CallPlanRow = ({
  objectiveId,
  cadence,
  row,
  isLoading,
  rank,
  onRowClick,
  crmData,
  entitySegments,
  isSegmentsLoading,
}: CallPlanRowProps) => {
  const [segments, setSegments] = useState([]);
  const [cityTooltipContent, setCityTooltipContent] = useState(null);

  const {
    productLineId,
    projectTargetProducts,
    isProductsLoading,
    profile,
    showCurationData,
    isSuggestedEntities,
  } = useCallPlanContext();

  const isSingleProduct = projectTargetProducts?.length === 1;

  useEffect(() => {
    if (row.entity) {
      const tooltipContent = TooltipContent([
        {
          id: 0,
          title: `${row.entity?.city}, ${row.entity?.province.toUpperCase()}`,
        },
        { id: 1, title: row.entity?.address || "No Address" },
        { id: 2, title: row.entity?.postalCode },
      ]);
      setCityTooltipContent(tooltipContent);
    }
  }, [
    row?.entity?.city,
    row?.entity?.province,
    row.entity?.address,
    row.entity?.postalCode,
  ]);

  return (
    <Row
      row={row}
      objectiveId={objectiveId}
      cadence={cadence}
      key={row.id}
      isShimmer={isLoading}
      onRowClick={() => onRowClick(row.entity.id)}
      showPowerscore={isSingleProduct}
      rowTitle={row.entity.name}
      rowSubtitle={`${row.entity?.specialty} - ${formatLastContactedDate(
        row.entity?.lastContactedDate
      )}`}
      showRankCol={showCurationData}
      rank={row.curationRank}
      showRankValue={isSuggestedEntities}
      productLineId={productLineId}
      showCustomListIcon
      profile={profile}
    >
      <StyledColumn width="150px">
        <ScoreContainer>
          <PowerScore
            score={Math.round(row.score)}
            objectiveScoreList={row.objectiveScores}
          />
          <StyledTitle variant="body2" marginLeft="8px">
            {row.product?.toLowerCase()}
          </StyledTitle>
        </ScoreContainer>
      </StyledColumn>
      <StyledColumn width="400px">
        <ItemBody>
          <InsightIconBackground>
            <InsightIcon />
          </InsightIconBackground>
          <BodyText>
            {row.insight?.title
              ? row.insight.title.replace(/^#*\s/, "")
              : "We don't have any insights for this HCP."}
          </BodyText>
        </ItemBody>
      </StyledColumn>
      <StyledCardColumnSection>
        <NumberContainer value={row.qtdCallCount} big={false} />
        <BadgeContainer
          content={entitySegments.filter(
            (segment) => segment.type.toUpperCase() !== "CUSTOMER"
          )}
          isLoading={isSegmentsLoading}
          maxItems={2}
        />
        <BadgeContainer
          content={entitySegments.filter(
            (segment) => segment.type.toUpperCase() === "CUSTOMER"
          )}
          isLoading={isSegmentsLoading}
          maxItems={2}
        />
      </StyledCardColumnSection>
    </Row>
  );
};
