import React from 'react';
import Chip from '@mui/material/Chip';
import { styled, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';

const StyledChip = styled(Chip)(
  ({ theme: { themeColors, spacing }, selected }) => ({
    marginRight: 10,
    '&.MuiChip-colorPrimary': {
      backgroundColor: themeColors.chipChoiceActiveSurface,
    },
    backgroundColor: selected
      ? themeColors.chipChoiceActiveSurface
      : themeColors.chipChoiceDefaultSurface,
    color: selected
      ? themeColors.chipChoiceActiveContent
      : themeColors.chipChoiceDefaultContent,
    borderColor: selected
      ? themeColors.chipChoiceActiveBorder
      : themeColors.chipChoiceDefaultBorder,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: spacing(2),
    '&:hover': {
      opacity: 1,
      backgroundColor: themeColors.chipChoiceHoverSurface,
      color: themeColors.chipChoiceHoverContent,
      borderColor: themeColors.chipChoiceHoverBorder,
    },
  })
);

interface SelectionChipProps {
  label: string;
  selected?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  disabled?: boolean;
}
export const SelectionChip = ({
  label,
  selected,
  onClick = () => {},
  disabled,
  ...props
}: SelectionChipProps) => (
  <StyledChip
    label={
      <Typography variant="body2" noWrap>
        {label || ''}
      </Typography>
    }
    icon={selected ? <Check /> : null}
    selected={selected}
    variant={selected ? 'primary' : 'outlined'}
    color={selected ? 'primary' : 'default'}
    onClick={(event) => {
      onClick(event);
    }}
    disabled={disabled}
    {...props}
  />
);
