import { Link } from 'react-router-dom';
import { Box, Button, Stack, CircularProgress, styled } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { FormGenerator } from '../FormGenerator';
import { LoadingContainer } from '../../generic/LoadingContainer';
import { ROUTES } from '../../../constants';
import { PageWrapper, StyledBreadcrumb } from '../styledComponents';
import { useMutateInstance } from './useMutateInstance';

type MutateInstaceProps = {
  isCreate?: boolean;
};

export const MutateInstance = ({ isCreate = false }: MutateInstaceProps) => {
  const {
    schemaName,
    userGroup,
    formData,
    setFormData,
    schemaData,
    isLoadingSchema,
    isLoadingDefaultValues,
    onCancel,
    onSave,
    isSaving,
    savedSuccessfully,
    showJsonData,
    toggleJsonData,
  } = useMutateInstance(isCreate);

  if (isLoadingSchema || isLoadingDefaultValues) {
    return <LoadingContainer height="100%" />;
  }

  const saveButtonText = (() => {
    if (isSaving) {
      return 'Saving';
    }
    if (savedSuccessfully) {
      return 'Saved';
    }
    return 'Save Config instance';
  })();

  return (
    <PageWrapper>
      <StyledBreadcrumb>
        <Link to={`${ROUTES.ADMIN}${ROUTES.APP_CONFIG}`}>App Config</Link>
        <Link to={`${ROUTES.ADMIN}${ROUTES.APP_CONFIG}`}>{userGroup}</Link>
        <a>{schemaName}</a>
      </StyledBreadcrumb>
      <FormWrapper>
        {schemaData && formData && (
          <Stack direction="row" gap={4}>
            <Box flexGrow={1}>
              <FormGenerator
                schema={schemaData}
                formData={formData}
                setFormData={setFormData}
                setFormError={() => {}}
              />
            </Box>
            <Box width={showJsonData ? 400 : 'unset'}>
              <Stack direction="row" justifyContent="flex-end" gap={1}>
                <Button variant="outlined" onClick={onCancel} size="small">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={onSave}
                  endIcon={
                    isSaving && <CircularProgress size={16} color="inherit" />
                  }
                  size="small"
                >
                  {saveButtonText}
                </Button>
              </Stack>
              <JsonDataSection
                showJsonData={showJsonData}
                onClick={toggleJsonData}
                jsonData={formData}
              />
            </Box>
          </Stack>
        )}
      </FormWrapper>
    </PageWrapper>
  );
};

function JsonDataSection({
  showJsonData,
  onClick,
  jsonData,
}: {
  showJsonData: boolean;
  onClick: () => void;
  jsonData: unknown;
}) {
  return (
    <>
      <Stack direction="row" justifyContent="space-between" mt={2}>
        <Box>{showJsonData && 'JSON Data'}</Box>
        <Button
          onClick={onClick}
          endIcon={showJsonData && <ChevronRight />}
          startIcon={!showJsonData && <ChevronLeft />}
          variant="utility"
        >
          {showJsonData ? 'Hide JSON Data' : 'Show JSON Data'}
        </Button>
      </Stack>
      <Box
        mt={1}
        textAlign="left"
        color={(theme) => theme.themeColors.inputs.secondary.default.content}
        fontSize={12}
      >
        <pre>
          <code>{showJsonData ? JSON.stringify(jsonData, null, 2) : ''}</code>
        </pre>
      </Box>
    </>
  );
}

const FormWrapper = styled(Box)(({ theme: { themeColors, spacing } }) => ({
  border: `2px solid ${themeColors.activeForm.border}`,
  borderRadius: spacing(2),
  flexGrow: 1,
  marginTop: spacing(3),
  padding: spacing(3),
  gap: spacing(4),
}));
