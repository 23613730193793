/* tslint:disable */
/* eslint-disable */
/**
 * cnf_api
 * handle config api
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * Model for an ArraySchema.
 * @export
 * @interface ArraySchemaInput
 */
export interface ArraySchemaInput {
    /**
     * 
     * @type {string}
     * @memberof ArraySchemaInput
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArraySchemaInput
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArraySchemaInput
     */
    'type': ArraySchemaInputTypeEnum;
    /**
     * 
     * @type {Items}
     * @memberof ArraySchemaInput
     */
    'items': Items;
}

export const ArraySchemaInputTypeEnum = {
    Array: 'array'
} as const;

export type ArraySchemaInputTypeEnum = typeof ArraySchemaInputTypeEnum[keyof typeof ArraySchemaInputTypeEnum];

/**
 * Model for an ArraySchema.
 * @export
 * @interface ArraySchemaOutput
 */
export interface ArraySchemaOutput {
    /**
     * 
     * @type {string}
     * @memberof ArraySchemaOutput
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArraySchemaOutput
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArraySchemaOutput
     */
    'type': ArraySchemaOutputTypeEnum;
    /**
     * 
     * @type {Items1}
     * @memberof ArraySchemaOutput
     */
    'items': Items1;
}

export const ArraySchemaOutputTypeEnum = {
    Array: 'array'
} as const;

export type ArraySchemaOutputTypeEnum = typeof ArraySchemaOutputTypeEnum[keyof typeof ArraySchemaOutputTypeEnum];

/**
 * Response model for a product line.
 * @export
 * @interface AttachProductLineResponse
 */
export interface AttachProductLineResponse {
    /**
     * 
     * @type {string}
     * @memberof AttachProductLineResponse
     */
    'productLineId': string;
    /**
     * 
     * @type {string}
     * @memberof AttachProductLineResponse
     */
    'marketId': string;
}
/**
 * Request body for cloning an existing product tree.
 * @export
 * @interface AttachProductTreePostRequest
 */
export interface AttachProductTreePostRequest {
    /**
     * 
     * @type {string}
     * @memberof AttachProductTreePostRequest
     */
    'targetCustomerMarketId': string;
}
/**
 * BasketMetric pydantic model.
 * @export
 * @interface BasketMetricsInput
 */
export interface BasketMetricsInput {
    /**
     * 
     * @type {string}
     * @memberof BasketMetricsInput
     */
    'entity': string;
    /**
     * 
     * @type {string}
     * @memberof BasketMetricsInput
     */
    'rxType': string;
    /**
     * 
     * @type {string}
     * @memberof BasketMetricsInput
     */
    'rxSourceType': string;
    /**
     * 
     * @type {number}
     * @memberof BasketMetricsInput
     */
    'displayPriority': number | null;
    /**
     * 
     * @type {Scoringweight}
     * @memberof BasketMetricsInput
     */
    'scoringWeight': Scoringweight;
}
/**
 * BasketMetric pydantic model.
 * @export
 * @interface BasketMetricsOutput
 */
export interface BasketMetricsOutput {
    /**
     * 
     * @type {string}
     * @memberof BasketMetricsOutput
     */
    'entity': string;
    /**
     * 
     * @type {string}
     * @memberof BasketMetricsOutput
     */
    'rxType': string;
    /**
     * 
     * @type {string}
     * @memberof BasketMetricsOutput
     */
    'rxSourceType': string;
    /**
     * 
     * @type {number}
     * @memberof BasketMetricsOutput
     */
    'displayPriority': number | null;
    /**
     * 
     * @type {string}
     * @memberof BasketMetricsOutput
     */
    'scoringWeight': string;
}
/**
 * 
 * @export
 * @interface Basketscoringweight
 */
export interface Basketscoringweight {
}
/**
 * 
 * @export
 * @interface Basketscoringweight1
 */
export interface Basketscoringweight1 {
}
/**
 * 
 * @export
 * @interface Buildestimate
 */
export interface Buildestimate {
}
/**
 * Request body for cloning an existing product tree.
 * @export
 * @interface CloneProductTreePostRequest
 */
export interface CloneProductTreePostRequest {
    /**
     * 
     * @type {string}
     * @memberof CloneProductTreePostRequest
     */
    'productLine': string;
    /**
     * 
     * @type {string}
     * @memberof CloneProductTreePostRequest
     */
    'customerMarketId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CloneProductTreePostRequest
     */
    'friendlyName': string;
    /**
     * 
     * @type {string}
     * @memberof CloneProductTreePostRequest
     */
    'level': string;
    /**
     * 
     * @type {string}
     * @memberof CloneProductTreePostRequest
     */
    'productName': string;
    /**
     * 
     * @type {string}
     * @memberof CloneProductTreePostRequest
     */
    'sourceProductLineId': string;
}
/**
 * Customer market request body model.
 * @export
 * @interface CreateCustomerMarketRequest
 */
export interface CreateCustomerMarketRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerMarketRequest
     */
    'market': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerMarketRequest
     */
    'regionalTeamId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCustomerMarketRequest
     */
    'specialty': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCustomerMarketRequest
     */
    'indications': Array<string>;
    /**
     * 
     * @type {{ [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; }}
     * @memberof CreateCustomerMarketRequest
     */
    'basketsScoringWeight'?: { [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; }; }}
     * @memberof CreateCustomerMarketRequest
     */
    'metricsScoringWeight'?: { [key: string]: { [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; }; } | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerMarketRequest
     */
    'therapeuticArea'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateCustomerMarketRequestBasketsScoringWeightValue
 */
export interface CreateCustomerMarketRequestBasketsScoringWeightValue {
}
/**
 * Request body for creating a new product tree.
 * @export
 * @interface CreateProductTreePostRequest
 */
export interface CreateProductTreePostRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProductTreePostRequest
     */
    'productLine': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductTreePostRequest
     */
    'customerMarketId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductTreePostRequest
     */
    'friendlyName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductTreePostRequest
     */
    'level': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductTreePostRequest
     */
    'productName': string;
}
/**
 * 
 * @export
 * @interface Creationdate
 */
export interface Creationdate {
}
/**
 * Main class for CustomerAccount.
 * @export
 * @interface CustomerAccount
 */
export interface CustomerAccount {
    /**
     * 
     * @type {RegionalTeams}
     * @memberof CustomerAccount
     */
    'regionalTeams': RegionalTeams;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface CustomerMarketInput
 */
export interface CustomerMarketInput {
    /**
     * 
     * @type {string}
     * @memberof CustomerMarketInput
     */
    'marketId': string;
    /**
     * 
     * @type {{ [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; }}
     * @memberof CustomerMarketInput
     */
    'basketsScoringWeight'?: { [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; }; }}
     * @memberof CustomerMarketInput
     */
    'categoricalMetricsScoringWeight'?: { [key: string]: { [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; }; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerMarketInput
     */
    'indications': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CustomerMarketInput
     */
    'market': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerMarketInput
     */
    'metrics'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; }; }}
     * @memberof CustomerMarketInput
     */
    'metricsScoringWeight'?: { [key: string]: { [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: { [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; }; }; }}
     * @memberof CustomerMarketInput
     */
    'entityMetricsScoringWeight'?: { [key: string]: { [key: string]: { [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; }; }; } | null;
    /**
     * 
     * @type {Array<HelperInterfaceDynamodbCustomerMarketsProductLineInput>}
     * @memberof CustomerMarketInput
     */
    'productLines': Array<HelperInterfaceDynamodbCustomerMarketsProductLineInput>;
    /**
     * 
     * @type {string}
     * @memberof CustomerMarketInput
     */
    'regionalTeamId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerMarketInput
     */
    'specialty': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerMarketInput
     */
    'totalIndications'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerMarketInput
     */
    'trainingSpecialties'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerMarketInput
     */
    'therapeuticArea'?: string | null;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface CustomerMarketOutput
 */
export interface CustomerMarketOutput {
    /**
     * 
     * @type {string}
     * @memberof CustomerMarketOutput
     */
    'marketId': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CustomerMarketOutput
     */
    'basketsScoringWeight'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof CustomerMarketOutput
     */
    'categoricalMetricsScoringWeight'?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerMarketOutput
     */
    'indications': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CustomerMarketOutput
     */
    'market': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerMarketOutput
     */
    'metrics'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof CustomerMarketOutput
     */
    'metricsScoringWeight'?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: { [key: string]: string; }; }; }}
     * @memberof CustomerMarketOutput
     */
    'entityMetricsScoringWeight'?: { [key: string]: { [key: string]: { [key: string]: string; }; }; } | null;
    /**
     * 
     * @type {Array<HelperInterfaceDynamodbCustomerMarketsProductLineOutput>}
     * @memberof CustomerMarketOutput
     */
    'productLines': Array<HelperInterfaceDynamodbCustomerMarketsProductLineOutput>;
    /**
     * 
     * @type {string}
     * @memberof CustomerMarketOutput
     */
    'regionalTeamId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerMarketOutput
     */
    'specialty': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerMarketOutput
     */
    'totalIndications'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerMarketOutput
     */
    'trainingSpecialties'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerMarketOutput
     */
    'therapeuticArea'?: string | null;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface CustomerMarkets
 */
export interface CustomerMarkets {
    /**
     * 
     * @type {Array<CustomerMarketOutput>}
     * @memberof CustomerMarkets
     */
    'markets': Array<CustomerMarketOutput>;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface CustomerMetrics
 */
export interface CustomerMetrics {
    /**
     * 
     * @type {{ [key: string]: MetricObj; }}
     * @memberof CustomerMetrics
     */
    'metrics': { [key: string]: MetricObj; } | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerMetrics
     */
    's3Bucket'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerMetrics
     */
    's3Path'?: string | null;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface CustomerObjectiveTypes
 */
export interface CustomerObjectiveTypes {
    /**
     * 
     * @type {Array<ObjectiveTypeOutput>}
     * @memberof CustomerObjectiveTypes
     */
    'objectiveTypes': Array<ObjectiveTypeOutput>;
}
/**
 * customerProject model.
 * @export
 * @interface CustomerProjectInput
 */
export interface CustomerProjectInput {
    /**
     * 
     * @type {FeaturesInput}
     * @memberof CustomerProjectInput
     */
    'features'?: FeaturesInput | null;
    /**
     * 
     * @type {MetadataInput}
     * @memberof CustomerProjectInput
     */
    'metadata': MetadataInput;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerProjectInput
     */
    'objectives'?: Array<string> | null;
}
/**
 * customerProject model.
 * @export
 * @interface CustomerProjectOutput
 */
export interface CustomerProjectOutput {
    /**
     * 
     * @type {FeaturesOutput}
     * @memberof CustomerProjectOutput
     */
    'features'?: FeaturesOutput | null;
    /**
     * 
     * @type {MetadataOutput}
     * @memberof CustomerProjectOutput
     */
    'metadata': MetadataOutput;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerProjectOutput
     */
    'objectives'?: Array<string> | null;
}
/**
 * customerProjects model.
 * @export
 * @interface CustomerProjects
 */
export interface CustomerProjects {
    /**
     * 
     * @type {{ [key: string]: CustomerProjectInput; }}
     * @memberof CustomerProjects
     */
    'projects': { [key: string]: CustomerProjectInput; };
}
/**
 * Response model for customer segments.
 * @export
 * @interface CustomerSegments
 */
export interface CustomerSegments {
    /**
     * 
     * @type {Array<SegmentOutput>}
     * @memberof CustomerSegments
     */
    'segments': Array<SegmentOutput> | null;
}
/**
 * TO DO.
 * @export
 * @interface Description
 */
export interface Description {
    /**
     * 
     * @type {string}
     * @memberof Description
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Description
     */
    'label'?: string | null;
}
/**
 * 
 * @export
 * @interface Description1
 */
export interface Description1 {
}
/**
 * 
 * @export
 * @interface Feature
 */
export interface Feature {
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface FeaturesInput
 */
export interface FeaturesInput {
    /**
     * 
     * @type {NextBestAudience}
     * @memberof FeaturesInput
     */
    'nextBestAudience': NextBestAudience;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface FeaturesOutput
 */
export interface FeaturesOutput {
    /**
     * 
     * @type {NextBestAudience}
     * @memberof FeaturesOutput
     */
    'nextBestAudience': NextBestAudience;
}
/**
 * TO DO.
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'feature'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface HelperInterfaceDynamodbCommonParamsInput
 */
export interface HelperInterfaceDynamodbCommonParamsInput {
    /**
     * 
     * @type {Array<HelperInterfaceDynamodbCommonProductInput>}
     * @memberof HelperInterfaceDynamodbCommonParamsInput
     */
    'products': Array<HelperInterfaceDynamodbCommonProductInput>;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface HelperInterfaceDynamodbCommonParamsOutput
 */
export interface HelperInterfaceDynamodbCommonParamsOutput {
    /**
     * 
     * @type {Array<HelperInterfaceDynamodbCommonProductOutput>}
     * @memberof HelperInterfaceDynamodbCommonParamsOutput
     */
    'products': Array<HelperInterfaceDynamodbCommonProductOutput>;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface HelperInterfaceDynamodbCommonProductInput
 */
export interface HelperInterfaceDynamodbCommonProductInput {
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCommonProductInput
     */
    'paramText': string;
    /**
     * 
     * @type {Basketscoringweight}
     * @memberof HelperInterfaceDynamodbCommonProductInput
     */
    'basketScoringWeight'?: Basketscoringweight;
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCommonProductInput
     */
    'paramType': string;
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCommonProductInput
     */
    'productFriendlyName'?: string | null;
    /**
     * 
     * @type {Array<HelperInterfaceDynamodbCommonProductLine>}
     * @memberof HelperInterfaceDynamodbCommonProductInput
     */
    'productLines'?: Array<HelperInterfaceDynamodbCommonProductLine>;
    /**
     * 
     * @type {{ [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; }}
     * @memberof HelperInterfaceDynamodbCommonProductInput
     */
    'metricsScoringWeight'?: { [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; };
    /**
     * 
     * @type {Array<string>}
     * @memberof HelperInterfaceDynamodbCommonProductInput
     */
    'indications'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof HelperInterfaceDynamodbCommonProductInput
     */
    'specialties'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof HelperInterfaceDynamodbCommonProductInput
     */
    'therapeuticAreas'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; }}
     * @memberof HelperInterfaceDynamodbCommonProductInput
     */
    'displayableMetrics'?: { [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; };
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface HelperInterfaceDynamodbCommonProductLine
 */
export interface HelperInterfaceDynamodbCommonProductLine {
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCommonProductLine
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCommonProductLine
     */
    'productName': string;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface HelperInterfaceDynamodbCommonProductOutput
 */
export interface HelperInterfaceDynamodbCommonProductOutput {
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCommonProductOutput
     */
    'paramText': string;
    /**
     * 
     * @type {Basketscoringweight1}
     * @memberof HelperInterfaceDynamodbCommonProductOutput
     */
    'basketScoringWeight'?: Basketscoringweight1;
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCommonProductOutput
     */
    'paramType': string;
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCommonProductOutput
     */
    'productFriendlyName'?: string | null;
    /**
     * 
     * @type {Array<HelperInterfaceDynamodbCommonProductLine>}
     * @memberof HelperInterfaceDynamodbCommonProductOutput
     */
    'productLines'?: Array<HelperInterfaceDynamodbCommonProductLine>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof HelperInterfaceDynamodbCommonProductOutput
     */
    'metricsScoringWeight'?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof HelperInterfaceDynamodbCommonProductOutput
     */
    'indications'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof HelperInterfaceDynamodbCommonProductOutput
     */
    'specialties'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof HelperInterfaceDynamodbCommonProductOutput
     */
    'therapeuticAreas'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof HelperInterfaceDynamodbCommonProductOutput
     */
    'displayableMetrics'?: { [key: string]: string; };
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface HelperInterfaceDynamodbCustomerMarketsProductInput
 */
export interface HelperInterfaceDynamodbCustomerMarketsProductInput {
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductInput
     */
    'friendlyName': string;
    /**
     * 
     * @type {boolean}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductInput
     */
    'isPurelyCompetitor'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductInput
     */
    'isPurelyOwnBrand'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductInput
     */
    'therapeuticAreas'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductInput
     */
    'level': string;
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductInput
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductInput
     */
    'productName': string;
    /**
     * 
     * @type {Array<HelperInterfaceDynamodbCustomerMarketsProductInput>}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductInput
     */
    'subCategories': Array<HelperInterfaceDynamodbCustomerMarketsProductInput>;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface HelperInterfaceDynamodbCustomerMarketsProductLineInput
 */
export interface HelperInterfaceDynamodbCustomerMarketsProductLineInput {
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductLineInput
     */
    'productLine': string;
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductLineInput
     */
    'productLineId': string;
    /**
     * 
     * @type {HelperInterfaceDynamodbCustomerMarketsProductInput}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductLineInput
     */
    'productTree': HelperInterfaceDynamodbCustomerMarketsProductInput;
    /**
     * 
     * @type {Array<string>}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductLineInput
     */
    'therapeuticAreas'?: Array<string> | null;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface HelperInterfaceDynamodbCustomerMarketsProductLineOutput
 */
export interface HelperInterfaceDynamodbCustomerMarketsProductLineOutput {
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductLineOutput
     */
    'productLine': string;
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductLineOutput
     */
    'productLineId': string;
    /**
     * 
     * @type {HelperInterfaceDynamodbCustomerMarketsProductOutput}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductLineOutput
     */
    'productTree': HelperInterfaceDynamodbCustomerMarketsProductOutput;
    /**
     * 
     * @type {Array<string>}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductLineOutput
     */
    'therapeuticAreas'?: Array<string> | null;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface HelperInterfaceDynamodbCustomerMarketsProductOutput
 */
export interface HelperInterfaceDynamodbCustomerMarketsProductOutput {
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductOutput
     */
    'friendlyName': string;
    /**
     * 
     * @type {boolean}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductOutput
     */
    'isPurelyCompetitor'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductOutput
     */
    'isPurelyOwnBrand'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductOutput
     */
    'therapeuticAreas'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductOutput
     */
    'level': string;
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductOutput
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductOutput
     */
    'productName': string;
    /**
     * 
     * @type {Array<HelperInterfaceDynamodbCustomerMarketsProductOutput>}
     * @memberof HelperInterfaceDynamodbCustomerMarketsProductOutput
     */
    'subCategories': Array<HelperInterfaceDynamodbCustomerMarketsProductOutput>;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface HelperInterfaceDynamodbCustomerObjectiveTypesParamsInput
 */
export interface HelperInterfaceDynamodbCustomerObjectiveTypesParamsInput {
    /**
     * 
     * @type {Array<HelperInterfaceDynamodbCustomerObjectiveTypesProduct>}
     * @memberof HelperInterfaceDynamodbCustomerObjectiveTypesParamsInput
     */
    'products': Array<HelperInterfaceDynamodbCustomerObjectiveTypesProduct>;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface HelperInterfaceDynamodbCustomerObjectiveTypesParamsOutput
 */
export interface HelperInterfaceDynamodbCustomerObjectiveTypesParamsOutput {
    /**
     * 
     * @type {Array<HelperInterfaceDynamodbCustomerObjectiveTypesProduct>}
     * @memberof HelperInterfaceDynamodbCustomerObjectiveTypesParamsOutput
     */
    'products': Array<HelperInterfaceDynamodbCustomerObjectiveTypesProduct>;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface HelperInterfaceDynamodbCustomerObjectiveTypesProduct
 */
export interface HelperInterfaceDynamodbCustomerObjectiveTypesProduct {
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCustomerObjectiveTypesProduct
     */
    'paramText': string;
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCustomerObjectiveTypesProduct
     */
    'paramType': string;
    /**
     * 
     * @type {string}
     * @memberof HelperInterfaceDynamodbCustomerObjectiveTypesProduct
     */
    'productFriendlyName': string;
    /**
     * 
     * @type {HelperInterfaceDynamodbCommonProductLine}
     * @memberof HelperInterfaceDynamodbCustomerObjectiveTypesProduct
     */
    'productLine'?: HelperInterfaceDynamodbCommonProductLine | null;
}
/**
 * 
 * @export
 * @interface Items
 */
export interface Items {
    /**
     * 
     * @type {string}
     * @memberof Items
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Items
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Items
     */
    'type': ItemsTypeEnum;
    /**
     * 
     * @type {{ [key: string]: PropertiesValue; }}
     * @memberof Items
     */
    'properties': { [key: string]: PropertiesValue; };
    /**
     * 
     * @type {Items}
     * @memberof Items
     */
    'items': Items;
}

export const ItemsTypeEnum = {
    Object: 'object',
    Array: 'array',
    String: 'string',
    Number: 'number',
    Boolean: 'boolean'
} as const;

export type ItemsTypeEnum = typeof ItemsTypeEnum[keyof typeof ItemsTypeEnum];

/**
 * 
 * @export
 * @interface Items1
 */
export interface Items1 {
    /**
     * 
     * @type {string}
     * @memberof Items1
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Items1
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Items1
     */
    'type': Items1TypeEnum;
    /**
     * 
     * @type {{ [key: string]: PropertiesValue1; }}
     * @memberof Items1
     */
    'properties': { [key: string]: PropertiesValue1; };
    /**
     * 
     * @type {Items1}
     * @memberof Items1
     */
    'items': Items1;
}

export const Items1TypeEnum = {
    Object: 'object',
    Array: 'array',
    String: 'string',
    Number: 'number',
    Boolean: 'boolean'
} as const;

export type Items1TypeEnum = typeof Items1TypeEnum[keyof typeof Items1TypeEnum];

/**
 * TO DO.
 * @export
 * @interface MaptualList
 */
export interface MaptualList {
    /**
     * 
     * @type {string}
     * @memberof MaptualList
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof MaptualList
     */
    'order': number;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface MetadataInput
 */
export interface MetadataInput {
    /**
     * 
     * @type {Creationdate}
     * @memberof MetadataInput
     */
    'creationDate': Creationdate;
    /**
     * 
     * @type {boolean}
     * @memberof MetadataInput
     */
    'isBusy'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MetadataInput
     */
    'isNightlyRun'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MetadataInput
     */
    'isSampleRun'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MetadataInput
     */
    'isScoreInDwh'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'lastUpdated': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'market': string;
    /**
     * 
     * @type {Array<ObjectiveInformationInput>}
     * @memberof MetadataInput
     */
    'objectives'?: Array<ObjectiveInformationInput> | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'organization'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'ownerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'ownerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'productLine': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'productLineId': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'projectHash'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'projectImportId': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'projectStatus': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'refreshStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'regionalTeamId': string;
    /**
     * 
     * @type {object}
     * @memberof MetadataInput
     */
    'signal'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'snapshotUrl'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MetadataInput
     */
    'specialties'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'team'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'timestampDeployed': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'dueDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'changeLog'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'taskStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'marketId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'parentProjectId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'importType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataInput
     */
    'templateId'?: string | null;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface MetadataOutput
 */
export interface MetadataOutput {
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'creationDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof MetadataOutput
     */
    'isBusy'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MetadataOutput
     */
    'isNightlyRun'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MetadataOutput
     */
    'isSampleRun'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MetadataOutput
     */
    'isScoreInDwh'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'lastUpdated': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'market': string;
    /**
     * 
     * @type {Array<ObjectiveInformationOutput>}
     * @memberof MetadataOutput
     */
    'objectives'?: Array<ObjectiveInformationOutput> | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'organization'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'ownerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'ownerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'productLine': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'productLineId': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'projectHash'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'projectImportId': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'projectStatus': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'refreshStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'regionalTeamId': string;
    /**
     * 
     * @type {object}
     * @memberof MetadataOutput
     */
    'signal'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'snapshotUrl'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MetadataOutput
     */
    'specialties'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'team'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'timestampDeployed': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'dueDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'changeLog'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'taskStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'marketId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'parentProjectId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'importType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetadataOutput
     */
    'templateId'?: string | null;
}
/**
 * MetricObj pydantic model.
 * @export
 * @interface MetricObj
 */
export interface MetricObj {
    /**
     * 
     * @type {boolean}
     * @memberof MetricObj
     */
    'createRxExport'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MetricObj
     */
    'createRxTable'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MetricObj
     */
    'displayGroupPriority': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MetricObj
     */
    'displayMetric': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MetricObj
     */
    'lastTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof MetricObj
     */
    'metricActivityLabel': string;
    /**
     * 
     * @type {string}
     * @memberof MetricObj
     */
    'metricDType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetricObj
     */
    'metricRxVolumeUnit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetricObj
     */
    'metricRxDistribution'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetricObj
     */
    'metricTableName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricObj
     */
    'metricEntityType': string;
    /**
     * 
     * @type {string}
     * @memberof MetricObj
     */
    'metricFriendlyName': string;
    /**
     * 
     * @type {string}
     * @memberof MetricObj
     */
    'metricId': string;
    /**
     * 
     * @type {string}
     * @memberof MetricObj
     */
    'metricInternalName': string;
    /**
     * 
     * @type {MetricRxCadence}
     * @memberof MetricObj
     */
    'metricRxCadence': MetricRxCadence;
    /**
     * 
     * @type {string}
     * @memberof MetricObj
     */
    'metricRxSource': string;
    /**
     * 
     * @type {string}
     * @memberof MetricObj
     */
    'metricRxType': string;
    /**
     * 
     * @type {boolean}
     * @memberof MetricObj
     */
    'predictMetric'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MetricObj
     */
    'rxSourceType': string;
    /**
     * 
     * @type {number}
     * @memberof MetricObj
     */
    'metricScoreDominancePriority'?: number | null;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface MetricRxCadence
 */
export interface MetricRxCadence {
    /**
     * 
     * @type {string}
     * @memberof MetricRxCadence
     */
    'feature': string;
    /**
     * 
     * @type {string}
     * @memberof MetricRxCadence
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof MetricRxCadence
     */
    'originalValue'?: string | null;
}
/**
 * Metric List Pydantic model.
 * @export
 * @interface MetricsList
 */
export interface MetricsList {
    /**
     * 
     * @type {{ [key: string]: MetricObj; }}
     * @memberof MetricsList
     */
    'metrics': { [key: string]: MetricObj; };
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface NextBestAudience
 */
export interface NextBestAudience {
    /**
     * 
     * @type {Array<number>}
     * @memberof NextBestAudience
     */
    'predictionGaps'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof NextBestAudience
     */
    'sampleRate': number;
    /**
     * 
     * @type {SimulationDates}
     * @memberof NextBestAudience
     */
    'simulationDates': SimulationDates;
    /**
     * 
     * @type {number}
     * @memberof NextBestAudience
     */
    'targetPeriod': number;
}
/**
 * Response model for an ObjectSchema.
 * @export
 * @interface ObjectSchemaInput
 */
export interface ObjectSchemaInput {
    /**
     * 
     * @type {string}
     * @memberof ObjectSchemaInput
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectSchemaInput
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectSchemaInput
     */
    'type': ObjectSchemaInputTypeEnum;
    /**
     * 
     * @type {{ [key: string]: PropertiesValue; }}
     * @memberof ObjectSchemaInput
     */
    'properties': { [key: string]: PropertiesValue; };
}

export const ObjectSchemaInputTypeEnum = {
    Object: 'object'
} as const;

export type ObjectSchemaInputTypeEnum = typeof ObjectSchemaInputTypeEnum[keyof typeof ObjectSchemaInputTypeEnum];

/**
 * Response model for an ObjectSchema.
 * @export
 * @interface ObjectSchemaOutput
 */
export interface ObjectSchemaOutput {
    /**
     * 
     * @type {string}
     * @memberof ObjectSchemaOutput
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectSchemaOutput
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectSchemaOutput
     */
    'type': ObjectSchemaOutputTypeEnum;
    /**
     * 
     * @type {{ [key: string]: PropertiesValue1; }}
     * @memberof ObjectSchemaOutput
     */
    'properties': { [key: string]: PropertiesValue1; };
}

export const ObjectSchemaOutputTypeEnum = {
    Object: 'object'
} as const;

export type ObjectSchemaOutputTypeEnum = typeof ObjectSchemaOutputTypeEnum[keyof typeof ObjectSchemaOutputTypeEnum];

/**
 * TODO: Add docstring here.
 * @export
 * @interface ObjectiveInformationInput
 */
export interface ObjectiveInformationInput {
    /**
     * 
     * @type {string}
     * @memberof ObjectiveInformationInput
     */
    'objectiveId': string;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveInformationInput
     */
    'objectiveHash'?: string | null;
    /**
     * 
     * @type {HelperInterfaceDynamodbCommonParamsInput}
     * @memberof ObjectiveInformationInput
     */
    'params': HelperInterfaceDynamodbCommonParamsInput;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveInformationInput
     */
    'typeDisplay': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectiveInformationInput
     */
    'indications'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectiveInformationInput
     */
    'specialties'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveInformationInput
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveInformationInput
     */
    'fullDisplayName'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof ObjectiveInformationInput
     */
    'shareMetric'?: object | null;
    /**
     * 
     * @type {object}
     * @memberof ObjectiveInformationInput
     */
    'metrics'?: object | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectiveInformationInput
     */
    'therapeuticAreas'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveInformationInput
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveInformationInput
     */
    'projectId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ObjectiveInformationInput
     */
    'nonScoringObjective'?: boolean | null;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface ObjectiveInformationOutput
 */
export interface ObjectiveInformationOutput {
    /**
     * 
     * @type {string}
     * @memberof ObjectiveInformationOutput
     */
    'objectiveId': string;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveInformationOutput
     */
    'objectiveHash'?: string | null;
    /**
     * 
     * @type {HelperInterfaceDynamodbCommonParamsOutput}
     * @memberof ObjectiveInformationOutput
     */
    'params': HelperInterfaceDynamodbCommonParamsOutput;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveInformationOutput
     */
    'typeDisplay': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectiveInformationOutput
     */
    'indications'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectiveInformationOutput
     */
    'specialties'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveInformationOutput
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveInformationOutput
     */
    'fullDisplayName'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof ObjectiveInformationOutput
     */
    'shareMetric'?: object | null;
    /**
     * 
     * @type {object}
     * @memberof ObjectiveInformationOutput
     */
    'metrics'?: object | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectiveInformationOutput
     */
    'therapeuticAreas'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveInformationOutput
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveInformationOutput
     */
    'projectId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ObjectiveInformationOutput
     */
    'nonScoringObjective'?: boolean | null;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface ObjectiveTypeInput
 */
export interface ObjectiveTypeInput {
    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectiveTypeInput
     */
    'features': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveTypeInput
     */
    'typeDisplay': string;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveTypeInput
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveTypeInput
     */
    'typeDescription': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectiveTypeInput
     */
    'metrics'?: Array<string> | null;
    /**
     * 
     * @type {HelperInterfaceDynamodbCustomerObjectiveTypesParamsInput}
     * @memberof ObjectiveTypeInput
     */
    'params': HelperInterfaceDynamodbCustomerObjectiveTypesParamsInput;
    /**
     * 
     * @type {TrendDurationInput}
     * @memberof ObjectiveTypeInput
     */
    'trendDuration': TrendDurationInput;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveTypeInput
     */
    'type': string;
    /**
     * 
     * @type {Buildestimate}
     * @memberof ObjectiveTypeInput
     */
    'buildEstimate'?: Buildestimate | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveTypeInput
     */
    'objectiveTypeId'?: string | null;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface ObjectiveTypeOutput
 */
export interface ObjectiveTypeOutput {
    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectiveTypeOutput
     */
    'features': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveTypeOutput
     */
    'typeDisplay': string;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveTypeOutput
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveTypeOutput
     */
    'typeDescription': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectiveTypeOutput
     */
    'metrics'?: Array<string> | null;
    /**
     * 
     * @type {HelperInterfaceDynamodbCustomerObjectiveTypesParamsOutput}
     * @memberof ObjectiveTypeOutput
     */
    'params': HelperInterfaceDynamodbCustomerObjectiveTypesParamsOutput;
    /**
     * 
     * @type {TrendDurationOutput}
     * @memberof ObjectiveTypeOutput
     */
    'trendDuration': TrendDurationOutput;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveTypeOutput
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveTypeOutput
     */
    'buildEstimate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveTypeOutput
     */
    'objectiveTypeId'?: string | null;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface ObjectiveTypePostRequest
 */
export interface ObjectiveTypePostRequest {
    /**
     * 
     * @type {string}
     * @memberof ObjectiveTypePostRequest
     */
    'typeDisplay': string;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveTypePostRequest
     */
    'typeDescription': string;
    /**
     * 
     * @type {HelperInterfaceDynamodbCustomerObjectiveTypesParamsInput}
     * @memberof ObjectiveTypePostRequest
     */
    'params': HelperInterfaceDynamodbCustomerObjectiveTypesParamsInput;
    /**
     * 
     * @type {string}
     * @memberof ObjectiveTypePostRequest
     */
    'type': string;
}
/**
 * Model for a PrimitiveSchema.
 * @export
 * @interface PrimitiveSchema
 */
export interface PrimitiveSchema {
    /**
     * 
     * @type {string}
     * @memberof PrimitiveSchema
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PrimitiveSchema
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PrimitiveSchema
     */
    'type': PrimitiveSchemaTypeEnum;
}

export const PrimitiveSchemaTypeEnum = {
    String: 'string',
    Number: 'number',
    Boolean: 'boolean'
} as const;

export type PrimitiveSchemaTypeEnum = typeof PrimitiveSchemaTypeEnum[keyof typeof PrimitiveSchemaTypeEnum];

/**
 * Response model for a product line.
 * @export
 * @interface ProduceLineResponseObject
 */
export interface ProduceLineResponseObject {
    /**
     * 
     * @type {string}
     * @memberof ProduceLineResponseObject
     */
    'productLine': string;
    /**
     * 
     * @type {string}
     * @memberof ProduceLineResponseObject
     */
    'productLineId': string;
    /**
     * 
     * @type {HelperInterfaceDynamodbCustomerMarketsProductOutput}
     * @memberof ProduceLineResponseObject
     */
    'productTree': HelperInterfaceDynamodbCustomerMarketsProductOutput;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProduceLineResponseObject
     */
    'therapeuticAreas'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProduceLineResponseObject
     */
    'marketName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProduceLineResponseObject
     */
    'marketId'?: string | null;
}
/**
 * TO DO.
 * @export
 * @interface ProjectSegmentLabelGenerator
 */
export interface ProjectSegmentLabelGenerator {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSegmentLabelGenerator
     */
    'doAggregation'?: boolean;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ProjectSegmentLabelGenerator
     */
    'histFlags'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ProjectSegmentLabelGenerator
     */
    'cdVals'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ProjectSegmentLabelGenerator
     */
    'objectiveWeights'?: { [key: string]: number; };
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSegmentLabelGenerator
     */
    'doScoreRebase'?: boolean;
}
/**
 * Project template model.
 * @export
 * @interface ProjectTemplate
 */
export interface ProjectTemplate {
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplate
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplate
     */
    'templateName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplate
     */
    'productLineId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplate
     */
    'marketId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplate
     */
    'marketName': string;
    /**
     * 
     * @type {Array<ProjectTemplateObjectiveOutput>}
     * @memberof ProjectTemplate
     */
    'objectives'?: Array<ProjectTemplateObjectiveOutput>;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplate
     */
    'sourceTemplateId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectTemplate
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplate
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplate
     */
    'updatedAt': string;
}
/**
 * Basket model within a project template objective.
 * @export
 * @interface ProjectTemplateBasketInput
 */
export interface ProjectTemplateBasketInput {
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateBasketInput
     */
    'paramText': string;
    /**
     * 
     * @type {Basketscoringweight}
     * @memberof ProjectTemplateBasketInput
     */
    'basketScoringWeight'?: Basketscoringweight;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateBasketInput
     */
    'paramType': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateBasketInput
     */
    'productFriendlyName': string;
    /**
     * 
     * @type {Array<HelperInterfaceDynamodbCommonProductLine>}
     * @memberof ProjectTemplateBasketInput
     */
    'productLines'?: Array<HelperInterfaceDynamodbCommonProductLine>;
    /**
     * 
     * @type {{ [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; }}
     * @memberof ProjectTemplateBasketInput
     */
    'metricsScoringWeight': { [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; }; }}
     * @memberof ProjectTemplateBasketInput
     */
    'entityMetricsScoringWeight': { [key: string]: { [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; }; };
    /**
     * 
     * @type {Array<BasketMetricsInput>}
     * @memberof ProjectTemplateBasketInput
     */
    'basketMetrics'?: Array<BasketMetricsInput> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectTemplateBasketInput
     */
    'specialties'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectTemplateBasketInput
     */
    'indications'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectTemplateBasketInput
     */
    'therapeuticAreas'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ProjectTemplateBasketInput
     */
    'displayableMetrics'?: { [key: string]: number; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ProjectTemplateBasketInput
     */
    'entityDisplayableMetrics'?: { [key: string]: { [key: string]: number; }; } | null;
}
/**
 * Basket model within a project template objective.
 * @export
 * @interface ProjectTemplateBasketOutput
 */
export interface ProjectTemplateBasketOutput {
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateBasketOutput
     */
    'paramText': string;
    /**
     * 
     * @type {Basketscoringweight1}
     * @memberof ProjectTemplateBasketOutput
     */
    'basketScoringWeight'?: Basketscoringweight1;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateBasketOutput
     */
    'paramType': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateBasketOutput
     */
    'productFriendlyName': string;
    /**
     * 
     * @type {Array<HelperInterfaceDynamodbCommonProductLine>}
     * @memberof ProjectTemplateBasketOutput
     */
    'productLines'?: Array<HelperInterfaceDynamodbCommonProductLine>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProjectTemplateBasketOutput
     */
    'metricsScoringWeight': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof ProjectTemplateBasketOutput
     */
    'entityMetricsScoringWeight': { [key: string]: { [key: string]: string; }; };
    /**
     * 
     * @type {Array<BasketMetricsOutput>}
     * @memberof ProjectTemplateBasketOutput
     */
    'basketMetrics'?: Array<BasketMetricsOutput> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectTemplateBasketOutput
     */
    'specialties'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectTemplateBasketOutput
     */
    'indications'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectTemplateBasketOutput
     */
    'therapeuticAreas'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ProjectTemplateBasketOutput
     */
    'displayableMetrics'?: { [key: string]: number; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ProjectTemplateBasketOutput
     */
    'entityDisplayableMetrics'?: { [key: string]: { [key: string]: number; }; } | null;
}
/**
 * Objective model within a customer project.
 * @export
 * @interface ProjectTemplateObjectiveInput
 */
export interface ProjectTemplateObjectiveInput {
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateObjectiveInput
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateObjectiveInput
     */
    'typeDisplay': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectTemplateObjectiveInput
     */
    'nonScoringObjective': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectTemplateObjectiveInput
     */
    'therapeuticAreas'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectTemplateObjectiveInput
     */
    'indications'?: Array<string>;
    /**
     * 
     * @type {Array<ProjectTemplateBasketInput>}
     * @memberof ProjectTemplateObjectiveInput
     */
    'baskets'?: Array<ProjectTemplateBasketInput>;
    /**
     * 
     * @type {ShareMetric}
     * @memberof ProjectTemplateObjectiveInput
     */
    'shareMetric'?: ShareMetric | null;
}
/**
 * Objective model within a customer project.
 * @export
 * @interface ProjectTemplateObjectiveOutput
 */
export interface ProjectTemplateObjectiveOutput {
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateObjectiveOutput
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateObjectiveOutput
     */
    'typeDisplay': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectTemplateObjectiveOutput
     */
    'nonScoringObjective': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectTemplateObjectiveOutput
     */
    'therapeuticAreas'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectTemplateObjectiveOutput
     */
    'indications'?: Array<string>;
    /**
     * 
     * @type {Array<ProjectTemplateBasketOutput>}
     * @memberof ProjectTemplateObjectiveOutput
     */
    'baskets'?: Array<ProjectTemplateBasketOutput>;
    /**
     * 
     * @type {ShareMetric}
     * @memberof ProjectTemplateObjectiveOutput
     */
    'shareMetric'?: ShareMetric | null;
}
/**
 * Request body for creating a project template.
 * @export
 * @interface ProjectTemplateRequest
 */
export interface ProjectTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateRequest
     */
    'productLineId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateRequest
     */
    'templateName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateRequest
     */
    'marketId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTemplateRequest
     */
    'marketName': string;
    /**
     * 
     * @type {Array<ProjectTemplateObjectiveInput>}
     * @memberof ProjectTemplateRequest
     */
    'objectives'?: Array<ProjectTemplateObjectiveInput>;
}
/**
 * 
 * @export
 * @interface Projectsegmentlabelgenerator
 */
export interface Projectsegmentlabelgenerator {
    /**
     * 
     * @type {boolean}
     * @memberof Projectsegmentlabelgenerator
     */
    'doAggregation'?: boolean;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof Projectsegmentlabelgenerator
     */
    'histFlags'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof Projectsegmentlabelgenerator
     */
    'cdVals'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof Projectsegmentlabelgenerator
     */
    'objectiveWeights'?: { [key: string]: number; };
    /**
     * 
     * @type {boolean}
     * @memberof Projectsegmentlabelgenerator
     */
    'doScoreRebase'?: boolean;
}
/**
 * 
 * @export
 * @interface PropertiesValue
 */
export interface PropertiesValue {
    /**
     * 
     * @type {string}
     * @memberof PropertiesValue
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertiesValue
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertiesValue
     */
    'type': PropertiesValueTypeEnum;
    /**
     * 
     * @type {{ [key: string]: PropertiesValue; }}
     * @memberof PropertiesValue
     */
    'properties': { [key: string]: PropertiesValue; };
    /**
     * 
     * @type {Items}
     * @memberof PropertiesValue
     */
    'items': Items;
}

export const PropertiesValueTypeEnum = {
    Object: 'object',
    Array: 'array',
    String: 'string',
    Number: 'number',
    Boolean: 'boolean'
} as const;

export type PropertiesValueTypeEnum = typeof PropertiesValueTypeEnum[keyof typeof PropertiesValueTypeEnum];

/**
 * 
 * @export
 * @interface PropertiesValue1
 */
export interface PropertiesValue1 {
    /**
     * 
     * @type {string}
     * @memberof PropertiesValue1
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertiesValue1
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertiesValue1
     */
    'type': PropertiesValue1TypeEnum;
    /**
     * 
     * @type {{ [key: string]: PropertiesValue1; }}
     * @memberof PropertiesValue1
     */
    'properties': { [key: string]: PropertiesValue1; };
    /**
     * 
     * @type {Items1}
     * @memberof PropertiesValue1
     */
    'items': Items1;
}

export const PropertiesValue1TypeEnum = {
    Object: 'object',
    Array: 'array',
    String: 'string',
    Number: 'number',
    Boolean: 'boolean'
} as const;

export type PropertiesValue1TypeEnum = typeof PropertiesValue1TypeEnum[keyof typeof PropertiesValue1TypeEnum];

/**
 * response model for put request.
 * @export
 * @interface PutResponse
 */
export interface PutResponse {
    /**
     * 
     * @type {ResponseStatus}
     * @memberof PutResponse
     */
    'status': ResponseStatus;
    /**
     * 
     * @type {string}
     * @memberof PutResponse
     */
    'details': string;
}


/**
 * RegionalTeams in CustomerAccount.
 * @export
 * @interface RegionalTeams
 */
export interface RegionalTeams {
    /**
     * 
     * @type {string}
     * @memberof RegionalTeams
     */
    's3Bucket': string;
    /**
     * 
     * @type {string}
     * @memberof RegionalTeams
     */
    's3Path': string;
}
/**
 * CustomerDDBTable enum class for different environments.
 * @export
 * @enum {string}
 */

export const ResponseStatus = {
    Success: 'success',
    Failure: 'failure'
} as const;

export type ResponseStatus = typeof ResponseStatus[keyof typeof ResponseStatus];


/**
 * Response model for a product line.
 * @export
 * @interface SchemaSummary
 */
export interface SchemaSummary {
    /**
     * 
     * @type {string}
     * @memberof SchemaSummary
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SchemaSummary
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SchemaSummary
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface Scoringweight
 */
export interface Scoringweight {
}
/**
 * TO DO.
 * @export
 * @interface SegmentActivityGenerator
 */
export interface SegmentActivityGenerator {
    /**
     * 
     * @type {number}
     * @memberof SegmentActivityGenerator
     */
    'shrinkerMaxScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof SegmentActivityGenerator
     */
    'growerMinScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof SegmentActivityGenerator
     */
    'minPercentIncrease'?: number;
    /**
     * 
     * @type {number}
     * @memberof SegmentActivityGenerator
     */
    'monthsDelta'?: number;
    /**
     * 
     * @type {number}
     * @memberof SegmentActivityGenerator
     */
    'segmentWindow'?: number;
}
/**
 * TO DO.
 * @export
 * @interface SegmentInput
 */
export interface SegmentInput {
    /**
     * 
     * @type {Segmentactivitygenerator}
     * @memberof SegmentInput
     */
    'segmentActivityGenerator'?: Segmentactivitygenerator;
    /**
     * 
     * @type {boolean}
     * @memberof SegmentInput
     */
    'isRescoringInclusive'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SegmentInput
     */
    'metricIds'?: Array<string>;
    /**
     * 
     * @type {MaptualList}
     * @memberof SegmentInput
     */
    'maptualList'?: MaptualList;
    /**
     * 
     * @type {Array<any>}
     * @memberof SegmentInput
     */
    'productLineIds'?: Array<any> | null;
    /**
     * 
     * @type {string}
     * @memberof SegmentInput
     */
    'primaryText'?: string;
    /**
     * 
     * @type {number}
     * @memberof SegmentInput
     */
    'segmentLastUpdated'?: number;
    /**
     * 
     * @type {number}
     * @memberof SegmentInput
     */
    'segmentCreatedDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof SegmentInput
     */
    'activityName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SegmentInput
     */
    'icon'?: string | null;
    /**
     * 
     * @type {Segmentlabelgenerator}
     * @memberof SegmentInput
     */
    'segmentLabelGenerator'?: Segmentlabelgenerator;
    /**
     * 
     * @type {boolean}
     * @memberof SegmentInput
     */
    'isHiddenInSphere'?: boolean | null;
    /**
     * 
     * @type {Description1}
     * @memberof SegmentInput
     */
    'description'?: Description1;
    /**
     * 
     * @type {string}
     * @memberof SegmentInput
     */
    'segmentSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SegmentInput
     */
    'subsegmentName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SegmentInput
     */
    'isMaptualListFilter'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SegmentInput
     */
    'segmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SegmentInput
     */
    'secondaryText'?: string | null;
    /**
     * 
     * @type {Filter}
     * @memberof SegmentInput
     */
    'filter'?: Filter | null;
    /**
     * 
     * @type {number}
     * @memberof SegmentInput
     */
    'intercomId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SegmentInput
     */
    'segmentId'?: string;
    /**
     * 
     * @type {Projectsegmentlabelgenerator}
     * @memberof SegmentInput
     */
    'projectSegmentLabelGenerator'?: Projectsegmentlabelgenerator;
    /**
     * 
     * @type {string}
     * @memberof SegmentInput
     */
    'segmentType'?: string;
    /**
     * 
     * @type {Array<SegmentInput>}
     * @memberof SegmentInput
     */
    'nodes'?: Array<SegmentInput>;
}
/**
 * TO DO.
 * @export
 * @interface SegmentLabelGenerator
 */
export interface SegmentLabelGenerator {
    /**
     * 
     * @type {Array<string>}
     * @memberof SegmentLabelGenerator
     */
    'histFlags'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SegmentLabelGenerator
     */
    'cdValTable'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SegmentLabelGenerator
     */
    'metricIds'?: Array<string>;
    /**
     * 
     * @type {Feature}
     * @memberof SegmentLabelGenerator
     */
    'feature'?: Feature;
    /**
     * 
     * @type {string}
     * @memberof SegmentLabelGenerator
     */
    'targetValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof SegmentLabelGenerator
     */
    'segmentWindowMonths'?: number;
    /**
     * 
     * @type {string}
     * @memberof SegmentLabelGenerator
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof SegmentLabelGenerator
     */
    'table'?: string;
}
/**
 * TO DO.
 * @export
 * @interface SegmentOutput
 */
export interface SegmentOutput {
    /**
     * 
     * @type {Segmentactivitygenerator}
     * @memberof SegmentOutput
     */
    'segmentActivityGenerator'?: Segmentactivitygenerator;
    /**
     * 
     * @type {boolean}
     * @memberof SegmentOutput
     */
    'isRescoringInclusive'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SegmentOutput
     */
    'metricIds'?: Array<string>;
    /**
     * 
     * @type {MaptualList}
     * @memberof SegmentOutput
     */
    'maptualList'?: MaptualList;
    /**
     * 
     * @type {Array<any>}
     * @memberof SegmentOutput
     */
    'productLineIds'?: Array<any> | null;
    /**
     * 
     * @type {string}
     * @memberof SegmentOutput
     */
    'primaryText'?: string;
    /**
     * 
     * @type {number}
     * @memberof SegmentOutput
     */
    'segmentLastUpdated'?: number;
    /**
     * 
     * @type {number}
     * @memberof SegmentOutput
     */
    'segmentCreatedDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof SegmentOutput
     */
    'activityName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SegmentOutput
     */
    'icon'?: string | null;
    /**
     * 
     * @type {Segmentlabelgenerator}
     * @memberof SegmentOutput
     */
    'segmentLabelGenerator'?: Segmentlabelgenerator;
    /**
     * 
     * @type {boolean}
     * @memberof SegmentOutput
     */
    'isHiddenInSphere'?: boolean | null;
    /**
     * 
     * @type {Description1}
     * @memberof SegmentOutput
     */
    'description'?: Description1;
    /**
     * 
     * @type {string}
     * @memberof SegmentOutput
     */
    'segmentSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SegmentOutput
     */
    'subsegmentName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SegmentOutput
     */
    'isMaptualListFilter'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SegmentOutput
     */
    'segmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SegmentOutput
     */
    'secondaryText'?: string | null;
    /**
     * 
     * @type {Filter}
     * @memberof SegmentOutput
     */
    'filter'?: Filter | null;
    /**
     * 
     * @type {number}
     * @memberof SegmentOutput
     */
    'intercomId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SegmentOutput
     */
    'segmentId'?: string;
    /**
     * 
     * @type {Projectsegmentlabelgenerator}
     * @memberof SegmentOutput
     */
    'projectSegmentLabelGenerator'?: Projectsegmentlabelgenerator;
    /**
     * 
     * @type {string}
     * @memberof SegmentOutput
     */
    'segmentType'?: string;
    /**
     * 
     * @type {Array<SegmentOutput>}
     * @memberof SegmentOutput
     */
    'nodes'?: Array<SegmentOutput>;
}
/**
 * 
 * @export
 * @interface Segmentactivitygenerator
 */
export interface Segmentactivitygenerator {
    /**
     * 
     * @type {number}
     * @memberof Segmentactivitygenerator
     */
    'shrinkerMaxScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof Segmentactivitygenerator
     */
    'growerMinScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof Segmentactivitygenerator
     */
    'minPercentIncrease'?: number;
    /**
     * 
     * @type {number}
     * @memberof Segmentactivitygenerator
     */
    'monthsDelta'?: number;
    /**
     * 
     * @type {number}
     * @memberof Segmentactivitygenerator
     */
    'segmentWindow'?: number;
}
/**
 * 
 * @export
 * @interface Segmentlabelgenerator
 */
export interface Segmentlabelgenerator {
    /**
     * 
     * @type {Array<string>}
     * @memberof Segmentlabelgenerator
     */
    'histFlags'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Segmentlabelgenerator
     */
    'cdValTable'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Segmentlabelgenerator
     */
    'metricIds'?: Array<string>;
    /**
     * 
     * @type {Feature}
     * @memberof Segmentlabelgenerator
     */
    'feature'?: Feature;
    /**
     * 
     * @type {string}
     * @memberof Segmentlabelgenerator
     */
    'targetValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof Segmentlabelgenerator
     */
    'segmentWindowMonths'?: number;
    /**
     * 
     * @type {string}
     * @memberof Segmentlabelgenerator
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof Segmentlabelgenerator
     */
    'table'?: string;
}
/**
 * Share Metric pydantic model.
 * @export
 * @interface ShareMetric
 */
export interface ShareMetric {
    /**
     * 
     * @type {string}
     * @memberof ShareMetric
     */
    'uuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShareMetric
     */
    'rxType'?: string | null;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface SimulationDates
 */
export interface SimulationDates {
    /**
     * 
     * @type {string}
     * @memberof SimulationDates
     */
    'earliest_date': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimulationDates
     */
    'latest_month_simulation_dates': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SimulationDates
     */
    'recommendation_date': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimulationDates
     */
    'sample_rate_simulation_dates': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimulationDates
     */
    'simulation_dates': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimulationDates
     */
    'static_label_dates': Array<string>;
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface TrendDurationInput
 */
export interface TrendDurationInput {
    /**
     * 
     * @type {{ [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; }}
     * @memberof TrendDurationInput
     */
    'long_term': { [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; };
    /**
     * 
     * @type {{ [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; }}
     * @memberof TrendDurationInput
     */
    'short_term': { [key: string]: CreateCustomerMarketRequestBasketsScoringWeightValue; };
}
/**
 * TODO: Add docstring here.
 * @export
 * @interface TrendDurationOutput
 */
export interface TrendDurationOutput {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TrendDurationOutput
     */
    'long_term': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TrendDurationOutput
     */
    'short_term': { [key: string]: string; };
}
/**
 * Request body for creating, edititing, or deleting a node.
 * @export
 * @interface UpdateProductTreeRequest
 */
export interface UpdateProductTreeRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateProductTreeRequest
     */
    'productLine': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductTreeRequest
     */
    'customerMarketId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductTreeRequest
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductTreeRequest
     */
    'parentNodeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductTreeRequest
     */
    'friendlyName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductTreeRequest
     */
    'level': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductTreeRequest
     */
    'productName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductTreeRequest
     */
    'operation': UpdateProductTreeRequestOperationEnum;
}

export const UpdateProductTreeRequestOperationEnum = {
    Create: 'CREATE',
    Update: 'UPDATE',
    Delete: 'DELETE'
} as const;

export type UpdateProductTreeRequestOperationEnum = typeof UpdateProductTreeRequestOperationEnum[keyof typeof UpdateProductTreeRequestOperationEnum];

/**
 * Body for the update_all endpoint.
 * @export
 * @interface UpdateSchemaAndValuesRequestBody
 */
export interface UpdateSchemaAndValuesRequestBody {
    /**
     * 
     * @type {ObjectSchemaInput}
     * @memberof UpdateSchemaAndValuesRequestBody
     */
    'config_schema': ObjectSchemaInput;
    /**
     * 
     * @type {object}
     * @memberof UpdateSchemaAndValuesRequestBody
     */
    'config_values': object;
    /**
     * 
     * @type {string}
     * @memberof UpdateSchemaAndValuesRequestBody
     */
    'schema_name': string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * CustomerAccountApi - axios parameter creator
 * @export
 */
export const CustomerAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * TODO add docstring.
         * @summary Get Customer Account
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAccountApiV1CustomerAccountGet: async (userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getCustomerAccountApiV1CustomerAccountGet', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get customer domains for a specific user group and environment.
         * @summary Get Customer Domains
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerDomainsApiV1CustomerDomainsGet: async (userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getCustomerDomainsApiV1CustomerDomainsGet', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-domains`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TODO add docstring.
         * @summary Put Customer Account
         * @param {string} userGroup 
         * @param {CustomerAccount} customerAccount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerAccountApiV1CustomerAccountPost: async (userGroup: string, customerAccount: CustomerAccount, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('putCustomerAccountApiV1CustomerAccountPost', 'userGroup', userGroup)
            // verify required parameter 'customerAccount' is not null or undefined
            assertParamExists('putCustomerAccountApiV1CustomerAccountPost', 'customerAccount', customerAccount)
            const localVarPath = `/api/v1/customer-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerAccount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update customer domains for a specific user group and environment.
         * @summary Update Customer Domains
         * @param {string} userGroup 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerDomainsApiV1CustomerDomainsPut: async (userGroup: string, requestBody: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('updateCustomerDomainsApiV1CustomerDomainsPut', 'userGroup', userGroup)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateCustomerDomainsApiV1CustomerDomainsPut', 'requestBody', requestBody)
            const localVarPath = `/api/v1/customer-domains`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerAccountApi - functional programming interface
 * @export
 */
export const CustomerAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * TODO add docstring.
         * @summary Get Customer Account
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerAccountApiV1CustomerAccountGet(userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerAccountApiV1CustomerAccountGet(userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerAccountApi.getCustomerAccountApiV1CustomerAccountGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get customer domains for a specific user group and environment.
         * @summary Get Customer Domains
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerDomainsApiV1CustomerDomainsGet(userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerDomainsApiV1CustomerDomainsGet(userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerAccountApi.getCustomerDomainsApiV1CustomerDomainsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * TODO add docstring.
         * @summary Put Customer Account
         * @param {string} userGroup 
         * @param {CustomerAccount} customerAccount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomerAccountApiV1CustomerAccountPost(userGroup: string, customerAccount: CustomerAccount, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomerAccountApiV1CustomerAccountPost(userGroup, customerAccount, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerAccountApi.putCustomerAccountApiV1CustomerAccountPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update customer domains for a specific user group and environment.
         * @summary Update Customer Domains
         * @param {string} userGroup 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerDomainsApiV1CustomerDomainsPut(userGroup: string, requestBody: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerDomainsApiV1CustomerDomainsPut(userGroup, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerAccountApi.updateCustomerDomainsApiV1CustomerDomainsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerAccountApi - factory interface
 * @export
 */
export const CustomerAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerAccountApiFp(configuration)
    return {
        /**
         * TODO add docstring.
         * @summary Get Customer Account
         * @param {CustomerAccountApiGetCustomerAccountApiV1CustomerAccountGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAccountApiV1CustomerAccountGet(requestParameters: CustomerAccountApiGetCustomerAccountApiV1CustomerAccountGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerAccount> {
            return localVarFp.getCustomerAccountApiV1CustomerAccountGet(requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Get customer domains for a specific user group and environment.
         * @summary Get Customer Domains
         * @param {CustomerAccountApiGetCustomerDomainsApiV1CustomerDomainsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerDomainsApiV1CustomerDomainsGet(requestParameters: CustomerAccountApiGetCustomerDomainsApiV1CustomerDomainsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getCustomerDomainsApiV1CustomerDomainsGet(requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * TODO add docstring.
         * @summary Put Customer Account
         * @param {CustomerAccountApiPutCustomerAccountApiV1CustomerAccountPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerAccountApiV1CustomerAccountPost(requestParameters: CustomerAccountApiPutCustomerAccountApiV1CustomerAccountPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PutResponse> {
            return localVarFp.putCustomerAccountApiV1CustomerAccountPost(requestParameters.userGroup, requestParameters.customerAccount, options).then((request) => request(axios, basePath));
        },
        /**
         * Update customer domains for a specific user group and environment.
         * @summary Update Customer Domains
         * @param {CustomerAccountApiUpdateCustomerDomainsApiV1CustomerDomainsPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerDomainsApiV1CustomerDomainsPut(requestParameters: CustomerAccountApiUpdateCustomerDomainsApiV1CustomerDomainsPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.updateCustomerDomainsApiV1CustomerDomainsPut(requestParameters.userGroup, requestParameters.requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCustomerAccountApiV1CustomerAccountGet operation in CustomerAccountApi.
 * @export
 * @interface CustomerAccountApiGetCustomerAccountApiV1CustomerAccountGetRequest
 */
export interface CustomerAccountApiGetCustomerAccountApiV1CustomerAccountGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerAccountApiGetCustomerAccountApiV1CustomerAccountGet
     */
    readonly userGroup: string
}

/**
 * Request parameters for getCustomerDomainsApiV1CustomerDomainsGet operation in CustomerAccountApi.
 * @export
 * @interface CustomerAccountApiGetCustomerDomainsApiV1CustomerDomainsGetRequest
 */
export interface CustomerAccountApiGetCustomerDomainsApiV1CustomerDomainsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerAccountApiGetCustomerDomainsApiV1CustomerDomainsGet
     */
    readonly userGroup: string
}

/**
 * Request parameters for putCustomerAccountApiV1CustomerAccountPost operation in CustomerAccountApi.
 * @export
 * @interface CustomerAccountApiPutCustomerAccountApiV1CustomerAccountPostRequest
 */
export interface CustomerAccountApiPutCustomerAccountApiV1CustomerAccountPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerAccountApiPutCustomerAccountApiV1CustomerAccountPost
     */
    readonly userGroup: string

    /**
     * 
     * @type {CustomerAccount}
     * @memberof CustomerAccountApiPutCustomerAccountApiV1CustomerAccountPost
     */
    readonly customerAccount: CustomerAccount
}

/**
 * Request parameters for updateCustomerDomainsApiV1CustomerDomainsPut operation in CustomerAccountApi.
 * @export
 * @interface CustomerAccountApiUpdateCustomerDomainsApiV1CustomerDomainsPutRequest
 */
export interface CustomerAccountApiUpdateCustomerDomainsApiV1CustomerDomainsPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerAccountApiUpdateCustomerDomainsApiV1CustomerDomainsPut
     */
    readonly userGroup: string

    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerAccountApiUpdateCustomerDomainsApiV1CustomerDomainsPut
     */
    readonly requestBody: Array<string>
}

/**
 * CustomerAccountApi - object-oriented interface
 * @export
 * @class CustomerAccountApi
 * @extends {BaseAPI}
 */
export class CustomerAccountApi extends BaseAPI {
    /**
     * TODO add docstring.
     * @summary Get Customer Account
     * @param {CustomerAccountApiGetCustomerAccountApiV1CustomerAccountGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccountApi
     */
    public getCustomerAccountApiV1CustomerAccountGet(requestParameters: CustomerAccountApiGetCustomerAccountApiV1CustomerAccountGetRequest, options?: RawAxiosRequestConfig) {
        return CustomerAccountApiFp(this.configuration).getCustomerAccountApiV1CustomerAccountGet(requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get customer domains for a specific user group and environment.
     * @summary Get Customer Domains
     * @param {CustomerAccountApiGetCustomerDomainsApiV1CustomerDomainsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccountApi
     */
    public getCustomerDomainsApiV1CustomerDomainsGet(requestParameters: CustomerAccountApiGetCustomerDomainsApiV1CustomerDomainsGetRequest, options?: RawAxiosRequestConfig) {
        return CustomerAccountApiFp(this.configuration).getCustomerDomainsApiV1CustomerDomainsGet(requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TODO add docstring.
     * @summary Put Customer Account
     * @param {CustomerAccountApiPutCustomerAccountApiV1CustomerAccountPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccountApi
     */
    public putCustomerAccountApiV1CustomerAccountPost(requestParameters: CustomerAccountApiPutCustomerAccountApiV1CustomerAccountPostRequest, options?: RawAxiosRequestConfig) {
        return CustomerAccountApiFp(this.configuration).putCustomerAccountApiV1CustomerAccountPost(requestParameters.userGroup, requestParameters.customerAccount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update customer domains for a specific user group and environment.
     * @summary Update Customer Domains
     * @param {CustomerAccountApiUpdateCustomerDomainsApiV1CustomerDomainsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccountApi
     */
    public updateCustomerDomainsApiV1CustomerDomainsPut(requestParameters: CustomerAccountApiUpdateCustomerDomainsApiV1CustomerDomainsPutRequest, options?: RawAxiosRequestConfig) {
        return CustomerAccountApiFp(this.configuration).updateCustomerDomainsApiV1CustomerDomainsPut(requestParameters.userGroup, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomerMarketsApi - axios parameter creator
 * @export
 */
export const CustomerMarketsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add new customer market.
         * @summary Create Customer Market
         * @param {string} userGroup 
         * @param {CreateCustomerMarketRequest} createCustomerMarketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMarket: async (userGroup: string, createCustomerMarketRequest: CreateCustomerMarketRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('createMarket', 'userGroup', userGroup)
            // verify required parameter 'createCustomerMarketRequest' is not null or undefined
            assertParamExists('createMarket', 'createCustomerMarketRequest', createCustomerMarketRequest)
            const localVarPath = `/api/v1/customer-markets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomerMarketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get customer market by id.
         * @summary Get Customer Market By Id
         * @param {string} marketId 
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarket: async (marketId: string, userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketId' is not null or undefined
            assertParamExists('getMarket', 'marketId', marketId)
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getMarket', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-markets/{market_id}`
                .replace(`{${"market_id"}}`, encodeURIComponent(String(marketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all customer markets.
         * @summary Get Customer Markets
         * @param {string} userGroup 
         * @param {string} [configUserGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarkets: async (userGroup: string, configUserGroup?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getMarkets', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-markets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }

            if (configUserGroup !== undefined) {
                localVarQueryParameter['config_user_group'] = configUserGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update customer markets in ddb using the id of the provided market.
         * @summary Update Customer Market
         * @param {string} customerMarketId 
         * @param {string} userGroup 
         * @param {CustomerMarketInput} customerMarketInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarket: async (customerMarketId: string, userGroup: string, customerMarketInput: CustomerMarketInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerMarketId' is not null or undefined
            assertParamExists('updateMarket', 'customerMarketId', customerMarketId)
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('updateMarket', 'userGroup', userGroup)
            // verify required parameter 'customerMarketInput' is not null or undefined
            assertParamExists('updateMarket', 'customerMarketInput', customerMarketInput)
            const localVarPath = `/api/v1/customer-markets/{customer_market_id}`
                .replace(`{${"customer_market_id"}}`, encodeURIComponent(String(customerMarketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerMarketInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerMarketsApi - functional programming interface
 * @export
 */
export const CustomerMarketsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerMarketsApiAxiosParamCreator(configuration)
    return {
        /**
         * Add new customer market.
         * @summary Create Customer Market
         * @param {string} userGroup 
         * @param {CreateCustomerMarketRequest} createCustomerMarketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMarket(userGroup: string, createCustomerMarketRequest: CreateCustomerMarketRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerMarketOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMarket(userGroup, createCustomerMarketRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerMarketsApi.createMarket']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get customer market by id.
         * @summary Get Customer Market By Id
         * @param {string} marketId 
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarket(marketId: string, userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerMarketOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarket(marketId, userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerMarketsApi.getMarket']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all customer markets.
         * @summary Get Customer Markets
         * @param {string} userGroup 
         * @param {string} [configUserGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarkets(userGroup: string, configUserGroup?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerMarkets>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarkets(userGroup, configUserGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerMarketsApi.getMarkets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update customer markets in ddb using the id of the provided market.
         * @summary Update Customer Market
         * @param {string} customerMarketId 
         * @param {string} userGroup 
         * @param {CustomerMarketInput} customerMarketInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMarket(customerMarketId: string, userGroup: string, customerMarketInput: CustomerMarketInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMarket(customerMarketId, userGroup, customerMarketInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerMarketsApi.updateMarket']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerMarketsApi - factory interface
 * @export
 */
export const CustomerMarketsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerMarketsApiFp(configuration)
    return {
        /**
         * Add new customer market.
         * @summary Create Customer Market
         * @param {CustomerMarketsApiCreateMarketRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMarket(requestParameters: CustomerMarketsApiCreateMarketRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerMarketOutput> {
            return localVarFp.createMarket(requestParameters.userGroup, requestParameters.createCustomerMarketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get customer market by id.
         * @summary Get Customer Market By Id
         * @param {CustomerMarketsApiGetMarketRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarket(requestParameters: CustomerMarketsApiGetMarketRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerMarketOutput> {
            return localVarFp.getMarket(requestParameters.marketId, requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all customer markets.
         * @summary Get Customer Markets
         * @param {CustomerMarketsApiGetMarketsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarkets(requestParameters: CustomerMarketsApiGetMarketsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerMarkets> {
            return localVarFp.getMarkets(requestParameters.userGroup, requestParameters.configUserGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Update customer markets in ddb using the id of the provided market.
         * @summary Update Customer Market
         * @param {CustomerMarketsApiUpdateMarketRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarket(requestParameters: CustomerMarketsApiUpdateMarketRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.updateMarket(requestParameters.customerMarketId, requestParameters.userGroup, requestParameters.customerMarketInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createMarket operation in CustomerMarketsApi.
 * @export
 * @interface CustomerMarketsApiCreateMarketRequest
 */
export interface CustomerMarketsApiCreateMarketRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerMarketsApiCreateMarket
     */
    readonly userGroup: string

    /**
     * 
     * @type {CreateCustomerMarketRequest}
     * @memberof CustomerMarketsApiCreateMarket
     */
    readonly createCustomerMarketRequest: CreateCustomerMarketRequest
}

/**
 * Request parameters for getMarket operation in CustomerMarketsApi.
 * @export
 * @interface CustomerMarketsApiGetMarketRequest
 */
export interface CustomerMarketsApiGetMarketRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerMarketsApiGetMarket
     */
    readonly marketId: string

    /**
     * 
     * @type {string}
     * @memberof CustomerMarketsApiGetMarket
     */
    readonly userGroup: string
}

/**
 * Request parameters for getMarkets operation in CustomerMarketsApi.
 * @export
 * @interface CustomerMarketsApiGetMarketsRequest
 */
export interface CustomerMarketsApiGetMarketsRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerMarketsApiGetMarkets
     */
    readonly userGroup: string

    /**
     * 
     * @type {string}
     * @memberof CustomerMarketsApiGetMarkets
     */
    readonly configUserGroup?: string
}

/**
 * Request parameters for updateMarket operation in CustomerMarketsApi.
 * @export
 * @interface CustomerMarketsApiUpdateMarketRequest
 */
export interface CustomerMarketsApiUpdateMarketRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerMarketsApiUpdateMarket
     */
    readonly customerMarketId: string

    /**
     * 
     * @type {string}
     * @memberof CustomerMarketsApiUpdateMarket
     */
    readonly userGroup: string

    /**
     * 
     * @type {CustomerMarketInput}
     * @memberof CustomerMarketsApiUpdateMarket
     */
    readonly customerMarketInput: CustomerMarketInput
}

/**
 * CustomerMarketsApi - object-oriented interface
 * @export
 * @class CustomerMarketsApi
 * @extends {BaseAPI}
 */
export class CustomerMarketsApi extends BaseAPI {
    /**
     * Add new customer market.
     * @summary Create Customer Market
     * @param {CustomerMarketsApiCreateMarketRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerMarketsApi
     */
    public createMarket(requestParameters: CustomerMarketsApiCreateMarketRequest, options?: RawAxiosRequestConfig) {
        return CustomerMarketsApiFp(this.configuration).createMarket(requestParameters.userGroup, requestParameters.createCustomerMarketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get customer market by id.
     * @summary Get Customer Market By Id
     * @param {CustomerMarketsApiGetMarketRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerMarketsApi
     */
    public getMarket(requestParameters: CustomerMarketsApiGetMarketRequest, options?: RawAxiosRequestConfig) {
        return CustomerMarketsApiFp(this.configuration).getMarket(requestParameters.marketId, requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all customer markets.
     * @summary Get Customer Markets
     * @param {CustomerMarketsApiGetMarketsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerMarketsApi
     */
    public getMarkets(requestParameters: CustomerMarketsApiGetMarketsRequest, options?: RawAxiosRequestConfig) {
        return CustomerMarketsApiFp(this.configuration).getMarkets(requestParameters.userGroup, requestParameters.configUserGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update customer markets in ddb using the id of the provided market.
     * @summary Update Customer Market
     * @param {CustomerMarketsApiUpdateMarketRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerMarketsApi
     */
    public updateMarket(requestParameters: CustomerMarketsApiUpdateMarketRequest, options?: RawAxiosRequestConfig) {
        return CustomerMarketsApiFp(this.configuration).updateMarket(requestParameters.customerMarketId, requestParameters.userGroup, requestParameters.customerMarketInput, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomerMetricsApi - axios parameter creator
 * @export
 */
export const CustomerMetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get a specific customer metric by ID.
         * @summary Get Customer Metric By Id
         * @param {string} id 
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerMetricByIdApiV1CustomerMetricsIdGet: async (id: string, userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerMetricByIdApiV1CustomerMetricsIdGet', 'id', id)
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getCustomerMetricByIdApiV1CustomerMetricsIdGet', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-metrics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all customer metrics with options for filtering.
         * @summary Get Customer Metrics
         * @param {string} userGroup 
         * @param {string} [metricEntityType] 
         * @param {string} [metricRxCadence] 
         * @param {string} [metricRxType] 
         * @param {string} [rxSourceType] 
         * @param {GetCustomerMetricsApiV1CustomerMetricsGetAggregationTypeEnum} [aggregationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerMetricsApiV1CustomerMetricsGet: async (userGroup: string, metricEntityType?: string, metricRxCadence?: string, metricRxType?: string, rxSourceType?: string, aggregationType?: GetCustomerMetricsApiV1CustomerMetricsGetAggregationTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getCustomerMetricsApiV1CustomerMetricsGet', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }

            if (metricEntityType !== undefined) {
                localVarQueryParameter['metric_entity_type'] = metricEntityType;
            }

            if (metricRxCadence !== undefined) {
                localVarQueryParameter['metric_rx_cadence'] = metricRxCadence;
            }

            if (metricRxType !== undefined) {
                localVarQueryParameter['metric_rx_type'] = metricRxType;
            }

            if (rxSourceType !== undefined) {
                localVarQueryParameter['rx_source_type'] = rxSourceType;
            }

            if (aggregationType !== undefined) {
                localVarQueryParameter['aggregation_type'] = aggregationType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerMetricsApi - functional programming interface
 * @export
 */
export const CustomerMetricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerMetricsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get a specific customer metric by ID.
         * @summary Get Customer Metric By Id
         * @param {string} id 
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerMetricByIdApiV1CustomerMetricsIdGet(id: string, userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricObj>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerMetricByIdApiV1CustomerMetricsIdGet(id, userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerMetricsApi.getCustomerMetricByIdApiV1CustomerMetricsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all customer metrics with options for filtering.
         * @summary Get Customer Metrics
         * @param {string} userGroup 
         * @param {string} [metricEntityType] 
         * @param {string} [metricRxCadence] 
         * @param {string} [metricRxType] 
         * @param {string} [rxSourceType] 
         * @param {GetCustomerMetricsApiV1CustomerMetricsGetAggregationTypeEnum} [aggregationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerMetricsApiV1CustomerMetricsGet(userGroup: string, metricEntityType?: string, metricRxCadence?: string, metricRxType?: string, rxSourceType?: string, aggregationType?: GetCustomerMetricsApiV1CustomerMetricsGetAggregationTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerMetrics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerMetricsApiV1CustomerMetricsGet(userGroup, metricEntityType, metricRxCadence, metricRxType, rxSourceType, aggregationType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerMetricsApi.getCustomerMetricsApiV1CustomerMetricsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerMetricsApi - factory interface
 * @export
 */
export const CustomerMetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerMetricsApiFp(configuration)
    return {
        /**
         * Get a specific customer metric by ID.
         * @summary Get Customer Metric By Id
         * @param {CustomerMetricsApiGetCustomerMetricByIdApiV1CustomerMetricsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerMetricByIdApiV1CustomerMetricsIdGet(requestParameters: CustomerMetricsApiGetCustomerMetricByIdApiV1CustomerMetricsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<MetricObj> {
            return localVarFp.getCustomerMetricByIdApiV1CustomerMetricsIdGet(requestParameters.id, requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all customer metrics with options for filtering.
         * @summary Get Customer Metrics
         * @param {CustomerMetricsApiGetCustomerMetricsApiV1CustomerMetricsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerMetricsApiV1CustomerMetricsGet(requestParameters: CustomerMetricsApiGetCustomerMetricsApiV1CustomerMetricsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerMetrics> {
            return localVarFp.getCustomerMetricsApiV1CustomerMetricsGet(requestParameters.userGroup, requestParameters.metricEntityType, requestParameters.metricRxCadence, requestParameters.metricRxType, requestParameters.rxSourceType, requestParameters.aggregationType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCustomerMetricByIdApiV1CustomerMetricsIdGet operation in CustomerMetricsApi.
 * @export
 * @interface CustomerMetricsApiGetCustomerMetricByIdApiV1CustomerMetricsIdGetRequest
 */
export interface CustomerMetricsApiGetCustomerMetricByIdApiV1CustomerMetricsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerMetricsApiGetCustomerMetricByIdApiV1CustomerMetricsIdGet
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CustomerMetricsApiGetCustomerMetricByIdApiV1CustomerMetricsIdGet
     */
    readonly userGroup: string
}

/**
 * Request parameters for getCustomerMetricsApiV1CustomerMetricsGet operation in CustomerMetricsApi.
 * @export
 * @interface CustomerMetricsApiGetCustomerMetricsApiV1CustomerMetricsGetRequest
 */
export interface CustomerMetricsApiGetCustomerMetricsApiV1CustomerMetricsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerMetricsApiGetCustomerMetricsApiV1CustomerMetricsGet
     */
    readonly userGroup: string

    /**
     * 
     * @type {string}
     * @memberof CustomerMetricsApiGetCustomerMetricsApiV1CustomerMetricsGet
     */
    readonly metricEntityType?: string

    /**
     * 
     * @type {string}
     * @memberof CustomerMetricsApiGetCustomerMetricsApiV1CustomerMetricsGet
     */
    readonly metricRxCadence?: string

    /**
     * 
     * @type {string}
     * @memberof CustomerMetricsApiGetCustomerMetricsApiV1CustomerMetricsGet
     */
    readonly metricRxType?: string

    /**
     * 
     * @type {string}
     * @memberof CustomerMetricsApiGetCustomerMetricsApiV1CustomerMetricsGet
     */
    readonly rxSourceType?: string

    /**
     * 
     * @type {'volume' | 'share'}
     * @memberof CustomerMetricsApiGetCustomerMetricsApiV1CustomerMetricsGet
     */
    readonly aggregationType?: GetCustomerMetricsApiV1CustomerMetricsGetAggregationTypeEnum
}

/**
 * CustomerMetricsApi - object-oriented interface
 * @export
 * @class CustomerMetricsApi
 * @extends {BaseAPI}
 */
export class CustomerMetricsApi extends BaseAPI {
    /**
     * Get a specific customer metric by ID.
     * @summary Get Customer Metric By Id
     * @param {CustomerMetricsApiGetCustomerMetricByIdApiV1CustomerMetricsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerMetricsApi
     */
    public getCustomerMetricByIdApiV1CustomerMetricsIdGet(requestParameters: CustomerMetricsApiGetCustomerMetricByIdApiV1CustomerMetricsIdGetRequest, options?: RawAxiosRequestConfig) {
        return CustomerMetricsApiFp(this.configuration).getCustomerMetricByIdApiV1CustomerMetricsIdGet(requestParameters.id, requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all customer metrics with options for filtering.
     * @summary Get Customer Metrics
     * @param {CustomerMetricsApiGetCustomerMetricsApiV1CustomerMetricsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerMetricsApi
     */
    public getCustomerMetricsApiV1CustomerMetricsGet(requestParameters: CustomerMetricsApiGetCustomerMetricsApiV1CustomerMetricsGetRequest, options?: RawAxiosRequestConfig) {
        return CustomerMetricsApiFp(this.configuration).getCustomerMetricsApiV1CustomerMetricsGet(requestParameters.userGroup, requestParameters.metricEntityType, requestParameters.metricRxCadence, requestParameters.metricRxType, requestParameters.rxSourceType, requestParameters.aggregationType, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetCustomerMetricsApiV1CustomerMetricsGetAggregationTypeEnum = {
    Volume: 'volume',
    Share: 'share'
} as const;
export type GetCustomerMetricsApiV1CustomerMetricsGetAggregationTypeEnum = typeof GetCustomerMetricsApiV1CustomerMetricsGetAggregationTypeEnum[keyof typeof GetCustomerMetricsApiV1CustomerMetricsGetAggregationTypeEnum];


/**
 * CustomerObjectiveTypesApi - axios parameter creator
 * @export
 */
export const CustomerObjectiveTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Update customer objective type based on the objective type id.
         * @summary Add Objective Type
         * @param {string} userGroup 
         * @param {ObjectiveTypePostRequest} objectiveTypePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addObjectiveTypeApiV1CustomerObjectiveTypesPost: async (userGroup: string, objectiveTypePostRequest: ObjectiveTypePostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('addObjectiveTypeApiV1CustomerObjectiveTypesPost', 'userGroup', userGroup)
            // verify required parameter 'objectiveTypePostRequest' is not null or undefined
            assertParamExists('addObjectiveTypeApiV1CustomerObjectiveTypesPost', 'objectiveTypePostRequest', objectiveTypePostRequest)
            const localVarPath = `/api/v1/customer-objective-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectiveTypePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all customer objective types.
         * @summary Get Objective Types
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObjectiveTypesApiV1CustomerObjectiveTypesGet: async (userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getObjectiveTypesApiV1CustomerObjectiveTypesGet', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-objective-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update customer objective type based on the objective type id.
         * @summary Update Objective Type
         * @param {string} objectiveTypeId 
         * @param {string} userGroup 
         * @param {ObjectiveTypeInput} objectiveTypeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPut: async (objectiveTypeId: string, userGroup: string, objectiveTypeInput: ObjectiveTypeInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectiveTypeId' is not null or undefined
            assertParamExists('updateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPut', 'objectiveTypeId', objectiveTypeId)
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('updateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPut', 'userGroup', userGroup)
            // verify required parameter 'objectiveTypeInput' is not null or undefined
            assertParamExists('updateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPut', 'objectiveTypeInput', objectiveTypeInput)
            const localVarPath = `/api/v1/customer-objective-types/{objective_type_id}`
                .replace(`{${"objective_type_id"}}`, encodeURIComponent(String(objectiveTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectiveTypeInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerObjectiveTypesApi - functional programming interface
 * @export
 */
export const CustomerObjectiveTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerObjectiveTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * Update customer objective type based on the objective type id.
         * @summary Add Objective Type
         * @param {string} userGroup 
         * @param {ObjectiveTypePostRequest} objectiveTypePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addObjectiveTypeApiV1CustomerObjectiveTypesPost(userGroup: string, objectiveTypePostRequest: ObjectiveTypePostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addObjectiveTypeApiV1CustomerObjectiveTypesPost(userGroup, objectiveTypePostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerObjectiveTypesApi.addObjectiveTypeApiV1CustomerObjectiveTypesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all customer objective types.
         * @summary Get Objective Types
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getObjectiveTypesApiV1CustomerObjectiveTypesGet(userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerObjectiveTypes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getObjectiveTypesApiV1CustomerObjectiveTypesGet(userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerObjectiveTypesApi.getObjectiveTypesApiV1CustomerObjectiveTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update customer objective type based on the objective type id.
         * @summary Update Objective Type
         * @param {string} objectiveTypeId 
         * @param {string} userGroup 
         * @param {ObjectiveTypeInput} objectiveTypeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPut(objectiveTypeId: string, userGroup: string, objectiveTypeInput: ObjectiveTypeInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPut(objectiveTypeId, userGroup, objectiveTypeInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerObjectiveTypesApi.updateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerObjectiveTypesApi - factory interface
 * @export
 */
export const CustomerObjectiveTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerObjectiveTypesApiFp(configuration)
    return {
        /**
         * Update customer objective type based on the objective type id.
         * @summary Add Objective Type
         * @param {CustomerObjectiveTypesApiAddObjectiveTypeApiV1CustomerObjectiveTypesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addObjectiveTypeApiV1CustomerObjectiveTypesPost(requestParameters: CustomerObjectiveTypesApiAddObjectiveTypeApiV1CustomerObjectiveTypesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.addObjectiveTypeApiV1CustomerObjectiveTypesPost(requestParameters.userGroup, requestParameters.objectiveTypePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all customer objective types.
         * @summary Get Objective Types
         * @param {CustomerObjectiveTypesApiGetObjectiveTypesApiV1CustomerObjectiveTypesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObjectiveTypesApiV1CustomerObjectiveTypesGet(requestParameters: CustomerObjectiveTypesApiGetObjectiveTypesApiV1CustomerObjectiveTypesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerObjectiveTypes> {
            return localVarFp.getObjectiveTypesApiV1CustomerObjectiveTypesGet(requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Update customer objective type based on the objective type id.
         * @summary Update Objective Type
         * @param {CustomerObjectiveTypesApiUpdateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPut(requestParameters: CustomerObjectiveTypesApiUpdateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.updateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPut(requestParameters.objectiveTypeId, requestParameters.userGroup, requestParameters.objectiveTypeInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addObjectiveTypeApiV1CustomerObjectiveTypesPost operation in CustomerObjectiveTypesApi.
 * @export
 * @interface CustomerObjectiveTypesApiAddObjectiveTypeApiV1CustomerObjectiveTypesPostRequest
 */
export interface CustomerObjectiveTypesApiAddObjectiveTypeApiV1CustomerObjectiveTypesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerObjectiveTypesApiAddObjectiveTypeApiV1CustomerObjectiveTypesPost
     */
    readonly userGroup: string

    /**
     * 
     * @type {ObjectiveTypePostRequest}
     * @memberof CustomerObjectiveTypesApiAddObjectiveTypeApiV1CustomerObjectiveTypesPost
     */
    readonly objectiveTypePostRequest: ObjectiveTypePostRequest
}

/**
 * Request parameters for getObjectiveTypesApiV1CustomerObjectiveTypesGet operation in CustomerObjectiveTypesApi.
 * @export
 * @interface CustomerObjectiveTypesApiGetObjectiveTypesApiV1CustomerObjectiveTypesGetRequest
 */
export interface CustomerObjectiveTypesApiGetObjectiveTypesApiV1CustomerObjectiveTypesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerObjectiveTypesApiGetObjectiveTypesApiV1CustomerObjectiveTypesGet
     */
    readonly userGroup: string
}

/**
 * Request parameters for updateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPut operation in CustomerObjectiveTypesApi.
 * @export
 * @interface CustomerObjectiveTypesApiUpdateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPutRequest
 */
export interface CustomerObjectiveTypesApiUpdateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerObjectiveTypesApiUpdateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPut
     */
    readonly objectiveTypeId: string

    /**
     * 
     * @type {string}
     * @memberof CustomerObjectiveTypesApiUpdateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPut
     */
    readonly userGroup: string

    /**
     * 
     * @type {ObjectiveTypeInput}
     * @memberof CustomerObjectiveTypesApiUpdateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPut
     */
    readonly objectiveTypeInput: ObjectiveTypeInput
}

/**
 * CustomerObjectiveTypesApi - object-oriented interface
 * @export
 * @class CustomerObjectiveTypesApi
 * @extends {BaseAPI}
 */
export class CustomerObjectiveTypesApi extends BaseAPI {
    /**
     * Update customer objective type based on the objective type id.
     * @summary Add Objective Type
     * @param {CustomerObjectiveTypesApiAddObjectiveTypeApiV1CustomerObjectiveTypesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerObjectiveTypesApi
     */
    public addObjectiveTypeApiV1CustomerObjectiveTypesPost(requestParameters: CustomerObjectiveTypesApiAddObjectiveTypeApiV1CustomerObjectiveTypesPostRequest, options?: RawAxiosRequestConfig) {
        return CustomerObjectiveTypesApiFp(this.configuration).addObjectiveTypeApiV1CustomerObjectiveTypesPost(requestParameters.userGroup, requestParameters.objectiveTypePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all customer objective types.
     * @summary Get Objective Types
     * @param {CustomerObjectiveTypesApiGetObjectiveTypesApiV1CustomerObjectiveTypesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerObjectiveTypesApi
     */
    public getObjectiveTypesApiV1CustomerObjectiveTypesGet(requestParameters: CustomerObjectiveTypesApiGetObjectiveTypesApiV1CustomerObjectiveTypesGetRequest, options?: RawAxiosRequestConfig) {
        return CustomerObjectiveTypesApiFp(this.configuration).getObjectiveTypesApiV1CustomerObjectiveTypesGet(requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update customer objective type based on the objective type id.
     * @summary Update Objective Type
     * @param {CustomerObjectiveTypesApiUpdateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerObjectiveTypesApi
     */
    public updateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPut(requestParameters: CustomerObjectiveTypesApiUpdateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPutRequest, options?: RawAxiosRequestConfig) {
        return CustomerObjectiveTypesApiFp(this.configuration).updateObjectiveTypeApiV1CustomerObjectiveTypesObjectiveTypeIdPut(requestParameters.objectiveTypeId, requestParameters.userGroup, requestParameters.objectiveTypeInput, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomerObjectivesApi - axios parameter creator
 * @export
 */
export const CustomerObjectivesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get customer objective by id. Optionally filter the response by comma separated list of keys.
         * @summary Get Customer Objective By Id
         * @param {string} objectiveId 
         * @param {string} userGroup 
         * @param {Array<GetObjectiveByIdFilterCriteriaEnum>} [filterCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObjectiveById: async (objectiveId: string, userGroup: string, filterCriteria?: Array<GetObjectiveByIdFilterCriteriaEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getObjectiveById', 'objectiveId', objectiveId)
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getObjectiveById', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-objectives/{objective_id}`
                .replace(`{${"objective_id"}}`, encodeURIComponent(String(objectiveId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Given an objective id and a distinct set of filers, get the objective metrics.
         * @summary Get Customer Objective Metrics By Id
         * @param {string} objectiveId 
         * @param {string} userGroup 
         * @param {string} [metricEntityType] 
         * @param {string} [metricRxCadence] 
         * @param {string} [metricRxType] 
         * @param {string} [rxSourceType] 
         * @param {GetObjectiveMetricsAggregationTypeEnum} [aggregationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObjectiveMetrics: async (objectiveId: string, userGroup: string, metricEntityType?: string, metricRxCadence?: string, metricRxType?: string, rxSourceType?: string, aggregationType?: GetObjectiveMetricsAggregationTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectiveId' is not null or undefined
            assertParamExists('getObjectiveMetrics', 'objectiveId', objectiveId)
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getObjectiveMetrics', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-objectives/{objective_id}/metrics`
                .replace(`{${"objective_id"}}`, encodeURIComponent(String(objectiveId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }

            if (metricEntityType !== undefined) {
                localVarQueryParameter['metric_entity_type'] = metricEntityType;
            }

            if (metricRxCadence !== undefined) {
                localVarQueryParameter['metric_rx_cadence'] = metricRxCadence;
            }

            if (metricRxType !== undefined) {
                localVarQueryParameter['metric_rx_type'] = metricRxType;
            }

            if (rxSourceType !== undefined) {
                localVarQueryParameter['rx_source_type'] = rxSourceType;
            }

            if (aggregationType !== undefined) {
                localVarQueryParameter['aggregation_type'] = aggregationType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerObjectivesApi - functional programming interface
 * @export
 */
export const CustomerObjectivesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerObjectivesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get customer objective by id. Optionally filter the response by comma separated list of keys.
         * @summary Get Customer Objective By Id
         * @param {string} objectiveId 
         * @param {string} userGroup 
         * @param {Array<GetObjectiveByIdFilterCriteriaEnum>} [filterCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getObjectiveById(objectiveId: string, userGroup: string, filterCriteria?: Array<GetObjectiveByIdFilterCriteriaEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getObjectiveById(objectiveId, userGroup, filterCriteria, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerObjectivesApi.getObjectiveById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Given an objective id and a distinct set of filers, get the objective metrics.
         * @summary Get Customer Objective Metrics By Id
         * @param {string} objectiveId 
         * @param {string} userGroup 
         * @param {string} [metricEntityType] 
         * @param {string} [metricRxCadence] 
         * @param {string} [metricRxType] 
         * @param {string} [rxSourceType] 
         * @param {GetObjectiveMetricsAggregationTypeEnum} [aggregationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getObjectiveMetrics(objectiveId: string, userGroup: string, metricEntityType?: string, metricRxCadence?: string, metricRxType?: string, rxSourceType?: string, aggregationType?: GetObjectiveMetricsAggregationTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getObjectiveMetrics(objectiveId, userGroup, metricEntityType, metricRxCadence, metricRxType, rxSourceType, aggregationType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerObjectivesApi.getObjectiveMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerObjectivesApi - factory interface
 * @export
 */
export const CustomerObjectivesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerObjectivesApiFp(configuration)
    return {
        /**
         * Get customer objective by id. Optionally filter the response by comma separated list of keys.
         * @summary Get Customer Objective By Id
         * @param {CustomerObjectivesApiGetObjectiveByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObjectiveById(requestParameters: CustomerObjectivesApiGetObjectiveByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getObjectiveById(requestParameters.objectiveId, requestParameters.userGroup, requestParameters.filterCriteria, options).then((request) => request(axios, basePath));
        },
        /**
         * Given an objective id and a distinct set of filers, get the objective metrics.
         * @summary Get Customer Objective Metrics By Id
         * @param {CustomerObjectivesApiGetObjectiveMetricsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObjectiveMetrics(requestParameters: CustomerObjectivesApiGetObjectiveMetricsRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getObjectiveMetrics(requestParameters.objectiveId, requestParameters.userGroup, requestParameters.metricEntityType, requestParameters.metricRxCadence, requestParameters.metricRxType, requestParameters.rxSourceType, requestParameters.aggregationType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getObjectiveById operation in CustomerObjectivesApi.
 * @export
 * @interface CustomerObjectivesApiGetObjectiveByIdRequest
 */
export interface CustomerObjectivesApiGetObjectiveByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerObjectivesApiGetObjectiveById
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof CustomerObjectivesApiGetObjectiveById
     */
    readonly userGroup: string

    /**
     * 
     * @type {Array<'features' | 'metadata'>}
     * @memberof CustomerObjectivesApiGetObjectiveById
     */
    readonly filterCriteria?: Array<GetObjectiveByIdFilterCriteriaEnum>
}

/**
 * Request parameters for getObjectiveMetrics operation in CustomerObjectivesApi.
 * @export
 * @interface CustomerObjectivesApiGetObjectiveMetricsRequest
 */
export interface CustomerObjectivesApiGetObjectiveMetricsRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerObjectivesApiGetObjectiveMetrics
     */
    readonly objectiveId: string

    /**
     * 
     * @type {string}
     * @memberof CustomerObjectivesApiGetObjectiveMetrics
     */
    readonly userGroup: string

    /**
     * 
     * @type {string}
     * @memberof CustomerObjectivesApiGetObjectiveMetrics
     */
    readonly metricEntityType?: string

    /**
     * 
     * @type {string}
     * @memberof CustomerObjectivesApiGetObjectiveMetrics
     */
    readonly metricRxCadence?: string

    /**
     * 
     * @type {string}
     * @memberof CustomerObjectivesApiGetObjectiveMetrics
     */
    readonly metricRxType?: string

    /**
     * 
     * @type {string}
     * @memberof CustomerObjectivesApiGetObjectiveMetrics
     */
    readonly rxSourceType?: string

    /**
     * 
     * @type {'volume' | 'share'}
     * @memberof CustomerObjectivesApiGetObjectiveMetrics
     */
    readonly aggregationType?: GetObjectiveMetricsAggregationTypeEnum
}

/**
 * CustomerObjectivesApi - object-oriented interface
 * @export
 * @class CustomerObjectivesApi
 * @extends {BaseAPI}
 */
export class CustomerObjectivesApi extends BaseAPI {
    /**
     * Get customer objective by id. Optionally filter the response by comma separated list of keys.
     * @summary Get Customer Objective By Id
     * @param {CustomerObjectivesApiGetObjectiveByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerObjectivesApi
     */
    public getObjectiveById(requestParameters: CustomerObjectivesApiGetObjectiveByIdRequest, options?: RawAxiosRequestConfig) {
        return CustomerObjectivesApiFp(this.configuration).getObjectiveById(requestParameters.objectiveId, requestParameters.userGroup, requestParameters.filterCriteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Given an objective id and a distinct set of filers, get the objective metrics.
     * @summary Get Customer Objective Metrics By Id
     * @param {CustomerObjectivesApiGetObjectiveMetricsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerObjectivesApi
     */
    public getObjectiveMetrics(requestParameters: CustomerObjectivesApiGetObjectiveMetricsRequest, options?: RawAxiosRequestConfig) {
        return CustomerObjectivesApiFp(this.configuration).getObjectiveMetrics(requestParameters.objectiveId, requestParameters.userGroup, requestParameters.metricEntityType, requestParameters.metricRxCadence, requestParameters.metricRxType, requestParameters.rxSourceType, requestParameters.aggregationType, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetObjectiveByIdFilterCriteriaEnum = {
    Features: 'features',
    Metadata: 'metadata'
} as const;
export type GetObjectiveByIdFilterCriteriaEnum = typeof GetObjectiveByIdFilterCriteriaEnum[keyof typeof GetObjectiveByIdFilterCriteriaEnum];
/**
 * @export
 */
export const GetObjectiveMetricsAggregationTypeEnum = {
    Volume: 'volume',
    Share: 'share'
} as const;
export type GetObjectiveMetricsAggregationTypeEnum = typeof GetObjectiveMetricsAggregationTypeEnum[keyof typeof GetObjectiveMetricsAggregationTypeEnum];


/**
 * CustomerProductLinesApi - axios parameter creator
 * @export
 */
export const CustomerProductLinesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Attach a product tree to a customer market.
         * @summary Attach Product Tree
         * @param {string} productLineId 
         * @param {string} userGroup 
         * @param {AttachProductTreePostRequest} attachProductTreePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachProductTreeToMarket: async (productLineId: string, userGroup: string, attachProductTreePostRequest: AttachProductTreePostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('attachProductTreeToMarket', 'productLineId', productLineId)
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('attachProductTreeToMarket', 'userGroup', userGroup)
            // verify required parameter 'attachProductTreePostRequest' is not null or undefined
            assertParamExists('attachProductTreeToMarket', 'attachProductTreePostRequest', attachProductTreePostRequest)
            const localVarPath = `/api/v1/customer-product-trees/{product_line_id}/attach`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachProductTreePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Clone an existing product tree.
         * @summary Clone Product Tree
         * @param {string} userGroup 
         * @param {CloneProductTreePostRequest} cloneProductTreePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneProductTree: async (userGroup: string, cloneProductTreePostRequest: CloneProductTreePostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('cloneProductTree', 'userGroup', userGroup)
            // verify required parameter 'cloneProductTreePostRequest' is not null or undefined
            assertParamExists('cloneProductTree', 'cloneProductTreePostRequest', cloneProductTreePostRequest)
            const localVarPath = `/api/v1/customer-product-trees/clone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cloneProductTreePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new product tree.
         * @summary Create Product Tree
         * @param {string} userGroup 
         * @param {CreateProductTreePostRequest} createProductTreePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductTree: async (userGroup: string, createProductTreePostRequest: CreateProductTreePostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('createProductTree', 'userGroup', userGroup)
            // verify required parameter 'createProductTreePostRequest' is not null or undefined
            assertParamExists('createProductTree', 'createProductTreePostRequest', createProductTreePostRequest)
            const localVarPath = `/api/v1/customer-product-trees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductTreePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a product tree.
         * @summary Delete Product Tree
         * @param {string} productLineId 
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductTree: async (productLineId: string, userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLineId' is not null or undefined
            assertParamExists('deleteProductTree', 'productLineId', productLineId)
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('deleteProductTree', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-product-trees/{product_line_id}`
                .replace(`{${"product_line_id"}}`, encodeURIComponent(String(productLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all product line identifiers.
         * @summary Get Customer Product Lines
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductLines: async (userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getProductLines', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-product-lines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all product trees.
         * @summary Get Customer Trees
         * @param {string} userGroup 
         * @param {boolean | null} [attached] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductTrees: async (userGroup: string, attached?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getProductTrees', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-product-trees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }

            if (attached !== undefined) {
                localVarQueryParameter['attached'] = attached;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add, edit, and delete nodes from existing product trees.
         * @summary Update Product Tree
         * @param {string} userGroup 
         * @param {UpdateProductTreeRequest} updateProductTreeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductTree: async (userGroup: string, updateProductTreeRequest: UpdateProductTreeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('updateProductTree', 'userGroup', userGroup)
            // verify required parameter 'updateProductTreeRequest' is not null or undefined
            assertParamExists('updateProductTree', 'updateProductTreeRequest', updateProductTreeRequest)
            const localVarPath = `/api/v1/customer-product-trees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductTreeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerProductLinesApi - functional programming interface
 * @export
 */
export const CustomerProductLinesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerProductLinesApiAxiosParamCreator(configuration)
    return {
        /**
         * Attach a product tree to a customer market.
         * @summary Attach Product Tree
         * @param {string} productLineId 
         * @param {string} userGroup 
         * @param {AttachProductTreePostRequest} attachProductTreePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachProductTreeToMarket(productLineId: string, userGroup: string, attachProductTreePostRequest: AttachProductTreePostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachProductLineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachProductTreeToMarket(productLineId, userGroup, attachProductTreePostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProductLinesApi.attachProductTreeToMarket']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Clone an existing product tree.
         * @summary Clone Product Tree
         * @param {string} userGroup 
         * @param {CloneProductTreePostRequest} cloneProductTreePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneProductTree(userGroup: string, cloneProductTreePostRequest: CloneProductTreePostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HelperInterfaceDynamodbCustomerMarketsProductLineOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloneProductTree(userGroup, cloneProductTreePostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProductLinesApi.cloneProductTree']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new product tree.
         * @summary Create Product Tree
         * @param {string} userGroup 
         * @param {CreateProductTreePostRequest} createProductTreePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProductTree(userGroup: string, createProductTreePostRequest: CreateProductTreePostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HelperInterfaceDynamodbCustomerMarketsProductLineOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProductTree(userGroup, createProductTreePostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProductLinesApi.createProductTree']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a product tree.
         * @summary Delete Product Tree
         * @param {string} productLineId 
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductTree(productLineId: string, userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductTree(productLineId, userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProductLinesApi.deleteProductTree']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all product line identifiers.
         * @summary Get Customer Product Lines
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductLines(userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductLines(userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProductLinesApi.getProductLines']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all product trees.
         * @summary Get Customer Trees
         * @param {string} userGroup 
         * @param {boolean | null} [attached] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductTrees(userGroup: string, attached?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProduceLineResponseObject>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductTrees(userGroup, attached, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProductLinesApi.getProductTrees']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Add, edit, and delete nodes from existing product trees.
         * @summary Update Product Tree
         * @param {string} userGroup 
         * @param {UpdateProductTreeRequest} updateProductTreeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProductTree(userGroup: string, updateProductTreeRequest: UpdateProductTreeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HelperInterfaceDynamodbCustomerMarketsProductOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProductTree(userGroup, updateProductTreeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProductLinesApi.updateProductTree']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerProductLinesApi - factory interface
 * @export
 */
export const CustomerProductLinesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerProductLinesApiFp(configuration)
    return {
        /**
         * Attach a product tree to a customer market.
         * @summary Attach Product Tree
         * @param {CustomerProductLinesApiAttachProductTreeToMarketRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachProductTreeToMarket(requestParameters: CustomerProductLinesApiAttachProductTreeToMarketRequest, options?: RawAxiosRequestConfig): AxiosPromise<AttachProductLineResponse> {
            return localVarFp.attachProductTreeToMarket(requestParameters.productLineId, requestParameters.userGroup, requestParameters.attachProductTreePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Clone an existing product tree.
         * @summary Clone Product Tree
         * @param {CustomerProductLinesApiCloneProductTreeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneProductTree(requestParameters: CustomerProductLinesApiCloneProductTreeRequest, options?: RawAxiosRequestConfig): AxiosPromise<HelperInterfaceDynamodbCustomerMarketsProductLineOutput> {
            return localVarFp.cloneProductTree(requestParameters.userGroup, requestParameters.cloneProductTreePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new product tree.
         * @summary Create Product Tree
         * @param {CustomerProductLinesApiCreateProductTreeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductTree(requestParameters: CustomerProductLinesApiCreateProductTreeRequest, options?: RawAxiosRequestConfig): AxiosPromise<HelperInterfaceDynamodbCustomerMarketsProductLineOutput> {
            return localVarFp.createProductTree(requestParameters.userGroup, requestParameters.createProductTreePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a product tree.
         * @summary Delete Product Tree
         * @param {CustomerProductLinesApiDeleteProductTreeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductTree(requestParameters: CustomerProductLinesApiDeleteProductTreeRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteProductTree(requestParameters.productLineId, requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all product line identifiers.
         * @summary Get Customer Product Lines
         * @param {CustomerProductLinesApiGetProductLinesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductLines(requestParameters: CustomerProductLinesApiGetProductLinesRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getProductLines(requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all product trees.
         * @summary Get Customer Trees
         * @param {CustomerProductLinesApiGetProductTreesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductTrees(requestParameters: CustomerProductLinesApiGetProductTreesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProduceLineResponseObject>> {
            return localVarFp.getProductTrees(requestParameters.userGroup, requestParameters.attached, options).then((request) => request(axios, basePath));
        },
        /**
         * Add, edit, and delete nodes from existing product trees.
         * @summary Update Product Tree
         * @param {CustomerProductLinesApiUpdateProductTreeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductTree(requestParameters: CustomerProductLinesApiUpdateProductTreeRequest, options?: RawAxiosRequestConfig): AxiosPromise<HelperInterfaceDynamodbCustomerMarketsProductOutput> {
            return localVarFp.updateProductTree(requestParameters.userGroup, requestParameters.updateProductTreeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for attachProductTreeToMarket operation in CustomerProductLinesApi.
 * @export
 * @interface CustomerProductLinesApiAttachProductTreeToMarketRequest
 */
export interface CustomerProductLinesApiAttachProductTreeToMarketRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerProductLinesApiAttachProductTreeToMarket
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof CustomerProductLinesApiAttachProductTreeToMarket
     */
    readonly userGroup: string

    /**
     * 
     * @type {AttachProductTreePostRequest}
     * @memberof CustomerProductLinesApiAttachProductTreeToMarket
     */
    readonly attachProductTreePostRequest: AttachProductTreePostRequest
}

/**
 * Request parameters for cloneProductTree operation in CustomerProductLinesApi.
 * @export
 * @interface CustomerProductLinesApiCloneProductTreeRequest
 */
export interface CustomerProductLinesApiCloneProductTreeRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerProductLinesApiCloneProductTree
     */
    readonly userGroup: string

    /**
     * 
     * @type {CloneProductTreePostRequest}
     * @memberof CustomerProductLinesApiCloneProductTree
     */
    readonly cloneProductTreePostRequest: CloneProductTreePostRequest
}

/**
 * Request parameters for createProductTree operation in CustomerProductLinesApi.
 * @export
 * @interface CustomerProductLinesApiCreateProductTreeRequest
 */
export interface CustomerProductLinesApiCreateProductTreeRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerProductLinesApiCreateProductTree
     */
    readonly userGroup: string

    /**
     * 
     * @type {CreateProductTreePostRequest}
     * @memberof CustomerProductLinesApiCreateProductTree
     */
    readonly createProductTreePostRequest: CreateProductTreePostRequest
}

/**
 * Request parameters for deleteProductTree operation in CustomerProductLinesApi.
 * @export
 * @interface CustomerProductLinesApiDeleteProductTreeRequest
 */
export interface CustomerProductLinesApiDeleteProductTreeRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerProductLinesApiDeleteProductTree
     */
    readonly productLineId: string

    /**
     * 
     * @type {string}
     * @memberof CustomerProductLinesApiDeleteProductTree
     */
    readonly userGroup: string
}

/**
 * Request parameters for getProductLines operation in CustomerProductLinesApi.
 * @export
 * @interface CustomerProductLinesApiGetProductLinesRequest
 */
export interface CustomerProductLinesApiGetProductLinesRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerProductLinesApiGetProductLines
     */
    readonly userGroup: string
}

/**
 * Request parameters for getProductTrees operation in CustomerProductLinesApi.
 * @export
 * @interface CustomerProductLinesApiGetProductTreesRequest
 */
export interface CustomerProductLinesApiGetProductTreesRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerProductLinesApiGetProductTrees
     */
    readonly userGroup: string

    /**
     * 
     * @type {boolean}
     * @memberof CustomerProductLinesApiGetProductTrees
     */
    readonly attached?: boolean | null
}

/**
 * Request parameters for updateProductTree operation in CustomerProductLinesApi.
 * @export
 * @interface CustomerProductLinesApiUpdateProductTreeRequest
 */
export interface CustomerProductLinesApiUpdateProductTreeRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerProductLinesApiUpdateProductTree
     */
    readonly userGroup: string

    /**
     * 
     * @type {UpdateProductTreeRequest}
     * @memberof CustomerProductLinesApiUpdateProductTree
     */
    readonly updateProductTreeRequest: UpdateProductTreeRequest
}

/**
 * CustomerProductLinesApi - object-oriented interface
 * @export
 * @class CustomerProductLinesApi
 * @extends {BaseAPI}
 */
export class CustomerProductLinesApi extends BaseAPI {
    /**
     * Attach a product tree to a customer market.
     * @summary Attach Product Tree
     * @param {CustomerProductLinesApiAttachProductTreeToMarketRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductLinesApi
     */
    public attachProductTreeToMarket(requestParameters: CustomerProductLinesApiAttachProductTreeToMarketRequest, options?: RawAxiosRequestConfig) {
        return CustomerProductLinesApiFp(this.configuration).attachProductTreeToMarket(requestParameters.productLineId, requestParameters.userGroup, requestParameters.attachProductTreePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Clone an existing product tree.
     * @summary Clone Product Tree
     * @param {CustomerProductLinesApiCloneProductTreeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductLinesApi
     */
    public cloneProductTree(requestParameters: CustomerProductLinesApiCloneProductTreeRequest, options?: RawAxiosRequestConfig) {
        return CustomerProductLinesApiFp(this.configuration).cloneProductTree(requestParameters.userGroup, requestParameters.cloneProductTreePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new product tree.
     * @summary Create Product Tree
     * @param {CustomerProductLinesApiCreateProductTreeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductLinesApi
     */
    public createProductTree(requestParameters: CustomerProductLinesApiCreateProductTreeRequest, options?: RawAxiosRequestConfig) {
        return CustomerProductLinesApiFp(this.configuration).createProductTree(requestParameters.userGroup, requestParameters.createProductTreePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a product tree.
     * @summary Delete Product Tree
     * @param {CustomerProductLinesApiDeleteProductTreeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductLinesApi
     */
    public deleteProductTree(requestParameters: CustomerProductLinesApiDeleteProductTreeRequest, options?: RawAxiosRequestConfig) {
        return CustomerProductLinesApiFp(this.configuration).deleteProductTree(requestParameters.productLineId, requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all product line identifiers.
     * @summary Get Customer Product Lines
     * @param {CustomerProductLinesApiGetProductLinesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductLinesApi
     */
    public getProductLines(requestParameters: CustomerProductLinesApiGetProductLinesRequest, options?: RawAxiosRequestConfig) {
        return CustomerProductLinesApiFp(this.configuration).getProductLines(requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all product trees.
     * @summary Get Customer Trees
     * @param {CustomerProductLinesApiGetProductTreesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductLinesApi
     */
    public getProductTrees(requestParameters: CustomerProductLinesApiGetProductTreesRequest, options?: RawAxiosRequestConfig) {
        return CustomerProductLinesApiFp(this.configuration).getProductTrees(requestParameters.userGroup, requestParameters.attached, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add, edit, and delete nodes from existing product trees.
     * @summary Update Product Tree
     * @param {CustomerProductLinesApiUpdateProductTreeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductLinesApi
     */
    public updateProductTree(requestParameters: CustomerProductLinesApiUpdateProductTreeRequest, options?: RawAxiosRequestConfig) {
        return CustomerProductLinesApiFp(this.configuration).updateProductTree(requestParameters.userGroup, requestParameters.updateProductTreeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomerProjectTemplatesApi - axios parameter creator
 * @export
 */
export const CustomerProjectTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new project template.
         * @summary Create Project Template
         * @param {string} userGroup 
         * @param {ProjectTemplateRequest} projectTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectTemplateApiV1CustomerProjectTemplatesPost: async (userGroup: string, projectTemplateRequest: ProjectTemplateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('createProjectTemplateApiV1CustomerProjectTemplatesPost', 'userGroup', userGroup)
            // verify required parameter 'projectTemplateRequest' is not null or undefined
            assertParamExists('createProjectTemplateApiV1CustomerProjectTemplatesPost', 'projectTemplateRequest', projectTemplateRequest)
            const localVarPath = `/api/v1/customer-project-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a project template.
         * @summary Delete Project Template
         * @param {string} id 
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectTemplateApiV1CustomerProjectTemplatesIdDelete: async (id: string, userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProjectTemplateApiV1CustomerProjectTemplatesIdDelete', 'id', id)
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('deleteProjectTemplateApiV1CustomerProjectTemplatesIdDelete', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-project-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a project template.
         * @summary Get Project Template
         * @param {string} id 
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTemplateApiV1CustomerProjectTemplatesIdGet: async (id: string, userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectTemplateApiV1CustomerProjectTemplatesIdGet', 'id', id)
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getProjectTemplateApiV1CustomerProjectTemplatesIdGet', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-project-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all project templates.
         * @summary Get Project Templates
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTemplatesApiV1CustomerProjectTemplatesGet: async (userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getProjectTemplatesApiV1CustomerProjectTemplatesGet', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-project-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a project template.
         * @summary Migrate Project Templates
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateProjectTemplatesApiV1CustomerProjectTemplatesMigratePost: async (userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('migrateProjectTemplatesApiV1CustomerProjectTemplatesMigratePost', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-project-templates/migrate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a project template.
         * @summary Update Project Template
         * @param {string} id 
         * @param {string} userGroup 
         * @param {ProjectTemplateRequest} projectTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectTemplateApiV1CustomerProjectTemplatesIdPut: async (id: string, userGroup: string, projectTemplateRequest: ProjectTemplateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProjectTemplateApiV1CustomerProjectTemplatesIdPut', 'id', id)
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('updateProjectTemplateApiV1CustomerProjectTemplatesIdPut', 'userGroup', userGroup)
            // verify required parameter 'projectTemplateRequest' is not null or undefined
            assertParamExists('updateProjectTemplateApiV1CustomerProjectTemplatesIdPut', 'projectTemplateRequest', projectTemplateRequest)
            const localVarPath = `/api/v1/customer-project-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerProjectTemplatesApi - functional programming interface
 * @export
 */
export const CustomerProjectTemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerProjectTemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new project template.
         * @summary Create Project Template
         * @param {string} userGroup 
         * @param {ProjectTemplateRequest} projectTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectTemplateApiV1CustomerProjectTemplatesPost(userGroup: string, projectTemplateRequest: ProjectTemplateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectTemplateApiV1CustomerProjectTemplatesPost(userGroup, projectTemplateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProjectTemplatesApi.createProjectTemplateApiV1CustomerProjectTemplatesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets a project template.
         * @summary Delete Project Template
         * @param {string} id 
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectTemplateApiV1CustomerProjectTemplatesIdDelete(id: string, userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectTemplateApiV1CustomerProjectTemplatesIdDelete(id, userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProjectTemplatesApi.deleteProjectTemplateApiV1CustomerProjectTemplatesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets a project template.
         * @summary Get Project Template
         * @param {string} id 
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectTemplateApiV1CustomerProjectTemplatesIdGet(id: string, userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectTemplateApiV1CustomerProjectTemplatesIdGet(id, userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProjectTemplatesApi.getProjectTemplateApiV1CustomerProjectTemplatesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets all project templates.
         * @summary Get Project Templates
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectTemplatesApiV1CustomerProjectTemplatesGet(userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: ProjectTemplate; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectTemplatesApiV1CustomerProjectTemplatesGet(userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProjectTemplatesApi.getProjectTemplatesApiV1CustomerProjectTemplatesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets a project template.
         * @summary Migrate Project Templates
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateProjectTemplatesApiV1CustomerProjectTemplatesMigratePost(userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrateProjectTemplatesApiV1CustomerProjectTemplatesMigratePost(userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProjectTemplatesApi.migrateProjectTemplatesApiV1CustomerProjectTemplatesMigratePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets a project template.
         * @summary Update Project Template
         * @param {string} id 
         * @param {string} userGroup 
         * @param {ProjectTemplateRequest} projectTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProjectTemplateApiV1CustomerProjectTemplatesIdPut(id: string, userGroup: string, projectTemplateRequest: ProjectTemplateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectTemplateApiV1CustomerProjectTemplatesIdPut(id, userGroup, projectTemplateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProjectTemplatesApi.updateProjectTemplateApiV1CustomerProjectTemplatesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerProjectTemplatesApi - factory interface
 * @export
 */
export const CustomerProjectTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerProjectTemplatesApiFp(configuration)
    return {
        /**
         * Creates a new project template.
         * @summary Create Project Template
         * @param {CustomerProjectTemplatesApiCreateProjectTemplateApiV1CustomerProjectTemplatesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectTemplateApiV1CustomerProjectTemplatesPost(requestParameters: CustomerProjectTemplatesApiCreateProjectTemplateApiV1CustomerProjectTemplatesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectTemplate> {
            return localVarFp.createProjectTemplateApiV1CustomerProjectTemplatesPost(requestParameters.userGroup, requestParameters.projectTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a project template.
         * @summary Delete Project Template
         * @param {CustomerProjectTemplatesApiDeleteProjectTemplateApiV1CustomerProjectTemplatesIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectTemplateApiV1CustomerProjectTemplatesIdDelete(requestParameters: CustomerProjectTemplatesApiDeleteProjectTemplateApiV1CustomerProjectTemplatesIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteProjectTemplateApiV1CustomerProjectTemplatesIdDelete(requestParameters.id, requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a project template.
         * @summary Get Project Template
         * @param {CustomerProjectTemplatesApiGetProjectTemplateApiV1CustomerProjectTemplatesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTemplateApiV1CustomerProjectTemplatesIdGet(requestParameters: CustomerProjectTemplatesApiGetProjectTemplateApiV1CustomerProjectTemplatesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectTemplate> {
            return localVarFp.getProjectTemplateApiV1CustomerProjectTemplatesIdGet(requestParameters.id, requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all project templates.
         * @summary Get Project Templates
         * @param {CustomerProjectTemplatesApiGetProjectTemplatesApiV1CustomerProjectTemplatesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTemplatesApiV1CustomerProjectTemplatesGet(requestParameters: CustomerProjectTemplatesApiGetProjectTemplatesApiV1CustomerProjectTemplatesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: ProjectTemplate; }> {
            return localVarFp.getProjectTemplatesApiV1CustomerProjectTemplatesGet(requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a project template.
         * @summary Migrate Project Templates
         * @param {CustomerProjectTemplatesApiMigrateProjectTemplatesApiV1CustomerProjectTemplatesMigratePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateProjectTemplatesApiV1CustomerProjectTemplatesMigratePost(requestParameters: CustomerProjectTemplatesApiMigrateProjectTemplatesApiV1CustomerProjectTemplatesMigratePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.migrateProjectTemplatesApiV1CustomerProjectTemplatesMigratePost(requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a project template.
         * @summary Update Project Template
         * @param {CustomerProjectTemplatesApiUpdateProjectTemplateApiV1CustomerProjectTemplatesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectTemplateApiV1CustomerProjectTemplatesIdPut(requestParameters: CustomerProjectTemplatesApiUpdateProjectTemplateApiV1CustomerProjectTemplatesIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectTemplate> {
            return localVarFp.updateProjectTemplateApiV1CustomerProjectTemplatesIdPut(requestParameters.id, requestParameters.userGroup, requestParameters.projectTemplateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createProjectTemplateApiV1CustomerProjectTemplatesPost operation in CustomerProjectTemplatesApi.
 * @export
 * @interface CustomerProjectTemplatesApiCreateProjectTemplateApiV1CustomerProjectTemplatesPostRequest
 */
export interface CustomerProjectTemplatesApiCreateProjectTemplateApiV1CustomerProjectTemplatesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerProjectTemplatesApiCreateProjectTemplateApiV1CustomerProjectTemplatesPost
     */
    readonly userGroup: string

    /**
     * 
     * @type {ProjectTemplateRequest}
     * @memberof CustomerProjectTemplatesApiCreateProjectTemplateApiV1CustomerProjectTemplatesPost
     */
    readonly projectTemplateRequest: ProjectTemplateRequest
}

/**
 * Request parameters for deleteProjectTemplateApiV1CustomerProjectTemplatesIdDelete operation in CustomerProjectTemplatesApi.
 * @export
 * @interface CustomerProjectTemplatesApiDeleteProjectTemplateApiV1CustomerProjectTemplatesIdDeleteRequest
 */
export interface CustomerProjectTemplatesApiDeleteProjectTemplateApiV1CustomerProjectTemplatesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerProjectTemplatesApiDeleteProjectTemplateApiV1CustomerProjectTemplatesIdDelete
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CustomerProjectTemplatesApiDeleteProjectTemplateApiV1CustomerProjectTemplatesIdDelete
     */
    readonly userGroup: string
}

/**
 * Request parameters for getProjectTemplateApiV1CustomerProjectTemplatesIdGet operation in CustomerProjectTemplatesApi.
 * @export
 * @interface CustomerProjectTemplatesApiGetProjectTemplateApiV1CustomerProjectTemplatesIdGetRequest
 */
export interface CustomerProjectTemplatesApiGetProjectTemplateApiV1CustomerProjectTemplatesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerProjectTemplatesApiGetProjectTemplateApiV1CustomerProjectTemplatesIdGet
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CustomerProjectTemplatesApiGetProjectTemplateApiV1CustomerProjectTemplatesIdGet
     */
    readonly userGroup: string
}

/**
 * Request parameters for getProjectTemplatesApiV1CustomerProjectTemplatesGet operation in CustomerProjectTemplatesApi.
 * @export
 * @interface CustomerProjectTemplatesApiGetProjectTemplatesApiV1CustomerProjectTemplatesGetRequest
 */
export interface CustomerProjectTemplatesApiGetProjectTemplatesApiV1CustomerProjectTemplatesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerProjectTemplatesApiGetProjectTemplatesApiV1CustomerProjectTemplatesGet
     */
    readonly userGroup: string
}

/**
 * Request parameters for migrateProjectTemplatesApiV1CustomerProjectTemplatesMigratePost operation in CustomerProjectTemplatesApi.
 * @export
 * @interface CustomerProjectTemplatesApiMigrateProjectTemplatesApiV1CustomerProjectTemplatesMigratePostRequest
 */
export interface CustomerProjectTemplatesApiMigrateProjectTemplatesApiV1CustomerProjectTemplatesMigratePostRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerProjectTemplatesApiMigrateProjectTemplatesApiV1CustomerProjectTemplatesMigratePost
     */
    readonly userGroup: string
}

/**
 * Request parameters for updateProjectTemplateApiV1CustomerProjectTemplatesIdPut operation in CustomerProjectTemplatesApi.
 * @export
 * @interface CustomerProjectTemplatesApiUpdateProjectTemplateApiV1CustomerProjectTemplatesIdPutRequest
 */
export interface CustomerProjectTemplatesApiUpdateProjectTemplateApiV1CustomerProjectTemplatesIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerProjectTemplatesApiUpdateProjectTemplateApiV1CustomerProjectTemplatesIdPut
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CustomerProjectTemplatesApiUpdateProjectTemplateApiV1CustomerProjectTemplatesIdPut
     */
    readonly userGroup: string

    /**
     * 
     * @type {ProjectTemplateRequest}
     * @memberof CustomerProjectTemplatesApiUpdateProjectTemplateApiV1CustomerProjectTemplatesIdPut
     */
    readonly projectTemplateRequest: ProjectTemplateRequest
}

/**
 * CustomerProjectTemplatesApi - object-oriented interface
 * @export
 * @class CustomerProjectTemplatesApi
 * @extends {BaseAPI}
 */
export class CustomerProjectTemplatesApi extends BaseAPI {
    /**
     * Creates a new project template.
     * @summary Create Project Template
     * @param {CustomerProjectTemplatesApiCreateProjectTemplateApiV1CustomerProjectTemplatesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProjectTemplatesApi
     */
    public createProjectTemplateApiV1CustomerProjectTemplatesPost(requestParameters: CustomerProjectTemplatesApiCreateProjectTemplateApiV1CustomerProjectTemplatesPostRequest, options?: RawAxiosRequestConfig) {
        return CustomerProjectTemplatesApiFp(this.configuration).createProjectTemplateApiV1CustomerProjectTemplatesPost(requestParameters.userGroup, requestParameters.projectTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a project template.
     * @summary Delete Project Template
     * @param {CustomerProjectTemplatesApiDeleteProjectTemplateApiV1CustomerProjectTemplatesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProjectTemplatesApi
     */
    public deleteProjectTemplateApiV1CustomerProjectTemplatesIdDelete(requestParameters: CustomerProjectTemplatesApiDeleteProjectTemplateApiV1CustomerProjectTemplatesIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return CustomerProjectTemplatesApiFp(this.configuration).deleteProjectTemplateApiV1CustomerProjectTemplatesIdDelete(requestParameters.id, requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a project template.
     * @summary Get Project Template
     * @param {CustomerProjectTemplatesApiGetProjectTemplateApiV1CustomerProjectTemplatesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProjectTemplatesApi
     */
    public getProjectTemplateApiV1CustomerProjectTemplatesIdGet(requestParameters: CustomerProjectTemplatesApiGetProjectTemplateApiV1CustomerProjectTemplatesIdGetRequest, options?: RawAxiosRequestConfig) {
        return CustomerProjectTemplatesApiFp(this.configuration).getProjectTemplateApiV1CustomerProjectTemplatesIdGet(requestParameters.id, requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all project templates.
     * @summary Get Project Templates
     * @param {CustomerProjectTemplatesApiGetProjectTemplatesApiV1CustomerProjectTemplatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProjectTemplatesApi
     */
    public getProjectTemplatesApiV1CustomerProjectTemplatesGet(requestParameters: CustomerProjectTemplatesApiGetProjectTemplatesApiV1CustomerProjectTemplatesGetRequest, options?: RawAxiosRequestConfig) {
        return CustomerProjectTemplatesApiFp(this.configuration).getProjectTemplatesApiV1CustomerProjectTemplatesGet(requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a project template.
     * @summary Migrate Project Templates
     * @param {CustomerProjectTemplatesApiMigrateProjectTemplatesApiV1CustomerProjectTemplatesMigratePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProjectTemplatesApi
     */
    public migrateProjectTemplatesApiV1CustomerProjectTemplatesMigratePost(requestParameters: CustomerProjectTemplatesApiMigrateProjectTemplatesApiV1CustomerProjectTemplatesMigratePostRequest, options?: RawAxiosRequestConfig) {
        return CustomerProjectTemplatesApiFp(this.configuration).migrateProjectTemplatesApiV1CustomerProjectTemplatesMigratePost(requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a project template.
     * @summary Update Project Template
     * @param {CustomerProjectTemplatesApiUpdateProjectTemplateApiV1CustomerProjectTemplatesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProjectTemplatesApi
     */
    public updateProjectTemplateApiV1CustomerProjectTemplatesIdPut(requestParameters: CustomerProjectTemplatesApiUpdateProjectTemplateApiV1CustomerProjectTemplatesIdPutRequest, options?: RawAxiosRequestConfig) {
        return CustomerProjectTemplatesApiFp(this.configuration).updateProjectTemplateApiV1CustomerProjectTemplatesIdPut(requestParameters.id, requestParameters.userGroup, requestParameters.projectTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomerProjectsApi - axios parameter creator
 * @export
 */
export const CustomerProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * TODO add docstring.
         * @summary Put Customer Account
         * @param {string} userGroup 
         * @param {CustomerProjects} customerProjects 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (userGroup: string, customerProjects: CustomerProjects, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('createProject', 'userGroup', userGroup)
            // verify required parameter 'customerProjects' is not null or undefined
            assertParamExists('createProject', 'customerProjects', customerProjects)
            const localVarPath = `/api/v1/customer-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerProjects, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get customer project by id. Optionally filter the response by comma separated list of keys.
         * @summary Get Customer Project By Id
         * @param {string} projectId 
         * @param {string} userGroup 
         * @param {string} [filterCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById: async (projectId: string, userGroup: string, filterCriteria?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectById', 'projectId', projectId)
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getProjectById', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-projects/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }

            if (filterCriteria !== undefined) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TODO add docstring.
         * @summary Get Customer Projects
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getProjects', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deprecated. Use /customer-projects instead
         * @summary Get Customer Projects Verbose
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjectsVerbose: async (userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getProjectsVerbose', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-projects-verbose`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerProjectsApi - functional programming interface
 * @export
 */
export const CustomerProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * TODO add docstring.
         * @summary Put Customer Account
         * @param {string} userGroup 
         * @param {CustomerProjects} customerProjects 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(userGroup: string, customerProjects: CustomerProjects, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(userGroup, customerProjects, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProjectsApi.createProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get customer project by id. Optionally filter the response by comma separated list of keys.
         * @summary Get Customer Project By Id
         * @param {string} projectId 
         * @param {string} userGroup 
         * @param {string} [filterCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectById(projectId: string, userGroup: string, filterCriteria?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectById(projectId, userGroup, filterCriteria, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProjectsApi.getProjectById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * TODO add docstring.
         * @summary Get Customer Projects
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: CustomerProjectOutput; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProjectsApi.getProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deprecated. Use /customer-projects instead
         * @summary Get Customer Projects Verbose
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProjectsVerbose(userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsVerbose(userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProjectsApi.getProjectsVerbose']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerProjectsApi - factory interface
 * @export
 */
export const CustomerProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerProjectsApiFp(configuration)
    return {
        /**
         * TODO add docstring.
         * @summary Put Customer Account
         * @param {CustomerProjectsApiCreateProjectRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(requestParameters: CustomerProjectsApiCreateProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<PutResponse> {
            return localVarFp.createProject(requestParameters.userGroup, requestParameters.customerProjects, options).then((request) => request(axios, basePath));
        },
        /**
         * Get customer project by id. Optionally filter the response by comma separated list of keys.
         * @summary Get Customer Project By Id
         * @param {CustomerProjectsApiGetProjectByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(requestParameters: CustomerProjectsApiGetProjectByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getProjectById(requestParameters.projectId, requestParameters.userGroup, requestParameters.filterCriteria, options).then((request) => request(axios, basePath));
        },
        /**
         * TODO add docstring.
         * @summary Get Customer Projects
         * @param {CustomerProjectsApiGetProjectsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(requestParameters: CustomerProjectsApiGetProjectsRequest, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: CustomerProjectOutput; }> {
            return localVarFp.getProjects(requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Deprecated. Use /customer-projects instead
         * @summary Get Customer Projects Verbose
         * @param {CustomerProjectsApiGetProjectsVerboseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjectsVerbose(requestParameters: CustomerProjectsApiGetProjectsVerboseRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getProjectsVerbose(requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createProject operation in CustomerProjectsApi.
 * @export
 * @interface CustomerProjectsApiCreateProjectRequest
 */
export interface CustomerProjectsApiCreateProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerProjectsApiCreateProject
     */
    readonly userGroup: string

    /**
     * 
     * @type {CustomerProjects}
     * @memberof CustomerProjectsApiCreateProject
     */
    readonly customerProjects: CustomerProjects
}

/**
 * Request parameters for getProjectById operation in CustomerProjectsApi.
 * @export
 * @interface CustomerProjectsApiGetProjectByIdRequest
 */
export interface CustomerProjectsApiGetProjectByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerProjectsApiGetProjectById
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof CustomerProjectsApiGetProjectById
     */
    readonly userGroup: string

    /**
     * 
     * @type {string}
     * @memberof CustomerProjectsApiGetProjectById
     */
    readonly filterCriteria?: string
}

/**
 * Request parameters for getProjects operation in CustomerProjectsApi.
 * @export
 * @interface CustomerProjectsApiGetProjectsRequest
 */
export interface CustomerProjectsApiGetProjectsRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerProjectsApiGetProjects
     */
    readonly userGroup: string
}

/**
 * Request parameters for getProjectsVerbose operation in CustomerProjectsApi.
 * @export
 * @interface CustomerProjectsApiGetProjectsVerboseRequest
 */
export interface CustomerProjectsApiGetProjectsVerboseRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerProjectsApiGetProjectsVerbose
     */
    readonly userGroup: string
}

/**
 * CustomerProjectsApi - object-oriented interface
 * @export
 * @class CustomerProjectsApi
 * @extends {BaseAPI}
 */
export class CustomerProjectsApi extends BaseAPI {
    /**
     * TODO add docstring.
     * @summary Put Customer Account
     * @param {CustomerProjectsApiCreateProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProjectsApi
     */
    public createProject(requestParameters: CustomerProjectsApiCreateProjectRequest, options?: RawAxiosRequestConfig) {
        return CustomerProjectsApiFp(this.configuration).createProject(requestParameters.userGroup, requestParameters.customerProjects, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get customer project by id. Optionally filter the response by comma separated list of keys.
     * @summary Get Customer Project By Id
     * @param {CustomerProjectsApiGetProjectByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProjectsApi
     */
    public getProjectById(requestParameters: CustomerProjectsApiGetProjectByIdRequest, options?: RawAxiosRequestConfig) {
        return CustomerProjectsApiFp(this.configuration).getProjectById(requestParameters.projectId, requestParameters.userGroup, requestParameters.filterCriteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TODO add docstring.
     * @summary Get Customer Projects
     * @param {CustomerProjectsApiGetProjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProjectsApi
     */
    public getProjects(requestParameters: CustomerProjectsApiGetProjectsRequest, options?: RawAxiosRequestConfig) {
        return CustomerProjectsApiFp(this.configuration).getProjects(requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deprecated. Use /customer-projects instead
     * @summary Get Customer Projects Verbose
     * @param {CustomerProjectsApiGetProjectsVerboseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CustomerProjectsApi
     */
    public getProjectsVerbose(requestParameters: CustomerProjectsApiGetProjectsVerboseRequest, options?: RawAxiosRequestConfig) {
        return CustomerProjectsApiFp(this.configuration).getProjectsVerbose(requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomerSegmentsApi - axios parameter creator
 * @export
 */
export const CustomerSegmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get customer segment by id.
         * @summary Get Customer Segment By Id
         * @param {string} customerSegmentId 
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGet: async (customerSegmentId: string, userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerSegmentId' is not null or undefined
            assertParamExists('getCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGet', 'customerSegmentId', customerSegmentId)
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGet', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-segments/{customer_segment_id}`
                .replace(`{${"customer_segment_id"}}`, encodeURIComponent(String(customerSegmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all customer segments.
         * @summary Get Customer Segments
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSegmentsApiV1CustomerSegmentsGet: async (userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getCustomerSegmentsApiV1CustomerSegmentsGet', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customer-segments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update customer markets in ddb using the id of the provided market.
         * @summary Update Customer Segments
         * @param {string} customerSegmentId 
         * @param {string} userGroup 
         * @param {SegmentInput} segmentInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPut: async (customerSegmentId: string, userGroup: string, segmentInput: SegmentInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerSegmentId' is not null or undefined
            assertParamExists('updateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPut', 'customerSegmentId', customerSegmentId)
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('updateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPut', 'userGroup', userGroup)
            // verify required parameter 'segmentInput' is not null or undefined
            assertParamExists('updateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPut', 'segmentInput', segmentInput)
            const localVarPath = `/api/v1/customer-segments/{customer_segment_id}`
                .replace(`{${"customer_segment_id"}}`, encodeURIComponent(String(customerSegmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(segmentInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerSegmentsApi - functional programming interface
 * @export
 */
export const CustomerSegmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerSegmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get customer segment by id.
         * @summary Get Customer Segment By Id
         * @param {string} customerSegmentId 
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGet(customerSegmentId: string, userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGet(customerSegmentId, userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerSegmentsApi.getCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all customer segments.
         * @summary Get Customer Segments
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerSegmentsApiV1CustomerSegmentsGet(userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerSegments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerSegmentsApiV1CustomerSegmentsGet(userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerSegmentsApi.getCustomerSegmentsApiV1CustomerSegmentsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update customer markets in ddb using the id of the provided market.
         * @summary Update Customer Segments
         * @param {string} customerSegmentId 
         * @param {string} userGroup 
         * @param {SegmentInput} segmentInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPut(customerSegmentId: string, userGroup: string, segmentInput: SegmentInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPut(customerSegmentId, userGroup, segmentInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerSegmentsApi.updateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerSegmentsApi - factory interface
 * @export
 */
export const CustomerSegmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerSegmentsApiFp(configuration)
    return {
        /**
         * Get customer segment by id.
         * @summary Get Customer Segment By Id
         * @param {CustomerSegmentsApiGetCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGet(requestParameters: CustomerSegmentsApiGetCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<SegmentOutput> {
            return localVarFp.getCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGet(requestParameters.customerSegmentId, requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all customer segments.
         * @summary Get Customer Segments
         * @param {CustomerSegmentsApiGetCustomerSegmentsApiV1CustomerSegmentsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSegmentsApiV1CustomerSegmentsGet(requestParameters: CustomerSegmentsApiGetCustomerSegmentsApiV1CustomerSegmentsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerSegments> {
            return localVarFp.getCustomerSegmentsApiV1CustomerSegmentsGet(requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Update customer markets in ddb using the id of the provided market.
         * @summary Update Customer Segments
         * @param {CustomerSegmentsApiUpdateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPut(requestParameters: CustomerSegmentsApiUpdateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<PutResponse> {
            return localVarFp.updateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPut(requestParameters.customerSegmentId, requestParameters.userGroup, requestParameters.segmentInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGet operation in CustomerSegmentsApi.
 * @export
 * @interface CustomerSegmentsApiGetCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGetRequest
 */
export interface CustomerSegmentsApiGetCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerSegmentsApiGetCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGet
     */
    readonly customerSegmentId: string

    /**
     * 
     * @type {string}
     * @memberof CustomerSegmentsApiGetCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGet
     */
    readonly userGroup: string
}

/**
 * Request parameters for getCustomerSegmentsApiV1CustomerSegmentsGet operation in CustomerSegmentsApi.
 * @export
 * @interface CustomerSegmentsApiGetCustomerSegmentsApiV1CustomerSegmentsGetRequest
 */
export interface CustomerSegmentsApiGetCustomerSegmentsApiV1CustomerSegmentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerSegmentsApiGetCustomerSegmentsApiV1CustomerSegmentsGet
     */
    readonly userGroup: string
}

/**
 * Request parameters for updateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPut operation in CustomerSegmentsApi.
 * @export
 * @interface CustomerSegmentsApiUpdateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPutRequest
 */
export interface CustomerSegmentsApiUpdateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerSegmentsApiUpdateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPut
     */
    readonly customerSegmentId: string

    /**
     * 
     * @type {string}
     * @memberof CustomerSegmentsApiUpdateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPut
     */
    readonly userGroup: string

    /**
     * 
     * @type {SegmentInput}
     * @memberof CustomerSegmentsApiUpdateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPut
     */
    readonly segmentInput: SegmentInput
}

/**
 * CustomerSegmentsApi - object-oriented interface
 * @export
 * @class CustomerSegmentsApi
 * @extends {BaseAPI}
 */
export class CustomerSegmentsApi extends BaseAPI {
    /**
     * Get customer segment by id.
     * @summary Get Customer Segment By Id
     * @param {CustomerSegmentsApiGetCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSegmentsApi
     */
    public getCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGet(requestParameters: CustomerSegmentsApiGetCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGetRequest, options?: RawAxiosRequestConfig) {
        return CustomerSegmentsApiFp(this.configuration).getCustomerSegmentByIdApiV1CustomerSegmentsCustomerSegmentIdGet(requestParameters.customerSegmentId, requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all customer segments.
     * @summary Get Customer Segments
     * @param {CustomerSegmentsApiGetCustomerSegmentsApiV1CustomerSegmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSegmentsApi
     */
    public getCustomerSegmentsApiV1CustomerSegmentsGet(requestParameters: CustomerSegmentsApiGetCustomerSegmentsApiV1CustomerSegmentsGetRequest, options?: RawAxiosRequestConfig) {
        return CustomerSegmentsApiFp(this.configuration).getCustomerSegmentsApiV1CustomerSegmentsGet(requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update customer markets in ddb using the id of the provided market.
     * @summary Update Customer Segments
     * @param {CustomerSegmentsApiUpdateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSegmentsApi
     */
    public updateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPut(requestParameters: CustomerSegmentsApiUpdateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPutRequest, options?: RawAxiosRequestConfig) {
        return CustomerSegmentsApiFp(this.configuration).updateCustomerSegmentsApiV1CustomerSegmentsCustomerSegmentIdPut(requestParameters.customerSegmentId, requestParameters.userGroup, requestParameters.segmentInput, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get customer aggregated_entities list.
         * @summary Get Customer Aggregated Entities
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAggregatedEntitiesApiV1CustomersAggregatedEntitiesGet: async (userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getCustomerAggregatedEntitiesApiV1CustomersAggregatedEntitiesGet', 'userGroup', userGroup)
            const localVarPath = `/api/v1/customers/aggregated_entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all customer names.
         * @summary Get Customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersApiV1CustomersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/customers/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * Get customer aggregated_entities list.
         * @summary Get Customer Aggregated Entities
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerAggregatedEntitiesApiV1CustomersAggregatedEntitiesGet(userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerAggregatedEntitiesApiV1CustomersAggregatedEntitiesGet(userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersApi.getCustomerAggregatedEntitiesApiV1CustomersAggregatedEntitiesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all customer names.
         * @summary Get Customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomersApiV1CustomersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomersApiV1CustomersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersApi.getCustomersApiV1CustomersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersApiFp(configuration)
    return {
        /**
         * Get customer aggregated_entities list.
         * @summary Get Customer Aggregated Entities
         * @param {CustomersApiGetCustomerAggregatedEntitiesApiV1CustomersAggregatedEntitiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAggregatedEntitiesApiV1CustomersAggregatedEntitiesGet(requestParameters: CustomersApiGetCustomerAggregatedEntitiesApiV1CustomersAggregatedEntitiesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getCustomerAggregatedEntitiesApiV1CustomersAggregatedEntitiesGet(requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all customer names.
         * @summary Get Customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersApiV1CustomersGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getCustomersApiV1CustomersGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCustomerAggregatedEntitiesApiV1CustomersAggregatedEntitiesGet operation in CustomersApi.
 * @export
 * @interface CustomersApiGetCustomerAggregatedEntitiesApiV1CustomersAggregatedEntitiesGetRequest
 */
export interface CustomersApiGetCustomerAggregatedEntitiesApiV1CustomersAggregatedEntitiesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiGetCustomerAggregatedEntitiesApiV1CustomersAggregatedEntitiesGet
     */
    readonly userGroup: string
}

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
    /**
     * Get customer aggregated_entities list.
     * @summary Get Customer Aggregated Entities
     * @param {CustomersApiGetCustomerAggregatedEntitiesApiV1CustomersAggregatedEntitiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getCustomerAggregatedEntitiesApiV1CustomersAggregatedEntitiesGet(requestParameters: CustomersApiGetCustomerAggregatedEntitiesApiV1CustomersAggregatedEntitiesGetRequest, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).getCustomerAggregatedEntitiesApiV1CustomersAggregatedEntitiesGet(requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all customer names.
     * @summary Get Customers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getCustomersApiV1CustomersGet(options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).getCustomersApiV1CustomersGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Health check function.
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Health check function.
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.rootGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Health check function.
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.rootGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Health check function.
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IntegrationsEndpointsApi - axios parameter creator
 * @export
 */
export const IntegrationsEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * TODO remove once a integrations specific API is available. Does not belong in the cnf_api.
         * @summary Generate New Links
         * @param {string} userGroup 
         * @param {string} csvPath 
         * @param {string} commaSepCsvPath 
         * @param {string} testSummaryFilepath 
         * @param {string} businessUnit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateNewLinksApiV1GenerateNewLinksGet: async (userGroup: string, csvPath: string, commaSepCsvPath: string, testSummaryFilepath: string, businessUnit: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('generateNewLinksApiV1GenerateNewLinksGet', 'userGroup', userGroup)
            // verify required parameter 'csvPath' is not null or undefined
            assertParamExists('generateNewLinksApiV1GenerateNewLinksGet', 'csvPath', csvPath)
            // verify required parameter 'commaSepCsvPath' is not null or undefined
            assertParamExists('generateNewLinksApiV1GenerateNewLinksGet', 'commaSepCsvPath', commaSepCsvPath)
            // verify required parameter 'testSummaryFilepath' is not null or undefined
            assertParamExists('generateNewLinksApiV1GenerateNewLinksGet', 'testSummaryFilepath', testSummaryFilepath)
            // verify required parameter 'businessUnit' is not null or undefined
            assertParamExists('generateNewLinksApiV1GenerateNewLinksGet', 'businessUnit', businessUnit)
            const localVarPath = `/api/v1/generate-new-links/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }

            if (csvPath !== undefined) {
                localVarQueryParameter['csv_path'] = csvPath;
            }

            if (commaSepCsvPath !== undefined) {
                localVarQueryParameter['comma_sep_csv_path'] = commaSepCsvPath;
            }

            if (testSummaryFilepath !== undefined) {
                localVarQueryParameter['test_summary_filepath'] = testSummaryFilepath;
            }

            if (businessUnit !== undefined) {
                localVarQueryParameter['business_unit'] = businessUnit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationsEndpointsApi - functional programming interface
 * @export
 */
export const IntegrationsEndpointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationsEndpointsApiAxiosParamCreator(configuration)
    return {
        /**
         * TODO remove once a integrations specific API is available. Does not belong in the cnf_api.
         * @summary Generate New Links
         * @param {string} userGroup 
         * @param {string} csvPath 
         * @param {string} commaSepCsvPath 
         * @param {string} testSummaryFilepath 
         * @param {string} businessUnit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateNewLinksApiV1GenerateNewLinksGet(userGroup: string, csvPath: string, commaSepCsvPath: string, testSummaryFilepath: string, businessUnit: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateNewLinksApiV1GenerateNewLinksGet(userGroup, csvPath, commaSepCsvPath, testSummaryFilepath, businessUnit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationsEndpointsApi.generateNewLinksApiV1GenerateNewLinksGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * IntegrationsEndpointsApi - factory interface
 * @export
 */
export const IntegrationsEndpointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationsEndpointsApiFp(configuration)
    return {
        /**
         * TODO remove once a integrations specific API is available. Does not belong in the cnf_api.
         * @summary Generate New Links
         * @param {IntegrationsEndpointsApiGenerateNewLinksApiV1GenerateNewLinksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateNewLinksApiV1GenerateNewLinksGet(requestParameters: IntegrationsEndpointsApiGenerateNewLinksApiV1GenerateNewLinksGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.generateNewLinksApiV1GenerateNewLinksGet(requestParameters.userGroup, requestParameters.csvPath, requestParameters.commaSepCsvPath, requestParameters.testSummaryFilepath, requestParameters.businessUnit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for generateNewLinksApiV1GenerateNewLinksGet operation in IntegrationsEndpointsApi.
 * @export
 * @interface IntegrationsEndpointsApiGenerateNewLinksApiV1GenerateNewLinksGetRequest
 */
export interface IntegrationsEndpointsApiGenerateNewLinksApiV1GenerateNewLinksGetRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsEndpointsApiGenerateNewLinksApiV1GenerateNewLinksGet
     */
    readonly userGroup: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationsEndpointsApiGenerateNewLinksApiV1GenerateNewLinksGet
     */
    readonly csvPath: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationsEndpointsApiGenerateNewLinksApiV1GenerateNewLinksGet
     */
    readonly commaSepCsvPath: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationsEndpointsApiGenerateNewLinksApiV1GenerateNewLinksGet
     */
    readonly testSummaryFilepath: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationsEndpointsApiGenerateNewLinksApiV1GenerateNewLinksGet
     */
    readonly businessUnit: string
}

/**
 * IntegrationsEndpointsApi - object-oriented interface
 * @export
 * @class IntegrationsEndpointsApi
 * @extends {BaseAPI}
 */
export class IntegrationsEndpointsApi extends BaseAPI {
    /**
     * TODO remove once a integrations specific API is available. Does not belong in the cnf_api.
     * @summary Generate New Links
     * @param {IntegrationsEndpointsApiGenerateNewLinksApiV1GenerateNewLinksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsEndpointsApi
     */
    public generateNewLinksApiV1GenerateNewLinksGet(requestParameters: IntegrationsEndpointsApiGenerateNewLinksApiV1GenerateNewLinksGetRequest, options?: RawAxiosRequestConfig) {
        return IntegrationsEndpointsApiFp(this.configuration).generateNewLinksApiV1GenerateNewLinksGet(requestParameters.userGroup, requestParameters.csvPath, requestParameters.commaSepCsvPath, requestParameters.testSummaryFilepath, requestParameters.businessUnit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ModuleConfigurationsApi - axios parameter creator
 * @export
 */
export const ModuleConfigurationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Endpoint used to delete a schema.
         * @summary Delete Schema
         * @param {string} schemaName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchemaApiV1ModuleConfigurationsSchemasSchemaNameDelete: async (schemaName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schemaName' is not null or undefined
            assertParamExists('deleteSchemaApiV1ModuleConfigurationsSchemasSchemaNameDelete', 'schemaName', schemaName)
            const localVarPath = `/api/v1/module-configurations/schemas/{schema_name}`
                .replace(`{${"schema_name"}}`, encodeURIComponent(String(schemaName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint used delete an existing module config.
         * @summary Delete Value
         * @param {string} schemaName 
         * @param {string} configUserGroup 
         * @param {string | null} [marketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteValueApiV1ModuleConfigurationsValuesDelete: async (schemaName: string, configUserGroup: string, marketId?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schemaName' is not null or undefined
            assertParamExists('deleteValueApiV1ModuleConfigurationsValuesDelete', 'schemaName', schemaName)
            // verify required parameter 'configUserGroup' is not null or undefined
            assertParamExists('deleteValueApiV1ModuleConfigurationsValuesDelete', 'configUserGroup', configUserGroup)
            const localVarPath = `/api/v1/module-configurations/values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (schemaName !== undefined) {
                localVarQueryParameter['schema_name'] = schemaName;
            }

            if (configUserGroup !== undefined) {
                localVarQueryParameter['config_user_group'] = configUserGroup;
            }

            if (marketId !== undefined) {
                localVarQueryParameter['market_id'] = marketId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get summarized info for all available schemas.
         * @summary Get All Schemas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSchemasApiV1ModuleConfigurationsSchemasGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/module-configurations/schemas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single schema instance.
         * @summary Get Config Values
         * @param {string} schemaName 
         * @param {string | null} [configUserGroup] 
         * @param {string | null} [marketId] 
         * @param {boolean | null} [useDefaults] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGet: async (schemaName: string, configUserGroup?: string | null, marketId?: string | null, useDefaults?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schemaName' is not null or undefined
            assertParamExists('getConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGet', 'schemaName', schemaName)
            const localVarPath = `/api/v1/module-configurations/schemas/{schema_name}/values`
                .replace(`{${"schema_name"}}`, encodeURIComponent(String(schemaName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (configUserGroup !== undefined) {
                localVarQueryParameter['config_user_group'] = configUserGroup;
            }

            if (marketId !== undefined) {
                localVarQueryParameter['market_id'] = marketId;
            }

            if (useDefaults !== undefined) {
                localVarQueryParameter['use_defaults'] = useDefaults;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single schema instance.
         * @summary Get Single Schema
         * @param {string} schemaName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleSchemaApiV1ModuleConfigurationsSchemasSchemaNameGet: async (schemaName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schemaName' is not null or undefined
            assertParamExists('getSingleSchemaApiV1ModuleConfigurationsSchemasSchemaNameGet', 'schemaName', schemaName)
            const localVarPath = `/api/v1/module-configurations/schemas/{schema_name}`
                .replace(`{${"schema_name"}}`, encodeURIComponent(String(schemaName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all schemas names for given usergroup and market for which we have an instance available.
         * @summary Get Values
         * @param {string | null} [configUserGroup] 
         * @param {string | null} [marketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValuesApiV1ModuleConfigurationsValuesGet: async (configUserGroup?: string | null, marketId?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/module-configurations/values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (configUserGroup !== undefined) {
                localVarQueryParameter['config_user_group'] = configUserGroup;
            }

            if (marketId !== undefined) {
                localVarQueryParameter['market_id'] = marketId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint used to update module configuration schemas AND values in DynamoDB.
         * @summary Update Module Config Schema And Values
         * @param {UpdateSchemaAndValuesRequestBody} updateSchemaAndValuesRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModuleConfigSchemaAndValuesApiV1ModuleConfigurationsUpdateSchemaWithInstanceMigrationPut: async (updateSchemaAndValuesRequestBody: UpdateSchemaAndValuesRequestBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSchemaAndValuesRequestBody' is not null or undefined
            assertParamExists('updateModuleConfigSchemaAndValuesApiV1ModuleConfigurationsUpdateSchemaWithInstanceMigrationPut', 'updateSchemaAndValuesRequestBody', updateSchemaAndValuesRequestBody)
            const localVarPath = `/api/v1/module-configurations/update-schema-with-instance-migration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSchemaAndValuesRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint used to update module configuration schemas in DynamoDB.
         * @summary Update Module Config Schemas
         * @param {ObjectSchemaInput} objectSchemaInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModuleConfigSchemasApiV1ModuleConfigurationsSchemasPut: async (objectSchemaInput: ObjectSchemaInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectSchemaInput' is not null or undefined
            assertParamExists('updateModuleConfigSchemasApiV1ModuleConfigurationsSchemasPut', 'objectSchemaInput', objectSchemaInput)
            const localVarPath = `/api/v1/module-configurations/schemas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectSchemaInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint used to update module configuration values in DDB. If item does not exist, it will be created.
         * @summary Update Module Config Values
         * @param {string} configName 
         * @param {object} body 
         * @param {string | null} [marketId] 
         * @param {string | null} [configUserGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModuleConfigValuesApiV1ModuleConfigurationsValuesPut: async (configName: string, body: object, marketId?: string | null, configUserGroup?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configName' is not null or undefined
            assertParamExists('updateModuleConfigValuesApiV1ModuleConfigurationsValuesPut', 'configName', configName)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateModuleConfigValuesApiV1ModuleConfigurationsValuesPut', 'body', body)
            const localVarPath = `/api/v1/module-configurations/values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (configName !== undefined) {
                localVarQueryParameter['config_name'] = configName;
            }

            if (marketId !== undefined) {
                localVarQueryParameter['market_id'] = marketId;
            }

            if (configUserGroup !== undefined) {
                localVarQueryParameter['config_user_group'] = configUserGroup;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModuleConfigurationsApi - functional programming interface
 * @export
 */
export const ModuleConfigurationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModuleConfigurationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Endpoint used to delete a schema.
         * @summary Delete Schema
         * @param {string} schemaName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSchemaApiV1ModuleConfigurationsSchemasSchemaNameDelete(schemaName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSchemaApiV1ModuleConfigurationsSchemasSchemaNameDelete(schemaName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleConfigurationsApi.deleteSchemaApiV1ModuleConfigurationsSchemasSchemaNameDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Endpoint used delete an existing module config.
         * @summary Delete Value
         * @param {string} schemaName 
         * @param {string} configUserGroup 
         * @param {string | null} [marketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteValueApiV1ModuleConfigurationsValuesDelete(schemaName: string, configUserGroup: string, marketId?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteValueApiV1ModuleConfigurationsValuesDelete(schemaName, configUserGroup, marketId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleConfigurationsApi.deleteValueApiV1ModuleConfigurationsValuesDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get summarized info for all available schemas.
         * @summary Get All Schemas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSchemasApiV1ModuleConfigurationsSchemasGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SchemaSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSchemasApiV1ModuleConfigurationsSchemasGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleConfigurationsApi.getAllSchemasApiV1ModuleConfigurationsSchemasGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single schema instance.
         * @summary Get Config Values
         * @param {string} schemaName 
         * @param {string | null} [configUserGroup] 
         * @param {string | null} [marketId] 
         * @param {boolean | null} [useDefaults] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGet(schemaName: string, configUserGroup?: string | null, marketId?: string | null, useDefaults?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGet(schemaName, configUserGroup, marketId, useDefaults, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleConfigurationsApi.getConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single schema instance.
         * @summary Get Single Schema
         * @param {string} schemaName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleSchemaApiV1ModuleConfigurationsSchemasSchemaNameGet(schemaName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectSchemaOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleSchemaApiV1ModuleConfigurationsSchemasSchemaNameGet(schemaName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleConfigurationsApi.getSingleSchemaApiV1ModuleConfigurationsSchemasSchemaNameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all schemas names for given usergroup and market for which we have an instance available.
         * @summary Get Values
         * @param {string | null} [configUserGroup] 
         * @param {string | null} [marketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getValuesApiV1ModuleConfigurationsValuesGet(configUserGroup?: string | null, marketId?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getValuesApiV1ModuleConfigurationsValuesGet(configUserGroup, marketId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleConfigurationsApi.getValuesApiV1ModuleConfigurationsValuesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Endpoint used to update module configuration schemas AND values in DynamoDB.
         * @summary Update Module Config Schema And Values
         * @param {UpdateSchemaAndValuesRequestBody} updateSchemaAndValuesRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateModuleConfigSchemaAndValuesApiV1ModuleConfigurationsUpdateSchemaWithInstanceMigrationPut(updateSchemaAndValuesRequestBody: UpdateSchemaAndValuesRequestBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateModuleConfigSchemaAndValuesApiV1ModuleConfigurationsUpdateSchemaWithInstanceMigrationPut(updateSchemaAndValuesRequestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleConfigurationsApi.updateModuleConfigSchemaAndValuesApiV1ModuleConfigurationsUpdateSchemaWithInstanceMigrationPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Endpoint used to update module configuration schemas in DynamoDB.
         * @summary Update Module Config Schemas
         * @param {ObjectSchemaInput} objectSchemaInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateModuleConfigSchemasApiV1ModuleConfigurationsSchemasPut(objectSchemaInput: ObjectSchemaInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateModuleConfigSchemasApiV1ModuleConfigurationsSchemasPut(objectSchemaInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleConfigurationsApi.updateModuleConfigSchemasApiV1ModuleConfigurationsSchemasPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Endpoint used to update module configuration values in DDB. If item does not exist, it will be created.
         * @summary Update Module Config Values
         * @param {string} configName 
         * @param {object} body 
         * @param {string | null} [marketId] 
         * @param {string | null} [configUserGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateModuleConfigValuesApiV1ModuleConfigurationsValuesPut(configName: string, body: object, marketId?: string | null, configUserGroup?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateModuleConfigValuesApiV1ModuleConfigurationsValuesPut(configName, body, marketId, configUserGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleConfigurationsApi.updateModuleConfigValuesApiV1ModuleConfigurationsValuesPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ModuleConfigurationsApi - factory interface
 * @export
 */
export const ModuleConfigurationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModuleConfigurationsApiFp(configuration)
    return {
        /**
         * Endpoint used to delete a schema.
         * @summary Delete Schema
         * @param {ModuleConfigurationsApiDeleteSchemaApiV1ModuleConfigurationsSchemasSchemaNameDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchemaApiV1ModuleConfigurationsSchemasSchemaNameDelete(requestParameters: ModuleConfigurationsApiDeleteSchemaApiV1ModuleConfigurationsSchemasSchemaNameDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.deleteSchemaApiV1ModuleConfigurationsSchemasSchemaNameDelete(requestParameters.schemaName, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint used delete an existing module config.
         * @summary Delete Value
         * @param {ModuleConfigurationsApiDeleteValueApiV1ModuleConfigurationsValuesDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteValueApiV1ModuleConfigurationsValuesDelete(requestParameters: ModuleConfigurationsApiDeleteValueApiV1ModuleConfigurationsValuesDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.deleteValueApiV1ModuleConfigurationsValuesDelete(requestParameters.schemaName, requestParameters.configUserGroup, requestParameters.marketId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get summarized info for all available schemas.
         * @summary Get All Schemas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSchemasApiV1ModuleConfigurationsSchemasGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<SchemaSummary>> {
            return localVarFp.getAllSchemasApiV1ModuleConfigurationsSchemasGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single schema instance.
         * @summary Get Config Values
         * @param {ModuleConfigurationsApiGetConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGet(requestParameters: ModuleConfigurationsApiGetConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGet(requestParameters.schemaName, requestParameters.configUserGroup, requestParameters.marketId, requestParameters.useDefaults, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single schema instance.
         * @summary Get Single Schema
         * @param {ModuleConfigurationsApiGetSingleSchemaApiV1ModuleConfigurationsSchemasSchemaNameGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleSchemaApiV1ModuleConfigurationsSchemasSchemaNameGet(requestParameters: ModuleConfigurationsApiGetSingleSchemaApiV1ModuleConfigurationsSchemasSchemaNameGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ObjectSchemaOutput> {
            return localVarFp.getSingleSchemaApiV1ModuleConfigurationsSchemasSchemaNameGet(requestParameters.schemaName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all schemas names for given usergroup and market for which we have an instance available.
         * @summary Get Values
         * @param {ModuleConfigurationsApiGetValuesApiV1ModuleConfigurationsValuesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValuesApiV1ModuleConfigurationsValuesGet(requestParameters: ModuleConfigurationsApiGetValuesApiV1ModuleConfigurationsValuesGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.getValuesApiV1ModuleConfigurationsValuesGet(requestParameters.configUserGroup, requestParameters.marketId, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint used to update module configuration schemas AND values in DynamoDB.
         * @summary Update Module Config Schema And Values
         * @param {ModuleConfigurationsApiUpdateModuleConfigSchemaAndValuesApiV1ModuleConfigurationsUpdateSchemaWithInstanceMigrationPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModuleConfigSchemaAndValuesApiV1ModuleConfigurationsUpdateSchemaWithInstanceMigrationPut(requestParameters: ModuleConfigurationsApiUpdateModuleConfigSchemaAndValuesApiV1ModuleConfigurationsUpdateSchemaWithInstanceMigrationPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.updateModuleConfigSchemaAndValuesApiV1ModuleConfigurationsUpdateSchemaWithInstanceMigrationPut(requestParameters.updateSchemaAndValuesRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint used to update module configuration schemas in DynamoDB.
         * @summary Update Module Config Schemas
         * @param {ModuleConfigurationsApiUpdateModuleConfigSchemasApiV1ModuleConfigurationsSchemasPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModuleConfigSchemasApiV1ModuleConfigurationsSchemasPut(requestParameters: ModuleConfigurationsApiUpdateModuleConfigSchemasApiV1ModuleConfigurationsSchemasPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.updateModuleConfigSchemasApiV1ModuleConfigurationsSchemasPut(requestParameters.objectSchemaInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint used to update module configuration values in DDB. If item does not exist, it will be created.
         * @summary Update Module Config Values
         * @param {ModuleConfigurationsApiUpdateModuleConfigValuesApiV1ModuleConfigurationsValuesPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModuleConfigValuesApiV1ModuleConfigurationsValuesPut(requestParameters: ModuleConfigurationsApiUpdateModuleConfigValuesApiV1ModuleConfigurationsValuesPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.updateModuleConfigValuesApiV1ModuleConfigurationsValuesPut(requestParameters.configName, requestParameters.body, requestParameters.marketId, requestParameters.configUserGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteSchemaApiV1ModuleConfigurationsSchemasSchemaNameDelete operation in ModuleConfigurationsApi.
 * @export
 * @interface ModuleConfigurationsApiDeleteSchemaApiV1ModuleConfigurationsSchemasSchemaNameDeleteRequest
 */
export interface ModuleConfigurationsApiDeleteSchemaApiV1ModuleConfigurationsSchemasSchemaNameDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ModuleConfigurationsApiDeleteSchemaApiV1ModuleConfigurationsSchemasSchemaNameDelete
     */
    readonly schemaName: string
}

/**
 * Request parameters for deleteValueApiV1ModuleConfigurationsValuesDelete operation in ModuleConfigurationsApi.
 * @export
 * @interface ModuleConfigurationsApiDeleteValueApiV1ModuleConfigurationsValuesDeleteRequest
 */
export interface ModuleConfigurationsApiDeleteValueApiV1ModuleConfigurationsValuesDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ModuleConfigurationsApiDeleteValueApiV1ModuleConfigurationsValuesDelete
     */
    readonly schemaName: string

    /**
     * 
     * @type {string}
     * @memberof ModuleConfigurationsApiDeleteValueApiV1ModuleConfigurationsValuesDelete
     */
    readonly configUserGroup: string

    /**
     * 
     * @type {string}
     * @memberof ModuleConfigurationsApiDeleteValueApiV1ModuleConfigurationsValuesDelete
     */
    readonly marketId?: string | null
}

/**
 * Request parameters for getConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGet operation in ModuleConfigurationsApi.
 * @export
 * @interface ModuleConfigurationsApiGetConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGetRequest
 */
export interface ModuleConfigurationsApiGetConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ModuleConfigurationsApiGetConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGet
     */
    readonly schemaName: string

    /**
     * 
     * @type {string}
     * @memberof ModuleConfigurationsApiGetConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGet
     */
    readonly configUserGroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof ModuleConfigurationsApiGetConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGet
     */
    readonly marketId?: string | null

    /**
     * 
     * @type {boolean}
     * @memberof ModuleConfigurationsApiGetConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGet
     */
    readonly useDefaults?: boolean | null
}

/**
 * Request parameters for getSingleSchemaApiV1ModuleConfigurationsSchemasSchemaNameGet operation in ModuleConfigurationsApi.
 * @export
 * @interface ModuleConfigurationsApiGetSingleSchemaApiV1ModuleConfigurationsSchemasSchemaNameGetRequest
 */
export interface ModuleConfigurationsApiGetSingleSchemaApiV1ModuleConfigurationsSchemasSchemaNameGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ModuleConfigurationsApiGetSingleSchemaApiV1ModuleConfigurationsSchemasSchemaNameGet
     */
    readonly schemaName: string
}

/**
 * Request parameters for getValuesApiV1ModuleConfigurationsValuesGet operation in ModuleConfigurationsApi.
 * @export
 * @interface ModuleConfigurationsApiGetValuesApiV1ModuleConfigurationsValuesGetRequest
 */
export interface ModuleConfigurationsApiGetValuesApiV1ModuleConfigurationsValuesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ModuleConfigurationsApiGetValuesApiV1ModuleConfigurationsValuesGet
     */
    readonly configUserGroup?: string | null

    /**
     * 
     * @type {string}
     * @memberof ModuleConfigurationsApiGetValuesApiV1ModuleConfigurationsValuesGet
     */
    readonly marketId?: string | null
}

/**
 * Request parameters for updateModuleConfigSchemaAndValuesApiV1ModuleConfigurationsUpdateSchemaWithInstanceMigrationPut operation in ModuleConfigurationsApi.
 * @export
 * @interface ModuleConfigurationsApiUpdateModuleConfigSchemaAndValuesApiV1ModuleConfigurationsUpdateSchemaWithInstanceMigrationPutRequest
 */
export interface ModuleConfigurationsApiUpdateModuleConfigSchemaAndValuesApiV1ModuleConfigurationsUpdateSchemaWithInstanceMigrationPutRequest {
    /**
     * 
     * @type {UpdateSchemaAndValuesRequestBody}
     * @memberof ModuleConfigurationsApiUpdateModuleConfigSchemaAndValuesApiV1ModuleConfigurationsUpdateSchemaWithInstanceMigrationPut
     */
    readonly updateSchemaAndValuesRequestBody: UpdateSchemaAndValuesRequestBody
}

/**
 * Request parameters for updateModuleConfigSchemasApiV1ModuleConfigurationsSchemasPut operation in ModuleConfigurationsApi.
 * @export
 * @interface ModuleConfigurationsApiUpdateModuleConfigSchemasApiV1ModuleConfigurationsSchemasPutRequest
 */
export interface ModuleConfigurationsApiUpdateModuleConfigSchemasApiV1ModuleConfigurationsSchemasPutRequest {
    /**
     * 
     * @type {ObjectSchemaInput}
     * @memberof ModuleConfigurationsApiUpdateModuleConfigSchemasApiV1ModuleConfigurationsSchemasPut
     */
    readonly objectSchemaInput: ObjectSchemaInput
}

/**
 * Request parameters for updateModuleConfigValuesApiV1ModuleConfigurationsValuesPut operation in ModuleConfigurationsApi.
 * @export
 * @interface ModuleConfigurationsApiUpdateModuleConfigValuesApiV1ModuleConfigurationsValuesPutRequest
 */
export interface ModuleConfigurationsApiUpdateModuleConfigValuesApiV1ModuleConfigurationsValuesPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ModuleConfigurationsApiUpdateModuleConfigValuesApiV1ModuleConfigurationsValuesPut
     */
    readonly configName: string

    /**
     * 
     * @type {object}
     * @memberof ModuleConfigurationsApiUpdateModuleConfigValuesApiV1ModuleConfigurationsValuesPut
     */
    readonly body: object

    /**
     * 
     * @type {string}
     * @memberof ModuleConfigurationsApiUpdateModuleConfigValuesApiV1ModuleConfigurationsValuesPut
     */
    readonly marketId?: string | null

    /**
     * 
     * @type {string}
     * @memberof ModuleConfigurationsApiUpdateModuleConfigValuesApiV1ModuleConfigurationsValuesPut
     */
    readonly configUserGroup?: string | null
}

/**
 * ModuleConfigurationsApi - object-oriented interface
 * @export
 * @class ModuleConfigurationsApi
 * @extends {BaseAPI}
 */
export class ModuleConfigurationsApi extends BaseAPI {
    /**
     * Endpoint used to delete a schema.
     * @summary Delete Schema
     * @param {ModuleConfigurationsApiDeleteSchemaApiV1ModuleConfigurationsSchemasSchemaNameDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleConfigurationsApi
     */
    public deleteSchemaApiV1ModuleConfigurationsSchemasSchemaNameDelete(requestParameters: ModuleConfigurationsApiDeleteSchemaApiV1ModuleConfigurationsSchemasSchemaNameDeleteRequest, options?: RawAxiosRequestConfig) {
        return ModuleConfigurationsApiFp(this.configuration).deleteSchemaApiV1ModuleConfigurationsSchemasSchemaNameDelete(requestParameters.schemaName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint used delete an existing module config.
     * @summary Delete Value
     * @param {ModuleConfigurationsApiDeleteValueApiV1ModuleConfigurationsValuesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleConfigurationsApi
     */
    public deleteValueApiV1ModuleConfigurationsValuesDelete(requestParameters: ModuleConfigurationsApiDeleteValueApiV1ModuleConfigurationsValuesDeleteRequest, options?: RawAxiosRequestConfig) {
        return ModuleConfigurationsApiFp(this.configuration).deleteValueApiV1ModuleConfigurationsValuesDelete(requestParameters.schemaName, requestParameters.configUserGroup, requestParameters.marketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get summarized info for all available schemas.
     * @summary Get All Schemas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleConfigurationsApi
     */
    public getAllSchemasApiV1ModuleConfigurationsSchemasGet(options?: RawAxiosRequestConfig) {
        return ModuleConfigurationsApiFp(this.configuration).getAllSchemasApiV1ModuleConfigurationsSchemasGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single schema instance.
     * @summary Get Config Values
     * @param {ModuleConfigurationsApiGetConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleConfigurationsApi
     */
    public getConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGet(requestParameters: ModuleConfigurationsApiGetConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGetRequest, options?: RawAxiosRequestConfig) {
        return ModuleConfigurationsApiFp(this.configuration).getConfigValuesApiV1ModuleConfigurationsSchemasSchemaNameValuesGet(requestParameters.schemaName, requestParameters.configUserGroup, requestParameters.marketId, requestParameters.useDefaults, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single schema instance.
     * @summary Get Single Schema
     * @param {ModuleConfigurationsApiGetSingleSchemaApiV1ModuleConfigurationsSchemasSchemaNameGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleConfigurationsApi
     */
    public getSingleSchemaApiV1ModuleConfigurationsSchemasSchemaNameGet(requestParameters: ModuleConfigurationsApiGetSingleSchemaApiV1ModuleConfigurationsSchemasSchemaNameGetRequest, options?: RawAxiosRequestConfig) {
        return ModuleConfigurationsApiFp(this.configuration).getSingleSchemaApiV1ModuleConfigurationsSchemasSchemaNameGet(requestParameters.schemaName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all schemas names for given usergroup and market for which we have an instance available.
     * @summary Get Values
     * @param {ModuleConfigurationsApiGetValuesApiV1ModuleConfigurationsValuesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleConfigurationsApi
     */
    public getValuesApiV1ModuleConfigurationsValuesGet(requestParameters: ModuleConfigurationsApiGetValuesApiV1ModuleConfigurationsValuesGetRequest = {}, options?: RawAxiosRequestConfig) {
        return ModuleConfigurationsApiFp(this.configuration).getValuesApiV1ModuleConfigurationsValuesGet(requestParameters.configUserGroup, requestParameters.marketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint used to update module configuration schemas AND values in DynamoDB.
     * @summary Update Module Config Schema And Values
     * @param {ModuleConfigurationsApiUpdateModuleConfigSchemaAndValuesApiV1ModuleConfigurationsUpdateSchemaWithInstanceMigrationPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleConfigurationsApi
     */
    public updateModuleConfigSchemaAndValuesApiV1ModuleConfigurationsUpdateSchemaWithInstanceMigrationPut(requestParameters: ModuleConfigurationsApiUpdateModuleConfigSchemaAndValuesApiV1ModuleConfigurationsUpdateSchemaWithInstanceMigrationPutRequest, options?: RawAxiosRequestConfig) {
        return ModuleConfigurationsApiFp(this.configuration).updateModuleConfigSchemaAndValuesApiV1ModuleConfigurationsUpdateSchemaWithInstanceMigrationPut(requestParameters.updateSchemaAndValuesRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint used to update module configuration schemas in DynamoDB.
     * @summary Update Module Config Schemas
     * @param {ModuleConfigurationsApiUpdateModuleConfigSchemasApiV1ModuleConfigurationsSchemasPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleConfigurationsApi
     */
    public updateModuleConfigSchemasApiV1ModuleConfigurationsSchemasPut(requestParameters: ModuleConfigurationsApiUpdateModuleConfigSchemasApiV1ModuleConfigurationsSchemasPutRequest, options?: RawAxiosRequestConfig) {
        return ModuleConfigurationsApiFp(this.configuration).updateModuleConfigSchemasApiV1ModuleConfigurationsSchemasPut(requestParameters.objectSchemaInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint used to update module configuration values in DDB. If item does not exist, it will be created.
     * @summary Update Module Config Values
     * @param {ModuleConfigurationsApiUpdateModuleConfigValuesApiV1ModuleConfigurationsValuesPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleConfigurationsApi
     */
    public updateModuleConfigValuesApiV1ModuleConfigurationsValuesPut(requestParameters: ModuleConfigurationsApiUpdateModuleConfigValuesApiV1ModuleConfigurationsValuesPutRequest, options?: RawAxiosRequestConfig) {
        return ModuleConfigurationsApiFp(this.configuration).updateModuleConfigValuesApiV1ModuleConfigurationsValuesPut(requestParameters.configName, requestParameters.body, requestParameters.marketId, requestParameters.configUserGroup, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilitiesApi - axios parameter creator
 * @export
 */
export const UtilitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all customer metrics for the given market.
         * @summary Get Customer Metrics For Market
         * @param {string} regionalTeamId 
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGet: async (regionalTeamId: string, userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionalTeamId' is not null or undefined
            assertParamExists('getCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGet', 'regionalTeamId', regionalTeamId)
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGet', 'userGroup', userGroup)
            const localVarPath = `/api/v1/utilities/metrics-from-customer-market/{regional_team_id}`
                .replace(`{${"regional_team_id"}}`, encodeURIComponent(String(regionalTeamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all customer metrics for the given objective type.
         * @summary Get Customer Metrics For Objective Type
         * @param {string} objectiveType 
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGet: async (objectiveType: string, userGroup: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectiveType' is not null or undefined
            assertParamExists('getCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGet', 'objectiveType', objectiveType)
            // verify required parameter 'userGroup' is not null or undefined
            assertParamExists('getCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGet', 'userGroup', userGroup)
            const localVarPath = `/api/v1/utilities/metrics-from-objective-type/{objective_type}`
                .replace(`{${"objective_type"}}`, encodeURIComponent(String(objectiveType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userGroup !== undefined) {
                localVarQueryParameter['user_group'] = userGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilitiesApi - functional programming interface
 * @export
 */
export const UtilitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all customer metrics for the given market.
         * @summary Get Customer Metrics For Market
         * @param {string} regionalTeamId 
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGet(regionalTeamId: string, userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGet(regionalTeamId, userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilitiesApi.getCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all customer metrics for the given objective type.
         * @summary Get Customer Metrics For Objective Type
         * @param {string} objectiveType 
         * @param {string} userGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGet(objectiveType: string, userGroup: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGet(objectiveType, userGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilitiesApi.getCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilitiesApi - factory interface
 * @export
 */
export const UtilitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilitiesApiFp(configuration)
    return {
        /**
         * Get all customer metrics for the given market.
         * @summary Get Customer Metrics For Market
         * @param {UtilitiesApiGetCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGet(requestParameters: UtilitiesApiGetCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<MetricsList> {
            return localVarFp.getCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGet(requestParameters.regionalTeamId, requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all customer metrics for the given objective type.
         * @summary Get Customer Metrics For Objective Type
         * @param {UtilitiesApiGetCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGet(requestParameters: UtilitiesApiGetCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<MetricsList> {
            return localVarFp.getCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGet(requestParameters.objectiveType, requestParameters.userGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGet operation in UtilitiesApi.
 * @export
 * @interface UtilitiesApiGetCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGetRequest
 */
export interface UtilitiesApiGetCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UtilitiesApiGetCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGet
     */
    readonly regionalTeamId: string

    /**
     * 
     * @type {string}
     * @memberof UtilitiesApiGetCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGet
     */
    readonly userGroup: string
}

/**
 * Request parameters for getCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGet operation in UtilitiesApi.
 * @export
 * @interface UtilitiesApiGetCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGetRequest
 */
export interface UtilitiesApiGetCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UtilitiesApiGetCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGet
     */
    readonly objectiveType: string

    /**
     * 
     * @type {string}
     * @memberof UtilitiesApiGetCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGet
     */
    readonly userGroup: string
}

/**
 * UtilitiesApi - object-oriented interface
 * @export
 * @class UtilitiesApi
 * @extends {BaseAPI}
 */
export class UtilitiesApi extends BaseAPI {
    /**
     * Get all customer metrics for the given market.
     * @summary Get Customer Metrics For Market
     * @param {UtilitiesApiGetCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public getCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGet(requestParameters: UtilitiesApiGetCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGetRequest, options?: RawAxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).getCustomerMetricsForMarketApiV1UtilitiesMetricsFromCustomerMarketRegionalTeamIdGet(requestParameters.regionalTeamId, requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all customer metrics for the given objective type.
     * @summary Get Customer Metrics For Objective Type
     * @param {UtilitiesApiGetCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public getCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGet(requestParameters: UtilitiesApiGetCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGetRequest, options?: RawAxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).getCustomerMetricsForObjectiveTypeApiV1UtilitiesMetricsFromObjectiveTypeObjectiveTypeGet(requestParameters.objectiveType, requestParameters.userGroup, options).then((request) => request(this.axios, this.basePath));
    }
}



