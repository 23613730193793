/* eslint-disable no-nested-ternary */
import { useContext, useState } from 'react';
import { styled, Button, Typography, Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  CallPlanContext,
  CallPlanTimestamp,
} from '@odaia/shared-components/src/callPlan';
import { useCallPlanContextValues } from './useCallPlanContextValues';
import { CallPlanTableProvider } from './callPlanTableProvider';
import { FieldContext } from '../fieldContext';
import { AppContext } from '../../../appContext';
import { RegionSelection } from '../navigation/regionSelection/RegionSelection';
import { EmptyView } from './emptyView';
import { CallPlanDrawer } from './CallPlanDrawer';
import { useRegions } from '../navigation/regionSelection/useRegions';

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
}));

const Header = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '32px 16px 8px',
  gap: '16px',
  borderBottom: `1px solid ${themeColors.dividerPrimaryColor}`,
}));

const Heading = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
  fontSize: '20.25px',
  fontWeight: 600,
  lineHeight: '120%',
  alignSelf: 'flex-start',
}));

const NavigationHeadingContainer = styled('div')(() => ({
  alignSelf: 'flex-end',
}));

const NavigationHeading = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16px',
}));

const StyledSkeleton = styled(Skeleton)(({ theme: { themeColors } }) => ({
  color: themeColors.buttonContentColor,
  transform: 'none',
}));

const LoadingContainer = styled('div')(({ justifyContent }) => ({
  display: 'flex',
  justifyContent,
  alignItems: 'center',
}));

const ContentWrapper = styled('div')(() => ({
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  height: '100%',
}));

const SubHeaderRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
}));

const StyledUtilityButton = styled(Button)(({ theme: { themeColors } }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: themeColors.utilityContentColor,
  textTransform: 'none',
}));

const isAdminUser = (userProfile) => userProfile?.role?.type === 'ADMIN';

export const CallPlan = () => {
  const callPlanContext = useCallPlanContextValues();
  const { profile } = useContext(AppContext);
  const { maptualListMetadata, isMaptualListsLoading } =
    useContext(FieldContext);
  const { getRegionDistrict } = useRegions();
  const [entityDrawerOpen, setEntityDrawerOpen] = useState(false);
  const [entityDrawerId, setEntityDrawerId] = useState<string | null>(null);
  const { projectId } = useParams();

  const isRegionLoading =
    isMaptualListsLoading || !maptualListMetadata?.listName;

  const navigateToPulse = () => {
    window.location.href = `/pulse/projects/${projectId}/regions/${getRegionDistrict(
      maptualListMetadata.maptualListId
    )}/sales-reps/${maptualListMetadata.maptualListId}`;
  };

  const openEntityDrawer = (entityId: string) => {
    setEntityDrawerOpen(true);
    setEntityDrawerId(entityId);
  };

  return (
    <CallPlanContext.Provider value={callPlanContext}>
      <Wrapper>
        <CallPlanDrawer
          open={entityDrawerOpen}
          entityId={entityDrawerId}
          snowflakeMaptualListId={maptualListMetadata?.sfMaptualListId}
          setOpen={setEntityDrawerOpen}
        />
        <Header>
          <NavigationHeadingContainer>
            {isAdminUser(profile) && (
              <RegionSelection regionName={maptualListMetadata?.listName} />
            )}
            {!isAdminUser(profile) &&
              (isRegionLoading ? (
                <StyledSkeleton variant="text" height={16} width={55} />
              ) : (
                <NavigationHeading>
                  {maptualListMetadata?.listName}
                </NavigationHeading>
              ))}
          </NavigationHeadingContainer>
          <Heading>Call Plan</Heading>
        </Header>
        <ContentWrapper>
          {isRegionLoading ||
          callPlanContext.isProductsLoading ||
          callPlanContext.isTimestampLoading ? (
            <>
              <LoadingContainer justifyContent="flex-start">
                <StyledSkeleton variant="text" height={30} width={250} />
              </LoadingContainer>
              <LoadingContainer justifyContent="center">
                <StyledSkeleton variant="text" height={300} width="100%" />
              </LoadingContainer>
            </>
          ) : (maptualListMetadata?.locality || '').toUpperCase() ===
            'TERRITORY' ? (
            <>
              <SubHeaderRow>
                <CallPlanTimestamp />
                <StyledUtilityButton
                  variant="utiltiy"
                  aria-label="past activity"
                  onClick={() => {
                    navigateToPulse();
                  }}
                >
                  View past activity
                </StyledUtilityButton>
              </SubHeaderRow>
              <CallPlanTableProvider openEntityDrawer={openEntityDrawer} />
            </>
          ) : (
            <EmptyView />
          )}
        </ContentWrapper>
      </Wrapper>
    </CallPlanContext.Provider>
  );
};
