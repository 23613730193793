import { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { CADENCE_NAME_MAPPING } from '@odaia/domain/src';

const StyledCadenceSelector = styled('div')(() => ({}));

const StyledToggleButton = styled(ToggleButton)(() => ({
  textTransform: 'capitalize',
}));

export const CadenceSelector = ({ updateFilterCadence, cadence }) => {
  const [selectedCadence, setSelectedCadence] = useState('');
  const cadences = Object.keys(CADENCE_NAME_MAPPING);

  const handleCadenceChange = (e, newCadence) => {
    if (newCadence) {
      setSelectedCadence(newCadence);
      updateFilterCadence(newCadence);
    }
  };

  useEffect(() => {
    if (cadence) {
      setSelectedCadence(cadence);
    } else {
      setSelectedCadence(cadences[0]);
      updateFilterCadence(cadences[0]);
    }
  }, []);

  return (
    <StyledCadenceSelector>
      {cadences && cadences.length > 0 && (
        <ToggleButtonGroup
          value={selectedCadence}
          exclusive
          onChange={handleCadenceChange}
          aria-label="Cadence"
        >
          {cadences.map(
            (currentCadence) =>
              CADENCE_NAME_MAPPING[currentCadence] && (
                <StyledToggleButton
                  key={currentCadence}
                  value={currentCadence}
                  variant="contained"
                >
                  {CADENCE_NAME_MAPPING[currentCadence] || currentCadence}
                </StyledToggleButton>
              )
          )}
        </ToggleButtonGroup>
      )}
    </StyledCadenceSelector>
  );
};
