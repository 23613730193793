import { ThemeProvider } from '@mui/material/styles';
import { theme } from '@odaia/ui/src/theme';

export const defaultInputLabelProps = { shrink: true };
export const AdminThemeProvider = ({ children }) => (
  <ThemeProvider
    theme={{
      ...theme,
      typography: {
        ...theme.typography,
        h5: { ...theme.typography.h5, color: theme.themeColors.neutral90 },
      },
      components: {
        ...theme.components,
        MuiTextField: {
          defaultProps: {
            InputLabelProps: defaultInputLabelProps,
          },
          styleOverrides: {
            root: {
              '& label': {
                color: theme.themeColors.neutral60,
              },
              '& input::placeholder': {
                opacity: 1,
              },
              '& fieldset': {
                border: `1px solid ${theme.themeColors.neutral60}`,
              },
              '& .MuiFormHelperText-root': {
                color: theme.themeColors.neutral60,
              },
              color: theme.themeColors.neutral60,
            },
          },
        },
      },
    }}
  >
    {children}
  </ThemeProvider>
);
