import { useContext } from 'react';
import { sendEvent } from '../../../../trackers/mixpanel';
import { DEFAULT_CONTEXT } from '../../../../trackers/context';
import { AppContext } from '../../../../containers/application/appContext';
import { MarketPerformanceMetadataContext } from './data/marketPerformanceMetadataContext';
import { METRIC_DATA_TYPE, SHORT_FORM_CADENCE } from '../constants';
import { toCapitalize } from '../../../../utils/toCapitalize';

interface MarketPerformanceMixpanelTrackerType {
  trackGraphTabSelection: (tabName: METRIC_DATA_TYPE) => void;
  trackGraphVolumeMetric: (
    metricName: string,
    timescaleSelected: SHORT_FORM_CADENCE
  ) => void;
  trackGraphTimescale: (
    tabName: METRIC_DATA_TYPE,
    metricName: string,
    timescaleSelected: SHORT_FORM_CADENCE
  ) => void;
}

export const useMarketPerformanceMixpanelTracker =
  (): MarketPerformanceMixpanelTrackerType => {
    const { projectList } = useContext(AppContext);
    const { projectId } = useContext(MarketPerformanceMetadataContext);

    const project = projectList?.find((proj) => proj.projectId === projectId);

    const trackEvent = (eventName: string, properties: Object): void => {
      const payload = {
        ...DEFAULT_CONTEXT,
        'Project ID': projectId ?? '',
        'Project Name': project?.projectName ?? '',
        ...properties,
      };

      sendEvent(eventName, payload);
    };

    const trackGraphTabSelection = (tabName: METRIC_DATA_TYPE): void => {
      trackEvent('Market Performance Graph Tab Selected', {
        'Tab Selected': toCapitalize(tabName),
      });
    };

    const trackGraphVolumeMetric = (
      metricName: string,
      timescaleSelected: SHORT_FORM_CADENCE
    ): void => {
      trackEvent('Market Performance Graph Volume Metric', {
        'Metric Selected': metricName,
        'Timescale Selected': timescaleSelected,
      });
    };

    const trackGraphTimescale = (
      tabName: METRIC_DATA_TYPE,
      metricName: string,
      timescaleSelected: SHORT_FORM_CADENCE
    ): void => {
      trackEvent(
        `Market Performance Graph ${toCapitalize(tabName)} Timescale`,
        {
          'Metric Selected': metricName,
          'Timescale Selected': timescaleSelected,
        }
      );
    };

    return {
      trackGraphTabSelection,
      trackGraphVolumeMetric,
      trackGraphTimescale,
    };
  };
