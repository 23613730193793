import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { darkThemeColors } from '@odaia/ui/src/colorConfig';
import BarGraphLabelAtom from './barGraphLabelAtom';

const maxWidth = '12rem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    margin: '0.5vw',
    marginBottom: '1vh',
  },
  barStyleHighContrast: {
    height: '0.3rem',
    borderRadius: '0.15rem',
    backgroundColor: theme.palette.text.primary,
    marginTop: '0.125rem',
  },
  barStyle: {
    height: '0.3rem',
    borderRadius: '0.15rem',
    backgroundColor: grey.A400,
    marginTop: '0.125rem',
  },
  percentStyle: {
    fontSize: 12,
    fontWeight: 400,
    color: darkThemeColors.tertiaryColor,
    lineHeight: 1.1,
    marginTop: '0.25rem',
    borderRadius: 5,
  },
}));

export default function BarGraphLabel(props) {
  const classes = useStyles();
  const {
    primaryText = null,
    secondaryText = null,
    tertiaryText = null,
    isColumn = true,
    highContrast = false,
    match,
  } = props;
  const { value = 0 } = props;
  const percentWidth = `${value}%`;

  return (
    <div
      className={classes.root}
      style={isColumn ? {} : { maxWidth, marginRight: '1rem' }}
    >
      {primaryText ? (
        <BarGraphLabelAtom
          text={primaryText}
          type="primaryText"
          match={match}
          isColumn={isColumn}
          customStyle={isColumn ? {} : { maxWidth }}
          highContrast={highContrast}
        />
      ) : null}
      {secondaryText ? (
        <BarGraphLabelAtom
          text={secondaryText}
          type="secondaryText"
          match={match}
          isColumn={isColumn}
          customStyle={isColumn ? {} : { maxWidth }}
          highContrast={highContrast}
        />
      ) : null}
      {tertiaryText ? (
        <BarGraphLabelAtom
          text={tertiaryText}
          type="tertiaryText"
          match={match}
          isColumn={isColumn}
          customStyle={isColumn ? {} : { maxWidth }}
          highContrast={highContrast}
        />
      ) : null}

      {value ? (
        <>
          <div
            className={
              highContrast ? classes.barStyleHighContrast : classes.barStyle
            }
            style={{ width: percentWidth, marginTop: 5 }}
          >
            &nbsp;
          </div>
          <Typography
            className={classes.percentStyle}
            style={{ width: percentWidth }}
            align="right"
          >
            {`${Math.round(value)}%`}
          </Typography>
        </>
      ) : null}
    </div>
  );
}
