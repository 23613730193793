import { IconButton, styled } from '@mui/material';

const StyledNavigationIconButton = styled(IconButton)(() => ({
  height: '24px',
  width: '24px',
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  marginRight: '16px',
  position: 'absolute',
  left: 16,
  top: 24,
  zIndex: 1,
}));

const StyledSVG = styled('svg')`
  height: 24px;
  width: 24px;
  & path {
    fill: ${(props) => props.theme.themeColors.utilityContentColor};
  }
`;

export const NavigationButton = ({ navigationOpen, handleClick }) => (
  <StyledNavigationIconButton
    aria-label="navigation"
    aria-expanded={navigationOpen}
    onClick={handleClick}
    data-testid="navigation-button"
  >
    <StyledSVG>
      <ThumbnailIcon />
    </StyledSVG>
  </StyledNavigationIconButton>
);

const ThumbnailIcon = () => (
  <>
    <mask
      id="mask0_470_1792"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="24"
    >
      <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_470_1792)">
      <path
        d="M4.5 20C3.95 20 3.47917 19.8042 3.0875 19.4125C2.69583 19.0208 2.5 18.55 2.5 18V6C2.5 5.45 2.69583 4.97917 3.0875 4.5875C3.47917 4.19583 3.95 4 4.5 4H20.5C21.05 4 21.5208 4.19583 21.9125 4.5875C22.3042 4.97917 22.5 5.45 22.5 6V18C22.5 18.55 22.3042 19.0208 21.9125 19.4125C21.5208 19.8042 21.05 20 20.5 20H4.5ZM10.5 18H20.5V6H10.5V18ZM8.5 18V6H4.5V18H8.5Z"
        fill="white"
      />
    </g>
  </>
);
