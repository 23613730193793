import { useContext, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import {
  getEntityOverviewCSV,
  getEntityOverviewCSVMetadata,
} from '../../../request/entityOverviewRequests';
import { AppContext } from '../../application/appContext';
import { FieldContext } from '../../application/appViews/field/fieldContext';
import { SHORT_FORM_CADENCE_ENUM } from '../constants';

interface CSVExportMetadataParams {
  marketId: string;
  productLineId: string;
  projectId: string;
  regionId: string;
  entityType: string;
}

const useCSVExportMetadata = ({
  projectId,
  entityType,
}: CSVExportMetadataParams): UseQueryResult => {
  const fetchMetadata = async () => {
    if (!(projectId && entityType)) {
      return null;
    }
    const params = {
      projectId,
      entityType,
    };
    const response = await getEntityOverviewCSVMetadata(params);
    return response?.data;
  };
  return useQuery({
    queryKey: ['entity-overview-csv-metadata', projectId, entityType],
    queryFn: fetchMetadata,
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error('[ERROR] useCSVExportMetadata', err);
    },
  });
};

interface CSVExportParams {
  productLineId: string;
  projectId: string;
  regionId: string;
  entityType: string;
  objectiveId: string;
  cadence: SHORT_FORM_CADENCE_ENUM | null;
  dates: string[];
  columns: string[];
}

const useCSVExport = ({
  productLineId,
  projectId,
  regionId,
  entityType,
  objectiveId,
  cadence,
  dates,
  columns,
}: CSVExportParams): UseQueryResult => {
  const fetchCSV = async () => {
    if (
      !(
        productLineId &&
        projectId &&
        regionId &&
        entityType &&
        cadence &&
        objectiveId
      )
    ) {
      return null;
    }

    const params = {
      productLineId,
      projectId,
      regionId,
      entityType,
      objectiveId,
      cadence,
      dates,
      columns,
    };
    const response = await getEntityOverviewCSV(params);
    return response?.data;
  };

  return useQuery({
    queryKey: [
      'entity-overview-csv-export',
      productLineId,
      projectId,
      regionId,
      entityType,
      objectiveId,
      cadence,
      dates,
      columns,
    ],
    queryFn: fetchCSV,
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error('[ERROR] useCSVExport', err);
    },
    enabled: false,
    cacheTime: 0,
  });
};

interface CSVExportDataResponse {
  isMetadataLoading: boolean;
  metadata: any;
  getData: () => void;
  isDataLoading: boolean;
  data: any;
  objectiveId: string;
  setObjectiveId: (objectiveId: string) => void;
  cadence: SHORT_FORM_CADENCE_ENUM | null;
  setCadence: (cadence: SHORT_FORM_CADENCE_ENUM) => void;
  columns: string[];
  setColumns: (columns: string[]) => void;
  dates: string[];
  setDates: (dates: string[]) => void;
}

export const useCSVExportData = ({
  entityType,
}: {
  entityType: string;
}): CSVExportDataResponse => {
  const { projectList } = useContext(AppContext);
  const { project, maptualListMetadata } = useContext(FieldContext);

  const projectId = project?.metadata?.projectId;
  const maptualListId = maptualListMetadata?.sfMaptualListId;

  const { productLineId } =
    projectList?.find((proj) => proj.projectId === projectId) || {};

  const [cadence, setCadence] = useState<SHORT_FORM_CADENCE_ENUM | null>(null);
  const [columns, setColumns] = useState<string[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [objectiveId, setObjectiveId] = useState<string>('');

  const { data: metadata, isLoading: isMetadataLoading } = useCSVExportMetadata(
    {
      productLineId,
      projectId,
      regionId: maptualListId,
      entityType,
    }
  );

  const {
    data,
    isFetching: isDataFetching,
    isLoading: isDataLoading,
    refetch: getData,
    error: dataError,
  } = useCSVExport({
    productLineId,
    projectId,
    regionId: maptualListId,
    entityType,
    objectiveId,
    cadence,
    dates,
    columns,
  });

  return {
    isMetadataLoading,
    metadata,
    getData,
    isDataLoading,
    isDataFetching,
    data,
    dataError,
    objectiveId,
    setObjectiveId,
    cadence,
    setCadence,
    columns,
    setColumns,
    dates,
    setDates,
  };
};
