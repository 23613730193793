import { useEffect } from 'react';
import 'typeface-roboto';
import makeStyles from '@mui/styles/makeStyles';
import {
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grow,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    'align-items': 'center',
    flex: 1,
    'flex-direction': 'column',
    'justify-content': 'center',
  },
  labelGroup: {
    'justify-content': 'flex-end',
    'flex-direction': 'column',
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    'text-align': 'left',
    padding: theme.spacing(1),
  },
  radiogroup: {
    marginTop: theme.spacing(5),
  },
  paddingBox: {},
}));

export default function IngestionNewData({
  ingestNav,
  ingest,
  getExistingDatasets,
  newOrAddDataset,
  myIndex,
}) {
  const classes = useStyles();

  useEffect(() => {
    if (!ingest.customerDatasets) {
      getExistingDatasets();
    }
  }, []);

  return (
    <div className={classes.container}>
      <CssBaseline />
      <Grow in>
        <div className={classes.labelGroup}>
          <div className={classes.paddingBox} />
          <Typography className={classes.title} variant="h2" component="h3">
            Guided Data Ingestion
          </Typography>
          <Typography className={classes.subtitle} variant="h5">
            for historical data
          </Typography>
        </div>
      </Grow>
      {ingest.isBusy ? (
        <CircularProgress size={30} thickness={5} />
      ) : (
        <RadioGroup
          className={classes.radiogroup}
          aria-label="position"
          name="position"
          value={ingest.isNewDataset ? 'newdataset' : 'addtodataset'}
          onChange={(event) => {
            newOrAddDataset(event.target.value);
          }}
        >
          <Grow in timeout={1000}>
            <Typography className={classes.subtitle} variant="h6">
              <FormControlLabel
                value="newdataset"
                control={<Radio color="primary" />}
                label=""
                labelPlacement="end"
                disabled={ingestNav.completedSteps[myIndex]}
              />
              New Dataset
            </Typography>
          </Grow>
          <Grow in timeout={1500}>
            <Typography className={classes.subtitle} variant="h6">
              <FormControlLabel
                value="addtodataset"
                control={<Radio color="primary" />}
                label=""
                labelPlacement="end"
                disabled={ingestNav.completedSteps[myIndex]}
              />
              Add to Dataset
            </Typography>
          </Grow>
        </RadioGroup>
      )}
    </div>
  );
}
