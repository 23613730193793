import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

import { defaultInputLabelProps } from '../../../admin/adminThemeProvider';

export const FormAutocomplete = ({
  name,
  control,
  rules,
  autoCompleteProps = {},
  textFieldProps = {},
  onChange,
}) => {
  const isFreeSolo = autoCompleteProps?.freeSolo;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState, formState }) => (
        <Autocomplete
          {...autoCompleteProps}
          {...field}
          onChange={(event, newValue) => {
            if (onChange) {
              onChange?.(event, newValue, { field, fieldState, formState });
            } else {
              field.onChange(newValue);
            }
          }}
          onInputChange={
            isFreeSolo
              ? (event, newInputValue) => {
                  field.onChange(newInputValue);
                }
              : undefined
          }
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                ...params.InputLabelProps,
                ...defaultInputLabelProps,
              }}
              error={fieldState.invalid}
              {...textFieldProps}
            />
          )}
        />
      )}
    />
  );
};
