import { styled, Box } from '@mui/material';

export default styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ theme, color }) => ({
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  gridGap: 10,
  marginBottom: 4,
  marginTop: 'auto',
  padding: '6px 12px',
  borderLeft: `4px solid ${theme.palette?.[color]?.main || 'white'}`,
}));
