/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { useState, useEffect } from 'react';
import {
  styled,
  Typography,
  Button,
  Skeleton,
  Popper,
  IconButton,
} from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import { PowerScore } from '@odaia/shared-components/src/powerScore';
import RouteIcon from '@mui/icons-material/Route';
import { useMapViewContext } from '@odaia/shared-components/src/contexts/mapViewContext';
import {
  CloseButton,
  StyledCloseIcon,
} from '../../../../../components/generic/modalDisplay';
import { InsightsCard } from '../../../../../components/fieldView/entityInsights/insightsCard/insightsCard';
import { ObjectiveSelector } from '../../shared/objectiveSelector/objectiveSelector';
import { useEntityPrecallInsights } from './hooks/useEntityPrecallInsights';
import { useEntityRuleInsights } from './hooks/useEntityRuleInsights';

const StyledPopover = styled(Popper)(({ theme: { themeColors } }) => ({
  width: '420px',
  maxWidth: '420px',
  border: 'none',
  gap: '10px',
  padding: '12px 24px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: themeColors.contentCardBackgroundColor,
  borderRadius: '4px',
  boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.25)',
  zIndex: 2,
}));

const StyledMapListItemSubTitle = styled('div')(
  ({ theme: { themeColors } }) => ({
    fontSize: 14,
    fontWeight: 400,
    color: themeColors.tertiaryColor,
  })
);

const StyledMapListItemPowerscore = styled('div')(() => ({
  fontWeight: 600,
  color: 'red',
  alignSelf: 'center',
}));

const HeadingContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const PopoverButtton = styled(Button)(({ theme: { themeColors } }) => ({
  display: 'flex',
  alignSelf: 'flex-end',
  height: '32px',
  padding: '8px 12px',
  borderRadius: '4px',
  border: `1px solid ${themeColors.buttonBorderColor}`,
  backgroundColor: themeColors.buttonBackgroundColor,
  boxShadow: `0px 1px 4px 0px ${themeColors.shadow}`,
  color: themeColors.buttonContentColor,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16px',
}));

const PopoverBodyContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '32px 0px 8px 0px',
}));

const SelectorWrapper = styled('div')(() => ({
  display: 'flex',
  gap: '4px',
  width: '90%',
  paddingTop: '8px',
}));

const Controls = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
  justifyContent: 'flex-end',
}));

const StyledSkeleton = styled(Skeleton)(({ theme: { themeColors } }) => ({
  color: themeColors.buttonContentColor,
  transform: 'none',
}));

const ObjectiveLabel = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
  alignContent: 'center',
  marginLeft: '10px',
}));

const StyledIconButton = styled(IconButton)(
  ({ active, theme: { themeColors } }) => ({
    color: active
      ? themeColors.utilityActiveContentColor
      : themeColors.utilityContentColor,
    backgroundColor: active ? themeColors.buttonActiveBackgroundColor : 'none',
    border: active
      ? `1px solid ${themeColors.buttonActiveBorderColor}`
      : '1px solid transparent',
    borderRadius: '5px',
  })
);

export const EntityPopover = ({
  isOpen,
  anchorEl,
  setIsEntityFullModalOpen,
  objective,
  entityData,
  topPosition,
  handleClose,
  routeEntities,
  setRoute,
  setIsRouteLoading,
  onEntityDetailsClick,
}) => {
  const {
    maptualListObjectives,
    maptualListMetadata,
    projectId,
    productLineId,
    anonymize,
  } = useMapViewContext();
  const [popoverObjectiveId, setPopoverObjectiveId] = useState(objective?.id);
  const [entityInsights, setEntityInsights] = useState(
    entityData?.insight ? [entityData?.insight] : []
  );

  const {
    data: entityPrecallInsights,
    isLoading: isEntityPrecallInsightsLoading,
  } = useEntityPrecallInsights({
    maptualListMetadata,
    projectId,
    objectiveId: popoverObjectiveId,
    entityId: entityData?.snowflakeEntityId,
  });

  const { data: entityRuleInsights, isLoading: isEntityRuleInsightsLoading } =
    useEntityRuleInsights({
      regionId: maptualListMetadata.sfMaptualListId,
      productLineId,
      projectId,
      objectiveId: popoverObjectiveId,
    });

  useEffect(() => {
    const insights = [];
    if (entityPrecallInsights?.length) {
      const precallInsightToDisplay =
        entityPrecallInsights[0].insights && entityPrecallInsights.length > 0
          ? entityPrecallInsights[0].insights?.reduce((prev, current) =>
              prev && prev.priority < current.priority ? prev : current
            )
          : null;
      if (precallInsightToDisplay) insights.push(precallInsightToDisplay);
    }
    const ruleInsight = entityRuleInsights?.[entityData?.snowflakeEntityId]
      ? {
          title: entityRuleInsights[entityData.snowflakeEntityId].title,
          description: entityRuleInsights[entityData.snowflakeEntityId].message,
        }
      : null;
    if (ruleInsight) insights.push(ruleInsight);
    setEntityInsights(insights);
  }, [entityPrecallInsights, entityRuleInsights]);

  const updateObjective = (objectiveId) => {
    if (objectiveId) {
      setPopoverObjectiveId(objectiveId);
      setEntityInsights([]);
    }
  };

  const isInRoute =
    routeEntities.length > 0 &&
    routeEntities.some(
      (e) => e.snowflakeEntityId === entityData.snowflakeEntityId
    );

  const handleRouteClick = (e) => {
    setIsRouteLoading(true);
    if (isInRoute) {
      setRoute(
        routeEntities.filter(
          (e) => e.snowflakeEntityId !== entityData.snowflakeEntityId
        )
      );
    } else {
      setRoute([...routeEntities, entityData]);
    }
    e.stopPropagation();
  };

  const selectedPopoverObjectiveIndex = maptualListObjectives.findIndex(
    (i) => i?.id === popoverObjectiveId
  );

  const insightCards = () => {
    const res = [];
    if (!entityInsights.length) return null;
    for (const insight of entityInsights) {
      if (!insight) continue;
      res.push(
        <InsightsCard
          messaging
          title={insight.title}
          subtitle={insight.description}
          icon={TimelineIcon}
          key={insight.title} // this is not a good key
        />
      );
    }
    return res;
  };

  return (
    <StyledPopover
      id={isOpen ? 'map-insights-popover' : undefined}
      data-testid="map-insights-popover"
      open={isOpen}
      anchorEl={anchorEl}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [topPosition + 36, 28],
          },
        },
      ]}
      placement="left-start"
    >
      <Controls>
        <StyledIconButton active={isInRoute} onClick={handleRouteClick}>
          <RouteIcon sx={{ fontSize: 16 }} />
        </StyledIconButton>
        <CloseButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="Close"
          size="small"
        >
          <StyledCloseIcon />
        </CloseButton>
      </Controls>
      <HeadingContent>
        <Typography variant="h3">
          {anonymize
            ? `Entity Name ${entityData.snowflakeEntityId.slice(-5)}`
            : entityData?.primaryText}
        </Typography>
        <StyledMapListItemSubTitle>
          {entityData?.secondaryText}
        </StyledMapListItemSubTitle>
      </HeadingContent>
      <SelectorWrapper>
        <StyledMapListItemPowerscore>
          <PowerScore
            score={entityData.objectiveScores[selectedPopoverObjectiveIndex]}
            objectiveScoreList={entityData.objectiveScores
              .slice(0, selectedPopoverObjectiveIndex)
              .concat(
                entityData.objectiveScores.slice(
                  selectedPopoverObjectiveIndex + 1
                )
              )}
            isSelected={false}
          />
        </StyledMapListItemPowerscore>
        {maptualListObjectives.length > 1 ? (
          <ObjectiveSelector
            projectId={projectId}
            updateFilterObjective={(objectiveId) =>
              updateObjective(objectiveId)
            }
            objectiveId={popoverObjectiveId}
            currentObjectives={maptualListObjectives}
            outlined={false}
            fullWidth
          />
        ) : (
          <ObjectiveLabel>{maptualListObjectives[0].name}</ObjectiveLabel>
        )}
      </SelectorWrapper>
      {(entityInsights.length ||
        isEntityPrecallInsightsLoading ||
        isEntityRuleInsightsLoading) && (
        <PopoverBodyContent>
          {(isEntityRuleInsightsLoading || isEntityPrecallInsightsLoading) &&
            !entityInsights.length && (
              <StyledSkeleton variant="rectangular" width={370} height={100} />
            )}
          {insightCards()}
        </PopoverBodyContent>
      )}
      <PopoverButtton
        onClick={() => {
          setIsEntityFullModalOpen(true);
          onEntityDetailsClick(entityData.snowflakeEntityId);
        }}
      >
        More Details
      </PopoverButtton>
    </StyledPopover>
  );
};
