import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  useCustomersQuery,
  useMarkets,
  useModuleConfigSchemasQuery,
  useModuleConfigValuesQuery,
} from '../../../../request/admin/queries';
import { ROUTES } from '../../../../constants';
import { useSearchParams } from '../../../../hooks/useSearchParams';

export type FormData = {
  marketId?: string;
  userGroup?: string;
};
export function useCreateInstancesSection() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const searchParams = useSearchParams();
  const { control, watch } = useForm<FormData>({
    defaultValues: {
      userGroup: searchParams.get('userGroup') ?? '',
      marketId: searchParams.get('marketId') ?? '',
    },
  });

  const { data: customers = [] } = useCustomersQuery();

  const [createPopoverAnchorEl, setCreatePopoverAnchorEl] = useState(null);
  const [createPopoverLevel, setCreatePopoverLevel] = useState<
    'usergroup' | 'market'
  >('usergroup');

  const isCreatePopoverOpen = Boolean(createPopoverAnchorEl);

  const userGroup = watch('userGroup');
  const marketId = watch('marketId');

  const { data: marketsData } = useMarkets(userGroup);

  const markets = marketsData?.markets ?? [];

  const marketOptions = useMemo(
    () =>
      markets.map((m) => ({
        label: m.market,
        value: m.marketId,
      })),
    [markets]
  );

  const createSearchParams = () => {
    const searchParams = new URLSearchParams();
    if (userGroup) {
      searchParams.set('userGroup', userGroup);
    }
    if (marketId) {
      searchParams.set('marketId', marketId);
    }
    return searchParams;
  };

  useEffect(() => {
    const searchParams = createSearchParams();
    history.replace({
      pathname: path,
      search: searchParams.toString(),
    });
  }, [userGroup, marketId]);

  const selectedMarket = marketOptions.find((m) => m.value === marketId);

  const userGroupInstancesQuery = useModuleConfigValuesQuery(
    { userGroup },
    { enabled: !!userGroup }
  );

  const marketInstancesQuery = useModuleConfigValuesQuery(
    { userGroup, marketId },
    { enabled: !!userGroup && !!marketId }
  );

  const { data: schemas } = useModuleConfigSchemasQuery();

  const userGroupHelperText = (() => {
    if (!userGroup) {
      return 'select user group to see configs';
    }
    if (
      userGroupInstancesQuery.isSuccess &&
      !userGroupInstancesQuery.isLoading &&
      !userGroupInstancesQuery.data?.length
    ) {
      return 'No user group configs yet. Add instance to get started';
    }
    return '';
  })();
  const marketIdHelperText = (() => {
    if (!userGroup) {
      return '';
    }
    if (!marketId) {
      return 'select market to view configs';
    }
    if (
      marketInstancesQuery.isSuccess &&
      !marketInstancesQuery.isLoading &&
      !marketInstancesQuery.data?.length
    ) {
      return 'No market level configs yet. Add instance to get started';
    }
    return '';
  })();

  const userGroupInstances = userGroupInstancesQuery.data ?? [];
  const marketInstances =
    marketId && marketInstancesQuery.data ? marketInstancesQuery.data : [];

  const handleClickCreatePopover = (event, level: 'usergroup' | 'market') => {
    setCreatePopoverAnchorEl(event.currentTarget);
    setCreatePopoverLevel(level);
  };

  const handleCloseCreatePopoever = () => {
    setCreatePopoverAnchorEl(null);
  };

  const missingInstances = (() => {
    const existingInstances =
      createPopoverLevel === 'usergroup' ? userGroupInstances : marketInstances;
    if (!schemas) {
      return [];
    }
    return schemas.filter((schema) => !existingInstances.includes(schema.name));
  })();

  const handleClickDropdownItem = (schemaName: string) => {
    const searchParams = new URLSearchParams();
    if (userGroup) {
      searchParams.set('userGroup', userGroup);
    }
    if (marketId && createPopoverLevel === 'market') {
      searchParams.set('marketId', marketId);
    }
    history.push({
      pathname: `${path}${ROUTES.SCHEMAS}/${schemaName}${ROUTES.CREATE_INSTANCE}`,
      search: searchParams.toString(),
    });
  };

  return {
    control,
    customers,
    marketOptions,
    userGroup,
    userGroupHelperText,
    marketIdHelperText,
    selectedMarket,
    userGroupInstances,
    isLoadingUserGroupInstances: userGroupInstancesQuery.isLoading,
    marketInstances,
    isLoadingMarketInstances: marketInstancesQuery.isLoading,
    createPopoverAnchorEl,
    isCreatePopoverOpen,
    handleClickCreatePopover,
    handleCloseCreatePopoever,
    missingInstances,
    handleClickDropdownItem,
  };
}
