import { useContext } from 'react';
import { sendEvent } from '../../../../trackers/mixpanel';
import { DEFAULT_CONTEXT } from '../../../../trackers/context';
import { AppContext } from '../../../../containers/application/appContext';
import { ProductPerformanceMetadataContext } from './data/productPerformanceMetadataContext';

interface ProductPerformanceMixpanelTrackerType {
  trackGraphTabSelection: (tabName: string) => void;
  trackGraphVolumeMetric: (
    metricName: string,
    timescaleSelected: string
  ) => void;
  trackGraphCardTimescale: (
    tabName: string,
    metricName: string,
    timescaleSelected: string
  ) => void;
  trackGraphAddProduct: (productName: string) => void;
}

export const useProductPerformanceMixpanelTracker =
  (): ProductPerformanceMixpanelTrackerType => {
    const { projectList } = useContext(AppContext);
    const { projectId } = useContext(ProductPerformanceMetadataContext);

    const project = projectList?.find((proj) => proj.projectId === projectId);

    const trackEvent = (eventName: string, properties: Object): void => {
      const payload = {
        ...DEFAULT_CONTEXT,
        'Project ID': projectId ?? '',
        'Project Name': project?.projectName ?? '',
        ...properties,
      };

      sendEvent(eventName, payload);
    };

    const trackGraphTabSelection = (tabName: string): void => {
      trackEvent('Product Performance Graph Tab Selected', {
        'Tab Selected': tabName,
      });
    };

    const trackGraphVolumeMetric = (
      metricName: string,
      timescaleSelected: string
    ): void => {
      trackEvent('Product Performance Graph Volume Metric', {
        'Metric Selected': metricName,
        'Timescale Selected': timescaleSelected,
      });
    };

    const trackGraphCardTimescale = (
      tabName: string,
      metricName: string,
      timescaleSelected: string
    ): void => {
      trackEvent(`Product Performance Graph ${tabName} Timescale`, {
        'Metric Selected': metricName,
        'Timescale Selected': timescaleSelected,
      });
    };

    const trackGraphAddProduct = (productName: string): void => {
      trackEvent('Product Performance Graph Add Product', {
        'New product checkbox selected': true,
        'Product Name': productName,
      });
    };

    return {
      trackGraphTabSelection,
      trackGraphVolumeMetric,
      trackGraphCardTimescale,
      trackGraphAddProduct,
    };
  };
