import React from "react";
import { styled, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";

const SwitchContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const StyledSwitchTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.segmentSelectionCaptionColor,
}));

const FilterSwitchSelection = ({
  selectedFilters,
  setSelectedFilters,
  switchDescription,
  switchId,
}) => {
  return (
    <SwitchContainer>
      <StyledSwitchTitle noWrap variant="body1">
        {switchDescription}
      </StyledSwitchTitle>
      <Switch
        checked={selectedFilters.userCreatedListFilter[switchId]}
        onChange={(_, val) => {
          if (val) {
            setSelectedFilters({
              ...selectedFilters,
              userCreatedListFilter: {
                ...selectedFilters.userCreatedListFilter,
                [switchId]: true,
              },
            });
          } else {
            const newFilter = { ...selectedFilters };
            newFilter.userCreatedListFilter[switchId] =
              !newFilter.userCreatedListFilter[switchId];
            setSelectedFilters(newFilter);
          }
        }}
      />
    </SwitchContainer>
  );
};

export default FilterSwitchSelection;
