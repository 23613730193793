import { useRouteMatch } from 'react-router-dom';
import { useContext } from 'react';
import { ROUTES } from '../constants';
import { AppContext } from '../containers/application/appContext';

const useColumnPreferences = () => {
  const match = useRouteMatch(`${ROUTES.SPHERE}${ROUTES.PROJECTS}/:projectId`);
  const { profile } = useContext(AppContext);
  const columnPreferences =
    profile?.preference?.columnPreferences?.[match?.params?.projectId];

  return columnPreferences;
};

export default useColumnPreferences;
