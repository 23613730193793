import { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { STATIC_CURATED_TYPES } from '../../../../shared/constants';

const StyledCuratedTypeSelector = styled('div')(() => ({}));

const StyledToggleButton = styled(ToggleButton)(() => ({
  textTransform: 'capitalize',
  minWidth: 121,
}));

export const CuratedTypeSelector = ({ updateFilterCuratedType }) => {
  const [selectedCuratedType, setSelectedCuratedType] = useState([]);

  const handleCuratedTypeChange = (e, newCuratedType) => {
    if (newCuratedType.length > 0) {
      setSelectedCuratedType(newCuratedType);
      updateFilterCuratedType(newCuratedType);
    }
  };

  useEffect(() => {
    setSelectedCuratedType([STATIC_CURATED_TYPES[0]]);
    updateFilterCuratedType([STATIC_CURATED_TYPES[0]]);
  }, []);

  return (
    <StyledCuratedTypeSelector>
      <ToggleButtonGroup
        value={selectedCuratedType}
        onChange={handleCuratedTypeChange}
        aria-label="Metric Type"
      >
        {STATIC_CURATED_TYPES.map((curatedType) => (
          <StyledToggleButton
            key={curatedType}
            value={curatedType}
            variant="contained"
          >
            {curatedType}
          </StyledToggleButton>
        ))}
      </ToggleButtonGroup>
    </StyledCuratedTypeSelector>
  );
};
