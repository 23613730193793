import { useContext, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import { SentryRoute } from '../../sentryRoute';
import * as ViewerAction from '../../actions/projectViewActions';
import ProjectContent from '../../components/projectviewer/projectContent';
import ProjectControl from '../../components/projectviewer/projectControl';
import ProjectItem from '../../components/projectviewer/projectItem';
import { ProjectContext } from '../../contexts/projectContexts';
import { AdminContext } from '../application/appViews/adminContext';

function TabContainer({ children }) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.themeColors.mainBackground,
    height: '100%',
    'min-height': '100%',
    display: 'flex',
    'flex-direction': 'column',
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  progressContainer: {
    height: '100%',
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'flex-end',
    'align-items': 'center',
  },
  progressBar: {
    'margin-top': '50px',
    width: 'auto',
    'margin-bottom': '70px',
  },
  projectName: {
    flexGrow: 1,
  },
  widthSpacer: {
    width: 25,
  },
}));

const mapStateToProps = (state) => ({
  viewer: state.projectViewer,
});

const mapDispatchToProps = (dispatch) => ({
  deleteProjectItem: ({ projectId, useMaptualAPI }) =>
    dispatch(ViewerAction.deleteProject({ projectId, useMaptualAPI })),
  updateProjectItem: (project) =>
    dispatch(ViewerAction.updateProjectMetadata(project)),
  getAnalysis: (analysisId, userId = null) =>
    dispatch(ViewerAction.getAnalysis(analysisId, userId)),
  getProject: (projectId) =>
    dispatch(ViewerAction.getProjectFromAPI(projectId)),
  setProjectItem: (item) => dispatch(ViewerAction.setProjectItem(item)),
  setFullAnalysisItem: (item) =>
    dispatch(ViewerAction.setFullAnalysisItem(item)),
  setFeatureIdMapping: (item) =>
    dispatch(ViewerAction.setFeatureIdMapping(item)),
  setAnalysisIdMapping: (item) =>
    dispatch(ViewerAction.setAnalysisIdMapping(item)),
  setAnalysisExpanded: (item) =>
    dispatch(ViewerAction.setAnalysisExpanded(item)),
});

function ProjectViewer(props) {
  const classes = useStyles();
  const {
    match,
    profile,
    deleteProjectItem,
    updateProjectItem,
    viewer,
    setAnalysisExpanded,
  } = props;

  const { setShowProjectBuilderDemoModeToggle } = useContext(AdminContext);

  const [injectableComponents, setInjectableComponents] = useState([]);
  const [customCrumb, setCustomCrumb] = useState(null); // {component: {}, action: ()=>{}}

  useEffect(() => {
    setShowProjectBuilderDemoModeToggle(true);
    return () => {
      setShowProjectBuilderDemoModeToggle(false);
    };
  }, []);

  const expandButtonProps = {
    isAnalysisExpanded: viewer.isAnalysisExpanded,
    setAnalysisExpanded: () => setAnalysisExpanded(!viewer.isAnalysisExpanded),
  };

  return (
    <div className={classes.root}>
      <Switch>
        <SentryRoute
          exact
          path={match.path}
          render={() => (
            <>
              <ProjectControl
                injectableControls={injectableComponents}
                customCrumb={customCrumb}
              />
              <ProjectContent
                {...props}
                setInjectableComponents={setInjectableComponents}
                profile={profile}
                deleteProject={deleteProjectItem}
                updateProject={updateProjectItem}
              />
            </>
          )}
        />
        <SentryRoute
          path={`${match.path}/:projectId/:externalAnalysisId?`}
          render={(renderProps) => (
            <>
              {!viewer.isAnalysisExpanded ? (
                <ProjectControl
                  injectableControls={injectableComponents}
                  customCrumb={customCrumb}
                />
              ) : null}
              <ProjectContext.Provider value={expandButtonProps}>
                <ProjectItem
                  {...props}
                  returnMatch={match}
                  match={renderProps.match}
                  setInjectableComponents={setInjectableComponents}
                  setCustomCrumb={setCustomCrumb}
                />
              </ProjectContext.Provider>
            </>
          )}
        />
      </Switch>
    </div>
  );
}

ProjectViewer.propTypes = {
  deleteProjectItem: PropTypes.func.isRequired,
  match: PropTypes.any,
  profile: PropTypes.shape({
    email: PropTypes.string,
    fullName: PropTypes.string,
    name: PropTypes.string,
    organization: PropTypes.shape({
      organizationName: PropTypes.string,
    }),
    role: PropTypes.shape({
      specialType: PropTypes.string,
      subType: PropTypes.string,
      type: PropTypes.string,
    }),
    userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  setAnalysisExpanded: PropTypes.func.isRequired,
  viewer: PropTypes.shape({
    isAnalysisExpanded: PropTypes.bool,
    setAnalysisExpanded: PropTypes.func,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectViewer);
