import { Box, Typography, styled } from '@mui/material';

const Container = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  padding: '48px 56px',
  flexDirection: 'column',
  gap: 16,
  borderRadius: 4,
  background: themeColors.surfaceEmpty,
  alignItems: 'center',
  height: '100%',
  justifyContent: 'center',
}));

const TextContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  textAlign: 'center',
});

const Text = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.emptyText,
  whiteSpace: 'pre-line',
}));

export const AmaOptOutSection = () => (
  <Container>
    <TextContainer>
      <Text variant="title3">Rx Data Not Available</Text>
      <Text variant="subtitle1">
        This HCP has opted out of having individual prescribing data released to
        pharmaceutical representatives.
      </Text>
    </TextContainer>
  </Container>
);
