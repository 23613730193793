import { useQuery } from 'react-query';
import {
  EntityType,
  OpportunityChartMetadataResponse,
} from '@odaia/clients/maptualAPI';
import { fieldApi } from '../../../../../request/maptualApiClient';

export const useOpportunityMetadata = (
  objectiveId: string,
  productLineId: string,
  entityId: string,
  entityType: EntityType
): {
  data?: OpportunityChartMetadataResponse;
  isLoading: boolean;
  refetch: () => {};
  isError: boolean;
  isIdle: boolean;
} => {
  const { data, isLoading, refetch, isError, isIdle } = useQuery({
    queryKey: ['opportunity-metadata', objectiveId, productLineId, entityId],
    queryFn: async () => {
      const response = await fieldApi.getOpportunityChartMetadata({
        productLineId,
        entityId,
        entityType,
        objectiveId,
      });

      return response.data;
    },
    enabled: !!objectiveId,
  });

  return {
    data,
    isLoading,
    refetch,
    isError,
    isIdle,
  };
};
