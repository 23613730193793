import {
  Box,
  Slider,
  SliderThumb,
  Theme,
  styled,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

type PowerscoreNumberColorType = {
  value: number;
  color: string;
};

const StyledSlider = styled(Slider)(
  ({
    theme,
    value,
    powerScoreColorNumberMap,
  }: {
    theme: Theme;
    value: number[];
    powerScoreColorNumberMap: PowerscoreNumberColorType[];
  }) => ({
    color: theme.themeColors.inputs.secondary.default.surface,
    height: 10,
    "& .MuiSlider-rail": {
      opacity: 0.3,
      background: `linear-gradient(to right, 
        ${powerScoreColorNumberMap
          .map((colorNumber) => {
            return `${colorNumber.color} ${(colorNumber.value - 1) * 10}% ${
              colorNumber.value * 10
            }% `;
          })
          .toString()}
      )`,
    },

    "& .MuiSlider-track": {
      background: `linear-gradient(to right, 
        ${powerScoreColorNumberMap
          .map((colorNumber) => {
            if (
              colorNumber.value >= value[0] &&
              colorNumber.value <= value[1]
            ) {
              return `${colorNumber.color} ${
                (colorNumber.value - value[0] - 1) *
                10 *
                ((1 / (value[1] - value[0]) / 10) * 100)
              }% ${
                (colorNumber.value - value[0]) *
                10 *
                ((1 / (value[1] - value[0]) / 10) * 100)
              }% `;
            }
          })
          .filter((colorString) => colorString !== undefined)
          .toString()}
      )`,
      opacity: 1,
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 32,
      width: 32,
      border: `1px solid ${theme.themeColors.inputs.secondary.default.border}`,
      "&:hover": {
        boxShadow: `0px 0px 0px 0px`,
      },
    },
    "& .MuiSlider-valueLabel": {
      backgroundColor: theme.themeColors.tooltipSurfaceColor,
      color: theme.themeColors.tooltipContentColor,
      border: "none",
      padding: "4px 8px",
    },
  })
);

const StyledThumbValue = styled("span")(({ theme }) => ({
  color: theme.themeColors.tertiaryColor,
}));

type ThumbLabelProps = {
  children: any;
  ownerState: any;
  "data-index": number;
};

const ThumbLabel = ({ children, ...other }: ThumbLabelProps) => {
  return (
    <SliderThumb {...other}>
      {children}
      <StyledThumbValue>
        {other.ownerState.value[other["data-index"]]}
      </StyledThumbValue>
    </SliderThumb>
  );
};

type PowerscoreFilterValueType = {
  min: number;
  max: number;
};

export const PowerscoreSlider = ({
  defaultValue = { min: 0, max: 10 },
  updateValue,
}: {
  defaultValue: PowerscoreFilterValueType;
  updateValue: (arg0: PowerscoreFilterValueType) => void;
}) => {
  const { themeColors } = useTheme();
  const powerScoreColorNumberMap: PowerscoreNumberColorType[] = [
    { value: 0, color: themeColors.powerscore[0].borderColor },
    { value: 1, color: themeColors.powerscore[1].borderColor },
    { value: 2, color: themeColors.powerscore[2].borderColor },
    { value: 3, color: themeColors.powerscore[3].borderColor },
    { value: 4, color: themeColors.powerscore[4].borderColor },
    { value: 5, color: themeColors.powerscore[5].borderColor },
    { value: 6, color: themeColors.powerscore[6].borderColor },
    { value: 7, color: themeColors.powerscore[7].borderColor },
    { value: 8, color: themeColors.powerscore[8].borderColor },
    { value: 9, color: themeColors.powerscore[9].borderColor },
    { value: 10, color: themeColors.powerscore[10].borderColor },
  ];
  const [value, setValue] = useState<number[]>([
    defaultValue.min,
    defaultValue.max,
  ]);

  const handleChange = (event: Event, newValue: number[]) => {
    setValue(newValue as number[]);
    updateValue({ min: newValue[0], max: newValue[1] });
  };

  return (
    <Box sx={{ width: 300 }}>
      <StyledSlider
        getAriaLabel={() => "powerscore range"}
        value={value}
        onChange={handleChange}
        step={1}
        min={0}
        max={10}
        valueLabelDisplay="auto"
        track="inverted"
        powerScoreColorNumberMap={powerScoreColorNumberMap}
        slots={{ thumb: ThumbLabel }}
      />
    </Box>
  );
};
