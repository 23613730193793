import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { ProjectModalMode } from '../../newproject/projectModalMode';

type DeployDialogContextType = {
  activeProject: {};
  activeTemplate: {};
  setProjectModalMode: Dispatch<SetStateAction<ProjectModalMode>>;
  setDeployDialogState: Dispatch<SetStateAction<string>>;
  projects: any;
  projectModalMode: ProjectModalMode;
  isTemplate: boolean;
  setActiveProject: Dispatch<SetStateAction<{}>>;
  deployDialogState: string;
  setActiveTemplate: Dispatch<SetStateAction<{}>>;
  setShowProjectDialog: Dispatch<SetStateAction<boolean>>;
};
export const DeployDialogContext =
  createContext<DeployDialogContextType>(undefined);

export const useDeployDialogContext = () => {
  const context = useContext(DeployDialogContext);
  if (context === undefined) {
    throw new Error(
      'useDeployDialogContext must be within DeployDialogContextProvider'
    );
  }
  return context;
};
export const DeployFileContext = createContext(null);
