import {
  Box,
  Button,
  Skeleton,
  Slider,
  styled,
  Typography,
  Tooltip,
  SliderValueLabelProps,
  Popper,
} from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useContext, useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from '@odaia/ui/src/components/snackbar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  ModalDisplay,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '../../../../components/generic/modalDisplay';
import { EntityOverviewContext } from '../../data/entityOverviewContext';
import { SelectionChip } from '../../../../components/generic/selectionChip';
import { useCSVExportData } from '../../data/useCSVExportData';
import {
  CADENCES_ENUM,
  SHORT_FORM_CADENCE_ENUM,
  CSV_EXPORT_STATUS_ENUM,
} from '../../constants';
import { trackEntityDataExport } from '../../../../trackers/mixpanel';

interface ExportOption {
  id: string;
  label: string;
}

interface DateOption {
  [key: string]: ExportOption[];
}
interface ObjectiveOptions {
  id: string;
  label: string;
  cadences: SHORT_FORM_CADENCE_ENUM[];
  columns: ExportOption[];
  dates: DateOption;
}

const DEFAULT_OBJECTIVE_SETTINGS: ObjectiveOptions = {
  id: '',
  label: '',
  cadences: [],
  columns: [],
  dates: {},
};

const StyledTooltipPopper = styled(Popper)(({ theme: { themeColors } }) => ({
  '.MuiTooltip-arrow::before': {
    backgroundColor: themeColors.tooltipSurfaceColor,
  },
  '.MuiTooltip-tooltip': {
    backgroundColor: themeColors.tooltipSurfaceColor,
  },
}));

const ValueLabelComponent = ({ children, value }: SliderValueLabelProps) => (
  <Tooltip
    enterTouchDelay={0}
    title={value}
    arrow
    placement="top"
    PopperComponent={StyledTooltipPopper}
  >
    {children}
  </Tooltip>
);

const StyledLoadingButton = styled(LoadingButton)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.utilityContentColor,
    padding: '8px 12px',
    minWidth: 'unset',
  })
);

const Divider = styled(Box)(({ theme: { themeColors } }) => ({
  margin: 8,
  borderBottom: `1px solid ${themeColors.dividerPrimaryColor}`,
}));

const Section = styled(Box)(() => ({
  margin: 8,
  display: 'flex',
  gap: 8,
  flexDirection: 'column',
}));

const SkeletonBar = styled(Skeleton, {
  shouldForwardProp: (prop) => prop !== 'height',
})(({ theme: { themeColors }, height }) => ({
  backgroundColor: themeColors.surfaceEmpty,
  transform: 'scale(1)',
  height,
  width: '100%',
}));

const CheckboxContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridGap: 9,
  alignItems: 'flex-start',

  '& .MuiFormControlLabel-label': {
    paddingTop: 1.5,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme: { themeColors } }) => ({
    marginLeft: 0,
    color: themeColors.primaryTextColor,
    '.MuiCheckbox-root': {
      padding: 0,
      marginRight: 9,
      alignSelf: 'start',
    },
    paddingTop: 0,
  })
);

const StyledCheckbox = styled(Checkbox)(({ theme: { themeColors } }) => ({
  '&.Mui-checked': {
    color: themeColors.checkboxSelected,
  },
}));

export const getInitialDates = (
  dates: DateOption,
  cadence: SHORT_FORM_CADENCE_ENUM
): string[] => {
  if (!dates || !cadence) return [];
  const cadenceDates = dates[cadence] ?? [];
  return cadenceDates.map((date) => date.id);
};

export const getInitialColumns = (columns: ExportOption[]): string[] => {
  if (!columns) return [];
  return columns.map((column) => column.id);
};

export const calculateDateInterval = (
  dateOptions: DateOption,
  cadence: SHORT_FORM_CADENCE_ENUM | null
): number => {
  if (!cadence || !dateOptions[cadence]?.length) return 1;
  return 100 / (dateOptions[cadence].length - 1);
};

export const calculateDatesFromSlider = (
  range: number[],
  interval: number,
  dates: ExportOption[]
): string[] => {
  if (!dates?.length || !interval || !range) return [];
  const [start, end] = range.map((v: number) => Math.round(v / interval));
  if (end - start < 1) {
    return [];
  }
  return dates.slice(start, end + 1).map((date) => date.id);
};

const SnackbarMessage = ({ title, body }) => (
  <Box display="flex" flexDirection="column" gap={0.5}>
    <Typography variant="title3">{title}</Typography>
    <Typography variant="subtitle1">{body}</Typography>
  </Box>
);

export const CSVExport = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [dateSliderValues, setDateSliderValues] = useState<number[]>([0, 100]);
  const [dateLabels, setDateLabels] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const {
    isDataFetching: isTableLoading,
    metadata: tableMetadata,
    objectiveId: initialSelectedObjectiveId,
    cadence: initialSelectedCadence,
    entityType,
  } = useContext(EntityOverviewContext);

  const {
    getData: getCSVExport,
    isDataLoading: isCSVLoading,
    isDataFetching: isCSVFetching,
    data: csvData,
    dataError: csvError,
    metadata,
    isMetadataLoading,
    cadence,
    setCadence,
    objectiveId,
    setObjectiveId,
    columns,
    setColumns,
    dates,
    setDates,
  } = useCSVExportData({
    entityType,
  });

  useEffect(() => {
    setCadence(initialSelectedCadence);
  }, [initialSelectedCadence]);

  useEffect(() => {
    setObjectiveId(initialSelectedObjectiveId);
  }, [initialSelectedObjectiveId]);

  const { dates: dateOptions, cadences: cadenceOptions } =
    useMemo<ObjectiveOptions>(
      () => metadata?.objectives?.[objectiveId] ?? DEFAULT_OBJECTIVE_SETTINGS,
      [objectiveId, metadata]
    );

  const dateInterval = useMemo<number>(
    () => calculateDateInterval(dateOptions, cadence),
    [dateOptions, cadence]
  );

  useEffect(() => {
    if (!dateOptions || !cadence) setDateLabels('');

    const [start, end] = dateSliderValues.map((v: number) =>
      Math.round(v / dateInterval)
    );

    const startLabel = dateOptions[cadence]?.[start]?.label;
    const endLabel = dateOptions[cadence]?.[end]?.label;

    if (!startLabel || !endLabel) setDateLabels('');
    setDateLabels(`${startLabel} - ${endLabel}`);
  }, [dateOptions, cadence, dateSliderValues]);

  useEffect(() => {
    if (!metadata || !metadata?.objectives?.[objectiveId]) return;
    const initialColumns = getInitialColumns(
      metadata?.objectives?.[objectiveId]?.columns
    );
    setColumns(initialColumns);

    const initialDates = getInitialDates(
      metadata?.objectives?.[objectiveId]?.dates,
      cadence
    );
    setDates(initialDates);
  }, [metadata, objectiveId, cadence]);

  useEffect(() => {
    setDateSliderValues([0, 100]);
  }, [cadence, objectiveId]);

  const handleExport = () => {
    getCSVExport();
    setOpen(false);
    enqueueSnackbar(
      <SnackbarMessage
        title="Preparing CSV Export"
        body="We’re preparing your CSV. This may take up to a minute."
      />,
      {
        variant: 'info',
      }
    );
  };

  const objectiveName = tableMetadata?.objectives.find(
    (objective) => objective.id === objectiveId
  )?.label;

  const trackMixpanelEvent = (exportStatus: CSV_EXPORT_STATUS_ENUM) => {
    const allColumns = metadata?.objectives[objectiveId]?.columns || [];
    const selectedColumnLabels = columns.map(
      (columnId) => allColumns.find((c) => c.id === columnId)?.label
    );

    trackEntityDataExport({
      entityType,
      product: objectiveName,
      cadence: CADENCES_ENUM[cadence],
      columns: selectedColumnLabels,
      dateRange: dates.length,
      exportStatus,
    });
  };

  useEffect(() => {
    if (csvError) {
      const isRequestTimeout = csvError.code === 'ECONNABORTED';
      if (isRequestTimeout) {
        enqueueSnackbar(
          <SnackbarMessage
            title="CSV Export Timeout"
            body="The attempt to export the data to CSV was unsuccessful due to a processing timeout. Try exporting a smaller dataset for better results."
          />,
          {
            variant: 'error',
          }
        );

        trackMixpanelEvent(CSV_EXPORT_STATUS_ENUM.TIMEOUT);
      } else {
        enqueueSnackbar(
          <SnackbarMessage
            title="CSV Export Failed"
            body="The attempt to export your data to CSV was unsuccessful. Please try again later. If the problem persists, contact support for assistance."
          />,
          {
            variant: 'error',
          }
        );

        trackMixpanelEvent(CSV_EXPORT_STATUS_ENUM.FAILURE);
      }
    }
  }, [csvError]);

  useEffect(() => {
    if (!isCSVFetching && !isCSVLoading && csvData) {
      const url = window.URL.createObjectURL(new Blob([csvData]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${objectiveName} ${CADENCES_ENUM[cadence]}.csv`
      );

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      trackMixpanelEvent(CSV_EXPORT_STATUS_ENUM.SUCCESS);
    }
  }, [isCSVFetching, isCSVLoading, csvData]);

  const hasMetadata = Object.keys(metadata || {}).length > 0;

  const handleColumnChange = (columnId) => {
    const newColumns = [...columns];
    if (newColumns.includes(columnId)) {
      newColumns.splice(newColumns.indexOf(columnId), 1);
    } else {
      newColumns.push(columnId);
    }
    setColumns(newColumns);
  };

  const getDateLabel = (value: number): string => {
    const date = dateOptions[cadence]?.[Math.round(value / dateInterval)];
    return date?.label ?? '';
  };

  const handleDates = (_: Event, value: number | number[]) => {
    if (!Array.isArray(value)) {
      return;
    }

    const dates = calculateDatesFromSlider(
      value,
      dateInterval,
      dateOptions[cadence] ?? []
    );

    if (!dates.length) return;

    setDates(dates);
    setDateSliderValues(value);
  };

  return (
    <>
      <StyledLoadingButton
        aria-label="csv-export"
        size="small"
        data-testid="csv-export-button"
        onClick={() => setOpen(true)}
        disabled={isTableLoading}
        loading={isCSVLoading || isCSVFetching}
      >
        <SaveAltIcon />
      </StyledLoadingButton>
      <ModalDisplay isOpen={open} onClose={setOpen}>
        <ModalHeader onClose={setOpen}>Customize CSV Export</ModalHeader>
        <ModalBody sx={{ gap: 1 }}>
          <Section>
            <Typography variant="h5">Product</Typography>
            <Box>
              {tableMetadata?.objectives.map((objective) => (
                <SelectionChip
                  label={objective.label}
                  selected={objectiveId === objective.id}
                  onClick={() => setObjectiveId(objective.id)}
                  key={objective.id}
                  data-testid={
                    `objective-${objective.id}` +
                    `${objectiveId === objective.id ? '-selected' : ''}`
                  }
                />
              ))}
            </Box>
          </Section>
          <Divider />
          <Section>
            <Typography variant="h5">Cadence</Typography>
            {isMetadataLoading && <SkeletonBar height={32} />}
            {!isMetadataLoading && hasMetadata && (
              <Box>
                {cadenceOptions?.map((c: SHORT_FORM_CADENCE_ENUM) => (
                  <SelectionChip
                    label={CADENCES_ENUM[c] as string}
                    selected={c === cadence}
                    onClick={() => setCadence(c)}
                    key={c}
                  />
                ))}
              </Box>
            )}
          </Section>
          <Divider />
          <Section>
            <Typography variant="h5">Columns</Typography>
            {isMetadataLoading && <SkeletonBar height={208} />}
            {!isMetadataLoading && hasMetadata && (
              <CheckboxContainer>
                {metadata?.objectives[objectiveId]?.columns?.map((column) => (
                  <StyledFormControlLabel
                    key={column.id}
                    control={
                      <StyledCheckbox
                        name={column.id}
                        onChange={() => handleColumnChange(column.id)}
                        checked={(columns || []).includes(column.id)}
                      />
                    }
                    label={column.label}
                  />
                ))}
              </CheckboxContainer>
            )}
          </Section>
          <Divider />
          <Section>
            <Typography variant="h5">Date Range</Typography>
            <Typography variant="body2">{dateLabels}</Typography>
            {isMetadataLoading && <SkeletonBar height={48} />}
            {!isMetadataLoading && hasMetadata && (
              <Box paddingX="10px">
                <Slider
                  defaultValue={[0, 100]}
                  value={dateSliderValues}
                  onChange={handleDates}
                  step={dateInterval}
                  disableSwap
                  valueLabelFormat={(value) => getDateLabel(value)}
                  valueLabelDisplay="auto"
                  slots={{
                    valueLabel: ValueLabelComponent,
                  }}
                />
              </Box>
            )}
          </Section>
        </ModalBody>
        <ModalFooter>
          <Button variant="utility" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleExport()}
            data-testid="apply-filters-button"
            disabled={isMetadataLoading}
          >
            Export
          </Button>
        </ModalFooter>
      </ModalDisplay>
    </>
  );
};
