import { styled, Box, Skeleton } from '@mui/material';

const StyledSkeletonBar = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  height: 105,
  backgroundColor: themeColors.surfaceEmpty,
}));

export const DrawerTimelineSkeleton = () => (
  <Box display="flex" flexDirection="column" gap={2}>
    <StyledSkeletonBar animation="wave" />
    <StyledSkeletonBar animation="wave" />
    <StyledSkeletonBar animation="wave" />
  </Box>
);
