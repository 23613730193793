import { Box, CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="indeterminate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{ fontSize: '10px' }}
          variant="caption"
          component="div"
          color="text.secondary"
        >
          {Math.round(props.value)}
        </Typography>
      </Box>
    </Box>
  );
}
