import { useContext, useEffect, useState } from 'react';
import { useEntityDrawerMetadata } from './useEntityDrawerData';
import { FieldContext } from '../../../../application/appViews/field/fieldContext';
import { AppContext } from '../../../../application/appContext';

export const useEntityDrawerContext = (entityType, entityId) => {
  const { projectList } = useContext(AppContext);
  const { project, maptualListMetadata, isMaptualListsLoading } =
    useContext(FieldContext);

  const projectId = project?.metadata?.projectId;
  const snowflakeMaptualListId = maptualListMetadata?.sfMaptualListId;
  const { productLineId, marketId } =
    projectList?.find((proj) => proj.projectId === projectId) || {};

  // ============================ METADATA ============================ //
  const [currentEntityType, setCurrentEntityType] = useState();
  const [currentEntityId, setCurrentEntityId] = useState();

  const [isMetadataLoading, setIsMetadataLoading] = useState(true);

  const {
    data: metadata,
    isFetching: isMetadataFetching,
    isError: isMetadataError,
    error: metadataError,
  } = useEntityDrawerMetadata({
    productLineId,
    marketId,
    projectId,
    regionId: snowflakeMaptualListId,
    entityType,
    entityId,
  });

  useEffect(() => {
    if (isMaptualListsLoading && isMetadataFetching) {
      setIsMetadataLoading(true);
    }

    const newMetadataFetchingStatus =
      isMetadataFetching !== undefined &&
      isMetadataFetching !== isMetadataLoading;

    if (!isMaptualListsLoading && newMetadataFetchingStatus) {
      setIsMetadataLoading(isMetadataFetching);
    }
  }, [isMetadataFetching, isMaptualListsLoading]);

  useEffect(() => {
    if (entityType !== undefined && entityType !== currentEntityType) {
      setCurrentEntityType(entityType);
    }
  }, [entityType]);

  useEffect(() => {
    if (entityId !== undefined && entityId !== currentEntityId) {
      setCurrentEntityId(entityId);
    }
  }, [entityId]);

  // ============================ RETURN ============================ //

  return {
    isMetadataLoading,
    isMetadataError,
    metadataError,
    metadata,
    entityType: currentEntityType,
    entityId: currentEntityId,
    marketId,
    productLineId,
    projectId,
    regionId: snowflakeMaptualListId,
  };
};
