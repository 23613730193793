import { Chip, FormGroup, Typography, styled } from "@mui/material";
import { FilterSection } from "./filterSidebar";
import { useState } from "react";
import { Filters } from "../mapView";
import { Check, HelpOutline } from "@mui/icons-material";
import { NeverSeenFilter } from "./neverSeenFilter";

const SectionTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryMaptualListFilteringColor,
}));

const SegmentFilterDescription = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.primaryMaptualListFilteringColor,
    marginBottom: "32px",
    marginTop: "4px",
  })
);

const ChipArray = styled(FormGroup)(() => ({
  display: "flex",
  gridGap: "10px",
  flexDirection: "row",
  marginBottom: 16,
}));

const LastContactedNote = styled("div")(({ theme: { themeColors } }) => ({
  display: "flex",
  gap: "7px",
  alignItems: "flex-start",
  color: themeColors.primaryMaptualListFilteringColor,
  marginTop: 16,
  ".MuiSvgIcon-root": {
    color: themeColors.textButtonColor,
    width: "13.33px",
    height: "13.33px",
  },
}));

export const StyledPrimaryChip = styled(Chip)(
  ({ theme: { themeColors }, selected }) => ({
    "&.MuiChip-colorPrimary": {
      backgroundColor: themeColors.chipChoiceActiveSurface,
    },
    backgroundColor: selected
      ? themeColors.chipChoiceActiveSurface
      : themeColors.chipChoiceDefaultSurface,
    color: selected
      ? themeColors.chipChoiceActiveContent
      : themeColors.chipChoiceDefaultContent,
    borderColor: selected
      ? themeColors.chipChoiceActiveBorder
      : themeColors.chipChoiceDefaultBorder,
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "22px",
    "&:hover": {
      opacity: 1,
      backgroundColor: themeColors.chipChoiceHoverSurface,
      color: themeColors.chipChoiceHoverContent,
      borderColor: themeColors.chipChoiceHoverBorder,
    },
  })
);

export const lastContactedIds = {
  OFF: null,
  THIRTY_DAYS: 30,
  SIXTY_DAYS: 60,
  NINETY_DAYS: 90,
};

const lastContactedFilterOptions = [
  { label: "Off", id: lastContactedIds.OFF },
  { label: "30 days", id: lastContactedIds.THIRTY_DAYS },
  { label: "60 days", id: lastContactedIds.SIXTY_DAYS },
  { label: "90 days", id: lastContactedIds.NINETY_DAYS },
];

export const NotContactedFilter = ({
  filters,
  setFilters,
}: {
  filters: Filters;
  setFilters: (arg0: Filters) => void;
}) => {
  const [notContactedFilter, setNotContactedFilter] = useState(
    filters.notContactedFilter
  );

  const lastContactedChipIsSelected = (option) =>
    notContactedFilter === option.id || (!notContactedFilter && !option.id);

  const updateFilters = (newFilter) => {
    setNotContactedFilter(newFilter);
    setFilters({
      ...filters,
      notContactedFilter: newFilter,
    });
  };

  return (
    <FilterSection>
      <SectionTitle variant="h4">Not Contacted</SectionTitle>
      <SegmentFilterDescription variant="body2">
        HCPs you have not contacted in the last...
      </SegmentFilterDescription>
      <ChipArray>
        {lastContactedFilterOptions.map((option) => (
          <StyledPrimaryChip
            data-testid="test-filter-last-n-days"
            key={option.id}
            label={option.label}
            variant={
              lastContactedChipIsSelected(option) ? "primary" : "outlined"
            }
            color={lastContactedChipIsSelected(option) ? "primary" : "default"}
            icon={lastContactedChipIsSelected(option) ? <Check /> : null}
            onClick={() => updateFilters(option.id)}
          />
        ))}
      </ChipArray>
      <NeverSeenFilter filters={filters} setFilters={setFilters} />
      <LastContactedNote>
        <HelpOutline />
        <Typography variant="body2" sx={{ textAlign: "left" }}>
          Last contacted dates can lag because you don't have real-time data
          enabled
        </Typography>
      </LastContactedNote>
    </FilterSection>
  );
};
