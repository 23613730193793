import { Box, Typography } from '@mui/material';

export const ModalSectionHeader = ({ title, subtitle, ...rest }) => (
  <Box {...rest}>
    <Typography variant="h5">{title}</Typography>
    <Typography variant="body1" color={(theme) => theme.themeColors.neutral60}>
      {subtitle}
    </Typography>
  </Box>
);
