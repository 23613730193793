import { useContext } from 'react';
import { styled, Select, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { TerritoryOverviewContext } from '../territoryOverviewContext';
import { getComparisonTimeFrames } from '../utils';

const TimeFrameSelect = styled(Select)(({ theme: { themeColors } }) => ({
  width: 304,
  backgroundColor: themeColors.buttonBackgroundColor,
  color: themeColors.menuTextColor,
  textAlign: 'left',
  '.MuiSelect-select': {
    padding: '8px 12px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: themeColors.buttonBorderColor,
  },
  svg: {
    color: themeColors.buttonContentColor,
  },
}));

export const ComparisonTimeFrameSelector = () => {
  const {
    selectedTimeframeID,
    comparisonTimeFrameId,
    setComparisonTimeFrameId,
  } = useContext(TerritoryOverviewContext);

  const handleTimeFrameChange = (comparisonTimeFrameId) => {
    setComparisonTimeFrameId(comparisonTimeFrameId);
  };

  const comparisonTimeFrames = getComparisonTimeFrames(selectedTimeframeID);

  return (
    <TimeFrameSelect
      data-testid="time-frame-selector"
      key={comparisonTimeFrameId}
      value={comparisonTimeFrameId}
      IconComponent={KeyboardArrowDownIcon}
      disabled={comparisonTimeFrames.length === 1}
    >
      {comparisonTimeFrames.map((comparisonTimeFrame) => (
        <MenuItem
          key={comparisonTimeFrame.id}
          value={comparisonTimeFrame.id}
          onClick={() => handleTimeFrameChange(comparisonTimeFrame.id)}
        >
          {comparisonTimeFrame.label}
        </MenuItem>
      ))}
    </TimeFrameSelect>
  );
};
