/* eslint-disable no-restricted-syntax */

import CircularProgress from '@mui/material/CircularProgress';
import BarChart from './barchart';
import { useCurationReports } from './hooks/useCurationReports';

function PowerscoreChart({
  title,
  projectId,
  regionId,
  graphType,
  classGraphContent = '',
  classLoading = '',
}) {
  const { isLoading, data } = useCurationReports({
    projectId,
    regionId,
    graphType,
    report: 'powerscore',
    aggregationMethod: 'mean',
  });

  return (
    <div className={classGraphContent}>
      {!isLoading && data?.data && (
        <BarChart
          title={title}
          series={formatSeries(data.data)}
          labels={formatLabels(data.data)}
        />
      )}
      {isLoading && (
        <div className={classLoading}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

const formatSeries = (data) => {
  const hcpCountData = { data: data.map((item) => item.hcpCount) };
  return [hcpCountData];
};
const formatLabels = (data) =>
  data.map((item) => `${Math.round(item.powerscore)}`);

export default PowerscoreChart;
