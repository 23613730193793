import { styled } from '@mui/material';
import { CrmOverviewCard } from './cards/CrmOverviewCard';
import { CircularProgressProvider } from './cards/crmProgressCircle/circularProgressProvider';
import { CrmPsCard } from './cards/CrmPsCard';

const StyledCardRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  marginBottom: '32px',
  gap: '16px',
}));

export const CardRow = ({ objectiveId, cadence, timestamp }) => (
  <StyledCardRow>
    <CrmOverviewCard
      objectiveId={objectiveId}
      cadence={cadence}
      timestamp={timestamp}
    />
    <CircularProgressProvider
      objectiveId={objectiveId}
      cadence={cadence}
      timestamp={timestamp}
    />
    <CrmPsCard
      curated
      objectiveId={objectiveId}
      cadence={cadence}
      timestamp={timestamp}
    />
    <CrmPsCard
      curated={false}
      objectiveId={objectiveId}
      cadence={cadence}
      timestamp={timestamp}
    />
  </StyledCardRow>
);
