import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';
import { Box, Popover, styled } from '@mui/material';

const StyledIconButton = styled(IconButton)(() => ({
  width: 13,
  height: 13,
}));

const StyledPopover = styled(Popover)(({ theme: { themeColors } }) => ({
  marginTop: 4,

  '& .MuiPopover-paper': {
    width: '18rem',
    backgroundColor: themeColors.popoverBackground,
    borderColor: themeColors.borderPrimaryColor,
  },
}));

const StyledInfoIcon = styled(InfoOutlinedIcon, {
  shouldForwardProp: (prop) => prop !== 'iconColor',
})(({ theme: { themeColors }, iconColor }) => ({
  color: iconColor || themeColors.neutral70,
  width: 12.5,
  height: 12.5,
}));

const PopoverHeader = styled(Box)(({ theme: { themeColors } }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  color: themeColors.secondaryTextColor,
}));

const PopoverBody = styled(Box)(({ theme: { themeColors } }) => ({
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  color: themeColors.tertiaryColor,
}));

const StyledList = styled('ul')(() => ({
  marginBottom: 0,
  textTransform: 'capitalize',
}));

export const TooltipPopover = ({
  title,
  description,
  listData,
  onClick = () => {},
  iconColor = null,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopoverOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);

    onClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledIconButton data-testid="header-tooltip" onClick={handleClick}>
        <StyledInfoIcon iconColor={iconColor} />
      </StyledIconButton>
      <StyledPopover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box display="flex" flexDirection="column" padding={2} gap={1}>
          <PopoverHeader>
            <Typography variant="title3">{title}</Typography>
          </PopoverHeader>
          <PopoverBody data-testid="tooltip-popover-content">
            <Typography variant="body2">{description}</Typography>
            {listData?.length > 0 && (
              <StyledList>
                {listData?.map((listItem, index) => (
                  <li key={`${listItem}${index}`}>
                    <Typography variant="body2">{listItem}</Typography>
                  </li>
                ))}
              </StyledList>
            )}
          </PopoverBody>
        </Box>
      </StyledPopover>
    </>
  );
};
