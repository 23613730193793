import React, { useEffect, useState } from "react";
import { FilterSection } from "./filterSidebar";
import { Filters } from "../mapView";
import { PowerscoreSlider } from "@odaia/ui/src/components/powerscoreSlider";
import { Typography, styled } from "@mui/material";

const SectionTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryMaptualListFilteringColor,
}));

const FilterDescription = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryMaptualListFilteringColor,
  marginBottom: "32px",
  marginTop: "4px",
}));

export const PowerscoreFilter = ({
  filters,
  setFilters,
}: {
  filters: Filters;
  setFilters: (arg0: Filters) => void;
}) => {
  const [powerscoreFilter, setPowerscoreFilter] = useState(
    filters.powerscoreFilter
  );

  useEffect(() => {
    if (
      powerscoreFilter.min !== filters.powerscoreFilter.min ||
      powerscoreFilter.max !== filters.powerscoreFilter.max
    ) {
      setPowerscoreFilter(powerscoreFilter);
    }
  }, [filters]);

  return (
    <FilterSection>
      <SectionTitle variant="h4">PowerScore</SectionTitle>
      <FilterDescription variant="body2">
        Show HCPs within the PowerScore Range
      </FilterDescription>
      <PowerscoreSlider
        defaultValue={{
          max: powerscoreFilter.max,
          min: powerscoreFilter.min,
        }}
        updateValue={(value) => {
          setFilters({ ...filters, powerscoreFilter: value });
        }}
      />
    </FilterSection>
  );
};
