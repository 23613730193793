import { METRIC_DATA_TYPE, METRIC_UNIT, SHORT_FORM_CADENCE } from './constants';
import { MetricData } from './types';

export const hasMetricDataType = (
  metricDataTypes: MetricData[],
  type: METRIC_DATA_TYPE
): boolean => {
  const matchingDataType = metricDataTypes?.filter(
    (dataType) => dataType.metricDataType === type
  );

  return matchingDataType?.length > 0 && matchingDataType[0].metrics.length > 0;
};

export const formatTimestampLabel = (
  timestamp: string,
  cadence: SHORT_FORM_CADENCE
): string => {
  if (cadence === SHORT_FORM_CADENCE.WK) {
    return timestamp.split(',')[0];
  }

  if (
    cadence === SHORT_FORM_CADENCE.MTH ||
    cadence === SHORT_FORM_CADENCE.R3M ||
    cadence === SHORT_FORM_CADENCE.QTR
  ) {
    return timestamp.split(' ')[0];
  }

  return timestamp;
};

export const isPredictionCadence = (cadence, metricDataType = null) =>
  cadence === SHORT_FORM_CADENCE.MAT ||
  (metricDataType === METRIC_DATA_TYPE.SHARE &&
    cadence === SHORT_FORM_CADENCE.YR);

export const formatYAxisValue = (
  value: number,
  unit: METRIC_UNIT
): string | number => {
  if (unit === METRIC_UNIT.RATE) {
    return `${Math.round(value)}%`;
  }
  return value;
};
