export const CHART_LINE_DASH_PATTERN = [3, 5];
export const LINE_CHART_TOP_GAP = 1.1;
export const EM_DASH = '—';

export enum CADENCE_LABEL {
  wk = 'Weekly',
  mth = 'Monthly',
  r3m = 'R3M',
  qtr = 'Quarterly',
  yr = 'Annually',
  mat = 'MAT',
}

export enum SHORT_FORM_CADENCE {
  WK = 'wk',
  MTH = 'mth',
  R3M = 'r3m',
  QTR = 'qtr',
  YR = 'yr',
  MAT = 'mat',
}

export const CADENCES_ORDER = {
  wk: 1,
  mth: 2,
  r3m: 3,
  qtr: 4,
  yr: 5,
  mat: 6,
};

export enum TREND_STATUS {
  INCREASING = 2,
  NO_CHANGE = 1,
  DECREASING = 0,
  VALID_METRIC_DATA_STATUS = 0,
  CANNOT_PREDICT = -1,
  INCREASING_OUT_OF_RANGE = -2,
  DECREASING_OUT_OF_RANGE = -3,
}

export enum METRIC_DATA_TYPE {
  VOLUME = 'volume',
  SHARE = 'share',
}

export enum BASKET_TYPE {
  TARGET = 'target',
  COMPETITOR = 'competitor',
}

export enum METRIC_UNIT {
  UNIT = 'unit',
  DOLLAR = 'dollar',
  RX = 'rx',
  RATE = 'rate',
  PATIENT_COUNT = 'patient_count',
}
