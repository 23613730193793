import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import {
  getColors,
  getIcon,
  transformValue,
} from '../../../components/fieldView/trendValues';

const TrendValueContainer = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  justifyContent: 'left',
  color: themeColors.primaryTextColor,
}));

const StyledTrendValue = styled(Typography)(({ type, status, value }) => ({
  lineHeight: '16.8px',
  color: getColors({ type, status, value }).primary,
}));

export const TrendValue = ({
  type,
  status,
  value,
  useZero = false,
  collapsed = false,
}) =>
  (value !== undefined || status) && type ? (
    <TrendValueContainer data-testid="trend-value-container">
      {getIcon({ type, status, value, useZero, collapsed })}
      {collapsed ? null : (
        <StyledTrendValue
          type={type}
          status={status}
          value={value}
          noWrap
          align="left"
          variant="body2"
        >
          {transformValue({ type, status, useZero, value })}
        </StyledTrendValue>
      )}
    </TrendValueContainer>
  ) : null;
