import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { styled, Popover } from '@mui/material';
import UserMenuOptions from './userMenuOptions';
import { selectAppThunk } from '../../../slices/app';
import { AppContext } from '../../../containers/application/appContext';

const StyledPopover = styled(Popover)(({ compact }) => ({
  '& .MuiPopover-paper': {
    width: compact ? '180px' : '343px',
    border: 'none',
  },
}));

export const UserMenuPopover = ({ anchorEl, setAnchorEl, compact = false }) => {
  const { profile } = useContext(AppContext);
  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'profile' : undefined;

  const dispatch = useDispatch();
  const selectApp = (app) => dispatch(selectAppThunk(app));

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledPopover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: -9,
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      compact={compact ? 1 : 0}
    >
      <UserMenuOptions profile={profile} selectApp={selectApp} />
    </StyledPopover>
  );
};
