import { useQuery } from 'react-query';
import { getCurrentUserCreatedLists } from '../../request/requests';

export const useUserCreatedLists = ({ userId }) => {
  const fetchUserCreatedLists = async () => {
    if (!userId) {
      return null;
    }
    const response = await getCurrentUserCreatedLists(userId);

    return response?.data;
  };

  const {
    isLoading: isCreatedListsLoading,
    isError: isCreatedListsError,
    data: userCreatedLists,
    error: createdListsError,
  } = useQuery({
    queryKey: ['user-created-lists', userId],
    queryFn: fetchUserCreatedLists,
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error('[ERROR] fetchUserCreatedLists', err);
    },
  });

  return {
    isCreatedListsLoading,
    userCreatedLists,
    isCreatedListsError,
    createdListsError,
  };
};
