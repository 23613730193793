import { styled, Skeleton } from '@mui/material';

const StyledSkeletonBar = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  marginTop: 10,
  height: 250,
  backgroundColor: themeColors.surfaceEmpty,
}));

export const DrawerChartSkeleton = () => <StyledSkeletonBar animation="wave" />;
