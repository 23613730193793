import {
  styled,
  Box,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const StyledButton = styled(Button)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
  backgroundColor: themeColors.closeButtonBackgroundColor,
  border: `1px solid ${themeColors.buttonBorderColor}`,
}));

export const StyledModalBody = styled('div')(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
  height: 356,
  overflow: 'auto',
  padding: 20,
}));

export const StyledTextField = styled(TextField)(
  ({ isError = false, theme: { themeColors } }) => ({
    width: '100%',
    '& label.Mui-focused': {
      color: isError ? themeColors.danger60 : themeColors.neutral60,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `1px solid ${
          isError ? themeColors.danger60 : themeColors.neutral60
        }`,
      },
    },
    '& input::placeholder, & textarea::placeholder': {
      opacity: 1,
    },
    '.MuiFormHelperText-root.Mui-error': {
      color: themeColors.danger60,
    },
    '.MuiFormHelperText-root ': {
      color: themeColors.neutral60,
    },
  })
);

export const LoadingIcon = styled(CircularProgress)(
  ({ theme: { themeColors } }) => ({
    display: 'block',
    marginLeft: 7,
    color: themeColors.primaryTextColor,
  })
);

export const FloatingButtonWrapper = styled('div')({
  display: 'flex',
  gap: 8,
  position: 'sticky',
  top: 24,
  float: 'right',
  zIndex: 2,
  '.MuiButton-outlined': {
    lineHeight: 1.75,
    padding: '7px 16px',
  },
});

export const ConfigSectionBody = styled('div')({
  height: `calc(100% - 70px)`,
});

export const CenteredContentContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledBackButton = styled(ArrowBackIcon)({
  '&:hover': {
    cursor: 'pointer',
  },
});
