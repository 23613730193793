import { useContext } from 'react';
import { Box, Typography, Button, styled } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { useQueryClient } from 'react-query';
import { EntityOverviewContext } from '../data/entityOverviewContext';
import { ERROR_TYPES_ENUM } from '../constants';

const ErrorContainer = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  padding: '48px 56px',
  flexDirection: 'column',
  gap: 16,
  borderRadius: 4,
  background: themeColors.surfaceEmpty,
  alignItems: 'center',
}));

const ErrorTextContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
});

const ErrorText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.emptyText,
  whiteSpace: 'pre-line',
}));

const RetryButton = styled(Button)({
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  padding: '8px 12px',
});

const RetryIcon = styled(CachedIcon)(({ theme: { themeColors } }) => ({
  width: 20,
  height: 20,
  '&.MuiSvgIcon-root': {
    color: themeColors.buttonContentColor,
  },
}));

const errorHeader = (errorType) => {
  switch (errorType) {
    case ERROR_TYPES_ENUM.NON_TERRITORY:
    case ERROR_TYPES_ENUM.NO_DATA:
      return 'Report not available';
    case ERROR_TYPES_ENUM.RETRY:
      return 'No content loaded';
    default:
      return '';
  }
};

const errorBody = (errorType) => {
  switch (errorType) {
    case ERROR_TYPES_ENUM.NON_TERRITORY:
      return 'We currently only provide Account overview reports at the territory level.\n Use the menu to navigate to a territory to view Account overview reports.';
    case ERROR_TYPES_ENUM.RETRY:
      return 'Sorry, we are having trouble loading the requested data. Please try again later.';
    case ERROR_TYPES_ENUM.NO_DATA:
      return 'We currently do not have enough data to generate this table.';
    case ERROR_TYPES_ENUM.NO_FILTER_DATA:
    case ERROR_TYPES_ENUM.NO_SEARCH_DATA:
      return 'No accounts match the current search or filters. Try removing a filter or a different search term.';
    default:
      return '';
  }
};

export const EntityError = () => {
  const queryClient = useQueryClient();
  const { errorType } = useContext(EntityOverviewContext);

  const handleRetry = () => {
    queryClient.refetchQueries('entity-overview-metadata');
    queryClient.refetchQueries('entity-overview-data');
  };

  return (
    <ErrorContainer>
      <ErrorTextContainer>
        <ErrorText variant="title3">{errorHeader(errorType)}</ErrorText>
        <ErrorText variant="subtitle1">{errorBody(errorType)}</ErrorText>
      </ErrorTextContainer>
      {errorType === ERROR_TYPES_ENUM.RETRY && (
        <RetryButton variant="outlined" onClick={handleRetry}>
          <RetryIcon />
          Retry
        </RetryButton>
      )}
    </ErrorContainer>
  );
};
