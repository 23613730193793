import { useQuery } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import { MarketsView } from './marketsView';
import { getMarkets } from '../../request/newProjectRequests';
import { ConfigSectionTitle } from './configViewTitle';
import { DEFAULT_TABS } from './configTabs';
import {
  CenteredContentContainer,
  ConfigSectionBody,
} from './styledComponents';

export function MarketsViewProvider() {
  const { data: markets = [], isLoading } = useQuery('markets', async () => {
    const response = await getMarkets();
    return JSON.parse(response.data);
  });

  return (
    <>
      <ConfigSectionTitle currentTabTitle={DEFAULT_TABS.MARKETS} />
      <ConfigSectionBody>
        {isLoading ? (
          <CenteredContentContainer>
            <CircularProgress />
          </CenteredContentContainer>
        ) : (
          <MarketsView markets={markets} />
        )}
      </ConfigSectionBody>
    </>
  );
}
