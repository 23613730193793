/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-console */
import axios from 'axios';
import { baseAlbInstance, baseInstance, maptualApiInstance } from './config';
import { userManagementApi } from './maptualApiClient';

function uploadFile(s3Data, content) {
  const formData = new FormData();

  // All the PreSigned URL fields to FormData as required by Amazon S3
  // TODO: refactor this loop into a more eslint pleasing syntax
  for (const key in s3Data.fields) {
    formData.append(key, s3Data.fields[key]);
  }

  formData.append('file', content);

  return axios({
    method: 'post',
    url: s3Data.url,
    data: formData,
    timeout: 300000,
    config: {
      headers: { 'Content-Type': 'multipart/form-data' },
      cache: false,
      processData: false,
      timeout: 300000,
    },
  });
}

function getLLM({ projectId, userGroup }) {
  return baseInstance({
    method: 'get',
    url: `alb2apigw-proxy-lm/llm_router?projectId=${projectId}&userGroup=${userGroup}`,
    timeout: 300000,
    config: {
      cache: false,
      processData: false,
      timeout: 300000,
    },
  });
}

async function getPresignedS3UrlandUpload(content, fileName, datasetName) {
  const response = await baseInstance.get(
    'alb2apigw-proxy-lm/datapackageUploadAPI',
    {
      // datapackage
      params: {
        filename: fileName,
        datasetName,
      },
    }
  );

  const res = JSON.parse(response.data);
  return uploadFile(res, content);
}

function getClearCookie() {
  return baseAlbInstance
    .get('logout')
    .then((response) => response)
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

function getLogin() {
  return baseInstance
    .get('login', { params: { validateLogin: true } })
    .then((response) => {
      console.log(response.data);

      if (response.status === 201) {
        console.log('Login successful');
        return;
      }
      throw new Error('Most likely hitting login page return');
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        console.log(error.response.data);
        console.log({
          headers: error.response.headers,
          status: error.response.status,
        });
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        console.log(error.message);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      throw error;
    });
}

async function getEncryptionKeyFromKMS() {
  const res = await baseInstance.get('alb2apigw-proxy-lm/encryptionkey', {
    contentType: 'application/json; charset=utf-8',
  });
  return JSON.parse(res.data);
}

function getCurrentUser() {
  return userManagementApi.getCurrentUser();
}

function getRequiredAction() {
  return baseAlbInstance.get('/users/current-user/required-action', {
    timeout: 15000,
  });
}

function refreshAccessToken() {
  return baseInstance.get('login', { params: { validateLogin: true } });
}

export function putCurrentUserPreference(requestBody) {
  return baseAlbInstance.put('users/current-user/preference', requestBody, {
    timeout: 30000,
  });
}

export function getCurrentUserCreatedLists(userId) {
  return maptualApiInstance.get(
    `/usermanagement/user-created-lists/${userId}`,
    { timeout: 15000 }
  );
}

export function putCurrentUserCreatedLists(newUserCreatedLists, userId) {
  const excludedKeys = ['starred', 'longTermLeave', 'noSee'];
  const lists = {};

  Object.keys(newUserCreatedLists).forEach((key) => {
    if (!excludedKeys.includes(key)) {
      lists[key] = newUserCreatedLists[key];
    }
  });

  return maptualApiInstance.put(
    `/usermanagement/user-created-lists/${userId}`,
    {
      lists,
    },
    {
      timeout: 15000,
    }
  );
}

export function putUserPreference(newPreference) {
  return putCurrentUserPreference(newPreference)
    .then((response) => {
      let responseJson = {};
      try {
        responseJson = JSON.parse(response.data);
        return responseJson;
      } catch (e) {
        console.error('save Preference Error', e.message);
      }
      return responseJson;
    })
    .catch((error) => {
      console.error('save Preference Error', error.message);
    });
}

function createFieldUserPreference({
  userProfile,
  projectMetadata,
  maptualListMetadata,
  entityMetadata,
  segmentSelection,
  newRoute,
}) {
  if (userProfile?.userId) {
    const newPreference = userProfile.preference
      ? { ...userProfile.preference }
      : {};

    if (
      newPreference.lastProjectMetadata?.projectId !==
      projectMetadata?.projectId
    ) {
      newPreference.lastProjectMetadata = { ...projectMetadata };
      newPreference.lastMaptualListMetadata = null;
      newPreference.lastEntityMetadata = null;
    }

    if (
      maptualListMetadata?.maptualListId &&
      newPreference.lastMaptualListMetadata?.maptualListId !==
        maptualListMetadata?.maptualListId
    ) {
      newPreference.lastMaptualListMetadata = { ...maptualListMetadata };
      newPreference.lastEntityMetadata = null;
    }

    if (
      newPreference.lastEntityMetadata?.entityId !== entityMetadata?.entityId
    ) {
      newPreference.lastEntityMetadata = { ...entityMetadata };
    }

    if (newPreference?.lastSegmentSelection !== segmentSelection) {
      newPreference.lastSegmentSelection = segmentSelection;
    }

    if (newRoute && newPreference?.route !== newRoute) {
      newPreference.route = newRoute;
    }

    return newPreference;
  }
  return {};
}

export {
  getCurrentUser,
  getLogin,
  getClearCookie,
  getEncryptionKeyFromKMS,
  getPresignedS3UrlandUpload,
  getRequiredAction,
  refreshAccessToken,
  getLLM,
  createFieldUserPreference,
};
