import { styled, Typography, Box } from '@mui/material';
import { InsightsCard } from './insightsCard/insightsCard';
import { getIconComp } from '../channelIconProvider';

const FlexWrapper = styled(Box)(() => ({
  display: 'flex',
}));
const Channel = styled(FlexWrapper)(({ theme: { themeColors } }) => ({
  gridGap: 10,
  alignItems: 'center',
  svg: {
    height: 16,
    width: 16,
    color: themeColors.textButtonColor,
  },
  color: themeColors.secondaryTextColor,
}));
const ErrorChannel = styled(Channel)(({ theme: { themeColors } }) => ({
  p: {
    color: themeColors.primaryMaptualListFilteringColor,
  },
}));
const ChannelRow = styled(FlexWrapper)(() => ({
  gridGap: 16,
  flexWrap: 'wrap',
}));

export const InsightsChannels = ({ maptualTopChannels, isLoading }) => (
  <InsightsCard title="Top Influential Channels" minimal isLoading={isLoading}>
    <ChannelRow>
      {maptualTopChannels &&
        maptualTopChannels.map((channel) => (
          <Channel key={channel.channelName}>
            {getIconComp(channel?.channelName?.toLowerCase() || '', {}, true)}
            <Typography variant="body2">{channel.channelName}</Typography>
          </Channel>
        ))}
      {!maptualTopChannels ||
        (maptualTopChannels.length === 0 && (
          <ErrorChannel>
            <Typography variant="body2">
              Not enough data available to generate channels for this HCP.
            </Typography>
          </ErrorChannel>
        ))}
    </ChannelRow>
  </InsightsCard>
);
