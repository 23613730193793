import { useContext } from 'react';
import { Tab, Tabs, styled } from '@mui/material';
import { trackEntityOverviewDrilldownGraphSelector } from '../../../../trackers/mixpanel';
import { EntityDrawerContext } from './data/entityDrawerContext';

const StyledTabs = styled(Tabs)({
  display: 'flex',
  alignItems: 'center',
  minHeight: 40,
  '.MuiTabs-indicator': {
    display: 'none',
  },
  '.MuiTabs-flexContainer': {
    height: 40,
  },
});

const StyledTab = styled(Tab)(({ theme: { themeColors } }) => ({
  minHeight: 'auto',
  padding: '8px 10px',
  backgroundColor: themeColors.buttonBackgroundColor,
  color: themeColors.buttonContentColor,
  border: `1px solid ${themeColors.buttonActiveBorderColor}`,
  marginRight: -1,
  textTransform: 'none',
  fontSize: 16,
  fontWeight: 400,
  '&.Mui-selected': {
    color: themeColors.buttonActiveContentColor,
    backgroundColor: themeColors.buttonSecondaryBackgroundColour,
  },
  '.MuiTab-iconWrapper': {
    margin: '0 6px 0 0',
  },
  '&:first-of-type': {
    borderRadius: '4px 0px 0px 4px',
  },
  '&:last-of-type': {
    borderRadius: '0px 4px 4px 0px',
    marginRight: 0,
  },
}));

export const TabGroup = ({
  availableTabs,
  tab,
  setTab,
  sectionTitle,
  graphType,
}) => {
  const { entityType } = useContext(EntityDrawerContext);
  const onTabClick = (tabId, tabName) => {
    trackEntityOverviewDrilldownGraphSelector(
      tabName,
      entityType,
      sectionTitle,
      graphType
    );
    setTab(tabId);
  };

  return (
    availableTabs.length > 1 && (
      <StyledTabs value={tab || 0}>
        {availableTabs.map((availableTab) => {
          const { id, name } = availableTab;

          return (
            <StyledTab
              key={`${id}-key`}
              label={name}
              value={id}
              onClick={() => {
                onTabClick(id, name);
              }}
            />
          );
        })}
      </StyledTabs>
    )
  );
};
