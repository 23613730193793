import { useEffect, useState } from 'react';
import { Button, CircularProgress, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { isEqual } from 'lodash';
import { ModalDisplayCustomStyle } from '../newproject/styles/styledComponents';
import {
  ModalBody,
  ModalDisplay,
  ModalFooter,
  ModalHeader,
} from '../generic/modalDisplay';
import { TextFieldWithChipsInside } from './textFieldWithChipsInside';

const StyledButton = styled(Button)(({ theme: { themeColors } }) => ({
  '&:disabled': {
    backgroundColor: themeColors.paperColor,
  },
}));
const StyledModalBody = styled(ModalBody)({
  paddingTop: '24px',
  paddingBottom: '36px',
  gridGap: 24,
});

const SecondaryText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
}));

const TextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
});

const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

const LoadingIcon = styled(CircularProgress)(({ theme: { themeColors } }) => ({
  display: 'block',
  marginLeft: 7,
  color: themeColors.primaryTextColor,
}));

export function UserDomainModal({
  isOpen,
  closeForm,
  domains,
  onSaveDomains,
  domainsAreSaving,
}) {
  const [selectedDomains, setSelectedDomains] = useState(domains);
  useEffect(() => {
    setSelectedDomains(domains);
  }, [domains, isOpen]);
  const cannotSave = isEqual(selectedDomains, domains);
  return (
    <ModalDisplay isOpen={isOpen} style={ModalDisplayCustomStyle}>
      <ModalHeader onClose={closeForm}>Email Domains</ModalHeader>
      <StyledModalBody>
        <ContentWrapper>
          <TextWrapper>
            <Typography variant="h5">Permitted Domains</Typography>
            <SecondaryText variant="body1">
              Enter email domains that are permitted for this user group
            </SecondaryText>
          </TextWrapper>
          <TextFieldWithChipsInside
            selectedItems={selectedDomains}
            setSelectedItems={setSelectedDomains}
          />
        </ContentWrapper>
      </StyledModalBody>
      <ModalFooter>
        <Button onClick={closeForm} variant="outlined">
          Cancel
        </Button>
        <StyledButton
          disabled={cannotSave}
          onClick={() => {
            onSaveDomains(selectedDomains);
          }}
          variant="contained"
        >
          {domainsAreSaving ? (
            <>
              Saving
              <LoadingIcon size="11px" />
            </>
          ) : (
            'Save Changes'
          )}
        </StyledButton>
      </ModalFooter>
    </ModalDisplay>
  );
}
