/* eslint-disable no-nested-ternary */
import { useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { darkThemeColors } from '@odaia/ui/src/colorConfig';
import LinkController from '../../containers/links/linkController';

const useStyles = makeStyles((theme) => ({
  root: {},
  primaryText: {
    fontSize: 15,
    fontWeight: 600,
    color: darkThemeColors.tertiaryColor,
    lineHeight: 1.2,
    maxWidth: '100%',
  },
  primaryTextHighContrast: {
    fontSize: 15,
    fontWeight: 800,
    color: theme.palette.text.primary,
    lineHeight: 1.2,
  },
  primaryTextHyper: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: 1.2,
    color: darkThemeColors.tertiaryColor, // blueGrey[700],
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
      textDecoration: 'underline',
    },
  },
  primaryTextHyperHighContrast: {
    fontSize: 15,
    fontWeight: 800,
    lineHeight: 1.2,
    color: blue.A200,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
      textDecoration: 'underline',
    },
  },
  secondaryText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.2,
    color: darkThemeColors.tertiaryColor,
    maxWidth: '100%',
  },
  secondaryTextHighContrast: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.2,
    color: theme.palette.text.secondary,
    maxWidth: '100%',
  },
  secondaryTextHyper: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.2,
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
      textDecoration: 'underline',
    },
  },
  secondaryTextHyperHighContrast: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.2,
    color: theme.palette.primary.light,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
      textDecoration: 'underline',
    },
  },
  tertiaryText: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 1.2,
    color: darkThemeColors.tertiaryColor,
  },
  tertiaryTextHighContrast: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 1.2,
    color: darkThemeColors.tertiaryColor,
  },
  tertiaryTextHyper: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 1.2,
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
      textDecoration: 'underline',
    },
  },
  tertiaryTextHyperHighContrast: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 1.2,
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
      textDecoration: 'underline',
    },
  },
}));

export default function BarGraphLabelAtom({
  text,
  type,
  customStyle = null,
  match,
  highContrast = false,
}) {
  const classes = useStyles();

  const controllerRef = useRef(null);
  const controllerClick = () => {
    if (controllerRef && controllerRef.current) controllerRef.current.click();
  };

  return text && type ? (
    typeof text === 'string' ? (
      <Typography
        variant="h5"
        align="left"
        className={classes[highContrast ? `${type}HighContrast` : type]}
        noWrap
        style={customStyle || {}}
      >
        {text}
      </Typography>
    ) : (
      <>
        <Typography
          variant="h5"
          align="left"
          className={
            classes[highContrast ? `${type}HyperHighContrast` : `${type}Hyper`]
          }
          noWrap
          style={customStyle || {}}
          onClick={controllerClick}
        >
          {text.primaryText ? text.primaryText.toUpperCase() : ''}
        </Typography>
        <LinkController
          ref={controllerRef}
          type={type}
          {...text}
          match={match}
        />
      </>
    )
  ) : null;
}
