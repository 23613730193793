import { useQuery } from 'react-query';
import { EntityType } from '@odaia/clients/maptualAPI';
import {
  getEntitiesPrecallInsights,
  getEntityPrecallInsights,
} from '../../../../../../request/projectRequests';

export const useEntityPrecallInsights = ({
  maptualListMetadata,
  projectId,
  objectiveId,
  entityId = null,
}) =>
  useQuery({
    queryKey: [
      'map-entity-insights',
      maptualListMetadata.sfMaptualListId,
      objectiveId,
      entityId,
    ],
    queryFn: async () => {
      try {
        if (!entityId) {
          const response = await getEntitiesPrecallInsights({
            projectId,
            objectiveId,
            regionId: maptualListMetadata.sfMaptualListId,
            entityType: EntityType.Hcp,
          });
          return response.data;
        }

        const response = await getEntityPrecallInsights({
          entityId,
          projectId,
          objectiveId,
          regionId: maptualListMetadata.sfMaptualListId,
        });

        return response.data || [];
      } catch {
        return [];
      }
    },
    enabled:
      !!maptualListMetadata.maptualListId && !!objectiveId && !!projectId,
  });
