import { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { StyledTextField } from './styles/styledComponents';

import { BasketScoringWeightField } from './common/basketScoringWeightField';
import { AdminContext } from '../../containers/application/appViews/adminContext';

import { ObjectiveModalActionsContext } from './newProjectContext';

const StyledBasketNameField = styled(StyledTextField)(() => ({
  flex: 2,
}));

const FieldContainer = styled(`div`)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  gap: 24,
});

const BasketHeaderFields = ({ basketConfig, handleOnChange, setIsError }) => {
  const { isProjectBuilderDemoMode } = useContext(AdminContext);
  const { objectiveModalErrors, setObjectiveModalErrors } = useContext(
    ObjectiveModalActionsContext
  );

  const [basketScoringWeightFieldError, setBasketScoringWeightFieldError] =
    useState(false);
  const [basketNameError, setBasketNameError] = useState(false);

  useEffect(() => {
    setObjectiveModalErrors({
      ...objectiveModalErrors,
      invalidBasketScoringWeight: basketScoringWeightFieldError,
    });
  }, [basketScoringWeightFieldError]);

  useEffect(() => {
    if (basketConfig.products.length > 0 && !basketConfig.basketName) {
      setBasketNameError(validateBasketName(basketConfig.basketName));
    }
  }, [basketConfig]);

  const handleBasketNameChange = (newValue) => {
    const isError = validateBasketName(newValue);
    setBasketNameError(isError);
    setIsError(isError);
    handleOnChange({
      ...basketConfig,
      basketName: newValue,
    });
  };
  const validateBasketName = (newBasketName) => !newBasketName.trim().length;
  return (
    <div>
      <FieldContainer>
        <StyledBasketNameField
          label="Basket Name"
          focused
          isError={basketNameError}
          placeholder="Basket Name"
          value={basketConfig.basketName}
          error={basketNameError}
          helperText={basketNameError && 'Must add basket name'}
          onChange={(e) => {
            handleBasketNameChange(e.target.value);
          }}
        />

        {isProjectBuilderDemoMode ? null : (
          <BasketScoringWeightField
            basketConfig={basketConfig}
            handleOnChange={handleOnChange}
            setBasketScoringWeightError={setBasketScoringWeightFieldError}
            basketScoringWeightFieldError={basketScoringWeightFieldError}
            errorText="Value must be between 0-100"
            setIsError={setIsError}
          />
        )}
      </FieldContainer>
    </div>
  );
};

export default BasketHeaderFields;
