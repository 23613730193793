import { useContext, useEffect, useMemo, useState } from 'react';
import { Switch as MuiSwitch, Box, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  NewProjectContext,
  ObjectiveModalActionsContext,
} from '../newProjectContext';
import { ProductBasketDropdown } from './productBasketDropdown';
import { AdminContext } from '../../../containers/application/appViews/adminContext';

const defaultPrecursorDropdowns = [
  {
    id: 1,
    displayName: 'Precursor (1)',
  },
];

const StyledToggleWrapper = styled('div')({
  padding: '20px',
  gap: '24px',
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
});

const TextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

const StyledTypography = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
}));

export default function ObjectiveDropdownGroup({ preloadBaskets }) {
  const [activeDropdown, setActiveDropdown] = useState('');
  const [precursorDropdowns, setPrecursorDropdowns] = useState(
    defaultPrecursorDropdowns
  );
  const { objectiveType, nonScoringObjective, setNonScoringObjective } =
    useContext(NewProjectContext);
  const displayableBasketTypes = useMemo(
    () => objectiveType.params.products.map((product) => product.paramType),
    [objectiveType.params.products]
  );

  const nonPrecursorBaskets = useMemo(
    () =>
      objectiveType.params.products.filter(
        (product) => product.paramType !== 'PRECURSOR_PRODUCT'
      ),
    [objectiveType.params.products]
  );

  const { isProjectBuilderDemoMode } = useContext(AdminContext);
  const { deleteBasket } = useContext(ObjectiveModalActionsContext);
  const [expandContractDisabled, setExpandContractDisabled] = useState(false);
  const getLastPrecursorBasket = () => [...precursorDropdowns].pop();
  const newPrecursorBasket = () => {
    const newId = getLastPrecursorBasket().id + 1;
    return {
      id: newId,
      displayName: `Precursor (${newId})`,
    };
  };

  useEffect(() => {
    if (preloadBaskets.length > 0) {
      const preloadedPrecursorBaskets = preloadBaskets.filter(
        (basket) => basket.basketType === 'PRECURSOR_PRODUCT'
      );

      if (preloadedPrecursorBaskets.length > 0) {
        setPrecursorDropdowns(
          preloadedPrecursorBaskets.map((basket, i) => ({
            id: i + 1,
            uuid: basket.uuid,
            displayName: `Precursor (${i + 1})`,
          }))
        );
      } else setPrecursorDropdowns(defaultPrecursorDropdowns);
    }
  }, []);

  const activeDropdownOnClickHandler = (key) => () =>
    setActiveDropdown(key !== activeDropdown ? key : '');
  return (
    <Box>
      {!isProjectBuilderDemoMode && (
        <StyledToggleWrapper>
          <TextWrapper>
            <Typography variant="h5">Non-Scoring Objective</Typography>
            <StyledTypography variant="body1">
              Toggle on if this objective is for displaying metrics only
            </StyledTypography>
          </TextWrapper>
          <MuiSwitch
            checked={nonScoringObjective}
            onClick={() => {
              setNonScoringObjective(!nonScoringObjective);
            }}
          />
        </StyledToggleWrapper>
      )}
      {nonPrecursorBaskets.map((basketPreset, index) => {
        const key = index;
        return (
          <ProductBasketDropdown
            key={key}
            basketKey={key}
            title={basketPreset.productFriendlyName}
            basketType={basketPreset.paramType}
            basketText={basketPreset.paramText}
            activeDropdown={activeDropdown}
            activeDropdownToggle={activeDropdownOnClickHandler(key)}
            preloadData={preloadBaskets.find(
              (basket) => basket.basketType === basketPreset.paramType
            )}
            expandContractDisabled={expandContractDisabled}
            setExpandContractDisabled={setExpandContractDisabled}
          />
        );
      })}
      {displayableBasketTypes.includes('PRECURSOR_PRODUCT') &&
        precursorDropdowns.map((currentPrecursor, index) => {
          const key = `precursor${index}`;
          return (
            <ProductBasketDropdown
              key={key}
              basketKey={key}
              title={currentPrecursor.displayName}
              basketType="PRECURSOR_PRODUCT"
              basketText="Precursor"
              showActionButtons
              hasNewBasketButton={
                currentPrecursor.id === getLastPrecursorBasket().id
              }
              hasDeleteButton={
                currentPrecursor.id === getLastPrecursorBasket().id
              }
              onPrecursorAdd={(event) => {
                event.stopPropagation();
                setPrecursorDropdowns([
                  ...precursorDropdowns,
                  newPrecursorBasket(),
                ]);
              }}
              onPrecursorDelete={(event) => {
                event.stopPropagation();
                setPrecursorDropdowns(
                  precursorDropdowns.filter(
                    (basket) => basket.id !== currentPrecursor.id
                  )
                );
                deleteBasket(key, currentPrecursor?.uuid);
              }}
              activeDropdown={activeDropdown}
              activeDropdownToggle={activeDropdownOnClickHandler(key)}
              preloadData={preloadBaskets.find(
                (basket) => basket.uuid === currentPrecursor.uuid
              )}
              expandContractDisabled={expandContractDisabled}
              setExpandContractDisabled={setExpandContractDisabled}
            />
          );
        })}
    </Box>
  );
}
