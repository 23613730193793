import React, { useEffect, useState } from "react";
import { FilterSection } from "./filterSidebar";
import { MultiSelectFiltering, filterOption } from "./multiSelectFiltering";
import { Filters } from "../mapView";

const getSelectedSpecialties = (filters: Filters) => {
  const selectedSpecialties = {};
  filters.specialtyFilter.forEach((s) => {
    selectedSpecialties[s] = { id: s, label: s.toUpperCase() };
  });
  return selectedSpecialties;
};

export const SpecialtyFilter = ({
  filters,
  setFilters,
  relevantUserSpecialties,
}: {
  filters: Filters;
  setFilters: (arg0: Filters) => void;
  relevantUserSpecialties: any;
}) => {
  const [selectedSpecialties, setSelectedSpecialties] =
    useState<filterOption | null>(getSelectedSpecialties(filters));

  useEffect(() => {
    const specialitiesToUpdate = selectedSpecialties || {};
    if (
      filters.specialtyFilter.sort().toString() !==
      Object.keys(specialitiesToUpdate).sort().toString()
    ) {
      setSelectedSpecialties(getSelectedSpecialties(filters));
    }
  }, [filters]);

  const sortedSpecialties = relevantUserSpecialties
    ?.sort()
    .map((s) => ({ id: s, label: s.toUpperCase() }));

  const updateSelectedItems = (itemId, itemLabel, checked) => {
    let newSpecialties = {};
    if (checked) {
      newSpecialties = {
        ...selectedSpecialties,
        [itemId]: { id: itemId, label: itemLabel },
      };
    } else {
      newSpecialties = selectedSpecialties;
      delete selectedSpecialties[itemId];
    }

    setSelectedSpecialties(newSpecialties);
    setFilters({ ...filters, specialtyFilter: Object.keys(newSpecialties) });
  };

  if (!sortedSpecialties) return null;

  return (
    <FilterSection>
      {filters.specialtyFilter && (
        <MultiSelectFiltering
          title="Specialty"
          description="Filter HCPs by specialty"
          filterType="specialties"
          filterOptions={sortedSpecialties}
          selectedFilters={selectedSpecialties}
          updateFilters={(itemId, itemLabel, checked) =>
            updateSelectedItems(itemId, itemLabel, checked)
          }
        />
      )}
    </FilterSection>
  );
};
