import ReactMarkdown from 'react-markdown';
import { Typography, Skeleton, styled, List } from '@mui/material';
import { Box } from '@mui/system';
import toKebabCase from '../../../../utils/toKebabCase';

const InsightContainer = styled(Box)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.contentCardBackgroundColor,
  border: '1px solid',
  borderColor: themeColors.contentCardBorderColor,
  borderRadius: 7,
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 16,
  width: '100%',
}));

const InsightIconContainer = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  justifyContent: 'center',
  height: 40,
  width: 40,
  borderRadius: 40,
  backgroundColor: themeColors.anchorIconsGeneralPrimary,
  color: themeColors.anchorIconsGeneralSecondary,
  marginBottom: 8,
}));

const InsightTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  textAlign: 'left',
  color: themeColors.secondaryTextColor,
  fontWeight: 500,
}));

const InsightSubTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  textAlign: 'left',
  marginTop: 6,
  color: themeColors.tertiaryColor,
  fontSize: 14,
  lineHeight: '19px',
  fontWeight: 400,
}));

const MinimalWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const MinimalInsightContent = styled(Box)(() => ({
  marginLeft: 'auto',
}));

const MinimalInsightTitle = styled(InsightTitle)(() => ({
  width: 'fit-content',
}));

const LoadingItem = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  margin: '0px 0px 8px',
  backgroundColor: themeColors.mainBackground,
}));

const LoadingTitle = styled(LoadingItem)(() => ({
  height: 16,
}));

const LoadingIcon = styled(LoadingItem)(() => ({
  height: 40,
  width: 40,
  borderRadius: 40,
}));

const LoadingSubTitle = styled(LoadingItem)(() => ({
  height: 60,
}));

const MessagingInsightContainer = styled(InsightContainer)(() => ({
  gap: 11.5,
}));

const MessagingInsightsTitle = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    textAlign: 'left',
    color: themeColors.primaryTextColor,
  })
);

const MessagingInsightSubTitle = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    textAlign: 'left',
    color: themeColors.secondaryTextColor,
  })
);

const StyledInsightList = styled(List)(({ theme: { themeColors } }) => ({
  textAlign: 'left',
  color: themeColors.secondaryTextColor,
  listStylePosition: 'outside',
  listStyle: 'unset',
  marginLeft: '1em',
  fontSize: 14,
}));

const MessagingIcon = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
}));

const MessageIconBackground = styled(Box)(({ theme: { themeColors } }) => ({
  height: 24,
  width: 24,
  borderRadius: 20,
  backgroundColor: themeColors.dataGeneralColor,
  opacity: 0.2,
}));

const HeaderRow = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 12,
}));

const SkeletonBar = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  backgroundColor: themeColors.surfaceEmpty,
}));

const markdownComponents = {
  h3: (props) => (
    <MessagingInsightsTitle variant="title3">
      {props.children}
    </MessagingInsightsTitle>
  ),
  p: (props) => (
    <MessagingInsightSubTitle variant="body2">
      {props.children}
    </MessagingInsightSubTitle>
  ),
  ul: (props) => (
    <StyledInsightList variant="body2">{props.children}</StyledInsightList>
  ),
};

export const InsightsCard = ({
  title,
  subtitle,
  icon,
  children,
  isLoading,
  minimal = false,
  messaging = false,
}) => {
  let InsightIcon = null;
  if (icon) {
    if (messaging) {
      InsightIcon = styled(icon)(({ theme: { themeColors } }) => ({
        height: 16,
        width: 16,
        marginTop: 4,
        color: themeColors.dataGeneralColor,
        left: 4,
        position: 'absolute',
      }));
    } else {
      InsightIcon = styled(icon)(() => ({
        height: 24,
        width: 24,
        marginTop: 8,
      }));
    }
  }

  if (isLoading && !minimal) {
    return (
      <InsightContainer>
        <LoadingIcon animation="wave" />
        <LoadingTitle animation="wave" />
        <LoadingSubTitle animation="wave" />
      </InsightContainer>
    );
  }

  if (messaging) {
    return (
      <MessagingInsightContainer>
        <HeaderRow>
          {icon && (
            <MessagingIcon>
              <MessageIconBackground />
              <InsightIcon />
            </MessagingIcon>
          )}
          <ReactMarkdown components={markdownComponents}>{title}</ReactMarkdown>
        </HeaderRow>
        <ReactMarkdown components={markdownComponents}>
          {subtitle}
        </ReactMarkdown>
        {children}
      </MessagingInsightContainer>
    );
  }

  if (minimal) {
    return (
      <InsightContainer
        className={`intercom-field-${toKebabCase(title)}-insights-card`}
      >
        <MinimalWrapper>
          <MinimalInsightTitle variant="title3">{title}</MinimalInsightTitle>
          {isLoading ? (
            <SkeletonBar animation="wave" height={17} width={390} />
          ) : (
            <MinimalInsightContent>{children}</MinimalInsightContent>
          )}
        </MinimalWrapper>
      </InsightContainer>
    );
  }

  return (
    <InsightContainer>
      {icon && (
        <InsightIconContainer>
          <InsightIcon />
        </InsightIconContainer>
      )}
      <InsightTitle variant="title3">{title}</InsightTitle>
      <InsightSubTitle>{subtitle}</InsightSubTitle>
      {children}
    </InsightContainer>
  );
};
