import { styled, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { ModalDisplayCustomStyle } from '../newproject/styles/styledComponents';
import {
  ModalBody,
  ModalDisplay,
  ModalFooter,
  ModalHeader,
} from '../generic/modalDisplay';

const StyledModalBody = styled(ModalBody)({
  paddingTop: '24px',
  paddingBottom: '24px',
  gridGap: 24,
});

const SecondaryText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
}));

const ErrorText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.danger60,
}));

const ContentWrapper = styled('div')({
  display: 'flex',
  gap: 16,
  alignItems: 'center',
});

const StyledErrorIcon = styled(ErrorOutlineOutlinedIcon)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.danger60,
    height: 24,
    width: 24,
  })
);
export function UserDomainModalPostSave({ isOpen, error, closeForm }) {
  return (
    <ModalDisplay isOpen={isOpen} style={ModalDisplayCustomStyle}>
      <ModalHeader onClose={closeForm}>
        Email Domains {error ? 'Failed to Save' : 'Saved Successfully'}
      </ModalHeader>
      <StyledModalBody>
        {!error && (
          <SecondaryText variant="body1">
            Changes to email domains are saved. You can now close this modal
          </SecondaryText>
        )}
        {error && (
          <ContentWrapper>
            <StyledErrorIcon />
            <ErrorText variant="body1">
              There was an issue saving changes to email domains. Please try
              again later.
            </ErrorText>
          </ContentWrapper>
        )}
      </StyledModalBody>
      <ModalFooter>
        <Button onClick={closeForm} variant="outlined">
          Close
        </Button>
      </ModalFooter>
    </ModalDisplay>
  );
}
