import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  List,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
} from '@mui/material';
import CsvIcon from '@mui/icons-material/GridOn';
import DataConnectorIcon from '@mui/icons-material/SettingsInputComponent';
import { ROUTES } from '../../constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
  },
}));

export default function DataSourceMenu({ onClose }) {
  const classes = useStyles();
  const routeHistory = useHistory();
  return (
    <div className={classes.container}>
      <List className={classes.root}>
        <ListItemButton
          onClick={() => {
            onClose();
            routeHistory.push(`${ROUTES.ADMIN}/datasets/adddata/`);
          }}
        >
          <ListItemAvatar>
            <Avatar alt="Import CSV">
              <CsvIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Import CSV" />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            onClose();
            routeHistory.push(`${ROUTES.ADMIN}/datasets/dataconnectors/`);
          }}
        >
          <ListItemAvatar>
            <Avatar alt="Connectors">
              <DataConnectorIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Connectors" />
        </ListItemButton>
      </List>
    </div>
  );
}

DataSourceMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
};
