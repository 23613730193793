import { useQuery } from 'react-query';
import { maptualApiInstance } from '../../../../../../request/config';

export const useLatestTimestamp = ({
  productLineId,
  regionId = '',
  currentList = false,
  enabled = true,
}) =>
  useQuery({
    queryKey: [
      'product-line',
      productLineId,
      'latest-timestamp',
      regionId,
      'current-list',
      currentList,
    ],
    queryFn: async () => {
      try {
        const response = await getLatestTimestamp(
          productLineId,
          regionId,
          currentList
        );
        if (typeof response.data === 'string') return response.data;
        return null;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    enabled,
  });

const getLatestTimestamp = (productLineId, regionId, currentList) => {
  if (!productLineId) {
    return Promise.reject();
  }
  let paramString = regionId || currentList ? '?' : '';
  paramString += regionId ? `region_id=${regionId}&` : '';
  paramString += currentList ? `current_list=${currentList}` : '';

  return maptualApiInstance.get(
    `pulse/product-lines/${productLineId}/latest-timestamp${paramString}`,
    {
      timeout: 30000,
    }
  );
};
