import { Switch, useRouteMatch } from 'react-router-dom';
import { ConfigSchemaView } from './view';
import { SentryRoute } from '../../sentryRoute';
import { NewSchemaContainer } from './update';
import { EditSchemaContainer } from './update/EditSchemaContainer';
import { ROUTES } from '../../constants';
import { AdminThemeProvider } from '../admin/adminThemeProvider';
import { MutateInstance } from './mutateInstances';

export default function AppConfig() {
  const { path } = useRouteMatch();

  return (
    <AdminThemeProvider>
      <Switch>
        <SentryRoute exact path={path} component={ConfigSchemaView} />
        <SentryRoute
          exact
          path={`${path}${ROUTES.CREATE_SCHEMA}`}
          component={NewSchemaContainer}
        />
        <SentryRoute
          exact
          path={`${path}${ROUTES.SCHEMAS}/:schemaName`}
          component={EditSchemaContainer}
        />
        <SentryRoute
          exact
          path={`${path}${ROUTES.SCHEMAS}/:schemaName${ROUTES.CREATE_INSTANCE}`}
          render={() => <MutateInstance isCreate />}
        />
        <SentryRoute
          exact
          path={`${path}${ROUTES.SCHEMAS}/:schemaName${ROUTES.UPDATE_INSTANCE}`}
          component={MutateInstance}
        />
      </Switch>
    </AdminThemeProvider>
  );
}
