import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { CircularProgress } from '@mui/material';
import { ObjectiveTypesView } from './objectiveTypesView';
import {
  addCustomerObjectiveType,
  getCustomerObjectiveTypes,
  updateCustomerObjectiveType,
} from '../../request/configViewRequests';
import { ConfigViewContext } from './configViewContext';
import { CONFIG_ALERT_STATUSES } from './configAlertStatuses';
import {
  CONFIG_VIEW_MODE,
  NEW_OBJECTIVE_TYPE_TEMP_ID,
} from './userGroupConfigConstants';
import { ConfigSectionTitle } from './configViewTitle';
import { DEFAULT_TABS } from './configTabs';
import {
  CenteredContentContainer,
  ConfigSectionBody,
} from './styledComponents';

export const ObjectiveTypesProvider = () => {
  const [customerObjectiveTypes, setCustomerObjectiveTypes] = useState([]);
  const [objectiveTypeBeingEdited, setObjectiveTypeBeingEdited] =
    useState(null);
  const {
    data: objectiveTypes,
    refetch,
    isLoading,
  } = useQuery(
    'objective-types',
    async () => {
      const response = await getCustomerObjectiveTypes();
      return response.data.objectiveTypes;
    },
    {
      onSuccess: (data) => {
        setCustomerObjectiveTypes(data);
      },
    }
  );
  const { showConfigAlert, configViewMode, setConfigViewMode } =
    useContext(ConfigViewContext);

  useEffect(() => {
    if (objectiveTypes) {
      setCustomerObjectiveTypes(objectiveTypes);
    } else {
      refetch();
    }
  }, []);

  useEffect(() => {
    setCustomerObjectiveTypes(objectiveTypes);
  }, [objectiveTypes]);

  useEffect(() => {
    if (objectiveTypes) {
      if (configViewMode === CONFIG_VIEW_MODE.CREATE) {
        const newObjectiveType = {
          objectiveTypeId: NEW_OBJECTIVE_TYPE_TEMP_ID,
          type: '',
          typeDescription: '',
          typeDisplay: '',
          params: { products: [] },
        };
        setCustomerObjectiveTypes([newObjectiveType, ...objectiveTypes]);
        setObjectiveTypeBeingEdited(NEW_OBJECTIVE_TYPE_TEMP_ID);
      } else if (configViewMode === CONFIG_VIEW_MODE.VIEW) {
        setObjectiveTypeBeingEdited(null);
      }
    }
  }, [configViewMode]);

  const removeAddObjectiveType = () => {
    setCustomerObjectiveTypes(
      objectiveTypes.filter(
        (objectiveType) =>
          objectiveType.objectiveTypeId !== NEW_OBJECTIVE_TYPE_TEMP_ID
      )
    );
  };

  const {
    mutate: onSaveEditObjectiveType,
    isLoading: isEditObjectiveTypeSaving,
  } = useMutation(
    ({ objectiveTypeIdToUpdate, requestBody }) =>
      updateCustomerObjectiveType(objectiveTypeIdToUpdate, requestBody),
    {
      onSuccess: (data, variables) => {
        if (data?.data === true) {
          const { requestBody: objectiveTypeToUpdate } = variables;
          showConfigAlert(
            CONFIG_ALERT_STATUSES.SUCCESS,
            `Saved changes to ${objectiveTypeToUpdate.typeDisplay}`
          );
          setConfigViewMode(CONFIG_VIEW_MODE.VIEW);
          refetch();
        } else {
          showConfigAlert(CONFIG_ALERT_STATUSES.ERROR);
        }
      },
      onError: () => {
        showConfigAlert(CONFIG_ALERT_STATUSES.ERROR);
      },
    }
  );

  const {
    mutate: onSaveAddObjectiveType,
    isLoading: isAddObjectiveTypeSaving,
  } = useMutation(({ requestBody }) => addCustomerObjectiveType(requestBody), {
    onSuccess: (data, variables) => {
      if (data?.data === true) {
        const { requestBody: objectiveTypeToUpdate } = variables;
        showConfigAlert(
          CONFIG_ALERT_STATUSES.SUCCESS,
          `Successfully created ${objectiveTypeToUpdate.typeDisplay}`
        );
        setConfigViewMode(CONFIG_VIEW_MODE.VIEW);
        refetch();
      } else {
        showConfigAlert(
          CONFIG_ALERT_STATUSES.ERROR,
          'Cannot create or save changes. Please try again'
        );
      }
    },
    onError: () => {
      showConfigAlert(
        CONFIG_ALERT_STATUSES.ERROR,
        'Cannot create or save changes. Please try again'
      );
    },
  });

  return isLoading ? (
    <>
      <ConfigSectionTitle currentTabTitle={DEFAULT_TABS.TYPES} />
      <ConfigSectionBody>
        <CenteredContentContainer>
          <CircularProgress />
        </CenteredContentContainer>
      </ConfigSectionBody>
    </>
  ) : (
    <>
      <ConfigSectionTitle currentTabTitle={DEFAULT_TABS.TYPES} />
      <ConfigSectionBody>
        <ObjectiveTypesView
          objectiveTypes={customerObjectiveTypes}
          objectiveTypeBeingEdited={objectiveTypeBeingEdited}
          setObjectiveTypeBeingEdited={setObjectiveTypeBeingEdited}
          removeAddObjectiveType={removeAddObjectiveType}
          onSaveEditObjectiveType={onSaveEditObjectiveType}
          onSaveAddObjectiveType={onSaveAddObjectiveType}
          isEditObjectiveTypeSaving={isEditObjectiveTypeSaving}
          isAddObjectiveTypeSaving={isAddObjectiveTypeSaving}
        />
      </ConfigSectionBody>
    </>
  );
};
