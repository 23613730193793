import { Button, Stack, Typography, styled } from '@mui/material';
import { useContext } from 'react';
import { DEFAULT_TABS } from './configTabs';
import { CONFIG_VIEW_MODE } from './userGroupConfigConstants';
import { ConfigViewContext } from './configViewContext';
import { StyledBackButton } from './styledComponents';
import { useIsAuthorized } from '../../hooks/useIsAuthorized';
import { RBAC_PERMISSIONS } from '../../constants';

const Title = styled('div')(({ theme: { themeColors } }) => ({
  height: 70,
  borderBottom: `1px solid ${themeColors.borderLowContrast}`,
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  padding: 25,
}));

const AddNewButton = styled(Button)({
  height: 32,
});

export function ConfigSectionTitle({ currentTabTitle, onClickBack }) {
  const { configViewMode, setConfigViewMode, setProductLineId } =
    useContext(ConfigViewContext);

  const hasConfigAdminPermissions = useIsAuthorized([
    RBAC_PERMISSIONS.USER_GROUP_CONFIG_ADMIN,
  ]);
  return (
    <Title>
      <Stack direction="row" gap={2} alignItems="center">
        {onClickBack && <StyledBackButton onClick={onClickBack} />}
        <Typography variant="h4">{currentTabTitle}</Typography>
      </Stack>

      {(currentTabTitle === DEFAULT_TABS.MARKETS ||
        currentTabTitle === DEFAULT_TABS.TYPES ||
        currentTabTitle === DEFAULT_TABS.PRODUCT_TREE) &&
        hasConfigAdminPermissions && (
          <AddNewButton
            aria-label="add-new-config-item"
            variant="outlined"
            disabled={configViewMode !== CONFIG_VIEW_MODE.VIEW}
            onClick={() => {
              setProductLineId(null);
              setConfigViewMode(CONFIG_VIEW_MODE.CREATE);
            }}
          >
            {currentTabTitle === DEFAULT_TABS.PRODUCT_TREE
              ? 'Add New Tree'
              : 'Add New'}{' '}
            +
          </AddNewButton>
        )}
    </Title>
  );
}
