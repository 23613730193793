import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';

export const ConfigSchemaTitle = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.neutral60,
  })
);

export const StyledButton = styled(Button)(({ theme: { themeColors } }) => ({
  '&.Mui-disabled': {
    color: themeColors.disabledUtilityContent,
    backgroundColor: themeColors.disabledUtilitySurface,
  },
}));

export const StyledHelperText = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.neutral60,
    textAlign: 'left',
    marginTop: 8,
  })
);

export const ModalBody = styled('div')<{ notScrollable: boolean }>(
  ({ theme, notScrollable }) => ({
    flexGrow: 1,
    overflowY: notScrollable ? 'none' : 'auto',
    padding: 24,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gridGap: 16,
    borderBottom: '1px solid',
    borderColor: theme.themeColors.borderLowContrast,
  })
);

export const PageWrapper = styled(Box)({
  padding: '24px 36px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});
export const StyledBreadcrumb = styled(Breadcrumbs)(
  ({ theme: { themeColors } }) => ({
    textAlign: 'left',
    a: {
      padding: 0,
      margin: 0,
      backgroundColor: '#00000000',
      color: themeColors.neutral60,
      '&:hover, &:visited': {
        backgroundColor: '#00000000',
      },
      fontSize: 20,
      fontWeight: 600,
    },
    li: {
      '&:last-child > a': {
        color: themeColors.primary99,
      },
    },
  })
);
