import { styled, Box } from '@mui/material';
import { getCellValue } from './utils';

const ValueTrendBox = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: themeColors.offWhiteFontColor,
  alignItems: 'center',
  fontSize: 16,
  fontWeight: 400,
}));

export const ValueContainer = ({ data, valueType, children }) => (
  <ValueTrendBox id="trend-value-box">
    {getCellValue(data, valueType)}
    {children}
  </ValueTrendBox>
);
