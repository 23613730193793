/* eslint-disable no-restricted-syntax */
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from '@mui/material';
import * as ViewerAction from '../../../../actions/projectViewActions';

const useStyles = makeStyles(({ themeColors }) => ({
  textField: {
    borderRadius: 4,
    margin: 0,
    width: '220px',
    fontSize: 14,
    backgroundColor: themeColors.mainBackground,
    '& .MuiInputLabel-root': {
      color: '#FBFCFE',
    },
    '& .MuiOutlinedInput-root fieldset': {
      borderColor: '#8595AD',
    },
  },
  headerContainer: {
    background: themeColors.mainBackground,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 32px',
  },
  title: {
    fontSize: '20px',
    margin: 0,
    color: '#E0E4EB',
    fontWeight: 600,
    font: 'Roboto',
  },
  controls: {
    margin: 0,
    display: 'flex',
    height: '40px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

function Header({ viewer, getProjects, onParamSelect }) {
  const classes = useStyles();
  const [projects, setProjects] = useState(viewer?.projects || []);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    async function retrieveProjects() {
      const pl = await getProjects();
      setProjects(pl.payload);
    }
    if (!projects.length) retrieveProjects();
  }, []);

  return (
    <div className={classes.headerContainer}>
      <h1 className={classes.title}>Curation Dashboard</h1>
      <div className={classes.controls}>
        <TextField
          select
          id="project-dropdown-key"
          disabled={!projects.length}
          value={selectedProject}
          className={classes.textField}
          label="Select Project"
          size="small"
          sx={{
            '& .MuiInputLabel-root.Mui-disabled': {
              color: '#8595AD',
            },
          }}
          onChange={(e) => {
            setSelectedProject(e.target.value);
            onParamSelect({
              projectId: e.target.value.projectId,
            });
          }}
        >
          {projects.length
            ? projects.map((item) => (
                <MenuItem key={item.projectId} value={item}>
                  {item.projectName || ''}
                </MenuItem>
              ))
            : null}
        </TextField>
      </div>
    </div>
  );
}

Header.propTypes = {
  viewer: PropTypes.any,
};

const mapStateToProps = (state) => ({
  viewer: state.projectViewer,
});

const mapDispatchToProps = (dispatch) => ({
  getProjects: () => dispatch(ViewerAction.getProjectFromAPI()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
