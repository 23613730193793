import { useState, useRef, useEffect } from 'react';
import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium';
import { darken } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import LinkController from '../../containers/links/linkController';

const useStyle = makeStyles({
  root: {
    minHeight: '50vh',
    '& .MuiDataGrid-cell': {
      cursor: 'pointer',
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#1C1C1C',
    },
    '& .MuiDataGrid-toolbarContainer': {
      backgroundColor: '#1C1C1C',
    },
    '& .row-theme-same': {
      backgroundColor: '#303030',
      '&:hover': {
        backgroundColor: darken('#303030', 0.2),
      },
    },
    '& .row-theme-new': {
      backgroundColor: '#414245',
      '&:hover': {
        backgroundColor: darken('#414245', 0.2),
      },
    },
  },
});

export function XGridTables({
  rows,
  columns,
  analysisId,
  type,
  linkLocation,
  match,
}) {
  const [userId, setUserId] = useState(null);
  const controllerRef = useRef(null);
  const classes = useStyle();

  function getDateString(timestamp) {
    const date = moment.unix(timestamp).utc();
    return timestamp !== '' ? date.format('ddd, MMM D, YYYY') : '';
  }

  function isNew(value) {
    return value ? 'new' : 'same';
  }

  columns.forEach((col) => {
    // eslint-disable-next-line no-param-reassign
    col.width = 150;
    if (col.field === 'lastContactedDate') {
      // eslint-disable-next-line no-param-reassign
      col.renderCell = (params) => getDateString(params.value);
    } else if (col.field === 'linkedInConnections') {
      // eslint-disable-next-line no-param-reassign
      col.renderCell = (params) =>
        params.value === 500 ? '500+' : String(params.value);
    }
  });

  const handleRowClick = (e) => {
    setUserId(e.row.user_id);
  };

  useEffect(() => {
    if (userId) {
      if (controllerRef && controllerRef.current) {
        controllerRef.current.click();
      }
    }
  }, [userId]);

  return (
    <>
      <LinkController
        ref={controllerRef}
        type={type}
        linkLocation={linkLocation}
        match={match}
        analysisId={analysisId}
        userId={userId}
      />
      <DataGridPremium
        className={classes.root}
        rowHeight={70}
        slots={{
          toolbar: GridToolbar,
        }}
        rows={rows}
        columns={columns}
        getRowClassName={(params) =>
          `row-theme-${isNew(params.getValue(params.id, 'maptualScoreChange'))}`
        }
        onRowClick={handleRowClick}
      />
    </>
  );
}
