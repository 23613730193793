import { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import { SentryRoute } from '../../sentryRoute';
import * as ViewerAction from '../../actions/projectViewActions';
import {
  setSegment,
  setTabView as setTabViewAction,
} from '../../actions/headOfficeViewActions';
import ProjectContent from '../../components/projectviewer/projectContent';
import ProjectControl from '../../components/projectviewer/projectControl';
import { HeadOfficeProjectItem } from '../../components/headoffice/headOfficeProjectItem';
import useFeatureToggles from '../../hooks/useFeatureToggles';
import { NAVBAR_DRAWER_WIDTH } from '../application/appViews/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    'min-height': '100%',
    display: 'flex',
    'flex-direction': 'column',
    marginLeft: NAVBAR_DRAWER_WIDTH,
    backgroundColor: theme.themeColors.mainBackground,
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  progressContainer: {
    height: '100%',
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'flex-end',
    'align-items': 'center',
  },
  progressBar: {
    'margin-top': '50px',
    width: 'auto',
    'margin-bottom': '70px',
  },
  projectName: {
    flexGrow: 1,
  },
  widthSpacer: {
    width: 25,
  },
}));

const eagerLoadFeatureToggles = () => useFeatureToggles();

const mapStateToProps = (state) => ({
  viewer: state.projectViewer,
  searchState: state.projectSearch,
});

const mapDispatchToProps = (dispatch) => ({
  deleteProjectItem: (projectId) =>
    dispatch(ViewerAction.deleteProject(projectId)),
  getAnalysis: (analysisId, userId = null) =>
    dispatch(ViewerAction.getAnalysis(analysisId, userId)),
  getProject: (projectId) =>
    dispatch(ViewerAction.getProjectFromAPI(projectId)),
  getSegments: (projectId) => dispatch(ViewerAction.getSegments(projectId)),
  getDashboardStructure: ({ projectId, segmentId, maptualListId }) =>
    dispatch(
      ViewerAction.getDashboardStructure({
        projectId,
        segmentId,
        maptualListId,
      })
    ),
  getDashboardSubUrl: ({ subUrl, projectId, segmentId, maptualListId }) =>
    dispatch(
      ViewerAction.getDashboardSubUrl({
        subUrl,
        projectId,
        segmentId,
        maptualListId,
      })
    ),
  setProjectItem: (item) => dispatch(ViewerAction.setProjectItem(item)),
  setFullAnalysisItem: (item) =>
    dispatch(ViewerAction.setFullAnalysisItem(item)),
  setFeatureIdMapping: (item) =>
    dispatch(ViewerAction.setFeatureIdMapping(item)),
  setAnalysisIdMapping: (item) =>
    dispatch(ViewerAction.setAnalysisIdMapping(item)),
  setAnalysisExpanded: (item) =>
    dispatch(ViewerAction.setAnalysisExpanded(item)),
  setRegionSelection: (region) =>
    dispatch(ViewerAction.setRegionSelection(region)),
  setSegmentSelection: (segmentId) => dispatch(setSegment(segmentId)),
  setTabView: (tabView) => dispatch(setTabViewAction(tabView)),
});

function HeadOfficeViewer(props) {
  const classes = useStyles();
  const { match, profile, deleteProjectItem, searchState } = props;
  const [injectableComponents, setInjectableComponents] = useState([]);
  const [customCrumb, setCustomCrumb] = useState(null); // {component: {}, action: ()=>{}}
  eagerLoadFeatureToggles();

  return (
    <div className={classes.root}>
      <Switch>
        <SentryRoute
          exact
          path={match.path}
          render={() => (
            <>
              <ProjectControl
                injectableControls={injectableComponents}
                customCrumb={customCrumb}
              />
              <ProjectContent
                {...props}
                setInjectableComponents={setInjectableComponents}
                setCustomCrumb={setCustomCrumb}
                profile={profile}
                deleteProject={deleteProjectItem}
              />
            </>
          )}
        />
        <SentryRoute
          path={`${match.path}/:projectId`}
          render={(renderProps) => (
            <>
              <ProjectControl
                injectableControls={injectableComponents}
                customCrumb={customCrumb}
              />
              <HeadOfficeProjectItem
                {...props}
                returnMatch={match}
                match={renderProps.match}
                isSearchActive={searchState.isSearchActive}
                setInjectableComponents={setInjectableComponents}
                setCustomCrumb={setCustomCrumb}
              />
            </>
          )}
        />
      </Switch>
    </div>
  );
}

HeadOfficeViewer.propTypes = {
  deleteProjectItem: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
  profile: PropTypes.shape({
    email: PropTypes.string,
    fullName: PropTypes.string,
    name: PropTypes.string,
    organization: PropTypes.shape({
      organizationName: PropTypes.string,
    }),
    role: PropTypes.shape({
      specialType: PropTypes.string,
      subType: PropTypes.string,
      type: PropTypes.string,
    }),
    userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  setAnalysisExpanded: PropTypes.func.isRequired,
  searchState: PropTypes.shape({
    isSearchActive: PropTypes.bool,
    keyword: PropTypes.string,
  }),
  viewer: PropTypes.shape({
    isAnalysisExpanded: PropTypes.bool,
    setAnalysisExpanded: PropTypes.func,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(HeadOfficeViewer);
