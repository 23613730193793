import { isEmpty } from 'lodash';

export function validateJson(jsonString) {
  try {
    const jsonObject = JSON.parse(jsonString);
    const pathToDisallowedChars = findDisallowedChars(jsonObject);
    if (pathToDisallowedChars !== false) {
      return `Null, empty object, and empty array are not allowed values. Path: ${pathToDisallowedChars}`;
    }
  } catch (e) {
    return e.message;
  }
  return true;
}

function findDisallowedChars(jsonNode, path = '') {
  if (jsonNode === null) {
    return path;
  }

  if (typeof jsonNode === 'object') {
    if (isEmpty(jsonNode)) {
      return path;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const key in jsonNode) {
      if (Object.prototype.hasOwnProperty.call(jsonNode, key)) {
        const subPath = path ? `${path}.${key}` : key;
        const result = findDisallowedChars(jsonNode[key], subPath);
        if (result !== false) {
          return result;
        }
      }
    }
  }

  return false;
}
