import { useQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';
import {
  getSchema,
  getSchemas,
  getInstancesBySchema,
  GetSchemaValuesArgs,
  getInstances,
  GetInstancesArgs,
} from './moduleConfigRequests';
import { queryKeys } from '../queries';
import { unwrapResponseData } from '../utils';
import { getCustomers } from './customersRequests';
import { getMarkets } from './marketsRequests';

export const useModuleConfigSchemasQuery = () =>
  useQuery(queryKeys.moduleConfigSchemas, unwrapResponseData(getSchemas));

export const useModuleConfigSchemaQuery = (schemaName: string, options = {}) =>
  useQuery(
    [queryKeys.moduleConfigSchema, schemaName],
    unwrapResponseData(getSchema, schemaName),
    options
  );

export const useModuleConfigSchemaValuesQuery = (
  args: GetSchemaValuesArgs,
  options = {}
) =>
  useQuery(
    [queryKeys.moduleConfigSchemaValues, args],
    unwrapResponseData(getInstancesBySchema, args),
    options
  );

export const useModuleConfigValuesQuery = (
  args: GetInstancesArgs,
  options: UseQueryOptions<
    Awaited<ReturnType<typeof getInstances>>['data']
  > = {}
) =>
  useQuery(
    [queryKeys.moduleConfigValues, args],
    unwrapResponseData(getInstances, args),
    options
  );

export const useCustomersQuery = (options = {}) =>
  useQuery(queryKeys.customers, unwrapResponseData(getCustomers), options);

export const useMarkets = (config_user_group?: string) =>
  useQuery(
    [queryKeys.configMarkets, config_user_group],
    unwrapResponseData(getMarkets, config_user_group)
  );
