/* eslint-disable no-nested-ternary */

import { styled, Typography, Box, Skeleton } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: 35,
  width: 140,
}));

const NumericalContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  width: 60,
}));

const Percentage = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.offWhiteFontColor,
  fontSize: 16,
}));

const SecondaryRow = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryMaptualListFilteringColor,
  fontSize: 12,
}));

const GraphContainer = styled('div')(() => ({
  display: 'flex',
  maxWidth: 92,
  minWidth: 92,
  alignItems: 'center',
  marginRight: '8px',
}));

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme: { themeColors } }) => ({
    height: 10,
    width: 92,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: themeColors.progressBarBackground,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: themeColors.dataGeneralColor,
    },
  })
);

const LoadingContainer = styled(Box)(() => ({
  margin: 'auto',
}));

const StyledSkeleton = styled(Skeleton)(({ theme: { themeColors } }) => ({
  color: themeColors.buttonContentColor,
  transform: 'none',
}));

export default function ProgressBar({
  numerator,
  denominator,
  isLoading,
  percentageOnly = false,
}) {
  const formattedPercentage = () =>
    !numerator && !denominator
      ? '—'
      : denominator && numerator !== undefined
      ? Math.round((numerator / denominator) * 100)
      : 0;
  const percentage = formattedPercentage();
  return (
    <Root>
      {isLoading && (
        <LoadingContainer>
          <StyledSkeleton height={35} width={140} />
        </LoadingContainer>
      )}
      {!isLoading && (
        <>
          <GraphContainer>
            <BorderLinearProgress
              variant="determinate"
              value={percentage === '—' ? 0 : percentage}
            />
          </GraphContainer>
          <NumericalContainer>
            <Percentage>{`${
              percentage === '—' ? percentage : `${percentage}%`
            }`}</Percentage>
            {!percentageOnly && (
              <SecondaryRow>
                {!numerator && !denominator
                  ? '—'
                  : `${numerator || 0}/${denominator}`}
              </SecondaryRow>
            )}
          </NumericalContainer>
        </>
      )}
    </Root>
  );
}
