import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import CustomExportButton from './export';

const CustomToolbar = (props) => (
  <GridToolbarContainer {...props}>
    <GridToolbarColumnsButton
      data-testid="test-columns"
      className="intercom-sphere-columns-button"
    />
    <GridToolbarFilterButton className="intercom-sphere-filters-button" />
    <GridToolbarDensitySelector />
    <CustomExportButton />
  </GridToolbarContainer>
);

export default CustomToolbar;
