/* eslint-disable import/no-cycle */

import makeStyles from '@mui/styles/makeStyles';
import { Content } from './content';
import SectionLabel from '../labels/sectionLabel';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    marginBottom: '1.5vh',
  },
  horContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

export default function Sections({
  primaryText = null,
  secondaryText = null,
  tertiaryText = null,
  content = null,
  match,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.horContainer}>
        {primaryText ? (
          <SectionLabel text={primaryText} type="primaryText" match={match} />
        ) : null}
        {secondaryText ? (
          <SectionLabel
            text={secondaryText}
            type="secondaryText"
            match={match}
          />
        ) : null}
        {tertiaryText ? (
          <SectionLabel text={tertiaryText} type="tertiaryText" match={match} />
        ) : null}
      </div>
      {content ? <Content content={content} match={match} /> : null}
    </div>
  );
}
