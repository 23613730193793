import { useContext } from 'react';
import { OutlinedInput, SvgIcon, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import {
  StyledFieldSubtitle,
  StyledFieldTitle,
  StyledFormControl,
  StyledSelect,
} from './styles/styledComponents';
import { NewProjectContext } from './newProjectContext';
import { ObjectiveSummary } from './objectiveSummary';
import { ProjectModalMode } from './projectModalMode';
import { useDeployDialogContext } from '../projectviewer/deployCsvDialog/deployDialogContext';

const StyledIconDropdownBox = styled(Box)(
  ({ disabled, theme: { themeColors } }) => ({
    display: 'flex',
    width: '50%,',
    color: disabled ? themeColors.listIconColor : 'initial',
    WebkitTextFillColor: 'initial',
  })
);

export default function NotchedObjectiveModalDropdown({
  title,
  subtitle,
  onChange,
  values,
  disabled,
  flip,
}) {
  const { objectives } = useContext(NewProjectContext);
  const { projectModalMode } = useDeployDialogContext();
  return (
    <div>
      <StyledFieldTitle variant="h5">{title}</StyledFieldTitle>
      <StyledFieldSubtitle variant="body1">{subtitle}</StyledFieldSubtitle>
      {objectives.map((objective, idx) => (
        <ObjectiveSummary key={idx} objective={objective} number={idx + 1} />
      ))}
      <StyledFormControl disabled={disabled}>
        {projectModalMode !== ProjectModalMode.runProjectFromTemplate && (
          <StyledSelect
            input={<OutlinedInput notched />}
            defaultValue=""
            onChange={onChange}
            displayEmpty
            renderValue={() => (
              <StyledIconDropdownBox disabled={disabled}>
                <SvgIcon>
                  <AddIcon />
                </SvgIcon>
                Add objective
              </StyledIconDropdownBox>
            )}
            MenuProps={{
              anchorOrigin: {
                vertical: flip ? 'top' : 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: flip ? 'bottom' : 'top',
                horizontal: 'left',
              },
            }}
          >
            {values}
          </StyledSelect>
        )}
      </StyledFormControl>
    </div>
  );
}
