import { useState, useEffect, useContext } from 'react';
import { styled, Box, Typography } from '@mui/material';
import { RepTableProvider } from './repTable/repTableProvider';
import { RegionSelection } from './regionSelector/RegionSelection';
import { PulseContext } from '../../context/PulseContext';

const Root = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  backgroundColor: themeColors.mainBackground,
  colorScheme: themeColors.colorScheme,
}));

const RegionSelectorContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  margin: '24px 16px 0px 0px',
}));

const HeaderContainer = styled(Box)((props) => ({
  display: 'flex',
  width: '100%',
  borderBottom: `1px solid ${props.theme.themeColors.borderLowContrast}`,
  padding: '12px 16px 32px 16px',
}));

const HeaderText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
  fontSize: 20.25,
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '120%',
}));

export const SalesReps = () => {
  const { latestCurationTimestamp } = useContext(PulseContext);
  const [timestamp, setTimestamp] = useState();

  useEffect(() => {
    if (timestamp !== latestCurationTimestamp)
      setTimestamp(latestCurationTimestamp);
  }, [latestCurationTimestamp]);

  return (
    <Root>
      <RegionSelectorContainer>
        <RegionSelection />
      </RegionSelectorContainer>
      <HeaderContainer>
        <HeaderText>Sales Reps</HeaderText>
      </HeaderContainer>
      <RepTableProvider timestamp={timestamp} />
    </Root>
  );
};
