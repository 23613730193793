import { useState, useRef } from 'react';
import { useTheme } from '@mui/styles';
import { Line } from 'react-chartjs-2';
import { formatCellValue, formatYAxisTitle, GRAPH_VALUE_TYPE } from '../utils';
import { Tooltip, createTooltip } from '../tooltip';

const createHoverLine = (chart, strokeColor) => {
  const x = chart.tooltip.caretX;
  const { ctx } = chart;
  const topY = chart.scales.y.top;
  const bottomY = chart.scales.y.bottom;
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(x, topY);
  ctx.lineTo(x, bottomY);
  ctx.lineWidth = 1;
  ctx.setLineDash([3, 3]);
  ctx.strokeStyle = strokeColor;
  ctx.stroke();
  ctx.restore();
};

const DECIMAL_PLACES = 1;
export const SparklineLineGraph = ({
  data,
  labels,
  color,
  valueType,
  expanded = false,
  yTitle = '',
  tooltipDataPointTitle = '',
}) => {
  const [tooltip, setTooltip] = useState({
    trend: {},
    opacity: 0,
    top: 0,
    left: 0,
    date: '',
    value: '',
    display: 'none',
  });
  const { themeColors } = useTheme();
  const chartRef = useRef(null);

  const afterDrawPlugin = {
    afterDraw(chart) {
      // eslint-disable-next-line no-underscore-dangle
      if (chart?.tooltip?.caretX && chart.tooltip._active?.length) {
        createHoverLine(chart, themeColors.markerLine);
      }
    },
  };

  const chartData = {
    labels,
    datasets: [
      {
        tick: false,
        scale: false,
        pointStyle: false,
        label: '',
        data,
        fill: false,
        borderColor: color,
        borderWidth: 2,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    animation: expanded,
    scales: {
      x: {
        display: expanded,
        ticks: {
          color: themeColors.chartDataLabel,
        },
        grid: {
          display: false,
        },
      },
      y: {
        min: (scale) =>
          scale.chart.data.datasets.reduce((acc, curr) => {
            const min = Math.min(...curr.data);
            return min < acc ? min : acc;
          }, 0),
        max:
          valueType === GRAPH_VALUE_TYPE.RATE ||
          (valueType ?? '').includes('percent')
            ? 100
            : undefined,
        title: {
          display: !!yTitle,
          text: formatYAxisTitle(yTitle),
          font: 12,
          color: themeColors.chartAxisLabel,
        },
        ticks: {
          color: themeColors.chartDataLabel,
          padding: 8,
          callback: (value) =>
            formatCellValue(value, valueType, DECIMAL_PLACES),
        },
        grid: {
          display: expanded,
          color: themeColors.chartLines,
        },
        display: expanded,
        border: { display: false },
      },
    },
    legend: {
      display: false,
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          if (!expanded) return;
          createTooltip({
            context,
            tooltip,
            updateTooltip: (newTooltipData) => setTooltip(newTooltipData),
          });
        },
      },
    },
  };

  const plugins = expanded ? [afterDrawPlugin] : [];

  return (
    <>
      <Line
        data={chartData}
        ref={chartRef}
        options={options}
        plugins={plugins}
      />
      {expanded && (
        <Tooltip
          tooltip={tooltip}
          tooltipDataPointTitle={tooltipDataPointTitle}
          valueType={valueType}
        />
      )}
    </>
  );
};
