import { useEffect, useState, useContext } from 'react';
import { getTimeframeName, getComparisonTimeFrames } from './utils';
import { Distribution, DISTRIBUTION_CONFIG } from './constants';
import { useTerritoryOverviewData } from './useTerritoryOverviewData';
import { trackTerritoryOverviewOptionSet } from '../../trackers/mixpanel';
import { FieldContext } from '../application/appViews/field/fieldContext';
import { Cadence } from './enums';

export const useFieldTerritoryOverviewContext = () => {
  const {
    project: projectItem,
    maptualListMetadata,
    isMaptualListsLoading,
  } = useContext(FieldContext);
  const { projectId } = projectItem.metadata || {};

  const projectName = projectItem?.metadata?.projectName || '';
  // TODO ODAIA-8744: Change sfMaptualListId to maptualListId when
  // all endpoints are using sfMaptualListId
  const {
    sfMaptualListId,
    maptualListId,
    listName: territoryName,
  } = maptualListMetadata ?? {};

  const [selectedMetric, setSelectedMetric] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedTimeframeID, setSelectedTimeframeID] = useState();
  const [comparisonTimeFrameId, setComparisonTimeFrameId] = useState();

  const updateSelectedCategory = (newCategory) => {
    setSelectedCategory(newCategory);

    setMetricOptions(categoryOptions[newCategory]?.metrics);
    const metric = Object.values(categoryOptions[newCategory]?.metrics)[0];
    setSelectedMetric(metric.id);
    setCadenceOptions(metric.cadences);

    setSelectedTimeframeID((prevTimeframeId) =>
      metric.cadences.includes(prevTimeframeId)
        ? prevTimeframeId
        : metric.cadences[0]
    );

    trackTerritoryOverviewOptionSet(
      DISTRIBUTION_CONFIG[distributionType]?.dropdownLabel,
      categoryOptions[newCategory].label,
      categoryOptions[selectedCategory]?.label,
      [
        getTimeframeName(selectedTimeframeID),
        distributionType,
        categoryOptions[newCategory].label,
        categoryOptions.metrics[selectedMetric]?.label,
      ],
      false
    );
  };

  const [distributionType, setDistributionType] = useState('');

  const updateDistributionType = (newDistributionType) => {
    trackTerritoryOverviewOptionSet(
      'View',
      newDistributionType,
      distributionType,
      [
        getTimeframeName(selectedTimeframeID),
        newDistributionType,
        categoryOptions[selectedCategory]?.label,
        metricOptions[selectedMetric]?.label,
      ],
      false
    );
    setDistributionType(newDistributionType);
  };

  const {
    dataResponse,
    status,
    metadataResponse,
    metadataStatus,
    isDataLoading,
    isDataError,
    isMetadataLoading,
  } = useTerritoryOverviewData({
    distributionType,
    setDistributionType,
    projectId,
    maptualListId,
    sfMaptualListId,
    selectedMetric,
    selectedCategory,
    selectedTimeframeID,
    comparisonTimeFrameId,
  });

  const [categoryOptions, setCategoryOptions] = useState({});
  const [metricOptions, setMetricOptions] = useState({});
  const [cadenceOptions, setCadenceOptions] = useState([]);

  useEffect(() => {
    if (!distributionType) {
      setCategoryOptions({});
      setMetricOptions({});
      setCadenceOptions([]);
      return;
    }

    if (!metadataResponse?.data) return;

    const { category: categoryKey } = DISTRIBUTION_CONFIG[distributionType];

    const categories = metadataResponse?.data?.[categoryKey] ?? {};
    const defaultCategory = Object.values(categories)?.[0] ?? {};
    setCategoryOptions(categories);
    setSelectedCategory(defaultCategory?.id);

    const metrics = defaultCategory?.metrics ?? {};
    const defaultMetric = Object.values(metrics)?.[0] ?? {};
    setMetricOptions(metrics);
    setSelectedMetric(defaultMetric?.id);

    const cadences = defaultMetric?.cadences ?? [];
    const defaultCadence = cadences?.[0] ?? null;
    setCadenceOptions(cadences);
    setSelectedTimeframeID(defaultCadence);

    const comparisonTimeFrames = getComparisonTimeFrames(defaultCadence);
    const defaultComparisonTimeFrameId = comparisonTimeFrames?.[0]?.id ?? null;
    setComparisonTimeFrameId(defaultComparisonTimeFrameId);
  }, [metadataResponse?.data, distributionType]);

  useEffect(() => {
    if (dataResponse && distributionType && categoryOptions && metricOptions) {
      trackTerritoryOverviewOptionSet(
        '',
        '',
        '',
        [
          getTimeframeName(selectedTimeframeID),
          distributionType,
          categoryOptions[selectedCategory]?.label,
          metricOptions[selectedMetric]?.label,
        ],
        true
      );
    }
  }, [dataResponse]);

  const [selectedSupplyRows, setSelectedSupplyRows] = useState([]);
  const updateSelectedSupplyRows = (newSelection) =>
    setSelectedSupplyRows(newSelection);

  const [distributionTypesAvailable, setDistributionTypesAvailable] = useState({
    supply: false,
    demand: false,
  });

  useEffect(() => {
    setDistributionTypesAvailable({
      supply: metadataResponse?.data?.availableDistributions?.includes(
        Distribution.SUPPLY
      ),
      demand: metadataResponse?.data?.availableDistributions?.includes(
        Distribution.DEMAND
      ),
    });
  }, [metadataResponse?.data]);

  const updateSelectedMetric = (newMetric) => {
    trackTerritoryOverviewOptionSet(
      'Metric',
      metricOptions[newMetric].label,
      metricOptions[selectedMetric]?.label,
      [
        getTimeframeName(selectedTimeframeID),
        distributionType,
        categoryOptions[selectedCategory]?.label,
        metricOptions[newMetric].label,
      ],
      false
    );

    setSelectedMetric(newMetric);

    setCadenceOptions(metricOptions[newMetric]?.cadences);

    setSelectedTimeframeID((prevCadence) => {
      const newTimeframe = metricOptions[newMetric]?.cadences.includes(
        prevCadence
      )
        ? prevCadence
        : metricOptions[newMetric]?.cadences[0];

      handleComparisonTimeFrameChange(newTimeframe);

      return newTimeframe;
    });
  };

  const handleComparisonTimeFrameChange = (cadence: Cadence) => {
    const comparisonTimeFrames = getComparisonTimeFrames(cadence);
    const isOutdatedComparisonTimeFrameId =
      comparisonTimeFrames &&
      !comparisonTimeFrames.some(
        (comparisonTimeFrame) =>
          comparisonTimeFrame.id === comparisonTimeFrameId
      );
    if (isOutdatedComparisonTimeFrameId) {
      const newComparisonTimeFrameId = comparisonTimeFrames?.[0]?.id ?? null;
      setComparisonTimeFrameId(newComparisonTimeFrameId);
    }
  };

  return {
    projectId,
    regionId: maptualListId,
    projectName,
    territoryName,
    data: dataResponse,
    metadata: metadataResponse,
    metadataStatus,
    metricOptions,
    categoryOptions,
    status,
    isDataLoading,
    isDataError,
    isMetadataLoading: isMetadataLoading || isMaptualListsLoading,
    selectedCategory,
    updateSelectedCategory,
    setSelectedCategory,
    selectedMetric,
    updateSelectedMetric,
    setSelectedMetric,
    selectedTimeframeID,
    setSelectedTimeframeID,
    selectedSupplyRows,
    updateSelectedSupplyRows,
    distributionType,
    updateDistributionType,
    distributionTypesAvailable,
    cadenceOptions,
    comparisonTimeFrameId,
    setComparisonTimeFrameId,
    handleComparisonTimeFrameChange,
  };
};
