import { useContext } from 'react';
import { FieldContext } from './fieldContext';
import { NavigationContentWrapper } from '../shared/NavigationContentWrapper';

export const FieldContentWrapper = ({ children }) => {
  const { navigationOpen, setNavigationOpen } = useContext(FieldContext);

  return (
    <NavigationContentWrapper
      isNavigationOpen={navigationOpen}
      setIsNavigationOpen={setNavigationOpen}
    >
      {children}
    </NavigationContentWrapper>
  );
};
