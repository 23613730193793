import { PREDICTION_PRECISION } from '../components/fieldView/trendValues';

export function getColorFromTrend({ status, value }, themeColors) {
  if (
    status === PREDICTION_PRECISION.INCREASING_WITHIN_RANGE ||
    status === PREDICTION_PRECISION.INCREASING_OUTSIDE_RANGE
  )
    return themeColors.increaseColorMain;
  if (
    status === PREDICTION_PRECISION.DECREASING_WITHIN_RANGE ||
    status === PREDICTION_PRECISION.DECREASING_OUTSIDE_RANGE
  )
    return themeColors.decreaseColorMain;
  if (
    status === PREDICTION_PRECISION.NO_CHANGE ||
    status === PREDICTION_PRECISION.CANNOT_PREDICT
  )
    return themeColors.tertiaryColor;

  if (typeof value !== 'number' || value === 0)
    return themeColors.tertiaryColor;
  return value > 0
    ? themeColors.increaseColorMain
    : themeColors.decreaseColorMain;
}
