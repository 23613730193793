import PowerscoreChart from './reports/charts/powerscoreChart';
import CpsChart from './reports/charts/cpsChart';
import DedupeChart from './reports/charts/dedupeChart';

export const CURATION_GRAPHS = {
  POWERSCORE: 'powerscore',
  CPS: 'cps',
  DEDUPE: 'dedupe',
};

export const CURATION_CHARTS = [
  {
    title: 'PowerScore',
    type: CURATION_GRAPHS.POWERSCORE,
    component: PowerscoreChart,
  },
  {
    title: 'Customer Guided Segments',
    type: CURATION_GRAPHS.CPS,
    component: CpsChart,
  },
  {
    title: 'Dedupe Breakdown',
    type: CURATION_GRAPHS.DEDUPE,
    component: DedupeChart,
  },
];

export const TABS_TO_SHOW = [{ title: 'Administration' }]; // TODO: Add 'Reports' tab back,  { title: 'Reports' }
