export const translateJsonToSchema = (schemaName, description, jsonData) => {
  const schema = {
    title: schemaName,
    description,
    properties: {},
    type: 'object',
  };
  Object.entries(jsonData).forEach(([key, value]) => {
    schema.properties[key] = handleTranslation(value, key);
  });
  return schema;
};

function handleTranslateObject(config, keyName, includeTitle = true) {
  const defaultObjectProperties = {
    type: 'object',
    description: 'TODO: Add description here',
    properties: {},
  };
  if (includeTitle) {
    defaultObjectProperties.title = keyName;
  }

  Object.entries(config).forEach(([key, value]) => {
    defaultObjectProperties.properties[key] = handleTranslation(value, key);
  });
  return defaultObjectProperties;
}

const handleTranslation = (config, keyName) => {
  const type = Array.isArray(config) ? 'array' : typeof config;

  switch (type) {
    case 'boolean':
    case 'number':
    case 'string':
      return {
        type,
        description: '',
        title: keyName,
      };
    case 'object':
      return handleTranslateObject(config, keyName);
    case 'array':
      return handleTranslateArray(config, keyName);
    default:
      return {};
  }
};

const handleTranslateArray = (config, keyName) => {
  const itemType = Array.isArray(config[0]) ? 'array' : typeof config[0];
  switch (itemType) {
    case 'boolean':
    case 'number':
    case 'string':
      return {
        type: 'array',
        title: keyName,
        description: 'TODO: Add description here',
        items: {
          type: itemType,
          description: '',
        },
      };
    case 'object':
      return {
        type: 'array',
        description: 'TODO: Add description here',
        title: keyName,
        items: {
          ...handleTranslateObject(config[0], keyName, false),
        },
      };
    default:
      return {};
  }
};
