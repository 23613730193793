import { SyntheticEvent } from 'react';
import { Accordion, AccordionSummary, Box, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import {
  ContributionBreakdownResponse,
  EntityType,
} from '@odaia/clients/maptualAPI';
import { CONTRIBUTION_TYPE } from './constants';
import { ContributionBreakdownContent } from './ContributionBreakdownContent';

export const ContributionBreakdown = ({
  contributionData,
  expanded,
  setExpanded,
  entityType,
}: {
  contributionData: ContributionBreakdownResponse;
  expanded: CONTRIBUTION_TYPE | false;
  setExpanded: (value: CONTRIBUTION_TYPE | false) => void;
  entityType: EntityType;
}) => {
  const handleAccordionChange =
    (panel: CONTRIBUTION_TYPE) =>
    (_event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Box data-testid="contribution-breakdown">
      <StyledAccordion
        disableGutters
        elevation={0}
        expanded={expanded === CONTRIBUTION_TYPE.CURRENT}
        onChange={handleAccordionChange(CONTRIBUTION_TYPE.CURRENT)}
      >
        <StyledAccordionSummary expandIcon={<ExpandIcon />}>
          <CurrentValueCircle />
          <Typography variant="labelSm" fontWeight={500}>
            Current Value
          </Typography>
          <Typography variant="labelSm">
            {Math.round(contributionData.currentValue.value)}%
          </Typography>
        </StyledAccordionSummary>
        <ContributionBreakdownContent
          content={contributionData.currentValue}
          category={CONTRIBUTION_TYPE.CURRENT}
          entityType={entityType}
        />
      </StyledAccordion>
      <StyledAccordion
        disableGutters
        elevation={0}
        expanded={expanded === CONTRIBUTION_TYPE.POTENTIAL}
        onChange={handleAccordionChange(CONTRIBUTION_TYPE.POTENTIAL)}
      >
        <StyledAccordionSummary expandIcon={<ExpandIcon />}>
          <PotentialCircle />
          <Typography variant="labelSm" fontWeight={500}>
            Potential
          </Typography>
          <Typography variant="labelSm">
            {Math.round(contributionData.potential.value)}%
          </Typography>
        </StyledAccordionSummary>
        <ContributionBreakdownContent
          content={contributionData.potential}
          category={CONTRIBUTION_TYPE.POTENTIAL}
          entityType={entityType}
        />
      </StyledAccordion>
    </Box>
  );
};

const StyledAccordion = styled(Accordion)(
  ({ theme: { themeColors }, expanded }) => ({
    backgroundColor: expanded
      ? themeColors.utilityActiveSurfaceColor
      : 'transparent',
    border: `1px solid ${themeColors.borderPrimaryColor}`,

    '&:not(:last-child)': {
      borderBottom: 0,
    },

    '&::before': {
      display: 'none',
    },
  })
);

const StyledAccordionSummary = styled(AccordionSummary)(
  ({ theme: { spacing } }) => ({
    padding: `0 ${spacing(1.5)}`,

    '& .MuiAccordionSummary-content': {
      display: 'flex',
      gap: spacing(1),
      alignItems: 'center',
    },
  })
);

const Circle = styled(Box)({
  height: 12,
  width: 12,
  borderRadius: '50%',
});

const CurrentValueCircle = styled(Circle)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.dataGeneralColor3,
}));

const PotentialCircle = styled(Circle)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.dataGeneralColor,
}));

const ExpandIcon = styled(ExpandMoreIcon)(() => ({
  height: 16,
  width: 16,
}));
