import { useQuery } from 'react-query';
import { getProjectObjectives } from '../../../../../request/projectRequests';

export const useObjectives = (projectId) =>
  useQuery(projectId, async () => {
    try {
      const response = await getProjectObjectives({ projectId });
      return response.data;
    } catch {
      return [] satisfies Record<string, unknown>[];
    }
  });
