import { styled, Skeleton } from '@mui/material';

const StyledSkeletonBar = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  height: 100,
  backgroundColor: themeColors.surfaceEmpty,
}));

export const DrawerProfileSkeleton = () => (
  <StyledSkeletonBar animation="wave" />
);
