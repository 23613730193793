import { useContext, useMemo, useState } from 'react';
import { Autocomplete, Box, Button, Typography, styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import { isEqual } from 'lodash';
import {
  MutateOptions,
  UseMutateAsyncFunction,
  useQueryClient,
} from 'react-query';
import { AxiosResponse } from 'axios';
import { SetState } from '@odaia/domain/src/utilityTypes';
import {
  AttachProductLineResponse,
  CustomerMarketInput,
  CustomerMarketOutput,
  CustomerMarketsApiCreateMarketRequest,
  CustomerMarketsApiUpdateMarketRequest,
  CustomerProductLinesApiAttachProductTreeToMarketRequest,
} from '@odaia/clients/configAPI';
import { formatMarketUpdateRequest } from './formatUserGroupConfigUpdateRequest';
import { TextFieldWithChipsOutside } from './textFieldWithChipsOutside';
import { MarketScoringWeightsTable } from './marketScoringWeightsTable';
import { FloatingButtonWrapper, LoadingIcon } from './styledComponents';
import { CONFIG_VIEW_MODE } from './userGroupConfigConstants';
import { ConfigViewContext } from './configViewContext';
import { ModalSectionHeader } from '../generic/modalSectionHeader';

const FlexColumnBase = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const PrimaryText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.adminCardSubheader,
}));

const SecondaryText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
  fontSize: 16,
}));

const TextWrapper = styled(FlexColumnBase)({
  rowGap: 4,
});

const TitleWrapper = styled(TextWrapper)({
  marginBottom: 16,
});

const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
});

const RowWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

const FlexRowWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
});

export const StyledTextField = styled(TextField)(
  ({ isError = false, theme: { themeColors } }) => ({
    width: '100%',
    '& label.Mui-focused': {
      color: isError ? themeColors.danger60 : themeColors.neutral60,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `1px solid ${
          isError ? themeColors.danger60 : themeColors.neutral60
        }`,
      },
    },
    '& input::placeholder': {
      opacity: 1,
    },
    '.MuiFormHelperText-root.Mui-error': {
      color: themeColors.danger60,
    },
    '.MuiFormHelperText-root ': {
      color: themeColors.neutral60,
    },
  })
);

const ShortTextField = styled(StyledTextField)({
  width: 268,
});

const StyledButton = styled(Button)(({ theme: { themeColors } }) => ({
  height: 32,
  '&.MuiButton-outlined': { padding: '8px 12px' },
  '&:disabled': {
    backgroundColor: themeColors.contentCardAltBackgroundColor,
  },
}));

function basketsAreAbleToBeSaved(
  selectedBasketsScoringWeight,
  basketsScoringWeight,
  selectedMetricsScoringWeight,
  metricsScoringWeight,
  scoringTableError
) {
  return (
    (!isEqual(selectedBasketsScoringWeight, basketsScoringWeight) ||
      !isEqual(selectedMetricsScoringWeight, metricsScoringWeight)) &&
    !scoringTableError
  );
}

function validSelectionMade(
  selectedMarketName,
  marketName,
  selectedRegionalTeamId,
  regionalTeamId,
  selectedSpecialties,
  specialties,
  selectedTherapeuticArea,
  therapeuticArea,
  selectedIndications,
  indications
) {
  return (
    selectedMarketName !== '' &&
    selectedRegionalTeamId !== '' &&
    (selectedMarketName !== marketName ||
      selectedRegionalTeamId !== regionalTeamId ||
      !isEqual(selectedSpecialties, specialties) ||
      selectedTherapeuticArea !== therapeuticArea ||
      !isEqual(selectedIndications, indications))
  );
}

type MarketEditProps = {
  marketBeingEdited: CustomerMarketInput;
  setMarketBeingEdited: SetState<CustomerMarketInput | null>;
  productTreeOptions: { label: any; value: any }[];
  createMarket: UseMutateAsyncFunction<
    AxiosResponse<CustomerMarketOutput, any>,
    unknown,
    Omit<CustomerMarketsApiCreateMarketRequest, 'userGroup'>,
    unknown
  >;
  updateMarket: UseMutateAsyncFunction<
    AxiosResponse<any, any>,
    unknown,
    Omit<CustomerMarketsApiUpdateMarketRequest, 'userGroup'>,
    unknown
  >;
  attachProductLine: (
    variables: Omit<
      CustomerProductLinesApiAttachProductTreeToMarketRequest,
      'userGroup'
    >,
    options?: MutateOptions<
      AxiosResponse<AttachProductLineResponse>,
      unknown,
      Omit<
        CustomerProductLinesApiAttachProductTreeToMarketRequest,
        'userGroup'
      >,
      unknown
    >
  ) => Promise<AxiosResponse<AttachProductLineResponse>>;
  isCreateLoading: boolean;
  isUpdateLoading: boolean;
  isAttachProductLineLoading: boolean;
};

export default function MarketEdit({
  marketBeingEdited,
  setMarketBeingEdited,
  productTreeOptions,
  createMarket,
  updateMarket,
  attachProductLine,
  isCreateLoading,
  isUpdateLoading,
  isAttachProductLineLoading,
}: MarketEditProps) {
  const queryClient = useQueryClient();
  const {
    market: marketName,
    marketId,
    productLines,
    regionalTeamId,
    specialty: specialties,
    therapeuticArea,
    indications,
    basketsScoringWeight,
    metricsScoringWeight,
  } = marketBeingEdited;
  const productLineId = productLines[0]?.productLineId;
  const [selectedMarketName, setSelectedMarketName] = useState(marketName);
  const [wasMarketNameUpdated, setWasMarketNameUpdated] = useState(false);
  const [selectedSpecialties, setSpecialties] = useState(specialties);
  const [selectedTherapeuticArea, setSelectedTherapeuticArea] =
    useState(therapeuticArea);
  const [selectedIndications, setSelectedIndications] = useState(indications);
  const [selectedRegionalTeamId, setSelectedRegionalTeamId] =
    useState(regionalTeamId);
  const [wasRegionalTeamIdUpdated, setWasRegionalTeamIdUpdated] =
    useState(false);

  const [selectedBasketsScoringWeight, setSelectedBasketsScoringWeight] =
    useState(basketsScoringWeight);
  const [selectedMetricsScoringWeight, setSelectedMetricsScoringWeight] =
    useState(metricsScoringWeight);
  const [selectedProductLine, setSelectedProductLine] = useState<{
    label: any;
    value: any;
  } | null>(null);

  const { configViewMode, setConfigViewMode } = useContext(ConfigViewContext);

  const [scoringTableError, setScoringTableError] = useState(false);

  const isFormDataValidAndChanged = useMemo(
    () =>
      validSelectionMade(
        selectedMarketName,
        marketName,
        selectedRegionalTeamId,
        regionalTeamId,
        selectedSpecialties,
        specialties,
        selectedTherapeuticArea,
        therapeuticArea,
        selectedIndications,
        indications
      ) ||
      basketsAreAbleToBeSaved(
        selectedBasketsScoringWeight,
        basketsScoringWeight,
        selectedMetricsScoringWeight,
        metricsScoringWeight,
        scoringTableError
      ),
    [
      selectedMarketName,
      selectedRegionalTeamId,
      selectedSpecialties,
      selectedTherapeuticArea,
      selectedIndications,
      selectedBasketsScoringWeight,
      selectedMetricsScoringWeight,
      scoringTableError,
    ]
  );
  const handleBasketsWeightSelectionChange = (newValue) => {
    setSelectedBasketsScoringWeight(newValue);
  };
  const handleMetricsWeightSelectionChange = (newValue) => {
    setSelectedMetricsScoringWeight(newValue);
  };

  const handleScoringTableError = (newValue) => {
    setScoringTableError(newValue);
  };

  const handleMarketNameChange = (event) => {
    setSelectedMarketName(event.target.value);
    setWasMarketNameUpdated(true);
  };

  const handleRegionalTeamIdChange = (event) => {
    setSelectedRegionalTeamId(event.target.value);
    setWasRegionalTeamIdUpdated(true);
  };

  const handleCancel = () => {
    setMarketBeingEdited(null);
    setConfigViewMode(CONFIG_VIEW_MODE.VIEW);
  };

  const handleSave = async () => {
    const requestBody = {
      market: selectedMarketName,
      regionalTeamId: selectedRegionalTeamId,
      specialty: selectedSpecialties,
      therapeuticArea: selectedTherapeuticArea,
      indications: selectedIndications,
      metricsScoringWeight: selectedMetricsScoringWeight,
      basketsScoringWeight: selectedBasketsScoringWeight,
    };

    let editedMarket = marketBeingEdited;
    if (isFormDataValidAndChanged) {
      if (configViewMode === CONFIG_VIEW_MODE.CREATE) {
        const response = await createMarket({
          createCustomerMarketRequest: requestBody,
        });
        editedMarket = response.data;
      } else {
        await updateMarket({
          customerMarketId: marketId,
          customerMarketInput: formatMarketUpdateRequest(
            marketBeingEdited,
            requestBody
          ),
        });
      }
    }
    if (selectedProductLine && editedMarket) {
      await attachProductLine({
        productLineId: selectedProductLine.value,
        attachProductTreePostRequest: {
          targetCustomerMarketId: editedMarket.marketId,
        },
      });
    }
    await queryClient.refetchQueries('markets');
    handleCancel();
  };

  const hasProductTreeAttached = marketBeingEdited?.productLines.length > 0;

  return (
    <Box>
      <FloatingButtonWrapper>
        <StyledButton onClick={handleCancel} variant="outlined">
          Cancel
        </StyledButton>
        <StyledButton
          variant="contained"
          disabled={!(isFormDataValidAndChanged || !!selectedProductLine)}
          onClick={handleSave}
        >
          {configViewMode === CONFIG_VIEW_MODE.CREATE &&
            (isCreateLoading || isAttachProductLineLoading ? (
              <>
                Creating
                <LoadingIcon size="11px" />
              </>
            ) : (
              'Create and Save'
            ))}
          {configViewMode === CONFIG_VIEW_MODE.EDIT &&
            (isUpdateLoading || isAttachProductLineLoading ? (
              <>
                Saving
                <LoadingIcon size="11px" />
              </>
            ) : (
              'Save'
            ))}
        </StyledButton>
      </FloatingButtonWrapper>
      <TitleWrapper>
        <PrimaryText variant="h5">Template Information</PrimaryText>
        <SecondaryText variant="body2">
          Assign a name and regional team ID to this template
        </SecondaryText>
      </TitleWrapper>
      <ContentWrapper>
        <RowWrapper>
          <StyledTextField
            label="Template Name"
            placeholder="Add Template Name"
            value={selectedMarketName}
            onChange={handleMarketNameChange}
            isError={selectedMarketName === '' && wasMarketNameUpdated}
            focused
            required
          />
          <FlexRowWrapper>
            <StyledTextField
              label="Regional Team ID"
              placeholder="Add Regional Team ID"
              value={selectedRegionalTeamId}
              onChange={handleRegionalTeamIdChange}
              isError={
                selectedRegionalTeamId === '' && wasRegionalTeamIdUpdated
              }
              focused
              required
            />
            <StyledTextField
              label="Product Line ID"
              focused
              value={productLineId || 'Save Customer Market to Generate'}
              disabled
            />
          </FlexRowWrapper>
        </RowWrapper>
        <RowWrapper>
          <TextWrapper>
            <PrimaryText variant="h5">Total Specialties</PrimaryText>
          </TextWrapper>
          <TextFieldWithChipsOutside
            selectedItems={selectedSpecialties || []}
            setSelectedItems={setSpecialties}
            inputLabel="Add Specialties"
          />
        </RowWrapper>
        <RowWrapper>
          <TextWrapper>
            <PrimaryText variant="h5">Total Therapeutic Areas</PrimaryText>
          </TextWrapper>
          <ShortTextField
            placeholder="Add Therapeutic Area"
            value={selectedTherapeuticArea}
            onChange={(event) => {
              setSelectedTherapeuticArea(event.target.value || null);
            }}
            focused
          />
        </RowWrapper>
        <RowWrapper>
          <TextWrapper>
            <PrimaryText variant="h5">Total Indications</PrimaryText>
          </TextWrapper>
          <TextFieldWithChipsOutside
            selectedItems={selectedIndications}
            setSelectedItems={setSelectedIndications}
            inputLabel="Add Indications"
          />
        </RowWrapper>
        <MarketScoringWeightsTable
          basketsScoringWeight={selectedBasketsScoringWeight}
          metricsScoringWeight={selectedMetricsScoringWeight}
          onBasketsScoringWeightChange={handleBasketsWeightSelectionChange}
          onMetricsScoringWeightChange={handleMetricsWeightSelectionChange}
          setIsError={handleScoringTableError}
          isEdit
        />
        {!hasProductTreeAttached && (
          <>
            <ModalSectionHeader
              title="Product tree"
              subtitle="Attach a product tree to this template. You can add this later, but your market will not be usable until a product tree has been attached"
            />
            <Autocomplete
              value={selectedProductLine}
              options={productTreeOptions}
              onChange={(e, newValue) => setSelectedProductLine(newValue)}
              renderInput={(params) => (
                <ShortTextField {...params} placeholder="Select product tree" />
              )}
            />
          </>
        )}
      </ContentWrapper>
    </Box>
  );
}
