import { AxiosInstance, AxiosResponse } from 'axios';
import { Configuration } from '@odaia/clients/maptualAPI';

export const unwrapResponseData =
  <ResponseType, Args extends any[]>(
    fn: (...args: Args) => Promise<AxiosResponse<ResponseType>>,
    ...args: Args
  ) =>
  async () => {
    const response = await fn(...args);
    return response.data;
  };

export const apiInstanceCreator =
  (basePath: string, axiosInstance: AxiosInstance) =>
  <T>(ApiClass: {
    new (
      configuration?: Configuration,
      basePath?: string,
      axios?: AxiosInstance
    ): T;
  }) =>
    new ApiClass(undefined, basePath, axiosInstance);
