import { Skeleton } from '@mui/material';

import { useQuery } from 'react-query';
import AutomatedReportBadge from './automatedReportBadge';
import { getTestSuiteStatus } from '../../../../../request/projectRequests';

export default function AutomatedTestResults({ project }) {
  const { isLoading: isTestSuiteStatusLoading, data: testSuiteStatusResponse } =
    useQuery([`quality-report-checks`], () => getOverallTestSuiteStatuses());

  const projectTestResults =
    testSuiteStatusResponse?.data?.projectsStatus[project.projectId];

  return (
    <>
      {isTestSuiteStatusLoading && (
        <Skeleton variant="rounded" animation="wave" height={32} />
      )}
      {testSuiteStatusResponse &&
        ('errorMessage' in testSuiteStatusResponse ? (
          <AutomatedReportBadge
            status="warning"
            tooltipMsg="Error"
            message={testSuiteStatusResponse?.errorMessage}
          />
        ) : (
          <AutomatedReportBadge
            status={projectTestResults?.status}
            tooltipMsg={formatToolTipMessage(projectTestResults?.failedTests)}
            message={formatMessage(
              projectTestResults?.totalPassedTests,
              projectTestResults?.totalTestSuites
            )}
          />
        ))}
    </>
  );
}

const formatToolTipMessage = (failedTests) =>
  failedTests?.length === 0
    ? 'All test suites passed'
    : `${formatFailedTests(failedTests)} failed`;

const formatMessage = (totalPassedTests, totalTestSuites) => {
  if (totalTestSuites === 0) {
    return 'No test suites have been run';
  }

  return `${totalPassedTests}/${totalTestSuites} test suites passed`;
};

const formatFailedTests = (failedTests) => {
  if (failedTests?.length === 2) return failedTests?.join(' & ');

  const lastTwoFailedTests = failedTests?.slice(-2);
  const remainingFailedTests = failedTests?.slice(0, -2);

  return [...remainingFailedTests, lastTwoFailedTests.join(' & ')].join(', ');
};

const getOverallTestSuiteStatuses = async () => {
  const statuses = await getTestSuiteStatus();
  return statuses;
};
