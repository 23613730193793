import { useContext } from 'react';
import { styled, Tab, Tabs } from '@mui/material';
import { useHistory } from 'react-router-dom';
import TableViewContainer from './tableview/tableViewContainer';
import DashboardView from './dashboardview/dashboardView';
import { TAB_PATHS } from './constants';
import { overwriteURLQueryParam } from './helpers';
import { AppContext } from '../../containers/application/appContext';
import { ObjectiveContext } from './contexts/objectiveContext';
import SegmentNav from './segmentNav';
import useFeatureToggles from '../../hooks/useFeatureToggles';
import toKebabCase from '../../utils/toKebabCase';

const hcpTab = {
  title: 'HCP VIEW',
  path: TAB_PATHS.TABLE,
  component: TableViewContainer,
};

const dashboardTab = {
  title: 'DASHBOARD',
  path: TAB_PATHS.DASHBOARD,
  component: DashboardView,
};

const ColumnFlex = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const RowFlex = styled('div')`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
  overflow-y: scroll;
`;

const TableContainer = styled('div')`
  height: 100%;
  width: 100%;
  padding: 16px;
`;

const CustomTabs = styled(Tabs)(({ theme: { themeColors } }) => ({
  marginBottom: 2,
  borderRadius: 0,
  minHeight: 52,
  '& .MuiTabs-indicator': {
    marginTop: 3,
    backgroundColor: themeColors.primaryColorDark,
  },
  borderBottom: '1px solid',
  borderColor: themeColors.mainSurfaceBackground,
}));

const CustomTab = styled(Tab)(({ theme: { themeColors } }) => ({
  marginRight: 15,
  height: 45,
  minHeight: 45,
  borderRadius: 8,
  color: themeColors.secondaryTextColor,
  '&.Mui-selected': {
    color: themeColors.primaryTextColor,
  },
}));

export default function ProjectTabContainer(props) {
  const routeHistory = useHistory();
  const {
    setTabView,
    selectedTab,
    viewer,
    setRegionSelection,
    setSegmentSelection,
  } = props;
  const { profile } = useContext(AppContext);
  const featureToggles = useFeatureToggles();
  const { setIsInTab } = useContext(ObjectiveContext);

  const tabsToShow = [];
  tabsToShow.push(hcpTab);
  if (featureToggles(profile.userGroup, 'showSphereDashboardTab')) {
    tabsToShow.push(dashboardTab);
  }

  return (
    <ColumnFlex style={{ paddingLeft: 30 }}>
      <CustomTabs data-testid="tab-switcher" value={selectedTab}>
        {tabsToShow.map((tab) => (
          <CustomTab
            data-testid="test-table-view-dashboard"
            className={`intercom-sphere-${toKebabCase(tab.title)}-button`}
            key={tab.title}
            icon={tab.icon}
            label={tab.title}
            value={tab.path}
            iconPosition="start"
            onClick={() => {
              setTabView(tab.path);
              setIsInTab(tab.path);
              const queryParam = overwriteURLQueryParam({
                queryParams: routeHistory.location.search,
                paramKey: 'tab',
                paramValue: tab.path,
              });
              routeHistory.push(
                `${routeHistory.location.pathname}?${queryParam}`
              );
            }}
          />
        ))}
      </CustomTabs>

      <RowFlex>
        <SegmentNav
          setRegionSelection={setRegionSelection}
          setSegmentSelection={setSegmentSelection}
          viewer={viewer}
        />
        {tabsToShow.map(
          (tab, i) =>
            tab.path === selectedTab && (
              <TableContainer key={`${tab.path}-${i}`}>
                <tab.component {...props} />
              </TableContainer>
            )
        )}
      </RowFlex>
    </ColumnFlex>
  );
}
