import { Skeleton } from '@mui/material';

import useProjectStatus, {
  ProjectRunStatusResponse,
} from '../hooks/useProjectStatus';
import RunningProjectStatus from './runningProjectStatusStatus';
import FailedProjectStatus from './failedProjectStatus';
import UnknownProjectStatus from './unknownProjectStatus';

export default function RealtimeProjectRunStatus({
  projectId,
}: {
  projectId: string;
}) {
  const {
    isLoading,
    data: projectRunStatus,
  }: { isLoading: boolean; data: ProjectRunStatusResponse } = useProjectStatus({
    projectId,
  });

  if (isLoading) {
    return <Skeleton variant="rounded" animation="wave" height={32} />;
  }

  switch (projectRunStatus.status) {
    case 'RUNNING':
    case 'SUCCEEDED':
      return <RunningProjectStatus status={projectRunStatus} />;
    case 'FAILED':
    case 'ABORTED':
      return <FailedProjectStatus status={projectRunStatus} />;
    default:
      return <UnknownProjectStatus status={projectRunStatus} />;
  }
}
