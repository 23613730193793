import { useContext, useEffect, useState } from 'react';
import { styled, Box } from '@mui/material';
import { FieldContext } from '../fieldContext';
import {
  ListSelectionItemButton,
  ListSelectionOptions,
  ListSelectionPopover,
  ListSelectionPopoverButton,
  ListSelectionPopoverContent,
} from './listSelectionComponents';
import { trackObjectiveSelected } from '../../../../../trackers/mixpanel';

export const ObjectiveSelectionWrapper = styled(Box)(
  ({ theme: { themeColors } }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: 40,
    backgroundColor: themeColors.buttonBackgroundColor,
    border: '1px solid',
    borderColor: themeColors.buttonBorderColor,
    borderRadius: 4,
  })
);

export const ObjectiveSelection = () => {
  const { maptualListObjectives, setObjective, objective } =
    useContext(FieldContext);

  const [activeObjectiveIndex, setActiveObjectiveIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const objectiveDropdownOpen = Boolean(anchorEl);
  const [selectionOptions, setSelectionOptions] = useState([]);

  useEffect(() => {
    setActiveObjectiveIndex(0);
    trackObjectiveSelected(
      'Objective 1',
      true,
      'PowerList Objective Selector Dropdown'
    );
    setSelectionOptions(maptualListObjectives ?? []);
    setObjective(maptualListObjectives?.length ? maptualListObjectives[0] : {});
  }, [maptualListObjectives]);

  useEffect(() => {
    if (!objective?.id && maptualListObjectives?.length) {
      setObjective(maptualListObjectives[0]);
    }
  }, [objective]);

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleObjectiveSelection = (objectiveIndex) => {
    setObjective(selectionOptions[objectiveIndex]);
    setActiveObjectiveIndex(objectiveIndex);

    trackObjectiveSelected(
      `Objective ${objectiveIndex + 1}`,
      false,
      'PowerList Objective Selector Dropdown'
    );
    handleDropdownClose();
  };

  const getPopoverTopPosition = () => {
    if (!anchorEl) return 0;
    return anchorEl.offsetTop + anchorEl.clientHeight;
  };

  const getPopoverLeftPosition = () => {
    if (!anchorEl) return 0;
    const parentOffset = anchorEl.offsetParent?.offsetLeft ?? 0;
    return anchorEl.offsetLeft + parentOffset;
  };

  const popoverId = objectiveDropdownOpen ? 'transitions-popper' : undefined;

  if (!maptualListObjectives?.length || maptualListObjectives.length < 2)
    return null;

  return (
    <ObjectiveSelectionWrapper
      className="intercom-field-objective-selector"
      data-testid="objective-selection"
    >
      <ListSelectionPopoverButton
        data-testid="objective-selection-popover-button"
        label={selectionOptions[activeObjectiveIndex]?.name}
        onClickAction={handleDropdownClick}
      />
      <ListSelectionPopover
        anchorTop={getPopoverTopPosition()}
        id={popoverId}
        open={objectiveDropdownOpen}
        onClose={handleDropdownClose}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: getPopoverTopPosition(),
          left: getPopoverLeftPosition(),
        }}
      >
        <ListSelectionPopoverContent data-testid="objective-selection-popover">
          <ListSelectionOptions selection={activeObjectiveIndex}>
            {maptualListObjectives.map((option, i) => (
              <ListSelectionItemButton
                value={i}
                className="test-objective-list"
                key={`objective-selection-${option.id}`}
                onClick={() => handleObjectiveSelection(i)}
              >
                {option.name}
              </ListSelectionItemButton>
            ))}
          </ListSelectionOptions>
        </ListSelectionPopoverContent>
      </ListSelectionPopover>
    </ObjectiveSelectionWrapper>
  );
};
