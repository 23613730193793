/* eslint-disable import/no-cycle */
import { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Content } from '../analysisview/content';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  dropdownStyle: {
    maxWidth: '20rem',
    marginBottom: '1rem',
  },
}));

export default function DropDownContent({ primaryText, dropDownItems, match }) {
  const classes = useStyles();

  const [selection, setSelection] = useState(0);
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (
      Array.isArray(dropDownItems) &&
      dropDownItems[0] &&
      dropDownItems[0].content
    ) {
      setContent(dropDownItems[0].content);
    }
  }, []);

  const handleChange = (event) => {
    setSelection(event.target.value);

    if (
      dropDownItems[event.target.value] &&
      dropDownItems[event.target.value].content
    )
      setContent(dropDownItems[event.target.value].content);
    else setContent(null);
  };

  return (
    <div className={classes.root}>
      <FormControl variant="outlined" className={classes.dropdownStyle}>
        <InputLabel id="dropdown-label">{primaryText}</InputLabel>
        <Select
          labelId="inputlabel"
          id="selectedItem"
          value={selection}
          onChange={handleChange}
          label={primaryText}
        >
          {Array.isArray(dropDownItems)
            ? dropDownItems.map((item, i) => (
                <MenuItem value={i} key={i}>
                  {item.primaryText}
                </MenuItem>
              ))
            : null}
        </Select>
      </FormControl>
      {content ? <Content content={content} match={match} /> : null}
    </div>
  );
}
