import { styled } from '@mui/material';
import React from 'react';

const StyledStethoscopeIconSVG = styled('svg')(
  ({ theme: { themeColors } }) => ({
    flexShrink: 0,
    height: 20,
    width: 20,
    '& path': {
      fill: themeColors.listIconColor,
    },
  })
);

const Stethoscope = () => (
  <>
    <mask
      id="mask0_470_1725"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="21"
      height="21"
    >
      <rect x="0.5" y="0.359863" width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_470_1725)">
      <path
        d="M11.6042 18.3599C10.2484 18.3599 9.09349 17.8808 8.13942 16.9227C7.18536 15.9646 6.70833 14.8047 6.70833 13.4432V12.8182C5.52778 12.6654 4.53125 12.1481 3.71875 11.2661C2.90625 10.3842 2.5 9.33209 2.5 8.10986V3.35986H5V2.35986H6.5V5.85986H5V4.85986H4V8.11945C4 9.01584 4.31597 9.78 4.94792 10.4119C5.57986 11.0439 6.34722 11.3599 7.25 11.3599C8.15278 11.3599 8.92014 11.0439 9.55208 10.4119C10.184 9.78 10.5 9.01584 10.5 8.11945V4.85986H9.5V5.85986H8V2.35986H9.5V3.35986H12V8.10986C12 9.28139 11.6424 10.3 10.9271 11.1656C10.2118 12.0312 9.30556 12.5682 8.20833 12.7765V13.464C8.20833 14.4131 8.5386 15.2198 9.19915 15.8842C9.85969 16.5485 10.6618 16.8807 11.6054 16.8807C12.549 16.8807 13.3507 16.5485 14.0104 15.8842C14.6701 15.2198 15 14.4131 15 13.464V12.2272C14.4861 12.0795 14.066 11.7904 13.7396 11.3599C13.4132 10.9293 13.25 10.4293 13.25 9.85986C13.25 9.16542 13.4931 8.57514 13.9792 8.08903C14.4653 7.60292 15.0556 7.35986 15.75 7.35986C16.4444 7.35986 17.0347 7.60292 17.5208 8.08903C18.0069 8.57514 18.25 9.16542 18.25 9.85986C18.25 10.4293 18.0868 10.9293 17.7604 11.3599C17.434 11.7904 17.0139 12.0821 16.5 12.2349V13.4432C16.5 14.8047 16.023 15.9646 15.0689 16.9227C14.1148 17.8808 12.9599 18.3599 11.6042 18.3599ZM15.75 10.8599C16.0333 10.8599 16.2708 10.764 16.4625 10.5724C16.6542 10.3807 16.75 10.1432 16.75 9.85986C16.75 9.57653 16.6542 9.33903 16.4625 9.14736C16.2708 8.9557 16.0333 8.85986 15.75 8.85986C15.4667 8.85986 15.2292 8.9557 15.0375 9.14736C14.8458 9.33903 14.75 9.57653 14.75 9.85986C14.75 10.1432 14.8458 10.3807 15.0375 10.5724C15.2292 10.764 15.4667 10.8599 15.75 10.8599Z"
        fill="#5E708D"
      />
    </g>
  </>
);

export const StethoscopeIcon = () => (
  <StyledStethoscopeIconSVG>
    <Stethoscope />
  </StyledStethoscopeIconSVG>
);
