import { useQuery } from 'react-query';
import { EntityRuleInsight } from '@odaia/domain/src/types';
import { maptualApiInstance } from '../../../../../../request/config';

export const useEntityRuleInsights = ({
  regionId,
  productLineId,
  projectId,
  objectiveId,
}) =>
  useQuery({
    queryKey: ['entity-rule-insights', regionId, objectiveId],
    queryFn: () =>
      getEntityRuleInsights(productLineId, projectId, regionId, objectiveId),
    enabled: !!regionId && !!objectiveId && !!projectId,
  });

export const getEntityRuleInsights = (
  productLineId,
  projectId,
  regionId,
  objectiveId
) =>
  maptualApiInstance.get<Record<string, EntityRuleInsight>>(
    `core/product-lines/${productLineId}/projects/${projectId}/regions/${regionId}/objectives/${objectiveId}/entities/insights`,
    {
      timeout: 20000,
    }
  );
