import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { styled, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import toUpper from 'lodash/toUpper';
import without from 'lodash/without';
import { memo } from 'react';
import TableView from './tableview/tableView';

const ContentRoot = styled(Box)(({ theme: { themeColors } }) => ({
  width: '100%',
  height: '100%',
  margin: 0,
  padding: 0,
  paddingTop: 30,
  alignItems: 'flex-start',
  display: 'flex',
  paddingLeft: '3vw',
  paddingRight: '3vw',
  flexDirection: 'column',
  backgroundColor: themeColors.mainBackground,
}));

const SearchResult = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: 30,
  marginBottom: 15,
  borderRadius: 5,
  backgroundColor: themeColors.menuTextColor,
  paddingLeft: 15,
  paddingRight: 15,
  height: 38,
}));

const SearchResultText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.menuTextColorContrasting,
}));

const NoResultsFound = styled('div')({
  display: 'flex',
  color: '#7d7d7d',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  flex: 1,
});

const SearchTableContainer = styled('div')({
  flexGrow: 1,
  width: '100%',
  margin: '10px 0',
});

const SearchResults = ({
  columns,
  hcpList,
  keyword,
  onCloseSearch,
  columnVisibilityModel,
  columnOrderModel,
}) => {
  const allColumns = columns.map((column) => column.field);
  const filteredOutColumns = Object.keys(columnVisibilityModel).filter(
    (columnName) => columnVisibilityModel[columnName] === false
  );

  const columnsToSearch = without(allColumns, ...filteredOutColumns);
  const filteredList = hcpList.filter((hcp) => {
    const matchingValue = columnsToSearch.find((column) =>
      includes(toUpper(hcp[column]), toUpper(keyword))
    );

    return !isEmpty(matchingValue);
  });

  return (
    <ContentRoot>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Button
          startIcon={<ArrowBackIosNewIcon />}
          variant="contained"
          color="primary"
          onClick={onCloseSearch}
        >
          BACK
        </Button>
        <SearchResult data-testid="test-search-result">
          <SearchResultText style={{ fontWeight: 800, marginRight: 5 }}>
            {filteredList.length}
          </SearchResultText>
          <SearchResultText noWrap>{' search result(s) for '}</SearchResultText>
          <SearchResultText style={{ fontWeight: 800, marginLeft: 5 }}>
            {keyword}
          </SearchResultText>
        </SearchResult>
      </Stack>
      {filteredList.length > 0 ? (
        <SearchTableContainer>
          <TableView
            columns={columns}
            rows={filteredList}
            columnOrderModel={columnOrderModel}
            columnVisibilityModel={columnVisibilityModel}
            disableMultipleSelection
          />
        </SearchTableContainer>
      ) : (
        <NoResultsFound>
          Sorry, We didn't find what you were looking for.
        </NoResultsFound>
      )}
    </ContentRoot>
  );
};

SearchResults.propTypes = {
  columnVisibilityModel: PropTypes.object,
  columnOrderModel: PropTypes.array,
  hcpList: PropTypes.array,
  keyword: PropTypes.string,
  onCloseSearch: PropTypes.func,
};

export default memo(SearchResults);
