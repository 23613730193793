import React from "react";
import { styled, Typography } from "@mui/material";
import {
  availabilityOptions,
  userCreatedListOptions,
} from "../../userCreatedListsConfig";
import FilterSwitchSelection from "./filterSwitchSelection";
import { FilterSection } from "./filterSidebar";
import { Filters } from "../mapView";

const SectionTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryMaptualListFilteringColor,
}));

const SectionBody = styled("div")(() => ({
  marginTop: "32px",
  width: "100%",
}));

const AvailabilitySwitches = styled(SectionBody)(() => ({
  display: "flex",
  gap: "16px",
  flexDirection: "column",
}));

export const CustomListFilter = ({
  filters,
  setFilters,
}: {
  filters: Filters;
  setFilters: (arg0: Filters) => void;
}) => {
  return (
    <>
      <FilterSection>
        <SectionTitle variant="h4">
          {userCreatedListOptions.starred.label}
        </SectionTitle>
        <SectionBody>
          <FilterSwitchSelection
            selectedFilters={filters}
            setSelectedFilters={setFilters}
            switchDescription="Only show HCPs that I starred"
            switchId={userCreatedListOptions.starred.id}
          />
        </SectionBody>
      </FilterSection>

      <FilterSection>
        <SectionTitle variant="h4">Availability Options</SectionTitle>
        <AvailabilitySwitches>
          {availabilityOptions.map((option) => (
            <FilterSwitchSelection
              key={option.id}
              selectedFilters={filters}
              setSelectedFilters={setFilters}
              switchDescription={option.switchDescription}
              switchId={option.id}
            />
          ))}
        </AvailabilitySwitches>
      </FilterSection>
    </>
  );
};
