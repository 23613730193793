import reduceReducers from 'reduce-reducers';
import loginReducer, { loginInitialState } from '../slices/login';
import adminViewReducer, { adminViewInitialState } from '../slices/adminView';
import appReducer, { appInitialState } from '../slices/app';

export default reduceReducers(
  {
    ...appInitialState,
    ...loginInitialState,
    ...adminViewInitialState,
  },
  appReducer,
  loginReducer,
  adminViewReducer
);
