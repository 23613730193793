import { useEffect, useState } from 'react';
import { Tab, Tabs, Typography, styled } from '@mui/material';
import { useConfigViewContext } from './configViewContext';
import { CONFIG_VIEW_MODE } from './userGroupConfigConstants';

export const DEFAULT_TABS = {
  SEGMENTS: 'Customer Segments',
  MARKETS: 'Customer Markets',
  METRICS: 'Metrics',
  TYPES: 'Objective Types',
  PRODUCT_TREE: 'Product Tree',
};

const LabelTypography = styled(Typography)({
  padding: 0,
});

const StyledTab = styled(Tab)(({ theme: { themeColors } }) => ({
  alignItems: 'start',
  textTransform: 'none',
  padding: 8,
  paddingLeft: 24,
  minHeight: 0,
  '&.Mui-selected': {
    color: themeColors.primaryTextColor,
  },
}));

export const ConfigTabs = ({
  initializeTabSelection,
  handleTabChange = () => {},
}) => {
  const { setConfigViewMode } = useConfigViewContext();
  const [selectedTab, setSelectedTab] = useState(DEFAULT_TABS.SEGMENTS);

  useEffect(() => {
    initializeTabSelection(DEFAULT_TABS.SEGMENTS);
  }, []);

  return (
    <Tabs orientation="vertical" value={selectedTab}>
      {Object.values(DEFAULT_TABS).map((tab) => (
        <StyledTab
          key={tab}
          value={tab}
          label={<LabelTypography variant="body2">{tab}</LabelTypography>}
          onClick={() => {
            if (selectedTab === tab) {
              return;
            }
            setConfigViewMode(CONFIG_VIEW_MODE.VIEW);
            setSelectedTab(tab);
            handleTabChange(tab);
          }}
        />
      ))}
    </Tabs>
  );
};
