export const searchOptions = {
  byPowerScore: 'By PowerScore',
  bySegmentScore: 'By Segment Score',
  byLastContacted: 'By Last Contacted',
};

export const lastContactedIds = {
  OFF: null,
  THIRTY_DAYS: 30,
  SIXTY_DAYS: 60,
  NINETY_DAYS: 90,
};

export const segmentTypes = {
  RECENT: 'recent',
  PREDICTED: 'predicted',
};
