import { useContext } from 'react';
import {
  Box,
  Skeleton,
  Tab,
  Tabs,
  styled,
  MenuItem,
  Select,
  Chip,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Check } from '@mui/icons-material';
import { OpportunityMetadataContext } from './data/opportunityMetadataContext';
import { Cadence, Metric } from '../types';
import { ErrorContainer } from '../../../generic/errorContainer';
import { CADENCES_ORDER, METRIC_UNIT } from '../constants';
import { useOpportunityMixpanelTracker } from './useOpportunityMixpanelTracker';

const SkeletonBar = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  backgroundColor: themeColors.surfaceEmpty,
}));

const Controls = styled(Box)(() => ({
  display: 'flex',
  gap: 16,
  flexWrap: 'wrap',
  flexDirection: 'column',
}));

const MetricSelect = styled(Select)(({ theme: { themeColors } }) => ({
  minWidth: 237,
  maxWidth: 340,
  backgroundColor: themeColors.buttonBackgroundColor,
  color: themeColors.menuTextColor,
  textAlign: 'left',
  '.MuiSelect-select': {
    padding: '8px 12px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: themeColors.buttonBorderColor,
  },
  svg: {
    color: themeColors.buttonContentColor,
  },
}));

const StyledChip = styled(Chip)(
  ({ theme: { themeColors, spacing }, selected }) => ({
    marginRight: 10,
    '&.MuiChip-colorPrimary': {
      backgroundColor: themeColors.chipChoiceActiveSurface,
    },
    backgroundColor: selected
      ? themeColors.chipChoiceActiveSurface
      : themeColors.chipChoiceDefaultSurface,
    color: selected
      ? themeColors.chipChoiceActiveContent
      : themeColors.chipChoiceDefaultContent,
    borderColor: selected
      ? themeColors.chipChoiceActiveBorder
      : themeColors.chipChoiceDefaultBorder,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: spacing(2),
    '&:hover': {
      opacity: 1,
      backgroundColor: themeColors.chipChoiceHoverSurface,
      color: themeColors.chipChoiceHoverContent,
      borderColor: themeColors.chipChoiceHoverBorder,
    },
  })
);

const ChipWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  margin: '5px 0px',
  rowGap: 10,
}));

const MetricTabs = () => {
  const {
    metadata,
    selectedCadence,
    selectedMetric,
    setSelectedMetric,
    setSelectedUnit,
  } = useContext(OpportunityMetadataContext);

  const { trackGraphMetric } = useOpportunityMixpanelTracker();

  const handleMetricChange = (metric: string, unit: METRIC_UNIT) => {
    setSelectedMetric(metric);
    setSelectedUnit(unit);
    trackGraphMetric(metric, selectedCadence.id);
  };

  if (!metadata || metadata.metrics.length <= 1) return null;

  if (metadata.metrics.length <= 5) {
    return (
      <ChipWrapper>
        {metadata.metrics.map((metric) => (
          <StyledChip
            key={`${metric.rxType}`}
            data-testid={`opportunity-metric-chip-${metric.rxType}`}
            label={
              <Typography noWrap sx={{ fontSize: 14, fontWeight: 400 }}>
                {metric.displayName || ''}
              </Typography>
            }
            icon={selectedMetric === metric.rxType ? <Check /> : null}
            selected={selectedMetric === metric.rxType}
            variant={selectedMetric === metric.rxType ? 'primary' : 'outlined'}
            color={selectedMetric === metric.rxType ? 'primary' : 'default'}
            onClick={() => {
              handleMetricChange(metric.rxType, metric.unit);
            }}
          />
        ))}
      </ChipWrapper>
    );
  }

  return (
    <MetricSelect
      value={selectedMetric}
      IconComponent={KeyboardArrowDownIcon}
      data-testid="metrics-dropdown"
    >
      {metadata.metrics.map((metric) => (
        <MenuItem
          key={`${metric.rxType}-metric-key`}
          value={metric.rxType}
          onClick={() => handleMetricChange(metric.rxType, metric.unit)}
        >
          {metric.displayName}
        </MenuItem>
      ))}
    </MetricSelect>
  );
};

export const getAllCadences = (metrics: Metric[]): Cadence[] => {
  const uniqueCadencesMap = new Map();
  metrics.forEach((metric: Metric) => {
    metric.cadences.forEach((cadence: Cadence) => {
      uniqueCadencesMap.set(cadence.id, cadence);
    });
  });

  const uniqueCadences = [...uniqueCadencesMap.values()];
  uniqueCadences.sort(
    (a: Cadence, b: Cadence) => CADENCES_ORDER[a.id] - CADENCES_ORDER[b.id]
  );

  return uniqueCadences;
};

const CadenceTabs = () => {
  const { metadata, selectedCadence, setSelectedCadence, selectedMetric } =
    useContext(OpportunityMetadataContext);

  const { trackGraphTimescale } = useOpportunityMixpanelTracker();

  if (!metadata?.metrics) return null;
  const allCadences = getAllCadences(metadata.metrics);

  const isCadenceDisabled = (cadence: Cadence): boolean =>
    !metadata.metrics.find(
      (metric) =>
        metric.rxType === selectedMetric &&
        metric.cadences.some((c) => c.id === cadence.id)
    );

  const handleCadenceChange = (cadence: Cadence) => {
    setSelectedCadence(cadence);
    trackGraphTimescale(selectedMetric, cadence.id);
  };

  return (
    <Tabs
      variant="filled"
      value={selectedCadence?.id}
      data-testid="opportunity-cadence-tabs"
    >
      {allCadences.map((cadence) => (
        <Tab
          key={`${cadence.id}-cadence-key`}
          label={cadence.label}
          value={cadence.id}
          onClick={() => {
            handleCadenceChange(cadence);
          }}
          disabled={isCadenceDisabled(cadence)}
        />
      ))}
    </Tabs>
  );
};

export const OpportunityHeader = () => {
  const {
    isMetadataLoading,
    metadata,
    refetchMetadata,
    isMetadataError,
    isMetadataIdle,
  } = useContext(OpportunityMetadataContext);

  if (isMetadataIdle || isMetadataLoading) {
    return (
      <>
        <SkeletonBar animation="wave" height={30} />
        <SkeletonBar animation="wave" height={40} />
      </>
    );
  }

  if (isMetadataError || !metadata) {
    return <ErrorContainer handleRetry={refetchMetadata} />;
  }

  if (!metadata.metrics?.length) {
    return (
      <ErrorContainer
        title="Graph not available"
        description="We do not have enough data to generate a graph for these products."
      />
    );
  }

  return (
    <Controls>
      <MetricTabs />
      <CadenceTabs />
    </Controls>
  );
};
