import { useContext, useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { EntityOverviewContext } from '../../data/entityOverviewContext';
import { CADENCES_ENUM } from '../../constants';
import { trackEntityOverviewCadenceSelector } from '../../../../trackers/mixpanel';
import { getComparisonTimeFrames } from '../../data/useEntityOverviewContext';

export const CadenceSelectorTabs = () => {
  const {
    entityType,
    metadata,
    isMetadataLoading,
    objectiveId,
    cadence,
    setCadence,
    comparisonTimeFrameId,
    setComparisonTimeFrameId,
  } = useContext(EntityOverviewContext);

  const [availableCadences, setAvailableCadences] = useState([]);

  useEffect(() => {
    if (!isMetadataLoading && metadata && objectiveId) {
      const cadenceKeys = metadata.objectives?.find(
        (obj) => obj.id === objectiveId
      )?.cadences;

      const cadences = [];

      cadenceKeys.forEach((cadenceKey) => {
        const cadenceName = CADENCES_ENUM[cadenceKey];
        cadences.push({ id: cadenceKey, name: cadenceName });
      });

      setAvailableCadences(cadences);
    }
  }, [isMetadataLoading, metadata, objectiveId]);

  const onTabClick = (tabId, tabName) => {
    trackEntityOverviewCadenceSelector(tabName, entityType);
    setCadence(tabId);

    const comparisonTimeFrames = getComparisonTimeFrames(tabId);
    const isOutdatedComparisonTimeFrameId =
      comparisonTimeFrames &&
      !comparisonTimeFrames.some(
        (comparisonTimeFrame) =>
          comparisonTimeFrame.id === comparisonTimeFrameId
      );
    if (isOutdatedComparisonTimeFrameId) {
      const newComparisonTimeFrameId = comparisonTimeFrames?.[0]?.id ?? null;
      setComparisonTimeFrameId(newComparisonTimeFrameId);
    }
  };

  return (
    <Box>
      <Tabs variant="filled" value={cadence || 0}>
        {availableCadences &&
          availableCadences.map((cadenceTab) => {
            const { id, name } = cadenceTab;
            return (
              <Tab
                key={`${id}-key`}
                label={name}
                value={id}
                onClick={() => {
                  onTabClick(id, name);
                }}
              />
            );
          })}
      </Tabs>
    </Box>
  );
};
