import { useContext } from 'react';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import EmptyView from '../emptyview/emptyView';
import UserForm from './userForm';
import getUserManagementState from '../../hooks/userManagement';
import { closeUserModal } from '../../slices/userManagement';
import {
  ModalBody,
  ModalDisplay,
  ModalHeader,
  ModalError,
  ModalFooter,
} from '../generic/modalDisplay';
import { AppContext } from '../../containers/application/appContext';

const UserDataModal = () => {
  const state = getUserManagementState();
  const dispatch = useDispatch();
  const { profile } = useContext(AppContext);
  const handleClose = () => {
    dispatch(closeUserModal());
  };

  return (
    <ModalDisplay
      isOpen={state.isUserModalOpen}
      onClose={handleClose}
      style={{ height: '80%' }}
    >
      {state.userRequestStatus === 'FAILED' ? (
        <>
          <ModalHeader variant="h6">
            We were unable to create the user
          </ModalHeader>
          <ModalBody>
            <ModalError>
              There was an error creating the new user. Try again or record the
              error message below and contact your local engineer.
            </ModalError>
            {state.errorMessage}
          </ModalBody>
          <ModalFooter>
            <Button
              sx={{ textTransform: 'none' }}
              variant="contained"
              onClick={handleClose}
            >
              Done
            </Button>
          </ModalFooter>
        </>
      ) : (
        <>
          <ModalHeader onClose={handleClose}>
            {state.userId ? `Edit User` : `Add User to ${profile.userGroup}`}
          </ModalHeader>
          {state.userRequestStatus === 'PENDING' ||
          state.userModalStatus === 'PENDING' ? (
            <ModalBody>
              <EmptyView isLoading />
            </ModalBody>
          ) : (
            <UserForm onClose={handleClose} />
          )}
        </>
      )}
    </ModalDisplay>
  );
};
export default UserDataModal;
