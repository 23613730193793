import { styled, Box, Typography, Tooltip, Popper } from '@mui/material';
import isValidProp from '@emotion/is-prop-valid';
import { useTheme } from '@mui/styles';

const StyledBox = styled(Box, {
  shouldForwardProp: isValidProp,
})(({ bgColor }) => ({
  padding: '2px 4px',
  display: 'flex',
  borderRadius: 3,
  alignItems: 'center',
  gap: 4,
  backgroundColor: bgColor,
  height: 16,
  maxWidth: '50%',
}));

const StyledTypography = styled(Typography, {
  shouldForwardProp: isValidProp,
})(({ textColor }) => ({
  fontSize: 11,
  fontWeight: 500,
  lineHeight: 1,
  color: textColor,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const StyledIcons = styled(Box, { shouldForwardProp: isValidProp })(
  ({ iconColor }) => ({
    display: 'flex',
    svg: {
      fontSize: 'inherit',
      margin: 'auto',
      color: iconColor,
      justifySelf: 'center',
    },
  })
);

const StyledTooltipPopper = styled(Popper)(({ theme: { themeColors } }) => ({
  '.MuiTooltip-arrow::before': {
    backgroundColor: themeColors.tooltipSurfaceColor,
  },
  '.MuiTooltip-tooltip': {
    backgroundColor: themeColors.tooltipSurfaceColor,
  },
}));

export const SegmentChip = ({
  segmentLabel,
  key,
  darken = false,
  iconList = [],
  showIcon = false,
  tooltip = null,
}) => {
  const { themeColors } = useTheme();

  let backgroundColor = themeColors.badgePrimaryColor;
  let color = themeColors.badgePrimaryContentColor;

  if (darken) {
    backgroundColor += '55';
    color += '55';
  }

  return segmentLabel ? (
    <Tooltip
      title={tooltip}
      placement="bottom"
      arrow
      PopperComponent={StyledTooltipPopper}
    >
      <StyledBox
        data-testid="test-segment-chip"
        key={key}
        bgColor={backgroundColor}
      >
        {showIcon && <StyledIcons iconColor={color}>{iconList}</StyledIcons>}
        <StyledTypography noWrap textColor={color}>
          {segmentLabel}
        </StyledTypography>
      </StyledBox>
    </Tooltip>
  ) : null;
};
