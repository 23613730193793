import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { useTheme } from '@mui/styles';
import { ListItemButton, Typography, styled } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import TimelineIcon from '@mui/icons-material/Timeline';
import Tooltip from '@mui/material/Tooltip';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import { PowerScore } from '@odaia/shared-components/src/powerScore';
import { userCreatedListOptions } from '@odaia/shared-components/src/userCreatedListsConfig';
import { ListType } from '@odaia/domain/src/types';
import { trackEntitySelection } from '../../trackers/appEventTracker';
import { isProperSegment } from '../../utils/isProperSegment';

const useStyles = makeStyles((theme) => ({
  trendStrip: {
    width: 4,
  },
  divider: {
    height: 2,
    backgroundColor: theme.themeColors.mainBackground,
    margin: 0,
  },
  defaultProfile: {
    color: theme.themeColors.secondaryTextColor,
    backgroundColor: theme.themeColors.dropDownBackground,
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  nameScore: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gridGap: 16,
  },
  mainProfileInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  secondaryText: {
    width: '100%',
    maxWidth: '100%',
    fontWeight: 300,
    fontSize: 13,
    lineHeight: 1.1,
  },
  chipContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: 0,
    padding: 0,
    overflowY: 'hidden',
    maxHeight: 74,
    gridGap: 5,
    marginTop: 5,
  },
  chipIcon: {
    fontSize: 'inherit',
    margin: 'auto',
    color: theme.themeColors.badgeHighlightedContentColor,
  },
  insightChip: {
    padding: 3,
    paddingLeft: 5,
    paddingRight: 5,
    display: 'flex',
    borderRadius: 3,
    alignItems: 'center',
    gap: 4,
  },
  insightChipText: {
    maxWidth: 100,
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 1,
  },
  scoreContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: 12,
    left: 6,
  },
  rankNumber: {
    fontWeight: 300,
    fontSize: 11,
    lineHeight: 1.1,
    color: theme.themeColors.secondaryTextColorHighContrast,
  },
}));

const StyledListItemButton = styled(ListItemButton)(
  ({ theme: { themeColors }, selected }) => ({
    padding: 16,
    gap: 16,
    backgroundColor: selected
      ? `${themeColors.HCPListItemBackgroundActive} !important`
      : themeColors.HCPListItemBackground,
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: selected
        ? `${themeColors.HCPListItemBackgroundActive} !important`
        : themeColors.HCPListItemBackgroundHover,
    },
  })
);

const StyledHCPName = styled(Typography)(
  ({ theme: { themeColors }, selected }) => ({
    color: selected
      ? `${themeColors.HCPListItemContentActive} !important`
      : themeColors.HCPListItemContent,
    '&:hover': {
      color: selected
        ? `${themeColors.HCPListItemContentActive} !important`
        : themeColors.HCPListItemContentHover,
    },
  })
);

const StyledHCPDetails = styled(Typography)(
  ({ theme: { themeColors }, selected }) => ({
    color: selected
      ? `${themeColors.HCPListItemContentSecondaryActive} !important`
      : themeColors.HCPListItemContentSecondary,
    '&:hover': {
      color: selected
        ? `${themeColors.HCPListItemContentSecondaryActive} !important`
        : themeColors.HCPListItemContentSecondaryHover,
    },
  })
);

const EmailConsent = styled(MarkEmailReadOutlinedIcon)(
  ({ theme: { themeColors }, selected }) => ({
    color: selected
      ? themeColors.HCPListItemIconActive
      : themeColors.HCPListItemIcon,
    width: 16,
    height: 16,
  })
);

const EMAIL_OPT_OUT_STATUS = Object.freeze({
  NO_DATA: -1,
  OPT_OUT: 1,
  NO_OPT_OUT: 0,
});

export const CHIP_STYLES_OVERRIDES = Object.freeze({
  LIGHT: 'LIGHT',
  DARK: 'DARK',
});

const StyledTooltip = withStyles({
  tooltip: {
    position: 'relative',
    top: '-7px',
    fontSize: '14px',
    color: '#E0E4EB',
    backgroundColor: '#1F232C',
  },
  arrow: {
    color: '#1F232C',
  },
})(Tooltip);

// Props signature to comply with react-window
// https://react-window.vercel.app/#/examples/list/memoized-list-items
export default function EntityListItem({ data, index, style, ...extraProps }) {
  const classes = useStyles();
  const { themeColors } = useTheme();
  const {
    entityItems,
    selected,
    onSelect,
    rowHeight = 125,
    selectedSegment = null,
    isSegmentIconVisible = false,
  } = data;

  const insightChip = (
    insight,
    key,
    darken = false,
    isFilterMatch = false,
    isHistoricalIconVisible = false,
    isPredictedIconVisible = false,
    segmentName = ''
  ) => {
    let backgroundColor = themeColors.badgePrimaryColor;
    let color = themeColors.badgePrimaryContentColor;

    if (darken) {
      backgroundColor = themeColors.badgeActiveColor;
      color = themeColors.badgeActiveContentColor;
    }

    if (isFilterMatch) {
      backgroundColor = themeColors.badgeHighlightedColor;
      color = themeColors.badgeHighlightedContentColor;
    }

    let tooltipPrefix;
    if (isHistoricalIconVisible && isPredictedIconVisible) {
      tooltipPrefix = 'Recent and predicted';
    } else if (isHistoricalIconVisible) {
      tooltipPrefix = 'Recent';
    } else if (isPredictedIconVisible) {
      tooltipPrefix = 'Predicted';
    }
    const tooltipText =
      isFilterMatch && tooltipPrefix ? `${tooltipPrefix} ${segmentName}` : '';

    return insight ? (
      <StyledTooltip title={tooltipText} enterTouchDelay={400} arrow>
        <div
          className={classes.insightChip}
          data-testid="test-insight-chip"
          key={key}
          style={{ backgroundColor }}
        >
          {isFilterMatch && isHistoricalIconVisible ? (
            <HistoryIcon className={classes.chipIcon} />
          ) : null}
          {isFilterMatch && isPredictedIconVisible ? (
            <TimelineIcon className={classes.chipIcon} />
          ) : null}
          <Typography
            noWrap
            className={classes.insightChipText}
            style={{ color }}
          >
            {insight}
          </Typography>
        </div>
      </StyledTooltip>
    ) : null;
  };

  return (
    <div
      className={`test-hcp-list-item test-hcp-id-${entityItems[index].snowflakeEntityId}`}
      style={{
        ...style,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'stretch',
          flexGrow: 2,
          maxHeight: rowHeight - 2,
        }}
      >
        <div
          className={classes.trendStrip}
          style={{
            backgroundColor:
              (entityItems[index].maptualScoreChange > 0 &&
                themeColors.increaseColorMain) ||
              (entityItems[index].maptualScoreChange < 0 &&
                themeColors.decreaseColorMain) ||
              '',
          }}
        >
          &nbsp;
        </div>

        <StyledListItemButton
          selected={selected === index}
          onClick={() => {
            onSelect(entityItems[index].snowflakeEntityId);
            trackEntitySelection(
              entityItems[index].primaryText,
              entityItems[index].maptualDisplayScore,
              entityItems[index].maptualScoreChange
            );
          }}
          key={index}
          {...extraProps}
        >
          <div className={classes.avatarContainer}>
            <Typography noWrap className={classes.rankNumber}>
              {index + 1}
            </Typography>
          </div>
          <div className={classes.nameScore}>
            <div className={classes.scoreContainer}>
              <PowerScore
                score={entityItems[index].maptualDisplayScore}
                scoreChangeValue={entityItems[index].maptualScoreChange}
                isSelected={selected === index}
                objectiveScoreList={entityItems[index].objectiveScores || []}
              />
            </div>

            <div className={classes.mainProfileInfo}>
              <StyledHCPName
                selected={selected === index}
                noWrap
                variant="h5"
                className="test-hcp-name"
                color="textPrimary"
                style={{
                  marginBottom: '4px',
                }}
              >
                {entityItems[index].primaryText}
              </StyledHCPName>
              {entityItems[index].secondaryText ? (
                <StyledHCPDetails
                  selected={selected === index}
                  noWrap
                  variant="subtitle1"
                  color="textPrimary"
                >
                  {entityItems[index].secondaryText}
                </StyledHCPDetails>
              ) : null}
              {Array.isArray(entityItems[index].segments) ||
              Array.isArray(entityItems[index].chips) ? (
                <div className={classes.chipContainer}>
                  {entityItems[index]?.listMemberships
                    ? Object.entries(entityItems[index]?.listMemberships).map(
                        ([listName, listMembership]) => {
                          let ListIcon = null;
                          if (listMembership) {
                            ListIcon =
                              userCreatedListOptions[listName as ListType].icon
                                .default;
                            return (
                              <ListIcon
                                key={listName}
                                sx={{
                                  color:
                                    selected === index
                                      ? themeColors.HCPListItemIconActive
                                      : themeColors.HCPListItemIcon,
                                  width: 16,
                                  height: 16,
                                }}
                              />
                            );
                          }
                          return null;
                        }
                      )
                    : null}
                  {entityItems[index].emailOptOut ===
                    EMAIL_OPT_OUT_STATUS.NO_OPT_OUT && (
                    <StyledTooltip
                      title="Email Consent"
                      enterTouchDelay={400}
                      arrow
                    >
                      <EmailConsent selected={selected === index} />
                    </StyledTooltip>
                  )}
                  {Array.isArray(entityItems[index].chips)
                    ? entityItems[index].chips.map((item, i) => {
                        // if chip specifies if it is dark or light, use that
                        if (typeof item === 'object') {
                          return insightChip(
                            item.label,
                            i,
                            item.style !== CHIP_STYLES_OVERRIDES.LIGHT
                          );
                        }
                        // otherwise defaults to dark
                        return insightChip(item, i, selected === index, false);
                      })
                    : null}

                  {Array.isArray(entityItems[index].segments)
                    ? entityItems[index].segments.map((item, i) =>
                        isProperSegment(item?.segmentName) // [TO DO] temporary hardcode filter, to be solved properly later
                          ? insightChip(
                              item?.segmentName,
                              i,
                              selected === index,
                              selectedSegment === item?.segmentName,
                              isSegmentIconVisible && item?.isHistoricalMember,
                              isSegmentIconVisible && item?.isPredictedMember,
                              item?.segmentName
                            )
                          : null
                      )
                    : null}
                </div>
              ) : null}
            </div>
          </div>
        </StyledListItemButton>
      </div>

      <div className={classes.divider}>&nbsp;</div>
    </div>
  );
}
