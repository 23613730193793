import prettier from 'prettier/standalone';
import parserBabel from 'prettier/parser-babel';

export const formatJsonString = (jsonString) => {
  try {
    return prettier.format(jsonString, {
      parser: 'json',
      plugins: [parserBabel],
    });
  } catch (e) {
    return jsonString;
  }
};
