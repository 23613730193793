/* eslint-disable import/no-cycle */

import { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

import { darkThemeColors } from '@odaia/ui/src/colorConfig';
import { Content } from '../analysisview/content';
import GraphConfig from '../datavisualization/graphConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    padding: '1.5rem',
    margin: '0.5rem',
    backgroundColor: grey[900],
    borderRadius: 4,
    flexDirection: 'column',
  },
  rootHori: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    padding: '1.5rem',
    margin: '0.5rem',
    backgroundColor: grey[900],
    borderRadius: 4,
  },
  regularContentContainer: {
    width: '20rem',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  subContentContainer: {},
  titleContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  primaryText: {
    fontSize: 18,
    fontWeight: 800,
    color: theme.palette.text.secondary,
    margin: 0,
    padding: 0,
    lineHeight: 1.1,
    marginRight: '0.25rem',
  },
  secondaryText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.1,
    color: darkThemeColors.tertiaryColor,
    margin: 0,
  },
  tertiaryText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.1,
    color: darkThemeColors.tertiaryColor,
    margin: 0,
  },
  legendBar: {
    height: '2.125rem',
    width: '0.3rem',
    borderRadius: '0.15rem',
    marginRight: '0.5vw',
  },
  statsContainer: {
    display: 'flex',
    height: '3rem',
    borderBottomStyle: 'solid',
    borderWidth: 1,
    borderColor: grey.A400,
  },
}));

export default function ContentBox(props) {
  const classes = useStyles();
  const { primaryText, secondaryText, tertiaryText, match, index } = props;
  const {
    content,
    indicateLegendColors = false,
    useAutoSizing = false,
  } = props;
  const [showSubContent, setShowSubContent] = useState(false);
  const [subContent, setSubContent] = useState(null);

  const onExpandContent = (expandContent) => {
    if (expandContent) {
      if (expandContent === subContent) {
        setShowSubContent(false);
        setSubContent(null);
      } else {
        setShowSubContent(true);
        setSubContent(expandContent);
      }
    } else {
      if (showSubContent) setShowSubContent(false);
      setSubContent(null);
    }
  };

  return showSubContent && subContent ? (
    <div className={classes.rootHori}>
      <div
        className={classes.regularContentContainer}
        style={useAutoSizing ? { width: 'auto' } : {}}
      >
        <div className={classes.statsContainer} style={{ margin: '0.5vw' }}>
          {indicateLegendColors ? (
            <div
              className={classes.legendBar}
              style={{
                backgroundColor:
                  GraphConfig.graphColors[
                    index % GraphConfig.graphColors.length
                  ],
              }}
            >
              &nbsp;
            </div>
          ) : null}
          <div className={classes.titleContainer}>
            {primaryText ? (
              <Typography
                className={classes.primaryText}
                variant="h5"
                align="left"
                style={
                  indicateLegendColors
                    ? {}
                    : { color: darkThemeColors.tertiaryColor, fontWeight: 500 }
                }
              >
                {primaryText}
              </Typography>
            ) : null}
            {secondaryText ? (
              <Typography
                className={classes.secondaryText}
                variant="subtitle1"
                align="left"
              >
                {secondaryText}
              </Typography>
            ) : null}
            {tertiaryText ? (
              <Typography
                className={classes.tertiaryText}
                variant="body1"
                align="left"
              >
                {tertiaryText}
              </Typography>
            ) : null}
          </div>
        </div>
        {content ? (
          <Content
            content={content}
            match={match}
            onExpandContent={onExpandContent}
          />
        ) : null}
      </div>

      <Content
        content={subContent}
        match={match}
        onExpandContent={onExpandContent}
      />
    </div>
  ) : (
    <div
      className={classes.root}
      style={useAutoSizing ? { width: 'auto' } : {}}
    >
      <div className={classes.statsContainer} style={{ margin: '0.5vw' }}>
        {indicateLegendColors ? (
          <div
            className={classes.legendBar}
            style={{
              backgroundColor:
                GraphConfig.graphColors[index % GraphConfig.graphColors.length],
            }}
          >
            &nbsp;
          </div>
        ) : null}
        <div className={classes.titleContainer}>
          {primaryText ? (
            <Typography
              className={classes.primaryText}
              variant="h5"
              align="left"
              style={
                indicateLegendColors
                  ? {}
                  : { color: darkThemeColors.tertiaryColor, fontWeight: 500 }
              }
            >
              {primaryText}
            </Typography>
          ) : null}
          {secondaryText ? (
            <Typography
              className={classes.secondaryText}
              variant="subtitle1"
              align="left"
            >
              {secondaryText}
            </Typography>
          ) : null}
          {tertiaryText ? (
            <Typography
              className={classes.tertiaryText}
              variant="body1"
              align="left"
            >
              {tertiaryText}
            </Typography>
          ) : null}
        </div>
      </div>
      {content ? (
        <Content
          content={content}
          match={match}
          onExpandContent={onExpandContent}
        />
      ) : null}
    </div>
  );
}
