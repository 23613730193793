import { Redirect, useLocation } from 'react-router-dom';

import { SchemaDefinitionForm } from './schemaDefinitionForm';
import {
  useCreateModuleConfigSchemasMutation,
  useUpdateModuleConfigValuesMutation,
} from '../../../request/admin/mutations';

export const NewSchemaContainer = () => {
  const { state } = useLocation();
  if (!state || !(state.name && state.description)) {
    return <Redirect to="/admin/appConfig" />;
  }

  const moduleConfigValuesMutation = useUpdateModuleConfigValuesMutation();
  const moduleConfigSchemasMutation = useCreateModuleConfigSchemasMutation();

  const {
    mutateAsync: mutateConfigValues,
    isLoading: isConfigValuesMutationLoading,
    isError: isConfigValuesMutationError,
    isSuccess: isConfigValuesMutationSuccess,
  } = moduleConfigValuesMutation;

  const {
    mutateAsync: mutateConfigSchemas,
    isLoading: isConfigSchemasMutationLoading,
    isError: isConfigSchemasMutationError,
    isSuccess: isConfigSchemasMutationSuccess,
  } = moduleConfigSchemasMutation;

  const isLoading =
    isConfigValuesMutationLoading || isConfigSchemasMutationLoading;
  const isSuccess =
    isConfigValuesMutationSuccess && isConfigSchemasMutationSuccess;
  const isError = isConfigValuesMutationError || isConfigSchemasMutationError;

  const onSave = async (schemaName: string, schema: any, values: any) => {
    const schemaRes = await mutateConfigSchemas({
      requestBody: schema,
    });

    if (!schemaRes) {
      return;
    }

    await mutateConfigValues({
      requestBody: values,
      schemaName,
    });
  };

  return (
    <SchemaDefinitionForm
      schemaMetadata={{ name: state.name, description: state.description }}
      initialSchema="{}"
      initialJson="{}"
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      onSave={onSave}
    />
  );
};
