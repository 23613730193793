/* eslint-disable react/no-unstable-nested-components */

import {
  styled,
  Autocomplete,
  TextField,
  Popper,
  Paper,
  List,
  MenuItem,
} from "@mui/material";

const StyledAutocomplete = styled(Autocomplete)(() => ({
  height: "100%",
  margin: "0px",
  width: "100%",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.themeColors.buttonBackgroundColor,
  backgroundImage: "none",
  boxShadow: "0px 16px 70px 0px rgba(0, 0, 0, 0.50)",
  colorScheme: theme.palette.mode,
}));

const StyledTextField = styled(TextField)(({ theme: { themeColors } }) => ({
  height: "100%",
  ".MuiOutlinedInput-root": {
    padding: 0,
  },
  ".MuiOutlinedInput-root .MuiAutocomplete-input": {
    lineHeight: "24px",
    letterSpacing: "0.15px",
    padding: "8px 12px",
  },
  ".MuiInputBase-root": {
    height: "100%",
  },
  ".MuiInputLabel-root": {
    top: "-7px",
    opacity: 1,
    color: themeColors.secondaryTextColor,
  },
  ".MuiInputLabel-root.Mui-focused": {
    top: "0px",
  },
}));

const StyledOptionList = styled(List)(({ theme: { themeColors } }) => ({
  padding: "16px 0px !important",
  border: `1px solid ${themeColors.popoverBorderColor}`,
  boxShadow: "0px 16px 70px 0px rgba(0, 0, 0, 0.50)",
  borderRadius: 4,
}));

const StyledOption = styled(MenuItem)(({ theme: { themeColors } }) => ({
  padding: "12px !important",
  color: themeColors.segmentSelectionCaptionColor,
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "24px",
  borderRadius: 3,
  whiteSpace: "pre",
}));

const ListPopper = (props) => (
  <Popper
    {...props}
    placement="bottom"
    popperOptions={{
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 4],
          },
        },
      ],
    }}
  />
);

const defaultFilterOptions = (options, { inputValue }) => {
  return options.filter((option) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};

const defaultRenderOption = (props, option, optionLabel, itemKey) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { key, ...rest } = props;
  return (
    <StyledOption {...rest} key={option[itemKey]}>{`${" ".repeat(
      option.depth * 2
    )}${option[optionLabel]}`}</StyledOption>
  );
};

export const AutocompleteDisplay = ({
  itemsForSearch,
  handleSelection,
  optionLabel,
  noOptionsText,
  textFieldLabel,
  itemKey,
  filterOptions = defaultFilterOptions,
  renderOption = defaultRenderOption,
}) => (
  <StyledAutocomplete
    id="item-selection-combo-box"
    disablePortal
    options={itemsForSearch || []}
    getOptionLabel={(option) => option[optionLabel]}
    onChange={(event, value, reason) => {
      if (reason === "selectOption") handleSelection(value);
    }}
    clearIcon={null}
    forcePopupIcon={false}
    noOptionsText={noOptionsText}
    filterOptions={filterOptions}
    renderInput={(props) => (
      <StyledTextField
        {...props}
        id="item-selection-input"
        label={textFieldLabel}
      />
    )}
    PopperComponent={ListPopper}
    PaperComponent={(props) => <StyledPaper {...props} />}
    ListboxComponent={(props) => <StyledOptionList {...props} />}
    renderOption={(props, option) =>
      renderOption(props, option, optionLabel, itemKey)
    }
  />
);
