import { useState } from 'react';
import { Stack, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ProjectTemplateCard } from './projectTemplateCard';
import { TemplateDeleteModal } from './TemplateDeleteModal';
import { useDeleteProjectTemplateMutation } from '../../../request/mutations';

const TileList = styled(Stack)({
  padding: '0 3vw',
  marginBottom: 24,
});

const TemplateHeader = styled(Typography)({
  margin: '0 3vw 16px',
});

export const ProjectTemplatesList = ({ templates }) => {
  const [selectedTemplateForDeletion, setSelectedTemplateForDeletion] =
    useState(null);
  const deleteTemplateMutation = useDeleteProjectTemplateMutation({
    onSuccess: () => setSelectedTemplateForDeletion(null),
  });
  return (
    <>
      <TemplateHeader variant="h5" align="left">
        Templates
      </TemplateHeader>
      <TileList direction="row" spacing={3} useFlexGap flexWrap="wrap">
        {templates.map((template) => (
          <ProjectTemplateCard
            key={template.templateName}
            template={template}
            onDelete={() => {
              setSelectedTemplateForDeletion(template);
            }}
          />
        ))}
      </TileList>
      {selectedTemplateForDeletion && (
        <TemplateDeleteModal
          templateName={selectedTemplateForDeletion.templateName}
          isOpen={!!selectedTemplateForDeletion}
          onClose={() => {
            setSelectedTemplateForDeletion(null);
          }}
          onConfirm={() => {
            deleteTemplateMutation.mutate(selectedTemplateForDeletion.id);
          }}
          isLoading={deleteTemplateMutation.isLoading}
        />
      )}
    </>
  );
};
