/* eslint-disable no-nested-ternary */

import makeStyles from '@mui/styles/makeStyles';
import { Checkbox, ListItemButton, Typography, styled } from '@mui/material';
import { useTheme } from '@mui/styles';
import abbreviateNumber from '../../../utils/abbreviateNumber';
import TrendValue from '../../fieldView/trendValues';

const lighten = (color) => `${color}88`;

const LegendMarker = styled('div')`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  aspect-ratio: 1/1;
  background-color: ${(props) => props.backgroundColor};
`;

const LegendTitle = styled(Typography)`
  font-weight: 500;
  line-height: 1.1;
  color: ${(props) => props.color};
  word-break: break-word;
`;

const LegendContentCell = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 5px;
`;

const dataCellWidth = 115;
const cornerRadius = 8;
const rowHeight = 45;
const cellLeftPadding = 5;
const cellPadding = 10;
const prevDataCellWidth = 90;
const useStyles = makeStyles(({ themeColors }) => ({
  headerRow: {
    width: '100%',
    height: rowHeight,
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: `1fr ${prevDataCellWidth}px repeat(2, ${dataCellWidth}px)`,
  },
  headerPrimaryText: {
    fontSize: 14,
    fontWeight: 400,
    color: themeColors.secondaryTextColor,
    lineHeight: 1.2,
    wrapBreak: 'break-word',
    whiteSpace: 'normal',
  },
  headerSecondaryText: {
    fontSize: 12,
    fontWeight: 400,
    color: themeColors.secondaryTextColor,
    lineHeight: 1.3,
  },
  headerCellVertical: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: 5,
  },
  listContainer: {
    padding: 0,
    paddingLeft: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  listInnerRow: {
    width: '100%',
    display: 'grid',
    flexGrow: 1,
    gridTemplateColumns: `1fr ${prevDataCellWidth}px repeat(2, ${dataCellWidth}px)`,
  },
  mainValueStyle: {
    fontSize: 16,
    fontWeight: 800,
    color: themeColors.primaryTextColor,
    lineHeight: 1.2,
  },
  subValueStyle: {
    fontSize: 16,
    fontWeight: 800,
    color: `${lighten(themeColors.primaryTextColor)}`,
    lineHeight: 1.1,
  },
  dataCell: {
    display: 'flex',
    padding: cellPadding,
    paddingLeft: cellLeftPadding,
  },
  legend: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginRight: 5,
    aspectRatio: '1/1',
    alignSelf: 'center',
  },
  innerDataWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function FullDataListTable({
  dropdownSelection,
  selectedData,
  rowSelected,
  onRowSelect,
  rowsHidden,
  onRowsHidden = () => {},
}) {
  const { themeColors } = useTheme();
  const theme = themeColors;
  const classes = useStyles();
  const { dataList } = selectedData;
  const listHeaderAugment = Array.isArray(dataList?.listHeader)
    ? [...dataList.listHeader]
    : null;
  if (Array.isArray(listHeaderAugment) && rowsHidden)
    listHeaderAugment.push({
      key: 'Show',
      primaryText: 'Show',
    });

  const listHeader = listHeaderAugment || null;
  const listItems = dataList?.listItems || null;

  const isVisible = (i) =>
    !rowsHidden || (rowsHidden && !rowsHidden[`${dropdownSelection}drop${i}`]);

  const colourStartIndex = 5;

  return selectedData ? (
    <div className={classes.listContainer}>
      <div className={classes.headerRow}>
        {Array.isArray(listHeader)
          ? listHeader.map((item, i) => (
              <div
                key={`${i}header`}
                className={classes.headerCellVertical}
                style={{
                  paddingLeft: i !== 0 ? cellLeftPadding : 0,
                  backgroundColor:
                    item.key?.toLowerCase() === 'now'
                      ? theme.contentCardHighlightColor
                      : '',
                  borderTopLeftRadius:
                    item.key?.toLowerCase() === 'now' ? cornerRadius : 0,
                  borderTopRightRadius:
                    item.key?.toLowerCase() === 'now' ? cornerRadius : 0,
                  justifyContent: 'flex-end',
                }}
              >
                {item.primaryText ? (
                  <Typography
                    noWrap
                    className={classes.headerPrimaryText}
                    align={item.key === 'Show' ? 'center' : 'left'}
                    style={{
                      color:
                        item.key?.toLowerCase() === 'now'
                          ? theme.primaryTextColor
                          : '',
                    }}
                  >
                    {item.primaryText.toUpperCase()}
                  </Typography>
                ) : null}
                {item.secondaryText && i !== 0 ? (
                  <Typography
                    noWrap
                    className={classes.headerSecondaryText}
                    align={item.key === 'Show' ? 'center' : 'left'}
                    style={{
                      color:
                        item.key?.toLowerCase() === 'now'
                          ? theme.primaryTextColor
                          : '',
                    }}
                  >
                    {item.secondaryText.toUpperCase()}
                  </Typography>
                ) : null}
              </div>
            ))
          : null}
      </div>

      {Array.isArray(listItems)
        ? listItems.map((item, i) => (
            <ListItemButton
              className={`test-data-list ${classes.listContainer}`}
              key={i}
              selected={rowSelected === i}
              data-testid={`test-${
                listHeader[0].primaryText.toLowerCase().split(' ')[0]
              }-data-list-item`}
              onClick={(event) => {
                onRowSelect(event, i);
              }}
              style={{
                backgroundColor:
                  rowSelected === i ? theme.primaryColorDark : '',
                borderRadius: 3,
              }}
            >
              <div className={classes.listInnerRow}>
                {typeof item.primaryData === 'object'
                  ? Object.keys(item.primaryData).map((cell, j) => {
                      const localData = item.primaryData[cell];
                      return j === 0 ? (
                        <LegendContentCell key={j}>
                          <LegendMarker
                            backgroundColor={
                              isVisible(i)
                                ? theme.graphLegendColors[i + colourStartIndex]
                                : theme.secondaryTextColor
                            }
                          />
                          <LegendTitle
                            color={
                              isVisible(i)
                                ? theme.primaryTextColor
                                : theme.secondaryTextColor
                            }
                          >
                            {item.primaryData[cell] || null}
                          </LegendTitle>
                        </LegendContentCell>
                      ) : (
                        <div
                          className={classes.dataCell}
                          key={j}
                          style={{
                            backgroundColor:
                              cell?.toLowerCase() === 'now'
                                ? rowSelected === i
                                  ? theme.contentCardHighlightSelectedColor
                                  : theme.contentCardHighlightColor
                                : '',
                            borderBottomLeftRadius:
                              cell?.toLowerCase() === 'now' &&
                              (!item.secondaryData || rowSelected !== i) &&
                              i === listItems.length - 1
                                ? cornerRadius
                                : 0,
                            borderBottomRightRadius:
                              cell?.toLowerCase() === 'now' &&
                              (!item.secondaryData || rowSelected !== i) &&
                              i === listItems.length - 1
                                ? cornerRadius
                                : 0,
                            maxWidth:
                              item.primaryData[cell] &&
                              typeof item.primaryData[cell] === 'object'
                                ? dataCellWidth
                                : '',
                          }}
                        >
                          {typeof localData === 'object' ? (
                            <span className={classes.innerDataWrapper}>
                              {localData.value !== null &&
                              localData.value !== undefined ? (
                                <Typography
                                  data-testid="test-script-volume-data"
                                  noWrap
                                  className={classes.mainValueStyle}
                                  style={{ lineHeight: 1.2 }}
                                >
                                  {abbreviateNumber(
                                    item.primaryData[cell].value,
                                    2
                                  )}
                                </Typography>
                              ) : null}
                              {localData.secondaryValue ? (
                                <TrendValue
                                  type={localData.secondaryValue.type}
                                  status={localData.secondaryValue.status}
                                  value={localData.secondaryValue.value}
                                  collapsed={rowSelected !== i}
                                />
                              ) : null}
                            </span>
                          ) : null}
                        </div>
                      );
                    })
                  : null}
                {rowsHidden ? (
                  <div
                    className={classes.dataCell}
                    key={`hidden${i}`}
                    style={{
                      maxWidth: dataCellWidth,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Checkbox
                      checked={isVisible(i)}
                      onChange={() => {
                        onRowsHidden(`${dropdownSelection}drop${i}`);
                      }}
                      style={{
                        color: theme.primaryTextColor,
                        padding: 0,
                      }}
                    />
                  </div>
                ) : null}
              </div>

              {rowSelected === i ? (
                <div className={classes.listInnerRow}>
                  {typeof item.secondaryData === 'object'
                    ? Object.keys(item.secondaryData).map((cell, j) => {
                        const localData = item.secondaryData[cell];
                        return j === 0 ? (
                          <LegendContentCell key={j}>
                            <LegendMarker
                              backgroundColor={lighten(
                                isVisible(i)
                                  ? theme.graphLegendColors[
                                      i + colourStartIndex
                                    ]
                                  : theme.secondaryTextColor
                              )}
                            />
                            <LegendTitle
                              color={lighten(
                                isVisible(i)
                                  ? theme.primaryTextColor
                                  : theme.secondaryTextColor
                              )}
                            >
                              {localData || null}
                            </LegendTitle>
                          </LegendContentCell>
                        ) : (
                          <div
                            className={classes.dataCell}
                            key={j}
                            style={{
                              backgroundColor:
                                cell?.toLowerCase() === 'now'
                                  ? rowSelected === i
                                    ? theme.contentCardHighlightSelectedColor
                                    : theme.contentCardHighlightColor
                                  : '',
                              borderBottomLeftRadius:
                                cell?.toLowerCase() === 'now' &&
                                i === listItems.length - 1
                                  ? cornerRadius
                                  : 0,
                              borderBottomRightRadius:
                                cell?.toLowerCase() === 'now' &&
                                i === listItems.length - 1
                                  ? cornerRadius
                                  : 0,
                              maxWidth:
                                item.primaryData[cell] &&
                                typeof item.primaryData[cell] === 'object'
                                  ? dataCellWidth
                                  : '',
                            }}
                          >
                            {typeof localData === 'object' ? (
                              <span className={classes.innerDataWrapper}>
                                {localData.value !== null &&
                                localData.value !== undefined ? (
                                  <Typography
                                    data-testid="test-national-avg"
                                    noWrap
                                    className={classes.subValueStyle}
                                    style={{ lineHeight: 1.2 }}
                                  >
                                    {abbreviateNumber(
                                      item.secondaryData[cell].value,
                                      2
                                    )}
                                  </Typography>
                                ) : null}
                                {localData.secondaryValue ? (
                                  <TrendValue
                                    type={localData.secondaryValue.type}
                                    status={localData.secondaryValue.status}
                                    value={localData.secondaryValue.value}
                                    condensed={rowSelected !== i}
                                  />
                                ) : null}
                              </span>
                            ) : null}
                          </div>
                        );
                      })
                    : null}
                  {rowsHidden ? (
                    <div
                      className={classes.dataCell}
                      key={`hidden${i}`}
                      style={{
                        maxWidth: dataCellWidth,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      &nbsp;
                    </div>
                  ) : null}
                </div>
              ) : null}
            </ListItemButton>
          ))
        : null}
    </div>
  ) : null;
}
