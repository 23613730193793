import { useCallback, useContext, useMemo, useState } from 'react';
import { styled, Box, Button } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import Fade from '@mui/material/Fade';
import { CONFIG_VIEW_MODE } from './userGroupConfigConstants';
import { ConfigViewContext } from './configViewContext';

const TableViewContentContainer = styled('div')(() => ({
  height: '100%',
}));

const StyledDataGrid = styled(DataGridPremium)(
  ({ theme: { themeColors } }) => ({
    border: `1px solid ${themeColors.borderLowContrast}`,
    borderRadius: 4,
  })
);
const ErrorCell = styled('span')(({ theme: { themeColors } }) => ({
  color: themeColors.primary99,
  border: `2px solid ${themeColors.danger60}`,
  width: 'calc(100% + 20px)',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '0 10px',
  justifyContent: 'flex-start',
  marginLeft: '-10px',
  marginRight: '-10px',
  marginTop: 1,
  marginBottom: 1,
}));

const FlexFooterWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  paddingLeft: '4px',
  height: '52px',
});

const StyledFooterButton = styled(Button)(({ theme: { themeColors } }) => ({
  color: themeColors.buttonSecondaryBackgroundColour,
  fontWeight: 400,
}));

const CustomCellRenderer = ({ value, row, rowErrors }) => {
  const { path } = row;
  const errorExistsForRow = rowErrors[path] !== undefined;

  if (errorExistsForRow) {
    return (
      <ErrorCell>
        Enter value between 0-10 for basket, or decimal for metric scoring
        weight
      </ErrorCell>
    );
  }

  return <span>{value}</span>;
};
const getColumnConfig = (isEdit, rowErrors) => [
  {
    field: 'scoringWeight',
    headerName: 'Scoring Weight',
    flex: 1,
    editable: isEdit,
    renderCell: (params) => (
      <CustomCellRenderer {...params} rowErrors={rowErrors} />
    ),
  },
];

export default function serializeScoringWeightsToDataGridRows(
  basketsScoringWeight,
  metricsScoringWeight,
  rowErrors
) {
  const rows = Object.entries(basketsScoringWeight).reduce(
    (accumulator, [basket, basketScoringWeight]) => {
      const basketName = basket.toUpperCase();
      const basketRow = {
        path: [basketName],
        scoringWeight: rowErrors[basketName]
          ? rowErrors[basketName]
          : basketScoringWeight,
      };

      accumulator.push(basketRow);

      Object.entries(metricsScoringWeight[basket]).forEach(
        ([metric, metricScoringWeight]) => {
          const path = [basketName, metric];
          const metricRow = {
            path,
            scoringWeight: rowErrors[path]
              ? rowErrors[path]
              : metricScoringWeight,
          };
          accumulator.push(metricRow);
        }
      );

      return accumulator;
    },
    []
  );

  return rows.map((row, index) => ({
    ...row,
    id: index,
  }));
}

function isNonNegativeNumber(value) {
  const nonNegativeRegex = /^[0-9]*\.?[0-9]+$/;
  return nonNegativeRegex.test(value);
}

function isNumberBetweenZeroAndTen(value) {
  const betweenZeroAndTenRegex = /^(?:[0-9](?:\.[0-9]*)?|10)$/;
  return betweenZeroAndTenRegex.test(value);
}

const isBasketsScoringWeightRow = (path) => path.length === 1;
const isMetricsScoringWeightRow = (path) => path.length === 2;

const updateRowErrors = (
  path,
  scoringWeight,
  setIsError,
  setRowErrors,
  rowErrors
) => {
  if (
    (isBasketsScoringWeightRow(path) &&
      !isNumberBetweenZeroAndTen(scoringWeight)) ||
    (isMetricsScoringWeightRow(path) && !isNonNegativeNumber(scoringWeight))
  ) {
    setIsError(true);
    setRowErrors({ ...rowErrors, [path]: scoringWeight });
    return true;
  }

  const newErrors = { ...rowErrors };
  delete newErrors[path.join()];
  setRowErrors(newErrors);

  if (Object.keys(newErrors).length === 0) setIsError(false);

  return false;
};

const updateBasketsScoringWeight = (
  path,
  scoringWeight,
  basketWeights,
  onBasketsScoringWeightChange
) => {
  const basketName = path[0].toLowerCase();
  const newBasketsScoringWeight = {
    ...basketWeights,
    [basketName]: parseFloat(scoringWeight),
  };
  onBasketsScoringWeightChange(newBasketsScoringWeight);
};

const updateMetricsScoringWeight = (
  path,
  scoringWeight,
  metricWeights,
  onMetricsScoringWeightChange
) => {
  const [basketName, metricName] = path;
  const newMetricsScoringWeight = {
    ...metricWeights,
    [basketName.toLowerCase()]: {
      ...metricWeights[basketName.toLowerCase()],
      [metricName]: parseFloat(scoringWeight),
    },
  };
  onMetricsScoringWeightChange(newMetricsScoringWeight);
};

export const handleScoringWeightChange = (
  row,
  setIsError,
  basketWeights,
  onBasketsScoringWeightChange,
  metricWeights,
  onMetricsScoringWeightChange,
  rowErrors,
  setRowErrors
) => {
  const { path, scoringWeight } = row;

  if (
    updateRowErrors(path, scoringWeight, setIsError, setRowErrors, rowErrors)
  ) {
    return;
  }

  if (isBasketsScoringWeightRow(path)) {
    updateBasketsScoringWeight(
      path,
      scoringWeight,
      basketWeights,
      onBasketsScoringWeightChange
    );
  }

  if (isMetricsScoringWeightRow(path)) {
    updateMetricsScoringWeight(
      path,
      scoringWeight,
      metricWeights,
      onMetricsScoringWeightChange
    );
  }
};

const AddBasketGridFooter = ({ handleAddBasket }) => {
  const { configViewMode } = useContext(ConfigViewContext);
  return (
    configViewMode === CONFIG_VIEW_MODE.CREATE && (
      <FlexFooterWrapper>
        <StyledFooterButton variant="text" onClick={() => handleAddBasket()}>
          Add Basket +
        </StyledFooterButton>
      </FlexFooterWrapper>
    )
  );
};

export const MarketScoringWeightsTable = ({
  metricsScoringWeight,
  basketsScoringWeight,
  onBasketsScoringWeightChange,
  onMetricsScoringWeightChange,
  setIsError,
  isEdit = false,
}) => {
  const [rowErrors, setRowErrors] = useState({});

  const rows = useMemo(
    () =>
      serializeScoringWeightsToDataGridRows(
        basketsScoringWeight,
        metricsScoringWeight,
        rowErrors
      ),
    [basketsScoringWeight, metricsScoringWeight, rowErrors]
  );

  const handleRowUpdate = useCallback(
    (row, basketWeights, metricWeights) => {
      handleScoringWeightChange(
        row,
        setIsError,
        basketWeights,
        onBasketsScoringWeightChange,
        metricWeights,
        onMetricsScoringWeightChange,
        rowErrors,
        setRowErrors
      );
    },
    [onBasketsScoringWeightChange, onMetricsScoringWeightChange, rowErrors]
  );

  return (
    <Fade in timeout={500}>
      <TableViewContentContainer>
        <StyledDataGrid
          rows={rows}
          treeData
          getTreeDataPath={(row) => row.path}
          columns={getColumnConfig(isEdit, rowErrors)}
          hideFooterRowCount
          processRowUpdate={(updatedRow) => {
            handleRowUpdate(
              updatedRow,
              basketsScoringWeight,
              metricsScoringWeight
            );
            return updatedRow;
          }}
          onProcessRowUpdateError={(error) => {
            // eslint-disable-next-line no-console
            console.error(error);
          }}
          groupingColDef={{
            hideDescendantCount: true,
            headerName: 'Basket/Metric Name',
            flex: 1,
          }}
          defaultGroupingExpansionDepth={isEdit ? 1 : 0}
          slots={{
            footer: AddBasketGridFooter,
            noRowsOverlay: Box,
          }}
          slotProps={
            {
              // TODO
              // footer: { handleAddBasket },
            }
          }
        />
      </TableViewContentContainer>
    </Fade>
  );
};
