import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import { green } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import IncreaseIcon from '@mui/icons-material/ArrowUpward';
import DecreaseIcon from '@mui/icons-material/ArrowDownward';

import TestIcon from '@mui/icons-material/TrendingUp';
import { colorToneMapping } from '@odaia/ui/src/colorConfig';

const useStyles = makeStyles((theme) => ({
  chipStyle: {
    margin: '0.125rem',
    color: theme.palette.primary.contrastText,
  },
  icon: {
    width: '65%',
    height: '65%',
  },
  iconMini: {
    width: '80%',
    height: '80%',
  },
}));

export default function SemiOpaqueChip({
  primaryText = null,
  icon = <TestIcon style={{ width: '65%', height: '65%' }} />,
  onClick = null,
  color = green[600],
  isMiniature = false,
  colorTone = null,
  opacity = 1.0,
}) {
  const classes = useStyles();
  const getColorToneIcon = (tone) => {
    switch (tone) {
      case 'POSITIVE':
        return (
          <IncreaseIcon
            className={isMiniature ? classes.iconMini : classes.icon}
          />
        );
      case 'NEGATIVE':
        return (
          <DecreaseIcon
            className={isMiniature ? classes.iconMini : classes.icon}
          />
        );
      default:
        return null;
    }
  };

  const chipColor = colorTone ? colorToneMapping[colorTone] : color;
  return (
    <Chip
      className={classes.chipStyle}
      size={isMiniature ? 'small' : 'medium'}
      style={{
        backgroundColor: chipColor + (color ? '' : '44'),
        opacity: opacity < 0.2 ? 0.2 : opacity,
      }}
      avatar={
        icon || colorTone ? (
          <Avatar
            style={{ backgroundColor: chipColor + (isMiniature ? '00' : '') }}
          >
            {colorTone && isMiniature ? getColorToneIcon(colorTone) : icon}
          </Avatar>
        ) : null
      }
      label={primaryText}
      onClick={onClick || null}
    />
  );
}
