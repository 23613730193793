import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";

const toLatLng = (coordinate) => ({
  lat: coordinate.latitude,
  lng: coordinate.longitude,
});

export const EntityRoute = ({ routeEntities, setRouteInfo }) => {
  const map = useMap();
  const routesLibrary = useMapsLibrary("routes");
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [routeIndex] = useState(0);
  // Initialize directions service and renderer
  useEffect(() => {
    if (!routesLibrary || !map) return;
    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(
      new routesLibrary.DirectionsRenderer({
        map,
        preserveViewport: true,
        suppressMarkers: true,
      })
    );
  }, [routesLibrary, map]);
  // Use directions service
  useEffect(() => {
    if (!directionsService || !directionsRenderer || !map) return;

    if (!routeEntities || routeEntities.length < 1) {
      directionsRenderer.setMap(null);
      return;
    }

    const newOrigin = toLatLng(routeEntities[0].coordinates);
    const newWaypoints = [];
    for (let i = 1; i < routeEntities.length - 1; i++) {
      newWaypoints.push({
        location: toLatLng(routeEntities[i].coordinates),
      });
    }
    const newDestination = toLatLng(
      routeEntities[routeEntities.length - 1].coordinates
    );

    directionsService
      .route({
        origin: newOrigin,
        destination: newDestination,
        travelMode: routesLibrary.TravelMode.DRIVING,
        waypoints: newWaypoints,
      })
      .then((response) => {
        directionsRenderer.setMap(map);
        directionsRenderer.setDirections(response);
        setRoutes(response.routes);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [directionsService, directionsRenderer, routeEntities, map]);
  // Update direction route
  useEffect(() => {
    if (!directionsRenderer) return;
    directionsRenderer.setRouteIndex(routeIndex);
    const selectedRoute = routes[routeIndex];

    if (selectedRoute) {
      const origin = `${selectedRoute?.legs[0].start_location.lat()},${selectedRoute?.legs[0].start_location.lng()}`;
      const destination = `${selectedRoute?.legs[
        selectedRoute.legs.length - 1
      ].end_location.lat()},${selectedRoute?.legs[
        selectedRoute.legs.length - 1
      ].end_location.lng()}`;
      const urlWaypoints = selectedRoute.legs
        .slice(0, selectedRoute.legs.length - 1)
        .map((step) => `${step.end_location.lat()},${step.end_location.lng()}`);
      const url = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
        origin
      )}&destination=${encodeURIComponent(destination)}${
        urlWaypoints.length > 0
          ? `&waypoints=${encodeURIComponent(urlWaypoints.join("|"))}`
          : ""
      }`;
      setRouteInfo({
        title: selectedRoute.summary,
        link: url,
        duration: selectedRoute.legs.reduce(
          (acc, curr) => acc + curr.duration.value,
          0
        ),
        legs: selectedRoute.legs,
      });
    }
  }, [routeIndex, directionsRenderer, routes]);

  return null;
};
