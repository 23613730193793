import { styled, Typography } from '@mui/material';
import { useCallPlanContext } from './CallPlanContext';
import { CADENCE } from '@odaia/domain/src';
import { formatTimestamp } from '@odaia/domain/src';

const StyledTypography = styled(Typography)(({ theme: { themeColors } }) => ({
  minWidth: '75px',
  fontSize: 16,
  fontWeight: 500,
  color: themeColors.primaryTextColor,
  alignSelf: 'center',
}));

export const CallPlanTimestamp = () => {
  const { latestCurationTimestamp } = useCallPlanContext();

  if (!latestCurationTimestamp) {
    return null;
  }

  const listTime =
    typeof latestCurationTimestamp === 'string'
      ? formatTimestamp({
          timestamp: latestCurationTimestamp,
          cadence: CADENCE.WEEK,
        })
      : 'No';

  return <StyledTypography>{listTime} List</StyledTypography>;
};
