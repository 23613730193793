import { createContext, useContext } from 'react';

export type ShowDialog = (args: {
  title: string;
  content: string;
  onConfirm: () => Promise<void>;
  onCancel?: () => void;
  confirmText?: string;
  loadingText: string;
  cancelText?: string;
}) => void;

export const DialogContext = createContext<ShowDialog | undefined>(undefined);

export const useDialogContext = () => {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error('useDialogContext must be within DialogProvider');
  }
  return context;
};
