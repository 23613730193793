import { useContext, useState } from 'react';
import {
  styled,
  Box,
  IconButton,
  Avatar,
  Card,
  CardActionArea,
  CardHeader,
} from '@mui/material';
import { AppContext } from '../../containers/application/appContext';
import { UserMenuPopover } from './usermenu/userMenuPopover';

export const Container = styled(Box)(() => ({
  marginTop: 'auto',
}));

const StyledCard = styled(Card)(({ theme: { themeColors } }) => ({
  marginTop: 'auto',
  width: 'auto',
  height: 'auto',
  borderWidth: 0,
  borderTop: `1px solid ${themeColors.borderLowContrast}`,
  borderRadius: 0,
  backgroundColor: themeColors.mainBackground,
}));

const StyledCardActionArea = styled(CardActionArea)(
  ({ theme: { themeColors } }) => ({
    '&:hover': {
      color: themeColors.buttonHoverContentColor,
      backgroundColor: themeColors.buttonHoverBackgroundColor,
    },
  })
);

const StyledAvatar = styled(Avatar)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.borderMidContrast,
  color: themeColors.primaryTextColor,
  height: 32,
  width: 32,
  padding: 8,
  fontSize: 16,
}));

const StyledCardHeader = styled(CardHeader)(({ theme: { themeColors } }) => ({
  '& .MuiCardHeader-avatar': {
    marginRight: '8px',
  },
  '& .MuiCardHeader-title': {
    textAlign: 'left',
    color: themeColors.segmentSelectionCaptionColor,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontHeight: 500,
    lineHeight: '120%',
    marginBottom: 2,
  },
  '& .MuiCardHeader-subheader': {
    textAlign: 'left',
    color: themeColors.neutral60,
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontStyle: 'normal',
    fontHeight: 400,
    lineHeight: '120%',
  },
}));

export const CustomIconButton = styled(IconButton)(
  ({ theme: { themeColors } }) => ({
    padding: 0,
    color: themeColors.sideNavBarIconColor,
    svg: {
      height: 24,
      width: 24,
      margin: 0,
    },
  })
);

export const AccountCard = ({ compact = false }) => {
  const { profile } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const fullName = profile?.fullName || 'Unknown User';
  const firstLetterName = fullName.charAt(0)?.toUpperCase() || 'U';
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Container>
      {compact ? (
        <CustomIconButton
          onClick={(event) => {
            handleClick(event);
          }}
        >
          <StyledAvatar aria-label="recipe">{firstLetterName}</StyledAvatar>
        </CustomIconButton>
      ) : (
        <StyledCard raised={false} variant="outlined">
          <StyledCardActionArea onClick={(e) => handleClick(e)}>
            <StyledCardHeader
              avatar={
                <StyledAvatar aria-label="recipe">
                  {firstLetterName}
                </StyledAvatar>
              }
              title={fullName}
              subheader="Signed In"
            />
          </StyledCardActionArea>
        </StyledCard>
      )}
      <UserMenuPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        compact={compact}
      />
    </Container>
  );
};
