import { Redirect, useParams } from 'react-router-dom';

import { SchemaDefinitionForm } from './schemaDefinitionForm';
import {
  useModuleConfigSchemaQuery,
  useModuleConfigSchemaValuesQuery,
} from '../../../request/admin/queries';
import { LoadingContainer } from '../../generic/LoadingContainer';
import { useUpdateModuleConfigSchemasMutationWithMigration } from '../../../request/admin/mutations';

interface Params {
  schemaName?: string;
  marketId?: string;
  userGroup?: string;
}
export const EditSchemaContainer = () => {
  const { schemaName } = useParams<Params>();

  if (!schemaName) {
    return <Redirect to="/admin/appConfig" />;
  }

  const { data: schemaData, isLoading: isLoadingSchema } =
    useModuleConfigSchemaQuery(schemaName);
  const { data: defaultValues, isLoading: isLoadingDefaultValues } =
    useModuleConfigSchemaValuesQuery({
      schemaName,
    });

  const {
    mutateAsync: mutateConfigSchemas,
    isLoading: isConfigSchemasMutationLoading,
    isError: isConfigSchemasMutationError,
    isSuccess: isConfigSchemasMutationSuccess,
  } = useUpdateModuleConfigSchemasMutationWithMigration();

  if (isLoadingSchema || isLoadingDefaultValues) {
    return <LoadingContainer height="100%" />;
  }

  if (!schemaData || !defaultValues) {
    return null;
  }
  const onSave = async (
    schemaName: string,
    schema: Record<string, any>,
    values: Record<string, any>
  ) => {
    await mutateConfigSchemas({
      requestBody: {
        config_schema: schema,
        config_values: values,
        schema_name: schemaName,
      },
    });
  };

  return (
    <SchemaDefinitionForm
      schemaMetadata={{
        name: schemaData.title ?? '',
        description: schemaData.description ?? '',
      }}
      initialSchema={JSON.stringify(schemaData)}
      initialJson={JSON.stringify(defaultValues)}
      onSave={onSave}
      isLoading={isConfigSchemasMutationLoading}
      isError={isConfigSchemasMutationError}
      isSuccess={isConfigSchemasMutationSuccess}
    />
  );
};
