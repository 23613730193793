import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  styled,
  Typography,
} from "@mui/material";
import { FilterSection } from "./filterSidebar";
import { History, Timeline } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import {
  MapViewContext,
  useMapViewContext,
} from "../../contexts/mapViewContext";
import EmptyView from "../../emptyView";

const SectionTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryMaptualListFilteringColor,
}));

const FilterDescription = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tertiaryColor,
  marginBottom: 28,
}));

const CheckboxContainer = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  gridGap: 9,
  alignItems: "flex-start",

  "& .MuiFormControlLabel-label": {
    paddingTop: 1.5,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme: { themeColors } }) => ({
    marginLeft: 0,
    color: themeColors.primaryTextColor,
    ".MuiCheckbox-root": {
      padding: 0,
      marginRight: 9,
      alignSelf: "start",
    },
    paddingTop: 0,
  })
);

const StyledCheckbox = styled(Checkbox)(({ theme: { themeColors } }) => ({
  "&.Mui-checked": {
    color: themeColors.checkboxSelected,
  },
}));

const IconFormControlLabel = styled(Box)(({ theme: { themeColors } }) => ({
  display: "flex",
  alignItems: "center",
  gap: 12,
  svg: {
    color: themeColors.listIconColor,
    fontSize: 20,
  },
}));

const EmptyViewContainer = styled(Box)(() => ({
  marginTop: 32,
}));

const createFormLabel = (
  label: string,
  filterId: string
): JSX.Element | string => {
  switch (filterId) {
    case "behaviouralSegmentTypes":
      if (label === "Recent") {
        return (
          <IconFormControlLabel>
            <History />
            {label}
          </IconFormControlLabel>
        );
      }
      if (label === "Predicted") {
        return (
          <IconFormControlLabel>
            <Timeline />
            {label}
          </IconFormControlLabel>
        );
      }
      return label;
    default:
      return label;
  }
};

interface BehaviouralSegmentDescriptionType {
  template: string;
  params: Record<string, string>;
}

export const formatBehaviouralSegmentDescription = (
  description: BehaviouralSegmentDescriptionType
) => {
  const { template, params } = description;
  if (!params || !template) return template ?? null;

  let str = template;
  (template.match(/{(.+?)}/g) || []).forEach((key: string) => {
    const searchValue = new RegExp(key, "g");

    if (key === "{metric}") {
      str = str.replace(
        searchValue,
        `<b>${params[key.replace("{", "").replace("}", "")]}</b>`
      );
    } else {
      str = str.replace(
        searchValue,
        params[key.replace("{", "").replace("}", "")]
      );
    }
  });
  return str;
};

const BehaviouralSegmentDescription = ({
  segmentDescription,
}: {
  segmentDescription: BehaviouralSegmentDescriptionType;
}) => (
  <FilterDescription
    sx={{ marginLeft: "32px", textAlign: "left" }}
    variant="body2"
    dangerouslySetInnerHTML={{
      __html: formatBehaviouralSegmentDescription(segmentDescription),
    }}
  />
);
export const SegmentFilter = ({
  filters,
  setFilters,
  objective,
  filterMetadata,
  filterMetadataLoading,
}) => {
  const [segmentFilters, setSegmentFilters] = useState(filters.segmentFilter);

  useEffect(() => {
    if (segmentFilters !== filters.segmentFilter) {
      setSegmentFilters(filters.segmentFilter);
    }
  }, [filters]);

  const createFilters = (newFilter) => {
    if (segmentFilters.includes(newFilter)) {
      const newFilters = segmentFilters.filter(
        (filter) => filter !== newFilter
      );
      setFilters({ ...filters, segmentFilter: newFilters });
    } else {
      setFilters({ ...filters, segmentFilter: [...segmentFilters, newFilter] });
    }
  };

  if (filterMetadataLoading)
    return (
      <EmptyViewContainer>
        <EmptyView isLoading loadingTitle="Loading Segment Filters" />
      </EmptyViewContainer>
    );

  if (
    !filterMetadataLoading &&
    !filterMetadata?.behaviouralSegmentFilters?.length
  )
    return null;

  return (
    <>
      {filterMetadata?.behaviouralSegmentFilters?.map((filter) => (
        <FilterSection key={filter.name}>
          <SectionTitle variant="h5">{filter.title}</SectionTitle>
          <FilterDescription variant="body2">
            {filter.description}
          </FilterDescription>
          <CheckboxContainer sx={{ gridTemplateColumns: "1fr 1fr" }}>
            {filter.options?.map((option) => (
              <Box
                key={option.id}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <StyledFormControlLabel
                  control={
                    <StyledCheckbox
                      name={option.label}
                      onChange={() => createFilters(option.label)}
                      data-testid={`test-${filter.name}-${option.id}-checkbox`}
                      checked={segmentFilters.includes(option.label)}
                    />
                  }
                  label={createFormLabel(option.label, filter.name)}
                />
                {option.description && (
                  <BehaviouralSegmentDescription
                    segmentDescription={option.description}
                  />
                )}
              </Box>
            ))}
          </CheckboxContainer>
        </FilterSection>
      ))}
      <Divider />
    </>
  );
};
