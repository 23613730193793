import { useQuery } from 'react-query';
import { getInsights } from '../../request/projectRequests';

export const useInsights = ({ entityId, regionId, projectId }) => {
  const {
    data: insights,
    isLoading: isInsightsBusy,
    refetch: refetchInsights,
  } = useQuery([projectId, regionId, entityId, 'insights'], async () => {
    try {
      const response = await getInsightsFromApi({
        entityId,
        regionId,
        projectId,
      });

      return {
        ...response.data?.insights,
        objectives: (response.data?.insights?.objectives || []).filter(
          (objective) => !objective.isNonScoring
        ),
      };
    } catch {
      return null;
    }
  });

  const getInsightsFromApi = ({ entityId, regionId, projectId }) => {
    if (!entityId || !regionId || !projectId) {
      return Promise.reject();
    }
    return getInsights(entityId, projectId, regionId);
  };

  return {
    insights,
    isInsightsBusy,
    refetchInsights,
  };
};
