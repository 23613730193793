import { useEffect, useState, useRef } from 'react';
import DisplayProjects from '../formDisplays/displayProjects';
import PopoverSelectionInput from './popoverSelectionInput';
import { Title, Description } from '../styles/styledComponents';

export const filterProjects = (filters, projects) =>
  projects.filter((project) => filters.includes(`market_${project.marketId}`));

const ProjectsSelectionInput = ({
  options = {},
  id,
  onChange,
  projects = [],
  value = [],
  title = '',
  subtitle = '',
  error,
  errorMessage,
}) => {
  const [projectFilters, setProjectFilters] = useState(value);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [projectIds, setProjectIds] = useState([]);

  useEffect(() => {
    const newProjectIds = filteredProjects.map(
      (filteredProject) => filteredProject.id
    );

    setProjectIds(newProjectIds);
  }, [filteredProjects]);

  useEffect(() => {
    onChange(id, projectIds);
  }, [projectIds]);

  const initialRender = useRef(false);
  useEffect(() => {
    setFilteredProjects(filterProjects(projectFilters, projects));

    if (initialRender.current) onChange('projectFilters', projectFilters);
    else initialRender.current = true;
  }, [projectFilters]);

  const updateProjectFilters = (val) => {
    setProjectFilters(val);
  };

  return (
    <div>
      <Title variant="h4">{title}</Title>
      <Description variant="body1">{subtitle}</Description>

      <PopoverSelectionInput
        buttonText="Join Projects"
        id="projectFilters"
        filterOptions={options}
        initialValue={value}
        onChange={updateProjectFilters}
        popoverSearchBarLabel="Projects that involve"
        popoverButtonText="All Projects"
        additionalPopoverText="The user will have access to projects that involve the market selected"
        error={error}
        errorMessage={errorMessage}
      />

      <DisplayProjects filteredProjects={filteredProjects} />
    </div>
  );
};

export default ProjectsSelectionInput;
