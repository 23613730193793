/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import LinkIcon from '@mui/icons-material/Link';
import UnLinkIcon from '@mui/icons-material/LinkOff';
import CancelIcon from '@mui/icons-material/Cancel';
import UniqueIDIcon from '@mui/icons-material/AccountCircle';
import TimeIcon from '@mui/icons-material/Alarm';
import ActivityIcon from '@mui/icons-material/DirectionsRun';
import { blueGrey } from '@mui/material/colors';
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  Fab,
  Fade,
  Grow,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    'align-items': 'center',
    flex: 1,
    'flex-direction': 'column',
    'justify-content': 'center',
    overflowX: 'auto',
  },
  labelGroup: {
    'justify-content': 'flex-end',
    'flex-direction': 'column',
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    'text-align': 'left',
    padding: theme.spacing(1),
    maxWidth: '600px',
    fontSize: 16,
  },
  paddingBox: {},
  datadrop: {
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    overflowY: 'auto',
    width: '100%',
    height: '60vh',
  },
  content: {
    fontSize: 11,
    width: '100px',
    padding: '0',
    margin: '0',
  },
  textField: {
    width: '200px',
    marginRight: '15px',
    fontSize: 12,
  },
  fabStyle: {
    marginTop: '10px',
    elevation: 1,
  },
  listContainer: {
    marginTop: '20px',
    minWidth: '450px',
    width: '100%',
    height: '400px',
    overflow: 'auto',
  },
  listStyle: {
    width: '100%',
    height: '400px',
  },
  listItemStyle: {
    height: '55px',
    paddingLeft: '35px',
  },
  listTitle: {
    padding: '15px',
  },
  dialogTitle: {
    marginTop: '15px',
    marginLeft: '15px',
  },
  buttonContainer: {
    width: '100%',
    height: 'auto',
    'margin-top': '15px',
    display: 'flex',
    'flex-direction': 'row',
    'flex-wrap': 'wrap',
    'align-items': 'center',
    'justify-content': 'flex-end',
  },
  button: {
    margin: '20px',
    'margin-left': '0px',
  },
  iconStyle: {
    marginRight: '10px',
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.text.secondary,
    padding: 0,
    paddingLeft: '5px',
    width: 'auto',
  },
  body: {
    fontSize: 14,
    padding: 0,
    paddingRight: '10px',
    paddingLeft: '5px',
    width: 'auto',
  },
}))(TableCell);

const StyledColumnRowCell = withStyles((theme) => ({
  head: {},
  body: {
    backgroundColor:
      theme.palette.mode === 'dark' ? blueGrey[800] : blueGrey[100],
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.common.white
        : theme.palette.common.black,
    /* '&:nth-of-type(odd)':{
      backgroundColor: theme.palette.mode === 'dark' ? blueGrey[800] : blueGrey[200],
    }, */
    fontSize: 14,
    width: '290px',
    minWidth: '290px',
    padding: '15px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.Paper,
    },
  },
}))(TableRow);

export default function IngestionReviewData({
  ingestNav,
  ingest,
  myIndex,
  setInclude,
  setColFriendlyName,
  validColumnReview,
  onComplete,
  getExistingColumns,
  updateColumnMeta,
}) {
  const classes = useStyles();
  // eslint-disable-next-line no-param-reassign
  onComplete.action = () => {
    validColumnReview(ingest, myIndex);
  };

  useEffect(() => {
    if (!ingest.existingColumns) {
      getExistingColumns();
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [linkCol, setLinkCol] = useState(0);

  const handleClickOpen = (linkColIndex) => {
    setLinkCol(linkColIndex);
    setOpen(true);
    if (selectedIndex !== 0) setSelectedIndex(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const getColumnIcon = (colTag) => {
    switch (colTag) {
      case 'USERID':
        return <UniqueIDIcon />;
      case 'ACTIVITY':
        return <ActivityIcon />;
      case 'TIMESTAMP':
        return <TimeIcon />;
      case 'ACTIVITYTIMESTAMP':
      case 'TIMESTAMPACTIVITY':
        return (
          <>
            <TimeIcon />
            <ActivityIcon />
          </>
        );
      default:
        return null;
    }
  };

  const sortMatch = (source, match) => {
    let ret = [];
    if (source && match) {
      const topPart = source.filter((i) => i.friendlyName === match);
      const bottomPart = source.filter((i) => i.friendlyName !== match);
      if (topPart && topPart.length > 0) ret = topPart.concat(bottomPart);
      else ret = bottomPart;
    } else if (source) ret = source;

    return ret;
  };

  return (
    <div className={classes.container}>
      <Grow in>
        <div className={classes.labelGroup}>
          <div className={classes.paddingBox} />
          <Typography className={classes.title} variant="h2" component="h3">
            Review Your Data
          </Typography>
          <Typography
            className={classes.subtitle}
            variant="h5"
            color="textSecondary"
          >
            Select the columns of data you would like to be included in future
            analysis. We also recommend that you rename columns to something
            more meaningful to you.
          </Typography>
        </div>
      </Grow>
      <Fade in timeout={1000}>
        <div className={classes.datadrop}>
          {ingest.columnDisplay && ingest.columnSamples && ingest.columnMeta ? (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={
                        ingest.columnMeta
                          ? ingest.columnMeta.every((item) => !item.isExcluded)
                          : false
                      }
                      disabled={
                        ingestNav.completedSteps[myIndex] || ingest.isBusy
                      }
                      onChange={(event) => {
                        if (ingest.columnMeta && ingest.columnMeta.length > 0) {
                          ingest.columnMeta.forEach((item, i) => {
                            setInclude(i, !event.target.checked);
                          });
                        }
                      }}
                      inputProps={{ 'aria-label': 'select all columns' }}
                    />
                  </StyledTableCell>
                  {ingest.columnDisplay.length
                    ? ingest.columnDisplay.map((head, i) => (
                        <StyledTableCell key={i}>{head.title}</StyledTableCell>
                      ))
                    : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {ingest.columnSamples.length
                  ? ingest.columnSamples.map((row, i) => (
                      <StyledTableRow
                        key={i}
                        hover
                        disabled={
                          ingestNav.completedSteps[myIndex] || ingest.isBusy
                        }
                        onClick={() => {
                          // if (!(ingestNav.completedSteps[myIndex] || ingest.isBusy))
                          // setInclude(i, !ingest.columnMeta[i].isExcluded)
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={!ingest.columnMeta[i].isExcluded}
                            disabled={
                              ingestNav.completedSteps[myIndex] || ingest.isBusy
                            }
                            onChange={() => {
                              setInclude(i, !ingest.columnMeta[i].isExcluded);
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            inputProps={{ 'aria-label': 'select all columns' }}
                          />
                        </TableCell>
                        <StyledColumnRowCell>
                          <TextField
                            id={`standard-with-placeholder${i}`}
                            error={
                              ingest.txtInputErrMsg &&
                              ingest.txtInputErrMsg.target === i
                            }
                            label={
                              ingest.txtInputErrMsg &&
                              ingest.txtInputErrMsg.target === i
                                ? ingest.txtInputErrMsg.message
                                : ingest.columnMeta[i].associatedColumn
                                ? ingest.columnMeta[i].associatedColumn
                                : ingest.columnMeta[i].friendlyName
                            }
                            placeholder={ingest.columnMeta[i].friendlyName}
                            className={classes.textField}
                            margin="dense"
                            onChange={(event) => {
                              setColFriendlyName(i, event.target.value);
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            disabled={
                              ingest.columnMeta[i].isExcluded ||
                              ingestNav.completedSteps[myIndex] ||
                              ingest.isBusy ||
                              ingest.columnMeta[i].associatedColumn !== null
                            }
                          />
                          <Fab
                            aria-label="link"
                            size="small"
                            color={
                              ingest.columnMeta[i].associatedColumn
                                ? 'secondary'
                                : 'primary'
                            }
                            className={classes.fabStyle}
                            disabled={
                              ingest.columnMeta[i].isExcluded ||
                              ingest.isBusy ||
                              ingestNav.completedSteps[myIndex]
                            }
                            onClick={() => {
                              if (ingest.columnMeta[i].associatedColumn) {
                                const newColumnMeta = {
                                  ...ingest.columnMeta[i],
                                };
                                newColumnMeta.associatedColumn = null;
                                newColumnMeta.colTag = '';
                                newColumnMeta.isEncrypted = false;
                                updateColumnMeta(newColumnMeta);
                              } else handleClickOpen(i);
                            }}
                          >
                            {ingest.columnMeta[i].associatedColumn ? (
                              <UnLinkIcon />
                            ) : (
                              <LinkIcon />
                            )}
                          </Fab>
                        </StyledColumnRowCell>
                        {Object.values(row).map((cell, j) => (
                          <StyledTableCell key={j}>
                            <Typography
                              className={classes.content}
                              variant="body1"
                              color="textPrimary"
                              noWrap
                            >
                              {cell}
                            </Typography>
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))
                  : null}
              </TableBody>
              <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={open}
              >
                <DialogTitle
                  id="simple-dialog-title"
                  color="textSecondary"
                  className={classes.dialogTitle}
                >
                  Link {ingest.columnMeta[linkCol].friendlyName} to an Existing
                  Column
                </DialogTitle>
                <div className={classes.listContainer}>
                  <List className={classes.listStyle}>
                    {ingest.existingColumns
                      ? sortMatch(
                          ingest.existingColumns,
                          ingest.columnMeta[linkCol].friendlyName
                        ).map((column, i) => (
                          <ListItemButton
                            key={i}
                            className={classes.listItemStyle}
                            dense
                            selected={selectedIndex === i}
                            onClick={(event) => handleListItemClick(event, i)}
                          >
                            <ListItemIcon>
                              {' '}
                              {getColumnIcon(column.colTag)}
                            </ListItemIcon>
                            <ListItemText className={classes.listTitle}>
                              {column.friendlyName}{' '}
                            </ListItemText>
                          </ListItemButton>
                        ))
                      : null}
                  </List>
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <CancelIcon className={classes.iconStyle} />
                    Cancel
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      // setGoal(Object.keys(activities[selectedIndex])[0]);
                      const newColumnMeta = { ...ingest.columnMeta[linkCol] };
                      const sortedColumns = sortMatch(
                        ingest.existingColumns,
                        ingest.columnMeta[linkCol].friendlyName
                      );

                      newColumnMeta.associatedColumn =
                        sortedColumns[selectedIndex].friendlyName;
                      newColumnMeta.colTag =
                        sortedColumns[selectedIndex].colTag;
                      newColumnMeta.isEncrypted =
                        sortedColumns[selectedIndex].isEncrypted;
                      updateColumnMeta(newColumnMeta);
                      handleClose();
                    }}
                  >
                    <LinkIcon className={classes.iconStyle} />
                    Link
                  </Button>
                </div>
              </Dialog>
            </Table>
          ) : null}
        </div>
      </Fade>
      {ingest.errorMessage ? (
        <Typography className={classes.subtitle} variant="body1" color="error">
          {' '}
          ERROR: {ingest.errorMessage}
        </Typography>
      ) : null}
    </div>
  );
}
