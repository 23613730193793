import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, styled } from '@mui/material';

const Container = styled(Box)(({ theme }) => ({
  padding: 56,
  color: theme.themeColors.emptyText,
  backgroundColor: theme.themeColors.contentCardBackgroundColor,
  display: 'flex',
  flexDirection: 'column',
  gap: 13,
  marginTop: 24,
}));

const IconText = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 12,
  justifyContent: 'center',
  '.MuiSvgIcon-root': {
    color: theme.themeColors.emptyText,
    width: 20,
    height: 20,
  },
}));

const SubText = styled(Typography)(() => ({
  fontSize: 12,
}));

export const EmptyView = () => (
  <Container>
    <IconText>
      <InfoOutlinedIcon />
      <Typography variant="h5"> Report not available </Typography>
    </IconText>
    <Box data-testid="disclaimer-text">
      <SubText variant="body1">
        We currently only provide reports at the territory level. Please select
        a territory to generate a report.
      </SubText>
    </Box>
  </Container>
);
