import { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import GavelIcon from '@mui/icons-material/Gavel';
import { blue, amber, grey } from '@mui/material/colors';
import Grow from '@mui/material/Grow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {
  baseAlbInstance,
  baseAlbUrl,
  baseURL,
  isProd,
} from '../../request/config';

const useStyles = makeStyles(() => ({
  panel: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

export default function ConstructionView() {
  const classes = useStyles();
  const [displayResponse, setDisplayResponse] = useState('');
  const [isBusy, setIsBusy] = useState(false);
  const [requestUrl, setRequestUrl] = useState('');

  const getRequest = (url) => {
    setIsBusy(true);

    baseAlbInstance
      .get(url, { timeout: 30000 })
      .then((response) => {
        try {
          setDisplayResponse(JSON.stringify(response));
          // eslint-disable-next-line no-empty
        } catch (e) {}
        setIsBusy(false);
      })
      .catch((error) => {
        // handle error
        setDisplayResponse(JSON.stringify(error));
        setIsBusy(false);
      });
  };

  return (
    <Grow in timeout={500}>
      <div className={classes.panel} style={{ align: 'center' }}>
        <GavelIcon style={{ width: 60, height: 60, color: amber[500] }} />
        <h2 style={{ fontSize: 32, marginLeft: 25, color: grey[500] }}>
          Under Construction
        </h2>
        {isProd ? null : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 50,
            }}
          >
            <Typography variant="subtitle1" style={{ marginBottom: 30 }}>
              ****************** DEVELOPMENT TESTING TOOL *********************
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {'[BaseURL] '}
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ color: blue.A200 }}
            >
              {baseAlbUrl}
            </Typography>

            <TextField
              id="filled-basic"
              label="Enter the Remaining API URL"
              variant="filled"
              style={{ width: '80%', marginTop: 15 }}
              onChange={(event) => {
                setRequestUrl(event.target.value);
              }}
              disabled={isBusy}
            />
            {isBusy ? (
              <>
                <CircularProgress />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ marginTop: 30, maxWidth: '100%', color: 'yellow' }}
                >
                  {`Making Request: ${baseAlbUrl}${requestUrl}`}
                </Typography>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 15 }}
                onClick={() => {
                  getRequest(requestUrl);
                }}
              >
                Make Request
              </Button>
            )}
            <Typography
              variant="subtitle1"
              style={{ marginTop: 15, maxWidth: '100%' }}
            >
              {displayResponse && displayResponse !== ''
                ? `[ Result From: ] ${baseURL}alb2apigw-proxy-lm/${requestUrl}`
                : ''}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ marginTop: 30, maxWidth: '75%' }}
              align="left"
            >
              {displayResponse}
            </Typography>
          </div>
        )}
      </div>
    </Grow>
  );
}
