import { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { green } from '@mui/material/colors';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import ConnectIcon from '@mui/icons-material/Power';
import DisconnectIcon from '@mui/icons-material/PowerOff';
import ConnectedIcon from '@mui/icons-material/CloudDone';
import Fade from '@mui/material/Fade';
import { triggerShopifyIngestion } from '../../request/ingestRequests';
import * as layoutStyles from '../../styles/layoutStyles';
import shopify from '../../assets/connectors/shopifylogo.png';
import adobe from '../../assets/connectors/adobelogo.png';
import google from '../../assets/connectors/googlelogo.png';
import salesforce from '../../assets/connectors/salesforcelogo.png';
import s3 from '../../assets/connectors/s3logo.png';
import bigquery from '../../assets/connectors/bigquerylogo.png';
import marketo from '../../assets/connectors/marketologo.png';
import veeva from '../../assets/connectors/veevalogo.png';
import eloqua from '../../assets/connectors/eloqualogo.png';
import iqvia from '../../assets/connectors/iqvialogo.png';
import SectionHeader from '../generic/sectionHeader';
import ConnectorCard from './connectorCard';
import QuickCloseButton from '../generic/quickCloseButton';

const useStyles = makeStyles((theme) => ({
  inlineContainer: {
    ...layoutStyles.columnContainerCentered,
  },
  rowContainer: {
    ...layoutStyles.rowContainerLeftAligned,
    padding: 50,
    flexWrap: 'wrap',
  },
  titleRow: {
    ...layoutStyles.rowContainerLeftAligned,
  },
  root: {
    display: 'flex',
    height: 160,
    marginRight: 20,
    marginBottom: 20,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    ...layoutStyles.columnContainerLeftAligned,
    width: 300,
    height: '100%',
    marginLeft: 15,
    marginTop: 5,
  },
  cover: {
    margin: 20,
    width: 120,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  connectedIcon: {
    marginRight: 10,
  },
  title: {
    fontSize: 20,
  },
  subtitle: {
    fontSize: 13,
    marginTop: 3,
  },
  buttonStyle: {
    marginTop: 10,
  },
}));

const defaultMsg = 'Coming Soon! We are working hard on it ...';
const plannedConnectors = [
  {
    title: 'Adobe Analytics',
    subtitle: defaultMsg,
    image: adobe,
  },
  {
    title: 'Veeva',
    subtitle: defaultMsg,
    image: veeva,
  },
  {
    title: 'Oracle Eloqua',
    subtitle: defaultMsg,
    image: eloqua,
  },
  {
    title: 'Salesforce',
    subtitle: defaultMsg,
    image: salesforce,
  },
  {
    title: 'Google Analytics',
    subtitle: defaultMsg,
    image: google,
  },
  {
    title: 'AWS S3',
    subtitle: defaultMsg,
    image: s3,
  },
  {
    title: 'Google BigQuery',
    subtitle: defaultMsg,
    image: bigquery,
  },
  {
    title: 'Adobe Marketo Engage',
    subtitle: defaultMsg,
    image: marketo,
  },
];

export default function DataConnectorView({ profile }) {
  const classes = useStyles();
  const [connected, setConnected] = useState(false);

  return (
    <>
      <QuickCloseButton />
      <SectionHeader title="Data Connectors" marginTop={0} />
      <div className={classes.rowContainer}>
        {profile?.organization?.industry === 'ECOMMERCE' ? (
          <Fade in timeout={700}>
            <Card className={classes.root}>
              <CardContent className={classes.content}>
                <div className={classes.titleRow}>
                  {connected ? (
                    <ConnectedIcon
                      className={classes.connectedIcon}
                      style={{ color: green[300] }}
                    />
                  ) : null}
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.title}
                  >
                    Shopify
                  </Typography>
                </div>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  align="left"
                  className={classes.subtitle}
                >
                  Connect to ODAIA&apos;s Shopify app to onboard your data for
                  analysis.
                </Typography>
                {connected ? (
                  <Button
                    className={classes.buttonStyle}
                    size="small"
                    color="primary"
                    variant="contained"
                    startIcon={<DisconnectIcon />}
                    onClick={() => {
                      setConnected(false);
                    }}
                    disabled
                  >
                    Connected
                  </Button>
                ) : (
                  <Button
                    className={classes.buttonStyle}
                    size="small"
                    color="primary"
                    variant="contained"
                    startIcon={<ConnectIcon />}
                    onClick={() => {
                      triggerShopifyIngestion();
                      setConnected(true);
                    }}
                  >
                    Connect
                  </Button>
                )}
              </CardContent>

              <CardMedia
                className={classes.cover}
                image={shopify}
                title="Shopify Connector"
              />
            </Card>
          </Fade>
        ) : (
          <Fade in timeout={500}>
            <Card className={classes.root}>
              <CardContent className={classes.content}>
                <div className={classes.titleRow}>
                  <ConnectedIcon
                    className={classes.connectedIcon}
                    style={{ color: green[300] }}
                  />
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.title}
                  >
                    IQVIA
                  </Typography>
                </div>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  align="left"
                  className={classes.subtitle}
                >
                  IQVIA has been connected.
                </Typography>
                <Button
                  className={classes.buttonStyle}
                  size="small"
                  color="primary"
                  style={{ marginTop: 24 }}
                  variant="contained"
                  startIcon={<ConnectedIcon />}
                  onClick={() => {}}
                  disabled
                >
                  Connected
                </Button>
              </CardContent>

              <CardMedia
                className={classes.cover}
                image={iqvia}
                title="IQIVIA Connector"
              />
            </Card>
          </Fade>
        )}
        {Array.isArray(plannedConnectors)
          ? plannedConnectors.map((item, i) => (
              <ConnectorCard
                key={`${i}-${item.title}`}
                title={item.title}
                subTitle={item.subtitle}
                image={item.image}
                animateOrder={i}
              />
            ))
          : null}
      </div>
    </>
  );
}

DataConnectorView.propTypes = {
  profile: PropTypes.shape({
    organization: PropTypes.shape({
      industry: PropTypes.string,
    }),
  }),
};
