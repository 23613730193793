import { Box, Button, CircularProgress, styled } from '@mui/material';

import { StyledModalBody } from './styledComponents';
import {
  ModalDisplay,
  ModalFooter,
  ModalHeader,
} from '../generic/modalDisplay';

const ProgressWrapper = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
});

const StyledTable = styled('table')({
  width: '100%',
});

const TableCell = styled('td')(({ theme: { themeColors } }) => ({
  textAlign: 'left',
  color: themeColors.segmentSelectionCaptionColor,
  fontSize: 14,
  marginRight: '24px',
  paddingBottom: '16px',
}));

const TitleTableCell = styled(TableCell)(({ theme: { themeColors } }) => ({
  color: themeColors.primary99,
}));

const TableHeaderCell = styled(TableCell)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
  marginRight: '0px',
  fontSize: 12,
}));

export function TotalMetricsModal({
  isOpen,
  onClose,
  isLoading,
  isSuccess,
  marketName,
  metrics,
}) {
  return (
    <ModalDisplay isOpen={isOpen} style={{ maxWidth: 850, width: '70%' }}>
      <ModalHeader onClose={onClose}>
        {isLoading && 'Searching For Metrics'}
        {isSuccess && `${marketName} Metrics List`}
      </ModalHeader>
      <StyledModalBody>
        {isLoading && (
          <ProgressWrapper>
            <CircularProgress />
          </ProgressWrapper>
        )}
        {isSuccess && <MetricTable data={metrics} />}
      </StyledModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </ModalFooter>
    </ModalDisplay>
  );
}

export function MetricTable({ data }) {
  const formatTimestamp = (timestamp) => {
    const [year, month, day] = timestamp.split('-');
    return `${month}/${day}/${year}`;
  };
  return (
    <StyledTable>
      <thead>
        <tr>
          <TableHeaderCell>Metric name</TableHeaderCell>
          <TableHeaderCell>Metric ID</TableHeaderCell>
          <TableHeaderCell>Timestamp</TableHeaderCell>
        </tr>
      </thead>
      <tbody>
        {Object.values(data).map((metric) => (
          <tr key={metric.metricId}>
            <TitleTableCell>{metric.metricFriendlyName}</TitleTableCell>
            <TableCell>{metric.metricId}</TableCell>
            <TableCell>{formatTimestamp(metric.lastTimestamp)}</TableCell>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
}
