import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Description } from '../styles/styledComponents';

const NumProjectsToDisplay = 3;

const DisplayProjects = ({ filteredProjects = [] }) => {
  const [showMore, setShowMore] = useState(false);
  const [projectsToDisplay, setProjectsToDisplay] = useState([]);

  useEffect(() => {
    const newProjectsToDisplay = [];
    if (showMore) {
      filteredProjects.forEach((filteredProject) => {
        newProjectsToDisplay.push(filteredProject);
      });
    } else {
      filteredProjects
        .slice(0, NumProjectsToDisplay)
        .forEach((filterProject) => {
          newProjectsToDisplay.push(filterProject);
        });
    }

    setProjectsToDisplay(newProjectsToDisplay);
  }, [showMore, filteredProjects]);

  return (
    <>
      {Object.values(projectsToDisplay).map((projectToDisplay) => (
        <span key={projectToDisplay.id}>
          <Description variant="h5">{projectToDisplay?.label}</Description>
        </span>
      ))}

      {filteredProjects.length > NumProjectsToDisplay ? (
        <Button
          data-testid="test-show-more"
          aria-label="showMore"
          variant="text"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore
            ? 'Show Less'
            : `Show ${filteredProjects.length - NumProjectsToDisplay}  More`}
        </Button>
      ) : null}
    </>
  );
};

export default DisplayProjects;
