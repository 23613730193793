import { useContext } from 'react';
import { Skeleton, styled, Typography, useTheme } from '@mui/material';
import { ProductPerformanceMetadataContext } from './data/productPerformanceMetadataContext';
import { ProductPerformanceChartContext } from './data/productPerformanceChartContext';
import { ErrorContainer } from '../../../generic/errorContainer';
import { LineChart } from '../shared/LineChart';

const SkeletonBar = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  backgroundColor: themeColors.surfaceEmpty,
}));

export const ProductPerformanceLineChart = () => {
  const { themeColors } = useTheme();

  const {
    isMetadataLoading,
    selectedMetric,
    selectedCadence,
    currentTabMetadata,
    selectedCardTab,
    selectedUnit,
  } = useContext(ProductPerformanceMetadataContext);

  const { isDataLoading, isDataError, isDataIdle, refetchData, title, data } =
    useContext(ProductPerformanceChartContext);

  const metricName = currentTabMetadata?.metrics?.find(
    (metric) => metric.rxType === selectedMetric
  )?.displayName;

  if (isMetadataLoading || isDataIdle || isDataLoading) {
    return <SkeletonBar animation="wave" height={225} />;
  }

  if (isDataError) {
    return <ErrorContainer handleRetry={refetchData} />;
  }

  if (!data?.length) {
    return (
      <ErrorContainer
        title="Graph and table not available"
        dataTestId="empty-product-performance-line-chart"
        description="We do not have enough data to generate a graph and table for these products."
      />
    );
  }

  return (
    <>
      <Typography
        variant="h6"
        textAlign="left"
        color={themeColors.primaryTextColor}
      >
        {title}, {selectedCadence.label}
      </Typography>

      <LineChart
        selectedCadence={selectedCadence}
        selectedCardTab={selectedCardTab}
        unit={selectedUnit}
        metricName={metricName}
        data={data}
        legendPlugin={{ display: false }}
        dataTestId="product-performance-line-chart"
      />
    </>
  );
};
