import { useRef, useState } from 'react';
import { useTheme } from '@mui/styles';
import { Bar } from 'react-chartjs-2';
import { Plugin } from 'chart.js';
import { Tooltip, createTooltip } from '../tooltip';
import { formatCellValue, formatYAxisTitle } from '../utils';

const verticalCategoryBackgroundPlugin: Plugin = {
  id: 'verticalCategoryBackground',
  afterTooltipDraw: (chart, args, options) => {
    const { ctx } = chart;
    const { left, top, height, width } = chart.chartArea;

    ctx.save();

    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color;

    const numberOfCategoryColumns: number = chart.scales.x.ticks.length;
    const overlayWidth: number = width / numberOfCategoryColumns;
    const activeColumnIndex: number = args.tooltip.dataPoints[0].dataIndex;

    ctx.fillRect(
      left + overlayWidth * activeColumnIndex,
      top,
      overlayWidth,
      height
    );

    ctx.restore();
  },
};

const DECIMAL_PLACES = 1;

export const SparklineBarGraph = ({
  data,
  labels,
  expanded = false,
  yTitle = '',
  tooltipDataPointTitle = '',
  valueType = '',
}) => {
  const [tooltip, setTooltip] = useState({
    trend: {},
    opacity: 0,
    top: 0,
    left: 0,
    date: '',
    value: '',
    display: 'none',
  });
  const { themeColors } = useTheme();
  const chartRef = useRef(null);

  const chartData = {
    labels,
    datasets: [
      {
        tick: false,
        scale: false,
        label: '',
        data,
        fill: false,
        backgroundColor: themeColors.dataGeneralColor,
        hoverBackgroundColor: themeColors.dataGeneralColor,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    animation: expanded,
    scales: {
      x: {
        display: expanded,
        ticks: {
          color: themeColors.chartDataLabel,
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: !!yTitle,
          text: formatYAxisTitle(yTitle),
          font: 12,
          color: themeColors.chartAxisLabel,
        },
        ticks: {
          color: themeColors.chartDataLabel,
          padding: 8,
          callback: (value) =>
            formatCellValue(value, valueType, DECIMAL_PLACES),
        },
        grid: {
          display: expanded,
          color: themeColors.chartLines,
        },
        display: expanded,
        border: { display: false },
      },
    },
    legend: {
      display: false,
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          if (!expanded) return;
          createTooltip({
            context,
            tooltip,
            updateTooltip: (newTooltipData) => setTooltip(newTooltipData),
          });
        },
      },
      verticalCategoryBackground: {
        color: themeColors.popoverBorderColor,
      },
    },
  };

  const plugins = expanded ? [verticalCategoryBackgroundPlugin] : [];
  return (
    <>
      <Bar
        options={options}
        data={chartData}
        ref={chartRef}
        plugins={plugins}
      />
      {expanded && (
        <Tooltip
          tooltip={tooltip}
          tooltipDataPointTitle={tooltipDataPointTitle}
        />
      )}
    </>
  );
};
