import { useContext } from 'react';
import isValidProp from '@emotion/is-prop-valid';
import {
  styled,
  Box,
  Tabs,
  Tab,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';

import { getTimeframeName, TIMEFRAME_TABS } from '../utils';
import { TerritoryOverviewContext } from '../territoryOverviewContext';
import { trackTerritoryOverviewOptionSet } from '../../../trackers/mixpanel';

export const TimeframeTabs = ({ changeDescription }) => {
  const {
    data,
    selectedTimeframeID,
    setSelectedTimeframeID,
    distributionType,
    categoryOptions,
    selectedCategory,
    metricOptions,
    selectedMetric,
    cadenceOptions,
    handleComparisonTimeFrameChange,
  } = useContext(TerritoryOverviewContext);

  const onTabClick = (tabId) => {
    changeDescription(data?.description);

    const newTimeframe = getTimeframeName(tabId);

    const oldTimeframe = getTimeframeName(selectedTimeframeID);

    trackTerritoryOverviewOptionSet(
      'Time Range',
      newTimeframe,
      oldTimeframe,
      [
        newTimeframe,
        distributionType,
        categoryOptions[selectedCategory]?.label,
        metricOptions[selectedMetric]?.label,
      ],
      false
    );

    setSelectedTimeframeID(tabId);
    handleComparisonTimeFrameChange(tabId);
  };

  return (
    <Box>
      <SettingsTabs
        value={selectedTimeframeID || 0}
        data-testid="timeframe-tabs"
      >
        {Object.values(TIMEFRAME_TABS).map((tab) => {
          const { id, name } = tab;

          const optionAvailable =
            cadenceOptions?.length > 0 && cadenceOptions?.includes(id);

          return optionAvailable ? (
            <TimeframeTab
              key={id}
              label={name}
              value={id}
              iconPosition="start"
              onClick={() => {
                onTabClick(id);
              }}
            />
          ) : (
            <CustomTooltip
              title="We do not have the data to generate this report."
              arrow
            >
              <DisabledTimeframeTab
                key={`${id}-key`}
                label={name}
                value={id}
                iconPosition="start"
              />
            </CustomTooltip>
          );
        })}
      </SettingsTabs>
    </Box>
  );
};

const SettingsTabs = styled(Tabs)({
  display: 'flex',
  alignItems: 'center',
  minHeight: 40,
  svg: {
    height: 20,
  },
  '.MuiTabs-indicator': {
    display: 'none',
  },

  '.MuiTabs-flexContainer': {
    height: 40,
  },
});

const TimeframeTab = styled(Tab)(({ theme: { themeColors } }) => ({
  minHeight: 'auto',
  padding: '8px 12px',
  textTransform: 'none',
  fontSize: 16,
  fontWeight: 400,
  color: themeColors.buttonContentColor,
  backgroundColor: themeColors.buttonBackgroundColor,
  border: `1px solid ${themeColors.buttonActiveBorderColor}`,
  marginRight: -1,
  '&.Mui-selected': {
    color: themeColors.buttonActiveContentColor,
    backgroundColor: themeColors.buttonActiveBackgroundColor,
  },
  '&:hover': {
    color: themeColors.buttonHoverContentColor,
    backgroundColor: themeColors.buttonHoverBackgroundColor,
  },

  '&:first-of-type': {
    borderRadius: '4px 0px 0px 4px',
  },

  '&:last-of-type': {
    borderRadius: '0px 4px 4px 0px',
    marginRight: 0,
  },
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...(props as TooltipProps)} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.themeColors.tooltipContentColor,
    backgroundColor: theme.themeColors.tooltipSurfaceColor,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.themeColors.tooltipSurfaceColor,
  },
}));

const validateProp = (prop) => prop === 'icon' || isValidProp(prop);

const DisabledTimeframeTab = styled(Tab, { shouldForwardProp: validateProp })(
  ({ theme: { themeColors } }) => ({
    display: 'flex',
    flexDirection: 'row',
    opacity: 1,
    minHeight: 'auto',
    padding: '8px 10px',
    border: `1px solid ${themeColors.buttonDisabledSurfaceColor}`,
    textTransform: 'none',
    color: themeColors.buttonDisabledContentColor,
    fontSize: 16,
    cursor: 'not-allowed',
    backgroundColor: themeColors.buttonDisabledSurfaceColor,
    marginRight: -1,

    '&:first-of-type': {
      borderRadius: '4px 0px 0px 4px',
    },

    '&:last-of-type': {
      borderRadius: '0px 4px 4px 0px',
      marginRight: 0,
    },
  })
);
