import { useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActionArea from '@mui/material/CardActionArea';
import LinkController from '../../containers/links/linkController';

const useStyles = makeStyles((theme) => ({
  cardStyle: {
    margin: '0.25rem',
    width: '18rem',
    height: 'auto',
    padding: '0.25rem',
    paddingTop: '0.3rem',
    paddingBottom: '0.3rem',
    textAlign: 'left',
  },
  primaryText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
    maxWidth: '12rem',
    margin: 0,
    padding: 0,
    lineHeight: 1.1,
    marginRight: '0.25rem',
    align: 'left',
  },
  secondaryText: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    maxWidth: '8rem',
  },
  textWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 0,
    padding: 0,
  },
}));

export default function SimpleCard({
  avatar,
  icon,
  primaryText,
  secondaryText,
  type = 'ANALYSIS_HYPERLINK',
  linkLocation = 'EXTERNAL',
  analysisId,
  match,
}) {
  const classes = useStyles();

  const controllerRef = useRef(null);
  const controllerClick = () => {
    if (controllerRef) controllerRef.current.click();
  };

  const itemsCount = primaryText.split(',').length;

  return (
    <Card className={classes.cardStyle}>
      <CardActionArea onClick={controllerClick}>
        <CardHeader
          avatar={
            <Avatar aria-label="client-profile" src={avatar || null}>
              {icon || 'S'}
            </Avatar>
          }
          title={
            <div className={classes.textWrapper}>
              {primaryText.split(',').map((item, i) => (
                <p className={classes.primaryText} key={item}>
                  {itemsCount > 1 && i < itemsCount - 1 ? `${item}, ` : item}
                </p>
              ))}
            </div>
          }
          subheader={
            <Typography
              variant="subtitle1"
              className={classes.secondaryText}
              align="left"
              noWrap
            >
              {secondaryText}
            </Typography>
          }
        />
      </CardActionArea>
      <LinkController
        ref={controllerRef}
        type={type}
        linkLocation={linkLocation}
        analysisId={analysisId}
        match={match}
      />
    </Card>
  );
}
