import { styled } from '@mui/material';

import Typography from '@mui/material/Typography';
import { formatEntityName } from '../../../../utils/formatEntityName';
import { DemandTableDefaultCell } from './DemandTableDefaultCell';
import { ExternalLinkTableCell } from '../../components/ExternalLinkTableCell';
import { Region, RegionRow, Row } from '../../types';
import { getRegionLabel } from '../../utils';

const EntityName = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tableCellDefaultContent,
}));

interface DemandTableCellProps {
  row: Row;
  type: string;
  graph: Object;
  chartLabels: Object;
  metricType: string;
  modalGraphTitle: string;
  region?: Region;
}

export const DemandTableCell = ({
  row,
  type,
  graph,
  chartLabels,
  metricType,
  modalGraphTitle,
  region,
}: DemandTableCellProps) => {
  switch (type) {
    case 'label':
      return (
        <EntityName variant="body2">
          {formatEntityName(getRegionLabel(row as RegionRow))}
        </EntityName>
      );

    case 'externalLink':
      return <ExternalLinkTableCell data={row?.data} region={region} />;

    default:
      return (
        <DemandTableDefaultCell
          row={row}
          type={type}
          graph={graph}
          chartLabels={chartLabels}
          metricType={metricType}
          modalGraphTitle={modalGraphTitle}
        />
      );
  }
};
