import { Star, StarOutlineOutlined, VisibilityOff, VisibilityOffOutlined,  } from '@mui/icons-material';


import VacationIcon from '@odaia/ui/src/assets/vacation.svg?react';
import VacationOutlinedIcon from '@odaia/ui/src/assets/VacationOutlined.svg?react';

export const userCreatedListOptions = {
  starred: {
    id: 'starred',
    label: 'Starred',
    description: 'HCPs you wish to track',
    segmentDescription:
      'HCPs that you have identified as those you would like to focus on',
    icon: {
      default: Star,
      outlined: StarOutlineOutlined
    }
  },
  noSee: {
    id: 'noSee',
    label: 'No-See',
    description: 'HCPs you cannot contact',
    segmentDescription:
      'HCPs that you have identified as those you cannot contact',
    icon: {
      default: VisibilityOff,
      outlined: VisibilityOffOutlined
    }
  },
  longTermLeave: {
    id: 'longTermLeave',
    label: 'Long-Term Leave',
    description: 'HCPs that are on leave',
    segmentDescription:
      'HCPs that you have identified as those that are on leave',
      icon: {
        default: VacationIcon,
        outlined: VacationOutlinedIcon
      }
  },
} as const;

export const availabilityOptions = [
  {
    id: 'noSee',
    label: 'No-See',
    switchDescription: 'Include No-See HCPs',
  },
  {
    id: 'longTermLeave',
    label: 'Long-Term Leave',
    switchDescription: 'Include Long-Term Leave HCPs',
  },
];
