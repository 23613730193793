/* eslint-disable no-restricted-syntax */
import { useParams } from 'react-router-dom';
import {
  CallPlanRow,
  RowComponentProps,
} from '@odaia/shared-components/src/callPlan';
import { useCallPlanContext } from '@odaia/shared-components/src/callPlan/CallPlanContext';
import { useEntitySegments } from './useEntitySegments';
import { useHcpCrmData } from '../../pulse/views/rep/table/row/hooks/useHcpCrmData';

export const CallPlanRowProvider = ({
  objectiveId,
  cadence,
  row,
  isLoading,
  rank,
  onRowClick,
}: RowComponentProps) => {
  const { projectId, maptualListId: territoryId } = useParams();

  const {
    productLineId,
    projectTargetProducts,
    latestCurationTimestamp,
    sfRegionId,
  } = useCallPlanContext();

  const objectiveProducts = projectTargetProducts.reduce(
    (allProducts, product) => allProducts.concat(product.products),
    []
  );

  const { data: crmData, isLoading: isCrmDataLoading } = useHcpCrmData({
    productLineId,
    projectId,
    regionId: territoryId,
    entityId: row.entity.id,
    products: objectiveProducts,
    latestCurationTimestamp,
  });

  const { data: entitySegments, isLoading: isSegmentsLoading } =
    useEntitySegments({
      productLineId,
      projectId,
      objectiveId: row.objectiveId,
      regionId: sfRegionId,
      entityId: row.entity.id,
    });

  return (
    <CallPlanRow
      objectiveId={objectiveId}
      cadence={cadence}
      row={row}
      isLoading={isLoading}
      rank={rank}
      onRowClick={onRowClick}
      crmData={crmData}
      isCrmDataLoading={isCrmDataLoading}
      entitySegments={entitySegments || []}
      isSegmentsLoading={isSegmentsLoading}
    />
  );
};
