import { Children } from 'react';
import { styled, ListItemButton, Button, Popover } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DropDownIcon from '@mui/icons-material/ArrowDropDown';

const DescriptionWrapper = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 9,
  paddingLeft: 21,
  color: themeColors.primaryMaptualListFilteringColor,
}));

export const DescriptionBody = styled(Box)(() => ({
  marginTop: 9,
  marginBottom: 10,
  display: 'inline',
}));

const Selection = styled(Box)(({ theme: { themeColors } }) => ({
  borderTop: '1px solid',
  borderColor: themeColors.borderLowContrast,
  padding: 9,
  paddingTop: 13,
  paddingLeft: 21,
}));

const Subtitle = styled(Typography)(() => ({
  lineHeight: 1.4,
}));

const SelectionButtonGroup = styled('div')(() => ({
  marginTop: 13,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
}));

const LearnMoreButton = styled(Button)(({ theme: { themeColors } }) => ({
  padding: 5,
  marginLeft: -5,
  textTransform: 'none',
  color: themeColors.textButtonColor,
}));

const ButtonText = styled(Typography)(
  ({ theme: { themeColors }, disabled }) => ({
    flexGrow: 2,
    width: '100%',
    color: themeColors.primaryTextColor,
    opacity: disabled ? 0.3 : 1,
    fontSize: 14,
  })
);

const StyledButton = styled(Button)(({ theme: { themeColors }, disabled }) => ({
  height: '100%',
  flexGrow: 1,
  padding: '8px 12px',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: themeColors.segmentSelectionHoverBackgroundColor,
  },
  '.MuiSvgIcon-root': {
    color: themeColors.primaryTextColor,
    opacity: disabled ? 0.3 : 1,
    width: 18,
    height: 18,
    paddingRight: 0,
  },
  borderRadius: 3,
}));

export const ListSelectionTitleTypography = styled(Subtitle)(() => ({
  marginLeft: 9,
}));

export const ListSelectionItemButton = styled(ListItemButton)(
  ({ theme: { themeColors } }) => ({
    fontSize: 16,
    fontWeight: 400,
    height: 44,
    paddingLeft: 13,
    marginLeft: -13,
    color: themeColors.primaryTextColor,
    '&:hover': {
      borderRadius: 3,
      backgroundColor: themeColors.segmentSelectionHoverBackgroundColor,
    },
    '&&.Mui-selected': {
      backgroundColor: themeColors.segmentSelectionSelectedBackgroundColor,
      color: themeColors.selectedSegmentColor,
      borderRadius: 3,
    },
  })
);

export const ListSelectionDescription = ({
  children,
  onLearnMoreClick,
  showLearnMoreButton,
}) => (
  <DescriptionWrapper>
    {children}
    {showLearnMoreButton && (
      <div>
        <LearnMoreButton variant="text" onClick={onLearnMoreClick}>
          Learn more
        </LearnMoreButton>
      </div>
    )}
  </DescriptionWrapper>
);
export const ListSelectionOptions = ({ children, selection }) => {
  const childrenList = Children.toArray(children);
  return (
    <Selection className="intercom-field-objective-selection-options">
      <SelectionButtonGroup>
        {Children.map(childrenList, (child, i) => (
          <child.type
            key={`list-item-option-${i}`}
            selected={child.props.value === selection}
            {...child.props}
          />
        ))}
      </SelectionButtonGroup>
    </Selection>
  );
};

export const ListSelectionPopoverButton = ({
  label,
  onClickAction,
  icon,
  disabled,
}) => (
  <StyledButton
    onClick={onClickAction}
    endIcon={<DropDownIcon />}
    disabled={disabled}
  >
    {icon}
    <ButtonText noWrap align="left" disabled={disabled}>
      {label}
    </ButtonText>
  </StyledButton>
);

export const ListSelectionPopover = styled(
  (props) => (
    <Popover
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          height: 'auto',
        },
      }}
      {...props}
    />
  ),
  { shouldForwardProp: (prop) => prop !== 'anchorTop' }
)(({ anchorTop }) => ({
  '.MuiPopover-paper': {
    marginTop: 5,
    marginLeft: -1,
    maxHeight: `calc(100% - ${anchorTop + 16}px)`,
    transformOrigin: '0 !important',
  },
}));

export const ListSelectionPopoverContent = styled(Box)(() => ({ width: 355 }));
