import { useEffect, useState } from 'react';
import { styled, Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import PopoverWindowSelection from './popoverWindowSelection';
import {
  ErrorText,
  PopoverWindow,
  StyledPopover,
} from '../styles/styledComponents';

const ChipsGroup = styled('div')(() => ({
  width: '30%',
  paddingTop: 10,
  paddingBottom: 10,
  display: 'grid',
  gridTemplateColumns: '100% 100% 100%',
  gap: '5px',
}));

const StyledChip = styled(Chip)(({ theme: { themeColors } }) => ({
  background:
    'linear-gradient(0deg, rgba(68, 83, 106, 0.22), rgba(68, 83, 106, 0.22))',
  backgroundColor: themeColors.mainBackground,
  border: '1px solid ',
  borderColor: themeColors.borderLowContrast,
  borderRadius: '20px',
  display: 'flex',
  width: '100%',
  gridGap: '10px',
  padding: 0,
  '.MuiChip-root': {
    display: 'flex',
  },
  '.MuiChip-label': {
    textOverflow: 'clip',
    flexGrow: 1,
    paddingLeft: '15px',
    fontSize: 14,
  },
}));

const format = (data) => {
  if (Array.isArray(data)) {
    return data.reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue]: {
          label: currentValue,
          id: currentValue,
        },
      }),
      {}
    );
  }
  return data;
};

const PopoverSelectionInput = ({
  buttonText = '',
  id = '',
  filterOptions = {},
  initialValue = [],
  onChange,
  popoverSearchBarLabel = '',
  popoverButtonText = '',
  additionalPopoverText = '',
  error,
  errorMessage,
}) => {
  const [popoverFilters, setPopoverFilters] = useState(initialValue);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverSelectionClick = (val) => {
    setPopoverFilters(val);
  };

  const formattedFilterOptions = format(filterOptions);

  const handleChipDelete = (removeId) => {
    setPopoverFilters(popoverFilters.filter((x) => x !== removeId));
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'simple-popover' : undefined;
  useEffect(() => {
    onChange(popoverFilters);
  }, [popoverFilters]);

  return (
    <>
      <Button
        data-testid="test-outlined-button"
        aria-label="outlinedButton"
        variant="outlined"
        onClick={handleClick}
        style={{ marginTop: 4 }}
      >
        {buttonText}
      </Button>
      {error?.[id] ? (
        <ErrorText variant="body1"> {errorMessage} </ErrorText>
      ) : null}

      <StyledPopover
        id={popoverId}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width: '35%' },
        }}
      >
        <PopoverWindow>
          <PopoverWindowSelection
            allFilters={formattedFilterOptions}
            id={id}
            value={popoverFilters}
            onChange={handlePopoverSelectionClick}
            searchBarLabel={popoverSearchBarLabel}
            buttonText={popoverButtonText}
            additionalText={additionalPopoverText}
          />
        </PopoverWindow>
      </StyledPopover>
      {popoverFilters.length > 0 ? (
        <ChipsGroup>
          {popoverFilters.map((v) => (
            <StyledChip
              key={formattedFilterOptions[v]?.id}
              label={formattedFilterOptions[v]?.label}
              variant="outlined"
              onDelete={() => handleChipDelete(formattedFilterOptions[v]?.id)}
            />
          ))}
        </ChipsGroup>
      ) : null}
    </>
  );
};

export default PopoverSelectionInput;
