import { SchemaType } from './constants';

export const schemaToInitialValues = (schema) => {
  let defaultValue = null;

  const { type } = schema;

  switch (type) {
    case SchemaType.OBJECT:
      defaultValue = {};
      Object.keys(schema.properties).forEach((key) => {
        defaultValue[key] = schemaToInitialValues(schema.properties[key]);
      });
      break;
    case SchemaType.ARRAY:
      defaultValue = [schemaToInitialValues(schema.items)];
      break;
    case SchemaType.NUMBER:
      defaultValue = 0;
      break;
    case SchemaType.STRING:
      defaultValue = '';
      break;
    case SchemaType.BOOLEAN:
      defaultValue = false;
      break;
    default:
      defaultValue = null;
  }
  return defaultValue;
};
