import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Typography from '@mui/material/Typography';
import { styled, Box } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';

const TaskIconWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ theme, color }) => ({
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  gridGap: 10,
  marginBottom: 4,
  marginTop: 'auto',
  padding: '6px 12px',
  borderLeft: `4px solid ${theme.palette?.[color]?.main || 'white'}`,
}));

const StyledTooltip = withStyles({
  tooltip: {
    fontSize: '14px',
    backgroundColor: '#313B49',
  },
  arrow: {
    color: '#313B49',
  },
})(Tooltip);

export default function AutomatedReportBadge({ status, tooltipMsg, message }) {
  let color = 'white';
  let Icon = null;
  switch (status) {
    case 'warning':
      color = 'warning';
      Icon = ErrorOutlineOutlinedIcon;
      break;
    case 'success':
      color = 'success';
      Icon = CheckCircleOutlineOutlinedIcon;
      break;
    case 'failure':
      color = 'error';
      Icon = CancelOutlinedIcon;
      break;
    default:
      break;
  }

  if (!Icon) {
    return null;
  }

  return (
    <TaskIconWrapper color={color}>
      <StyledTooltip title={tooltipMsg} arrow placement="top">
        <Icon fontSize="small" color={color} />
      </StyledTooltip>
      <Typography variant="body2">{message}</Typography>
    </TaskIconWrapper>
  );
}
