import { Stack, Typography } from '@mui/material';
import { useModuleConfigSchemasQuery } from '../../../request/admin/queries';
import { LoadingContainer } from '../../generic/LoadingContainer';
import { SchemaList } from './SchemaList';

export const SchemaInventory = () => {
  const { data, isLoading } = useModuleConfigSchemasQuery();

  return (
    <Stack gap={2} alignItems="flex-start">
      <Typography color={(theme) => theme.themeColors.neutral90} variant="h4">
        Schema Inventory
      </Typography>
      {isLoading ? (
        <LoadingContainer height="300px" />
      ) : (
        <SchemaList data={data} />
      )}
    </Stack>
  );
};
