import { useContext } from 'react';
import { PulseContext } from './context/PulseContext';
import { NavigationContentWrapper } from '../shared/NavigationContentWrapper';

export const PulseContentWrapper = ({ children }) => {
  const { navigationOpen, setNavigationOpen } = useContext(PulseContext);

  return (
    <NavigationContentWrapper
      isNavigationOpen={navigationOpen}
      setIsNavigationOpen={setNavigationOpen}
    >
      {children}
    </NavigationContentWrapper>
  );
};
