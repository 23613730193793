import React, { useContext } from 'react';
import {
  Box,
  Button,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { OpenInNewOutlined } from '@mui/icons-material';
import { ExternalLinkPopover } from './ExternalLinkPopover';
import { ROUTES } from '../../../constants';
import { TerritoryOverviewContext } from '../territoryOverviewContext';
import {
  ENTITY_TYPES_MAP,
  URL_SEARCH_PARAMS_ENUM,
} from '../../entityOverview/constants';
import { formatEntityName } from '../../../utils/formatEntityName';

const NavigateOutIcon = styled(OpenInNewOutlined)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.neutral70,
    width: 16,
    height: 16,
    transformOrigin: 'center',
  })
);

const StyledButton = styled(Button)(({ theme: { themeColors }, isActive }) => ({
  padding: 0,
  minWidth: 40,
  minHeight: 40,

  '& .MuiSvgIcon-root': {
    color: themeColors.utilityActiveContentColor,
  },

  backgroundColor: isActive
    ? themeColors.utilityActiveSurfaceColor
    : 'transparent',
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...(props as TooltipProps)} classes={{ popper: className }} />
))(({ theme: { themeColors, shadows } }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: themeColors.tooltipContentColor,
    backgroundColor: themeColors.tooltipSurfaceColor,
    boxShadow: shadows[1],
    fontSize: 14,
    fontWeight: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: themeColors.tooltipSurfaceColor,
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
    {
      marginRight: 8,
    },
}));

export const ExternalLinkTableCell = ({ data, region }) => {
  const { name: geographicEntityName, id: geographicEntityId } = region;

  if (
    !data ||
    geographicEntityName?.length < 1 ||
    geographicEntityId?.length < 1
  ) {
    return null;
  }

  const { hcpRouting, entityOverviewRouting } = data;
  const { regionId: maptualListId, projectId } = useContext(
    TerritoryOverviewContext
  );

  const hasBothRoutingOptions = hcpRouting && entityOverviewRouting;

  const projectAndRegionUrl = `${ROUTES.PROJECTS}/${projectId}/maptualListId/${maptualListId}`;
  const baseRoute = `${ROUTES.FIELD}${projectAndRegionUrl}`;
  const encodedGeographicEntityName = encodeURIComponent(geographicEntityName);

  const onHCPClick = () => {
    const route = `${baseRoute}?filterTerritories=${encodedGeographicEntityName}`;

    window.open(route);
  };

  const onAccountViewClick = (entityDisplayName: string) => {
    const entityType = ENTITY_TYPES_MAP[entityDisplayName].replace(/_/g, '-');
    const route = `${baseRoute}${ROUTES.ENTITY_OVERVIEW}/${entityType}?${URL_SEARCH_PARAMS_ENUM.REGION_ID}=${geographicEntityId}&${URL_SEARCH_PARAMS_ENUM.REGION_LABEL}=${encodedGeographicEntityName}`;

    window.open(route);
  };

  const formattedRegionName = formatEntityName(geographicEntityName);

  return (
    <Box data-testid="external-link-wrapper">
      {hasBothRoutingOptions ? (
        <ExternalLinkPopover
          row={data}
          regionName={formattedRegionName}
          toHCPClick={onHCPClick}
          toAccountViewClick={onAccountViewClick}
        />
      ) : (
        <CustomTooltip
          title={`View HCPs in ${formattedRegionName}`}
          placement="left"
          arrow
        >
          <StyledButton
            onClick={onHCPClick}
            data-testid={`${geographicEntityName}-external-link-hcp-link-only-btn`}
          >
            <NavigateOutIcon />
          </StyledButton>
        </CustomTooltip>
      )}
    </Box>
  );
};
