import { useState } from 'react';
import { styled, Box, Button, Typography } from '@mui/material';
import CurationExecutionModal from './curationExecutionModal';
import { capitalizeFirstLetter } from '../timeline/Timeline';
import { useIsAuthorized } from '../../../../../../hooks/useIsAuthorized';
import { RBAC_PERMISSIONS } from '../../../../../../constants';

const HeaderContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  justifyContent: 'space-between',
  marginBottom: 24,
  alignItems: 'center',
}));

const ButtonContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  columnGap: 16,
}));

const TitleContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const StyledButton = styled(Button)(() => ({
  color: '#C2CAD6',
  height: 32,
  borderRadius: 4,
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
  border: '1px solid #313B49',
  backgroundColor: '#1F232C',
  fontWeight: 400,
  '&:disabled': {
    opacity: 0.3,
  },
}));

const StyledButtonTitle = styled(Typography)(() => ({
  color: '#C2CAD6',
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%',
}));

const StyledTypography = styled(Typography)(() => ({
  color: '#E0E4EB',
  textAlign: 'left',
  fontFamily: 'Roboto',
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '135%',
  marginBottom: 4,
}));

const StyledDateString = styled(Typography)(() => ({
  color: '#8595AD',
  textAlign: 'left',
  fontFamily: 'Roboto',
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%',
}));

export const CurationExeuction = ({ projectId, events, executionMode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formattedExecutionMode = capitalizeFirstLetter(executionMode);
  const [formattedDate, formattedTime] = getStartEventTime(events);
  const isLiveButtonDisabled = isButtonDisabled(events);

  const hasCurationAdminPermissions = useIsAuthorized([
    RBAC_PERMISSIONS.CURATION_ADMIN,
  ]);
  return (
    <>
      <HeaderContainer>
        <TitleContainer>
          <StyledTypography variant="h5">{`${formattedExecutionMode} Run`}</StyledTypography>
          {formattedDate && (
            <StyledDateString variant="h7">{`Started on ${formattedDate} at ${formattedTime}`}</StyledDateString>
          )}
        </TitleContainer>
        <ButtonContainer>
          {hasCurationAdminPermissions && (
            <StyledButton
              disabled={isLiveButtonDisabled}
              variant="text"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <StyledButtonTitle>{`Start ${formattedExecutionMode} Run`}</StyledButtonTitle>
            </StyledButton>
          )}
        </ButtonContainer>
      </HeaderContainer>
      <CurationExecutionModal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        projectId={projectId}
        mode={executionMode}
      />
    </>
  );
};

const getStartEventTime = (events) => {
  const start = events.find((i) => i.event_code === 'curation_started');
  if (!start) {
    return [];
  }
  const { timestamp: startTimestamp } = start;
  const date = new Date(startTimestamp);
  const formattedDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;
  const hours = date.getHours();
  const minutes = `0${date.getMinutes()}`;
  const seconds = `0${date.getSeconds()}`;
  const formattedTime = `${hours}:${minutes.substr(-2)}:${seconds.substr(-2)}`;
  return [formattedDate, formattedTime];
};

const isButtonDisabled = (events) => {
  if (events.length === 0) return false;
  const lastEvent = events.slice(-1)[0];
  if (
    lastEvent.event_code !== 'publisher_complete' &&
    lastEvent.status !== 'failure'
  )
    return true;
  return false;
};
