import { styled } from '@mui/material';
import { SignOutButton } from '../buttons/signOutButton';
import { AppSelectionButton } from '../buttons/appSelectionButton';
import { Preferences } from '../buttons/preferences';
import useFeatureToggles from '../../../hooks/useFeatureToggles';
import { ROUTES } from '../../../constants';

const StyledContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: theme.themeColors.paperColor,
  border: `1px solid ${theme.themeColors.borderLowContrast}`,
  borderRadius: '4px',
  padding: '16px',
}));

export default function UserMenuOptions({ profile, selectApp }) {
  const featureToggles = useFeatureToggles();

  // eslint-disable-next-line no-restricted-globals
  const routeContains = (route) => location.pathname.includes(route);

  const showPreferences = () => {
    if (!featureToggles(profile.userGroup, 'lightTheme')) return false;
    return routeContains(ROUTES.FIELD) || routeContains(ROUTES.PULSE);
  };

  return (
    profile && (
      <StyledContainer>
        {showPreferences() && <Preferences />}
        <AppSelectionButton selectApp={selectApp} />
        <SignOutButton />
      </StyledContainer>
    )
  );
}
