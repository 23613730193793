import { useState } from 'react';
import PopoverWindowCheckboxInput from './popoverWindowCheckboxInput';
import { StyledTextField, Description } from '../styles/styledComponents';

function PopoverWindowSelection({
  allFilters,
  id,
  onChange,
  value,
  searchBarLabel = '',
  buttonText = '',
  additionalText = '',
}) {
  const [filterInput, setFilterInput] = useState('');
  const filterValues = Object.values(allFilters);
  const filteredFilterValues = filterValues.filter((filterValue) =>
    filterValue.label.toLowerCase().includes(filterInput.toLowerCase())
  );
  const filteredFilters = filteredFilterValues.reduce(
    (previousValue, filter) => ({
      ...previousValue,
      [filter.id]: filter,
    }),
    {}
  );

  return (
    <>
      <StyledTextField
        label={searchBarLabel}
        id="filterInput"
        value={filterInput}
        onChange={(event) => setFilterInput(event.target.value)}
        fullWidth
      />

      {additionalText ? (
        <Description variant="body2">{additionalText}</Description>
      ) : null}

      <PopoverWindowCheckboxInput
        options={filteredFilters}
        id={id}
        onChange={onChange}
        initialOptions={value}
        buttonText={buttonText}
      />
    </>
  );
}

export default PopoverWindowSelection;
