import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AppsIcon from '@mui/icons-material/Apps';
import { AppContext } from '../../../containers/application/appContext';
import { ROUTES } from '../../../constants';
import { UserMenuButton } from './userMenuButton';

export const AppSelectionButton = ({ selectApp }) => {
  const { profile } = useContext(AppContext);
  const routeHistory = useHistory();

  return (
    profile.role?.maptualAccess?.length > 1 && (
      <UserMenuButton
        onClick={() => {
          selectApp(null);
          routeHistory.push(ROUTES.APP_SELECTION);
        }}
        startIcon={<AppsIcon />}
        buttonText="Switch Apps"
      />
    )
  );
};
