import { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import {
  Step,
  Stepper,
  StepLabel,
  styled,
  Box,
  Typography,
  Button,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { InfoOutlined, Replay } from '@mui/icons-material';
import { STEPS, STATUSES } from './csvDeliveryConstants';
import {
  updateCsvDeliveryProjectStatus,
  resetCsvDeliveryProject,
} from '../../../../request/csvDeliveryDashboard';
import { CsvDeliveryResetModal } from './CsvDeliveryResetModal';

const FlexColumnBase = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const StepperContainer = styled(FlexColumnBase)({
  gap: 16,
});

const ProjectHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  height: 53,
});

const ProjectDetailsContainer = styled(Box)({
  display: 'flex',
  gap: 8,
});

const ProjectDetails = styled(FlexColumnBase)({
  gap: 8,
  textAlign: 'left',
  justifyContent: 'center',
});

const ProjectName = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
});

const InfoIcon = styled(InfoOutlined)({
  width: 16,
});

const StyledReplayIcon = styled(Replay)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral70,
  width: 16,
  height: 16,
}));

export const ResetProjectButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
});

const UserGroup = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
}));

const StyledButton = styled(Button)({
  height: 32,
});

const StyledStepLabel = styled(StepLabel)(
  ({ theme: { themeColors }, areAllStepsCompleted }) => ({
    '.MuiStepIcon-text': {
      fill: 'initial',
    },
    '.MuiStepIcon-root': {
      color: themeColors.neutral30,
    },
    '.MuiStepIcon-root.Mui-completed': {
      color: areAllStepsCompleted
        ? themeColors.feedbackSuccessPrimary
        : themeColors.feedbackInfoSecondary,
    },
    '.MuiCircularProgress-svg': {
      color: themeColors.feedbackSecondary,
    },
  })
);

const LoadingIcon = styled(CircularProgress)(({ theme: { themeColors } }) => ({
  color: themeColors.feedbackInfoSecondary,
}));

export const CsvDeliveryStepper = ({ project }) => {
  const queryClient = useQueryClient();
  const [currentStep, setCurrentStep] = useState(STEPS.dataReady);
  const [isCompleteLoading, setIsCompleteLoading] = useState(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const orderedStatuses = Object.fromEntries(
    Object.entries(STEPS).map(([key, _value]) => [key, project.statuses[key]])
  );

  useEffect(() => {
    Object.entries(project.statuses).forEach(([step, status]) => {
      if (status === 'In progress') {
        setCurrentStep(STEPS[step]);
      }
    });
  }, [project.statuses]);

  const isCompleteEnabled = () =>
    [STEPS.qaTesting, STEPS.baTesting, STEPS.delivery].includes(currentStep) &&
    !areAllStepsCompleted() &&
    !isCompleteLoading;

  const areAllStepsCompleted = () =>
    Object.values(project.statuses).every(
      (status) => status === STATUSES.COMPLETED
    );

  const completeStep = async () => {
    setIsCompleteLoading(true);
    const currentStepKey = Object.keys(STEPS).find(
      (key) => STEPS[key] === currentStep
    );
    await updateCsvDeliveryProjectStatus(
      project.projectId,
      currentStepKey,
      STATUSES.COMPLETED
    );

    const nextStepKey = getNextStepKey();
    if (nextStepKey) {
      await updateCsvDeliveryProjectStatus(
        project.projectId,
        nextStepKey,
        STATUSES.IN_PROGRESS
      );
    }
    await queryClient.refetchQueries('deliveryStatuses');
    setIsCompleteLoading(false);
  };

  const getNextStepKey = () => {
    if (currentStep === STEPS.qaTesting) {
      return Object.keys(STEPS).find((key) => STEPS[key] === STEPS.baTesting);
    }

    if (currentStep === STEPS.baTesting) {
      return Object.keys(STEPS).find((key) => STEPS[key] === STEPS.delivery);
    }

    return null;
  };

  const handleProjectReset = async () => {
    await resetCsvDeliveryProject(project.projectId);
    await queryClient.refetchQueries('deliveryStatuses');
    setIsResetModalOpen(false);
  };

  return (
    <StepperContainer>
      <ProjectHeader>
        <ProjectDetailsContainer>
          <ProjectDetails>
            <Tooltip title={project.projectId} arrow placement="top">
              <ProjectName variant="h5">
                {project.businessUnit}: {project.projectName}
                <InfoIcon />
                <ResetProjectButton onClick={() => setIsResetModalOpen(true)}>
                  <StyledReplayIcon />
                </ResetProjectButton>
              </ProjectName>
            </Tooltip>
            <UserGroup variant="body1">{project.userGroup}</UserGroup>
          </ProjectDetails>
        </ProjectDetailsContainer>

        <StyledButton
          variant="outlined"
          disabled={!isCompleteEnabled()}
          onClick={completeStep}
        >
          Complete {currentStep}
        </StyledButton>
      </ProjectHeader>

      <Stepper alternativeLabel>
        {Object.entries(orderedStatuses).map(([step, status]) => (
          <Step
            key={step}
            completed={status === STATUSES.COMPLETED}
            active={status === STATUSES.IN_PROGRESS}
          >
            <StyledStepLabel
              icon={
                status === STATUSES.IN_PROGRESS && <LoadingIcon size={24} />
              }
              areAllStepsCompleted={areAllStepsCompleted()}
            >
              {STEPS[step]}
            </StyledStepLabel>
          </Step>
        ))}
      </Stepper>
      <CsvDeliveryResetModal
        isModalOpen={isResetModalOpen}
        onConfirm={handleProjectReset}
        onClose={() => setIsResetModalOpen(false)}
        project={project}
      />
    </StepperContainer>
  );
};
