import { Button, styled } from '@mui/material';

export const StyledUserMenuButton = styled(Button)(
  ({ theme: { themeColors }, disabled }) => ({
    height: '100%',
    width: '100%',
    padding: '12px',
    minHeight: '36px',
    justifyContent: 'flex-start',
    color: themeColors.buttonContentColor,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    '&:hover': {
      backgroundColor: themeColors.buttonHoverBackgroundColor,
      color: themeColors.buttonHoverContentColor,
    },
    '& .MuiSvgIcon-root': {
      color: disabled ? 'unset' : themeColors.listIconColor,
    },
  })
);

export const UserMenuButton = ({
  onClick,
  startIcon,
  buttonText,
  disabled,
}) => (
  <StyledUserMenuButton
    onClick={onClick}
    startIcon={startIcon}
    disabled={disabled}
  >
    {buttonText}
  </StyledUserMenuButton>
);
