import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import Grow from '@mui/material/Grow';

import MaptualLogo from '../../../assets/maptual_logo_dark.svg?react';
import { SignOutButton } from '../../../components/accountCard/buttons/signOutButton';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  textStyle: {
    fontSize: 13,
    fontWeight: 500,
    maxWidth: 230,
  },
}));

const Loading = () => {
  const classes = useStyles();

  return (
    <div data-testid="profile-loading-view">
      <CircularProgress size={30} style={{ marginBottom: 15 }} />
      <Typography
        variant="subtitle1"
        color="textSecondary"
        className={classes.textStyle}
      >
        Loading Account Profile ...
      </Typography>
    </div>
  );
};

const ErrorOnLoading = () => {
  const classes = useStyles();

  return (
    <div data-testid="profile-loading-error-view">
      <ErrorIcon
        color="error"
        style={{ width: 35, height: 35, marginBottom: 15 }}
      />
      <Typography
        variant="subtitle1"
        color="textSecondary"
        className={classes.textStyle}
      >
        Sorry, We are having trouble loading your profile. Please sign out and
        then sign in again.
      </Typography>

      <SignOutButton errorOnLoading />
    </div>
  );
};
export default function ProfileLoadingView({
  isBusy = true,
  hasError = false,
}) {
  const classes = useStyles();

  return (
    <Grow in direction="left" timeout={700}>
      <div className={classes.root}>
        <MaptualLogo
          style={{ width: '17%', height: 'auto', marginBottom: '5%' }}
        />
        {isBusy && <Loading />}
        {hasError && <ErrorOnLoading />}
      </div>
    </Grow>
  );
}

ProfileLoadingView.propTypes = {
  isBusy: PropTypes.bool,
  hasError: PropTypes.bool,
};
