import { JSX } from 'react';
import { ToggleButton, ToggleButtonGroup, styled } from '@mui/material';
import { useRouteMatch, useHistory } from 'react-router-dom';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';

import { ROUTES } from '../../../../../constants';

const LIST_TYPES = { LIST: 'list', MAP: 'map' };

interface IconMap {
  [key: string]: JSX.Element;
}

const LIST_TYPES_ICON_MAP: IconMap = {
  list: <FormatListBulletedOutlinedIcon />,
  map: <MapOutlinedIcon />,
};

const StyledToggleButton = styled(ToggleButton)(() => ({
  fontSize: 14,
  height: 34,
}));

export const MapToggle = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const selectedListType = match.url.includes(`${ROUTES.HCPS}${ROUTES.MAP}`)
    ? LIST_TYPES.MAP
    : LIST_TYPES.LIST;

  const handleListTypeChange = () => {
    if (selectedListType === LIST_TYPES.LIST) {
      history.push(`${match.url}${ROUTES.MAP}`);
    } else {
      history.push(
        `${match.url.split(`${ROUTES.HCPS}${ROUTES.MAP}`)[0]}${ROUTES.HCPS}`
      );
    }
  };

  return (
    <ToggleButtonGroup
      value={selectedListType}
      onChange={() => handleListTypeChange()}
      aria-label="List Type"
    >
      {Object.values(LIST_TYPES).map((listType) => (
        <StyledToggleButton key={listType} value={listType} variant="contained">
          {LIST_TYPES_ICON_MAP[listType]}
        </StyledToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
