import { Button, styled } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import {
  Modality,
  RTClient,
  RTInputAudioItem,
  RTResponse,
  TurnDetection,
} from "rt-client";
import { AudioHandler } from "./audio";
import { Mic, MicOff, GraphicEq, Sync, MoreHoriz } from "@mui/icons-material";
import { assistantInstructions } from "./assistantInstructions";

const Container = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 200,
}));

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isRecording",
})(({ theme: { themeColors }, isRecording }) => ({
  width: 36,
  height: 36,
  minWidth: 0,
  marginLeft: 100,
  marginTop: 0,
  borderRadius: "50%",
  padding: 4,
  backgroundColor: isRecording
    ? themeColors.inputsPrimaryFocusedSurfaceColor
    : themeColors.primaryColor,
  "&:hover": {
    backgroundColor: themeColors.colorBlue70,
  },
  "@keyframes pulse": {
    "0%": {
      borderWidth: 4,
    },
    "50%": {
      borderWidth: 8,
    },
    "100%": {
      borderWidth: 4,
    },
  },
  "@keyframes grow": {
    "0%": {
      width: 36,
      height: 36,
      marginLeft: 100,
      marginTop: 0,
    },
    "100%": {
      width: 56,
      height: 56,
      marginLeft: 80,
      marginTop: 40,
    },
  },
  boxSizing: "content-box",
  border: isRecording ? `4px solid ${themeColors.colorBlue70}` : "none",
  animation: isRecording ? "pulse 1.5s infinite, grow 1.5s 1 forwards" : "none",
}));

const StyledMic = styled(Mic)(() => ({
  color: "#FFFFFF",
  width: 20,
  height: 20,
}));

const StyledSync = styled(MoreHoriz)(() => ({
  color: "#FFFFFF",
  width: 20,
  height: 20,
}));

const StyledMicOff = styled(MicOff)(() => ({
  color: "#FFFFFF",
  width: 20,
  height: 20,
}));

const StyledAudioIcon = styled(GraphicEq)(() => ({
  color: "#FFFFFF",
  width: 20,
  height: 20,
}));

interface ToolDeclaration {
  name: string;
  parameters: string;
  returnValue: string;
}

interface Message {
  type: "user" | "assistant" | "status";
  content: string;
}

export const Assistant = ({ biggestCity }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const clientRef = useRef<RTClient | null>(null);
  const [instructions, setInstructions] = useState(assistantInstructions);
  const [modality, setModality] = useState("audio");
  const [isAzure, setIsAzure] = useState(true);
  const [apiKey, setApiKey] = useState(
    "EBjkZ5VjMMje3PNVdQ3vfPZ8oBCc0Cuiuf2Bv2q5JEvgu06veScuJQQJ99AKACHYHv6XJ3w3AAABACOGpNAm"
  );
  const [endpoint, setEndpoint] = useState(
    "wss://odaia.openai.azure.com/openai/realtime"
  );
  const [deployment, setDeployment] = useState("gpt-4o-realtime-preview");
  const [useVAD, setUseVAD] = useState(true);
  const [tools, setTools] = useState<ToolDeclaration[]>([]);
  const [temperature, setTemperature] = useState(0.9);
  const audioHandlerRef = useRef<AudioHandler | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isResponding, setIsResponding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleConnect = async () => {
    if (!isConnected) {
      try {
        setIsConnecting(true);
        clientRef.current = isAzure
          ? new RTClient(new URL(endpoint), { key: apiKey }, { deployment })
          : new RTClient(
              { key: apiKey },
              { model: "gpt-4o-realtime-preview-2024-10-01" }
            );
        const modalities: Modality[] =
          modality === "audio" ? ["text", "audio"] : ["text"];
        const turnDetection: TurnDetection = useVAD
          ? { type: "server_vad", threshold: 0.5 }
          : null;
        clientRef.current.configure({
          instructions: instructions?.length > 0 ? instructions : undefined,
          // input_audio_transcription: { model: "whisper-1" },
          turn_detection: turnDetection,
          tools,
          temperature,
          modalities,
        });
        startResponseListener();

        setIsConnected(true);
      } catch (error) {
        console.error("Connection failed:", error);
        setIsConnected(false);
      } finally {
        setIsConnecting(false);
      }
    } else {
      await disconnect();
    }
  };

  const disconnect = async () => {
    if (clientRef.current) {
      try {
        await clientRef.current.close();
        clientRef.current = null;
        setIsConnected(false);
        setIsResponding(false);
      } catch (error) {
        setIsConnected(false);
        setIsResponding(false);
        console.error("Disconnect failed:", error);
      }
    }
  };

  const handleResponse = async (response: RTResponse) => {
    for await (const item of response) {
      if (item.type === "message" && item.role === "assistant") {
        const message: Message = {
          type: item.role,
          content: "",
        };
        setMessages((prevMessages) => [...prevMessages, message]);
        for await (const content of item) {
          if (content.type === "text") {
            for await (const text of content.textChunks()) {
              message.content += text;
              setMessages((prevMessages) => {
                prevMessages[prevMessages.length - 1].content = message.content;
                return [...prevMessages];
              });
            }
          } else if (content.type === "audio") {
            setIsResponding(true);
            const textTask = async () => {
              for await (const text of content.transcriptChunks()) {
                message.content += text;
                setMessages((prevMessages) => {
                  prevMessages[prevMessages.length - 1].content =
                    message.content;
                  return [...prevMessages];
                });
              }
            };
            const audioTask = async () => {
              audioHandlerRef.current?.startStreamingPlayback();
              for await (const audio of content.audioChunks()) {
                audioHandlerRef.current?.playChunk(audio);
              }
            };
            await Promise.all([textTask(), audioTask()]);
          }
        }
      }
    }
  };

  const handleInputAudio = async (item: RTInputAudioItem) => {
    setIsResponding(false);
    audioHandlerRef.current?.stopStreamingPlayback();
    await item.waitForCompletion();
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        type: "user",
        content: item.transcription || "",
      },
    ]);
  };

  const startResponseListener = async () => {
    if (!clientRef.current) return;

    try {
      for await (const serverEvent of clientRef.current.events()) {
        if (serverEvent.type === "response") {
          await handleResponse(serverEvent);
        } else if (serverEvent.type === "input_audio") {
          await handleInputAudio(serverEvent);
        }
      }
    } catch (error) {
      if (clientRef.current) {
        console.error("Response iteration error:", error);
      }
    }
  };

  const toggleRecording = async () => {
    if (!isRecording && clientRef.current) {
      try {
        if (!audioHandlerRef.current) {
          audioHandlerRef.current = new AudioHandler();
          await audioHandlerRef.current.initialize();
        }
        await audioHandlerRef.current.startRecording(async (chunk) => {
          await clientRef.current?.sendAudio(chunk);
        });
        setIsRecording(true);
        sendIntro();
      } catch (error) {
        setIsRecording(false);
        console.error("Failed to start recording:", error);
      }
    } else if (audioHandlerRef.current) {
      try {
        audioHandlerRef.current?.stopStreamingPlayback();
        audioHandlerRef.current.stopRecording();
        if (!useVAD) {
          const inputAudio = await clientRef.current?.commitAudio();
          await handleInputAudio(inputAudio!);
          await clientRef.current?.generateResponse();
        }
        setIsRecording(false);
      } catch (error) {
        setIsConnected(false);
        setIsRecording(false);
        console.error("Failed to stop recording:", error);
      }
    }
  };

  const sendIntro = async () => {
    if (clientRef?.current) {
      // setTimeout(async () => {
      try {
        await clientRef.current.sendItem({
          type: "message",
          role: "user",
          content: [
            {
              type: "input_text",
              text: `Hello, introduce yourself please, notice the speaker is currently looking at ${biggestCity}, and ask if you can help them find HCPs there`,
            },
          ],
        });
        await clientRef.current.generateResponse();
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsConnected(false);
        setIsRecording(false);
        console.error("Failed to send intro:", error);
      }
      // }, 0);
    }
  };

  const toggleVoice = async () => {
    if (isConnected) {
      setIsLoading(true);

      toggleRecording();
      handleConnect();
      setIsLoading(false);
    } else {
      setIsLoading(true);
      handleConnect();
      toggleRecording();
      setIsLoading(false);
      // sendIntro();
    }
  };
  return (
    <Container>
      <StyledButton
        variant="outline"
        onClick={toggleVoice}
        className={isRecording ? "bg-red-100" : ""}
        // disabled={!isConnected}
        isRecording={isRecording}
      >
        {/* {isRecording && !isResponding && <StyledMicOff className="w-4 h-4" />} */}
        {!isResponding && !isLoading && <StyledMic className="w-4 h-4" />}
        {isResponding && !isLoading && <StyledAudioIcon className="w-4 h-4" />}
        {isLoading && <StyledSync className="w-4 h-4" />}
      </StyledButton>
    </Container>
  );
};
