import { useContext } from 'react';
import { sendEvent } from '../../../../trackers/mixpanel';
import { DEFAULT_CONTEXT } from '../../../../trackers/context';
import { AppContext } from '../../../../containers/application/appContext';
import { OpportunityMetadataContext } from './data/opportunityMetadataContext';
import { SHORT_FORM_CADENCE } from '../constants';

interface OpportunityMixpanelTrackerType {
  trackGraphMetric: (
    metricName: string,
    timescaleSelected: SHORT_FORM_CADENCE
  ) => void;
  trackGraphTimescale: (
    metricName: string,
    timescaleSelected: SHORT_FORM_CADENCE
  ) => void;
}

export const useOpportunityMixpanelTracker =
  (): OpportunityMixpanelTrackerType => {
    const { projectList } = useContext(AppContext);
    const { projectId } = useContext(OpportunityMetadataContext);
    const project = projectList?.find((proj) => proj.projectId === projectId);

    const trackEvent = (eventName: string, properties: Object): void => {
      const payload = {
        ...DEFAULT_CONTEXT,
        'Project ID': projectId ?? '',
        'Project Name': project?.projectName ?? '',
        ...properties,
      };

      sendEvent(eventName, payload);
    };

    const trackGraphMetric = (
      metricName: string,
      timescaleSelected: SHORT_FORM_CADENCE
    ): void => {
      trackEvent('Opportunity Graph Metric', {
        'Metric Selected': metricName,
        'Timescale Selected': timescaleSelected,
      });
    };

    const trackGraphTimescale = (
      metricName: string,
      timescaleSelected: SHORT_FORM_CADENCE
    ): void => {
      trackEvent(`Opportunity Graph Timescale`, {
        'Timescale Selected': timescaleSelected,
        'Metric Selected': metricName,
      });
    };

    return {
      trackGraphMetric,
      trackGraphTimescale,
    };
  };
