import { Button, styled } from "@mui/material";
import { useRef, useState } from "react";
import { AudioHandler, Player, Recorder } from "./audio";
import { Mic, MicOff, GraphicEq, Sync, MoreHoriz } from "@mui/icons-material";

const Container = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isRecording",
})(({ theme: { themeColors }, isRecording }) => ({
  width: 36,
  height: 36,
  minWidth: 0,
  marginRight: 12,
  marginTop: 0,
  borderRadius: "50%",
  padding: 4,
  backgroundColor: isRecording
    ? themeColors.inputsPrimaryFocusedSurfaceColor
    : themeColors.primaryColor,
  "&:hover": {
    backgroundColor: themeColors.colorBlue70,
  },
  "@keyframes pulse": {
    "0%": {
      borderWidth: 4,
    },
    "50%": {
      borderWidth: 8,
    },
    "100%": {
      borderWidth: 4,
    },
  },
  "@keyframes grow": {
    "0%": {
      width: 36,
      height: 36,
      marginLeft: 100,
      marginTop: 0,
    },
    "100%": {
      width: 56,
      height: 56,
      marginLeft: 80,
      marginTop: 40,
    },
  },
  boxSizing: "content-box",
  border: isRecording ? `4px solid ${themeColors.colorBlue70}` : "none",
  animation: isRecording ? "pulse 1.5s infinite, grow 1.5s 1 forwards" : "none",
}));

const StyledMic = styled(Mic)(() => ({
  color: "#FFFFFF",
  width: 20,
  height: 20,
}));

const StyledSync = styled(MoreHoriz)(() => ({
  color: "#FFFFFF",
  width: 20,
  height: 20,
}));

const StyledMicOff = styled(MicOff)(() => ({
  color: "#FFFFFF",
  width: 20,
  height: 20,
}));

const StyledAudioIcon = styled(GraphicEq)(() => ({
  color: "#FFFFFF",
  width: 20,
  height: 20,
}));

interface ToolDeclaration {
  name: string;
  parameters: string;
  returnValue: string;
}

interface Message {
  type: "user" | "assistant" | "status";
  content: string;
}

export const MaptualAssistant = ({
  usergroup,
  projectId,
  regionId,
  selectMaptualListItemByEntityId,
}) => {
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const clientRef = useRef<WebSocket | null>(null);
  const [endpoint, setEndpoint] = useState(
    `wss://voice.demo.odaia.ai/v1/realtime/user_group/${usergroup}/project_id/${projectId}/entity_parent_id/${regionId}`
  );
  const audioHandlerRef = useRef<AudioHandler | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isResponding, setIsResponding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const buffer = useRef(new Uint8Array());
  const [recordingActive, setRecordingActive] = useState(true);
  const audioRecorder = useRef<Recorder | null>(null);
  const audioPlayer = useRef<Player | null>(null);

  const handleConnect = async () => {
    if (!isConnected) {
      try {
        setIsConnecting(true);
        clientRef.current = new WebSocket(endpoint);

        clientRef.current.onmessage = (event) => {
          handleEvents(JSON.parse(event.data));
        };

        setIsConnected(true);
      } catch (error) {
        console.error("Connection failed:", error);
        setIsConnected(false);
      } finally {
        setIsConnecting(false);
      }
    } else {
      await disconnect();
    }
  };

  const disconnect = async () => {
    if (clientRef.current) {
      try {
        clientRef.current.close();
        clientRef.current = null;
        audioRecorder.current.stop();
        audioPlayer.current?.clear();

        setIsConnected(false);
        setIsResponding(false);
      } catch (error) {
        setIsConnected(false);
        setIsResponding(false);
        console.error("Disconnect failed:", error);
      }
    }
  };

  async function handleEvents(event) {
    const message = event;
    let consoleLog = "" + message.type;

    switch (message.type) {
      case "response.audio.delta":
        const binary = atob(message.delta);
        const bytes = Uint8Array.from(binary, (c) => c.charCodeAt(0));
        const pcmData = new Int16Array(bytes.buffer);
        audioPlayer.current.play(pcmData);
        break;

      case "input_audio_buffer.speech_started":
        audioPlayer.current.clear();
        break;
      case "response.text.entityId":
        selectMaptualListItemByEntityId(message.entityId.toString());
        break;
      default:
        consoleLog = JSON.stringify(message, null, 2);
        break;
    }
    if (consoleLog) {
      console.log(consoleLog);
    }

    // resetAudio(false);
  }

  function combineArray(newData: Uint8Array) {
    const newBuffer = new Uint8Array(buffer.current.length + newData.length);
    newBuffer.set(buffer.current);
    newBuffer.set(newData, buffer.current.length);
    buffer.current = newBuffer;
  }

  function processAudioRecordingBuffer(data) {
    const uint8Array = new Uint8Array(data);
    combineArray(uint8Array);
    if (buffer.current.length >= 4800) {
      const toSend = new Uint8Array(buffer.current.slice(0, 4800));
      buffer.current = new Uint8Array(buffer.current.slice(4800));
      const regularArray = String.fromCharCode(...toSend);
      const base64 = btoa(regularArray);
      if (recordingActive) {
        clientRef.current?.send(
          JSON.stringify({
            type: "input_audio_buffer.append",
            audio: base64,
          })
        );
      }
    }
  }

  async function resetAudio(startRecording: boolean) {
    setRecordingActive(false);
    if (audioRecorder.current) {
      audioRecorder.current.stop();
    }
    if (audioPlayer.current) {
      audioPlayer.current.clear();
    }
    const newPlayer = new Player();
    const newRecorder = new Recorder(processAudioRecordingBuffer);
    audioRecorder.current = newRecorder;
    audioPlayer.current = newPlayer;
    newPlayer.init(24000);
    if (startRecording) {
      setRecordingActive(true);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioRecorder.current.start(stream);
    }
  }

  const toggleRecording = async () => {
    if (!isRecording && clientRef.current) {
      resetAudio(true);
    }
  };

  const toggleVoice = async () => {
    if (isConnected) {
      setIsLoading(true);

      handleConnect();
      setIsLoading(false);
    } else {
      setIsLoading(true);
      handleConnect();
      toggleRecording();
      setIsLoading(false);
      // sendIntro();
    }
  };
  return (
    <Container>
      <StyledButton
        variant="outline"
        onClick={toggleVoice}
        className={isRecording ? "bg-red-100" : ""}
        // disabled={!isConnected}
        isRecording={isRecording}
      >
        {/* {isRecording && !isResponding && <StyledMicOff className="w-4 h-4" />} */}
        {!isResponding && !isLoading && <StyledMic className="w-4 h-4" />}
        {isResponding && !isLoading && <StyledAudioIcon className="w-4 h-4" />}
        {isLoading && <StyledSync className="w-4 h-4" />}
      </StyledButton>
    </Container>
  );
};
