import { useContext } from 'react';
import { styled, Box, Skeleton } from '@mui/material';
import { FieldContext } from '../../../application/appViews/field/fieldContext';
import { EntityDrawerContext } from '../drawer/data/entityDrawerContext';

const SkeletonWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  width: '100%',
  padding: '24px 16px',
}));

const StyledSkeletonBar = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  height: 75,
  backgroundColor: themeColors.surfaceEmpty,
}));

const StyledSkeletonChart = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  height: 350,
  backgroundColor: themeColors.surfaceEmpty,
}));

export const DrawerDataSkeleton = () => {
  const { entityViews } = useContext(FieldContext);
  const { entityType } = useContext(EntityDrawerContext);

  const entityTypeViews =
    entityViews?.find((view) => view?.entityType === entityType) ?? {};

  return (
    <SkeletonWrapper>
      <StyledSkeletonBar animation="wave" />

      {entityTypeViews?.drawerCharts?.map((_, index) => (
        <StyledSkeletonChart key={index} animation="wave" />
      ))}
    </SkeletonWrapper>
  );
};
