import { useMutation, useQueryClient } from 'react-query';
import {
  useCreateModuleConfigSchema,
  updateModuleConfigSchemaWithMigration,
  updateModuleConfigValues,
  deleteModuleConfigValue,
  deleteModuleConfigSchema,
} from './moduleConfigRequests';
import { queryKeys } from '../queries';

export const useUpdateModuleConfigValuesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateModuleConfigValues, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.moduleConfigSchemaValues);
      queryClient.invalidateQueries(queryKeys.moduleConfigValues);
    },
  });
};

export const useDeleteModuleConfigValuesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteModuleConfigValue, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.moduleConfigValues);
    },
  });
};

export const useDeleteModuleConfigSchemaMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteModuleConfigSchema, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.moduleConfigValues);
      queryClient.invalidateQueries(queryKeys.moduleConfigSchemas);
    },
  });
};

export const useCreateModuleConfigSchemasMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ requestBody }) => useCreateModuleConfigSchema(requestBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.moduleConfigSchemas);
      },
    }
  );
};

export const useUpdateModuleConfigSchemasMutationWithMigration = () => {
  const queryClient = useQueryClient();
  return useMutation(updateModuleConfigSchemaWithMigration, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([
        queryKeys.moduleConfigSchema,
        variables.requestBody.schema_name,
      ]);
      queryClient.invalidateQueries([
        queryKeys.moduleConfigSchemaValues,
        { schemaName: variables.requestBody.schema_name },
      ]);
    },
  });
};
